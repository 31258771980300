import { Component, inject, input } from "@angular/core";
import { SelectSearchFieldComponent } from "../select-search-field/select-search-field.component";
import { ISignalPaginatedResponse } from "../interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { LookupsService } from "../../data/market/lookups/services/lookups.service";
import { BaseCompositeSelectSearchFieldComponent } from "../abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { EMPTY, Observable } from "rxjs";

@Component({
  selector: "est-select-user-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./select-user-field.component.html",
  styleUrl: "./select-user-field.component.scss",
})
export class SelectUserFieldComponent extends BaseCompositeSelectSearchFieldComponent {
  divisionId = input<number | undefined>();
  private readonly lookupsService = inject(LookupsService);

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    if (this.divisionId()) {
      return this.lookupsService.getUsersLookUpsInDivision({
        search: this.searchSignal(),
        pageNumber,
        pageSize: this.paginatedOptions.pageSize(),
        divisionId: this.divisionId(),
        ...(this.control().value && { selectedId: this.control().value }),
      });
    }
    return EMPTY;
  }
}
