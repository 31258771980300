<div class="bg-white p-6 rounded-[0.625rem] r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    name="Jesper Thorkildsen"
    label="UNIT_TYPES.RESIDENTIAL"
    tooltip="UNIT_TYPES.RESIDENTIAL"
  ></est-subheader>
  <est-property-overview [propertyUnitStatus]="propertyUnitStatus()"></est-property-overview>
  <div>
    <div class="text-end my-6 pe-6">
      <div class="flex items-center justify-end gap-4 mb-4 gap-y-4 max-xl:flex-col-reverse max-xl:items-start">
        <est-select-field
          (changeSelectValue)="paginatedData.currentPage.set(1)"
          [dropDownOptions]="unitStatusOptions"
          [selectMultipleOptions]="false"
          [selectSignal]="unitStatusSignal"
          dropDownLabel="STATUS"
          appliedClass="!w-[9.375rem]"
        ></est-select-field>
        <est-search-input [searchSignal]="searchSignal"></est-search-input>
      </div>
    </div>

    <est-large-list-table
      [headers]="headers"
      [sortingFilterSignal]="sortingFilterSignal"
      [data]="paginatedData.results()"
      [isLoading]="isTableLoading"
      [actions]="actions"
      (advertised)="toggleAdvertisedUnits($event)"
      (heart)="toggleFavouriteUnit($event)"
    >
    </est-large-list-table>
    <est-pagination [paginationValues]="paginatedData"></est-pagination>
  </div>
  @if (isLoading) {
    <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
  }
</div>
