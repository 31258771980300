import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IUnitLayout } from "@root/data/market/units/models/unit-layout.model";
import { APIS } from "@root/core/api/market.apis";
import { IRoomName } from "@root/data/market/rooms/models/room-name.model";
import { IBalconyType } from "@root/data/market/rooms/models/balcony-type.model";
import { ITerraceType } from "@root/data/market/rooms/models/terrace-type.model";

@Injectable({
  providedIn: "root",
})
export class UnitLayoutRepository {
  readonly #http = inject(HttpClient);

  getRoomNames(): Observable<IRoomName[]> {
    return this.#http.get<IRoomName[]>(APIS.lookups.getRoomNames);
  }

  getBalconyTypes(): Observable<IBalconyType[]> {
    return this.#http.get<IBalconyType[]>(APIS.lookups.getBalconyTypes);
  }

  getTerraceTypes(): Observable<ITerraceType[]> {
    return this.#http.get<ITerraceType[]>(APIS.lookups.getTerraceTypes);
  }

  getUnitLayout(id: string): Observable<IUnitLayout> {
    return this.#http.get<IUnitLayout>(APIS.unit.getUnitLayout(id));
  }

  patchUnitLayout(id: string, body: Partial<IUnitLayout>): Observable<void> {
    return this.#http.patch<void>(APIS.unit.patchUnitLayout(id), body);
  }
}
