import { RoomType } from "@root/data/market/units/enums/room-type.enum";

export const RoomTypeHasConnectedRoomHashMap = new Map<RoomType, boolean>([
  [RoomType.Bedroom, false],
  [RoomType.LivingRoom, false],
  [RoomType.AdditionalRoom, false],
  [RoomType.Restroom, true],
  [RoomType.InternalStorageRoom, true],
  [RoomType.Garden, true],
  [RoomType.Terrace, true],
  [RoomType.Balcony, true],
]);
