import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "est-companies",
  standalone: true,
  imports: [UnderDevelopmentComponent, NavigationTabsComponent, RouterOutlet],
  templateUrl: "./companies.component.html",
  styleUrl: "./companies.component.scss",
})
export class CompaniesComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "OVERVIEW",
      routerLink: "overview",
    },
    {
      label: "COMPANIES.COMPANY",
      routerLink: "menu2",
    },
  ];
}
