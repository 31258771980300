import { Component, effect, EffectRef, inject, input, signal, WritableSignal } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { UNIT_FACILITIES_HEADERS } from "@root/views/main/units/unit-details/components/facilities/unit-facilities/unit-facilities.headers";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { UnitFacilitiesService } from "@root/data/market/units/services/unit-facilities.service";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { FacilityDetailsComponent } from "@root/shared/facility-details/facility-details.component";
import { finalize } from "rxjs";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { MatDialog } from "@angular/material/dialog";
import { AddUnitFacilityComponent } from "@root/views/main/units/unit-details/components/facilities/unit-facilities/add-unit-facility/add-unit-facility.component";
import { IAddFacilityInputs } from "@root/shared/abstracts/base-add-facility/add-facility-inputs.interface";
import { UnitFacilitiesCommunicationService } from "@root/views/main/units/unit-details/components/facilities/unit-facilities/unit-facilities-communication.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { EditAssignedUnitFacilityComponent } from "@root/views/main/units/unit-details/components/facilities/unit-facilities/edit-assigned-unit-facility/edit-assigned-unit-facility.component";
import { Router } from "@angular/router";

@Component({
  selector: "est-unit-facilities",
  standalone: true,
  imports: [ButtonComponent, SmallHeaderComponent, PaginationComponent, SmallListTableComponent],
  templateUrl: "./unit-facilities.component.html",
  styleUrl: "./unit-facilities.component.scss",
})
export class UnitFacilitiesComponent extends BasePaginatedTableWithSearchComponent {
  readonly toggleButtonDisableHashMap = new Map<number, boolean>();
  readonly loadDataEffect$: EffectRef = effect(() => this.#loadDataWithParams(), {
    allowSignalWrites: true,
  });
  #router = inject(Router);
  headers: ITableHeader[] = UNIT_FACILITIES_HEADERS;
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  unitId = input.required<string>();
  protected readonly FacilityDetailsComponent = FacilityDetailsComponent;
  readonly #unitFacilitiesService = inject(UnitFacilitiesService);
  readonly #matDialog = inject(MatDialog);
  readonly #unitFacilitiesCommunicationService = inject(UnitFacilitiesCommunicationService);
  readonly #refreshFacilitiesOnModificationSubscription$ =
    this.#unitFacilitiesCommunicationService.unitFacilitiesModified$.pipe(takeUntilDestroyed()).subscribe({
      next: () => {
        this.#loadDataWithParams();
      },
    });

  override loadData(params: Omit<IPaginationSortPayload, "search">): void {
    this.isTableLoading = true;
    this.#unitFacilitiesService
      .getUnitAssignedFacilities(this.unitId(), params)
      .pipe(finalize(() => (this.isTableLoading = false)))
      .subscribe({
        next: (signalResponse) => {
          copyPaginatedSignalResponse(this.paginatedData, signalResponse);
          this.#constructToggleButtonDisableHashMap(this.paginatedData.results());
        },
      });
  }

  openAddFacilityDialog(): void {
    this.#matDialog.open<AddUnitFacilityComponent, IAddFacilityInputs>(AddUnitFacilityComponent, {
      width: "40rem",
      height: "37.5rem",
      data: {
        entityId: this.unitId(),
        header: "PROPERTY_FACILITIES.UNIT_FACILITIES",
        description: "UNIT_FACILITIES.ADD_UNIT_FACILITY_DESCRIPTION",
      },
    });
  }

  toggleFacility(checked: boolean, unitFacility: ISmallListTableInput): void {
    this.#unitFacilitiesService.toggleUnitFacility(this.unitId(), unitFacility.id).subscribe({
      next: (res) => {
        unitFacility["isActive"] = res.isActive;
      },
      error: () => {
        unitFacility["isActive"] = !checked;
      },
    });
  }

  readonly #openEditAssignedFacilityModal = (facilityRow: ISmallListTableInput): void => {
    this.#matDialog.open<
      EditAssignedUnitFacilityComponent,
      {
        unitId: string;
        facilityId: number;
      }
    >(EditAssignedUnitFacilityComponent, {
      width: "28rem",
      maxHeight: "95dvh",
      data: {
        unitId: this.unitId(),
        facilityId: facilityRow.id,
      },
    });
  };

  override actions: ITableAction[] = [
    {
      labelFn: () => "EDIT",
      callbackFn: this.#openEditAssignedFacilityModal,
      isVisible: (row) => !row["facilityPackageId"],
    },
    {
      labelFn: () => "REMOVE",
      callbackFn: () => {},
      isVisible: (row) => !row["facilityPackageId"],
    },
    {
      labelFn: () => "GO_TO_PACKAGE",
      callbackFn: (row) => {
        this.#router.navigate(["/edit-package-facility", row["facilityPackageId"]]);
      },
      isVisible: (row) => row["facilityPackageId"],
    },
  ];

  #loadDataWithParams(): void {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageNumber: this.paginatedData.currentPage(),
      pageSize: this.paginatedData.pageSize(),
    });
  }

  #constructToggleButtonDisableHashMap(results: ISmallListTableInput[]): void {
    results.forEach((result) => {
      !result["canBeToggled"] && this.toggleButtonDisableHashMap.set(result.id, true);
    });
  }
}
