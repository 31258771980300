import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";

export const LINKED_DIVISIONS_TABLE_HEADERS: ITableHeader[] = [
  {
    label: "",
    key: "divisionIds",
    type: TableHeaderType.Checkbox,
    isSortable: false,
  },
  {
    label: "DIVISIONS.DIVISION",
    key: "name",
    isSortable: true,
    type: TableHeaderType.ImageWithHeaderAndSubHeader,
    maxTextLength: 70,
  },
  {
    label: "PROPERTIES.NAME",
    key: "properties",
    isSortable: true,
    type: TableHeaderType.Text,
    maxTextLength: 70,
  },
  {
    label: "UNITS",
    key: "units",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "SETTINGS.USERS.ACTIVATED_USERS",
    key: "activeUsers",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "CREATED",
    key: "createdOn",
    isSortable: true,
    type: TableHeaderType.Date,
  },
  {
    label: "UNITS_OVERVIEW",
    key: "unitsOverview",
    isSortable: true,
    type: TableHeaderType.OverViewStatus,
  },
];
