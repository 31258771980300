<div class="flex justify-between items-end mb-5">
  <est-small-header
    heading="PROPERTY_MEDIAS.NAME"
    appliedClasses="!text-xl"
    subheading="PROPERTY_MEDIAS.DESCRIPTION"
    tooltip="PROPERTY_MEDIAS.NAME"
  ></est-small-header>
  <est-button
    label="ADD_MEDIA"
    buttonStyleType="button--primary"
    buttonType="button"
    imageSrc="assets/images/icons/plus.svg"
    imageAlt="ADD"
    imagePosition="before"
    (click)="addMedia()"
  ></est-button>
</div>
<div class="relative">
  <est-media-sortable-grid
    [fileUploadControl]="fileUploadControl"
    [formArray]="formControls.medias"
    [medias]="packageMedias"
    (changeControl)="changeControl($event)"
  >
  </est-media-sortable-grid>

  @if (isLoading) {
    <est-loading-overlay appliedClasses="absolute inset-0 p-5"></est-loading-overlay>
  }
</div>
