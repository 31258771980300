import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class StylingFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  headerImageDesktopTabletFileSizeControl = this.#fb.control<string>("");
  headerImageDesktopTabletDimensionControl = this.#fb.control<string>("");
  headerImageDesktopTabletFilenameControl = this.#fb.control<string>("");
  headerImageMobileFileSizeControl = this.#fb.control<string>("");
  headerImageMobileFileDimensionControl = this.#fb.control<string>("");
  headerImageMobileFilenameControl = this.#fb.control<string>("");
  logoFileSizeControl = this.#fb.control<string>("");
  logoFileDimensionControl = this.#fb.control<string>("");
  logoFileNameControl = this.#fb.control<string>("");
  imageControl = this.#fb.control<string>("");
  control = this.#fb.control("#a00303");
  stylingDetails = this.#fb.group(
    {
      id: this.#fb.control(0),
      organisationId: this.#fb.control(1),
      headerSection: this.#fb.control(""),
      headerText: this.#fb.control(""),
      footerSection: this.#fb.control(""),
      footerBorder: this.#fb.control(""),
      footerText: this.#fb.control(""),
      ctaPrimaryButton: this.#fb.control(""),
      ctaPrimaryText: this.#fb.control(""),
      ctaSecondaryButton: this.#fb.control(""),
      ctaSecondaryText: this.#fb.control(""),
      links: this.#fb.control(""),
      timeSlotActiveButton: this.#fb.control(""),
      timeSlotActiveText: this.#fb.control(""),
      timeSlotInActiveButton: this.#fb.control(""),
      timeSlotInActiveText: this.#fb.control(""),
      headerImageMobileUrl: this.#fb.control<File | null | string>(""),
      headerImageDesktopTabletUrl: this.#fb.control<File | null | string>(""),
      logoUrl: this.#fb.control<File | null | string>(""),
      allowChangesOnLevelBelow: this.#fb.control<boolean>(false),
    },
    { updateOn: "change" },
  );

  organizationFormGroup = this.#fb.group(
    {
      stylingDetailsDTO: this.stylingDetails,
      headerImageMobile: this.#fb.control<File | null | string>(null),
      headerImageDesktopTablet: this.#fb.control<File | null | string>(null),
      logo: this.#fb.control<File | null | string>(null),
    },
    { updateOn: "change" },
  );
}
