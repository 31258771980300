import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-property-overview",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./property-overview.component.html",
  styleUrl: "./property-overview.component.scss",
})
export class PropertyOverviewComponent {}
