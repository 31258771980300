import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { MarketingQuestionsService } from "@root/data/market/marketing-questions/services/marketing-questions.service";
import { IMarketingQuestionPopupInputs } from "@root/shared/marketing-question-popup/marketing-question-popup-inputs.interface";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { InputComponent } from "@root/shared/input/input.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ExternalTitleInputComponent } from "@root/shared/external-title-input/external-title-input.component";
import {
  castControlFromAbstractToFormControl,
  markAllControlsAsTouchedAndDirty,
} from "@root/shared/utilities/form.utilities";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { forkJoin, Observable, Subscription, tap } from "rxjs";
import { IMarketingQuestion } from "@root/data/market/marketing-questions/models/marketing-question.model";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { UsersService } from "@root/data/market/users/services/users.service";

@Component({
  selector: "est-marketing-question-popup",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    SmallHeaderComponent,
    InputComponent,
    MatSlideToggle,
    TranslateModule,
    TooltipComponent,
    ButtonComponent,
    ExternalTitleInputComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./marketing-question-popup.component.html",
  styleUrl: "./marketing-question-popup.component.scss",
})
export class MarketingQuestionPopupComponent implements OnInit, OnDestroy {
  readonly #marketingQuestionsService = inject(MarketingQuestionsService);
  readonly #systemConfigurationService = inject(SystemConfigurationsService);
  readonly #userService = inject(UsersService);
  readonly #matDialog = inject(MatDialog);
  readonly #fb = inject(NonNullableFormBuilder);
  readonly componentInputs: IMarketingQuestionPopupInputs = inject(MAT_DIALOG_DATA);
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;
  protected languages: ILanguage[] = [];
  protected languageConfiguration!: ISystemLanguageConfiguration;
  isPageLoading: boolean = false;
  isFormSubmitting: boolean = false;
  #marketingQuestion!: IMarketingQuestion;

  #loadMarketingQuestionSubscription: Subscription = new Subscription();

  formGroup = this.#fb.group({
    id: this.#fb.control(0),
    internalTitle: this.#fb.control("", Validators.required),
    published: this.#fb.control(false),
    isInheritanceActive: this.#fb.control(false),
    externalTitles: this.#fb.array<FormGroup>([]),
  });

  #addExternalTitle() {
    this.formGroup.controls.externalTitles.push(
      this.#fb.group({
        id: this.#fb.control(0),
        languageId: this.#fb.control(""),
        title: this.#fb.control("", Validators.required),
        enabled: this.#fb.control(false),
      }),
    );
  }

  ngOnInit(): void {
    this.isPageLoading = true;
    if (this.componentInputs.isEditPopup) {
      this.#initializeEditPopup();
    } else {
      this.#initializeCreatePopup();
    }
  }

  ngOnDestroy() {
    this.#loadMarketingQuestionSubscription.unsubscribe();
  }

  isDefaultLanguage(languageId: string): boolean {
    return this.languageConfiguration.defaultEndUserLanguage.languageId === languageId;
  }

  getExternalTitleLanguage(languageId: string): ILanguage {
    return this.languages.find((language) => language.languageId === languageId)!;
  }

  #initializeEditPopup() {
    this.#loadMarketingQuestionSubscription = forkJoin([
      this.#getAllLanguages(),
      this.#getLanguageConfiguration(),
      this.#getMarketingQuestion(),
    ]).subscribe({
      complete: () => {
        this.isPageLoading = false;
        this.#updateExternalTitlesDisabled();
        this.#addMissingExternalTitles(this.#marketingQuestion);
      },
    });
  }

  #initializeCreatePopup() {
    this.#loadMarketingQuestionSubscription = forkJoin([
      this.#getAllLanguages(),
      this.#getLanguageConfiguration(),
    ]).subscribe({
      next: () => {
        this.#addRequiredExternalTitles();
        this.isPageLoading = false;
      },
      complete: () => this.#updateExternalTitlesDisabled(),
    });
  }

  #addRequiredExternalTitles() {
    this.#addExternalTitle();
    this.formGroup.controls.externalTitles
      .at(0)
      .patchValue({ languageId: this.languageConfiguration.defaultEndUserLanguage.languageId, enabled: true });
    for (let i = 0; i < this.languageConfiguration.allowedLanguages.length; i++) {
      this.#addExternalTitle();
      this.formGroup.controls.externalTitles
        .at(i + 1)
        .patchValue({ languageId: this.languageConfiguration.allowedLanguages[i].language.languageId });
    }
  }

  #getAllLanguages(): Observable<ILanguage[]> {
    return this.#userService.getAllLanguages().pipe(
      tap((languages: ILanguage[]) => {
        this.languages = languages;
      }),
    );
  }

  #addMissingExternalTitles(marketingQuestion: IMarketingQuestion) {
    if (this.formGroup.controls.externalTitles.length < this.languageConfiguration.allowedLanguages.length + 1) {
      const potentialLanguagesToAdd = [
        this.languageConfiguration.defaultEndUserLanguage,
        ...this.languageConfiguration.allowedLanguages.map((x) => x.language),
      ];
      const languagesToAdd = potentialLanguagesToAdd.filter(
        (x) => !marketingQuestion.externalTitles.map((value) => value.language.languageId).includes(x.languageId),
      );
      const indexOffset = this.formGroup.controls.externalTitles.length;
      for (let i = 0; i < languagesToAdd.length; i++) {
        this.#addExternalTitle();
        this.formGroup.controls.externalTitles.at(indexOffset + i).patchValue({
          languageId: languagesToAdd[i].languageId,
          enabled: this.isDefaultLanguage(languagesToAdd[i].languageId),
        });
      }
      this.formGroup.controls.externalTitles.controls = this.formGroup.controls.externalTitles.controls.sort((a, b) => {
        if (a.controls["languageId"].value === this.languageConfiguration.defaultEndUserLanguage.languageId) return -1;
        if (b.controls["languageId"].value === this.languageConfiguration.defaultEndUserLanguage.languageId) return 1;
        return 0;
      });
    }
  }

  #getMarketingQuestion(): Observable<IMarketingQuestion> {
    return this.#marketingQuestionsService
      .getMarketingQuestion({
        ids: { marketingQuestionId: this.componentInputs.marketingQuestionId! },
        sourceLevel: SourceLevel.Organisation,
      })
      .pipe(
        tap((marketingQuestion) => {
          this.#marketingQuestion = marketingQuestion;
          this.formGroup.patchValue(marketingQuestion);
          this.formGroup.controls.externalTitles.clear();
          marketingQuestion.externalTitles.forEach((externalTitle, index) => {
            this.#addExternalTitle();
            this.formGroup.controls.externalTitles
              .at(index)
              .patchValue({ ...externalTitle, languageId: externalTitle.language.languageId });
          });
        }),
      );
  }

  #getLanguageConfiguration(): Observable<ISystemLanguageConfiguration> {
    return this.#systemConfigurationService
      .getLanguageSystemConfiguration()
      .pipe(tap((languageConfiguration) => (this.languageConfiguration = languageConfiguration)));
  }

  submitForm() {
    if (!this.formGroup.valid) {
      markAllControlsAsTouchedAndDirty(this.formGroup);
      return;
    }
    this.isFormSubmitting = true;
    if (this.componentInputs.isEditPopup) {
      this.#marketingQuestionsService
        .updateMarketingQuestion(
          {
            sourceLevel: this.componentInputs.sourceLevel,
            ids: {
              marketingQuestionId: this.componentInputs.marketingQuestionId!,
            },
            routeId: this.componentInputs.routeId,
          },
          this.formGroup.value,
        )
        .subscribe({
          next: () => {
            this.isFormSubmitting = false;
            this.closeDialog();
          },
        });
    } else {
      this.#marketingQuestionsService
        .createMarketingQuestion(this.formGroup.value, this.componentInputs.sourceLevel, this.componentInputs.routeId)
        .subscribe({
          next: () => {
            this.isFormSubmitting = false;
            this.closeDialog();
          },
        });
    }
  }

  togglePublished() {
    this.formGroup.controls.published.patchValue(!this.formGroup.controls.published.value);
    this.#updateExternalTitlesDisabled();
  }

  #updateExternalTitlesDisabled() {
    this.formGroup.controls.externalTitles.controls.forEach((control, index) => {
      if (
        this.formGroup.controls.published.value &&
        this.formGroup.controls.externalTitles.at(index).controls["enabled"].value
      ) {
        this.formGroup.controls.externalTitles.at(index).enable();
      } else {
        this.formGroup.controls.externalTitles.at(index).disable();
      }
    });
  }

  toggleActivated() {
    this.formGroup.controls.isInheritanceActive.patchValue(!this.formGroup.controls.isInheritanceActive.value);
  }

  closeDialog() {
    this.#matDialog.closeAll();
  }
}
