<div #wrapperUi class="mb-6 relative">
  @if (!isLoading() || data().length) {
    <cdk-virtual-scroll-viewport itemSize="50" class="h-96">
      <table [dataSource]="data()" id="import-table" mat-table>
        <ng-container [matColumnDef]="firstColumnKey" [sticky]="true">
          @if (selectMode()) {
            <th class="w-16" mat-header-cell *matHeaderCellDef>
              <div class="flex items-center w-fit">
                <mat-checkbox
                  (change)="$event.checked ? selectAll() : deselectAll(); updateCheckboxUi()"
                  #selectAllCheckbox
                  [checked]="isAllCheckboxSelected"
                  [indeterminate]="isOneCheckboxSelected && !isAllCheckboxSelected"
                  [aria-label]="selectAllCheckbox.checked ? 'TABLE.DESELECT_ALL' : ('TABLE.SELECT_ALL' | translate)"
                >
                </mat-checkbox>
                <p>
                  {{ "IMPORT" | translate }}
                </p>
              </div>
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-checkbox
                (change)="updateCheckboxHashMap(i, $event.checked); updateCheckboxUi()"
                [checked]="checkboxHashMap().get(i)"
                #selectRowCheckbox
                [aria-label]="selectRowCheckbox.checked ? 'TABLE.DESELECT_ROW' : ('TABLE.SELECT_ROW' | translate)"
              >
              </mat-checkbox>
            </td>
          } @else if (showStatus()) {
            <th class="w-20 border-e border-black-500 !bg-black-200 !ps-4" mat-header-cell *matHeaderCellDef>
              <div class="flex items-center w-fit">
                <p>
                  {{ "STATUS" | translate }}
                </p>
              </div>
            </th>
            <td class="!bg-warning-50 border-e border-yellow-600 !ps-4" mat-cell *matCellDef="let element">
              <p class="font-medium text-sm">
                {{ "CREATE" | translate }}
              </p>
            </td>
          }
        </ng-container>
        @for (header of headers(); track header.key) {
          <ng-container [matColumnDef]="header.key" [stickyEnd]="!selectMode() && $last">
            <th mat-header-cell *matHeaderCellDef>
              <div class="flex items-center w-fit">
                @if (csvColumnToDtoFieldHashMap().has(header.key)) {
                  <p>
                    {{ csvColumnToDtoFieldHashMap().get(header.label) }}
                  </p>
                } @else {
                  <p class="text-error-100">
                    {{ "NOT_RECOGNIZED" | translate }}
                  </p>
                }
                @if (selectMode()) {
                  <button (click)="openMatchColumnDialog(header.label)" type="button" class="p-2">
                    <img
                      class="max-w-none"
                      ngSrc="assets/images/icons/edit-icon-gray.svg"
                      width="12"
                      height="12"
                      priority
                      [alt]="'EDIT' | translate"
                    />
                  </button>
                }
              </div>
            </th>
            <td
              [ngClass]="{
                '!bg-error-50':
                  !csvColumnToDtoFieldHashMap().has(header.key) ||
                  (UNITS_FIELDS_REQUIRED_HASHMAP.get(csvColumnToDtoFieldHashMap().get(header.key)!) &&
                    element[header.key].trim() === ''),
                '!ps-2': editRowsHashMap.get(i)
              }"
              mat-cell
              *matCellDef="let element; let i = index"
            >
              @if (editRowsHashMap.get(i)) {
                @if (csvColumnToDtoFieldHashMap().get(header.key)) {
                  @switch (UNITS_FIELDS_TYPES_HASHMAP.get(csvColumnToDtoFieldHashMap().get(header.key)!)) {
                    @case (UnitFieldType.Boolean) {
                      <mat-slide-toggle
                        (change)="element[header.key] = $event.checked.toString()"
                        [checked]="element[header.key] === '1' || element[header.key].toLowerCase() === 'true'"
                      ></mat-slide-toggle>
                    }
                    @case (UnitFieldType.Date) {
                      <est-datepicker
                        appliedClasses="w-32 !h-7"
                        [value]="convertStringDateToIso(element[header.key])"
                        (valueChange)="
                          element[header.key] = convertIsoDateToDateString($event, SUPPORTED_DATE_FORMAT_FOR_DISPLAY)
                        "
                      ></est-datepicker>
                    }
                    @case (UnitFieldType.Enum) {
                      <est-select-field-two-way-bind
                        appliedClass="!w-48 !h-7"
                        [selectMultipleOptions]="false"
                        [dropDownOptions]="
                          UNIT_COLUMNS_DROPDOWN_OPTIONS_MAPPER.get(csvColumnToDtoFieldHashMap().get(header.key)!)!
                        "
                        [(value)]="element[header.key]"
                      ></est-select-field-two-way-bind>
                    }
                    @case (UnitFieldType.Integer) {
                      <est-input
                        label=""
                        type="number"
                        [(value)]="element[header.key]"
                        appliedClass="!w-32 !h-7"
                        (keyup.enter)="clearEditRowsHashmap(); editRowsHashMap.set(i, false)"
                      ></est-input>
                    }
                    @default {
                      <est-input
                        label=""
                        [(value)]="element[header.key]"
                        appliedClass="!w-32 !h-7"
                        (keyup.enter)="clearEditRowsHashmap(); editRowsHashMap.set(i, false)"
                      ></est-input>
                    }
                  }
                } @else {
                  <est-input
                    label=""
                    [(value)]="element[header.key]"
                    appliedClass="!w-32 !h-7"
                    (keyup.enter)="clearEditRowsHashmap(); editRowsHashMap.set(i, false)"
                  ></est-input>
                }
              } @else {
                <p [attr.aria-label]="(header.label | translate) + element[header.key]">
                  {{ element[header.key] }}
                </p>
              }
            </td>
          </ng-container>
        }
        @if (selectMode()) {
          <ng-container [matColumnDef]="editColumnKey" [stickyEnd]="true">
            <th class="!bg-black-200 border-s border-black-500" mat-header-cell *matHeaderCellDef>
              <p>{{ "EDIT" | translate }}</p>
            </th>
            <td class="!bg-black-200 border-s border-black-500" mat-cell *matCellDef="let element; let i = index">
              <button
                (click)="editRowsHashMap.set(i, !editRowsHashMap.get(i))"
                type="button"
                class="p-1.5 rounded border border-black-100 bg-white"
              >
                @if (editRowsHashMap.get(i)) {
                  <img
                    class="max-w-none"
                    ngSrc="assets/images/icons/save.svg"
                    width="12"
                    height="12"
                    priority
                    [alt]="'SAVE' | translate"
                  />
                } @else {
                  <img
                    class="max-w-none"
                    ngSrc="assets/images/icons/edit-icon.svg"
                    width="12"
                    height="12"
                    priority
                    [alt]="'EDIT' | translate"
                  />
                }
              </button>
            </td>
          </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  }
</div>
