import { Component, inject, OnInit } from "@angular/core";
import { FormArray, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { BaseUserForm } from "@root/shared/abstracts/user-form/user-form.abstract";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { InputComponent } from "@root/shared/input/input.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { ListSelectTeamsComponent } from "@root/shared/list-select-teams/list-select-teams.component";
import { ListSelectPropertiesComponent } from "@root/shared/list-select-properties/list-select-properties.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageUploadComponent } from "@root/shared/image-upload/image-upload.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { IUser } from "@root/data/market/users/models/user.model";
import { SelectDivisionFieldComponent } from "@root/shared/select-search-division/select-division-field.component";
import { IUserWithPropertyAndTeam } from "@root/data/market/users/models/user-with-property-and-team.model";
import { ITeamDetails } from "@root/data/market/teams/models/team-details.model";
import { UserReplacementComponent } from "@root/views/main/organization/organization-settings/components/users/edit-user/user-replacement/user-replacement.component";
import { IUserReplacementComponentInputs } from "@root/views/main/organization/organization-settings/components/users/user-replacement-component-inputs.interface";
import { forkJoin, noop, timer } from "rxjs";
import { TeamsService } from "@root/data/market/teams/services/teams.service";
import { IPropertyDetails } from "@root/shared/interfaces/property.interface";
import { ICheckedItem } from "@root/shared/interfaces/checked-item.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { PropertyAccess } from "@root/data/market/properties/enums/property-access.enum";

@Component({
  selector: "est-edit-user",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatTooltip,
    MatProgressBar,
    MatStepper,
    MatStep,
    InputComponent,
    PhoneInputComponent,
    LanguageDropdownComponent,
    SelectDivisionFieldComponent,
    SelectFieldComponent,
    NgOptimizedImage,
    ListSelectTeamsComponent,
    ListSelectPropertiesComponent,
    NgClass,
    ButtonComponent,
    ImageUploadComponent,
    DialogHeaderComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./edit-user.component.html",
  styleUrl: "./edit-user.component.scss",
})
export class EditUserComponent extends BaseUserForm implements OnInit {
  componentInputs: { userId: number } = inject(MAT_DIALOG_DATA);
  totalTabsNo: number = 3;
  teamsDetails!: ITeamDetails[];
  propertiesDetails!: IPropertyDetails[];
  replaceUserId!: number | undefined;
  teamsIdReplacedUsed: number[] = [];
  teamsService = inject(TeamsService);
  deleteDivision: boolean = false;
  removeTeamsIdsFromUser: number[] = [];

  submitForm(): void {
    const replace = this.teamsService.replaceTeamManger(this.replaceUserId ?? 0, this.teamsIdReplacedUsed ?? []);
    const edit = this.usersService.editUser(this.userForm, false, this.componentInputs.userId);
    if (this.deleteDivision) {
      forkJoin([replace, edit, timer(2000)]).subscribe({
        next: () => noop(),
        complete: () => this.dialog.closeAll(),
      });
    } else {
      edit.subscribe(() => {
        this.updateUserListService.reloadUserListPage$.next();
        this.dialog.closeAll();
      });
    }
  }

  ngOnInit() {
    this.retrieveUserRoles();
    this.retrieveSystemLanguages();
    this.getUserDetailsById(this.componentInputs.userId);
  }

  getUserDetailsById(id: number) {
    this.isPageLoading = true;
    this.usersService.getUserDetailsById(id).subscribe((details) => {
      this.patchDivisionsValue(details.userDTO);
      this.patchTeamIdsValue(details);
      this.patchPropertiesValue(details);
      this.teamsDetails = details.teamsDTO;
      this.propertiesDetails = details.propertiesDTO;
      const userDetails = {
        ...details.userDTO,
        phoneNumber: {
          internationalNumber: details.userDTO.phoneNumber,
          countryCode: details.userDTO.phoneCountryCode,
          number: details.userDTO.phoneNumber,
        },
      };
      this.userForm.patchValue(userDetails);
      this.isPageLoading = false;
      this.setPropertyData();
    });
  }

  patchTeamIdsValue(userDetails: IUserWithPropertyAndTeam) {
    userDetails.teamsDTO.forEach((data) => {
      data.teamId && this.teamIds.push(data.teamId);
    });
    this.teamIdsControl?.setValue(this.teamIds);
  }

  patchPropertiesValue(userDetails: IUserWithPropertyAndTeam) {
    this.propertiesControl.setValue(userDetails.propertiesDTO);
  }

  patchDivisionsValue(userDetails: IUser) {
    const divisionArray = this.userForm.get("divisions") as FormArray;
    divisionArray.clear();
    userDetails.userDivisionRoles.forEach((division) => {
      divisionArray.push(this.constructExitUserDivisionRoleGroup(division));
    });
  }

  setPropertyData() {
    this.propertiesDetails.forEach((data) => {
      this.propertiesAccessLevelHashMap.set(data.propertyId, data.propertyAccess);
      this.propertiesAccessStatusHashMap.set(data.propertyId, data.propertyAccess !== PropertyAccess.Denied);
    });
  }

  removeAndReplaceDivisionUser(index: number) {
    const divisionId = this.divisionsFormArray.value[index]["divisionId"];
    this.removeAndReplaceTeamsOnDivisionDeleted(divisionId, index);
    this.removePropertiesOnDivisionDeleted(divisionId);
  }

  removeAndReplaceTeamsOnDivisionDeleted(divisionId: number, index: number) {
    let dialogOpened = false;
    this.teamsDetails.forEach((team) => {
      if (team.divisionId === divisionId) {
        if (team.teamManagerId === this.componentInputs.userId) {
          team.teamId && this.teamsIdReplacedUsed.push(team.teamId);
          if (!dialogOpened) {
            this.openReplaceDialog(divisionId, index);
            dialogOpened = true;
          }
        }
        team.teamId && this.removeTeamsIdsFromUser.push(team.teamId);
      }
    });

    !dialogOpened && this.divisionsFormArray.removeAt(index);
    const teamsFilter = this.teamIdsControl.value.filter((id) => !this.removeTeamsIdsFromUser.includes(id));
    this.teamIdsControl.setValue(teamsFilter);
  }

  removePropertiesOnDivisionDeleted(divisionId: number) {
    this.propertiesDetails.forEach((property) => {
      if (property.divisionId !== divisionId) {
        this.propertiesControl.setValue([]);
        this.propertiesControl.value.push({ propertyId: property.propertyId, propertyAccess: property.propertyAccess });
      }
    });
  }

  openReplaceDialog(divisionId: number, index?: number) {
    this.dialog
      .open<UserReplacementComponent, IUserReplacementComponentInputs>(UserReplacementComponent, {
        data: {
          divisionId: divisionId,
          warningMessage: "SETTINGS.USERS.CHANGES_TEAM_RELATIONS_MSG",
          titleName: "SETTINGS.USERS.CHANGES_TEAM_RELATIONS",
        },
      })
      .afterClosed()
      .subscribe((data: { replaceId: number }) => {
        if (data.replaceId === undefined) return;
        if (index !== undefined) {
          this.divisionsFormArray.removeAt(index);
        }
        this.replaceUserId = data.replaceId;
        this.deleteDivision = true;
      });
  }

  unSelectTeam(unselectedTeam: ICheckedItem) {
    if (unselectedTeam.row["teamManagerId"] === this.componentInputs.userId && !unselectedTeam.checked) {
      this.openReplaceDialog(unselectedTeam.row["divisionId"]);
      this.teamsIdReplacedUsed.push(unselectedTeam.row["id"]);
      this.teamIdsControl.setValue(this.teamIdsControl.value.filter((id) => !this.teamsIdReplacedUsed.includes(id)));
    }
  }

  updateStepData(event: StepperSelectionEvent) {
    switch (event.selectedIndex) {
      case 1:
        if (event.selectedIndex > event.previouslySelectedIndex) {
          this.updateDivisionIds();
        }
        break;
      default:
        break;
    }
  }
}
