<div class="h-full w-full flex flex-col justify-between">
  <div>
    <div class="sticky top-0 bg-white z-10">
      <div class="flex py-6 px-6 justify-between border-b border-black-200">
        <div class="flex gap-2 items-center sticky top-0">
          <p class="text-xl font-medium">
            {{ "SETTINGS.SYSTEM_CONFIGURATIONS.ADD_END_USER_LANGUAGES" | translate }}
          </p>
          <est-tooltip [tooltip]="'SETTINGS.SYSTEM_CONFIGURATIONS.ADD_END_USER_LANGUAGES'"></est-tooltip>
        </div>
      </div>
      <div class="p-6 flex flex-col gap-6">
        <div class="flex flex-col gap-2">
          <p class="text-sm font-medium">{{ "SETTINGS.SYSTEM_CONFIGURATIONS.SELECT_LANGUAGES.TITLE" | translate }}</p>
          <p class="text-sm font-light">
            {{ "SETTINGS.SYSTEM_CONFIGURATIONS.SELECT_LANGUAGES.DESCRIPTION" | translate }}
          </p>
        </div>
        <div class="flex flex-col gap-2">
          <p class="text-sm font-medium">{{ "SETTINGS.SYSTEM_CONFIGURATIONS.AVAILABLE_LANGUAGES" | translate }}</p>
          <p class="text-sm font-light">
            {{ componentInput.languages.length }} {{ "SETTINGS.SYSTEM_CONFIGURATIONS.LANGUAGES_AVAILABLE" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2 px-6">
      @for (language of componentInput.languages; track language) {
        <est-select-option
          [label]="language.name"
          [value]="language.languageId"
          [initialSelected]="getLanguageOptionState(language)"
          [disabled]="getLanguageOptionState(language)"
          (selectionChanged)="onOptionToggled($event)"
        ></est-select-option>
      }
    </div>
  </div>
  <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
    <est-button
      (click)="closeDialog()"
      [appliedClasses]="'!py-3 !px-6'"
      [label]="'CANCEL'"
      buttonStyleType="button--tertiary"
      buttonType="button"
    />
    <est-button
      (click)="addAndCloseDialog()"
      [appliedClasses]="'!py-3 !px-6'"
      [label]="'ADD'"
      buttonStyleType="button--primary"
      buttonType="button"
      [isLoading]="isFormSubmitting"
      [disabled]="languageIds.length <= 0 || isFormSubmitting"
    />
  </div>
</div>
