import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const FollowUpStatusTableHeader: ITableHeader[] = [
  {
    label: "ACTIVE",
    key: "isInheritanceActive",
    type: TableHeaderType.Toggle,
    isSortable: true,
  },
  {
    label: "TITLE",
    key: "title",
    type: TableHeaderType.Text,
    isSortable: true,
    maxTextLength: 70,
  },
  {
    label: "CREATED",
    key: "createdAt",
    type: TableHeaderType.Date,
    isSortable: true,
  },
  {
    label: "CREATED_BY",
    key: "createdBy",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "DOCUMENTS.SOURCE",
    key: "source",
    type: TableHeaderType.Text,
    isSortable: true,
  },
];
