import { Component } from "@angular/core";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "est-users",
  standalone: true,
  imports: [SubheaderComponent, NavigationTabsComponent, RouterOutlet],
  templateUrl: "./users.component.html",
  styleUrl: "./users.component.scss",
})
export class UsersComponent {
  usersTabs: INavigationTab[] = [
    {
      label: "SETTINGS.USERS.PENDING_USERS",
      routerLink: "pending-users",
    },
    {
      label: "SETTINGS.USERS.ACTIVATED_USERS",
      routerLink: "active-users",
    },
    {
      label: "SETTINGS.USERS.ARCHIVED_USERS",
      routerLink: "archived-users",
    },
    {
      label: "TEAMS.NAME",
      routerLink: "teams",
    },
  ];
}
