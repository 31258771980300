import { Routes } from "@angular/router";
import { LanguageComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/language/language.component";
import { GeneralComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/general/general.component";
import { UnitStatusComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/unit-status/unit-status.component";
import { NotesComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/notes/notes.component";
import { PlacementIdComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/placement-id/placement-id.component";
import { CustomerCategoryComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/customer-category/customer-category.component";
import { FollowUpStatusComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/follow-up-status/follow-up-status.component";
import { FollowUpTaskComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/follow-up-task/follow-up-task.component";

export const systemConfigurationsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "general",
  },
  {
    path: "general",
    component: GeneralComponent,
    data: {
      name: "GENERAL",
    },
  },
  {
    path: "language",
    component: LanguageComponent,
    data: {
      name: "LANGUAGE",
    },
  },
  {
    path: "unit-status",
    component: UnitStatusComponent,
    data: {
      name: "UNIT_STATUS.NAME",
    },
  },
  {
    path: "notes",
    component: NotesComponent,
    data: {
      name: "NOTES",
    },
  },
  {
    path: "placement-id",
    component: PlacementIdComponent,
    data: {
      name: "SETTINGS.SYSTEM_CONFIGURATIONS.PLACEMENT_ID",
    },
  },
  {
    path: "customer-category",
    component: CustomerCategoryComponent,
    data: {
      name: "SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.NAME",
    },
  },
  {
    path: "follow-up-status",
    component: FollowUpStatusComponent,
    data: {
      name: "SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_STATUS.NAME",
    },
  },
  {
    path: "follow-up-task",
    component: FollowUpTaskComponent,
    data: {
      name: "SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_TASK.NAME",
    },
  },
];
