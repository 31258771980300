import { IRoomName } from "@root/data/market/rooms/models/room-name.model";
import { IRoomDropdownOption } from "@root/views/main/units/unit-details/components/unit-layout/components/room-input-fields/room-dropdown-option.interface";
import { ITerraceType } from "@root/data/market/rooms/models/terrace-type.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IBalconyType } from "@root/data/market/rooms/models/balcony-type.model";

export const mapRoomNamesToDropdownOptions = (roomNames: IRoomName[]): IRoomDropdownOption[] => {
  return roomNames.map<IRoomDropdownOption>((roomName) => ({
    label: roomName.name,
    value: roomName.id,
    roomType: roomName.roomType!,
  }));
};

export const mapTerraceTypesToDropdownOptions = (terraceTypes: ITerraceType[]): IDropdownOption[] => {
  return terraceTypes.map<IDropdownOption>((terraceType) => ({
    label: terraceType.name,
    value: terraceType.id,
  }));
};

export const mapBalconyTypesToDropdownOptions = (balconyTypes: IBalconyType[]): IDropdownOption[] => {
  return balconyTypes.map<IDropdownOption>((balconyType) => ({
    label: balconyType.name,
    value: balconyType.id,
  }));
};

export const mapConnectionRoom = (
  roomTypeName: string,
  roomName: string | undefined,
  roomId: number,
  index: number,
): IDropdownOption => {
  return {
    label: `${roomTypeName} #${index + 1}`,
    value: roomId,
    subLabel: roomName,
  };
};
