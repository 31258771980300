import { Component, inject, OnInit } from "@angular/core";
import { IFollowUpStatusPopupInputs } from "@root/views/main/organization/organization-settings/components/system-configurations/components/follow-up-status/components/follow-up-status-popup/follow-up-status-popup-inputs.interface";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FollowUpStatusService } from "@root/data/market/system-configurations/services/follow-up-status.service";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { InputComponent } from "@root/shared/input/input.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule } from "@ngx-translate/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { Observable, tap } from "rxjs";
import { IFollowUpStatus } from "@root/data/market/system-configurations/follow-up-status/models/follow-up-status.model";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-follow-up-status-popup",
  standalone: true,
  imports: [
    ButtonComponent,
    DialogHeaderComponent,
    InputComponent,
    LoadingOverlayComponent,
    MatSlideToggle,
    SmallHeaderComponent,
    TooltipComponent,
    TranslateModule,
  ],
  templateUrl: "./follow-up-status-popup.component.html",
  styleUrl: "./follow-up-status-popup.component.scss",
})
export class FollowUpStatusPopupComponent implements OnInit {
  protected readonly componentInputs: IFollowUpStatusPopupInputs = inject(MAT_DIALOG_DATA);
  readonly #dialogRef: MatDialogRef<FollowUpStatusPopupComponent> = inject(MatDialogRef);
  readonly #followUpStatusService = inject(FollowUpStatusService);
  readonly #fb = inject(NonNullableFormBuilder);
  protected isFormSubmitting: boolean = false;
  protected isPageLoading: boolean = false;

  formGroup = this.#fb.group({
    id: this.#fb.control<number | null>(null),
    title: this.#fb.control("", Validators.required),
    isInheritanceActive: this.#fb.control(false),
  });

  ngOnInit() {
    if (this.componentInputs.isEditPopup) {
      this.isPageLoading = true;
      this.#getFollowUpStatus().subscribe({ complete: () => (this.isPageLoading = false) });
    }
  }

  #getFollowUpStatus(): Observable<IFollowUpStatus> {
    return this.#followUpStatusService
      .getFollowUpStatus({
        sourceLevel: this.componentInputs.sourceLevel,
        ids: { followUpStatusId: this.componentInputs.followUpStatusId! },
        routeId: this.componentInputs.routeId,
      })
      .pipe(
        tap((data: IFollowUpStatus) => {
          this.formGroup.patchValue(data);
        }),
      );
  }

  toggleActivated() {
    this.formGroup.controls.isInheritanceActive.patchValue(!this.formGroup.controls.isInheritanceActive.value);
  }

  submitForm() {
    if (!this.formGroup.valid) {
      markAllControlsAsTouchedAndDirty(this.formGroup);
      return;
    }
    this.isFormSubmitting = true;
    if (this.componentInputs.isEditPopup) {
      this.#updateFollowUpStatus();
    } else {
      this.#createFollowUpStatus();
    }
  }

  #updateFollowUpStatus() {
    this.#followUpStatusService
      .updateFollowUpStatus(
        {
          sourceLevel: this.componentInputs.sourceLevel,
          routeId: this.componentInputs.routeId,
          ids: { followUpStatusId: this.componentInputs.followUpStatusId! },
        },
        this.formGroup.value as IFollowUpStatus,
      )
      .subscribe({
        complete: () => {
          this.isFormSubmitting = false;
          this.closeDialog();
        },
      });
  }

  #createFollowUpStatus() {
    const createObjectRequest: IFollowUpStatus = {
      title: this.formGroup.controls.title.value,
      isInheritanceActive: this.formGroup.controls.isInheritanceActive.value,
    };
    this.#followUpStatusService
      .createFollowUpStatus(createObjectRequest, this.componentInputs.sourceLevel, this.componentInputs.routeId)
      .subscribe({
        complete: () => {
          this.isFormSubmitting = false;
          this.closeDialog();
        },
      });
  }

  protected closeDialog(isCancel: boolean = false): void {
    this.#dialogRef.close(isCancel);
  }
}
