import { DestroyRef, effect, inject, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, NonNullableFormBuilder, Validators } from "@angular/forms";
import { BaseStepperComponent } from "../stepper/base-stepper.component";
import { ITeamMember } from "../../interfaces/team-member.interface";
import { SnackbarService } from "../../ui-services/snackbar.service";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { Router } from "@angular/router";
import { TeamsService } from "@root/data/market/teams/services/teams.service";
import { TeamsCommunicationService } from "@root/views/main/organization/organization-settings/components/users/teams/teams-communication.service";

export abstract class BaseTeamFormComponent extends BaseStepperComponent {
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly matDialog = inject(MatDialog);
  protected readonly teamService = inject(TeamsService);
  protected readonly authService = inject(AuthService);
  protected readonly snackBarService = inject(SnackbarService);
  protected readonly teamsCommunicationService = inject(TeamsCommunicationService);
  formBuilder = inject(NonNullableFormBuilder);
  isSelectedTeamMangerId = false;
  isRequestLoading = false;
  isPageLoading = true;
  isTableLoading = false;
  router = inject(Router);
  dateOfCreation = new Date().toISOString();
  selectedDivisionId = signal<number | undefined>(undefined);
  teamLeaderFormControl = this.formBuilder.control<number[]>([]);
  teamMemberFormControl = this.formBuilder.control<number[]>([]);
  controlMapper: Map<string, FormControl<any[] | null>> = new Map([
    ["teamLeader", this.teamLeaderFormControl],
    ["teamMember", this.teamMemberFormControl],
  ]);
  profilePicture = this.formBuilder.control<File | null | string>(null);

  divisionId = this.formBuilder.control<number | undefined>(undefined, Validators.required);
  name = this.formBuilder.control<string>("", Validators.required);
  teamManagerId = this.formBuilder.control<number | null>({ value: null, disabled: true }, Validators.required);
  teamUsers = this.formBuilder.control<ITeamMember[]>([]);
  teamFormGroup = this.formBuilder.group({
    profilePicture: this.profilePicture,
    divisionId: this.divisionId,
    name: this.name,
    teamManagerId: this.teamManagerId,
    teamUsers: this.teamUsers,
  });
  readonly #enableMangerIdoOnSelectedDivision$ = effect(() => {
    if (this.selectedDivisionId()) {
      this.teamManagerId.enable();
    }
  });
  resetTeamForm() {
    this.teamMemberFormControl.reset();
    this.teamLeaderFormControl.reset();
    this.matStepper.reset();
    this.teamManagerId.disable();
  }
  closeDialog() {
    this.matDialog.closeAll();
  }
}
