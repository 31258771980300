<div [ngClass]="{ 'border-2 rounded-lg mt-6 p-6 bg-black-50': !isSeriesEvent() }">
  @defer (when  eventInstanceComputedSignal()) {
    <div class="flex items-end">
      @if (isSeriesEvent()) {
        <mat-icon class="self-center mr-6 text-3xl pb-8">
          @if (panelOpenState()) {
            expand_less
          } @else {
            expand_more
          }
        </mat-icon>
      }

      <div class="w-full grid grid-cols-5 gap-4">
        <!-- First Col -->
        @if (isSeriesEvent()) {
          <div class="flex flex-col gap-2 font-light text-sm">
            <p>
              {{ formatDateToGetWithDay(eventInstance().eventDate) }}
            </p>
            <p>{{ "EVENTS.DETAILS.INSTANCE.TIME" | translate }}: {{ getEventStartTime() }} - {{ getEventEndTime() }}</p>
          </div>
        }

        <!-- Second Col -->
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <p class="font-light text-sm">
              {{ "EVENTS.DETAILS.INSTANCE.CONFIRMED" | translate }}:
              @if (withBookingComputedSignal()) {
                {{ eventInstance().confirmedCustomers }}
              } @else {
                {{ "EVENTS.DETAILS.INSTANCE.NO_BOOKING" | translate }}
              }
            </p>
          </div>
          <div class="flex gap-2">
            <p class="font-light text-sm">
              {{ "EVENTS.DETAILS.INSTANCE.AVAILABLE" | translate }}:
              @if (withBookingComputedSignal()) {
                {{ eventInstance().availableAppointments }}
              } @else {
                {{ "EVENTS.DETAILS.INSTANCE.NO_BOOKING" | translate }}
              }
            </p>
          </div>
        </div>

        <!-- Third Col -->
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            @if (eventInstance().attendedCustomers > 0) {
              <button
                class="outline-none"
                type="button"
                (click)="openCustomerAttendanceModal(customerListingType.Attended); $event.stopPropagation()"
              >
                <img
                  ngSrc="assets/images/icons/linked-icon.svg"
                  [alt]="'ICON' | translate"
                  priority
                  width="14"
                  height="16"
                />
              </button>
            }
            <p class="font-light text-sm">
              {{ "EVENTS.DETAILS.INSTANCE.ATTENDED" | translate }}:
              @if (eventInstance().attendedCustomers > 0) {
                {{ eventInstance().attendedCustomers }}
              } @else {
                <span class="text-black-500">{{ "EVENTS.DETAILS.INSTANCE.NO_DATA" | translate }}</span>
              }
            </p>
          </div>
          <div class="flex gap-2">
            @if (eventInstance().dropoutCustomers > 0) {
              <button
                class="outline-none"
                type="button"
                (click)="openCustomerAttendanceModal(customerListingType.Dropout); $event.stopPropagation()"
              >
                <img
                  ngSrc="assets/images/icons/linked-icon.svg"
                  [alt]="'ICON' | translate"
                  priority
                  width="14"
                  height="16"
                />
              </button>
            }
            <p class="font-light text-sm">
              {{ "EVENTS.DETAILS.INSTANCE.DROPOUT" | translate }}:
              @if (eventInstance().dropoutCustomers > 0) {
                {{ eventInstance().dropoutCustomers }}
              } @else {
                <span class="text-black-500">{{ "EVENTS.DETAILS.INSTANCE.NO_DATA" | translate }}</span>
              }
            </p>
          </div>
        </div>

        <!-- Fourth Col -->
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            @if (eventInstance().contractsMade > 0) {
              <img
                ngSrc="assets/images/icons/linked-icon.svg"
                [alt]="'ICON' | translate"
                priority
                width="14"
                height="16"
              />
            }
            <p class="font-light text-sm">
              {{ "EVENTS.DETAILS.INSTANCE.CONTRACTS" | translate }}:
              @if (eventInstance().contractsMade > 0) {
                {{ eventInstance().contractsMade }}
              } @else {
                <span class="text-black-500">{{ "EVENTS.DETAILS.INSTANCE.NO_DATA" | translate }}</span>
              }
            </p>
          </div>
          <div class="flex gap-2">
            @if (eventInstance().screeningsMade > 0) {
              <img
                ngSrc="assets/images/icons/linked-icon.svg"
                [alt]="'ICON' | translate"
                priority
                width="14"
                height="16"
              />
            }
            <p class="font-light text-sm">
              {{ "EVENTS.DETAILS.INSTANCE.SCREENING" | translate }}:
              @if (eventInstance().screeningsMade > 0) {
                {{ eventInstance().screeningsMade }}
              } @else {
                <span class="text-black-500">{{ "EVENTS.DETAILS.INSTANCE.NO_DATA" | translate }}</span>
              }
            </p>
          </div>
        </div>

        <!-- Fifth Col -->
        @if (isSeriesEvent()) {
          <div class="flex flex-col gap-2">
            <p class="font-light text-sm">{{ eventInstance().responsibleAgent }}</p>
          </div>
        }
      </div>

      @if (isSeriesEvent()) {
        <div>
          <button
            type="button"
            [matMenuTriggerFor]="actionsMenu"
            [attr.aria-label]="'DROPDOWN_MENU' | translate"
            (click)="$event.stopPropagation()"
            class="cursor-pointer flex items-center bg-white rounded border border-black-300"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <button mat-menu-item type="button" (click)="editEventDetails()">
              {{ "EVENTS.EDIT_EVENT" | translate }}
            </button>
            <button mat-menu-item type="button">
              {{ "EVENTS.DELETE_EVENT" | translate }}
            </button>
          </mat-menu>
        </div>
      }
    </div>
  }
</div>
