<div class="flex flex-col gap-4">
  @for (formGroup of formArray().controls; track $index) {
    <div>
      <p class="text-[0.5rem] font-medium text-black-500">
        {{ "EVENTS.NUMBER_OF_ATTENDANTS_PER_TIMESLOT" | translate }}
      </p>
      <est-postfix-text-input
        label=""
        [control]="formGroup.controls.attendants"
        applyNegativeValuesValidation
        type="number"
        [minValue]="0"
        [maxValue]="10000"
        [postfixText]="
          'EVENTS.SLOT_TIME'
            | translate
              : {
                  startTime: formGroup.controls.startTime.value | formatTime,
                  endTime: formGroup.controls.endTime.value | formatTime,
                  index: $index + 1
                }
        "
      ></est-postfix-text-input>
    </div>
  }
  <p [innerHTML]="'EVENTS.ATTENDEES_COUNT' | translate: { attendees: attendeesTotalCount }" class="text-sm"></p>
</div>
