<div class="flex gap-8 mb-4 items-start">
  <div>
    <est-small-header
      heading="PROPERTY_FACILITIES.FACILITIES.NAME"
      subheading="PROPERTY_FACILITIES.FACILITIES.PROPERTY_FACILITIES_DESCRIPTION"
      subheadingAppliedClasses="!max-w-full"
      tooltip="PROPERTY_FACILITIES.FACILITIES.NAME"
    ></est-small-header>
    <p class="text-sm font-light mt-4">
      {{ "PROPERTY_FACILITIES.FACILITIES.PROPERTY_FACILITIES_SECOND_DESCRIPTION" | translate }}
    </p>
  </div>
  <est-button
    (click)="openAddPropertyFacilityModal()"
    buttonStyleType="button--primary"
    buttonType="button"
    imageAlt="PROPERTY_FACILITIES.FACILITIES.ADD_FACILITY"
    imageSrc="assets/images/icons/plus.svg"
    label="PROPERTY_FACILITIES.FACILITIES.ADD_FACILITY"
  ></est-button>
</div>
<div class="flex gap-2 justify-end mb-6 flex-wrap">
  <est-select-field
    [dropDownOptions]="locationsOptions"
    [predefinedOptions]="filtersPredefinedOptions"
    [selectMultipleOptions]="false"
    [selectSignal]="locationSignal"
    appliedClass="!w-[9.375rem]"
    dropDownLabel="LOCATION"
  ></est-select-field>
  <est-select-field
    [dropDownOptions]="categoriesOptions"
    [predefinedOptions]="filtersPredefinedOptions"
    [selectMultipleOptions]="false"
    [selectSignal]="categoryIdSignal"
    appliedClass="!w-[9.375rem]"
    dropDownLabel="CATEGORY"
  ></est-select-field>
  <est-search-input [searchSignal]="searchSignal"></est-search-input>
</div>
<est-small-list-table
  (toggleItem)="togglePropertyFacility($event.row)"
  [actions]="tableActions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [iconMenuComponentType]="FacilityDetailsComponent"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
