import { Component, DestroyRef, effect, EffectRef, inject, signal, WritableSignal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { MatMenu, MatMenuItem } from "@angular/material/menu";
import { NgOptimizedImage } from "@angular/common";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { DocumentService } from "@root/data/market/documents/services/document.service";
import {
  constructInitialSignalPaginatedResponse,
  copyPaginatedSignalResponse,
} from "@root/shared/utilities/signals.utilities";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDocument } from "@root/data/market/documents/models/documents.model";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { MatDialog } from "@angular/material/dialog";
import { DocumentUploadDialogComponent } from "@root/shared/document-upload-dialog/document-upload-dialog.component";
import { EditDocumentComponent } from "@root/views/main/organization/organization-documents/components/edit-document/edit-document.component";
import { finalize } from "rxjs";
import { IUploadMultiFilesInputs } from "@root/shared/interfaces/upload-multi-files-component-inputs.interface";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { DocumentOrganisationTableHeader } from "@root/views/main/organization/organization-documents/organisation-table-header";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { showDocumentOnNewTab } from "@root/data/market/documents/utilities/documents.utilities";

@Component({
  selector: "est-organization-documents",
  standalone: true,
  imports: [
    TranslateModule,
    SmallListTableComponent,
    MatMenu,
    MatMenuItem,
    NgOptimizedImage,
    PaginationComponent,
    SearchInputComponent,
    SelectFieldComponent,
    SelectSearchFieldComponent,
    SmallHeaderComponent,
    NavigationTabsComponent,
    RouterOutlet,
    SubheaderComponent,
    ButtonComponent,
  ],
  templateUrl: "./organization-documents.component.html",
  styleUrl: "./organization-documents.component.scss",
})
export class OrganizationDocumentsComponent extends BasePaginatedTableWithSearchComponent {
  readonly #documentService = inject(DocumentService);
  readonly #dialogService = inject(DialogService);
  readonly snackBar = inject(SnackbarService);
  dialog = inject(MatDialog);
  readonly #destroyRef = inject(DestroyRef);
  headers: ITableHeader[] = DocumentOrganisationTableHeader;
  restRow: WritableSignal<null | number> = signal(null);
  #isDeletingDocument = signal(false);
  override paginatedData: ISignalPaginatedResponse<IDocument> = constructInitialSignalPaginatedResponse();
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "documentName",
    sortDescending: false,
  });
  readonly loadDataEffect$: EffectRef = effect(() => this.#loadDocumentsWithParams());
  override actions: ITableAction[] = [
    {
      callbackFn: (row: ISmallListTableInput) => this.viewDocument(row),
      labelFn: () => "DOCUMENTS.VIEW_DOCUMENT",
    },
    {
      callbackFn: (row: ISmallListTableInput) => this.#openEditDialog(row.id),
      labelFn: () => "DOCUMENTS.EDIT_DOCUMENT_DETAILS",
    },
    {
      callbackFn: (row: ISmallListTableInput) => this.#openDeleteDocumentDialog(row),
      labelFn: () => "DOCUMENTS.DELETE_DOCUMENT",
    },
  ];

  loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#documentService
      .getPaginatedSmallTableInputDocuments(params, SourceLevel.Organisation)
      .subscribe((paginatedDocuments) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedDocuments);
        this.isTableLoading = false;
      });
  }

  viewDocument(row: ISmallListTableInput) {
    this.#documentService
      .viewDocument({
        sourceLevel: SourceLevel.Organisation,
        ids: { documentId: row.id.toString() },
      })
      .subscribe({
        next: (file: Blob) => {
          showDocumentOnNewTab(file);
          this.restRow.set(null);
        },
        error: () => {
          this.restRow.set(null);
          this.snackBar.open(SnackbarType.Error);
        },
      });
  }

  #openDeleteDocumentDialog(row: ISmallListTableInput): void {
    this.#dialogService.open(
      {
        title: "DOCUMENTS.DELETE_DOCUMENT",
        callBack: () => this.#deleteDocument(row.id),
        submitLabel: "DELETE",
        isInputIncluded: false,
        descriptions: [
          "DOCUMENTS.DELETE_DOCUMENT_EVERYWHERE_CONFIRMATION",
          "DOCUMENTS.DOCUMENT_NOT_DELETED_IF_USED_CONFIRMATION",
          "DOCUMENTS.DELETE_CONFIRMATION",
        ],
        isSubmitLoading: this.#isDeletingDocument,
      },
      "confirmation",
    );
  }

  #deleteDocument(id: number): void {
    this.#isDeletingDocument.set(true);
    this.#documentService
      .deleteDocument({
        sourceLevel: SourceLevel.Organisation,
        ids: { documentId: id.toString() },
      })
      .pipe(
        finalize(() => {
          this.#isDeletingDocument.set(false);
          this.#dialogService.close();
        }),
      )
      .subscribe(() => this.#loadDocumentsWithParams());
  }

  #loadDocumentsWithParams(): void {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
    });
  }
  readonly #matDialog = inject(MatDialog);

  openUploadDocumentsModal(): void {
    this.#matDialog
      .open<DocumentUploadDialogComponent, IUploadMultiFilesInputs>(DocumentUploadDialogComponent, {
        data: {
          sourceLevel: SourceLevel.Organisation,
        },
        width: "45rem",
        maxHeight: "70dvh",
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((isDocumentUploaded) => {
        if (isDocumentUploaded) {
          this.#loadDocumentsWithParams();
        }
      });
  }

  #openEditDialog(id: number) {
    this.dialog
      .open(EditDocumentComponent, {
        data: {
          ids: {
            documentId: id,
          },
          sourceLevel: SourceLevel.Organisation,
        },
        width: "40.5rem",
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((isEdit) => {
        if (isEdit) {
          this.#loadDocumentsWithParams();
        }
      });
  }

  updateDocumentInheritance(row: { checked: boolean; row: any }) {
    this.#documentService
      .toggleDocumentInheritance({
        sourceLevel: SourceLevel.Organisation,
        ids: { documentId: row.row.id },
      })
      .subscribe();
  }
}
