import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IPropertyEventInstance } from "@root/data/market/properties/models/property-event-instance.model";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { formatDateToGetWithDay, formatDateToShortTime } from "@root/shared/utilities/date.utilities";
import { CustomerAttendanceModalComponent } from "../../customer-attendance-modal/customer-attendance-modal.component";
import { CustomerListingType } from "../../enums/customer-listing-type.enum";
import { EventDetailsSignalStateService } from "../services/event-details-signal-state.service";

@Component({
  selector: "est-event-instance-details",
  standalone: true,
  imports: [
    SubheaderComponent,
    NgClass,
    TranslateModule,
    MatIcon,
    NgOptimizedImage,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ],
  templateUrl: "./event-instance-details.component.html",
  styleUrl: "./event-instance-details.component.scss",
})
export class EventInstanceDetailsComponent {
  eventInstance = input.required<IPropertyEventInstance>();
  panelOpenState = input(false);
  readonly #router = inject(Router);
  readonly #dialog = inject(MatDialog);
  readonly #eventDetailsSignalStateService = inject(EventDetailsSignalStateService);

  protected readonly formatDateToGetWithDay = formatDateToGetWithDay;
  protected readonly customerListingType = CustomerListingType;

  eventId = this.#eventDetailsSignalStateService.eventId;
  propertyId = this.#eventDetailsSignalStateService.propertyId;
  eventDetails = this.#eventDetailsSignalStateService.eventDetails;
  isSeriesEvent = this.#eventDetailsSignalStateService.isSeriesEvent;
  readonly withBookingComputedSignal = computed(() => this.eventDetails()?.bookingType !== null);
  eventInstanceComputedSignal = computed(() => {
    return this.eventInstance() || this.#eventDetailsSignalStateService.paginatedEventInstances.results()[0];
  });

  getEventStartTime(): string {
    const startDate: Date = new Date(this.eventInstance().startTime);
    return formatDateToShortTime(startDate);
  }
  getEventEndTime(): string {
    const endDate: Date = new Date(this.eventInstance().endTime);
    return formatDateToShortTime(endDate);
  }
  editEventDetails() {
    this.#router.navigate([`properties/${this.propertyId()}/events/edit/${this.eventInstance()!.id}`]);
  }
  openCustomerAttendanceModal(listingType: CustomerListingType) {
    this.#dialog.open(CustomerAttendanceModalComponent, {
      minWidth: "75dvw",
      maxHeight: "95dvh",
      data: {
        listingType,
        propertyId: this.propertyId(),
        eventId: this.eventId(),
        eventInstanceId: this.eventInstanceComputedSignal().id,
      },
    });
  }
}
