import { Component, inject } from "@angular/core";
import { BaseDuplicatePackageComponent } from "@root/shared/abstracts/base-duplicate-package/base-duplicate-package.component";
import { BASE_DUPLICATE_PACKAGE_IMPORTS } from "@root/shared/abstracts/base-duplicate-package/base-duplicate-package.imports";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { generateTranslatedDropdownOptionsFromEnum } from "@root/shared/utilities/dropdown-options.utilities";
import { PropertyMediaPackageDuplicationType } from "@root/views/main/property/property-marketing/enums/property-media-package-duplication-type.enum";
import { PropertyMediaPackageDuplicationTypeTranslationMapper } from "@root/views/main/property/property-marketing/mappers/property-media-package-duplication-type-translation.mapper";
import { PropertiesMediaPackagesService } from "@root/data/market/properties/services/properties-media-packages.service";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";

@Component({
  selector: "est-duplicate-property-media-package",
  standalone: true,
  imports: BASE_DUPLICATE_PACKAGE_IMPORTS,
  templateUrl: "/src/app/shared/abstracts/base-duplicate-package/base-duplicate-package.component.html",
  styleUrl: "./duplicate-property-media-package.component.scss",
})
export class DuplicatePropertyMediaPackageComponent extends BaseDuplicatePackageComponent<PropertyMediaPackageDuplicationType> {
  override readonly duplicationOptions: IDropdownOption[] = generateTranslatedDropdownOptionsFromEnum(
    PropertyMediaPackageDuplicationType,
    PropertyMediaPackageDuplicationTypeTranslationMapper,
  );
  readonly #propertiesMediaPackagesService = inject(PropertiesMediaPackagesService);
  readonly #snackbarService = inject(SnackbarService);

  override newPackageSubHeaderTranslationKey = "PACKAGES.MEDIA_PACKAGES.DUPLICATE_PACKAGE.TO_DUPLICATE_A_MEDIA";

  override duplicatePackage() {
    if (!this.form.valid) {
      markAllControlsAsTouchedAndDirty(this.form);
      return;
    }
    this.isLoading.set(true);
    this.#propertiesMediaPackagesService
      .duplicatePropertyMediaPackage(
        this.componentInputs.propertyId,
        this.componentInputs.originalPackageId,
        this.form.value,
      )
      .subscribe({
        next: () => {
          this.matDialog.closeAll();
          this.#snackbarService.open(SnackbarType.Success);
        },
        error: () => this.isLoading.set(false),
      });
  }
}
