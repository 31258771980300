<div class="mb-6">
  <est-small-header
    heading="PROPERTY_FACILITIES.NAME"
    subheading="UNIT_FACILITIES.UNIT_PROPERTY_FACILITIES_DESCRIPTION"
    subheadingAppliedClasses="!max-w-[75%]"
    tooltip="PROPERTY_FACILITIES.NAME"
  />
</div>
<est-small-list-table
  (toggleItem)="toggleFacility($event.checked, $event.row)"
  [actions]="actions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [iconMenuComponentType]="FacilityDetailsComponent"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
