import { ICreateEditPropertyMediaPackagePayload } from "@root/data/market/properties/models/create-edit-property-media-package-payload.model";

export const buildPropertyMediaPackageFormData = (
  payload: Partial<ICreateEditPropertyMediaPackagePayload>,
): FormData => {
  const formData = new FormData();
  Object.keys(payload).forEach((k) => {
    const key = k as keyof Partial<ICreateEditPropertyMediaPackagePayload>;
    if (key !== "medias") if (payload[key]!.toString()) formData.append(key, payload[key]!.toString());
  });
  payload.medias?.forEach((media: any, index) => {
    Object.keys(media).forEach((mediaKey) => {
      if (media[mediaKey]) formData.append(`medias[${index}][${mediaKey}]`, media[mediaKey]);
    });
  });
  return formData;
};
