import { Component, DestroyRef, effect, inject, OnInit, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { SetCurrentDivisionService } from "@root/data/market/division/utilities/set-current-division.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { LargeListTableComponent } from "@root/shared/large-list-table/large-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { DeleteDivisionComponent } from "./delete-division/delete-division.component";
import { ORGANIZATION_DIVISION_HEADER } from "@root/views/main/organization/organization-divisions/organization-division.header";
import { EditDivisionComponent } from "@root/views/main/organization/organization-divisions/edit-division/edit-division.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { OrganisationDivisionsCommunicationService } from "@root/views/main/organization/organization-divisions/services/organisation-details-communication.service";
import { NgOptimizedImage } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { CreateDivisionFormComponent } from "./form-create-division/create-division-form.component";

@Component({
  selector: "est-organization-divisions",
  standalone: true,
  imports: [
    SubheaderComponent,
    LargeListTableComponent,
    PaginationComponent,
    SearchInputComponent,
    TranslateModule,
    MatIconModule,
    NgOptimizedImage,
  ],
  templateUrl: "./organization-divisions.component.html",
  styleUrl: "./organization-divisions.component.scss",
})
export class OrganizationDivisionsComponent extends BasePaginatedTableWithSearchComponent implements OnInit {
  readonly #dialog = inject(MatDialog);
  readonly #divisionService = inject(DivisionService);
  readonly #setCurrentDivisionService = inject(SetCurrentDivisionService);
  readonly #authService = inject(AuthService);
  readonly #route = inject(Router);
  readonly #organisationDivisionsCommunicationService = inject(OrganisationDivisionsCommunicationService);
  readonly #destroyRef = inject(DestroyRef);
  headers: ITableHeader[] = ORGANIZATION_DIVISION_HEADER.map((header) => {
    if (header.key === "name") {
      return {
        ...header,
        routerLink: (id: any) => this.#changeAndNavigateToSelectedDivision(id),
      };
    }
    return header;
  });

  override actions: ITableAction[] = [
    {
      labelFn: () => "DIVISIONS.GO_TO_DIVISION",
      callbackFn: (row: ILargeListTableInput) => this.#changeAndNavigateToSelectedDivision(row.id),
    },
    {
      labelFn: () => "DIVISIONS.EDIT_DIVISION",
      callbackFn: (row) => this.openEditDivisionModal(row.id),
    },
    {
      labelFn: () => "DIVISIONS.DEACTIVATE_ACCESS",
      callbackFn: () => {},
    },
    {
      labelFn: () => "DIVISIONS.DELETE_DIVISION.BUTTON_LABEL",
      callbackFn: (row) => this.#openDeleteDivisionModal(row.id),
    },
  ];
  sortingFilterSignal = signal<ITableSortingFilter>({
    sortBy: "name",
    sortDescending: false,
  });

  ngOnInit() {
    this.#subscribeToUpdateDivisionPage$();
  }

  loadDataEffect$ = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
    });
  });

  #changeAndNavigateToSelectedDivision(selectedDivisionId: number) {
    const divisionRoles = this.#authService.getUserDivisionRoles();
    const selectedDivision = divisionRoles?.find((divisionRole) => divisionRole.divisionId === selectedDivisionId);
    if (selectedDivision) {
      this.#setCurrentDivisionService.setCurrentUserDivisionRole(selectedDivision, false);
      this.#route.navigate(["division"]);
    }
  }

  loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#divisionService.getPaginatedTableInputDivisionsOverview(params).subscribe((paginatedDivisions) => {
      copyPaginatedSignalResponse(this.paginatedData, paginatedDivisions);
      this.isTableLoading = false;
    });
  }

  #openDeleteDivisionModal(id: number) {
    this.#dialog.open(DeleteDivisionComponent, {
      data: {
        divisionId: id,
      },
      maxWidth: "28rem",
    });
  }
  openCreateDivisionModal() {
    this.#dialog.open(CreateDivisionFormComponent, {
      minWidth: "75dvw",
      maxHeight: "95dvh",
    });
  }
  openEditDivisionModal(id: number) {
    this.#dialog.open(EditDivisionComponent, {
      minWidth: "75dvw",
      maxHeight: "95dvh",
      data: {
        divisionId: id,
      },
    });
  }
  #subscribeToUpdateDivisionPage$(): void {
    this.#organisationDivisionsCommunicationService.reloadOrganisationDivisionsPage$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.loadData({
          sortBy: this.sortingFilterSignal().sortBy,
          sortDescending: this.sortingFilterSignal().sortDescending,
          pageSize: this.paginatedData.pageSize(),
          pageNumber: this.paginatedData.currentPage(),
          search: this.searchSignal(),
        });
      });
  }
}
