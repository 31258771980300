import { Component, computed, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ICreatePropertyEventConfirmationInputs } from "@root/shared/property-event-confirmation-popup/property-event-confirmation-inputs.interface";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { PropertyEventTypeTranslationMapper } from "@root/views/main/property/property-events/mappers/property-event-type-translation.mapper";
import { formatAddressValue } from "@root/shared/utilities/address.utilities";
import { DateTime } from "luxon";
import { PropertyEventBookingTypeTranslationMapper } from "@root/views/main/property/property-events/mappers/property-event-booking-type-translation.mapper";
import { ButtonComponent } from "@root/shared/button/button.component";
import { RepetitionCycle } from "@root/shared/enums/repitition-cycle";
import {
  getEventRepetitionEndDate,
  getEventRepetitionStartDate,
} from "@root/shared/utilities/event.repeitition.utilities";

@Component({
  selector: "est-property-event-confirmation-popup",
  standalone: true,
  imports: [TranslateModule, TooltipComponent, ButtonComponent],
  templateUrl: "./property-event-confirmation-popup.component.html",
  styleUrl: "./property-event-confirmation-popup.component.scss",
})
export class PropertyEventConfirmationPopupComponent {
  readonly componentInputs: ICreatePropertyEventConfirmationInputs = inject(MAT_DIALOG_DATA);
  readonly matDialog = inject(MatDialog);
  readonly dateString = computed(() => DateTime.fromISO(this.componentInputs.formValue.startTime).toFormat("DDDD"));

  readonly timeString = computed(() => {
    const startTime = DateTime.fromISO(this.componentInputs.formValue.startTime).toFormat("T");
    const endTime = DateTime.fromISO(this.componentInputs.formValue.endTime).toFormat("T");
    return `${startTime} - ${endTime}`;
  });

  readonly bookingString = computed(() => {
    if (!this.componentInputs.formValue.booking) {
      return "NO";
    }
    return PropertyEventBookingTypeTranslationMapper.get(this.componentInputs.formValue.booking.type)!;
  });

  readonly agentsName = computed(() => {
    const names: string[] = [];
    for (const entry of this.componentInputs.namesHashmap) {
      names.push(entry[1]);
    }
    if (names.length === 0) {
      return "-";
    }
    return names.join(", ");
  });
  protected readonly PropertyEventTypeTranslationMapper = PropertyEventTypeTranslationMapper;
  protected readonly formatAddressValue = formatAddressValue;
  protected readonly RepetitionCycle = RepetitionCycle;
  protected readonly DateTime = DateTime;

  get repeatedEventStartDate() {
    return getEventRepetitionStartDate({
      startDate: this.componentInputs.formValue.startTime,
      repetitionCycle: this.componentInputs.formValue.repetition!.repetitionCycle,
    });
  }

  get repeatedEventEndDate() {
    return getEventRepetitionEndDate({
      endDate: this.componentInputs.formValue.repetition!.endDate,
      startDate: this.componentInputs.formValue.startTime,
      repetitionCycle: this.componentInputs.formValue.repetition!.repetitionCycle,
      repetitionEndType: this.componentInputs.formValue.repetition!.endType,
      occurrences: this.componentInputs.formValue.repetition!.occurrences,
    });
  }
}
