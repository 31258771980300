<div class="max-w-screen-max-screen">
  <div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
    @if (isMainDetailsLoading()) {
      <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
    } @else {
      <est-event-main-details></est-event-main-details>

      @if (eventDetails()?.repetitionCycle) {
        <est-series-events-instances-wrapper></est-series-events-instances-wrapper>
      } @else {
        <est-single-event-instance-wrapper></est-single-event-instance-wrapper>
      }
    }
  </div>
</div>
