import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
  name: "formatTime",
  standalone: true,
})
export class FormatTimePipe implements PipeTransform {
  transform(value: string): string {
    const dateTime = DateTime.fromISO(value);
    if (!dateTime.isValid) throw new Error(dateTime.invalidExplanation!);
    return dateTime.toFormat("T");
  }
}
