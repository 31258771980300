import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-toolbox",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./toolbox.component.html",
  styleUrl: "./toolbox.component.scss",
})
export class ToolboxComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "TOOLBOX.IMPORT_DATA.NAME",
      routerLink: "import",
    },
    {
      label: "TOOLBOX.EXPORT_DATA.NAME",
      routerLink: "export",
    },
  ];
}
