import { DateTime } from "luxon";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { END_DATE_BEFORE_START_DATE_ERROR_KEY } from "@root/shared/constants/error-keys.constants";
import { markControlAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";

export function DateNotBeforeTodayValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const inputDate = DateTime.fromISO(control.value);
    const today = DateTime.local().startOf("day");
    return inputDate < today ? { dateBeforeToday: { value: control.value } } : null;
  };
}

export function endDateBeforeStartDateValidator(startDateKey: string, endDateKey: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const startDate = control.get(startDateKey);
    const endDate = control.get(endDateKey);
    if (!startDate || !endDate) {
      return null;
    }
    if ((startDate.value && !endDate.value) || (!startDate.value && endDate.value)) {
      startDate?.setErrors({ [END_DATE_BEFORE_START_DATE_ERROR_KEY]: true });
      endDate?.setErrors({ [END_DATE_BEFORE_START_DATE_ERROR_KEY]: true });
      startDate && markControlAsTouchedAndDirty(startDate);
      endDate && markControlAsTouchedAndDirty(endDate);
      return { [END_DATE_BEFORE_START_DATE_ERROR_KEY]: true };
    }
    const startErrors = startDate?.errors;
    const endErrors = endDate?.errors;
    startErrors && delete startErrors[END_DATE_BEFORE_START_DATE_ERROR_KEY];
    endErrors && delete endErrors[END_DATE_BEFORE_START_DATE_ERROR_KEY];
    if (startErrors && Object.keys(startErrors).length) {
      startDate?.setErrors(startErrors);
    } else {
      startDate?.setErrors(null);
    }
    if (endErrors && Object.keys(endErrors).length) {
      endDate?.setErrors(endErrors);
    } else {
      endDate?.setErrors(null);
    }
    if (startDate?.disabled || endDate?.disabled) {
      return null;
    }
    const startDateValue = DateTime.fromISO(startDate?.value);
    const endDateValue = DateTime.fromISO(endDate?.value);

    if (!startDateValue.isValid || !endDateValue.isValid) {
      return null;
    }

    if (endDateValue < startDateValue) {
      startDate?.setErrors({ [END_DATE_BEFORE_START_DATE_ERROR_KEY]: true });
      endDate?.setErrors({ [END_DATE_BEFORE_START_DATE_ERROR_KEY]: true });
      startDate && markControlAsTouchedAndDirty(startDate);
      endDate && markControlAsTouchedAndDirty(endDate);
      return { [END_DATE_BEFORE_START_DATE_ERROR_KEY]: true };
    }
    return null;
  };
}
