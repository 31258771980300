import { inject } from "@angular/core";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { HttpInterceptorFn } from "@angular/common/http";
import { addPatchJsonParser } from "@root/shared/utilities/form.utilities";

export const patchRequestInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  if (req.method === "PATCH") {
    const newReq = req.clone({
      headers: req.headers
        .set("Content-Type", "application/json-patch+json")
        .set("X-division-id", authService.currentUserDivisionRole?.divisionId.toString() || ""),
      body: addPatchJsonParser(req.body),
    });
    return next(newReq);
  }
  return next(req);
};
