<est-dialog-header
  label="TERMS_AND_CONDITIONS.PET_POLICY.ADD_PET_TYPE"
  tooltipLabel="TERMS_AND_CONDITIONS.PET_POLICY.ADD_PET_TYPE"
></est-dialog-header>
<form (ngSubmit)="submitForm()" class="relative pb-24">
  <div class="px-6">
    <p class="mt-6 mb-2 font-medium text-sm">{{ "TERMS_AND_CONDITIONS.PET_POLICY.SELECT_PET_TYPE" | translate }}</p>
    <p class="mb-4 font-light text-sm">
      {{ "TERMS_AND_CONDITIONS.PET_POLICY.SELECT_PET_TYPE_DESCRIPTION" | translate }}
    </p>
    @if (isEdit) {
      <est-select-field
        [control]="formControls.petTypeId"
        [dropDownOptions]="allowedPetTypesOptions"
        [selectMultipleOptions]="false"
        dropDownLabel="TERMS_AND_CONDITIONS.PET_POLICY.ALLOWED_PET_TYPE"
      ></est-select-field>
    } @else {
      <est-select-field
        [control]="formControls.petTypeId"
        [disabledIds]="assignedPetTypesIds"
        [dropDownOptions]="allowedPetTypesOptions"
        [selectMultipleOptions]="false"
        dropDownLabel="TERMS_AND_CONDITIONS.PET_POLICY.ALLOWED_PET_TYPE"
      ></est-select-field>
    }
  </div>
  <hr class="my-6 inset-y-0 bg-black-100" />
  <div class="px-6">
    <div class="flex items-center gap-2 mb-2">
      <p class="font-medium text-sm">{{ "TERMS_AND_CONDITIONS.PET_POLICY.DEFINE_RESTRICTIONS" | translate }}</p>
      <est-tooltip tooltip="TERMS_AND_CONDITIONS.PET_POLICY.DEFINE_RESTRICTIONS"></est-tooltip>
    </div>
    <div class="flex flex-col gap-4 mb-4 font-light text-sm">
      <mat-slide-toggle [formControl]="formControls.isApplicationRequired"
        >{{ "TERMS_AND_CONDITIONS.PET_POLICY.APPLICATION_REQUIRED" | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle [formControl]="formControls.isSizeRestricted"
        >{{ "TERMS_AND_CONDITIONS.PET_POLICY.SIZE_RESTRICTION" | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle [formControl]="formControls.isSpeciesRestricted"
        >{{ "TERMS_AND_CONDITIONS.PET_POLICY.SPECIES_RESTRICTION" | translate }}
      </mat-slide-toggle>
    </div>
    @if (formControls.isSizeRestricted.value) {
      <est-postfix-text-input
        [control]="formControls.animalSize"
        appliedClass="mb-4"
        applyNegativeValuesValidation
        label="TERMS_AND_CONDITIONS.PET_POLICY.ANIMAL_SIZE"
        postfixText="KG"
        type="number"
      ></est-postfix-text-input>
    }
    @if (formControls.isSpeciesRestricted.value) {
      <est-select-search-field
        [control]="formControls.notAllowedSpeciesIds"
        [dropdownOptions]="notAllowedSpeciesOptions"
        [isLoading]="isLoadingSpecies"
        [isMultiple]="true"
        [searchSignal]="notAllowedSpeciesSearchSignal"
        dropdownLabel="TERMS_AND_CONDITIONS.PET_POLICY.SELECT_NOT_ALLOWED_SPECIES"
      ></est-select-search-field>
    }
  </div>
  <div class="dialog__footer">
    <est-button
      (click)="matDialog.closeAll()"
      buttonStyleType="button--tertiary"
      buttonType="button"
      label="CANCEL"
    ></est-button>
    <est-button
      [isLoading]="isSubmittingForm"
      [label]="isEdit ? 'SAVE' : 'ADD'"
      buttonStyleType="button--primary"
      buttonType="submit"
    ></est-button>
  </div>
</form>
