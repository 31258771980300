<div class="sticky top-0 z-10">
  <est-dialog-header
    [label]="
      componentInputs.isEditPopup
        ? 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.EDIT.TITLE'
        : 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.CREATE.TITLE'
    "
    [tooltipLabel]="
      componentInputs.isEditPopup
        ? 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.EDIT.TITLE'
        : 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.CREATE.TITLE'
    "
  ></est-dialog-header>
</div>
<div class="p-6">
  @if (isPageLoading) {
    <est-loading-overlay></est-loading-overlay>
  } @else {
    <est-small-header
      [heading]="
        componentInputs.isEditPopup
          ? 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.EDIT.NAME'
          : 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.CREATE.NAME'
      "
      [subheading]="
        componentInputs.isEditPopup
          ? 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.EDIT.DESCRIPTION'
          : 'SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.CREATE.DESCRIPTION'
      "
    ></est-small-header>
    <div class="py-6">
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <div class="flex gap-2 items-center mb-2">
      <p class="text-sm font-medium">{{ "CATEGORY" | translate }}</p>
      <est-tooltip tooltip="CATEGORY"></est-tooltip>
    </div>
    <est-input
      label="SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.NAME"
      [control]="formGroup.controls.title"
    ></est-input>
    <div class="py-6">
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <est-small-header
      heading="SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.ACTIVATED.NAME"
      subheading="SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.ACTIVATED.DESCRIPTION"
    ></est-small-header>
    <mat-slide-toggle
      class="text-sm font-light mt-4"
      [checked]="formGroup.controls.isInheritanceActive.value"
      (change)="toggleActivated()"
      >{{ "SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.ACTIVATED.IS_ACTIVE" | translate }}
    </mat-slide-toggle>
  }
</div>
<div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
  <est-button
    [appliedClasses]="'!py-3 !px-6'"
    (click)="closeDialog(true)"
    label="CANCEL"
    buttonStyleType="button--tertiary"
    buttonType="button"
  />
  <est-button
    [appliedClasses]="'!py-3 !px-6'"
    [label]="componentInputs.isEditPopup ? 'SAVE' : 'CREATE'"
    buttonStyleType="button--primary"
    buttonType="button"
    [isLoading]="isFormSubmitting"
    (click)="submitForm()"
  />
</div>
