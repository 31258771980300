<div class="p-6">
  <div class="flex items-center gap-2 mb-6">
    <p class="font-medium text-xl">{{ componentInputs.title | translate }}</p>
    <est-tooltip [tooltip]="componentInputs.tooltip"></est-tooltip>
  </div>
  <p class="text-sm font-light mb-6">{{ componentInputs.description | translate }}</p>
  <div class="p-6 rounded-lg bg-black-100 border border-black-200 flex text-sm font-light mb-6">
    <div class="flex flex-1 flex-grow-[2] flex-col gap-4">
      <div>
        <p class="font-medium mb-1">
          {{ "EVENTS.TYPE_OF_EVENT" | translate }}
        </p>
        <p>
          {{ PropertyEventTypeTranslationMapper.get(componentInputs.formValue.type)! | translate }}
          {{ "EVENTS.EVENT_ON_PROPERTY" | translate }}
        </p>
      </div>
      <div>
        <p class="font-medium mb-1">
          {{ "EVENTS.EXTERNAL_EVENT_TITLE" | translate }}
        </p>
        <p>
          {{ componentInputs.formValue.externalTitle }}
        </p>
      </div>
      <div>
        <p class="font-medium mb-1">
          {{ "EVENTS.INTERNAL_EVENT_TITLE" | translate }}
        </p>
        <p>
          {{ componentInputs.formValue.internalTitle }}
        </p>
      </div>
      <div>
        <p class="font-medium mb-1">
          {{ "PROPERTIES.ADDRESS" | translate }}
        </p>
        <p>
          {{ formatAddressValue(componentInputs.formValue.address) }}
        </p>
      </div>
      <div>
        <p class="font-medium mb-1">
          {{ "DATE_AND_TIME" | translate }}
        </p>
        @if (!componentInputs.formValue.repetition) {
          <p>
            {{ dateString() + " " + ("AT" | translate) + " " + timeString() }}
          </p>
        } @else {
          <p>
            @switch (componentInputs.formValue.repetition.repetitionCycle) {
              @case (RepetitionCycle.Weekly) {
                <span
                  [innerHTML]="
                    ('EVENTS.REPEATED_EVERY_WEEK_AT'
                      | translate: { day: DateTime.fromISO(componentInputs.formValue.startTime).weekdayLong }) + ' '
                  "
                ></span>
              }
              @case (RepetitionCycle.Monthly) {
                <span
                  [innerHTML]="
                    ('EVENTS.REPEATED_EVERY_MONTH_AT'
                      | translate: { number: DateTime.fromISO(componentInputs.formValue.startTime).day }) + ' '
                  "
                ></span>
              }
              @default {
                <span [innerHTML]="('EVENTS.REPEATED_EVERY_DAY_AT' | translate) + ' '"></span>
              }
            }
            <span>
              {{ timeString() }}
            </span>
          </p>
          <p>
            {{
              "EVENTS.STARTING_AT"
                | translate
                  : {
                      startDate: repeatedEventStartDate,
                      endDate: repeatedEventEndDate
                    }
            }}
          </p>
        }
      </div>
      <div>
        <p class="font-medium mb-1">
          {{ "EVENTS.ASSIGN_AGENTS_TO_EVENT" | translate }}
        </p>
        <p>
          {{ agentsName() }}
        </p>
      </div>
    </div>
    <div class="flex flex-1 flex-col gap-4">
      <div>
        <p class="font-normal mb-1">
          {{ "EVENTS.BOOKING" | translate }}
        </p>
        <p>
          {{ bookingString() | translate }}
        </p>
      </div>
      <div>
        <p class="font-normal mb-1">
          {{ "EVENTS.PUBLISH_ON_WEBSITE" | translate }}
        </p>
        <p>
          {{ (componentInputs.formValue.publishOnWebsite ? "YES" : "NO") | translate }}
        </p>
      </div>
      <div>
        <p class="font-normal mb-1">
          {{ "EVENTS.PUBLISH_ON_EIDO" | translate }}
        </p>
        <p>
          {{ (componentInputs.formValue.publishOnEido ? "YES" : "NO") | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="dialog__footer !static">
  <est-button
    (click)="matDialog.closeAll()"
    buttonStyleType="button--tertiary"
    buttonType="button"
    label="CANCEL"
  ></est-button>
  <est-button
    (click)="componentInputs.callbackFn()"
    [isLoading]="componentInputs.loadingSignal()"
    buttonStyleType="button--primary"
    buttonType="button"
    label="CONFIRM"
  >
  </est-button>
</div>
