<div class="flex justify-between items-end mb-5">
  <est-small-header
    heading="PACKAGES.FACILITY_PACKAGES.NAME"
    appliedClasses="!text-xl"
    subheading="PACKAGES.FACILITY_PACKAGES.DESCRIPTION"
    tooltip="PACKAGES.FACILITY_PACKAGES.NAME"
  ></est-small-header>
  <est-button
    label="PACKAGES.CREATE_PACKAGE"
    buttonStyleType="button--primary"
    buttonType="button"
    imageSrc="assets/images/icons/plus.svg"
    imageAlt="ADD"
    (click)="routeToCreatePackage()"
  ></est-button>
</div>
<div class="relative">
  @if (isPageLoading) {
    <est-loading-overlay appliedClasses="my-20"></est-loading-overlay>
  } @else {
    <div class="flex flex-wrap gap-6">
      @for (facilityPackage of facilityPackages; track facilityPackage.id) {
        <est-facility-packages-card
          [actions]="actions"
          [facilityPackage]="facilityPackage"
          [propertyId]="propertyId()"
          [linkingSource]="linkingSource"
        ></est-facility-packages-card>
      }
    </div>
  }
  @if (facilityPackages && !facilityPackages.length) {
    <div class="bg-black-50 flex items-center justify-center py-8 rounded border border-black-300">
      <p class="text-xs font-light">{{ "NO_FACILITY_PACKAGES" | translate }}</p>
    </div>
  }
</div>
