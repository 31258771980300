<div class="flex justify-between items-end mb-5">
  <est-small-header
    heading="PACKAGES.TEXT_PACKAGES.NAME"
    tooltip="PACKAGES.TEXT_PACKAGES.NAME"
    appliedClasses="!text-xl"
    subheading="PACKAGES.TEXT_PACKAGES.SUBHEADER"
  ></est-small-header>
  <est-button
    label="PACKAGES.CREATE_PACKAGE"
    buttonStyleType="button--primary"
    buttonType="button"
    imageSrc="assets/images/icons/plus.svg"
    imageAlt="ADD"
    routerLink="create"
  ></est-button>
</div>
<div class="relative">
  <div class="flex flex-wrap gap-6">
    @for (text of textPackages; track text.id) {
      <est-text-card
        [isPackage]="true"
        [actions]="actions"
        [textPackage]="text"
        [propertyId]="propertyId()"
        [linkingSource]="linkingSource"
      ></est-text-card>
    }
  </div>
  @if (isPageLoading) {
    <est-loading-overlay appliedClasses="my-20"></est-loading-overlay>
  }
</div>
