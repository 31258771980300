import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-export-data",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./export-data.component.html",
  styleUrl: "./export-data.component.scss",
})
export class ExportDataComponent {}
