import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UnderDevelopmentComponent } from "../../../../shared/under-development/under-development.component";

@Component({
  selector: "est-organization-maintenance",
  standalone: true,
  imports: [TranslateModule, UnderDevelopmentComponent],
  templateUrl: "./organization-maintenance.component.html",
  styleUrl: "./organization-maintenance.component.scss",
})
export class OrganizationMaintenanceComponent {}
