import { Component, DestroyRef, OnInit, inject } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterModule } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";

type BreadcrumbItem = { name: string; url: string };

@Component({
  selector: "est-breadcrumb",
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, RouterModule],
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  // To get breadcrumb working with components make sure to add name
  // in the data object in the component routing. Also add its translation
  readonly #route = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #destroyRef$ = inject(DestroyRef);
  protected breadcrumbItems: BreadcrumbItem[] = [];

  ngOnInit(): void {
    this.#fillBreadcrumbItemsRecursive(this.#route.snapshot);
    this.#subscribeToRouteChange();
  }

  #fillBreadcrumbItemsRecursive(snapshot: ActivatedRouteSnapshot | null): void {
    if (!snapshot) return;
    if (snapshot.data["name"]) {
      this.breadcrumbItems.push({
        name: snapshot.data["name"],
        url: this.#getRelativeUrl(snapshot),
      });
    }
    this.#fillBreadcrumbItemsRecursive(snapshot.firstChild);
  }

  #getRelativeUrl(snapshot: ActivatedRouteSnapshot): string {
    return (
      "/" +
      snapshot.pathFromRoot
        .map((route) => route.url.map((segment) => segment.toString()).join("/"))
        .filter((route) => !!route)
        .join("/")
    );
  }

  #subscribeToRouteChange(): void {
    this.#router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.#destroyRef$),
      )
      .subscribe(() => {
        this.breadcrumbItems = [];
        this.#fillBreadcrumbItemsRecursive(this.#route.snapshot.firstChild);
      });
  }
}
