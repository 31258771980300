<est-stepper-navigation [buttonsLabels]="buttonLabels" [selectedIndex]="stepperIndex"></est-stepper-navigation>
<div class="bg-black-200 rounded-[0.625rem] p-6">
  <div class="bg-white rounded-[0.625rem] r-screen-[25rem] relative pb-20 overflow-hidden">
    @if (isPageLoading) {
      <est-loading-overlay></est-loading-overlay>
    }
    <mat-stepper #stepper [selectedIndex]="stepperIndex" animationDuration="0">
      <mat-step [stepControl]="createPropertyMediaPackageFormControls.firstStepGroup">
        <div class="p-6 pe-16">
          <est-subheader
            date="Mon Feb 19 2024 14:20:06 GMT+0200"
            label="SETTINGS.NAME"
            name="Jesper Thorkildsen"
            tooltip="SETTINGS.NAME"
          ></est-subheader>
          <est-small-header
            appliedClasses="mt-12"
            heading="PROPERTY_FACILITIES.PACKAGE_SETTING"
            subheading="CREATE_PROPERTY_MEDIA_PACKAGE.SETTINGS_DESCRIPTION"
            subheadingAppliedClasses="!max-w-[85%]"
            tooltip="PROPERTY_FACILITIES.PACKAGE_SETTING"
          ></est-small-header>
          <est-small-header
            appliedClasses="mt-6"
            heading="PROPERTY_FACILITIES.INTERNAL_TITLE"
            subheading="PROPERTY_FACILITIES.AN_INTERNAL_TITLE_USED"
            subheadingAppliedClasses="!max-w-[60%]"
            tooltip="PROPERTY_FACILITIES.INTERNAL_TITLE"
          ></est-small-header>
          <est-input
            [control]="formControls.internalTitle"
            appliedClass="mt-5 !w-[37.5rem]"
            label="PROPERTY_FACILITIES.INTERNAL_TITLE"
          ></est-input>
        </div>
        <hr class="border-black-100" />
        <div class="p-6 pe-16">
          <est-small-header
            heading="CREATE_PROPERTY_MEDIA_PACKAGE.SORT_MEDIA"
            subheading="CREATE_PROPERTY_MEDIA_PACKAGE.SORT_MEDIA_DESCRIPTION"
            subheadingAppliedClasses="!max-w-[85%] mb-6"
            tooltip="CREATE_PROPERTY_MEDIA_PACKAGE.SORT_MEDIA"
          ></est-small-header>
          <p class="font-light text-sm mb-6">
            <span class="font-medium uppercase">{{ "IMPORTANT" | translate }}:</span>
            {{ "CREATE_PROPERTY_MEDIA_PACKAGE.SORT_MEDIA_NOTE" | translate }}
          </p>
          <p class="font-light text-sm mb-2">
            <span class="font-medium"
              >{{ "CREATE_PROPERTY_MEDIA_PACKAGE.SELECTED_ORDER_IN_PACKAGE" | translate }} :</span
            >
            {{ "CREATE_PROPERTY_MEDIA_PACKAGE.SELECTED_ORDER_IN_PACKAGE_DESCRIPTION" | translate }}
          </p>
          <p class="font-light text-sm mb-4">
            <span class="font-medium">{{ "CREATE_PROPERTY_MEDIA_PACKAGE.ONE_TIME_RANDOMISATION" | translate }}:</span>
            {{ "CREATE_PROPERTY_MEDIA_PACKAGE.ONE_TIME_RANDOMISATION_DESCRIPTION" | translate }}
          </p>
          <mat-radio-group
            (change)="isEdit && openSortChangeConfirmationDialog()"
            [formControl]="formControls.sortMediaOrder"
            class="text-sm font-light"
          >
            <div class="flex flex-col">
              <mat-radio-button [value]="PropertyMediaPackageSortOrder.InOrder"
                >{{ "CREATE_PROPERTY_MEDIA_PACKAGE.SELECTED_ORDER_IN_PACKAGE" | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="PropertyMediaPackageSortOrder.OneTimeRandomized"
                >{{ "CREATE_PROPERTY_MEDIA_PACKAGE.ONE_TIME_RANDOMISATION" | translate }}
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
        <hr class="border-black-100" />
        <div class="p-6 pe-16">
          <est-small-header
            heading="PROPERTIES.DETAILS.MAIN_DETAILS.ALLOW_CHANGES_UNIT"
            subheading="CREATE_PROPERTY_MEDIA_PACKAGE.SORT_MEDIA_DESCRIPTION"
            subheadingAppliedClasses="!max-w-[85%] mb-4"
            tooltip="PROPERTIES.DETAILS.MAIN_DETAILS.ALLOW_CHANGES_UNIT"
          ></est-small-header>
          <div class="flex flex-col gap-4">
            <mat-slide-toggle [formControl]="formControls.allowChangeOnUnitLevel" class="text-sm font-light"
              >{{ "CREATE_PROPERTY_MEDIA_PACKAGE.ALLOW_CHANGES_TO_ORDER" | translate }}
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="formControls.allowDeLinkingOnUnitLevel" class="text-sm font-light"
              >{{ "CREATE_PROPERTY_MEDIA_PACKAGE.ALLOW_DELINKING" | translate }}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="p-6 pe-16">
          <est-small-header
            heading="PROPERTY_FACILITIES.ACTIVATE_PACKAGE"
            subheading="PROPERTY_FACILITIES.ENABLE_THE_SETTING"
            subheadingAppliedClasses="!max-w-[85%] mb-4"
            tooltip="PROPERTY_FACILITIES.ACTIVATE_PACKAGE"
          ></est-small-header>
          <mat-slide-toggle [formControl]="formControls.isActive" class="text-sm font-light"
            >{{ "ACTIVATE_PACKAGE" | translate }}
          </mat-slide-toggle>
        </div>
      </mat-step>
      <mat-step [stepControl]="createPropertyMediaPackageFormControls.secondStepGroup">
        <div class="p-6">
          <est-subheader
            date="Mon Feb 19 2024 14:20:06 GMT+0200"
            label="ADD_MEDIA"
            name="Jesper Thorkildsen"
            tooltip="ADD_MEDIA"
          ></est-subheader>
          <div class="mt-12 flex items-center justify-between gap-14">
            <div class="flex flex-col gap-4">
              <p class="font-medium">{{ "CREATE_PROPERTY_MEDIA_PACKAGE.UPLOADED_MEDIA_IN_PACKAGE" | translate }}</p>
              <p class="text-sm font-light">
                {{ "CREATE_PROPERTY_MEDIA_PACKAGE.UPLOADED_MEDIA_IN_PACKAGE_DESCRIPTION" | translate }}
              </p>
              <p class="text-sm">
                <span class="font-medium">{{ "CREATE_PROPERTY_MEDIA_PACKAGE.SORT_MEDIA_TYPE" | translate }}:</span>
                {{ PropertySortMediaOrderTranslationMapper.get(formControls.sortMediaOrder.value)! | translate }}
              </p>
            </div>
            <est-button
              (click)="openFileUploadDialog()"
              buttonStyleType="button--primary"
              buttonType="button"
              imageAlt="UPLOAD"
              imageSrc="assets/images/icons/plus.svg"
              label="UPLOAD"
            >
            </est-button>
          </div>
        </div>
        @if (isEdit) {
          <est-media-sortable-grid
            [fileUploadControl]="fileUploadControl"
            [formArray]="formControls.medias"
            [medias]="packageMedias"
          ></est-media-sortable-grid>
        } @else {
          <est-media-sortable-grid
            [fileUploadControl]="fileUploadControl"
            [formArray]="formControls.medias"
          ></est-media-sortable-grid>
        }
      </mat-step>
    </mat-stepper>
    <div class="dialog__footer">
      @if (stepperIndex === 0) {
        <est-button
          buttonStyleType="button--tertiary"
          buttonType="button"
          label="CANCEL"
          [routerLink]="isEdit ? '../../' : '../'"
        ></est-button>
        <est-button
          (click)="goToNextPage()"
          buttonStyleType="button--primary"
          buttonType="button"
          label="NEXT"
        ></est-button>
      } @else {
        <est-button
          (click)="goToPreviousPage()"
          buttonStyleType="button--tertiary"
          buttonType="button"
          label="BACK"
        ></est-button>
        <div class="flex items-center gap-6">
          @if (formControls.medias.invalid) {
            <p class="text-sm font-light">{{ "CREATE_PROPERTY_MEDIA_PACKAGE.ASSIGN_MEDIA_TYPES" | translate }}</p>
          }
          <est-button
            [disabled]="formControls.medias.invalid || formControls.medias.length === 0"
            [isLoading]="isSubmittingForm"
            (click)="submitForm()"
            buttonStyleType="button--primary"
            buttonType="button"
            [label]="isEdit ? 'SAVE' : 'PACKAGES.CREATE_PACKAGE'"
          ></est-button>
        </div>
      }
    </div>
  </div>
</div>
