import { Component, inject, signal } from "@angular/core";
import { FormControl, NonNullableFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { IDuplicatePackageInput } from "@root/shared/interfaces/duplicate-package-input.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { controlArrayNotEmptyValidator } from "@root/shared/validators/array-validators";

/*
  @component
  @template DOptionType - Type of the duplication option control.
  @description Provides base logic for handling package duplication.
  @param {object} DOptionType - The type of the option used in the component
  @templateUrl "./base-duplicate-package.component.html"
 */
@Component({
  standalone: true,
  template: "",
})
export abstract class BaseDuplicatePackageComponent<DOptionType> {
  isLoading = signal(false);
  readonly matDialog = inject(MatDialog);
  readonly componentInputs: IDuplicatePackageInput = inject(MAT_DIALOG_DATA);
  readonly originalPackageControl = new FormControl({
    value: this.componentInputs.originalName,
    disabled: true,
  });
  abstract readonly duplicationOptions: IDropdownOption[];
  readonly #fb = inject(NonNullableFormBuilder);
  readonly form = this.#fb.group({
    internalTitle: this.#fb.control<string | undefined>(undefined, Validators.required),
    duplicationOption: this.#fb.control<DOptionType | undefined>(undefined, Validators.required),
    propertyIds: this.#fb.control<number[]>([], controlArrayNotEmptyValidator()),
  });

  abstract newPackageSubHeaderTranslationKey: string;

  /**
   * Override these keys when using this base component to duplicate something else other than package
   * ie: duplicating text field
   */
  internalTranslationKeys = {
    popupTitle: "PACKAGES.DUPLICATE_PACKAGE.NAME",
    newPackageHeader: "PACKAGES.DUPLICATE_PACKAGE.NEW_PACKAGE",
    originalName: "PACKAGES.DUPLICATE_PACKAGE.ORIGINAL_PACKAGE_NAME",
    selectPropertySubHeader: "PACKAGES.DUPLICATE_PACKAGE.IT_IS_ONLY_POSSIBLE",
  };
  protected get translationKeys() {
    return this.internalTranslationKeys;
  }
  protected set translationKeys(value: typeof this.internalTranslationKeys) {
    this.internalTranslationKeys = { ...this.internalTranslationKeys, ...value };
  }

  abstract duplicatePackage(): void;
}
