import { NgOptimizedImage, SlicePipe } from "@angular/common";
import { Component, input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { ICardAction } from "@root/shared/interfaces/card-Action.interface";
import { IFacilityCard } from "@root/shared/interfaces/facility-card.interface";
import { LinkedUnitsTablePopupComponent } from "@root/shared/linked-units-table-popup/linked-units-table-popup.component";
import { LinkingSource } from "../enums/linking-source.enum";

@Component({
  selector: "est-facility-packages-card",
  standalone: true,
  imports: [
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
    MatMenuTrigger,
    NgOptimizedImage,
    SlicePipe,
    LinkedUnitsTablePopupComponent,
    CustomDatePipe,
  ],
  templateUrl: "./facility-packages-card.component.html",
  styleUrl: "./facility-packages-card.component.scss",
})
export class FacilityPackagesCardComponent {
  isClicked = false;
  actions = input.required<ICardAction[]>();
  propertyId = input.required<number>();
  linkingSource = input.required<LinkingSource>();
  facilityPackage = input.required<IFacilityCard>();
}
