<div class="flex justify-between gap-12 mt-12 mb-6">
  <est-small-header
    heading="PROPERTY_FACILITIES.UNIT_FACILITIES"
    subheading="UNIT_FACILITIES.DESCRIPTION"
    subheadingAppliedClasses="!max-w-full"
    tooltip="PROPERTY_FACILITIES.UNIT_FACILITIES"
  />
  <est-button
    (click)="openAddFacilityDialog()"
    buttonStyleType="button--primary"
    buttonType="button"
    imageAlt="ADD"
    imageSrc="assets/images/icons/plus.svg"
    label="PROPERTY_FACILITIES.ADD_FACILITY"
  ></est-button>
</div>
<est-small-list-table
  (toggleItem)="toggleFacility($event.checked, $event.row)"
  [actions]="actions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [iconMenuComponentType]="FacilityDetailsComponent"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
  [toggleButtonDisableHashMap]="toggleButtonDisableHashMap"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
