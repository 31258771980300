<div id="language-display" [ngClass]="[appliedClass(), disabled() ? 'disabled' : '']" class="w-[20.375rem]">
  <div class="flex justify-start items-center gap-2">
    <img
      [alt]="'FLAG' | translate"
      [src]="language().flagImageUrl"
      [ngClass]="small() ? 'w-[1.125rem] h-3' : 'w-6 h-4'"
      class="rounded-sm"
    />
    {{ language().name }}
    @if (this.isDefault()) {
      ({{ "END_USER_DEFAULT" | translate }})
    }
  </div>
</div>
