<div class="flex justify-between gap-x-2 gap-y-4 mb-6 max-2xl:flex-col">
  <est-small-header
    heading="SETTINGS.USERS.ARCHIVED_USERS"
    subheading="SETTINGS.USERS.ARCHIVED_USERS_DESCRIPTION"
    tooltip="SETTINGS.USERS.ARCHIVED_USERS"
  ></est-small-header>
  <div class="flex gap-4 self-end">
    <est-select-division-field [selectSignal]="divisionSignal"> </est-select-division-field>
    <est-search-input
      [searchSignal]="searchSignal"
      appliedClass="max-2xl:w-64"
      placeholder="SETTINGS.USERS.SEARCH_FOR_USER"
    ></est-search-input>
  </div>
</div>

<est-small-list-table
  [actions]="actions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [iconMenuComponentType]="UserDivisionRolesOverviewComponent"
  [isLoading]="isTableLoading"
  [isMouseoverIconVisibleFn]="isMouseoverIconVisible"
  [sortingFilterSignal]="sortingFilterSignal"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
