import { Component, input, output } from "@angular/core";
import { InputComponent } from "../input/input.component";
import { SelectFieldComponent } from "../select-field/select-field.component";
import { IAddressLookUp } from "../interfaces/address-look-up.interface";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { FormGroup } from "@angular/forms";
import { IAddressFormGroup } from "../interfaces/address-form-group.interface";
import { getControlFromAddressFormGroup } from "../utilities/form.utilities";

@Component({
  selector: "est-static-address",
  standalone: true,
  templateUrl: "./static-address.component.html",
  styleUrl: "./static-address.component.scss",
  imports: [InputComponent, SelectFieldComponent],
})
export class StaticAddressComponent {
  lookups = input.required<IAddressLookUp<IDropdownOption>>();
  formGroup = input.required<FormGroup<IAddressFormGroup>>();

  showFloor = input<boolean>();
  showSide = input<boolean>();

  selectedValueChange = output<{ name: string; value: number }>();

  protected readonly getControlFromAddressFormGroup = getControlFromAddressFormGroup;
}
