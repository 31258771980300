import { Component, OnInit, WritableSignal, inject, input, signal, DestroyRef } from "@angular/core";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { InputComponent } from "@root/shared/input/input.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { GeographicalLocationComponent } from "@root/shared/geographical-location/geographical-location.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { constructInitialAddressLookups, formatAddress } from "@root/shared/utilities/address.utilities";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { CurrencyConversionDialogComponent } from "@root/shared/currency-conversion-dialog/currency-conversion-dialog.component";
import { PropertyMainDetailsFormControls } from "./property-main-details.form-controls";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { IPropertyDetails } from "@root/data/market/properties/models/property-details.model";
import { PaymentFrequencySelectFieldComponent } from "@root/shared/payment-frequency-select-field/payment-frequency-select-field.component";
import { TimezoneSelectFieldComponent } from "@root/shared/timezone-select-field/timezone-select-field.component";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { MatDialog } from "@angular/material/dialog";
import { updateControlAndPatch } from "@root/shared/utilities/form.utilities";
import { PropertySettingField } from "@root/data/market/properties/enums/property-setting-field.enum";
import { SelectCompanyDialogComponent } from "./select-company-dialog/select-company-dialog.component";
import { UserRole } from "@root/data/market/users/enums/user-role.enum";
import { AuthorizedRoleDirective } from "@root/core/directives/authorized-role.directive";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { PropertyAddressComponent } from "@root/shared/property-address/property-address.component";
import { IAddress } from "@root/shared/interfaces/address.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "est-main-details",
  standalone: true,
  templateUrl: "./property-main-details.component.html",
  styleUrl: "./property-main-details.component.scss",
  imports: [
    SubheaderComponent,
    TooltipComponent,
    TranslateModule,
    ReactiveFormsModule,
    InputComponent,
    LoadingOverlayComponent,
    ButtonComponent,
    AuthorizedRoleDirective,
    AddressComponent,
    GeographicalLocationComponent,
    SelectFieldComponent,
    MatSlideToggle,
    CurrencyConversionDialogComponent,
    PaymentFrequencySelectFieldComponent,
    TimezoneSelectFieldComponent,
    PropertyAddressComponent,
  ],
  providers: [PropertyMainDetailsFormControls],
})
export class PropertyMainDetailsComponent implements OnInit {
  readonly mainDetailsFormControls = inject(PropertyMainDetailsFormControls);
  readonly #lookupsService = inject(LookupsService);
  readonly #translateService = inject(TranslateService);
  readonly #dialogService = inject(DialogService);
  readonly #propertiesService = inject(PropertiesService);
  readonly #authService = inject(AuthService);
  readonly currencyConversionDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);
  readonly propertyId = input.required<string>();
  readonly isPrimaryAddressLoadingSignal = signal(false);
  readonly isSecondaryAddressLoadingSignal = signal(false);
  readonly formattedPrimaryAddressSignal: WritableSignal<string> = signal<string>("");
  readonly formattedSecondaryAddressSignal: WritableSignal<string> = signal<string>("");
  readonly initialSecondaryAddressSignal = signal<IAddress | null>(null);
  readonly UserRole = UserRole;
  readonly destroyRef = inject(DestroyRef);
  addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  currenciesLookupsOptions: IDropdownOption[] = [];
  propertySettingField = PropertySettingField;
  isActivatePropertySettingsLoading: boolean = false;
  loggedInUserRole = this.#authService.getCurrentUserRole();
  changesOnUnitLevelForDefaultCurrency = true;
  changesOnUnitLevelForPaymentFrequency = true;
  isLoading = false;

  propertyConceptDropDownOptions: IDropdownOption[] = [
    {
      value: "1",
      label: "NO_CONCEPT",
    },
  ];

  ngOnInit() {
    this.#getAddressLookupsOptions();
    this.#getPropertyDetails();
    this.#getAllCurrenciesLookupsOptions();
  }

  #getAddressLookupsOptions() {
    this.#lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }

  #getAllCurrenciesLookupsOptions() {
    this.#lookupsService.getAllCurrenciesLookup().subscribe((currenciesLookupOptions) => {
      this.currenciesLookupsOptions = currenciesLookupOptions;
    });
  }

  #getPropertyDetails() {
    this.isLoading = true;
    this.#propertiesService.getPropertyDetails(this.propertyId()).subscribe((response: IPropertyDetails) => {
      if (response.propertyAddress.secondaryAddress) {
        this.initialSecondaryAddressSignal.set(response.propertyAddress.secondaryAddress.addressInformation);
        this.mainDetailsFormControls.addSecondaryAddress();
      }
      this.isLoading = false;
      this.mainDetailsFormControls.mainDetailsForm.patchValue(response);
      this.#setEmptyPlaceholder(this.mainDetailsFormControls.defaultSalesType);
      this.#setEmptyPlaceholder(this.mainDetailsFormControls.unitType);
      this.#setEmptyPlaceholder(this.mainDetailsFormControls.housingType);
      this.#setEmptyPlaceholder(this.mainDetailsFormControls.usageType);
      this.mainDetailsFormControls.propertyConcept.setValue("1");
      this.formattedPrimaryAddressSignal.set(
        formatAddress(
          this.mainDetailsFormControls.mainDetailsForm.controls.propertyAddress.controls.primaryAddress.controls
            .addressInformation,
        ),
      );
      this.changesOnUnitLevelForDefaultCurrency = response.defaultCurrency.areThereChangesOnUnitLevel;
      this.changesOnUnitLevelForPaymentFrequency = response.defaultPaymentFrequency.areThereChangesOnUnitLevel;
    });
  }

  updateDetailsOnUpdateGeoLocation(updated: boolean) {
    if (updated) this.updatePropertyDetails();
  }

  checkedChangesOnUnitLevelForDefaultCurrency(checkedValue: boolean) {
    if (!checkedValue) {
      this.changesOnUnitLevelForDefaultCurrency = false;
    }
    this.updatePropertyDetails();
  }

  openReactivatePropertySettingDialog(propertySettingField: PropertySettingField) {
    this.#dialogService.open(
      {
        tooltipLabel: "PROPERTIES.DETAILS.MAIN_DETAILS.REACTIVATE_DIALOG_TITLE",
        title: "PROPERTIES.DETAILS.MAIN_DETAILS.REACTIVATE_DIALOG_TITLE",
        cancelLabel: "CANCEL",
        callBack: () => this.#reactivatePropertySettings(this.propertyId(), propertySettingField),
        submitLabel: "CONFIRM",
        isInputIncluded: false,
        descriptions: [
          "PROPERTIES.DETAILS.MAIN_DETAILS.REACTIVATE_DIALOG_SUBTITLE",
          `<p class='font-medium relative top-5'>${this.#translateService.instant("WARNING")}!</p>`,
          "PROPERTIES.DETAILS.MAIN_DETAILS.REACTIVATE_DIALOG_DESCRIPTION",
        ],
      },
      "confirmation",
    );
  }

  #reactivatePropertySettings(propertyId: string, propertySettingField: PropertySettingField) {
    this.isActivatePropertySettingsLoading = true;
    this.#propertiesService.reactivatePropertySettings(propertyId, propertySettingField).subscribe({
      next: () => {
        this.isActivatePropertySettingsLoading = false;
        this.#dialogService.close();
      },
      error: () => {
        this.isActivatePropertySettingsLoading = false;
        this.#dialogService.close();
      },
    });
  }

  openChangeCompanyDialog() {
    this.dialog
      .open(SelectCompanyDialogComponent, {
        width: "28rem",
        data: {
          propertyId: this.propertyId(),
          companyId: this.mainDetailsFormControls.companyId.value,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((companyId) => {
        if (!companyId) return;
        this.#getPropertyDetails();
      });
  }

  #setEmptyPlaceholder = (control: FormControl<string[]>): void => {
    if (!control.value.length) {
      control.setValue([this.#translateService.instant("DATA_NOT_AVAILABLE")]);
    }
  };

  updatePropertyDetails() {
    const controlPaths = updateNestedControlsPathAndValue(this.mainDetailsFormControls.mainDetailsForm);
    !!Object.keys(controlPaths).length &&
      this.#propertiesService.updatePropertyDetails(this.propertyId()!, controlPaths).subscribe({
        next: () => {
          this.formattedPrimaryAddressSignal.set(
            formatAddress(
              this.mainDetailsFormControls.mainDetailsForm.controls.propertyAddress.controls.primaryAddress.controls
                .addressInformation,
            ),
          );
          if (this.mainDetailsFormControls.mainDetailsForm.controls.propertyAddress.controls.secondaryAddress) {
            this.formattedSecondaryAddressSignal.set(
              formatAddress(
                this.mainDetailsFormControls.mainDetailsForm.controls.propertyAddress.controls.secondaryAddress.controls
                  .addressInformation,
              ),
            );
          }
        },
      });
  }

  updateControlAndPatchNewValue(value: string | number, fieldName: string) {
    updateControlAndPatch(
      value,
      fieldName,
      this.mainDetailsFormControls.mainDetailsForm,
      this.updatePropertyDetails.bind(this),
    );
  }

  openCurrencyConversionDialogComponent() {
    this.currencyConversionDialog.open(CurrencyConversionDialogComponent, {
      width: "30rem",
      data: {
        propertyId: this.propertyId(),
        defaultCurrency: this.currenciesLookupsOptions.find(
          (currency) => currency.value === this.mainDetailsFormControls.currencyId.value,
        )!.label,
      },
    });
  }

  updateAddress(event: { name: string; value: number | string }) {
    const control = this.mainDetailsFormControls.mainDetailsForm.get(event.name);
    control?.setValue(event.value);
    this.updatePropertyDetails();
  }

  addOrRemoveSecondaryAddress(addAddress: boolean) {
    this.#propertiesService
      .addOrRemoveSecondaryAddress(this.propertyId(), addAddress)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
