import { NgClass } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDivider } from "@angular/material/divider";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { BaseLinkedItemsComponent } from "@root/shared/abstracts/base-linked-items/base-linked-items";
import { LinkedUnitsTableComponent } from "@root/shared/abstracts/base-linked-items/linked-units/linked-units-table.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CompanySelectFieldComponent } from "@root/shared/company-select-field/company-select-field.component";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { GeographicalLocationComponent } from "@root/shared/geographical-location/geographical-location.component";
import { InputComponent } from "@root/shared/input/input.component";
import { ListSelectTeamsComponent } from "@root/shared/list-select-teams/list-select-teams.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { LinkedCompaniesTableComponent } from "../abstracts/base-linked-items/linked-companies-table/linked-companies-table.component";
import { LinkedDivisionsTableComponent } from "../abstracts/base-linked-items/linked-divisions-table/linked-divisions-table.component";
import { LinkedPropertiesTableComponent } from "../abstracts/base-linked-items/linked-properties-table/linked-properties-table.component";
import { SelectionOperation } from "../enums/selection-operation.enum";

@Component({
  selector: "est-create-link-dialog",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatProgressBar,
    FormsModule,
    AddressComponent,
    CompanySelectFieldComponent,
    CreationSuccessMessageComponent,
    GeographicalLocationComponent,
    InputComponent,
    ListSelectTeamsComponent,
    MatDivider,
    MatSlideToggle,
    MatStep,
    MatStepper,
    NgClass,
    TranslateModule,
    SelectFieldComponent,
    SearchInputComponent,
    LinkedUnitsTableComponent,
    LinkedDivisionsTableComponent,
    LinkedCompaniesTableComponent,
    LinkedPropertiesTableComponent,
    ButtonComponent,
    LoadingOverlayComponent,
    MatTooltip,
    TooltipComponent,
    MatTooltip,
    TooltipComponent,
  ],
  templateUrl: "../abstracts/base-linked-items/base-linked-items.html",
})
export class CreateLinkDialogComponent extends BaseLinkedItemsComponent implements AfterViewInit, OnInit {
  isEdit = false;

  ngOnInit(): void {
    this.selectAllUnitsOperationSignal.set(SelectionOperation.SELECT_ALL);
  }

  ngAfterViewInit(): void {
    this.changeDec.detectChanges();
  }
}
