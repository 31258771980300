<div class="min-h-screen bg-secondary flex flex-col py-6 justify-center items-center xl:px-[17.125rem] px-[5rem]">
  <img
    class="mb-5"
    ngSrc="assets/images/estatetool-logo-white.png"
    width="160"
    height="67"
    priority="true"
    [alt]="'LOGO' | translate"
  />
  <div class="block">
    <div class="flex flex-col gap-10 items-center justify-center mb-6 w-[29rem] text-center">
      <p class="block__title">
        {{ "DIVISION_NOT_FOUND.TITLE" | translate }}
      </p>
      <p class="block__description">
        {{ "DIVISION_NOT_FOUND.DESCRIPTION" | translate }}
      </p>
      <p class="block__description">
        {{ "DIVISION_NOT_FOUND.SECONDARY_DESCRIPTION" | translate }}
      </p>
    </div>
    <img
      width="729"
      height="394"
      ngSrc="assets/images/no-division.png"
      priority="true"
      [alt]="'DIVISION_NOT_FOUND.IMAGE_ALT' | translate"
    />
  </div>
  <p class="text-sm font-light text-white">{{ "COPYRIGHT" | translate: { currentYear } }}</p>
</div>
