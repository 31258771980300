import { Component, effect, inject, signal, WritableSignal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { listTeamAssignedPropertiesHeaders } from "@root/views/main/organization/organization-settings/components/users/teams/list-team-assigned-properties/list-team-assigned-properties.headers";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { ButtonComponent } from "@root/shared/button/button.component";

@Component({
  selector: "est-list-team-assigned-properties",
  standalone: true,
  imports: [TranslateModule, SearchInputComponent, PaginationComponent, SmallListTableComponent, ButtonComponent],
  templateUrl: "./list-team-assigned-properties.component.html",
  styleUrl: "./list-team-assigned-properties.component.scss",
})
export class ListTeamAssignedPropertiesComponent extends BasePaginatedTableWithSearchComponent {
  componentInputs: { teamId: number } = inject(MAT_DIALOG_DATA);
  override headers = listTeamAssignedPropertiesHeaders;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  override loadDataEffect$ = effect(() => {
    this.loadData({
      search: this.searchSignal(),
      pageSize: this.paginatedData.pageSize(),
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageNumber: this.paginatedData.currentPage(),
      teamIds: [this.componentInputs.teamId],
    });
  });
  protected readonly matDialog = inject(MatDialog);
  readonly #propertiesService = inject(PropertiesService);

  override loadData(params: IPaginationSortPayload & { teamIds: number[] }) {
    this.isTableLoading = true;
    this.#propertiesService
      .getPaginatedSmallListTableInputPropertiesLookups(params)
      .subscribe((paginatedProperties) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedProperties);
        this.isTableLoading = false;
      });
  }
}
