<div class="mt-10">
  <div class="flex gap-2 items-center mb-4">
    <p class="text-base font-medium">{{ "UNIT_LAYOUT.ACCESSIBILITY.NAME" | translate }}</p>
    <est-tooltip tooltip="UNIT_LAYOUT.ACCESSIBILITY.NAME"></est-tooltip>
  </div>

  <mat-slide-toggle [formControl]="handicapAccessibleControl()" (change)="valuesChanged.emit()" class="mb-6"
    >{{ "UNIT_LAYOUT.ACCESSIBILITY.HANDICAP_ACCESSIBLE" | translate }}
  </mat-slide-toggle>

  @if (handicapAccessibleControl().value) {
    <div class="flex flex-col">
      <p class="mb-4 text-sm font-medium">{{ "UNIT_LAYOUT.ACCESSIBILITY.GEP.NAME" | translate }}</p>

      <div class="flex flex-wrap gap-6 items-center mb-6">
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.GEP.STEP_FREE"
          [control]="stepFreeEntranceControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.GEP.WIDE_ENTRANCE"
          [control]="wideEntranceControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.GEP.PARKING"
          [control]="parkingAccessibleControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.GEP.STEP_FREE_PATH"
          [control]="stepFreeEntrancePathControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
      </div>

      <p class="mb-4 text-sm font-medium">{{ "UNIT_LAYOUT.ACCESSIBILITY.BED.NAME" | translate }}</p>

      <div class="flex flex-wrap gap-6 items-center mb-6">
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BED.STEP_FREE"
          [control]="stepFreeBedroomEntranceControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BED.WIDE_ENTRANCE"
          [control]="wideBedroomEntranceControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
      </div>

      <p class="mb-4 text-sm font-medium">{{ "UNIT_LAYOUT.ACCESSIBILITY.BATH.NAME" | translate }}</p>

      <div class="flex flex-wrap gap-6 items-center mb-6">
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BATH.STEP_FREE"
          [control]="stepFreeBathroomEntranceControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BATH.WIDE_ENTRANCE"
          [control]="wideBathroomEntranceControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BATH.SHOWER_BAR"
          [control]="bathroomGrabBarControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BATH.TOILET_BAR"
          [control]="restroomGrabBarControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BATH.STEP_FREE_SHOWER"
          [control]="stepFreeShowerControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
        <est-decision-button-list
          label="UNIT_LAYOUT.ACCESSIBILITY.BATH.BATH_CHAIR"
          [control]="showerChairControl()"
          (selectionChanged)="valuesChanged.emit()"
        ></est-decision-button-list>
      </div>
    </div>
  }

  <est-end-user-visibility-input
    [control]="handicapAccessibleVisibleControl()"
    (selectionChanged)="valuesChanged.emit()"
  ></est-end-user-visibility-input>
</div>
