<est-dialog-header
  label="TERMS_AND_CONDITIONS.PET_POLICY.NAME"
  tooltipLabel="TERMS_AND_CONDITIONS.PET_POLICY.NAME"
></est-dialog-header>
<div class="p-6 relative pb-16">
  <p class="text-sm font-medium pb-2">{{ "TERMS_AND_CONDITIONS.PET_POLICY.DEFINE_RESTRICTIONS" | translate }}</p>
  <p class="text-sm font-light">{{ "TERMS_AND_CONDITIONS.PET_POLICY.RESTRICTIONS_DESCRIPTION" | translate }}</p>
  @if (!componentInputs.changesAllowed) {
    <p class="font-light text-sm text-error-100 mb-4 mt-2">
      {{ "UNIT.CHANGES_ARE_NOT_ALLOWED" | translate }}
    </p>
  } @else {
    <p class="font-light text-sm text-success-dark mb-4 mt-2">
      {{ "UNIT.CHANGES_ARE_ALLOWED" | translate }}
    </p>
  }
  <hr class="border-black-100 absolute start-0 end-0" />
  @if (isDataLoading) {
    <div class="relative p-12">
      <est-loading-overlay></est-loading-overlay>
    </div>
  } @else {
    <form class="mt-12" (ngSubmit)="submitForm()">
      @for (petPolicy of petPolicyFormArray.controls; track $index) {
        <div class="flex flex-col">
          <div class="flex flex-row gap-2 mb-2">
            <p class="font-medium text-sm">{{ getControlValueInFormGroup(petPolicy, "animalType") }}</p>
            <est-tooltip [tooltip]="getControlValueInFormGroup(petPolicy, 'animalType')"></est-tooltip>
          </div>
          <div class="flex flex-col gap-2 mb-6">
            <est-postfix-text-input
              label="TERMS_AND_CONDITIONS.PET_POLICY.ANIMAL_SIZE"
              postfixText="KG"
              type="number"
              appliedClass="!mt-2"
              [control]="getControlInFormGroup(petPolicy, 'animalSize')"
            >
            </est-postfix-text-input>
            <mat-slide-toggle [formControl]="getControlInFormGroup(petPolicy, 'isSizeRestricted')">
              {{ "TERMS_AND_CONDITIONS.PET_POLICY.SIZE_RESTRICTION" | translate }}
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="getControlInFormGroup(petPolicy, 'isSpeciesRestricted')">
              {{ "TERMS_AND_CONDITIONS.PET_POLICY.FOLLOW_SPECIES_RESTRICTION" | translate }}
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="getControlInFormGroup(petPolicy, 'isApplicationRequired')">
              {{ "TERMS_AND_CONDITIONS.PET_POLICY.APPLICATION_REQUIRED" | translate }}
            </mat-slide-toggle>
          </div>
        </div>
      }
      <div class="absolute bottom-0 start-0 end-0 flex flex-row px-6 py-2.5 border-t-2 justify-between bg-black-50">
        <est-button (click)="dialogRef.close()" buttonType="button" label="CLOSE" buttonStyleType="button--tertiary">
        </est-button>
        @if (componentInputs.changesAllowed) {
          <est-button buttonType="submit" label="SAVE" buttonStyleType="button--primary" [isLoading]="isUpdatingData">
          </est-button>
        }
      </div>
    </form>
  }
</div>
