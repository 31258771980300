<div class="bg-black-50 border border-black-300 p-6 rounded-lg relative w-[22.813rem]">
  <div
    class="place-items-center lg:m-auto grid grid-cols-5 gap-3 h-36 bg-white border border-black-300 rounded-lg px-7 py-2"
  >
    @if (facilityPackage().iconUrls.length <= 15) {
      @for (icon of facilityPackage().iconUrls; track $index) {
        <img class="place-self-start mx-auto" [ngSrc]="icon" width="29" height="32" priority alt="FACILITY_ICON" />
      }
    } @else {
      @for (icon of facilityPackage().iconUrls | slice: 0 : 14; track $index) {
        <img
          class="place-self-start mx-auto"
          [ngSrc]="icon"
          width="29"
          height="32"
          priority
          [alt]="'FACILITY_ICON' | translate"
        />
      }
      <p class="font-black text-sm lg:my-auto">+{{ facilityPackage().iconUrls.length - 14 }}</p>
    }
  </div>
  <hr class="absolute start-0 end-0 border-black-100 my-6" />
  <div class="mt-12 mb-4">
    <p class="font-medium text-sm">{{ "PACKAGES.INTERNAL_TITLE" | translate }}</p>
    <p class="font-light text-sm">{{ facilityPackage().internalTitle }}</p>
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "PACKAGES.ITEMS_IN_THE_PACKAGE" | translate }}</p>
    <p class="font-light text-sm">
      {{ facilityPackage().iconUrls.length }} {{ "PROPERTY_FACILITIES.FACILITIES.NAME" | translate }}
    </p>
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "PACKAGES.LINKED" | translate }}</p>
    @if (facilityPackage().isLinked) {
      <button
        class="flex gap-1 outline-none"
        type="button"
        [attr.aria-label]="'PACKAGES.LINKED' | translate"
        [matMenuTriggerFor]="menu"
        (click)="isClicked = true"
      >
        <img ngSrc="assets/images/icons/linked-icon.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
        <span class="font-light text-sm"> {{ "PACKAGES.LINKED" | translate }}</span>
        <mat-menu class="menu--large" #menu="matMenu" (closed)="isClicked = false">
          <est-linked-units-table-popup
            [packageId]="facilityPackage().id"
            (click)="$event.stopPropagation()"
            [isClicked]="isClicked"
            [propertyId]="propertyId()"
            [linkingSource]="linkingSource()"
          ></est-linked-units-table-popup>
        </mat-menu>
      </button>
    } @else {
      <p class="text-sm font-light text-black-500">{{ "PACKAGES.NO_LINK_CREATED" | translate }}</p>
    }
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "STATUS" | translate }}</p>
    @if (facilityPackage().isActive) {
      <p class="font-light text-sm">{{ "ACTIVE" | translate }}</p>
    } @else {
      <p class="font-light text-sm">{{ "NOT_ACTIVE" | translate }}</p>
    }
  </div>
  <div>
    <p class="font-medium text-sm">{{ "PACKAGES.UPDATED" | translate }}</p>
    <p class="font-light text-sm truncate">
      {{ facilityPackage().lastUpdatedAt | customDate: true }}
      <span class="text-black-500"> {{ facilityPackage().updatedByUser ?? facilityPackage().createdByUser }} </span>
    </p>
  </div>

  <div class="mt-6 flex justify-between items-end">
    <div>
      <p class="text-xs font-light text-black-500">{{ "PACKAGES.CREATED_BY" | translate }}</p>
      <p class="text-xs font-light text-black-500">
        {{ facilityPackage().createdByUser }}, {{ facilityPackage().createdAt | customDate: true }}
      </p>
      <p></p>
    </div>
    <div>
      <button
        [attr.aria-label]="'DROPDOWN_MENU' | translate"
        [matMenuTriggerFor]="actionsMenu"
        class="cursor-pointer flex items-center bg-white justify-center rounded border border-black-300"
        type="button"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        @for (action of actions(); track action.label) {
          @if (!action.isVisible || action.isVisible.call(this, facilityPackage().id)) {
            <button
              mat-menu-item
              type="button"
              [attr.aria-label]="action.label | translate"
              (keypress.enter)="action.callbackFn(facilityPackage().id)"
              (click)="
                action.callbackFn(
                  facilityPackage().id,
                  facilityPackage().internalTitle,
                  facilityPackage().numberOfLinkedUnits
                )
              "
            >
              {{ action.label | translate }}
            </button>
          }
        }
      </mat-menu>
    </div>
  </div>
</div>
