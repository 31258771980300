import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { ILinkedPackageUnit } from "@root/data/market/linked/models/linked-package-unit.model";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPatchBody } from "@root/shared/interfaces/patch-body.interface";
import { Observable } from "rxjs/internal/Observable";
import { LinkingSourceApiMapper } from "../mappers/source-level-api.mapper";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";

@Injectable({
  providedIn: "root",
})
export class LinkingPackagesRepository {
  readonly #http = inject(HttpClient);

  getPaginatedUnitsInProperty(
    params: IPaginationSortPayload & { propertyIds?: number[] },
  ): Observable<IPaginatedResponse<ILinkedPackageUnit> & { selectedUnits: number }> {
    return this.#http.get<IPaginatedResponse<ILinkedPackageUnit> & { selectedUnits: number }>(
      APIS.unit.getUnitsInProperty,
      {
        params: new HttpParams({
          fromObject: { ...params },
        }),
      },
    );
  }

  getLinkedUnitsList(
    params: IPaginationSortPayload & { filterLinked?: boolean },
    propertyId: number,
    packageId: number,
    linkingSource: LinkingSource,
  ): Observable<IPaginatedResponse<ILinkedPackageUnit> & { selectedUnits: number }> {
    return this.#http.get<IPaginatedResponse<ILinkedPackageUnit> & { selectedUnits: number }>(
      LinkingSourceApiMapper.get(linkingSource)!.getLinkedUnitsList(propertyId, packageId),
      {
        params: new HttpParams({
          fromObject: { ...params },
        }),
      },
    );
  }

  updateLinkedUnits(
    packageId: number,
    units: IPatchBody[],
    linkingSource: LinkingSource,
    propertyId: number,
    SelectAllUnits: SelectionOperation,
  ): Observable<void> {
    return this.#http.patch<void>(
      LinkingSourceApiMapper.get(linkingSource)!.updateLinkedUnits(propertyId, packageId),
      units,
      {
        params: new HttpParams({
          fromObject: {
            SelectAllUnits: SelectAllUnits,
          },
        }),
      },
    );
  }
}
