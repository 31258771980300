import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const residentialUnitsHeaders: ITableHeader[] = [
  {
    key: "unitNo",
    label: "PROPERTIES.UNIT_NUMBER",
    isSortable: true,
    type: TableHeaderType.TextWithHeartAndVisibleIcon,
  },
  {
    key: "address",
    label: "PROPERTIES.ADDRESS",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    key: "room",
    label: "PROPERTIES.UNIT_DETAILS",
    isSortable: false,
    type: TableHeaderType.TwoColumnRow,
  },
  {
    key: "price",
    label: "PRICE",
    isSortable: true,
    type: TableHeaderType.PriceRow,
  },
  {
    key: "paymentFrequency",
    label: "CONTRACT",
    isSortable: false,
    type: TableHeaderType.Text,
  },
  {
    key: "unitStatus",
    label: "PROPERTIES.UNITS_STATUS",
    isSortable: false,
    type: TableHeaderType.StatusAndSubStatus,
  },
];
