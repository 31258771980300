import { Component, inject, OnInit } from "@angular/core";
import { IRoomModalInputs } from "@root/views/main/units/unit-details/components/unit-layout/components/room-popup/room-popup-inputs.interface";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UnitLayoutRoomTypeSwitcher } from "@root/views/main/units/unit-details/components/unit-layout/unit-layout-room-type.switcher";
import { Observable, tap } from "rxjs";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { DecisionButtonListComponent } from "@root/shared/decision-button-list/decision-button-list.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import {
  RestroomTypeDescriptionNameTranslationMapper,
  RestroomTypeDetailNameTranslationMapper,
  RoomTypeDetailDescriptionTranslationMapper,
  RoomTypeDetailNameTranslationMapper,
  RoomTypeNameTranslationMapper,
} from "@root/data/market/units/mappers/room-type-translation.mapper";
import { TranslateModule } from "@ngx-translate/core";
import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { generateTranslatedDropdownOptionsFromEnum } from "@root/shared/utilities/dropdown-options.utilities";
import { Orientation } from "@root/data/market/units/enums/orientation.enum";
import { OrientationTranslationMapper } from "@root/data/market/units/mappers/orientation-translation.mapper";
import { castControlFromAbstractToFormControl } from "@root/shared/utilities/form.utilities";
import {
  RestroomTypePopupControlsMapper,
  RoomTypePopupControlsMapper,
} from "@root/views/main/units/unit-details/components/unit-layout/components/room-popup/room-popup-controls.mapper";
import { RestroomTypeTranslationMapper } from "@root/data/market/units/mappers/restroom-type-translation.mapper";
import { RoomService } from "@root/data/market/rooms/services/room.service";
import { IPopupControl } from "@root/views/main/units/unit-details/components/unit-layout/components/room-popup/popup-control.interface";

@Component({
  selector: "est-room-popup",
  standalone: true,
  imports: [
    MatSlideToggle,
    ReactiveFormsModule,
    SelectFieldComponent,
    DecisionButtonListComponent,
    LoadingOverlayComponent,
    ButtonComponent,
    TooltipComponent,
    TranslateModule,
  ],
  templateUrl: "./room-popup.component.html",
  styleUrl: "./room-popup.component.scss",
})
export class RoomPopupComponent implements OnInit {
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;
  protected componentInputs: IRoomModalInputs = inject(MAT_DIALOG_DATA);
  readonly #matDialog = inject(MatDialog);
  readonly #roomService = inject(RoomService);
  readonly #unitLayoutFormControlsSwitcher = inject(UnitLayoutRoomTypeSwitcher);
  protected formGroup: FormGroup = new FormGroup({});
  protected isLoading: boolean = false;
  protected OrientationOptions = generateTranslatedDropdownOptionsFromEnum(Orientation, OrientationTranslationMapper);
  protected PopupControls: Array<IPopupControl> = [];
  protected isFormSubmitted: boolean = false;
  ngOnInit() {
    this.#getRoom().subscribe({
      complete: () => {
        this.updateOrientationControlState(this.formGroup.controls["showRoomOrientation"].value);
        if (this.componentInputs.roomType === RoomType.Restroom) {
          this.PopupControls = RestroomTypePopupControlsMapper.get(this.componentInputs.restroomType!)!;
        } else {
          this.PopupControls = RoomTypePopupControlsMapper.get(this.componentInputs.roomType)!;
        }
        this.isLoading = false;
      },
    });
  }

  get getPopupTitle(): string {
    if (this.componentInputs.roomType === RoomType.Restroom) {
      return RestroomTypeTranslationMapper.get(this.componentInputs.restroomType!)!;
    }

    return RoomTypeNameTranslationMapper.get(this.componentInputs.roomType)!;
  }

  get getDetailName(): string {
    if (this.componentInputs.roomType === RoomType.Restroom) {
      return RestroomTypeDetailNameTranslationMapper.get(this.componentInputs.restroomType!)!;
    }

    return RoomTypeDetailNameTranslationMapper.get(this.componentInputs.roomType)!;
  }

  get getDetailDescription(): string {
    if (this.componentInputs.roomType === RoomType.Restroom) {
      return RestroomTypeDescriptionNameTranslationMapper.get(this.componentInputs.restroomType!)!;
    }

    return RoomTypeDetailDescriptionTranslationMapper.get(this.componentInputs.roomType)!;
  }

  #getRoom(): Observable<object> {
    this.isLoading = true;
    const roomConstructionMethod = this.#unitLayoutFormControlsSwitcher.roomTypeFormGroup.get(
      this.componentInputs.roomType,
    )!;

    if (this.componentInputs.roomType === RoomType.Restroom) {
      return this.#roomService
        .getById(this.componentInputs.roomId, this.componentInputs.restroomType! as unknown as RoomType)
        .pipe(
          tap((roomObject) => {
            this.formGroup = roomConstructionMethod(roomObject);
          }),
        );
    }

    return this.#roomService.getById(this.componentInputs.roomId, this.componentInputs.roomType).pipe(
      tap((roomObject) => {
        this.formGroup = roomConstructionMethod(roomObject);
      }),
    );
  }

  #updateRoom(): Observable<void> {
    this.isFormSubmitted = true;
    if (this.componentInputs.roomType === RoomType.Restroom) {
      return this.#roomService.update(
        this.componentInputs.roomId,
        this.formGroup.value,
        this.componentInputs.restroomType! as unknown as RoomType,
      );
    }
    return this.#roomService.update(this.componentInputs.roomId, this.formGroup.value, this.componentInputs.roomType);
  }

  protected updateOrientationControlState(value: boolean) {
    if (value) {
      this.formGroup.controls["roomOrientation"].enable();
    } else {
      this.formGroup.controls["roomOrientation"].disable();
    }
  }

  closeDialog(): void {
    this.#matDialog.closeAll();
  }

  saveAndCloseDialog(): void {
    this.#updateRoom().subscribe({
      complete: () => {
        this.isFormSubmitted = false;
        this.closeDialog();
      },
    });
  }
}
