import { inject, Injectable } from "@angular/core";
import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { PropertyMediaPackageSortOrder } from "@root/data/market/properties/enums/property-media-package-sort-order.enum";
import { IMediaFormGroup } from "@root/views/main/property/property-marketing/components/media-form-group.interface";

@Injectable()
export class PropertyMediaPackageFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #internalTitleControl = this.#fb.control<string | undefined>(undefined, Validators.required);
  readonly #sortMediaOrderControl = this.#fb.control<PropertyMediaPackageSortOrder>(
    PropertyMediaPackageSortOrder.InOrder,
  );
  readonly #allowChangeOnUnitLevelControl = this.#fb.control<boolean>(false);
  readonly #allowDeLinkingOnUnitLevelControl = this.#fb.control<boolean>(false);
  readonly #isActiveControl = this.#fb.control<boolean>(false);
  readonly #mediasControl = this.#fb.array<FormGroup<IMediaFormGroup>>([]);

  firstStepGroup = this.#fb.group({
    internalTitle: this.#internalTitleControl,
    sortMediaOrder: this.#sortMediaOrderControl,
    allowChangeOnUnitLevel: this.#allowChangeOnUnitLevelControl,
    allowDelinkingOnUnitLevel: this.#allowDeLinkingOnUnitLevelControl,
    isActive: this.#isActiveControl,
  });

  secondStepGroup = this.#fb.group({
    medias: this.#mediasControl,
  });

  formGroup = this.#fb.group({
    internalTitle: this.#internalTitleControl,
    sortMediaOrder: this.#sortMediaOrderControl,
    allowChangeOnUnitLevel: this.#allowChangeOnUnitLevelControl,
    allowDeLinkingOnUnitLevel: this.#allowDeLinkingOnUnitLevelControl,
    isActive: this.#isActiveControl,
    medias: this.#mediasControl,
  });
}
