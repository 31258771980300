import { inject, signal } from "@angular/core";
import { CreatePropertyEventFormControls } from "@root/views/main/property/property-events/create-property-event/create-property-event.form-controls";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { constructInitialAddressLookups } from "@root/shared/utilities/address.utilities";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { MatDialog } from "@angular/material/dialog";
import { PropertiesEventsService } from "@root/data/market/properties/services/properties-events.service";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ICheckedItem } from "@root/shared/interfaces/checked-item.interface";
import { IAllCheckedItems } from "@root/shared/interfaces/checked-items.interface";
import { PropertyEventBookingType } from "@root/data/market/properties/enums/property-event-booking-type.enum";
import { PropertyEventRepetitionPopupComponent } from "@root/shared/property-event-repetition-popup/property-event-repetition-popup.component";
import { ICreatePropertyEventRepetitionInputs } from "@root/shared/property-event-repetition-popup/property-event-repetition-inputs.interface";
import {
  getEventRepetitionEndDate,
  getEventRepetitionStartDate,
} from "@root/shared/utilities/event.repeitition.utilities";

export abstract class BaseEventForm {
  readonly componentFormControls = inject(CreatePropertyEventFormControls);
  readonly lookupsService = inject(LookupsService);
  readonly isSendingRequest = signal(false);
  readonly agentNamesHashmap = new Map<number, string>();
  addressLookupsOptions = constructInitialAddressLookups();
  responsibleAgentsOptions: IDropdownOption[] = [];
  readonly matDialog = inject(MatDialog);
  readonly propertiesEventsService = inject(PropertiesEventsService);
  readonly snackbarService = inject(SnackbarService);
  readonly router = inject(Router);
  readonly route = inject(ActivatedRoute);
  protected readonly PropertyEventBookingType = PropertyEventBookingType;

  get formControls() {
    return this.componentFormControls.form.controls;
  }

  get bookingFormControls() {
    return this.componentFormControls.form.controls.booking.controls;
  }

  get eventStartDate() {
    return getEventRepetitionStartDate({
      startDate: this.formControls.date.value!,
      repetitionCycle: this.formControls.repetition.value.repetitionCycle!,
    });
  }

  get eventEndDate() {
    return getEventRepetitionEndDate({
      endDate: this.formControls.repetition.value.endDate,
      startDate: this.formControls.date.value!,
      repetitionCycle: this.formControls.repetition.value.repetitionCycle!,
      repetitionEndType: this.formControls.repetition.value.endType!,
      occurrences: this.formControls.repetition.value.occurrences,
    });
  }

  checkItem(event: ICheckedItem) {
    if (event.checked) {
      this.agentNamesHashmap.set(event.row.id, event.row["name"]);
    } else {
      this.agentNamesHashmap.delete(event.row.id);
    }
  }

  checkAllItems(event: IAllCheckedItems) {
    event.rows.forEach((row) => {
      if (event.checked) {
        this.agentNamesHashmap.set(row.id, row["name"]);
      } else {
        this.agentNamesHashmap.delete(row.id);
      }
    });
  }

  getAddressLookupsOptions(): void {
    this.lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }

  getResponsibleAgents(propertyId: number) {
    return this.propertiesEventsService
      .getAvailableUsersLookups(propertyId)
      .subscribe((res) => (this.responsibleAgentsOptions = res));
  }

  selectedUnits(event: string[]) {
    this.componentFormControls.form.controls.selectedUnitsIds.setValue(event);
  }

  openRepetitionModal() {
    this.matDialog.open<PropertyEventRepetitionPopupComponent, ICreatePropertyEventRepetitionInputs>(
      PropertyEventRepetitionPopupComponent,
      {
        width: "40rem",
        data: {
          repetitionForm: this.componentFormControls.form.controls.repetition,
          startDateControl: this.formControls.date,
        },
      },
    );
  }
}
