import { Directive, inject, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { isUserAuthorized } from "../../shared/utilities/auth.utilities";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthService } from "../../data/market/auth/services/auth.service";
import { UserRole } from "../../data/market/users/enums/user-role.enum";

@Directive({
  selector: "[estAuthorizedRole]",
  standalone: true,
})
export class AuthorizedRoleDirective {
  readonly #templateRef = inject(TemplateRef);
  readonly #viewContainerRef = inject(ViewContainerRef);
  readonly #authService = inject(AuthService);
  #authorizedRole: UserRole | undefined;
  #isRendered = false;
  readonly #recheckAuthorizationOnDivisionChange$ = this.#authService.divisionChange$
    .pipe(takeUntilDestroyed())
    .subscribe(() => {
      this.#checkIfUserIsAuthorized(this.#authorizedRole);
    });

  @Input() set estAuthorizedRole(authorizedRole: UserRole | undefined) {
    this.#checkIfUserIsAuthorized(authorizedRole);
    this.#authorizedRole = authorizedRole;
  }

  #checkIfUserIsAuthorized(authorizedRole: UserRole | undefined) {
    if (
      (!authorizedRole || isUserAuthorized(this.#authService.getCurrentUserRole(), authorizedRole)) &&
      !this.#isRendered
    ) {
      this.#viewContainerRef.createEmbeddedView(this.#templateRef);
      this.#isRendered = true;
    } else if (this.#isRendered && !isUserAuthorized(this.#authService.getCurrentUserRole(), authorizedRole)) {
      this.#viewContainerRef.clear();
      this.#isRendered = false;
    }
  }
}
