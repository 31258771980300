<est-dialog-header label="LINKED_UNITS.NAME" tooltipLabel="LINKED_UNITS.NAME"></est-dialog-header>
<div class="p-6">
  <p class="pb-6 font-medium text-base">
    {{ "LINKED_UNITS.UNITS_LINKED_TO_THE_FEATURE" | translate }}
  </p>
  <p class="font-light text-sm pb-6">{{ "LINKED_UNITS.UNITS_LINKED_TO_THE_FEATURE_DESCRIPTION" | translate }}</p>
  <div class="flex gap-2 justify-end mb-6 flex-wrap">
    <est-select-field
      [dropDownOptions]="[]"
      [selectMultipleOptions]="false"
      appliedClass="!w-[18.75rem]"
      dropDownLabel="SET_UNIT_FILTER"
    ></est-select-field>
    <est-search-input [searchSignal]="searchSignal"></est-search-input>
  </div>
  <est-small-list-table
    [data]="paginatedData.results()"
    [headers]="headers"
    [sortingFilterSignal]="sortingFilterSignal"
    [isLoading]="isTableLoading"
    [iconMenuComponentType]="outdoorSpaceForUnitComponent"
  ></est-small-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
