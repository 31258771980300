<div class="flex flex-col w-full gap-6">
  <div class="flex justify-between items-center">
    <est-small-header
      appliedClasses="!mb-2"
      heading="SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_STATUS.NAME"
      tooltip="SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_STATUS.NAME"
      subheading="SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_STATUS.DESCRIPTION"
    ></est-small-header>
    <est-button
      imageSrc="assets/images/icons/plus.svg"
      imagePosition="before"
      imageAlt="+"
      buttonStyleType="button--primary"
      buttonType="button"
      label="CREATE_NEW"
      (click)="openCreatePopup()"
    ></est-button>
  </div>
  <est-small-list-table
    [actions]="actions"
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
    (toggleItem)="updateFollowUpStatusInheritance($event.row)"
  ></est-small-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
