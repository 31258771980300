import { INodeItem } from "@root/shared/interfaces/division-tree-node-item.interface";
import { DIVISION_HIERARCHY_RESULT } from "@root/shared/constants/global.constants";

export enum nodeCreatorIdentifier {
  ROOT = "root",
  NO_COMPANY = "noCompany",
  COMPANY = "company",
  PROPERTY = "property",
  NO_PROPERTIES = "noProperties",
}
export const propertyNodeCreators = new Map<string, (data?: any, index?: number) => INodeItem>([
  [nodeCreatorIdentifier.ROOT, (data: any) => ({ item: data.division.name, children: [] })],
  [nodeCreatorIdentifier.NO_COMPANY, () => ({ item: DIVISION_HIERARCHY_RESULT.NO_COMPANIES, children: [] })],
  [nodeCreatorIdentifier.COMPANY, (data: any) => ({ item: data.name, children: [] })],
  [
    nodeCreatorIdentifier.PROPERTY,
    (data: any, index?: number) => ({ item: data.name, id: data.id, children: [], index }),
  ],
  [nodeCreatorIdentifier.NO_PROPERTIES, () => ({ item: DIVISION_HIERARCHY_RESULT.NO_PROPERTIES, children: [] })],
]);
