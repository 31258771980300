import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-my-page",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./my-page.component.html",
  styleUrl: "./my-page.component.scss",
})
export class MyPageComponent {}
