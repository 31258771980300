<div class="flex justify-between items-end gap-6 mb-10">
  <est-small-header
    heading="ASSIGNED_AGENTS"
    subheading="EVENTS.ASSIGNED_AGENTS_DESCRIPTION"
    tooltip="ASSIGNED_AGENTS"
  ></est-small-header>
  <est-search-input [searchSignal]="searchSignal"></est-search-input>
</div>
@if (eventDate()) {
  <est-small-list-table
    (checkItem)="checkItem.emit($event)"
    (checkAllItems)="checkAllItems.emit($event)"
    [checkboxControlMapper]="checkboxControlMapper()"
    [isLoading]="isTableLoading"
    [iconMenuComponentType]="UserIdCardComponent"
    [data]="paginatedData.results()"
    [headers]="headers"
    [sortingFilterSignal]="sortingFilterSignal"
  ></est-small-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
}
