<est-dialog-header
  label="TOOLBOX.IMPORT_DATA.MATCH_COLUMN_TITLE"
  tooltipLabel="TOOLBOX.IMPORT_DATA.MATCH_COLUMN_TITLE"
></est-dialog-header>
<div class="p-6">
  <p class="font-light text-sm mb-4">{{ "TOOLBOX.IMPORT_DATA.MATCH_COLUMN_DESCRIPTION" | translate }}</p>
  <p class="text-sm">
    {{ "TOOLBOX.IMPORT_DATA.SELECTED_IMPORT_TYPE" | translate }}:
    <span class="font-light">{{ ImportTypeTranslationMapper.get(componentInputs.importType)! | translate }}</span>
  </p>
  <p class="text-sm mb-6">
    {{ "TOOLBOX.IMPORT_DATA.SELECTED_UNIT_TYPE" | translate }}:
    <span class="font-light">{{ UnitTypeTranslationMapper.get(componentInputs.unitType)! | translate }}</span>
  </p>
  <p class="text-sm mb-4">
    {{ "TOOLBOX.IMPORT_DATA.REGISTERED_COLUMN_NAME_IN_CSV" | translate }}:
    <span class="font-light">{{ componentInputs.csvColumnName }}</span>
  </p>
  <est-import-columns-select-field
    [control]="mappedUnitFieldControl"
    [disabledOptions]="disabledDatabaseFields"
    [dropdownOptions]="databaseFieldsOptions"
  ></est-import-columns-select-field>
  <p class="text-xs font-light mt-2">{{ "TOOLBOX.IMPORT_DATA.RESIDENTIAL_UNITS_NOTE" | translate }}</p>
</div>
<div class="px-6 py-2.5 flex justify-between items-center bg-black-50">
  <est-button (click)="close()" buttonStyleType="button--tertiary" buttonType="button" label="CANCEL" />
  <est-button
    (click)="componentInputs.callbackFn(componentInputs.csvColumnName, mappedUnitFieldControl.value!); close()"
    [disabled]="!mappedUnitFieldControl.value"
    buttonStyleType="button--primary"
    buttonType="button"
    label="CONFIRM"
  />
</div>
