import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "@root/core/api/market.apis";
import { ITimezone } from "@root/data/market/timezones/models/timezone.model";

@Injectable({
  providedIn: "root",
})
export class TimezonesRepository {
  readonly #http = inject(HttpClient);

  getSystemTimeZones(): Observable<ITimezone[]> {
    return this.#http.get<ITimezone[]>(APIS.timezones.getSystemTimeZones);
  }
}
