import { Component, input } from "@angular/core";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from "@angular/material/expansion";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { InputComponent } from "@root/shared/input/input.component";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { getControlInFormGroup } from "@root/shared/utilities/form.utilities";
import { CkTextEditorComponent } from "@root/views/main/property/property-marketing/components/texts/property-texts/ck-text-editor/ck-text-editor.component";
import { MatIcon } from "@angular/material/icon";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { NgOptimizedImage } from "@angular/common";
import { ITextFieldGroup } from "../../../text-field-form-group.interface";

@Component({
  selector: "est-text-editor-accordion",
  standalone: true,
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    SmallHeaderComponent,
    MatSlideToggle,
    TranslateModule,
    InputComponent,
    ReactiveFormsModule,
    CkTextEditorComponent,
    MatIcon,
    NgOptimizedImage,
  ],
  templateUrl: "./text-editor-accordion.component.html",
  styleUrl: "./text-editor-accordion.component.scss",
})
export class TextEditorAccordionComponent {
  textFieldFormGroup = input.required<FormGroup<ITextFieldGroup>>();
  allowedLanguage = input.required<ILanguage>();
  panelOpenState = false;
  protected readonly getControlInFormGroup = getControlInFormGroup;

  toggleHeadlineRequired(checkedValue: boolean) {
    if (!checkedValue) {
      this.textFieldFormGroup().controls.headline.disable();
    } else {
      this.textFieldFormGroup().controls.headline.enable();
    }
    this.textFieldFormGroup().controls.isHeadlineEnabled.setValue(checkedValue);
  }
}
