<div class="w-full flex gap-4 flex-wrap items-start">
  <div>
    @if (isDefault()) {
      <est-small-header [heading]="label()" [tooltip]="label()"></est-small-header>
    }
    <est-input
      [label]="label()"
      appliedClass="w-[37.5rem]"
      [control]="castControlFromAbstractToFormControl(formGroup().controls['title'])"
    ></est-input>
  </div>
  <div>
    @if (isDefault()) {
      <est-small-header heading="LANGUAGE" tooltip="LANGUAGE"></est-small-header>
    }
    <div class="flex gap-4 items-center">
      <est-language-display
        appliedClass="font-sm font-light text-black-500 px-4 py-2 h-[2.75rem]"
        [small]="true"
        [language]="language()"
        [isDefault]="isDefault()"
        [disabled]="!formGroup().controls['enabled'].value"
      ></est-language-display>
      @if (formGroup().controls["enabled"]) {
        <mat-slide-toggle
          [checked]="formGroup().controls['enabled'].value"
          (change)="toggleEnabled()"
          [disabled]="isDefault()"
        >
          {{ "ENABLED" | translate }}
        </mat-slide-toggle>
      }
    </div>
  </div>
</div>
