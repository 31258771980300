import { Component, OnInit, WritableSignal, inject, signal } from "@angular/core";
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { IOrganisationDetails } from "@root/data/market/organisation/models/organisation.model";
import { OrganizationService } from "@root/data/market/organisation/services/organisation.service";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InputComponent } from "@root/shared/input/input.component";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { constructInitialAddressLookups, formatAddress } from "@root/shared/utilities/address.utilities";
import {
  arePhoneControlsDirty,
  constructAddressFormGroup,
  getControlInFormGroup,
  markAllControlsAsTouchedAndDirty,
  markControlAsTouchedAndDirty,
  updateNestedControlsPathAndValue,
} from "@root/shared/utilities/form.utilities";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";
import { TransferOwnershipComponent } from "./transfer-ownership/transfer-ownership.component";

@Component({
  selector: "est-organisation-details",
  standalone: true,
  imports: [
    SubheaderComponent,
    InputComponent,
    TranslateModule,
    AddressComponent,
    ReactiveFormsModule,
    SelectFieldComponent,
    LoadingOverlayComponent,
    PhoneInputComponent,
    TooltipComponent,
    ButtonComponent,
    SmallHeaderComponent,
  ],
  templateUrl: "./organisation-details.component.html",
  styleUrl: "./organisation-details.component.scss",
})
export class OrganisationDetailsComponent implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #matDialog = inject(MatDialog);
  readonly #organisationService = inject(OrganizationService);
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #lookupsService = inject(LookupsService);

  isAddressLoadingSignal = signal(false);
  formattedAddressSignal: WritableSignal<string> = signal("");

  isPageLoading = false;
  addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();

  getControl(controlName: string): FormControl<any> {
    return getControlInFormGroup(this.organisationDetailsFG, controlName);
  }
  addressFormGroup = constructAddressFormGroup(this.#fb);
  phoneFormControl = this.#fb.control<IPhoneNumber>(constructInitialPhoneNumberValue(), Validators.required);

  organisationDetailsFG: FormGroup = this.#buildForm();

  ngOnInit(): void {
    this.#getAddressLookupsOptions();
    this.#getOrganisationDetails();
  }

  get isOrganisationOwner(): boolean {
    return this.#authService.getUserId() === this.getControl("ownerId").value;
  }

  openTransferOwnershipModal() {
    this.#matDialog
      .open(TransferOwnershipComponent, {
        maxWidth: "27.5dvw",
        minHeight: "66dvh",
        data: {
          currentOwnerUserId: this.getControl("ownerId").value,
          currentOwnerName: this.getControl("organisationOwner").value,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (!data?.newOwnerFullName) return;
        this.getControl("organisationOwner").setValue(data.newOwnerFullName);
        this.getControl("ownerId").setValue(data.newOwnerUserId);
      });
  }

  changeDomain(): void {
    console.log("Not Implemented Yet!");
  }

  updateOrganisationDetails(): void {
    if (
      this.phoneFormControl.touched &&
      arePhoneControlsDirty(this.phoneFormControl, this.organisationDetailsFG) &&
      this.phoneFormControl.valid
    ) {
      this.#mapPhoneControlToOrganisationDetails();
      this.#markPhoneControlsInParentAsTouchedAndDirty();
    }
    this.#sendPatchRequest();
  }

  patchAddressFields(changedField: { name: string; value: number | string }): void {
    if (changedField.name === "vat") {
      markAllControlsAsTouchedAndDirty(this.addressFormGroup);
      this.#sendPatchRequest();
      return;
    }
    markControlAsTouchedAndDirty(getControlInFormGroup(this.addressFormGroup, changedField.name));
    this.#sendPatchRequest();
  }

  #sendPatchRequest(): void {
    const controlPaths = updateNestedControlsPathAndValue(this.organisationDetailsFG);
    !!Object.keys(controlPaths).length &&
      this.#organisationService.patchOrganisationDetails(controlPaths).subscribe({
        next: () => {
          this.formattedAddressSignal.set(formatAddress(this.addressFormGroup));
        },
      });
  }

  #markPhoneControlsInParentAsTouchedAndDirty(): void {
    markControlAsTouchedAndDirty(this.getControl("contactPersonPhoneNo"));
    markControlAsTouchedAndDirty(this.getControl("contactPersonIsdCode"));
  }

  #mapPhoneControlToOrganisationDetails(): void {
    this.organisationDetailsFG.patchValue({
      contactPersonPhoneNo: this.phoneFormControl.value?.number,
      contactPersonIsdCode: this.phoneFormControl.value?.countryCode,
    });
  }

  #buildForm(): FormGroup {
    return this.#fb.group({
      name: this.#fb.control("", Validators.required),
      lastUpdatedAt: this.#fb.control(""),
      lastUpdatedBy: this.#fb.control(""),
      organisationOwner: this.#fb.control("", Validators.required),
      ownerId: this.#fb.control("", Validators.required),
      createdAt: this.#fb.control(""),
      createdBy: this.#fb.control(""),

      addressInformation: this.addressFormGroup,

      contactPersonName: this.#fb.control("", Validators.required),
      contactPersonEmail: this.#fb.control("", [Validators.required, Validators.email]),
      contactPersonPhoneNo: this.#fb.control("", Validators.required),
      contactPersonIsdCode: this.#fb.control("", Validators.required),
      websiteUrl: this.#fb.control(""),

      domainName: this.#fb.control("", Validators.required),
    });
  }

  #getOrganisationDetails(): void {
    this.isPageLoading = true;
    this.#organisationService.getOrganisationDetails().subscribe({
      next: (organisationDetails) => {
        this.organisationDetailsFG.patchValue(organisationDetails);
        this.#setPhoneFGValues(organisationDetails);
        this.#disableControls(["organisationOwner", "domainName"]);
        this.isPageLoading = false;
      },
      error: () => {
        this.isPageLoading = false;
      },
    });
  }

  #setPhoneFGValues(organisationDetails: IOrganisationDetails): void {
    this.phoneFormControl.setValue({
      internationalNumber: organisationDetails.contactPersonPhoneNo,
      number: organisationDetails.contactPersonPhoneNo,
      countryCode: organisationDetails.contactPersonIsdCode,
    });
  }

  #disableControls(controlNamesArray: string[]): void {
    controlNamesArray.forEach((controlName) => {
      this.getControl(controlName).disable();
    });
  }

  #getAddressLookupsOptions(): void {
    this.#lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }
}
