import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";
import { APIS } from "@root/core/api/market.apis";

@Injectable({
  providedIn: "root",
})
export class SystemConfigurationsRepository {
  readonly #http = inject(HttpClient);

  getSystemLanguageConfiguration(): Observable<ISystemLanguageConfiguration> {
    return this.#http.get<ISystemLanguageConfiguration>(APIS.organisation.getLanguageSystemConfiguration);
  }

  patchSystemLanguageConfiguration(body: Partial<ISystemLanguageConfiguration>): Observable<void> {
    return this.#http.patch<void>(APIS.organisation.patchLanguageSystemConfiguration, body);
  }

  toggleSystemLanguageConfigurationAllowedLanguageActiveInEndUser(languageId: string): Observable<void> {
    return this.#http.post<void>(APIS.organisation.toggleAllowedLanguageActiveInEnder, `"${languageId}"`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    });
  }

  deleteAllowedLanguage(allowedLanguageId: number): Observable<void> {
    return this.#http.delete<void>(APIS.organisation.deleteAllowedLanguage, { body: allowedLanguageId });
  }

  addAllowedLanguages(languageIds: string[]): Observable<void> {
    return this.#http.post<void>(APIS.organisation.addAllowedLanguages, languageIds);
  }
}
