<est-small-header
  tooltip="SYSTEM_MODULES.CUSTOMER_PAGE.STYLING"
  heading="SYSTEM_MODULES.CUSTOMER_PAGE.STYLING"
  appliedClasses="text-xl"
></est-small-header>
<p class="font-light mt-2 text-base pe-56">{{ "STYLING.STYLING_DESCRIPTION" | translate }}</p>
<p class="text-base font-medium mt-12">{{ "STYLING.ALLOW_SETTING_CHANGE_AT_LEVELS_BELOW" | translate }}</p>
<p class="text-sm font-light mt-4 mb-6">{{ "STYLING.ALLOW_SETTING_DESCRIPTION" | translate }}</p>
<form [formGroup]="stylingFormControls.organizationFormGroup" (change)="updateStyling()">
  <mat-slide-toggle
    class="text-sm font-light mb-6"
    [formControl]="
      stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.allowChangesOnLevelBelow
    "
    (change)="updateStyling()"
    >{{ "STYLING.ALLOW_CHANGE_AT_LEVELS_BELOW" | translate }}</mat-slide-toggle
  >
  <hr class="absolute start-0 end-0 border-black-100" />

  <est-small-header tooltip="STYLING.COLOURS" heading="STYLING.COLOURS" appliedClasses="mt-6"></est-small-header>
  <p class="text-sm font-light pe-44 mb-2">{{ "STYLING.COLOURS_DESCRIPTION" | translate }}</p>
  <p class="text-sm font-light pe-44 text-black-500">
    {{ "STYLING.SETTING_IS_INHERITED_TO_LEVELS_BELOW" | translate }}
  </p>
  <div class="flex gap-6 mt-4 flex-wrap">
    <est-color-picker-input
      label="STYLING.HEADER_SECTION"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.headerSection"
    ></est-color-picker-input>
    <est-color-picker-input
      label="STYLING.HEADER_TEXT"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.headerText"
    ></est-color-picker-input>
  </div>
  <div class="flex gap-6 mt-6 flex-wrap">
    <est-color-picker-input
      label="STYLING.FOOTER_SECTION"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.footerSection"
    ></est-color-picker-input>
    <est-color-picker-input
      label="STYLING.FOOTER_BORDER"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.footerBorder"
    ></est-color-picker-input>
    <est-color-picker-input
      label="STYLING.FOOTER_TEXT"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.footerText"
    ></est-color-picker-input>
  </div>
  <div class="flex gap-6 mt-6 flex-wrap">
    <est-color-picker-input
      label="STYLING.PRIMARY_BUTTON"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.ctaPrimaryButton"
    ></est-color-picker-input>
    <est-color-picker-input
      label="STYLING.PRIMARY_TEXT"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.ctaPrimaryText"
    ></est-color-picker-input>
  </div>
  <div class="flex gap-6 mt-6 flex-wrap">
    <est-color-picker-input
      label="STYLING.SECONDARY_BUTTON"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.ctaSecondaryButton"
    ></est-color-picker-input>
    <est-color-picker-input
      label="STYLING.SECONDARY_TEXT"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.ctaSecondaryText"
    ></est-color-picker-input>
  </div>
  <div class="flex gap-6 mt-6 flex-wrap">
    <est-color-picker-input
      label="STYLING.LINKS"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.links"
    ></est-color-picker-input>
  </div>
  <div class="flex gap-6 mt-6 flex-wrap">
    <est-color-picker-input
      label="STYLING.TIME_SLOT_BUTTON.ACTIVE_BUTTON"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.timeSlotActiveButton"
    ></est-color-picker-input>
    <est-color-picker-input
      label="STYLING.TIME_SLOT_BUTTON.ACTIVE_TEXT"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.timeSlotActiveText"
    ></est-color-picker-input>
  </div>
  <div class="flex gap-6 my-6 flex-wrap">
    <est-color-picker-input
      label="STYLING.TIME_SLOT_BUTTON.INACTIVE_BUTTON"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.timeSlotInActiveButton"
    ></est-color-picker-input>
    <est-color-picker-input
      label="STYLING.TIME_SLOT_BUTTON.INACTIVE_TEXT"
      [control]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.timeSlotInActiveText"
    ></est-color-picker-input>
  </div>
  <hr class="absolute start-0 end-0 border-black-100" />
  <est-small-header tooltip="STYLING.VISUALS" heading="STYLING.VISUALS" appliedClasses="mt-10"></est-small-header>
  <p class="text-sm font-light pe-44 mb-2">{{ "STYLING.VISUALS_DESCRIPTION" | translate }}</p>
  <p class="text-sm font-light pe-44 text-black-500 mb-6">
    {{ "STYLING.SETTING_IS_INHERITED_TO_LEVELS_BELOW" | translate }}
  </p>
  <!--upload Header image (Mobile)-->
  <est-small-header
    tooltip="STYLING.HEADER_IMAGE.MOBILE"
    heading="STYLING.HEADER_IMAGE.MOBILE"
    appliedClasses="text-sm"
  ></est-small-header>
  <p class="font-light text-sm mb-2">{{ "DIVISIONS.RECOMMENDED_IMAGE" | translate }}: 300 x 200px</p>
  <est-upload-logo-brand
    [fileSize]="stylingFormControls.headerImageMobileFileSizeControl"
    [imageDimensions]="stylingFormControls.headerImageMobileFileDimensionControl"
    [imageName]="stylingFormControls.headerImageMobileFilenameControl"
    [image]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.headerImageMobileUrl"
  ></est-upload-logo-brand>
  <!--upload Header image (Desktop & tablet)-->
  <est-small-header
    tooltip="STYLING.HEADER_IMAGE.DESKTOP"
    heading="STYLING.HEADER_IMAGE.DESKTOP"
    appliedClasses="text-sm mt-6"
  ></est-small-header>
  <p class="font-light text-sm mb-2">{{ "DIVISIONS.RECOMMENDED_IMAGE" | translate }}: 300 x 200px</p>
  <est-upload-logo-brand
    [fileSize]="stylingFormControls.headerImageDesktopTabletFileSizeControl"
    [imageDimensions]="stylingFormControls.headerImageDesktopTabletDimensionControl"
    [imageName]="stylingFormControls.headerImageDesktopTabletFilenameControl"
    [image]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.headerImageDesktopTabletUrl"
  ></est-upload-logo-brand>
  <!--upload logo-->
  <est-small-header tooltip="STYLING.LOGO" heading="STYLING.LOGO" appliedClasses="text-sm mt-6"></est-small-header>
  <p class="font-light text-sm mb-2">{{ "DIVISIONS.RECOMMENDED_IMAGE" | translate }}: 300 x 200px</p>
  <est-upload-logo-brand
    [fileSize]="stylingFormControls.logoFileSizeControl"
    [imageDimensions]="stylingFormControls.logoFileDimensionControl"
    [imageName]="stylingFormControls.logoFileNameControl"
    [image]="stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.controls.logoUrl"
  ></est-upload-logo-brand>
</form>
