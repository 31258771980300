import { inject, Injectable } from "@angular/core";
import { FollowUpTaskRepository } from "@root/data/market/system-configurations/repositories/follow-up-task.repository";
import { IInheritedQueryPayload } from "@root/shared/interfaces/inherited-query-payload.interface";
import { Observable } from "rxjs";
import { IFollowUpTask } from "@root/data/market/system-configurations/follow-up-tasks/models/follow-up-task.model";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { mapFollowUpTaskToSignalResponseSmallTableInput } from "@root/data/market/system-configurations/follow-up-tasks/utilities/follow-up-task.utilities";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";

@Injectable({
  providedIn: "root",
})
export class FollowUpTaskService {
  readonly #followUpTaskRepository = inject(FollowUpTaskRepository);

  getFollowUpTask(payload: IInheritedQueryPayload): Observable<IFollowUpTask> {
    return this.#followUpTaskRepository.getFollowUpTask(payload);
  }

  updateFollowUpTask(payload: IInheritedQueryPayload, body: IFollowUpTask): Observable<void> {
    return this.#followUpTaskRepository.updateFollowUpTask(payload, body);
  }

  createFollowUpTask(body: IFollowUpTask, sourceLevel: SourceLevel, routeId?: string): Observable<void> {
    return this.#followUpTaskRepository.createFollowUpTask(body, sourceLevel, routeId);
  }

  getPaginatedFollowTasks(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
    routeId?: string,
  ): Observable<ISignalPaginatedResponse<IFollowUpTask>> {
    return this.#followUpTaskRepository
      .getPaginatedFollowTasks(params, sourceLevel, routeId)
      .pipe(mapFollowUpTaskToSignalResponseSmallTableInput());
  }

  deleteFollowUpTask(payload: IInheritedQueryPayload): Observable<void> {
    return this.#followUpTaskRepository.deleteFollowUpTask(payload);
  }

  toggleFollowUpTaskInheritance(payload: IInheritedQueryPayload): Observable<void> {
    return this.#followUpTaskRepository.toggleFollowUpTaskInheritance(payload);
  }
}
