import { EventEmitter, Injectable } from "@angular/core";
import { ITextEditorImage } from "@root/shared/interfaces/text-editor.interface";
import { FileLoader, UploadResponse, type UploadAdapter } from "ckeditor5";

@Injectable()
export class TextEditorUploadAdapterService implements UploadAdapter {
  private loader!: FileLoader;
  public imageUploadedEvent: EventEmitter<ITextEditorImage> = new EventEmitter();

  setLoader(loader: FileLoader) {
    this.loader = loader;
    return this;
  }

  upload(): Promise<UploadResponse> {
    return this.loader.file.then(async (file) => {
      const resUrl = window.URL.createObjectURL(file!);
      this.imageUploadedEvent.next({
        file: file!,
        key: resUrl,
      });
      return {
        default: resUrl,
      };
    });
  }

  abort() {
    console.log("aborting file upload");
  }
}
