import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "@root/core/api/market.apis";
import { IGeneralSystemSettings } from "@root/data/market/generalSystemSettings/models/general-system-settings.model";

@Injectable({
  providedIn: "root",
})
export class GeneralSystemSettingsRepository {
  readonly #http = inject(HttpClient);

  getGeneralSystemDefaultSettings(): Observable<IGeneralSystemSettings> {
    return this.#http.get<IGeneralSystemSettings>(APIS.organisation.getGeneralSystemDefaultSettings);
  }

  patchSystemDefaultSettingsConfiguration(body: Partial<IGeneralSystemSettings>): Observable<void> {
    return this.#http.patch<void>(APIS.organisation.patchGeneralSystemDefaultSettings, body);
  }
}
