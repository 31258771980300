<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    [name]="'Jesper Thorkildsen'"
    label="DIVISIONS.NAME"
    tooltip="This is a division"
  ></est-subheader>

  <div class="mb-14"></div>
  <div class="flex mb-6 justify-end gap-4 items-center">
    <est-search-input [searchSignal]="searchSignal"></est-search-input>
    <button
      (click)="openCreateDivisionModal()"
      class="button--primary uppercase tracking-[0.5px] flex items-center gap-2"
    >
      <img alt="plus icon" height="12" ngSrc="assets/images/icons/plus.svg" priority width="12" />
      <span class="font-semibold text-sm">{{ "DIVISIONS.CREATE_NEW_DIVISION" | translate }}</span>
    </button>
  </div>
  <est-large-list-table
    [actions]="actions"
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
    subheaderPrefix="OWNER"
  ></est-large-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
