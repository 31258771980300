import { FormGroup, NonNullableFormBuilder } from "@angular/forms";
import { ITextEditorFormGroup, ITextEditorImage, TextEditorImageFormGroup } from "../interfaces/text-editor.interface";
import { ICreateEditPropertyTextPayload } from "@root/data/market/properties/models/create-edit-property-text-payload.model";

export const constructTextEditorFormGroup = (fb: NonNullableFormBuilder): FormGroup<ITextEditorFormGroup> => {
  return fb.group({
    value: fb.control<string>(""),
    images: fb.array<FormGroup<TextEditorImageFormGroup>>([]),
  });
};

export const constructTextEditorImageFormGroup = (
  fb: NonNullableFormBuilder,
  textImage: ITextEditorImage,
): FormGroup<TextEditorImageFormGroup> => {
  return fb.group({
    file: fb.control(textImage.file),
    key: fb.control(textImage.key),
  });
};

export const buildPropertyTextFormData = (payload: Partial<ICreateEditPropertyTextPayload>): FormData => {
  const formData = new FormData();
  return buildFormData(formData, payload);
};

const buildFormData = (formData: FormData, data: any, parentKey: string | null = null): FormData => {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      const objectKey = data instanceof Array ? `${parentKey}[${key}]` : `${parentKey}.${key}`;
      buildFormData(formData, data[key], parentKey ? objectKey : key);
    });
  } else if (parentKey) {
    const value = data ?? "";
    formData.append(parentKey, value);
  }
  return formData;
};
