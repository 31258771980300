import { inject, Injectable } from "@angular/core";
import { UnitLayoutFormControls } from "@root/views/main/units/unit-details/components/unit-layout/unit-layout.form-controls";
import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class UnitLayoutRoomTypeSwitcher {
  unitLayoutFormControls = inject(UnitLayoutFormControls);

  roomTypeFormGroup = new Map<RoomType, { (roomObject: any): FormGroup }>([
    [RoomType.Bedroom, this.unitLayoutFormControls.constructBedroom.bind(this.unitLayoutFormControls)],
    [RoomType.LivingRoom, this.unitLayoutFormControls.constructLivingRoom.bind(this.unitLayoutFormControls)],
    [RoomType.AdditionalRoom, this.unitLayoutFormControls.constructAdditionalRoom.bind(this.unitLayoutFormControls)],
    [RoomType.Restroom, this.unitLayoutFormControls.constructRestroom.bind(this.unitLayoutFormControls)],
    [
      RoomType.InternalStorageRoom,
      this.unitLayoutFormControls.constructInternalStorageRoom.bind(this.unitLayoutFormControls),
    ],
    [RoomType.Garden, this.unitLayoutFormControls.constructGarden.bind(this.unitLayoutFormControls)],
    [RoomType.Terrace, this.unitLayoutFormControls.constructTerrace.bind(this.unitLayoutFormControls)],
    [RoomType.Balcony, this.unitLayoutFormControls.constructBalcony.bind(this.unitLayoutFormControls)],
  ]);
}
