import { BaseTeamFormComponent } from "@root/shared/abstracts/team-form/team-form.abstract";
import { Component, inject, OnInit } from "@angular/core";
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { InputComponent } from "@root/shared/input/input.component";
import { MatDivider } from "@angular/material/divider";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { TranslateModule } from "@ngx-translate/core";
import { UploadLogoBrandComponent } from "@root/shared/upload-logo-brand/upload-logo-brand.component";
import { MatTooltip } from "@angular/material/tooltip";
import { ImageUploadComponent } from "@root/shared/image-upload/image-upload.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { TeamMemberTableComponent } from "@root/shared/team-member/team-member-table.component";
import { SelectUserFieldComponent } from "@root/shared/select-search-user/select-user-field.component";
import { SelectDivisionFieldComponent } from "@root/shared/select-search-division/select-division-field.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ITeamMember } from "@root/shared/interfaces/team-member.interface";
import { mapTeamFormGroupToFormData } from "@root/data/market/teams/utilities/team-form-data.utilities";
import {
  handleErrorOnRemoveTeamManager,
  setAndUpdateTeamMemberAndTeamLeader,
  setTeamMangerAsTeamMember,
} from "@root/data/market/teams/utilities/handel-team-member-selection-role.utilities";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";

@Component({
  selector: "est-edit-team-form",
  standalone: true,
  imports: [
    MatStepper,
    MatStepperModule,
    InputComponent,
    MatDivider,
    MatProgressBar,
    MatSlideToggle,
    NgOptimizedImage,
    PhoneInputComponent,
    ReactiveFormsModule,
    SelectFieldComponent,
    SelectSearchFieldComponent,
    TranslateModule,
    UploadLogoBrandComponent,
    MatTooltip,
    ImageUploadComponent,
    SearchInputComponent,
    SmallListTableComponent,
    PaginationComponent,
    CreationSuccessMessageComponent,
    NgClass,
    FormsModule,
    ButtonComponent,
    TeamMemberTableComponent,
    SelectUserFieldComponent,
    SelectDivisionFieldComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./edit-team-form.component.html",
  styleUrl: "./edit-team-form.component.scss",
})
export class EditTeamFormComponent extends BaseTeamFormComponent implements OnInit {
  override totalTabsNo = 2;
  componentInputs: { teamId: number } = inject(MAT_DIALOG_DATA);
  teamMember: number[] = [];
  teamLeader: number[] = [];

  ngOnInit() {
    setTeamMangerAsTeamMember(this.teamMemberFormControl, this.teamManagerId, this.destroyRef);
    setAndUpdateTeamMemberAndTeamLeader(
      this.teamMemberFormControl,
      this.teamLeaderFormControl,
      this.teamUsers,
      this.teamManagerId,
      this.destroyRef,
    );
    handleErrorOnRemoveTeamManager(this.teamManagerId, this.destroyRef, (hasValue) => {
      this.isSelectedTeamMangerId = hasValue;
    });
    this.patchTeamData();
    this.divisionId.disable();
  }

  override submitForm(): void {
    const formData = mapTeamFormGroupToFormData(this.teamFormGroup);
    this.isRequestLoading = true;
    this.teamService.updateTeamById(this.componentInputs.teamId, formData).subscribe({
      next: () => {
        this.isRequestLoading = false;
        this.closeDialog();
        this.teamsCommunicationService.reloadTeamPage$.next();
      },
      error: () => {
        this.snackBarService.open(SnackbarType.Error);
        this.closeDialog();
      },
    });
  }

  patchTeamData() {
    this.teamService.getTeamById(this.componentInputs.teamId).subscribe((data) => {
      this.selectedDivisionId.set(data.divisionId);
      this.patchTeamMemberWithRole(data.teamUsers);
      this.teamFormGroup.patchValue(data);
      this.isPageLoading = false;
    });
  }

  patchTeamMemberWithRole(teamUsers: ITeamMember[]) {
    teamUsers.forEach((user: ITeamMember) => {
      this.teamMember.push(user.userId);
      user.isTeamLeader ? this.teamLeader.push(user.userId) : null;
    });
    this.teamMemberFormControl.setValue(this.teamMember);
    this.teamLeaderFormControl.setValue(this.teamLeader);
  }
}
