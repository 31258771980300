import { Component, EventEmitter, inject, input, Output } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { IAllowedLanguage } from "@root/data/market/system-configurations/language/models/other-language.model";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { LanguageDisplayComponent } from "@root/shared/language-display/language-display.component";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";

@Component({
  selector: "est-additional-language-entry",
  standalone: true,
  imports: [
    ButtonComponent,
    MatSlideToggle,
    TranslateModule,
    LanguageDropdownComponent,
    LanguageDisplayComponent,
    SpinnerComponent,
  ],
  templateUrl: "./additional-language-entry.component.html",
  styleUrl: "./additional-language-entry.component.scss",
})
export class AdditionalLanguageEntryComponent {
  readonly #systemConfigurationService = inject(SystemConfigurationsService);
  otherLanguage = input.required<IAllowedLanguage>();
  @Output() deleteLanguage: EventEmitter<IAllowedLanguage> = new EventEmitter<IAllowedLanguage>();
  protected isDeleting: boolean = false;

  onDeleteLanguage() {
    this.isDeleting = true;
    this.#systemConfigurationService.deleteAllowedLanguage(this.otherLanguage().id).subscribe(() => {
      this.isDeleting = false;
      this.deleteLanguage.emit(this.otherLanguage());
    });
  }

  toggleLanguage() {
    this.#systemConfigurationService
      .toggleSystemLanguageConfigurationAllowedLanguageActiveInEndUser(this.otherLanguage().language.languageId)
      .subscribe();
  }
}
