import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const teamsTableHeaders: ITableHeader[] = [
  {
    label: "TEAMS.TEAM_NAME",
    key: "name",
    type: TableHeaderType.TextWithImageOrFirstLetter,
    isSortable: true,
  },
  {
    label: "TEAMS.TEAM_MANAGER",
    key: "teamManager",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "TEAMS.TEAM_LEADERS",
    key: "teamLeaders",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
  },
  {
    label: "MEMBERS",
    key: "teamMembers",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "ASSIGNED_PROPERTIES",
    key: "assignedProperties",
    type: TableHeaderType.TextWithPopup,
    isSortable: true,
  },
  {
    label: "CREATED",
    key: "createdAt",
    type: TableHeaderType.Date,
    isSortable: true,
  },
  {
    label: "STATUS",
    key: "status",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
  },
];
