import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const UNITS_TABLE_HEADERS: ITableHeader[] = [
  {
    key: "ids",
    label: "",
    type: TableHeaderType.Checkbox,
    isSortable: false,
  },
  {
    key: "unitNo",
    label: "LINKED_UNITS.TABLE.UNIT_NO",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "address",
    label: "PROPERTIES.ADDRESS",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "property",
    label: "PROPERTIES.PROPERTY",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "type",
    label: "TYPE",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "floor",
    label: "ADDRESS.FLOOR",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "unitArea",
    label: "UNIT_LAYOUT.UNIT_AREA",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "room",
    label: "LINKED_UNITS.TABLE.ROOM",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "outdoorSpaceTotalArea",
    label: "LINKED_UNITS.TABLE.OUTDOOR_SPACE",
    type: TableHeaderType.TextWithIcon,
    isSortable: false,
  },
  {
    key: "price",
    label: "LINKED_UNITS.TABLE.PRICE",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    key: "subStatus",
    label: "EVENTS.STATUS_ON_UNIT",
    type: TableHeaderType.StatusWithText,
    isSortable: false,
  },
];
