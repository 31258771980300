import { Routes } from "@angular/router";
import { DocumentsComponent } from "@root/views/main/property/property-documents/components/documents/documents.component";
import { DocumentPackagesComponent } from "@root/views/main/property/property-documents/components/document-packages/document-packages.component";
import { SigningPackagesComponent } from "@root/views/main/property/property-documents/components/signing-packages/signing-packages.component";

export const propertyDocumentsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "documents",
  },
  {
    path: "documents",
    component: DocumentsComponent,
    data: {
      name: "DOCUMENTS.NAME",
    },
  },
  {
    path: "document-packages",
    component: DocumentPackagesComponent,
    data: {
      name: "DOCUMENTS.DOCUMENT_PACKAGES.NAME",
    },
  },
  {
    path: "signing-packages",
    component: SigningPackagesComponent,
    data: {
      name: "DOCUMENTS.SIGNING_PACKAGES.NAME",
    },
  },
];
