import { Routes } from "@angular/router";
import { OrganisationDetailsComponent } from "./organization-details/organisation-details.component";
import { OrganizationSettingsComponent } from "./organization-settings/organization-settings.component";
import { organizationSettingsRoutes } from "./organization-settings/organization-settings.routes";
import { OrganizationCustomisationComponent } from "./organization-customisation/organization-customisation.component";
import { OrganizationMaintenanceComponent } from "./organization-maintenance/organization-maintenance.component";
import { OrganizationDivisionsComponent } from "./organization-divisions/organization-divisions.component";
import { OrganizationDocumentsComponent } from "./organization-documents/organization-documents.component";
import { OrganizationSystemModulesComponent } from "./organization-system-modules/organization-system-modules.component";
import { UserRole } from "../../../data/market/users/enums/user-role.enum";
import { organizationSystemModulesRoutes } from "@root/views/main/organization/organization-system-modules/organization-system-modules.routes";

export const organizationRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "divisions",
  },
  {
    path: "details",
    component: OrganisationDetailsComponent,
    data: {
      name: "DETAILS.NAME",
    },
  },
  {
    path: "customisation",
    component: OrganizationCustomisationComponent,
    data: {
      name: "CUSTOMISATION.NAME",
    },
  },
  {
    path: "maintenance",
    component: OrganizationMaintenanceComponent,
    data: {
      name: "MAINTENANCE.NAME",
    },
  },
  {
    path: "divisions",
    component: OrganizationDivisionsComponent,
    data: {
      name: "DIVISIONS.NAME",
    },
  },
  {
    path: "documents",
    component: OrganizationDocumentsComponent,
    data: {
      name: "DOCUMENTS.NAME",
    },
  },
  {
    path: "system-modules",
    component: OrganizationSystemModulesComponent,
    data: {
      name: "SYSTEM_MODULES.NAME",
    },
    children: organizationSystemModulesRoutes,
  },
  {
    path: "settings",
    component: OrganizationSettingsComponent,
    children: organizationSettingsRoutes,
    data: {
      name: "SETTINGS.NAME",
      authorizedRole: UserRole.SystemAdmin,
    },
  },
];
