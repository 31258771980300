import { Component, effect, EffectRef, inject, signal, WritableSignal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { PropertiesEventsService } from "@root/data/market/properties/services/properties-events.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { EventCustomersSortBy } from "@root/shared/enums/property-event-customers.enum";
import { IPropertyEventCustomersPayload } from "@root/shared/interfaces/property-event-customers-payload.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { ICustomerAttendanceModalInputs } from "@root/views/main/property/property-events/customer-attendance-modal/customer-attendance-modal-inputs.interface";
import {
  ATTENDED_CUSTOMERS_HEADERS,
  DROPOUTS_HEADERS,
} from "@root/views/main/property/property-events/customer-attendance-modal/customer-attendance-modal.headers";
import { finalize } from "rxjs/internal/operators/finalize";
import { CustomerListingType } from "../enums/customer-listing-type.enum";

@Component({
  selector: "est-customer-attendance-modal",
  standalone: true,
  imports: [DialogHeaderComponent, TranslateModule, SmallListTableComponent, PaginationComponent, ButtonComponent],
  templateUrl: "./customer-attendance-modal.component.html",
  styleUrl: "./customer-attendance-modal.component.scss",
})
export class CustomerAttendanceModalComponent extends BasePaginatedTableWithSearchComponent {
  readonly matDialog = inject(MatDialog);
  readonly #propertiesEventsService = inject(PropertiesEventsService);
  inputs: ICustomerAttendanceModalInputs = inject(MAT_DIALOG_DATA);

  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: EventCustomersSortBy.Name,
    sortDescending: false,
  });
  readonly loadDataEffect$: EffectRef = effect(() => this.#loadDataWithParams());

  get dialogTitle() {
    return this.inputs.listingType === CustomerListingType.Attended
      ? "EVENTS.ATTENDED_CUSTOMERS_AT_THE_EVENT"
      : "EVENTS.DROPOUTS_AT_THE_EVENT";
  }

  get dialogDescription() {
    return this.inputs.listingType === CustomerListingType.Attended
      ? "EVENTS.ATTENDED_CUSTOMERS_DESCRIPTION"
      : "EVENTS.DROPOUTS_DESCRIPTION";
  }

  get headers() {
    return this.inputs.listingType === CustomerListingType.Attended ? ATTENDED_CUSTOMERS_HEADERS : DROPOUTS_HEADERS;
  }

  override loadData(params: IPropertyEventCustomersPayload): void {
    this.isTableLoading = true;
    this.#propertiesEventsService
      .getEventCustomers(this.inputs.propertyId, this.inputs.eventInstanceId, params)
      .pipe(finalize(() => (this.isTableLoading = false)))
      .subscribe({
        next: (res) => {
          copyPaginatedSignalResponse(this.paginatedData, res);
        },
      });
  }

  toggleFavouriteCustomer(customerId: number) {
    this.#propertiesEventsService.toggleFavouriteCustomer(customerId).subscribe();
  }

  #loadDataWithParams() {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      pageNumber: this.paginatedData.currentPage(),
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      ListingType: this.inputs.listingType,
    });
  }
}
