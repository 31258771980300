import { Route } from "@angular/router";
import { SurveysComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/surveys/surveys.component";
import { BadgesComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/badges/badges.component";
import { LabelsComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/labels/labels.component";
import { PoiComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/poi/poi.component";
import { QrCodesComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/qr-codes/qr-codes.component";
import { PromotionLabelsComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/promotion-labels/promotion-labels.component";
import { CertificationLabelsComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/certification-labels/certification-labels.component";
import { FormBuilderComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/form-builder/form-builder.component";
import { surveysRoutes } from "@root/views/main/organization/organization-settings/components/marketing/components/surveys/surveys.routes";

export const marketingRoutes: Route[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "surveys",
  },
  {
    path: "surveys",
    component: SurveysComponent,
    data: {
      name: "SETTINGS.MARKETING.SURVEYS.NAME",
    },
    children: surveysRoutes,
  },
  {
    path: "badges",
    component: BadgesComponent,
    data: {
      name: "SETTINGS.MARKETING.BADGES.NAME",
    },
  },
  {
    path: "labels",
    component: LabelsComponent,
    data: {
      name: "SETTINGS.MARKETING.LABELS.NAME",
    },
  },
  {
    path: "poi",
    component: PoiComponent,
    data: {
      name: "SETTINGS.MARKETING.POI.NAME",
    },
  },
  {
    path: "qr-codes",
    component: QrCodesComponent,
    data: {
      name: "SETTINGS.MARKETING.QR_CODES.NAME",
    },
  },
  {
    path: "promotion-labels",
    component: PromotionLabelsComponent,
    data: {
      name: "SETTINGS.MARKETING.PROMOTION_LABELS.NAME",
    },
  },
  {
    path: "certification-labels",
    component: CertificationLabelsComponent,
    data: {
      name: "SETTINGS.MARKETING.CERT_LABELS.NAME",
    },
  },
  {
    path: "form-builder",
    component: FormBuilderComponent,
    data: {
      name: "SETTINGS.MARKETING.FORM_BUILDER.NAME",
    },
  },
];
