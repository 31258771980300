import { Injectable, inject } from "@angular/core";
import { PropertyTextsRepository } from "../repositories/properties-texts.repository";
import { Observable } from "rxjs/internal/Observable";
import { IPropertyTextCard } from "../models/property-text-card.interface";
import { ITextsParams } from "@root/shared/interfaces/property-texts-params.interface";
import { IPropertyDuplicateEntity } from "@root/shared/interfaces/property-texts-duplicate.interface";
import { ITextFieldDetails } from "@root/data/market/properties/models/text-field-details.model";
import { ICreateEditPropertyTextPayload } from "@root/data/market/properties/models/create-edit-property-text-payload.model";
import { IPaginationSelectedIdPayload } from "@root/shared/interfaces/pagination-selected-id-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { mapLookupItemToSignalDropdownOptionsOperator } from "@root/data/market/lookups/utilities/lookups.utilties";
import { buildPropertyTextFormData } from "@root/shared/utilities/text-editor.utilties";

@Injectable({
  providedIn: "root",
})
export class PropertyTextsService {
  readonly #propertyTextsRepo = inject(PropertyTextsRepository);

  getPropertyTextOverview(params: ITextsParams): Observable<IPropertyTextCard[]> {
    return this.#propertyTextsRepo.getPropertyTextOverview(params);
  }

  deletePropertyText(propertyId: number, textId: number, params: ITextsParams): Observable<void> {
    return this.#propertyTextsRepo.deletePropertyText(propertyId, textId, params);
  }

  duplicatePropertyText(
    propertyId: number,
    mediaPackageId: number,
    data: Partial<IPropertyDuplicateEntity>,
    params: ITextsParams,
  ): Observable<void> {
    return this.#propertyTextsRepo.duplicatePropertyText(propertyId, mediaPackageId, data, params);
  }

  getTextPlacements(params: IPaginationSelectedIdPayload): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#propertyTextsRepo.getTextPlacements(params).pipe(mapLookupItemToSignalDropdownOptionsOperator());
  }

  getTextFieldDetails(textFieldId: number): Observable<ITextFieldDetails> {
    return this.#propertyTextsRepo.getTextFieldDetails(textFieldId);
  }

  createPropertyText(
    propertyId: number,
    propertyTextDetails: Partial<ICreateEditPropertyTextPayload>,
    params: ITextsParams,
  ): Observable<void> {
    const propertyTextFormData = buildPropertyTextFormData(propertyTextDetails);
    return this.#propertyTextsRepo.createPropertyText(propertyId, propertyTextFormData, params);
  }

  editPropertyText(
    propertyId: number,
    textFieldId: number,
    propertyTextDetails: Partial<ICreateEditPropertyTextPayload>,
  ): Observable<void> {
    const propertyTextFormData = buildPropertyTextFormData(propertyTextDetails);
    return this.#propertyTextsRepo.editPropertyText(propertyId, textFieldId, propertyTextFormData);
  }
}
