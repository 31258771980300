import { Component, inject, OnInit } from "@angular/core";
import { MatStep, MatStepperModule } from "@angular/material/stepper";
import { InputComponent } from "@root/shared/input/input.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { UploadLogoBrandComponent } from "@root/shared/upload-logo-brand/upload-logo-brand.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { GlobalLoaderComponent } from "@root/shared/global-loader/global-loader.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SelectOwnerFieldComponent } from "@root/shared/select-division-owner-field/select-owner-field.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ICreateCompanyPayload } from "@root/data/market/companies/models/create-company-payload.model";
import { CompanyFormControls } from "@root/views/main/division/division-companies/company-form-controls";
import { BaseCompanyForm } from "@root/shared/abstracts/company-form/company-form.abstract";
import { CompaniesCommunicationService } from "@root/views/main/division/division-companies/companies-communication.service";

@Component({
  selector: "est-edit-create-company",
  standalone: true,
  imports: [
    MatStep,
    InputComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatTooltip,
    MatDialogActions,
    MatStepperModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    NgOptimizedImage,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatDividerModule,
    MatSlideToggle,
    UploadLogoBrandComponent,
    SelectFieldComponent,
    AddressComponent,
    SelectSearchFieldComponent,
    PhoneInputComponent,
    NgClass,
    ButtonComponent,
    GlobalLoaderComponent,
    LoadingOverlayComponent,
    SelectOwnerFieldComponent,
  ],
  templateUrl: "./edit-company.component.html",
  styleUrl: "./edit-company.component.scss",
  providers: [CompanyFormControls],
})
export class EditCompanyComponent extends BaseCompanyForm implements OnInit {
  override totalTabsNo = 1;
  isLoading: boolean = false;
  componentInputs: {
    companyId: number;
  } = inject(MAT_DIALOG_DATA);
  #companyCommunicationService = inject(CompaniesCommunicationService);

  ngOnInit(): void {
    this.#getAddressLookupsOptions();
    this.mapPhoneNumberOnContact();
    this.getCompanyDetailsById();
  }

  #getAddressLookupsOptions(): void {
    this.lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }

  isDataBeingLoading(): boolean {
    return this.isAddressLoadingSignal();
  }

  override submitForm() {
    this.isLoading = true;
    const editCompanyData: ICreateCompanyPayload = this.companyFormControls.companyFormGroup
      .value as ICreateCompanyPayload;
    this.companiesService.updateCompany(editCompanyData, this.componentInputs.companyId).subscribe({
      next: () => {
        this.isLoading = false;
        this.dialog.closeAll();
        this.#companyCommunicationService.reloadCompaniesTable$.next();
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  getCompanyDetailsById() {
    this.isLoading = true;
    this.companiesService
      .getCompanyDetails(this.componentInputs.companyId)
      .subscribe((response: ICreateCompanyPayload) => {
        this.isLoading = false;
        this.companyFormControls.companyFormGroup.patchValue(response);
        this.companyFormControls.companyFormGroup.controls.phoneNumber.patchValue({
          internationalNumber: response.companyDetails.contactPersonPhoneNo,
          number: response.companyDetails.contactPersonPhoneNo,
          countryCode: response.companyDetails.contactPersonIsdCode,
        });
      });
  }
}
