<div class="sticky top-0 bg-white z-10">
  <est-dialog-header
    [includeExitButton]="true"
    label="EVENTS.REGISTER_CUSTOMER_TO_EVENT"
    tooltipLabel="CUSTOMER_CARD.NAME"
  >
  </est-dialog-header>
</div>
<mat-progress-bar [value]="getProgressbarValue()"></mat-progress-bar>
<div class="max-h-[calc(100dvh-8.5rem)] overflow-auto relative p-6 pb-24">
  <mat-stepper #stepper [linear]="true" animationDuration="0">
    <mat-step [stepControl]="formControls.customer">
      @if (!matchingCustomerResponse()) {
        <div class="flex gap-2 items-center mb-4">
          <p class="font-medium">{{ "RESPONSIBLE_AGENT" | translate }}</p>
          <est-tooltip tooltip="RESPONSIBLE_AGENT"></est-tooltip>
        </div>
        <est-select-search-available-responsible-agents
          dropDownLabel="RESPONSIBLE_AGENT"
          [control]="responsibleAgentIdControl"
        ></est-select-search-available-responsible-agents>
        <div class="py-3"></div>
        <est-customer-information
          [addressLookUps]="inviteCustomerOnsiteFacadeService.addressLookups"
          [collapsible]="true"
          [countries]="inviteCustomerOnsiteFacadeService.countries"
          [customerCategories]="inviteCustomerOnsiteFacadeService.customerCategoryOptions"
          [demographicQuestions]="inviteCustomerOnsiteFacadeService.demographicQuestionLookUps"
          [formGroup]="formControls.customer"
          [isFavourite]="formControls.customer.controls.isFavourite"
          [languages]="inviteCustomerOnsiteFacadeService.languageOptions"
          [phoneControl]="inviteCustomerOnsiteFacadeService.phoneControl"
        ></est-customer-information>
      } @else {
        <est-customer-matching [matchData]="matchingCustomerResponse()!"></est-customer-matching>
      }
    </mat-step>
    <mat-step [stepControl]="formControls.followUp">
      <est-customer-follow-up [formGroup]="formControls.followUp"></est-customer-follow-up>
    </mat-step>
    <mat-step [stepControl]="formControls.notificationMethod">
      <est-send-invitation-to-customer
        [hasPopup]="false"
        [isRecipientsDisabled]="true"
        [recipientName]="customerFullname"
        [formGroup]="formControls.notificationMethod"
      ></est-send-invitation-to-customer>
    </mat-step>
  </mat-stepper>
</div>
<div class="dialog__footer">
  @if (matchingCustomerResponse() && matStepper.selectedIndex === 0) {
    <est-button
      (click)="resetMatchingCustomer()"
      buttonStyleType="button--tertiary"
      buttonType="button"
      label="BACK"
    ></est-button>
    <div class="flex gap-4 items-center">
      <est-button
        (click)="formControls.id.setValue(null); moveToNextStepIfValid()"
        imageSrc="assets/images/icons/Add-icon.svg"
        imageAlt="CUSTOMERS.CREATE_AS_NEW_CUSTOMER"
        buttonStyleType="button--secondary"
        buttonType="button"
        label="CUSTOMERS.CREATE_AS_NEW_CUSTOMER"
      ></est-button>
      <est-button
        [disabled]="isButtonsDisabled()"
        [isLoading]="isSubmitting()"
        (click)="updateCustomer()"
        buttonStyleType="button--primary"
        buttonType="button"
        label="UPDATE_AND_USE"
      ></est-button>
    </div>
  } @else {
    @if (matStepper.selectedIndex === 0) {
      <est-button
        (click)="inviteCustomerOnsiteFacadeService.closeDialog()"
        buttonStyleType="button--tertiary"
        buttonType="button"
        label="CANCEL"
      ></est-button>
    } @else {
      <est-button
        (click)="matStepper.previous()"
        buttonStyleType="button--tertiary"
        buttonType="button"
        label="BACK"
      ></est-button>
    }
    <div class="flex gap-4 items-center">
      @if (matStepper.selectedIndex < 2) {
        <est-button
          [disabled]="isButtonsDisabled()"
          [isLoading]="isNextButtonLoading()"
          (click)="moveToNextStepIfValid()"
          buttonStyleType="button--secondary"
          buttonType="button"
          label="NEXT"
        ></est-button>
      }
      <est-button
        (click)="inviteCustomer()"
        [disabled]="isButtonsDisabled()"
        [isLoading]="isSubmitting()"
        buttonStyleType="button--primary"
        buttonType="button"
        label="EVENTS.REGISTER_TO_EVENT"
      ></est-button>
    </div>
  }
</div>
