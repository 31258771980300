<div class="w-[30rem] relative bg-white rounded-lg pt-6 px-6">
  <div class="flex items-center gap-2 mb-4">
    <p class="font-medium">{{ componentInputs.titleName | translate }}</p>
    <button
      class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center cursor-pointer"
      [matTooltip]="componentInputs.titleName | translate"
    >
      ?
    </button>
  </div>
  <hr class="absolute start-0 end-0 border-black-100" />
  <p class="font-medium text-sm mb-2 mt-10">{{ "WARNING" | translate }}!</p>
  <p class="text-sm font-light mb-6">{{ componentInputs.warningMessage | translate }}</p>
  <p class="text-sm font-medium mb-1">{{ "SETTINGS.USERS.USERS_IN_DIVISION" | translate }}</p>
  <p class="text-xs font-light mb-6">{{ paginatedOptions.totalCount() }} {{ "SETTINGS.USERS.NAME" | translate }}</p>
  <est-search-input [searchSignal]="searchSignal" appliedClass="w-full mb-6"></est-search-input>
  <div class="h-52 overflow-y-auto" #containerUi (scroll)="scrollEnd()">
    <mat-radio-group [formControl]="selectReplaceUser">
      @for (user of paginatedOptions.results(); track user.value) {
        <div
          class="flex justify-between border px-4 py-1 items-center rounded-md w-full mb-2"
          [ngClass]="selectReplaceUser.value === user.value ? 'bg-primary-10 border-primary' : 'bg-white border'"
        >
          <div class="flex">
            <p [ngClass]="selectReplaceUser.value === user.value ? 'font-normal' : 'font-light'" class="text-sm pe-1">
              {{ user.label }}
            </p>
            <p class="font-light text-sm text-black-500">( {{ user.roleName ?? "" | translate }})</p>
          </div>
          <mat-radio-button [value]="user.value" [aria-label]="user.label"> </mat-radio-button>
        </div>
      }
    </mat-radio-group>

    @if (isLoading) {
      <div class="pb-2">
        <est-spinner height="30" width="30" />
      </div>
    }
    @if (!isLoading && !paginatedOptions.results().length) {
      <p class="text-sm text-center p-2 text-black-600">
        {{ "NO_RESULTS" | translate }}
      </p>
      <mat-option class="!hidden"></mat-option>
    }
  </div>
</div>

<div class="bg-black-50 px-6 py-2.5 border-t border-black-300 sticky bottom-0 z-10">
  <div class="flex justify-between">
    <est-button
      appliedClasses="uppercase !px-6 !py-3"
      label="COLD_FEET"
      buttonStyleType="button--tertiary"
      [isLoading]="false"
      buttonType="button"
      (click)="cancel()"
    ></est-button>
    <est-button
      appliedClasses="uppercase !px-6 !py-3"
      label="REPLACE"
      buttonStyleType="button--primary"
      buttonType="button"
      (click)="replacementUser()"
    ></est-button>
  </div>
</div>
