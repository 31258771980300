import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { UnitMaintenanceTypeTranslationMapper } from "@root/views/main/units/mappers/unit-maintenance-type-translation.mapper";
import { UnitMaintenanceType } from "@root/data/market/import/units/enums/unit-maintenance.enum";

export const UNIT_NOTICE_PERIOD_OPTIONS: IDropdownOption[] = [
  { label: "2 WEEKS", value: 14, translateKey: "WEEKS" },
  { label: "1 MONTH", value: 30, translateKey: "MONTH" },
  { label: "2 MONTHS", value: 60, translateKey: "MONTHS" },
  { label: "3 MONTHS", value: 90, translateKey: "MONTHS" },
];

export const UNIT_MAINTENANCE_OPTIONS: IDropdownOption[] = [
  {
    label: UnitMaintenanceTypeTranslationMapper.get(UnitMaintenanceType.Owner)!,
    value: UnitMaintenanceType.Owner,
  },
  {
    label: UnitMaintenanceTypeTranslationMapper.get(UnitMaintenanceType.Tenant)!,
    value: UnitMaintenanceType.Tenant,
  },
];

const generateMonthsDropdown = (start: number, end: number): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (let i = start; i <= end; i++) {
    options.push({
      label: i === 1 ? "1 MONTH" : `${i} MONTHS`,
      value: i,
      translateKey: i === 1 ? "MONTH" : "MONTHS",
    });
  }
  return options;
};

export const UNIT_DEPOSIT_MONTHS_OPTIONS = generateMonthsDropdown(1, 3);
export const UNIT_PREPAID_MONTHS_OPTIONS = generateMonthsDropdown(1, 3);
export const UNIT_NON_CANCELLATION_PERIOD_OPTIONS = generateMonthsDropdown(1, 12);
