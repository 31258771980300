import { Component, inject } from "@angular/core";
import { PropertyTextsService } from "@root/data/market/properties/services/properties-texts.service";
import { BaseDuplicatePackageComponent } from "@root/shared/abstracts/base-duplicate-package/base-duplicate-package.component";
import { BASE_DUPLICATE_PACKAGE_IMPORTS } from "@root/shared/abstracts/base-duplicate-package/base-duplicate-package.imports";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { generateTranslatedDropdownOptionsFromEnum } from "@root/shared/utilities/dropdown-options.utilities";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { PropertyTextPackageDuplicationType } from "../../../enums/property-text-package-duplication-type.enum";
import { PropertyTextPackageDuplicationTypeTranslationMapper } from "../../../mappers/property-text-package-duplication-type-translation.mapper";
import { PropertyTextPackagesCommunicationService } from "../property-text-packages/property-text-packages-communication.service";
import { PropertyTextsCommunicationService } from "../property-texts/property-texts-communication.service";

@Component({
  selector: "est-base-duplicate-property-text",
  standalone: true,
  imports: BASE_DUPLICATE_PACKAGE_IMPORTS,
  templateUrl: "/src/app/shared/abstracts/base-duplicate-package/base-duplicate-package.component.html",
  styleUrl: "./base-duplicate-property-text.component.scss",
})
export class BaseDuplicatePropertyTextComponent extends BaseDuplicatePackageComponent<PropertyTextPackageDuplicationType> {
  override readonly duplicationOptions: IDropdownOption[] = generateTranslatedDropdownOptionsFromEnum(
    PropertyTextPackageDuplicationType,
    PropertyTextPackageDuplicationTypeTranslationMapper,
  );
  readonly #propertiesTextPackagesService = inject(PropertyTextsService);
  readonly #snackbarService = inject(SnackbarService);
  readonly #propertyTextsCommunicationService = inject(PropertyTextsCommunicationService);
  readonly #propertyTextPackagesCommunicationService = inject(PropertyTextPackagesCommunicationService);

  #isPackage = true;
  protected get isPackage() {
    return this.#isPackage;
  }
  protected set isPackage(value) {
    this.#isPackage = value;
  }

  override newPackageSubHeaderTranslationKey = "PACKAGES.TEXT_PACKAGES.DUPLICATE_PACKAGE.TO_DUPLICATE_A_TEXT";

  override duplicatePackage() {
    if (this.form.invalid) {
      markAllControlsAsTouchedAndDirty(this.form);
      return;
    }
    this.isLoading.set(true);

    this.#propertiesTextPackagesService
      .duplicatePropertyText(
        +this.componentInputs.propertyId,
        this.componentInputs.originalPackageId,
        this.form.value,
        {
          isPackage: this.isPackage,
        },
      )
      .subscribe({
        next: () => {
          this.matDialog.closeAll();
          this.#reloadPage();
          this.#snackbarService.open(SnackbarType.Success);
        },
        error: () => this.isLoading.set(false),
      });
  }

  #reloadPage(): void {
    if (this.form.value.propertyIds?.includes(+this.componentInputs.propertyId)) {
      this.isPackage
        ? this.#propertyTextPackagesCommunicationService.reloadPropertyTextPackagesPage$.next()
        : this.#propertyTextsCommunicationService.reloadPropertyTextListPage$.next();
    }
  }
}
