<div class="bg-white rounded-[0.625rem] r-screen-[25rem] relative p-6">
  <est-subheader
    date="Mon Feb 19 2024 14:20:06 GMT+0200"
    name="Jesper Thorkildsen"
    label="UNIT_LAYOUT.NAME"
    tooltip="UNIT_LAYOUT.NAME"
  ></est-subheader>
  <div class="mb-10"></div>
  <div class="flex gap-2 items-center mb-3">
    <p class="text-base font-medium">{{ "UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.NAME" | translate }}</p>
    <est-tooltip tooltip="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.NAME"></est-tooltip>
  </div>
  @if (unitLayoutFormGroup) {
    <form (focusout)="patchUnitLayoutChanges()" [formGroup]="unitLayoutFormGroup">
      <div class="flex gap-4 flex-wrap items-baseline">
        <est-postfix-text-input
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['area'])"
          label="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.AREA"
          postfixText="m<sup>2</sup>"
          type="number"
          applyNegativeValuesValidation
          appliedClass="!w-[18.75rem]"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['registeredArea'])"
          label="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.REGISTERED_AREA"
          postfixText="m<sup>2</sup>"
          type="number"
          applyNegativeValuesValidation
          appliedClass="!w-[18.75rem]"
        ></est-postfix-text-input>

        <est-postfix-text-input
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['internalArea'])"
          label="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.INTERNAL_AREA"
          postfixText="m<sup>2</sup>"
          type="number"
          applyNegativeValuesValidation
          appliedClass="!w-[18.75rem]"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['commonArea'])"
          label="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.COMMON_AREA"
          postfixText="m<sup>2</sup>"
          type="number"
          applyNegativeValuesValidation
          appliedClass="!w-[18.75rem]"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['plotArea'])"
          label="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.PLOT_AREA"
          postfixText="m<sup>2</sup>"
          type="number"
          applyNegativeValuesValidation
          appliedClass="!w-[18.75rem]"
        ></est-postfix-text-input>
      </div>
      <div class="flex gap-4 flex-wrap items-baseline mt-6">
        <est-select-field
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['roomQuantity'])"
          dropDownLabel="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.ROOM_QUANTITY"
          [dropDownOptions]="RoomQuantities"
          [selectMultipleOptions]="false"
          (changeSelectValue)="patchUnitLayoutChanges()"
          appliedClass="!w-[18.75rem]"
        ></est-select-field>
        <est-select-field
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['internalFloors'])"
          dropDownLabel="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.INTERNAL_FLOORS_QUANTITY"
          [dropDownOptions]="NumberOfInternalFloors"
          [selectMultipleOptions]="false"
          (changeSelectValue)="patchUnitLayoutChanges()"
          appliedClass="!w-[18.75rem]"
        ></est-select-field>
        <est-select-field
          [control]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['orientation'])"
          dropDownLabel="UNIT_LAYOUT.GENERAL_LAYOUT_DETAILS.ORIENTATION"
          [dropDownOptions]="Orientations"
          [selectMultipleOptions]="false"
          (changeSelectValue)="patchUnitLayoutChanges()"
          appliedClass="!w-[18.75rem]"
        ></est-select-field>
      </div>
      @if (doesTotalRoomCountMatch()) {
        <p class="text-error-100 text-xs font-light mt-4">
          {{ "UNIT_LAYOUT.TOTAL_NUMBER_OF_ROOMS_MATCH_ERROR" | translate }}
        </p>
      }

      <div>
        <hr class="mt-6 border-black-100 absolute start-0 end-0" />
      </div>

      @for (room of RoomTypeAddons; track room) {
        <est-room-input-fields
          [unitLayoutId]="unitId()"
          [roomType]="room.type"
          [formArray]="castControlFromAbstractToFormArray(unitLayoutFormGroup.controls[room.controlName])"
          [quantityControl]="
            castControlFromAbstractToFormControl(unitLayoutFormGroup.controls[room.quantityControlName])
          "
          [visibleControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls[room.visibleControlName])"
          [doesTotalRoomCountMatch]="doesTotalRoomCountMatch()"
          [connectionRooms]="ConnectionRooms"
          [internalFloors]="limitedInternalFloors"
          [roomNames]="RoomNames"
          [terraceTypes]="TerraceTypes"
          [balconyTypes]="BalconyTypes"
          (valuesChanged)="patchUnitLayoutChanges()"
        ></est-room-input-fields>
        <hr class="mt-6 border-black-100 absolute start-0 end-0" />
      }

      <est-visibility-controlled-toggle-input
        header="UNIT_LAYOUT.STUDENT_FRIENDLY.NAME"
        label="UNIT_LAYOUT.STUDENT_FRIENDLY.VALUE"
        [valueControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['studentFriendly'])"
        [visibilityControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['studentFriendlyVisible'])
        "
        (valuesChanged)="patchUnitLayoutChanges()"
      ></est-visibility-controlled-toggle-input>

      <hr class="mt-6 border-black-100 absolute start-0 end-0" />

      <est-visibility-controlled-toggle-input
        header="UNIT_LAYOUT.SENIOR_FRIENDLY.NAME"
        label="UNIT_LAYOUT.SENIOR_FRIENDLY.VALUE"
        [valueControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['seniorFriendly'])"
        [visibilityControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['seniorFriendlyVisible'])
        "
        (valuesChanged)="patchUnitLayoutChanges()"
      ></est-visibility-controlled-toggle-input>

      <hr class="mt-6 border-black-100 absolute start-0 end-0" />

      <est-visibility-controlled-toggle-input
        header="UNIT_LAYOUT.SHARE_FRIENDLY.NAME"
        label="UNIT_LAYOUT.SHARE_FRIENDLY.VALUE"
        [valueControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['shareFriendly'])"
        [visibilityControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['shareFriendlyVisible'])"
        (valuesChanged)="patchUnitLayoutChanges()"
      ></est-visibility-controlled-toggle-input>

      <est-accessibility-feature-inputs
        [handicapAccessibleControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['handicapAccessible'])
        "
        [handicapAccessibleVisibleControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['handicapAccessibleVisible'])
        "
        [bathroomGrabBarControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['bathroomGrabBar'])"
        [parkingAccessibleControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['parkingAccessible'])
        "
        [restroomGrabBarControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['restroomGrabBar'])"
        [showerChairControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['showerChair'])"
        [stepFreeBathroomEntranceControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['stepFreeBathroomEntrance'])
        "
        [stepFreeBedroomEntranceControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['stepFreeBedroomEntrance'])
        "
        [stepFreeEntranceControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['stepFreeEntrance'])
        "
        [stepFreeEntrancePathControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['stepFreeEntrancePath'])
        "
        [stepFreeShowerControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['stepFreeShower'])"
        [wideBathroomEntranceControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['wideBathroomEntrance'])
        "
        [wideBedroomEntranceControl]="
          castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['wideBedroomEntrance'])
        "
        [wideEntranceControl]="castControlFromAbstractToFormControl(unitLayoutFormGroup.controls['wideEntrance'])"
        (valuesChanged)="patchUnitLayoutChanges()"
      ></est-accessibility-feature-inputs>
    </form>
  }
  @if (isPageLoading) {
    <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
  }
</div>
