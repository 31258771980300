import { NgClass } from "@angular/common";
import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { PropertyEventBookingType } from "@root/data/market/properties/enums/property-event-booking-type.enum";
import { IPropertyEventInstance } from "@root/data/market/properties/models/property-event-instance.model";
import { ButtonComponent } from "@root/shared/button/button.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { EventInstanceDetailsComponent } from "../event-instance-details/event-instance-details.component";
import { EventDetailsSignalStateService } from "../services/event-details-signal-state.service";
import { AttendedCustomersComponent } from "./attended-customers/attended-customers.component";
import { ConfirmedCustomersWithTimeSlotsComponent } from "./confirmed-customers-with-time-slots/confirmed-customers-with-time-slots.component";
import { ConfirmedCustomersComponent } from "./confirmed-customers/confirmed-customers.component";

@Component({
  selector: "est-single-event-instance-wrapper",
  standalone: true,
  imports: [
    ConfirmedCustomersWithTimeSlotsComponent,
    ConfirmedCustomersComponent,
    AttendedCustomersComponent,
    TranslateModule,
    EventInstanceDetailsComponent,
    SmallHeaderComponent,
    ButtonComponent,
    SpinnerComponent,
    NgClass,
  ],
  templateUrl: "./single-event-instance-wrapper.component.html",
  styleUrl: "./single-event-instance-wrapper.component.scss",
})
export class SingleEventInstanceWrapperComponent {
  eventInstanceForSeriesEvent = input<IPropertyEventInstance>();
  panelOpenState = input(false);
  showConfirmed = true;
  readonly #eventDetailsSignalStateService = inject(EventDetailsSignalStateService);

  propertyId = this.#eventDetailsSignalStateService.propertyId()!;
  isSeriesEvent = this.#eventDetailsSignalStateService.isSeriesEvent;
  eventDetailsSignal = this.#eventDetailsSignalStateService.eventDetails;

  readonly eventInstanceComputedSignal = computed(() => {
    return (
      this.eventInstanceForSeriesEvent() || this.#eventDetailsSignalStateService.paginatedEventInstances.results()[0]
    );
  });

  isBookingWithTimeSlot(): boolean {
    return this.eventDetailsSignal()?.bookingType === PropertyEventBookingType.TimeSlot;
  }

  showConfirmedCustomers() {
    this.showConfirmed = true;
  }
  showAttendedCustomers() {
    this.showConfirmed = false;
  }
}
