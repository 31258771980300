import { Component, inject, input } from "@angular/core";
import { SelectSearchFieldComponent } from "../select-search-field/select-search-field.component";
import { BaseCompositeSelectSearchFieldComponent } from "../abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "../interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { CitiesService } from "../../data/market/cities/services/cities.service";

@Component({
  selector: "est-geography-select-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./geography-select-field.component.html",
  styleUrl: "./geography-select-field.component.scss",
})
export class GeographySelectFieldComponent extends BaseCompositeSelectSearchFieldComponent {
  appliedClasses = input("");
  private readonly citiesService = inject(CitiesService);

  getHttpServiceCall(): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.citiesService.getCitiesOptions(this.searchSignal());
  }
}
