import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogActions } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatStep, MatStepperModule } from "@angular/material/stepper";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { addressDivisionFormDataMapper } from "@root/data/market/division/utilities/address-division-form-data.utilities";
import { DivisionFormClass } from "@root/shared/abstracts/division-form/division-form.abstract";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { GlobalLoaderComponent } from "@root/shared/global-loader/global-loader.component";
import { InputComponent } from "@root/shared/input/input.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SelectOwnerFieldComponent } from "@root/shared/select-division-owner-field/select-owner-field.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { UploadLogoBrandComponent } from "@root/shared/upload-logo-brand/upload-logo-brand.component";

@Component({
  selector: "est-form-create-division",
  standalone: true,
  imports: [
    MatStep,
    InputComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatTooltip,
    MatDialogActions,
    MatStepperModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    NgOptimizedImage,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatDividerModule,
    MatSlideToggle,
    UploadLogoBrandComponent,
    SelectFieldComponent,
    SelectSearchFieldComponent,
    PhoneInputComponent,
    NgClass,
    GlobalLoaderComponent,
    LoadingOverlayComponent,
    SelectOwnerFieldComponent,
    AddressComponent,
    ButtonComponent,
    DialogHeaderComponent,
    CreationSuccessMessageComponent,
  ],
  templateUrl: "./create-division-form.component.html",
  styleUrl: "./create-division-form.component.scss",
})
export class CreateDivisionFormComponent extends DivisionFormClass implements OnInit {
  totalTabsNo: number = 3;
  readonly #authService = inject(AuthService);

  ngOnInit() {
    this.getAddressLookupsOptions();
  }

  submitForm(): void {
    this.isLoading = true;
    const divisionFormData = addressDivisionFormDataMapper(this.divisionForm, this.phoneNumberControl);
    this.divisionService.createNewDivisionBeadOnFormData(divisionFormData).subscribe({
      next: () => {
        this.isLoading = false;
        this.matStepper.next();
        this.#authService.refreshUser();
      },
      error: () => {
        this.isLoading = false;
        this.closeDialog();
      },
    });
  }

  markControlsAsTouchedAndDirty() {
    if (this.phoneNumberControl.invalid) {
      this.phoneNumberControl.markAsDirty();
      this.phoneNumberControl.markAsTouched();
      return;
    }
    this.moveToNextStepIfValid();
  }
}
