import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { RestroomType } from "@root/data/market/units/enums/restroom-type.enum";
import { IPopupControl } from "@root/views/main/units/unit-details/components/unit-layout/components/room-popup/popup-control.interface";

export const RoomTypePopupControlsMapper = new Map<RoomType, Array<IPopupControl>>([
  [
    RoomType.Bedroom,
    [
      { label: "UNIT_LAYOUT.ROOM_TYPES.BEDROOM.DETAILS.BUILT_IN_CLOSET", value: "builtInCloset" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.BEDROOM.DETAILS.DIRECT_ACCESS_RESTROOM", value: "directAccessRestroom" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.BEDROOM.DETAILS.DIRECT_ACCESS_OUTSIDE", value: "directAccessOutside" },
    ],
  ],
  [
    RoomType.Garden,
    [
      { label: "UNIT_LAYOUT.STEP_FREE_ACCESS", value: "stepFreeAccess" },
      { label: "UNIT_LAYOUT.WIDE_ENTRANCE", value: "wideEntrance" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.GARDEN.DETAILS.FENCED", value: "fenced" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.GARDEN.DETAILS.HEDGE", value: "hedge" },
    ],
  ],
  [
    RoomType.Terrace,
    [
      { label: "UNIT_LAYOUT.STEP_FREE_ACCESS", value: "stepFreeAccess" },
      { label: "UNIT_LAYOUT.WIDE_ENTRANCE", value: "wideEntrance" },
    ],
  ],
  [
    RoomType.Balcony,
    [
      { label: "UNIT_LAYOUT.STEP_FREE_ACCESS", value: "stepFreeAccess" },
      { label: "UNIT_LAYOUT.WIDE_ENTRANCE", value: "wideEntrance" },
    ],
  ],
  [RoomType.LivingRoom, []],
  [RoomType.AdditionalRoom, []],
]);

export const RestroomTypePopupControlsMapper = new Map<RestroomType, Array<IPopupControl>>([
  [
    RestroomType.Restroom,
    [
      {
        label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.STORAGE_UNDER_SINK",
        value: "storageUnderSink",
        title: "UNIT_LAYOUT.INVENTORY",
      },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.MIRROR", value: "mirror" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.TOWEL_HOOKS", value: "towelHooks" },
      {
        label: "UNIT_LAYOUT.ACCESSIBILITY.BATH.TOILET_BAR",
        value: "toiletGrabBar",
        title: "UNIT_LAYOUT.ACCESSIBILITY.NAME",
      },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.RESTROOM.STEP_FREE_ACCESS", value: "stepFreeAccess" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.RESTROOM.WIDE_ENTRANCE", value: "wideEntrance" },
    ],
  ],
  [
    RestroomType.Bathroom,
    [
      {
        label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.STORAGE_UNDER_SINK",
        value: "storageUnderSink",
        title: "UNIT_LAYOUT.INVENTORY",
      },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.SHOWER", value: "shower" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.BATHTUB", value: "bathtub" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.MIRROR", value: "mirror" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.MIRROR_WITH_LIGHT", value: "mirrorWithLight" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.TOWEL_HOOKS", value: "towelHooks" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.TOWEL_RACK", value: "towelRack" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.TOILET_PAPER_HOLDER", value: "toiletPaperHolder" },
      { label: "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.BIDET", value: "bidet" },
      {
        label: "UNIT_LAYOUT.ACCESSIBILITY.BATH.STEP_FREE",
        value: "stepFreeAccess",
        title: "UNIT_LAYOUT.ACCESSIBILITY.NAME",
      },
      { label: "UNIT_LAYOUT.ACCESSIBILITY.BATH.WIDE_ENTRANCE", value: "wideEntrance" },
      { label: "UNIT_LAYOUT.ACCESSIBILITY.BATH.SHOWER_BAR", value: "showerGrabBar" },
      { label: "UNIT_LAYOUT.ACCESSIBILITY.BATH.TOILET_BAR", value: "toiletGrabBar" },
      { label: "UNIT_LAYOUT.ACCESSIBILITY.BATH.STEP_FREE_SHOWER", value: "stepFreeShower" },
      { label: "UNIT_LAYOUT.ACCESSIBILITY.BATH.BATH_CHAIR", value: "bathChair" },
    ],
  ],
]);
