import { Component, input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { InputComponent } from "@root/shared/input/input.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { GeographicalLocationComponent } from "@root/shared/geographical-location/geographical-location.component";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { TimePickerComponent } from "@root/shared/time-picker/time-picker.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ListUsersForEventComponent } from "@root/shared/list-users-for-event/list-users-for-event.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TimeslotsComponent } from "@root/shared/timeslots/timeslots.component";
import { CreatePropertyEventFormControls } from "@root/views/main/property/property-events/create-property-event/create-property-event.form-controls";
import { ReactiveFormsModule } from "@angular/forms";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { ICreatePropertyEventPayload } from "@root/data/market/properties/models/create-property-event-payload.model";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";

import { SelectedUnitsComponent } from "@root/views/main/property/property-events/create-property-event/selected-units/selected-units.component";
import { BaseEventForm } from "@root/shared/abstracts/base-event-form/base-event.form";
import { PropertyEventDraftConfirmationPopupComponent } from "@root/shared/property-event-draft-confirmation-popup/property-event-draft-confirmation-popup.component";
import { ICreatePropertyEventConfirmationInputs } from "@root/shared/property-event-confirmation-popup/property-event-confirmation-inputs.interface";
import { PropertyEventConfirmationPopupComponent } from "@root/shared/property-event-confirmation-popup/property-event-confirmation-popup.component";
import { ICreatePropertyEventDraftConfirmationInputs } from "@root/shared/property-event-draft-confirmation-popup/property-event-draft-confirmation-inputs.interface";
import { DateTime } from "luxon";
import { RepetitionCycle } from "@root/shared/enums/repitition-cycle";

@Component({
  selector: "est-create-property-event",
  standalone: true,
  imports: [
    TranslateModule,
    TooltipComponent,
    SelectFieldComponent,
    InputComponent,
    AddressComponent,
    GeographicalLocationComponent,
    DatepickerComponent,
    TimePickerComponent,
    ButtonComponent,
    ListUsersForEventComponent,
    MatSlideToggle,
    TimeslotsComponent,
    ReactiveFormsModule,
    SelectedUnitsComponent,
  ],
  templateUrl: "./create-property-event.component.html",
  styleUrl: "./create-property-event.component.scss",
  providers: [CreatePropertyEventFormControls],
})
export class CreatePropertyEventComponent extends BaseEventForm implements OnInit {
  readonly propertyId = input.required<number>();
  isIncludedUnits: boolean = false;
  protected readonly DateTime = DateTime;
  protected readonly RepetitionCycle = RepetitionCycle;

  ngOnInit() {
    this.getAddressLookupsOptions();
    this.getResponsibleAgents(this.propertyId());
    this.route.queryParams.subscribe((params) => {
      this.isIncludedUnits = params["isIncludedUnits"] === "true";
    });
  }

  openCreateEventConfirmationModal() {
    if (this.componentFormControls.form.invalid) {
      markAllControlsAsTouchedAndDirty(this.componentFormControls.form);
      return;
    }
    this.formControls.isDraft.setValue(false);
    this.matDialog.open<PropertyEventConfirmationPopupComponent, ICreatePropertyEventConfirmationInputs>(
      PropertyEventConfirmationPopupComponent,
      {
        width: "36rem",
        data: {
          formValue: this.componentFormControls.form.value as ICreatePropertyEventPayload,
          callbackFn: this.#sendCreateRequest.bind(this),
          loadingSignal: this.isSendingRequest,
          namesHashmap: this.agentNamesHashmap,
          title: "EVENTS.PUBLISH_EVENT",
          tooltip: "EVENTS.PUBLISH_EVENT",
          description: "EVENTS.CREATE_EVENT_CONFIRMATION",
        },
      },
    );
  }

  openCreateDraftEventConfirmationModal() {
    if (this.componentFormControls.form.invalid) {
      markAllControlsAsTouchedAndDirty(this.componentFormControls.form);
      return;
    }
    this.formControls.isDraft.setValue(true);
    this.matDialog.open<PropertyEventDraftConfirmationPopupComponent, ICreatePropertyEventDraftConfirmationInputs>(
      PropertyEventDraftConfirmationPopupComponent,
      {
        width: "28rem",
        data: {
          callbackFn: this.#sendCreateRequest.bind(this),
          loadingSignal: this.isSendingRequest,
        },
      },
    );
  }

  goToEventsList() {
    this.router.navigate([`properties/${this.propertyId()}/events/all`]);
  }

  #sendCreateRequest() {
    this.isSendingRequest.set(true);
    this.propertiesEventsService
      .create(this.propertyId(), this.componentFormControls.form.value as ICreatePropertyEventPayload)
      .subscribe({
        next: () => {
          this.router.navigate(["../"], { relativeTo: this.route });
          this.snackbarService.open(SnackbarType.Success);
          this.matDialog.closeAll();
        },
        error: () => this.isSendingRequest.set(false),
      });
  }
}
