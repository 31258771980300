import { Component, DestroyRef, effect, EffectRef, inject, input, signal, WritableSignal } from "@angular/core";
import { MatTable } from "@angular/material/table";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { PetDialogComponent } from "@root/views/main/property/property-details/property-terms-and-conditions/pet-dialog/pet-dialog.component";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { MatDialog } from "@angular/material/dialog";
import { IPetTypeFormInputs } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/pet-type-form-inputs.interface";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { EditPropertyPetTypeComponent } from "@root/views/main/property/property-details/property-terms-and-conditions/edit-property-pet-type/edit-property-pet-type.component";
import { IToggleResponse } from "@root/data/market/shared/models/toggle-response.model";
import { PET_TYPE_POLICIES_HEADERS } from "@root/views/main/property/property-details/property-terms-and-conditions/pet-types-table/pet-types.header";
import { finalize } from "rxjs";
import { PropertiesCommunicationService } from "@root/shared/abstracts/base-properties-table/properties-communication.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";

@Component({
  selector: "est-pet-types-table",
  standalone: true,
  imports: [MatTable, SmallListTableComponent, PaginationComponent],
  templateUrl: "./pet-types-table.component.html",
  styleUrl: "./pet-types-table.component.scss",
})
export class PetTypesTableComponent extends BasePaginatedTableWithSearchComponent {
  matDialog = inject(MatDialog);
  propertyId = input.required<number>();
  petPolicyId = input.required<number>();

  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  #isDeletingPetType = signal(false);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogService = inject(DialogService);
  readonly #propertiesCommunicationService = inject(PropertiesCommunicationService);
  readonly #propertiesReloadSubscription$ = this.#propertiesCommunicationService.reloadTermsAndConditionsPage$
    .pipe(takeUntilDestroyed(this.#destroyRef))
    .subscribe(() => {
      this.loadDataWithParams();
    });

  #propertiesService = inject(PropertiesService);

  contextMenuActions: ITableAction[] = [
    {
      callbackFn: (row: ISmallListTableInput) => this.openEditPetTypeDialog(row),
      labelFn: () => "EDIT_DETAILS",
    },
    {
      callbackFn: (row: ISmallListTableInput) => this.openDeletionConfirmationModal(row),
      labelFn: () => "DELETE",
    },
  ];

  openEditPetTypeDialog(row: ISmallListTableInput) {
    this.matDialog.open<EditPropertyPetTypeComponent, IPetTypeFormInputs>(EditPropertyPetTypeComponent, {
      data: {
        petPolicyId: this.petPolicyId(),
        propertyId: this.propertyId(),
        petTypePolicyId: row.id,
      },
      width: "28rem",
      maxHeight: "95dvh",
    });
  }
  loadDataWithParams() {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      propertyId: this.propertyId(),
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      ...(this.propertyId() ? { propertyId: this.propertyId() } : {}),
    });
  }
  readonly loadDataEffect$: EffectRef = effect(() => {
    this.loadDataWithParams();
  });

  loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#propertiesService
      .getPaginatedSmallTableInputPropertyPetTypePolicies(params)
      .pipe(finalize(() => (this.isTableLoading = false)))
      .subscribe({
        next: (paginatedLinkedUnit) => {
          copyPaginatedSignalResponse(this.paginatedData, paginatedLinkedUnit);
        },
      });
  }

  readonly headers = PET_TYPE_POLICIES_HEADERS;
  protected readonly PetDialogComponent = PetDialogComponent;

  private deletePetTypePolicy(row: ISmallListTableInput) {
    this.#isDeletingPetType.set(true);

    return this.#propertiesService
      .deletePropertyPetTypePolicy(this.propertyId(), row.id)
      .pipe(
        finalize(() => {
          this.#isDeletingPetType.set(false);
          this.#dialogService.close();
        }),
      )
      .subscribe({
        next: () => {
          this.loadDataWithParams();
        },
      });
  }

  togglePropertyPetTypePolicy(row: ISmallListTableInput) {
    this.#propertiesService.togglePropertyPetTypePolicyActive(this.propertyId(), row.id).subscribe({
      next: (res: IToggleResponse) => {
        row["active"] = res.isActive;
      },
      error: () => {
        row["active"] = !row["active"];
      },
    });
  }

  private openDeletionConfirmationModal(row: ISmallListTableInput) {
    this.#dialogService.open(
      {
        title: "TERMS_AND_CONDITIONS.PET_POLICY.DELETE_PET_TYPE",
        callBack: () => this.deletePetTypePolicy(row),
        cancelLabel: "CANCEL",
        submitLabel: "DELETE",
        isInputIncluded: false,
        descriptions: [
          "TERMS_AND_CONDITIONS.PET_POLICY.DELETE_PET_TYPE_CONFIRMATION",
          `<p class='font-bold -mb-2'>Warning!</p>`,
          "TERMS_AND_CONDITIONS.PET_POLICY.DELETE_PET_TYPE_DELETE_CONFIRMATION",
        ],
        tooltipLabel: "TERMS_AND_CONDITIONS.PET_POLICY.DELETE_PET_TYPE",
        isSubmitLoading: this.#isDeletingPetType,
      },
      "confirmation",
    );
  }
}
