import { inject, Injectable } from "@angular/core";
import { IEventDetailsOverview } from "@root/data/market/event/models/event-details-overview.model";
import { EventsRepository } from "@root/data/market/event/repositories/events.repository";
import { mapEventsUnitToSignalResponseSmallTableInput } from "@root/data/market/event/utilities/events.utilities";
import { IEventFilterPayload } from "@root/shared/interfaces/event-filter-payload.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  readonly #eventsRepository = inject(EventsRepository);

  getAllEvents(params: IEventFilterPayload, id: number): Observable<IEventDetailsOverview[]> {
    return this.#eventsRepository.getAllEvents(params, id);
  }

  geUnitsList(
    params: IPaginationSortPayload & { propertyIds: number[] },
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput<string>>> {
    return this.#eventsRepository.listUnits(params).pipe(mapEventsUnitToSignalResponseSmallTableInput());
  }
}
