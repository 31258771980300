import { Component, input } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { UnitFacilitiesComponent } from "@root/views/main/units/unit-details/components/facilities/unit-facilities/unit-facilities.component";
import { UnitPropertyFacilitiesComponent } from "@root/views/main/units/unit-details/components/facilities/unit-property-facilities/unit-property-facilities.component";
import { UnitOtherFacilitiesComponent } from "@root/views/main/units/unit-details/components/facilities/unit-other-facilities/unit-other-facilities.component";

@Component({
  selector: "est-facilities",
  standalone: true,
  imports: [
    UnderDevelopmentComponent,
    SubheaderComponent,
    SmallHeaderComponent,
    ButtonComponent,
    UnitFacilitiesComponent,
    UnitPropertyFacilitiesComponent,
    UnitOtherFacilitiesComponent,
  ],
  templateUrl: "./facilities.component.html",
  styleUrl: "./facilities.component.scss",
})
export class FacilitiesComponent {
  unitId = input.required<string>();
}
