import { Component, inject, OnInit } from "@angular/core";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ICustomerCategoryPopupInputs } from "@root/views/main/organization/organization-settings/components/system-configurations/components/customer-category/components/customer-category-popup/customer-category-popup-inputs.interface";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { Observable, tap } from "rxjs";
import { ICustomerCategory } from "@root/data/market/system-configurations/customer-category/models/customer-category.model";
import { CustomerCategoryService } from "@root/data/market/system-configurations/services/customer-category.service";
import { InputComponent } from "@root/shared/input/input.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-customer-category-popup",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    ButtonComponent,
    SmallHeaderComponent,
    LoadingOverlayComponent,
    MatSlideToggle,
    TranslateModule,
    InputComponent,
    TooltipComponent,
  ],
  templateUrl: "./customer-category-popup.component.html",
  styleUrl: "./customer-category-popup.component.scss",
})
export class CustomerCategoryPopupComponent implements OnInit {
  protected readonly componentInputs: ICustomerCategoryPopupInputs = inject(MAT_DIALOG_DATA);
  readonly #dialogRef: MatDialogRef<CustomerCategoryPopupComponent> = inject(MatDialogRef);
  protected isFormSubmitting: boolean = false;
  protected isPageLoading: boolean = false;
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #customerCategoryService = inject(CustomerCategoryService);

  formGroup = this.#fb.group({
    id: this.#fb.control(0),
    title: this.#fb.control("", Validators.required),
    isInheritanceActive: this.#fb.control(false),
  });

  ngOnInit() {
    if (this.componentInputs.isEditPopup) {
      this.isPageLoading = true;
      this.#getCustomerCategory().subscribe({ complete: () => (this.isPageLoading = false) });
    }
  }

  #getCustomerCategory(): Observable<ICustomerCategory> {
    return this.#customerCategoryService
      .getCustomerCategory({
        sourceLevel: this.componentInputs.sourceLevel,
        ids: { customerCategoryId: this.componentInputs.customerCategoryId! },
        routeId: this.componentInputs.routeId,
      })
      .pipe(
        tap((data: ICustomerCategory) => {
          this.formGroup.patchValue(data);
        }),
      );
  }

  toggleActivated() {
    this.formGroup.controls.isInheritanceActive.patchValue(!this.formGroup.controls.isInheritanceActive.value);
  }

  submitForm() {
    if (!this.formGroup.valid) {
      markAllControlsAsTouchedAndDirty(this.formGroup);
      return;
    }
    this.isFormSubmitting = true;
    if (this.componentInputs.isEditPopup) {
      this.#updateCustomerCategory();
    } else {
      this.#createCustomerCategory();
    }
  }

  #updateCustomerCategory() {
    this.#customerCategoryService
      .updateCustomerCategory(
        {
          sourceLevel: this.componentInputs.sourceLevel,
          routeId: this.componentInputs.routeId,
          ids: { customerCategoryId: this.componentInputs.customerCategoryId! },
        },
        this.formGroup.value as ICustomerCategory,
      )
      .subscribe({
        complete: () => {
          this.isFormSubmitting = false;
          this.closeDialog();
        },
      });
  }

  #createCustomerCategory() {
    this.#customerCategoryService
      .createCustomerCategory(
        this.formGroup.value as ICustomerCategory,
        this.componentInputs.sourceLevel,
        this.componentInputs.routeId,
      )
      .subscribe({
        complete: () => {
          this.isFormSubmitting = false;
          this.closeDialog();
        },
      });
  }

  closeDialog(isCancel: boolean = false): void {
    this.#dialogRef.close(isCancel);
  }
}
