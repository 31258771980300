import { Route } from "@angular/router";
import { UsersComponent } from "./components/users/users.component";
import { PropertyComponent } from "./components/property/property.component";
import { MarketingComponent } from "./components/marketing/marketing.component";
import { IntegrationsComponent } from "./components/integrations/integrations.component";
import { CommunicationComponent } from "./components/communication/communication.component";
import { usersRoutes } from "./components/users/users.routes";
import { SystemConfigurationsComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/system-configurations.component";
import { systemConfigurationsRoutes } from "@root/views/main/organization/organization-settings/components/system-configurations/system-configurations.routes";
import { marketingRoutes } from "@root/views/main/organization/organization-settings/components/marketing/marketing.routes";
import { communicationRoutes } from "./components/communication/communication.routes";

export const organizationSettingsRoutes: Route[] = [
  {
    path: "users",
    component: UsersComponent,
    data: {
      name: "SETTINGS.USERS.NAME",
    },
    children: usersRoutes,
  },
  {
    path: "system-configurations",
    component: SystemConfigurationsComponent,
    data: {
      name: "SETTINGS.SYSTEM_CONFIGURATIONS.NAME",
    },
    children: systemConfigurationsRoutes,
  },
  {
    path: "property",
    component: PropertyComponent,
    data: {
      name: "PROPERTIES.PROPERTY",
    },
  },
  {
    path: "marketing",
    component: MarketingComponent,
    data: {
      name: "SETTINGS.MARKETING.NAME",
    },
    children: marketingRoutes,
  },
  {
    path: "integrations",
    component: IntegrationsComponent,
    data: {
      name: "SETTINGS.INTEGRATIONS.NAME",
    },
  },
  {
    path: "communication",
    component: CommunicationComponent,
    data: {
      name: "SETTINGS.COMMUNICATION.NAME",
    },
    children: communicationRoutes,
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "users",
  },
];
