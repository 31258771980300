import { Component, OnInit, signal, WritableSignal } from "@angular/core";

import { ReactiveFormsModule } from "@angular/forms";
import { MatDivider } from "@angular/material/divider";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { MatTooltip } from "@angular/material/tooltip";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { ListSelectTeamsComponent } from "@root/shared/list-select-teams/list-select-teams.component";
import { SelectDivisionFieldComponent } from "@root/shared/select-search-division/select-division-field.component";
import { TeamMemberTableComponent } from "@root/shared/team-member/team-member-table.component";
import { InputComponent } from "@root/shared/input/input.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { ImageUploadComponent } from "@root/shared/image-upload/image-upload.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { ListSelectPropertiesComponent } from "@root/shared/list-select-properties/list-select-properties.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { BaseUserForm } from "@root/shared/abstracts/user-form/user-form.abstract";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { areArraysEqual } from "@root/shared/utilities/array.utilities";

@Component({
  selector: "est-create-user",
  standalone: true,
  imports: [
    CreationSuccessMessageComponent,
    ReactiveFormsModule,
    InputComponent,
    MatDivider,
    MatProgressBar,
    MatSlideToggle,
    MatStep,
    TranslateModule,
    MatTooltip,
    MatStepper,
    CommonModule,
    SearchInputComponent,
    SmallListTableComponent,
    PaginationComponent,
    SelectFieldComponent,
    SelectSearchFieldComponent,
    NgOptimizedImage,
    ImageUploadComponent,
    LanguageDropdownComponent,
    PhoneInputComponent,
    SelectDivisionFieldComponent,
    ListSelectTeamsComponent,
    ListSelectPropertiesComponent,
    ButtonComponent,
    TeamMemberTableComponent,
  ],
  templateUrl: "./create-user.component.html",
  styleUrl: "./create-user.component.scss",
})
export class CreateUserComponent extends BaseUserForm implements OnInit {
  // We create secondary variables beside the controls to only update them when a step is complete
  // This is to avoid the http effects in the other components firing each time the user updates something in the step

  override totalTabsNo = 4;
  searchSignal: WritableSignal<string> = signal("");

  ngOnInit() {
    this.retrieveUserRoles();
    this.retrieveSystemLanguages();
  }

  submitFormOnlyIfValid(): void {
    if (this.matStepper.selectedIndex === this.totalTabsNo - 2) {
      this.submitForm();
      return;
    }
    this.moveToNextStepIfValid();
  }

  navigateToPendingUsersList() {
    this.closeDialog();
    this.router.navigate(["/organization/settings/users/pending-users"]);
  }

  override submitForm(): void {
    this.isPageLoading = true;
    this.usersService.createNewUser(this.userForm).subscribe({
      next: () => {
        this.matStepper.next();
        this.isPageLoading = false;
      },
      error: () => {
        this.isPageLoading = false;
      },
    });
  }
  removeUserDivisionRole(index: number): void {
    this.divisionsFormArray.removeAt(index);
  }
  updateStepData(event: StepperSelectionEvent) {
    switch (event.selectedIndex) {
      case 1:
        if (event.selectedIndex > event.previouslySelectedIndex) {
          this.clearTeamsAndPropertiesIfDivisionsChanged();
          this.updateDivisionIds();
        }
        break;
      case 2:
        this.updateTeamIdsAndResetHashmapsIfTeamsChanged();
        break;
      default:
        break;
    }
  }
  clearTeamsAndPropertiesIfDivisionsChanged(): void {
    if (!areArraysEqual(this.divisionIds, this.dynamicDivisionIds)) {
      this.teamIdsControl.setValue([]);
      this.propertiesControl.setValue([]);
      this.clearPropertiesHashMaps();
    }
  }
}
