import { Component, effect, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { NgOptimizedImage } from "@angular/common";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { TranslateModule } from "@ngx-translate/core";
import { InputComponent } from "@root/shared/input/input.component";
import { MatDivider } from "@angular/material/divider";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  arePhoneControlsDirty,
  constructAddressFormGroup,
  disableAllControlsInFormGroup,
  enableAllControlsInFormGroup,
  getControlInFormGroup,
  markAllControlsAsTouchedAndDirty,
  markControlAsTouchedAndDirty,
  updateNestedControlsPathAndValue,
} from "@root/shared/utilities/form.utilities";
import { BillingMethod } from "@root/data/market/division/enums/billing-method.enum";
import { REGEX_PATTERNS } from "@root/shared/constants/regex-patterns.constants";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { BILLING_DETAILS_OPTIONS } from "@root/shared/constants/global.constants";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { IAddressFormGroup } from "@root/shared/interfaces/address-form-group.interface";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";
import { constructInitialAddressLookups, formatAddress } from "@root/shared/utilities/address.utilities";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";

@Component({
  selector: "est-division-details",
  standalone: true,
  imports: [
    UnderDevelopmentComponent,
    NgOptimizedImage,
    PaginationComponent,
    SearchInputComponent,
    SubheaderComponent,
    TranslateModule,
    InputComponent,
    MatDivider,
    AddressComponent,
    ButtonComponent,
    MatSlideToggle,
    ReactiveFormsModule,
    SelectFieldComponent,
    LoadingOverlayComponent,
    PhoneInputComponent,
    DatepickerComponent,
  ],
  templateUrl: "./division-details.component.html",
  styleUrl: "./division-details.component.scss",
})
export class DivisionDetailsComponent implements OnInit {
  addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  isPageLoading: boolean = false;
  isAddressLoadingSignal = signal(false);
  formattedAddressSignal: WritableSignal<string> = signal("");
  formBuilder = inject(NonNullableFormBuilder);
  phoneControl = this.formBuilder.control<IPhoneNumber>(constructInitialPhoneNumberValue(), Validators.required);
  divisionAddressFormGroup = constructAddressFormGroup(this.formBuilder, true, false);
  billingAddressFormGroup = constructAddressFormGroup(this.formBuilder, true, false);
  billingMethodControl = this.formBuilder.control<BillingMethod | undefined>(
    BillingMethod.OnDivision,
    Validators.required,
  );
  attentionPersonControl = this.formBuilder.control("", Validators.required);
  invoiceEmailControl = this.formBuilder.control("", [Validators.required, Validators.pattern(REGEX_PATTERNS.email)]);
  sameAsBillingDetailsControl = this.formBuilder.control<boolean>(true);
  nameControl = this.formBuilder.control("", Validators.required);
  domainControl = this.formBuilder.control({ value: "", disabled: true }, Validators.required);
  createdOnControl = this.formBuilder.control({ value: "", disabled: true });
  ownerControl = this.formBuilder.control({ value: "", disabled: true });
  contactPersonNameControl = this.formBuilder.control("", Validators.required);
  contactPersonIsdCodeControl = this.formBuilder.control("", Validators.required);
  contactPersonEmailControl = this.formBuilder.control("", Validators.required);
  websiteUrlControl = this.formBuilder.control("");
  contactPersonPhoneNoControl = this.formBuilder.control("");
  ownerIdControl = this.formBuilder.control(0);
  organisationIdControl = this.formBuilder.control(1);
  divisionFormGroup = this.formBuilder.group({
    name: this.nameControl,
    owner: this.ownerControl,
    createdOn: this.createdOnControl,
    ownerId: this.ownerIdControl,
    organisationId: this.organisationIdControl,
    divisionDetails: this.formBuilder.group({
      addressInformation: this.divisionAddressFormGroup,
      contactPersonName: this.contactPersonNameControl,
      contactPersonEmail: this.contactPersonEmailControl,
      contactPersonPhoneNo: this.contactPersonPhoneNoControl,
      contactPersonIsdCode: this.contactPersonIsdCodeControl,
      websiteUrl: this.websiteUrlControl,
    }),
    billingInformation: this.formBuilder.group({
      invoiceEmail: this.invoiceEmailControl,
      attentionPersonName: this.attentionPersonControl,
      addressInformation: this.billingAddressFormGroup,
    }),
    sameAsBillingDetails: this.sameAsBillingDetailsControl,
    billingMethod: this.billingMethodControl,
  });
  vatNumberEffect$ = effect(() => {
    if (this.formattedAddressSignal() && !this.isAddressLoadingSignal()) {
      this.updateDivisionDetails();
    }
  });
  protected readonly lookupsService = inject(LookupsService);
  protected readonly authService = inject(AuthService);
  protected readonly snackbarService = inject(SnackbarService);
  protected readonly divisionService = inject(DivisionService);
  protected readonly enableAllControlsInFormGroup = enableAllControlsInFormGroup;
  protected readonly billingDetailsOptions = BILLING_DETAILS_OPTIONS;
  protected readonly BillingMethodEnum = BillingMethod;

  ngOnInit(): void {
    this.getAddressLookupsOptions();
    this.getDivisionDetailsById();
  }

  copyDivisionDataToBillingData(): void {
    this.billingAddressFormGroup.patchValue(this.divisionAddressFormGroup.value);
    disableAllControlsInFormGroup(this.billingAddressFormGroup);
  }

  enableAllControlsInFormGroupAndUpdate(control: FormGroup<IAddressFormGroup>) {
    enableAllControlsInFormGroup(control);
  }

  getDivisionDetailsById() {
    this.isPageLoading = true;
    this.divisionService.getDivisionDetails().subscribe((response) => {
      this.isPageLoading = false;
      this.divisionFormGroup.patchValue(response);
      this.phoneControl.setValue({
        internationalNumber: response.divisionDetails.contactPersonPhoneNo,
        number: response.divisionDetails.contactPersonPhoneNo,
        countryCode: response.divisionDetails.contactPersonIsdCode,
      });
      this.sameAsBillingDetailsControl.value
        ? this.copyDivisionDataToBillingData()
        : this.enableAllControlsInFormGroup(this.billingAddressFormGroup);
    });
  }

  checkedSameAsBillingDetails(checkedValue: boolean) {
    checkedValue
      ? this.copyDivisionDataToBillingData()
      : this.enableAllControlsInFormGroupAndUpdate(this.billingAddressFormGroup);
    this.sameAsBillingDetailsControl.setValue(checkedValue);
    this.updateDivisionDetails();
  }

  changeBillingMethod(changeMethod: BillingMethod) {
    this.billingMethodControl.setValue(changeMethod);
    this.updateDivisionDetails();
  }

  patchAddressFields(changedField: { name: string; value: number | string }): void {
    if (changedField.name === "vat") {
      markAllControlsAsTouchedAndDirty(this.divisionAddressFormGroup);
      this.updateDivisionDetails();
      return;
    }
    markControlAsTouchedAndDirty(getControlInFormGroup(this.divisionAddressFormGroup, changedField.name));
    this.updateDivisionDetails();
  }

  updateDivisionDetails() {
    if (
      arePhoneControlsDirty(this.phoneControl, this.divisionFormGroup.controls.divisionDetails) &&
      this.phoneControl.valid
    ) {
      this.#mapPhoneControlToDetails();
      this.#markPhoneControlsInParentAsTouchedAndDirty();
    }

    const controlPaths = updateNestedControlsPathAndValue(this.divisionFormGroup);

    if (Object.keys(controlPaths).length) {
      this.divisionService.patchDivision(controlPaths).subscribe({
        next: () => {
          this.formattedAddressSignal.set(formatAddress(this.divisionAddressFormGroup));
        },
      });
    }
  }

  private getAddressLookupsOptions(): void {
    this.lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }

  #markPhoneControlsInParentAsTouchedAndDirty(): void {
    markControlAsTouchedAndDirty(this.contactPersonPhoneNoControl);
    markControlAsTouchedAndDirty(this.contactPersonIsdCodeControl);
  }

  #mapPhoneControlToDetails(): void {
    this.divisionFormGroup.controls.divisionDetails.patchValue({
      contactPersonPhoneNo: this.phoneControl.value?.number,
      contactPersonIsdCode: this.phoneControl.value?.countryCode,
    });
  }

  changeDomain(): void {
    console.log("---- change domain");
  }
}
