import { Component, computed, input, Signal } from "@angular/core";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatIcon } from "@angular/material/icon";
import { NgClass } from "@angular/common";

@Component({
  selector: "est-currency-field",
  standalone: true,
  imports: [MatFormField, MatInput, MatLabel, ReactiveFormsModule, TranslateModule, MatError, MatIcon, NgClass],
  templateUrl: "./currency-field.component.html",
  styleUrl: "./currency-field.component.scss",
})
export class CurrencyFieldComponent {
  label = input.required<string>();
  postfixText = input.required<string>();
  ariaLabel: Signal<string> = computed(() => `${this.label} label`);
  value = input();
  appliedClass = input<string>("");
}
