import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, EffectRef, WritableSignal, signal } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { IFlatNodeItem } from "@root/shared/interfaces/division-tree-flat-node-item.interface";
import { INodeItem } from "@root/shared/interfaces/division-tree-node-item.interface";

@Component({
  template: "",
  styles: "",
})
export abstract class BaseCheckboxTreeComponent<T, B> {
  flatNodeMap = new Map<IFlatNodeItem, INodeItem>();
  originalNodeMap = new Map<INodeItem, IFlatNodeItem>(); // it is required to have initial nodes so the transformer transforms into flatnodes
  checklistSelection = new SelectionModel<IFlatNodeItem>(true);
  searchSignal: WritableSignal<string> = signal("");
  abstract readonly loadDataEffect$: EffectRef;
  // the transformer transforms the original node map data structure into the flatnode data structure needed to render the tree.
  transformer = (node: INodeItem, level: number): IFlatNodeItem => {
    const existingNode = this.originalNodeMap.get(node);
    const flatNode: IFlatNodeItem =
      existingNode && existingNode.item === node.item
        ? existingNode
        : {
            item: node.item,
            level: level,
            expandable: !!node.children && node.children.length > 0,
            id: node.id,
            index: node.index,
          };
    this.flatNodeMap.set(flatNode, node);
    this.originalNodeMap.set(node, flatNode);
    return flatNode;
  };
  getLevel = (node: IFlatNodeItem) => node.level;
  isExpandable = (node: IFlatNodeItem) => node.expandable;
  getChildren = (node: INodeItem): INodeItem[] | undefined => {
    return node.children;
  };
  treeControl = new FlatTreeControl<IFlatNodeItem>(this.getLevel, this.isExpandable);
  treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, nodeData: IFlatNodeItem) => nodeData.expandable && nodeData.level <= 1;

  // check a parent node automatically if all children are selected
  isDescendantsAllSelected(node: IFlatNodeItem): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) => this.checklistSelection.isSelected(child));
  }

  // determines the indeterminate state for the checkbox of a parent node
  isDescendantsPartiallySelected(node: IFlatNodeItem): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.isDescendantsAllSelected(node);
  }

  parentItemSelectionToggle(node: IFlatNodeItem): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.every((child) => this.checklistSelection.isSelected(child));
  }

  itemSelectionToggle = (node: IFlatNodeItem): void => {
    this.parentItemSelectionToggle(node);
    this.checkAllParentsSelection(node);
  };

  lastChildSelectionToggle = (node: IFlatNodeItem): void => {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  };

  checkAllParentsSelection(node: IFlatNodeItem): void {
    let parent: IFlatNodeItem | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  getAllChildrenCount = (node: IFlatNodeItem): number => {
    return this.flatNodeMap.get(node)?.children?.length ?? 0;
  };

  getDirectSelectedChildrenCount = (node: IFlatNodeItem): number => {
    return (
      this.flatNodeMap
        .get(node)
        ?.children?.filter((child) => this.checklistSelection.isSelected(this.originalNodeMap.get(child)!))?.length ?? 0
    );
  };

  checkRootNodeSelection(node: IFlatNodeItem): void {
    const isNodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const isAllDescendantsSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    if (isNodeSelected && !isAllDescendantsSelected) {
      this.checklistSelection.deselect(node);
    } else if (!isNodeSelected && isAllDescendantsSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: IFlatNodeItem): IFlatNodeItem | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  // Override this function to create the needed structure for the mattree
  abstract mapResponseToTree(data: T): INodeItem[];

  abstract loadData(params: B): void;
}
