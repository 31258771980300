import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-property-setting",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./property-setting.component.html",
  styleUrl: "./property-setting.component.scss",
})
export class PropertySettingComponent {}
