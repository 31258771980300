import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { GlobalLoaderService } from "../../shared/ui-services/global-loader.service";
import { finalize, map } from "rxjs";
import { IUser } from "../../data/market/users/models/user.model";
import { UserRole } from "../../data/market/users/enums/user-role.enum";
import { AuthService } from "../../data/market/auth/services/auth.service";

const isUserAuthorized = (user: IUser): boolean => {
  return user.organisationRole === UserRole.SystemAdmin || user.userDivisionRoles.length > 0;
};

export const UserGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const globalLoaderService = inject(GlobalLoaderService);
  const router = inject(Router);

  globalLoaderService.showLoader();
  return authService.registerUser().pipe(
    map((user) => {
      return isUserAuthorized(user) || router.createUrlTree(["/division-not-found"]);
    }),
    finalize(() => {
      globalLoaderService.hideLoader();
    }),
  );
};
