import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { DecoupledEditor, EditorConfig } from "ckeditor5";
import CK_CONFIG from "./ck-text-editor.config";
import { TextEditorUploadAdapterService } from "@root/data/market/text-editor/services/text-editor-upload-adapter.service";
import { ITextEditorFormGroup } from "@root/shared/interfaces/text-editor.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { constructTextEditorImageFormGroup } from "@root/shared/utilities/text-editor.utilties";

@Component({
  selector: "est-ck-text-editor",
  standalone: true,
  imports: [CommonModule, CKEditorModule, ReactiveFormsModule],
  providers: [TextEditorUploadAdapterService],
  templateUrl: "./ck-text-editor.component.html",
  styleUrl: "./ck-text-editor.component.scss",
})
export class CkTextEditorComponent implements AfterViewInit, OnInit {
  #fb = inject(NonNullableFormBuilder);
  @ViewChild("editorToolbarElement") private editorToolbar!: ElementRef<HTMLDivElement>;
  textControl = input.required<FormGroup<ITextEditorFormGroup>>();
  textEditorUploadService = inject(TextEditorUploadAdapterService);
  changeDetector = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  isLayoutReady = false;
  Editor = DecoupledEditor;
  config: EditorConfig = {};

  ngOnInit(): void {
    this.subscribeToImageUploads();
  }

  private subscribeToImageUploads() {
    this.textEditorUploadService.imageUploadedEvent.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: (uploadedImage) => {
        this.textControl().controls.images.push(constructTextEditorImageFormGroup(this.#fb, uploadedImage));
      },
    });
  }

  ngAfterViewInit(): void {
    this.config = CK_CONFIG;
    this.isLayoutReady = true;
    this.changeDetector.detectChanges();
  }

  onReady(editor: DecoupledEditor): void {
    Array.from(this.editorToolbar.nativeElement.children).forEach((child) => child.remove());
    this.editorToolbar.nativeElement.appendChild(editor.ui.view.toolbar.element!);
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return this.textEditorUploadService.setLoader(loader);
    };
  }
}
