import { NgClass } from "@angular/common";
import { booleanAttribute, Component, computed, DestroyRef, inject, input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { BaseInput } from "../abstracts/base-input/base-input.abstract";
import { REGEX_PATTERNS } from "../constants/regex-patterns.constants";

@Component({
  selector: "est-postfix-email-input",
  standalone: true,
  imports: [MatError, MatIcon, MatLabel, MatFormField, ReactiveFormsModule, MatInput, TranslateModule, NgClass],
  templateUrl: "./postfix-email-input.component.html",
  styleUrl: "./postfix-email-input.component.scss",
})
export class PostfixEmailInputComponent extends BaseInput implements OnInit {
  postfixDomain = input<string>();
  postfixControl = input(new FormControl());
  postFixExitValue: any;
  emailControl = new FormControl();
  applyNegativeValuesValidation = input(false, { transform: booleanAttribute });
  minValue = input<number | null>(null);
  readonly #destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.splitEmailControl();
    this.isRequired = this.control()?.hasValidator(Validators.required) || false;

    if (this.applyNegativeValuesValidation()) {
      this.postfixControl().addValidators(Validators.min(0));
      this.postfixControl().updateValueAndValidity();
    }
    this.setControlValue();
  }
  setControlValue() {
    this.emailControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((value: string) => {
      this.setInputValidation(value);
      if (!this.postfixControl().hasError("mat-pattern")) {
        this.postfixControl().markAsPristine();
        this.postfixControl().markAsDirty();
        this.postfixControl().markAsTouched();

        if (!value || value.trim() === "") {
          this.postfixControl().setValue("");
          return;
        }

        this.postfixControl().setValue(value + this.postFixExitValue());
      }
    });
  }

  splitEmailControl() {
    // divided the email to two part before @ and after
    const atIndex = this.postfixControl().value.indexOf("@");
    const beforeAt = this.postfixControl().value.substring(0, atIndex);
    const afterAt = this.postfixControl().value.substring(atIndex);
    this.postFixExitValue = computed(() => (this.postfixDomain() ? this.postfixDomain() : afterAt));
    this.emailControl.setValue(beforeAt);
  }

  setInputValidation(value: string) {
    if (!REGEX_PATTERNS.emailText.test(value)) {
      this.emailControl.setErrors({ "mat-pattern": true });
      this.emailControl.markAsTouched();
      this.emailControl.markAsDirty();
      this.postfixControl().markAsTouched();
      this.postfixControl().markAsDirty();
      this.postfixControl().setErrors({ "mat-pattern": true });
    } else {
      this.postfixControl().setErrors(null);
    }
  }

  override emitFocusOut(value: FocusEvent) {
    if (value.target instanceof HTMLInputElement && this.emailControl.valid) {
      this.setInputValidation(value.target.value);

      if (!value.target.value || value.target.value.trim() === "") {
        this.focusedOut.emit("");
        return;
      }

      this.focusedOut.emit(value.target.value + this.postFixExitValue());
    }
  }
}
