import { Component, inject, OnInit, output } from "@angular/core";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { MatCheckbox } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DatePipe, DecimalPipe } from "@angular/common";
import { ICurrencyDetails } from "@root/data/market/units/models/currency-details.model";
import { UnitsService } from "@root/data/market/units/services/units.service";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { DATE_PIPE_FORMAT } from "@root/shared/constants/global.constants";
import {
  calculateBaseRentFromExchangeRate,
  calculateConversionRate,
} from "@root/data/market/units/utilities/economy.utilities";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { finalize } from "rxjs";
import { CurrencyFormatConverterPipe } from "@root/shared/pipe/currency-format-converter.pipe";

@Component({
  selector: "est-currency-conversion-dialog",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    TranslateModule,
    MatTooltip,
    MatCheckbox,
    ButtonComponent,
    MatDialogClose,
    DatePipe,
    DecimalPipe,
    SpinnerComponent,
    CurrencyFormatConverterPipe,
  ],
  templateUrl: "./currency-conversion-dialog.component.html",
  styleUrl: "./currency-conversion-dialog.component.scss",
})
export class CurrencyConversionDialogComponent implements OnInit {
  componentInputs: { unitId?: string; propertyId?: string; baseRent: number; defaultCurrency?: string } =
    inject(MAT_DIALOG_DATA);
  protected dialogRef: MatDialogRef<CurrencyConversionDialogComponent> = inject(MatDialogRef);
  protected cancel = () => this.dialogRef.close(false);
  emitCurrency = output<ICurrencyDetails>();
  updatedDate!: Date;
  currencies!: ICurrencyDetails[];
  selectedCurrencies: Set<ICurrencyDetails> = new Set();
  #unitService = inject(UnitsService);
  #propertyService = inject(PropertiesService);
  isLoading = true;
  protected readonly DATE_PIPE_FORMAT = DATE_PIPE_FORMAT;
  protected readonly calculateBaseRentFromExchangeRate = calculateBaseRentFromExchangeRate;
  calculateConversionRate = calculateConversionRate;

  propertiesDescription: string[] = [
    "PROPERTIES.DETAILS.CURRENCY_CONVERSION_DIALOG.FIRST_PARAGRAPH",
    "PROPERTIES.DETAILS.CURRENCY_CONVERSION_DIALOG.SECOND_PARAGRAPH",
    "PROPERTIES.DETAILS.CURRENCY_CONVERSION_DIALOG.THIRD_PARAGRAPH",
  ];

  ngOnInit() {
    this.getAllAvailableCurrencies();
  }

  emitSelectedItem(checked: boolean, currency: ICurrencyDetails) {
    const isCurrencyIncluded = Array.from(this.selectedCurrencies).some(
      (currencyObject) => currencyObject.id === currency.id,
    );
    if (checked && !isCurrencyIncluded) {
      const selectCurrency: ICurrencyDetails = {
        ...currency,
        iso: currency.iso,
      };
      if (this.componentInputs.unitId) {
        selectCurrency.rent = calculateBaseRentFromExchangeRate(
          currency.exchangeRate,
          this.componentInputs.baseRent,
          currency.iso,
        );
      }
      this.selectedCurrencies.add(selectCurrency);
      this.emitCurrency.emit(selectCurrency);
    } else if (!checked && isCurrencyIncluded) {
      const currencyToDelete = Array.from(this.selectedCurrencies).find(
        (currencyObject) => currencyObject.id === currency.id,
      );
      if (currencyToDelete) {
        this.selectedCurrencies.delete(currencyToDelete);
        this.emitCurrency.emit(currency);
      }
    }
  }

  saveData() {
    if (this.componentInputs.propertyId) {
      this.#updateSelectedCurrenciesForProperty();
    }
    this.dialogRef.close(this.selectedCurrencies);
  }

  #updateSelectedCurrenciesForProperty() {
    const idsArray = Array.from(this.selectedCurrencies).map((currency) => currency.id);
    this.#propertyService.updateSelectedCurrencies(this.componentInputs.propertyId!, idsArray).subscribe(() => {
      this.isLoading = false;
    });
  }

  getAllAvailableCurrencies() {
    this.isLoading = true;
    if (this.componentInputs.unitId) {
      this.#unitService
        .getAllCurrenciesForUnit(this.componentInputs.unitId)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((allCurrencies) => {
          this.currencies = allCurrencies.availableCurrencies;
          this.updatedDate = allCurrencies.updatedAt;
          this.currencies.map((data) => {
            if (data.isUsed) {
              this.selectedCurrencies.add({
                ...data,
                iso: data.iso,
                rent: calculateBaseRentFromExchangeRate(data.exchangeRate, this.componentInputs.baseRent, data.iso),
              });
            }
          });
        });
    } else if (this.componentInputs.propertyId) {
      this.#propertyService
        .getAllCurrenciesForProperty(this.componentInputs.propertyId)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((allCurrencies) => {
          this.currencies = allCurrencies.availableCurrencies;
          this.updatedDate = allCurrencies.updatedAt;
          this.currencies.map((data) => {
            if (data.isUsed) {
              this.selectedCurrencies.add({
                ...data,
              });
            }
          });
        });
    }
  }
}
