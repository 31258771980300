<est-select-search-field
  (scrollEnd)="loadOptions($event)"
  [appliedClass]="'mt-4 !w-[37.5rem]'"
  [control]="control()"
  [currentPage]="paginatedOptions.currentPage()"
  [dropdownLabel]="label()"
  [dropdownOptions]="paginatedOptions.results()"
  [isLoading]="isLoading"
  [isNoMoreResults]="isNoMoreResults"
  [searchSignal]="searchSignal"
  [selectSignal]="selectSignal"
  [selectedDropdownOption]="selectedDropdownOptionSignal"
  [totalPages]="paginatedOptions.totalPages()"
>
</est-select-search-field>
