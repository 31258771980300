<div class="bg-white p-6 rounded-[0.625rem] r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    name="Jesper Thorkildsen"
    label="PROPERTIES.DETAILS.MAIN_DETAILS.DETAILS"
    tooltip="PROPERTIES.MAIN_DETAILS.TITLE"
  ></est-subheader>
  <form [formGroup]="mainDetailsFormControls.mainDetailsForm" (change)="updatePropertyDetails()">
    <!-- COMPANY -->
    <div class="pt-10 pb-6">
      <div class="flex gap-2 items-center mb-4">
        <p class="font-semibold">
          {{ "PROPERTIES.DETAILS.MAIN_DETAILS.COMPANY" | translate }}
        </p>
        <est-tooltip tooltip="PROPERTIES.DETAILS.MAIN_DETAILS.COMPANY"></est-tooltip>
      </div>
      <p class="text-sm mb-4">{{ "PROPERTIES.DETAILS.MAIN_DETAILS.COMPANY_SUBTITLE" | translate }}</p>
      <div class="flex">
        <est-input
          appliedClass="me-4 w-[18.75rem] "
          [control]="mainDetailsFormControls.company"
          label="PROPERTIES.DETAILS.MAIN_DETAILS.COMPANY"
          aria-disabled="true"
        ></est-input>

        @if (loggedInUserRole === UserRole.SystemAdmin || loggedInUserRole === UserRole.DivisionAdmin) {
          <est-button
            (click)="openChangeCompanyDialog()"
            label="CHANGE"
            buttonStyleType="button--primary"
            buttonType="button"
          ></est-button>
        }
      </div>
    </div>

    <hr class="border-black-100 absolute start-0 end-0" />

    <!-- PROPERTY DETAILS -->
    <div class="py-6">
      <div class="flex gap-2 items-center mb-4">
        <p class="font-semibold">
          {{ "PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_DETAILS" | translate }}
        </p>
        <est-tooltip tooltip="PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_DETAILS"></est-tooltip>
      </div>

      <est-input
        appliedClass="w-[37.438rem]"
        [control]="mainDetailsFormControls.propertyName"
        label="PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_NAME"
        aria-disabled="false"
      ></est-input>
      <div class="flex flex-wrap gap-4 my-4">
        <est-input
          appliedClass="w-[18.75rem] max-breakpoint-ipad-screen:mb-4 "
          [control]="mainDetailsFormControls.propertyNumber"
          label="PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_NUMBER"
          aria-disabled="false"
        ></est-input>
        <est-input
          appliedClass="w-[18.75rem]"
          [control]="mainDetailsFormControls.cadastralNumber"
          label="PROPERTIES.DETAILS.MAIN_DETAILS.CADASTRAL_NUMBER"
          aria-disabled="false"
        ></est-input>
      </div>
      <div class="flex flex-wrap gap-4 my-4">
        <est-timezone-select-field
          [control]="mainDetailsFormControls.timeZone"
          (change)="updateControlAndPatchNewValue($event, 'timeZone')"
        ></est-timezone-select-field>
        <est-select-field
          [control]="mainDetailsFormControls.propertyConcept"
          dropDownLabel="PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_CONCEPT"
          [dropDownOptions]="propertyConceptDropDownOptions"
          [selectMultipleOptions]="false"
          appliedClass="!w-[18.75rem] "
          (changeSelectValue)="updateControlAndPatchNewValue($event, 'propertyConcept')"
        ></est-select-field>
      </div>
      <div class="flex flex-wrap gap-4 my-4">
        <est-input
          appliedClass="w-[18.75rem] "
          [control]="mainDetailsFormControls.defaultSalesType"
          label="PROPERTIES.DETAILS.MAIN_DETAILS.DEFAULT_SALES_TYPE"
          aria-disabled="true"
        ></est-input>
        <est-input
          appliedClass="w-[18.75rem] "
          [control]="mainDetailsFormControls.unitType"
          label="PROPERTIES.DETAILS.MAIN_DETAILS.UNIT_TYPE"
          aria-disabled="true"
        ></est-input>
      </div>
      <div class="flex flex-wrap gap-4 my-4">
        <est-input
          appliedClass="w-[18.75rem] "
          [control]="mainDetailsFormControls.housingType"
          label="PROPERTIES.DETAILS.MAIN_DETAILS.HOUSING_TYPE"
          aria-disabled="true"
        ></est-input>
        <est-input
          appliedClass="w-[18.75rem] "
          [control]="mainDetailsFormControls.usageType"
          label="PROPERTIES.DETAILS.MAIN_DETAILS.USAGE_TYPE"
          aria-disabled="true"
        ></est-input>
      </div>
    </div>
    <hr class="border-black-100 absolute start-0 end-0" />
    <!-- PROPERTY ADDRESS -->
    <div class="py-6">
      <div class="flex gap-2 items-center mb-4">
        <p class="font-semibold">
          {{ "PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_ADDRESS" | translate }}
        </p>
        <est-tooltip tooltip="PROPERTIES.DETAILS.MAIN_DETAILS.PROPERTY_ADDRESS"></est-tooltip>
      </div>
      <p class="text-sm font-light mb-4">{{ "PROPERTIES.DETAILS.MAIN_DETAILS.ENTER_ADDRESS" | translate }}</p>
      <est-property-address
        [addressLookups]="addressLookupsOptions"
        [propertyAddressControls]="mainDetailsFormControls.mainDetailsForm.controls.propertyAddress"
        [formattedPrimaryAddress]="formattedPrimaryAddressSignal"
        [isPrimaryAddressLoadingSignal]="isPrimaryAddressLoadingSignal"
        [formattedSecondaryAddress]="formattedSecondaryAddressSignal"
        [isSecondaryAddressLoadingSignal]="isSecondaryAddressLoadingSignal"
        [constructSecondaryAddress]="mainDetailsFormControls.addSecondaryAddress"
        (selectedValueChange)="updateAddress($event)"
        (addOrRemoveSecondaryAddressSignal)="addOrRemoveSecondaryAddress($event)"
        (geoLocationChange)="updateDetailsOnUpdateGeoLocation($event)"
        [initialSecondaryAddressSignal]="initialSecondaryAddressSignal"
      ></est-property-address>
    </div>
    <hr class="border-black-100 absolute start-0 end-0" />
    <!-- PAYMENT FREQUENCY -->
    <div class="py-6">
      <div class="flex gap-2 items-center mb-4">
        <p class="font-semibold">
          {{ "PROPERTIES.DETAILS.MAIN_DETAILS.PAYMENT_FREQUENCY" | translate }}
        </p>
        <est-tooltip tooltip="PROPERTIES.DETAILS.MAIN_DETAILS.PAYMENT_FREQUENCY"></est-tooltip>
      </div>

      <est-payment-frequency-select-field
        [control]="mainDetailsFormControls.paymentFrequency"
        (valueChanged)="updateControlAndPatchNewValue($event, 'paymentFrequency')"
      ></est-payment-frequency-select-field>

      <mat-slide-toggle
        (change)="updatePropertyDetails()"
        [formControl]="mainDetailsFormControls.allowPaymentFrequencyChangesOnUnitLevel"
        class="mb-6 mt-1.5"
        >{{ "PROPERTIES.DETAILS.MAIN_DETAILS.ALLOW_CHANGES_UNIT" | translate }}
      </mat-slide-toggle>
      @if (changesOnUnitLevelForPaymentFrequency) {
        <p class="text-secondary-50 text-sm">
          {{ "PROPERTIES.DETAILS.MAIN_DETAILS.CHANGES_ON_UNITS" | translate }} I
          <span
            (keydown.enter)="openReactivatePropertySettingDialog(propertySettingField.PaymentFrequency)"
            (click)="openReactivatePropertySettingDialog(propertySettingField.PaymentFrequency)"
            tabindex="0"
            class="text-primary font-medium text-sm cursor-pointer"
            >{{ "PROPERTIES.DETAILS.MAIN_DETAILS.REACTIVATE_SETTINGS" | translate }}</span
          >
        </p>
      }
    </div>

    <!-- CURRENCY -->
    <div class="pb-6">
      <div class="flex gap-2 items-center mb-4">
        <p class="font-semibold">
          {{ "PROPERTIES.DETAILS.MAIN_DETAILS.DEFAULT_CURRENCY" | translate }}
        </p>
        <est-tooltip tooltip="PROPERTIES.DETAILS.MAIN_DETAILS.DEFAULT_CURRENCY"></est-tooltip>
      </div>
      <div class="flex">
        <est-select-field
          [control]="mainDetailsFormControls.currencyId"
          dropDownLabel="PROPERTIES.DETAILS.MAIN_DETAILS.DEFAULT_CURRENCY"
          [dropDownOptions]="currenciesLookupsOptions"
          [selectMultipleOptions]="false"
          appliedClass="me-4 !w-[18.75rem]"
          (changeSelectValue)="updateControlAndPatchNewValue($event, 'currencyId')"
        />
        <est-button
          (click)="openCurrencyConversionDialogComponent()"
          label="currency conversion"
          buttonStyleType="button--secondary"
          buttonType="button"
        ></est-button>
      </div>

      <mat-slide-toggle
        (change)="checkedChangesOnUnitLevelForDefaultCurrency($event.checked)"
        [formControl]="mainDetailsFormControls.allowCurrencyChangesOnUnitLevel"
        class="mb-6 mt-1.5"
        >{{ "PROPERTIES.DETAILS.MAIN_DETAILS.ALLOW_CHANGES_UNIT" | translate }}
      </mat-slide-toggle>

      @if (changesOnUnitLevelForDefaultCurrency) {
        <p class="text-secondary-50 text-sm">
          {{ "PROPERTIES.DETAILS.MAIN_DETAILS.CHANGES_ON_UNITS" | translate }} I
          <span
            (keydown.enter)="openReactivatePropertySettingDialog(propertySettingField.OtherCurrencies)"
            tabindex="0"
            (click)="openReactivatePropertySettingDialog(propertySettingField.OtherCurrencies)"
            class="text-primary font-medium text-sm cursor-pointer"
            >{{ "PROPERTIES.DETAILS.MAIN_DETAILS.REACTIVATE_SETTINGS" | translate }}</span
          >
        </p>
      }
    </div>
  </form>
  @if (isLoading) {
    <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
  }
</div>
