import { OperatorFunction } from "rxjs/internal/types";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ILinkedPackageUnit } from "@root/data/market/linked/models/linked-package-unit.model";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { signal } from "@angular/core";
import { map } from "rxjs/internal/operators/map";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { mapUnitsToSmallListInput } from "@root/data/market/linked/utilities/linked-package.utilities";

export const mapEventsUnitToSignalResponseSmallTableInput = (): OperatorFunction<
  IPaginatedResponse<ILinkedPackageUnit>,
  ISignalPaginatedResponse<ISmallListTableInput<string>>
> => {
  return map((paginatedUsers: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedUsers),
      results: signal(mapUnitsToSmallListInput(paginatedUsers.results)),
    };
  });
};
