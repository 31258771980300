import { Component } from "@angular/core";
import { BaseCompositeSelectLocalSearchFieldComponent } from "@root/shared/abstracts/base-composite-select-local-search-field/base-composite-select-local-search-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";

@Component({
  selector: "est-import-columns-select-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./import-columns-select-field.component.html",
  styleUrl: "./import-columns-select-field.component.scss",
})
export class ImportColumnsSelectFieldComponent extends BaseCompositeSelectLocalSearchFieldComponent {}
