import { inject } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { IPhoneNumber } from "../../interfaces/phone-number.interface";
import { REGEX_PATTERNS } from "../../constants/regex-patterns.constants";
import { ILanguage } from "../../interfaces/language.interface";
import { MatDialog } from "@angular/material/dialog";
import { UsersService } from "@root/data/market/users/services/users.service";

export abstract class BaseSystemAdminForm {
  formBuilder = inject(FormBuilder);
  dialog = inject(MatDialog);
  userService = inject(UsersService);
  languages: ILanguage[] = [];
  isLoading = false;
  firstName = new FormControl("", [Validators.required]);
  lastName = new FormControl("", [Validators.required]);
  email = new FormControl("", [Validators.required, Validators.email, Validators.pattern(REGEX_PATTERNS.email)]);
  phoneNumber = new FormControl<IPhoneNumber>({ internationalNumber: "", countryCode: "DK" }, [
    Validators.required,
  ]) as FormControl<IPhoneNumber>;
  title = new FormControl("", [Validators.required]);
  workPlace = new FormControl("", [Validators.required]);
  systemLanguage: FormControl<ILanguage | string | null> = new FormControl("", [Validators.required]);
  imageControl = this.formBuilder.control(null);
  systemAdminForm = this.formBuilder.group({
    firstName: this.firstName,
    lastName: this.lastName,
    email: this.email,
    phoneNumber: this.phoneNumber,
    title: this.title,
    workPlace: this.workPlace,
    systemLanguage: this.systemLanguage,
    image: this.imageControl,
  });

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
