import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { APIS } from "@root/core/api/market.apis";
import { IUnitFacilityOverview } from "@root/data/market/units/models/unit-facility-overview.model";
import { IUnitFacilityLookups } from "@root/shared/interfaces/unit-facility-lookups.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IUnitAssignedFacility } from "@root/data/market/units/models/unit-assigned-facility.model";
import { IUnitPropertyAssignedFacilitiesOverview } from "@root/data/market/units/models/unit-property-assigned-facilities-overview.model";
import { IUnitOtherFacilities } from "@root/data/market/units/models/unit-other-facilities.model";
import { IPaginatedSelectedResponse } from "@root/shared/interfaces/paginated-selected-response.interface";

@Injectable({
  providedIn: "root",
})
export class UnitFacilitiesRepository {
  readonly #http = inject(HttpClient);

  getUnitFacilities(params: IUnitFacilityLookups): Observable<IPaginatedSelectedResponse<IUnitFacilityOverview>> {
    return this.#http.get<IPaginatedSelectedResponse<IUnitFacilityOverview>>(APIS.unitFacilities.getAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getUnitAssignedFacilitiesIds(unitId: string): Observable<number[]> {
    return this.#http.get<number[]>(APIS.unitFacilities.getAssignedIds(unitId));
  }

  getUnitAssignedFacilities(
    unitId: string,
    params: Omit<IPaginationSortPayload, "search">,
  ): Observable<IPaginatedResponse<IUnitAssignedFacility>> {
    return this.#http.get<IPaginatedResponse<IUnitAssignedFacility>>(APIS.unitFacilities.getAssigned(unitId), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  assignUnitFacility(unitId: string, unitFacilitiesIds: number[]): Observable<void> {
    return this.#http.post<void>(APIS.unitFacilities.add(unitId), {
      unitId,
      unitFacilitiesIds,
    });
  }

  getUnitAssignedFacility(unitId: string, facilityId: number): Observable<IUnitAssignedFacility> {
    return this.#http.get<IUnitAssignedFacility>(APIS.unitFacilities.get(unitId, facilityId));
  }

  toggleUnitFacility(unitId: string, facilityId: number): Observable<{ isActive: boolean }> {
    return this.#http.post<{ isActive: boolean }>(APIS.unitFacilities.toggleUnitFacility(unitId, facilityId), {});
  }

  toggleUnitPropertyFacility(unitId: string, facilityId: number): Observable<{ isActive: boolean }> {
    return this.#http.post<{
      isActive: boolean;
    }>(APIS.unitFacilities.toggleUnitPropertyFacility(unitId, facilityId), {});
  }

  getUnitPropertyAssignedFacilities(
    unitId: string,
    params: Omit<IPaginationSortPayload, "search">,
  ): Observable<IPaginatedResponse<IUnitPropertyAssignedFacilitiesOverview>> {
    return this.#http.get<IPaginatedResponse<IUnitPropertyAssignedFacilitiesOverview>>(
      APIS.unitFacilities.getPropertyFacilities(unitId),
      {
        params: new HttpParams({
          fromObject: { ...params },
        }),
      },
    );
  }

  getUnitOtherFacilities(unitId: string): Observable<IUnitOtherFacilities> {
    return this.#http.get<IUnitOtherFacilities>(APIS.unitFacilities.getOtherFacilities(unitId));
  }

  updateUnitOtherFacilities(body: { [key: string]: string }, unitId: string, facilityId: number): Observable<void> {
    return this.#http.patch<void>(APIS.unitFacilities.patchOtherFacilities(unitId, facilityId), body);
  }

  updateUnitAssignedFacility(body: { [key: string]: string }, unitId: string, facilityId: number): Observable<void> {
    return this.#http.post<void>(APIS.unitFacilities.updateAssigned(unitId, facilityId), body);
  }
}
