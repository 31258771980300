<div class="overflow-auto max-h-[95dvh]">
  <div class="p-6 relative">
    <p class="font-medium text-xl mb-6">{{ "PROPERTIES.PROPERTY_ACCESS.NAME" | translate }}</p>
    <hr class="border-black-100 absolute start-0 end-0" />
    <p class="font-medium mt-12 mb-4">{{ "PROPERTIES.PROPERTY_ACCESS.TITLE" | translate }}</p>
    <p class="font-light text-sm mb-4">{{ "PROPERTIES.PROPERTY_ACCESS.DESCRIPTION" | translate }}</p>
    @if (isDataLoaded) {
      <p class="font-medium text-sm mb-4">
        {{
          "PROPERTIES.PROPERTY_ACCESS.PROPERTIES_WITH_ACCESS"
            | translate
              : {
                  accessed: accessedPropertiesNo,
                  total: propertiesPaginatedData!.totalProperties()
                }
        }}
      </p>
    }
    <hr class="border-black-100 absolute start-0 end-0" />
    <div class="mt-12"></div>
    @if (isDataLoaded) {
      <est-list-select-properties
        tableAppliedClasses="max-h-[calc(100dvh-34rem)] overflow-auto pt-2 mb-4"
        acceptInitialValue
        [divisionIds]="divisionSignal() ? [divisionSignal()!] : []"
        [userId]="componentInputs.userId"
        [paginatedData]="propertiesPaginatedData!"
        [accessLevelControl]="propertiesAccessControl"
        (accessStatusChange)="updateAccessedPropertiesNo($event)"
      >
        <div class="flex justify-between items-center flex-1">
          <div>
            <p class="font-light text-sm">
              {{ "DIVISIONS.DIVISION" | translate }}:
              <span class="font-medium">{{ divisionSelectField.selectedDropdownOptionSignal()?.label }}</span>
            </p>
            @if (divisionSelectField.selectedDropdownOptionSignal()) {
              <p class="font-light text-sm">
                {{ "USER_ROLE_IN_DIVISION" | translate }}:
                <span class="font-medium">{{
                  RoleNameMapper.get(divisionSelectField.selectedDropdownOptionSignal()!.roleName!)! | translate
                }}</span>
              </p>
            }
          </div>
          <est-select-division-field
            #divisionSelectField
            acceptInitialOptionsAndSelectFirstValue
            [userId]="componentInputs.userId"
            [paginatedOptions]="divisionsPaginatedData!"
            [selectSignal]="divisionSignal"
          ></est-select-division-field>
        </div>
      </est-list-select-properties>
    } @else {
      <div class="flex justify-center items-center h-52">
        <est-spinner width="32" height="32"></est-spinner>
      </div>
    }
  </div>
  @if (isDataLoaded) {
    <div class="py-3 bg-black-50 flex border border-black-100 px-6 justify-between z-10">
      <est-button
        appliedClasses="!px-6"
        (click)="matDialog.closeAll()"
        buttonType="button"
        buttonStyleType="button--tertiary"
        label="CLOSE"
      ></est-button>
      <est-button
        appliedClasses="!px-6"
        [isLoading]="isUpdateLoading"
        (click)="updatePropertiesAccess()"
        buttonType="button"
        buttonStyleType="button--primary"
        label="SAVE"
      ></est-button>
    </div>
  }
</div>
