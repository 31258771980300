import { DatePipe } from "@angular/common";
import { effect, EffectRef, inject, signal, WritableSignal } from "@angular/core";
import { IEventDetailsOverview } from "@root/data/market/event/models/event-details-overview.model";
import { EventsService } from "@root/data/market/event/services/events.service";
import { IEventFilterPayload } from "@root/shared/interfaces/event-filter-payload.interface";

export abstract class BaseEventAbstract {
  isLoading: boolean = false;
  title!: string;
  searchSignal: WritableSignal<string> = signal("");
  eventTypeSignal: WritableSignal<string> = signal("");
  agentsSignal: WritableSignal<number | null> = signal(null);
  startDateSignal: WritableSignal<Date | null> = signal(null);
  endDateSignal: WritableSignal<Date | null> = signal(null);
  eventService = inject(EventsService);
  datePipe = inject(DatePipe);
  allEvents: IEventDetailsOverview[] = [];
  abstract readonly loadEventsEffect$: EffectRef;

  readonly #resetFilterEffect$ = effect(
    () => {
      this.searchSignal();
      this.eventTypeSignal();
      this.agentsSignal();
      this.startDateSignal();
      this.endDateSignal();
    },
    { allowSignalWrites: true },
  );

  abstract loadEvents(params: IEventFilterPayload): void;

  transferDateWithFormat(date: Date | null) {
    return this.datePipe.transform(date, "yyyy-MM-dd")?.toString();
  }

  cardIsExpanded(event: boolean, index: number) {
    const selectedBox = document.getElementById("main-box")?.children[index] as HTMLElement;
    if (selectedBox) {
      if (event) {
        selectedBox.style.gridRow = "span 2";
      } else {
        selectedBox.style.gridRow = ""; // Reset to default
      }
    }
  }
}
