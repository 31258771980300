<div class="mt-10">
  <div class="flex gap-2 items-center mb-4">
    <p class="text-base font-medium">{{ header() | translate }}</p>
    <est-tooltip [tooltip]="header()"></est-tooltip>
  </div>

  <mat-slide-toggle [formControl]="valueControl()" (change)="valuesChanged.emit()" class="mb-4"
    >{{ label() | translate }}
  </mat-slide-toggle>

  <est-end-user-visibility-input
    [control]="visibilityControl()"
    (selectionChanged)="valuesChanged.emit()"
  ></est-end-user-visibility-input>
</div>
