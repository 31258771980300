<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    name="Jesper Thorkildsen"
    label="TERMS_AND_CONDITIONS.NAME"
    tooltip="TERMS_AND_CONDITIONS.NAME"
  ></est-subheader>
  <form [formGroup]="propertyTermsAndConditionsFormControls.petPolicyFormGroup">
    <div class="flex flex-col pt-10 pb-6">
      <div class="flex gap-2 items-center mb-4">
        <p class="font-semibold">
          {{ "TERMS_AND_CONDITIONS.PET_POLICY.NAME" | translate }}
        </p>
        <est-tooltip tooltip="TERMS_AND_CONDITIONS.PET_POLICY.NAME"></est-tooltip>
      </div>
      <p class="text-sm mb-4">{{ "TERMS_AND_CONDITIONS.PET_POLICY.PROPERTY_DESCRIPTION" | translate }}</p>
      <mat-slide-toggle class="mb-5" [formControl]="propertyTermsAndConditionsFormControls.isPetAllowed">
        {{ "TERMS_AND_CONDITIONS.PET_POLICY.PETS_ALLOWED_IN_UNITS_IN_PROPERTY" | translate }}
      </mat-slide-toggle>
      @if (propertyTermsAndConditionsFormControls.isPetAllowed.value) {
        <est-select-field
          [control]="propertyTermsAndConditionsFormControls.numberOfPetsAllowed"
          [dropDownOptions]="numberOfPetsOptions"
          [dropDownLabel]="'TERMS_AND_CONDITIONS.PET_POLICY.NUMBER_OF_PETS'"
          [selectMultipleOptions]="false"
          appliedClass="mb-5"
        >
        </est-select-field>
      }

      <mat-slide-toggle class="mb-5" [formControl]="propertyTermsAndConditionsFormControls.isUnitChangeAllowed">
        {{ "TERMS_AND_CONDITIONS.PET_POLICY.ALLOW_CHANGES_ON_UNIT_LEVEL" | translate }}
      </mat-slide-toggle>

      <p class="text-sm text-black-500">
        {{ "TERMS_AND_CONDITIONS.PET_POLICY.SETTING_INHERITED_FROM_PROPERTY" | translate }}
      </p>
    </div>
  </form>
  @if (propertyTermsAndConditionsFormControls.isPetAllowed.value) {
    <div class="flex flex-row mb-3">
      <div class="flex flex-col">
        <div class="flex flex-row gap-2 mb-4">
          <p class="font-semibold">
            {{ "TERMS_AND_CONDITIONS.PET_POLICY.ALLOW_PETS_IN_PROPERTY" | translate }}
          </p>
          <est-tooltip tooltip="TERMS_AND_CONDITIONS.PET_POLICY.ALLOW_PETS_IN_PROPERTY"></est-tooltip>
        </div>
        <p class="text-sm mb-4">
          {{ "TERMS_AND_CONDITIONS.PET_POLICY.ALLOW_PETS_IN_PROPERTY_DESCRIPTION" | translate }}
        </p>
      </div>
      <est-button
        buttonStyleType="button--primary"
        buttonType="button"
        appliedClasses="ms-9"
        label="TERMS_AND_CONDITIONS.PET_POLICY.ADD_PET_TYPE"
        (click)="addPetTypeOnClick()"
      >
      </est-button>
    </div>

    <est-pet-types-table [propertyId]="propertyId()" [petPolicyId]="petPolicyId!"></est-pet-types-table>
  }
</div>
