import { Component, effect, EffectRef, inject, input, output, signal, WritableSignal } from "@angular/core";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { LargeListTableComponent } from "@root/shared/large-list-table/large-list-table.component";
import { LINKED_COMPANIES_TABLE_HEADERS } from "@root/shared/linked-companies-table-popup/linked-companies.header";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";

import { FormControl, NonNullableFormBuilder } from "@angular/forms";
import { CompaniesService } from "@root/data/market/companies/services/companies.service";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { TranslateModule } from "@ngx-translate/core";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";

@Component({
  selector: "est-linked-companies-table",
  standalone: true,
  imports: [
    SearchInputComponent,
    SelectFieldComponent,
    SmallListTableComponent,
    PaginationComponent,
    LargeListTableComponent,
    TranslateModule,
  ],
  templateUrl: "./linked-companies-table.component.html",
  styleUrl: "./linked-companies-table.component.scss",
})
export class LinkedCompaniesTableComponent extends BasePaginatedTableWithSearchComponent {
  headers: ITableHeader[] = LINKED_COMPANIES_TABLE_HEADERS;

  readonly #companiesService = inject(CompaniesService);
  readonly #fb = inject(NonNullableFormBuilder);
  companiesIdsFC = this.#fb.control<number[]>([]);

  linkingSource = input.required<LinkingSource>();
  stepIndex = input.required<number>();
  isEdit = input.required<boolean>();
  divisionIds = input.required<number[]>();
  updateLinkCompaniesIds = output<number[]>();

  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });

  selectedCompaniesMapper: Map<string, FormControl<number[]>> = new Map([["companiesIds", this.companiesIdsFC]]);

  readonly loadDataEffect$: EffectRef = effect(() => {
    if (this.stepIndex() === 1) {
      this.loadData({
        divisionIds: this.divisionIds(),
        sortBy: this.sortingFilterSignal().sortBy,
        sortDescending: this.sortingFilterSignal().sortDescending,
        pageSize: this.paginatedData.pageSize(),
        pageNumber: this.paginatedData.currentPage(),
        search: this.searchSignal(),
      });
    }
  });

  loadData(params: IPaginationSortPayload & { divisionIds: number[] }): void {
    this.isTableLoading = true;
    this.#companiesService.getCompanies(params).subscribe({
      next: (paginatedProperties) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedProperties);
        this.isTableLoading = false;
      },
      error: () => (this.isTableLoading = false),
    });
  }

  addAndRemoveItem() {
    const addingIds = new Set([...this.companiesIdsFC.value]);
    this.updateLinkCompaniesIds.emit(Array.from(addingIds));
  }

  addAndRemoveItems() {
    const addingIds = new Set([...this.companiesIdsFC.value]);
    this.updateLinkCompaniesIds.emit(Array.from(addingIds));
  }
}
