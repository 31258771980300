import { Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { BaseUsersComponent } from "../../../abstracts/base-users/base-users.component";
import { archivedUsersTableHeaders } from "./archived-users.headers";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { UserStatus } from "@root/data/market/users/enums/user-status.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { MatTooltip } from "@angular/material/tooltip";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { IUserDivisionRole } from "@root/data/market/users/models/user-division-role.model";
import { SelectDivisionFieldComponent } from "@root/shared/select-search-division/select-division-field.component";
import { RoleNameMapper } from "@root/data/market/users/mappers/role-name.mapper";
import { UserRole } from "@root/data/market/users/enums/user-role.enum";

@Component({
  selector: "est-archived-users",
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltip,
    SearchInputComponent,
    SmallHeaderComponent,
    SmallListTableComponent,
    SelectDivisionFieldComponent,
    PaginationComponent,
    SelectSearchFieldComponent,
  ],
  templateUrl: "./archived-users.component.html",
  styleUrl: "./archived-users.component.scss",
})
export class ArchivedUsersComponent extends BaseUsersComponent implements OnInit {
  headers: ITableHeader[] = archivedUsersTableHeaders;
  confirmationDialog = inject(DialogService);
  isReActivatingUserLoading: WritableSignal<boolean> = signal(false);
  override actions: ITableAction[] = [
    {
      callbackFn: (row: ISmallListTableInput) => this.openConfirmationRestoreUser(row),
      labelFn: () => "SETTINGS.USERS.RESTORE_USER",
    },
    {
      callbackFn: () => console.log("--- delete user"),
      labelFn: () => "SETTINGS.USERS.DELETE_USER",
    },
  ];

  ngOnInit() {
    this.subscribeToUpdateList$();
  }

  getPaginatedSmallTableInputUsers(params: IPaginationSortPayload) {
    return this.usersService.getPaginatedSmallTableInputUsers(UserStatus.Archived, params);
  }

  openConfirmationRestoreUser(row: ISmallListTableInput) {
    this.confirmationDialog.open(
      {
        tooltipLabel: "SETTINGS.USERS.RESTORE_USER",
        title: "SETTINGS.USERS.RESTORE_USER",
        callBack: () => this.restoreUser(row.id),
        submitLabel: "CONFIRM",
        isInputIncluded: false,
        descriptions: [
          "SETTINGS.USERS.RESTORE_CONFIRMATION.DO_YOU_WANT_RESTORE",
          this.getTranslatedUserRoles(row),
          "SETTINGS.USERS.RESTORE_CONFIRMATION.BY_CONFIRM",
        ],
        isSubmitLoading: this.isReActivatingUserLoading,
      },
      "confirmation",
    );
  }

  restoreUser(id: number) {
    this.isReActivatingUserLoading.set(true);
    this.usersService.restoreUser(id).subscribe(() => {
      this.isReActivatingUserLoading.set(false);
      this.updateUserListService.reloadUserListPage$.next();
      this.dialog.closeAll();
    });
  }

  getTranslatedUserRoles(row: ISmallListTableInput) {
    let userDivisionRolesText = `<p>${this.translate.instant("SETTINGS.USERS.RESTORE_CONFIRMATION.USER_ROLES")}</p>`;

    if (row["role"] === UserRole.SystemAdmin) {
      userDivisionRolesText += `<p class='text-black-500'>${this.translate.instant(
        RoleNameMapper.get(row["role"])!,
      )}</p>`;
      return userDivisionRolesText;
    }
    row["userDivisionRoles"].map((role: IUserDivisionRole) => {
      userDivisionRolesText += `<p class='text-black-500'>${this.translate.instant(
        RoleNameMapper.get(role.roleName)!,
      )}</p>`;
    });

    return userDivisionRolesText;
  }
}
