import { Component, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ICustomerCardInputs } from "@root/shared/customer-card/customer-card-inputs.interface";
import { CustomerCardComponent } from "@root/shared/customer-card/customer-card.component";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { RatingComponent } from "@root/shared/rating/rating.component";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { CustomerFollowUpComponent } from "@root/views/main/property/property-events/customer-follow-up/customer-follow-up.component";
import { ButtonComponent } from "../../../shared/button/button.component";
import { IInviteCustomerOnsiteInputs } from "@root/shared/invite-customer-onsite/invite-customer-onsite-inputs.interface";
import { InviteCustomerOnsiteComponent } from "@root/shared/invite-customer-onsite/invite-customer-onsite.component";
import { SendInvitationToCustomerComponent } from "@root/shared/send-invitiation-to-customer/send-invitation-to-customer.component";
import { ReactiveFormsModule } from "@angular/forms";
import { PostfixEmailInputComponent } from "@root/shared/postfix-email-input/postfix-email-input.component";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";

@Component({
  selector: "est-dashboard",
  standalone: true,
  imports: [
    UnderDevelopmentComponent,
    RatingComponent,
    CustomerFollowUpComponent,
    ButtonComponent,
    SendInvitationToCustomerComponent,
    ReactiveFormsModule,
    PostfixEmailInputComponent,
    PostfixTextInputComponent,
  ],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent {
  readonly #matDialog = inject(MatDialog);

  openInviteCustomerOnsiteFlow() {
    this.#matDialog.open<InviteCustomerOnsiteComponent, IInviteCustomerOnsiteInputs>(InviteCustomerOnsiteComponent, {
      width: "75rem",
      maxHeight: "95dvh",
      data: {
        eventInstanceId: 8,
        sourceLevel: SourceLevel.Organisation,
      },
    });
  }

  protected open() {
    this.#matDialog.open<CustomerCardComponent, ICustomerCardInputs>(CustomerCardComponent, {
      width: "75rem",
      height: "95dvh",
      data: {
        legalEntityId: 73,
        sourceLevel: SourceLevel.Organisation,
      },
    });
  }
}
