import { Component, effect, ElementRef, inject, signal, ViewChild } from "@angular/core";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IFacilityLookup } from "@root/data/market/shared/models/facility-lookup.model";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { isElementScrolledToTheBottom } from "@root/shared/utilities/element-poistion.utilities";
import { IAddFacilityInputs } from "@root/shared/abstracts/base-add-facility/add-facility-inputs.interface";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { NgClass } from "@angular/common";
import { MatCheckbox } from "@angular/material/checkbox";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";

/*
  List of imports to be used in the component referencing the base-add-facility.component.html template
 */
export const BASE_ADD_FACILITY_COMPONENT_IMPORTS = [
  DialogHeaderComponent,
  TranslateModule,
  TooltipComponent,
  SearchInputComponent,
  NgClass,
  MatCheckbox,
  SpinnerComponent,
  ButtonComponent,
];

@Component({
  standalone: true,
  template: "",
})
/*
  This component has a predefined template.
  When extending, reference the template provided "base-add-facility.component.html in your component".
  Provide IAddFacilityInputs as the data passed in the dialog.open method
 */
export abstract class BaseAddFacilityComponent {
  @ViewChild("facilitiesView", { static: true }) readonly facilitiesView!: ElementRef<HTMLDivElement>;
  isLoading = false;
  isAddingFacility = false;
  searchSignal = signal<string>("");
  paginatedFacilities = constructInitialSignalPaginatedResponse<IFacilityLookup>();
  disabledFacilitiesIds: number[] = [];
  totalFacilitiesNo = 0;
  readonly checkboxHashmap = new Map<number, boolean>();
  readonly matDialog = inject(MatDialog);
  readonly snackbarService = inject(SnackbarService);
  componentInputs: IAddFacilityInputs = inject(MAT_DIALOG_DATA);
  #isNoMoreData = false;
  readonly #loadDataEffect$ = effect(() => {
    this.searchSignal();
    this.#loadData();
  });

  readonly #resetPaginationOnSearchEffect$ = effect(
    () => {
      this.searchSignal();
      this.#resetData();
      this.#getAssignedFacilitiesIds();
    },
    {
      allowSignalWrites: true,
    },
  );

  addFacility(): void {
    this.isAddingFacility = true;
    this.getAddFacilityServiceCall().subscribe({
      next: () => {
        this.matDialog.closeAll();
        this.snackbarService.open(SnackbarType.Success);
      },
      error: () => (this.isAddingFacility = false),
    });
  }

  loadNextPage(): void {
    if (!isElementScrolledToTheBottom(this.facilitiesView.nativeElement, 25) || this.isLoading || this.#isNoMoreData) {
      return;
    }
    this.paginatedFacilities.currentPage.set(this.paginatedFacilities.currentPage() + 1);
  }

  protected abstract getLoadDataServiceCall(): Observable<ISignalPaginatedResponse<IFacilityLookup>>;

  protected abstract getAssignedIdsServiceCall(): Observable<number[]>;

  protected abstract getAddFacilityServiceCall(): Observable<void>;

  protected getSelectedFacilitiesIds(): number[] {
    const facilitiesIds: number[] = [];
    this.checkboxHashmap.forEach((_, key: number) => {
      this.checkboxHashmap.get(key) && facilitiesIds.push(key);
    });
    return facilitiesIds;
  }

  #loadData(): void {
    if (this.#isNoMoreData) return;
    this.isLoading = true;
    this.getLoadDataServiceCall().subscribe(this.#handleLoadedData.bind(this));
  }

  #handleLoadedData(data: ISignalPaginatedResponse<IFacilityLookup>): void {
    if (data.results().length === 0) {
      this.#isNoMoreData = true;
      this.isLoading = false;
      return;
    }
    // Cache total count of facilities
    this.totalFacilitiesNo === 0 && (this.totalFacilitiesNo = data.totalCount());
    this.paginatedFacilities.totalCount.set(data.totalCount());
    this.paginatedFacilities.results.set(this.paginatedFacilities.results().concat(data.results()));
    this.isLoading = false;
  }

  #getAssignedFacilitiesIds(): void {
    this.getAssignedIdsServiceCall().subscribe((facilityIds) => {
      this.disabledFacilitiesIds = facilityIds;
      facilityIds.forEach((id) => {
        this.checkboxHashmap.set(id, true);
      });
    });
  }

  #resetData(): void {
    this.paginatedFacilities.results.set([]);
    this.paginatedFacilities.currentPage.set(1);
    this.#isNoMoreData = false;
  }
}
