import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";

@Component({
  selector: "est-texts",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet, SubheaderComponent],
  templateUrl: "./texts.component.html",
  styleUrl: "./texts.component.scss",
})
export class TextsComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "PROPERTY_TEXTS.TAB_NAME",
      routerLink: "property-texts",
    },
    {
      label: "PACKAGES.TEXT_PACKAGES.NAME",
      routerLink: "text-packages",
    },
  ];
}
