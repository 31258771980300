import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";

@Component({
  selector: "est-user-id-card",
  standalone: true,
  imports: [TranslateModule, CustomDatePipe],
  templateUrl: "./user-id-card.component.html",
  styleUrl: "./user-id-card.component.scss",
})
export class UserIdCardComponent {
  firstName = input.required<string>();
  lastName = input.required<string>();
  phoneNumber = input.required<string>();
  email = input.required<string>();
  title = input.required<string>();
  workPlace = input.required<string>();
  joinedAt = input.required<string>();
  lastActive = input.required<string>();
  systemLanguage = input.required<string>();
  lastChanged = input.required<string>();
  imageUrl = input<string>();
}
