<est-select-search-field
  (scrollEnd)="loadOptions($event)"
  [appliedClass]="'!w-[18.75rem]'"
  [control]="control()"
  [currentPage]="paginatedOptions.currentPage()"
  [dropdownLabel]="'PROPERTIES.SELECT_PROPERTY'"
  [dropdownOptions]="paginatedOptions.results()"
  [isLoading]="isLoading"
  [isNoMoreResults]="isNoMoreResults"
  [searchSignal]="searchSignal"
  [selectSignal]="selectSignal"
  [selectedDropdownOption]="selectedDropdownOptionSignal"
  [totalPages]="paginatedOptions.totalPages()"
></est-select-search-field>
