import { Component, effect, EffectRef, inject, input, signal, WritableSignal } from "@angular/core";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { UNIT_PROPERTY_FACILITIES_HEADERS } from "@root/views/main/units/unit-details/components/facilities/unit-property-facilities/unit-property-facilities.headers";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { FacilityDetailsComponent } from "@root/shared/facility-details/facility-details.component";
import { UnitFacilitiesService } from "@root/data/market/units/services/unit-facilities.service";
import { finalize } from "rxjs";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";

@Component({
  selector: "est-unit-property-facilities",
  standalone: true,
  imports: [SmallHeaderComponent, SubheaderComponent, PaginationComponent, SmallListTableComponent],
  templateUrl: "./unit-property-facilities.component.html",
  styleUrl: "./unit-property-facilities.component.scss",
})
export class UnitPropertyFacilitiesComponent extends BasePaginatedTableWithSearchComponent {
  unitId = input.required<string>();
  override headers: ITableHeader[] = UNIT_PROPERTY_FACILITIES_HEADERS;
  override actions: ITableAction[] = [
    {
      labelFn: () => "UNIT_FACILITIES.REMOVE_FACILITY_FROM_UNIT",
      callbackFn: () => {},
    },
  ];
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  override readonly loadDataEffect$: EffectRef = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageNumber: this.paginatedData.currentPage(),
      pageSize: this.paginatedData.pageSize(),
    });
  });
  protected readonly FacilityDetailsComponent = FacilityDetailsComponent;
  readonly #unitFacilitiesService = inject(UnitFacilitiesService);

  loadData(params: Omit<IPaginationSortPayload, "search">): void {
    this.isTableLoading = true;
    this.#unitFacilitiesService
      .getUnitPropertyAssignedFacilities(this.unitId(), params)
      .pipe(finalize(() => (this.isTableLoading = false)))
      .subscribe({
        next: (signalResponse) => {
          copyPaginatedSignalResponse(this.paginatedData, signalResponse);
        },
      });
  }

  toggleFacility(checked: boolean, unitFacility: ISmallListTableInput): void {
    this.#unitFacilitiesService.toggleUnitPropertyFacility(this.unitId(), unitFacility.id).subscribe({
      next: (res) => {
        unitFacility["isActive"] = res.isActive;
      },
      error: () => {
        unitFacility["isActive"] = !checked;
      },
    });
  }
}
