<form (ngSubmit)="submitFormIfValid()">
  <div class="sticky top-0 bg-white z-10">
    <div class="flex border-b border-black-100 py-6 px-6 justify-between">
      <div class="flex gap-2 items-center sticky top-0">
        <p class="text-xl font-medium mb-1">
          {{ "TEAMS.CREATE_NEW_TEAM" | translate }}
        </p>
        <button
          [matTooltip]="'TEAMS.CREATE_NEW_TEAM' | translate"
          class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
          type="button"
        >
          ?
        </button>
      </div>
      <button (click)="closeDialog()" type="button">
        <img
          [alt]="'CLOSE_ICON' | translate"
          height="44"
          ngSrc="assets/images/icons/close-icon.svg"
          priority
          width="44"
        />
      </button>
    </div>
    <mat-progress-bar [value]="getProgressbarValue()"></mat-progress-bar>
  </div>

  <div class="pb-8 h-[calc(100dvh-14rem)] overflow-y-auto">
    <mat-stepper #stepper [linear]="true" animationDuration="0">
      <!----------------------------------------------- start mat step 1    ----------------------------------------------->
      <mat-step [stepControl]="teamFormGroup">
        <div class="grid grid-cols-8">
          <div class="col-span-6 max-breakpoint-ipad-screen:col-span-8">
            <div class="border-b">
              <div class="px-6 py-4">
                <p class="font-bold mb-4">{{ "TEAMS.CREATE_A_NEW_TEAM" | translate }}</p>
                <p class="font-light text-sm">{{ "TEAMS.A_TEAM_BELONG_TO_DIVISION" | translate }}</p>
                <p class="font-light text-sm mb-7">{{ "TEAMS.SYSTEM_USERS_GAINS_ACCESS" | translate }}</p>
                <p class="font-medium mb-4">{{ "DIVISIONS.DIVISION" | translate }}</p>
                <p class="font-light text-sm mb-6 w-9/12">
                  {{ "TEAMS.SELECT_DIVISION_UNDER_THE_TEAM" | translate }}
                </p>
                <est-select-division-field
                  [control]="divisionId"
                  [selectSignal]="selectedDivisionId"
                ></est-select-division-field>
              </div>
            </div>
            <div class="border-b">
              <div class="p-6">
                <p class="font-medium mb-2">{{ "TEAMS.TEAM_NAME" | translate }}</p>
                <p class="font-light text-sm mb-6">{{ "TEAMS.GIVE_THE_TEAM_NAME" | translate }}</p>
                <est-input [appliedClass]="'w-[18.75rem]'" [control]="name" [label]="'TEAMS.TEAM_NAME'" [name]="'name'">
                </est-input>
              </div>
            </div>
            <div class="p-6">
              <p class="font-medium mb-2">{{ "TEAMS.TEAM_MANAGER" | translate }}</p>
              <p class="font-light text-sm mb-6">
                {{ "TEAMS.A_TEAM_MUST_ALWAYS_HAVE_MANAGER" | translate }}
              </p>
              <est-select-user-field
                [control]="teamManagerId"
                [divisionId]="selectedDivisionId()"
              ></est-select-user-field>
            </div>
          </div>

          <!-- ----------------------------------------------------------image-------------------------------------------------------------------------->
          <div
            class="col-span-2 border-l pt-4 px-6 max-breakpoint-ipad-screen:col-span-8 max-breakpoint-ipad-screen:border-t max-breakpoint-ipad-screen:flex max-breakpoint-ipad-screen:justify-start"
          >
            <est-image-upload
              [description]="'TEAMS.GIVE_THE_TEAM_RECOGNISABLE'"
              [imageFormControl]="profilePicture"
              [title]="'TEAMS.TEAM_IMAGE'"
            ></est-image-upload>
          </div>
        </div>
      </mat-step>
      <mat-step [stepControl]="teamFormGroup">
        <div class="p-6 relative">
          @if (isRequestLoading) {
            <div class="absolute inset-0 bg-white/50 flex items-center justify-center z-10"></div>
          }
          <p class="text-sm font-medium mb-4">{{ "TEAMS.WHICH_USERS_SHOULD_BE_MEMBERS" | translate }}</p>
          <p class="text-sm font-light">{{ "TEAMS.BELOW_IS_A_LIST" | translate }}</p>
          <est-team-member-table
            [controlMapper]="controlMapper"
            [divisionId]="divisionId.value"
            [isSelectedTeamMangerError]="isSelectedTeamMangerId"
            [mangerId]="teamManagerId"
            [stepIndex]="stepper.selectedIndex"
          ></est-team-member-table>
        </div>
      </mat-step>
      <mat-step>
        <est-creation-success-message
          [createdBy]="authService.getFullName()"
          [creationDate]="dateOfCreation"
          [description]="'CREATION_SUCCESS.THE_TWO_OF_CREATE_NEW_TEAM'"
          [namePrefix]="'CREATION_TYPE_ENTITY.TEAM'"
          [name]="name.value"
        ></est-creation-success-message>
      </mat-step>
    </mat-stepper>
  </div>

  <div
    [ngClass]="stepper.selectedIndex !== 2 ? ' justify-between' : ' justify-end'"
    class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10"
  >
    @if (stepper.selectedIndex === 0) {
      <est-button
        [label]="'CANCEL'"
        [appliedClasses]="'!py-3 !px-6'"
        (click)="closeDialog()"
        buttonType="button"
        buttonStyleType="button--tertiary"
      >
      </est-button>
    } @else if (stepper.selectedIndex !== 2) {
      <est-button
        [label]="'BACK'"
        [appliedClasses]="'!py-3 !px-6'"
        (click)="stepper.previous()"
        buttonStyleType="button--tertiary"
        buttonType="button"
        [disabled]="isRequestLoading"
      >
      </est-button>
    }
    <div class="flex text-end">
      @if (stepper.selectedIndex === 2) {
        <est-button
          [label]="'TEAMS.CREATE_A_NEW_TEAM'"
          [appliedClasses]="'!py-3 !px-6 mr-4'"
          (click)="resetTeamForm()"
          buttonStyleType="button--secondary"
          buttonType="button"
          [imageSrc]="'assets/images/icons/Add-icon.svg'"
          [imageAlt]="'ADD_ICON'"
        >
        </est-button>
        <est-button
          [label]="'TEAMS.GO_TO_TEAMS'"
          [appliedClasses]="'!py-3 !px-6'"
          (click)="navigateToTeamsList()"
          buttonStyleType="button--primary"
          buttonType="button"
        >
        </est-button>
      } @else if (stepper.selectedIndex !== 1) {
        <est-button
          (click)="moveToNextStepIfValid()"
          [label]="'NEXT'"
          [appliedClasses]="'!py-3 !px-6'"
          buttonStyleType="button--primary"
          buttonType="button"
          [isLoading]="isTableLoading"
          [disabled]="isTableLoading"
        >
        </est-button>
      } @else {
        <est-button
          [label]="'CREATE'"
          [appliedClasses]="'!py-3 !px-6'"
          buttonStyleType="button--primary"
          buttonType="submit"
          [isLoading]="isRequestLoading"
          [disabled]="isRequestLoading"
        >
        </est-button>
      }
    </div>
  </div>
</form>
