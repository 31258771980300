import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder } from "@angular/forms";
import { IGeneralSystemSettings } from "@root/data/market/generalSystemSettings/models/general-system-settings.model";

@Injectable()
export class GeneralFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  timezoneControl = this.#fb.control("");

  generalSettingsFormGroup = this.#fb.group({
    timezoneId: this.timezoneControl,
  });

  patchValue(value: Partial<IGeneralSystemSettings>) {
    this.generalSettingsFormGroup.patchValue(value);
  }
}
