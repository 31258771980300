import { FormGroup } from "@angular/forms";
import { ITeamMember } from "@root/shared/interfaces/team-member.interface";

export const mapTeamFormGroupToFormData = (formGroup: FormGroup) => {
  const formData = new FormData();
  Object.keys(formGroup.controls).map((controlName) => {
    const control = formGroup.get(controlName);
    if (control?.value === null) return;
    if (control?.value instanceof Array) {
      control.value.forEach((user: ITeamMember, index) => {
        formData.append(`${controlName}[${index}].userId`, `${user.userId}`);
        formData.append(`${controlName}[${index}].isTeamLeader`, `${user.isTeamLeader}`);
      });
    } else formData.append(controlName, control?.value);
  });
  return formData;
};
