import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { IUnitFacilityOverview } from "@root/data/market/units/models/unit-facility-overview.model";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { IUnitAssignedFacility } from "@root/data/market/units/models/unit-assigned-facility.model";
import { IUnitPropertyAssignedFacilitiesOverview } from "@root/data/market/units/models/unit-property-assigned-facilities-overview.model";
import { PropertyFacilityLocationTranslationMapper } from "@root/data/market/properties/mappers/property-facility-location-translation.mapper";
import { IUnitFacilityLookup } from "@root/data/market/units/models/unit-facility-lookup.model";
import { ISignalPaginatedSelectedResponse } from "@root/shared/interfaces/signal-paginated-selected-response.interface";
import { IPaginatedSelectedResponse } from "@root/shared/interfaces/paginated-selected-response.interface";

export const mapUnitPropertyFacilitiesToSmallListInputOperator = (): OperatorFunction<
  IPaginatedResponse<IUnitPropertyAssignedFacilitiesOverview>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((res) => ({
    ...mapToSignalPaginatedResponse(res),
    results: signal(
      res.results.map((facility) => ({
        ...facility,
        isAccessible: facility.isAccessible ? "YES" : "NO",
        isPaymentRequired: facility.isPaymentRequired ? "YES" : "NO",
        location: PropertyFacilityLocationTranslationMapper.get(facility.location)!,
        mouseoverMenuInputs: {
          externalTitles: facility.externalTitles,
          internalTitle: facility.title,
          lastChangedDate: facility.lastModified,
          iconUrl: facility.iconUrl,
        },
      })),
    ),
  }));
};

export const mapUnitFacilityToSignalSmallListInputOperator = (): OperatorFunction<
  IPaginatedSelectedResponse<IUnitFacilityOverview>,
  ISignalPaginatedSelectedResponse<ISmallListTableInput>
> => {
  return map((paginatedCompanies) => ({
    ...mapToSignalPaginatedResponse(paginatedCompanies),
    results: signal(mapUnitFacilitiesToSmallListTableInput(paginatedCompanies.results)),
    selected: signal(paginatedCompanies.selected),
  }));
};

export const mapUnitFacilityToSignalUnitFacilityLookupOperator = (): OperatorFunction<
  IPaginatedResponse<IUnitFacilityOverview>,
  ISignalPaginatedResponse<IUnitFacilityLookup>
> => {
  return map((data) => ({
    ...mapToSignalPaginatedResponse(data),
    results: signal(
      data.results.map((facilityOverview) => ({
        id: facilityOverview.id,
        name: facilityOverview.facility,
        categoryName: facilityOverview.category,
        iconUrl: facilityOverview.unitFacilityDetails.iconUrl,
      })),
    ),
  }));
};

export const mapUnitAssignedFacilitiesToSignalSmallListInputOperator = (): OperatorFunction<
  IPaginatedResponse<IUnitAssignedFacility>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((data) => ({
    ...mapToSignalPaginatedResponse(data),
    results: signal(mapUnitAssignedFacilitiesToSmallListTableInput(data.results)),
  }));
};

const mapUnitAssignedFacilitiesToSmallListTableInput = (
  unitAssignedFacilities: IUnitAssignedFacility[],
): ISmallListTableInput[] => {
  return unitAssignedFacilities.map((facility) => ({
    ...facility,
    facilityPackage: !facility.facilityPackage ? "UNIT_FACILITIES.ADDED_TO_UNIT_DIRECTLY" : facility.facilityPackage,
    appliedClasses: [
      {
        key: "facilityPackage",
        classes: !facility.facilityPackage ? "text-black-500" : "",
      },
    ],
    mouseoverMenuInputs: {
      externalTitles: facility.externalTitles,
      internalTitle: facility.title,
      lastChangedDate: facility.lastModified,
      iconUrl: facility.iconUrl,
    },
  }));
};

const mapUnitFacilitiesToSmallListTableInput = (unitFacilities: IUnitFacilityOverview[]): ISmallListTableInput[] => {
  return unitFacilities.map((unit) => ({
    ...unit,
    accessible: unit.accessible ? "YES" : "NO",
    mouseoverMenuInputs: {
      externalTitles: unit.unitFacilityDetails.externalTitles,
      internalTitle: unit.unitFacilityDetails.internalTitle,
      lastChangedDate: unit.unitFacilityDetails.lastUpdatedAt,
      iconUrl: unit.unitFacilityDetails.iconUrl,
    },
  }));
};
