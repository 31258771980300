<form (ngSubmit)="submitForm()" [formGroup]="divisionForm">
  <est-dialog-header [includeExitButton]="true" label="DIVISIONS.EDIT_DIVISION" tooltipLabel="DIVISIONS.EDIT_DIVISION">
  </est-dialog-header>
  <mat-progress-bar [value]="getProgressbarValue()" class="py-1"></mat-progress-bar>

  <div class="h-[calc(100dvh-14rem)] overflow-y-auto pb-8">
    <mat-stepper #stepper animationDuration="0">
      <!----------------------------------------------- start mat step 1    ----------------------------------------------->
      <mat-step [stepControl]="divisionForm">
        <div class="mt-6">
          <p class="mb-4 font-medium px-6">{{ "DIVISIONS.FORM.DIVISION_NAME" | translate }}</p>
          <div class="px-6 pb-4">
            <div class="mb-4">
              <est-input appliedClass="w-[18.75rem] " [control]="nameControl" label="NAME"></est-input>
            </div>
            <est-select-owner-field
              label="DIVISIONS.FORM.DIVISION_OWNER"
              [control]="ownerIdControl"
            ></est-select-owner-field>
          </div>
          <mat-divider></mat-divider>
          <div class="px-6 pt-6">
            <p class="mb-4 font-medium">{{ "DIVISIONS.FORM.DIVISION_DETAILS" | translate }}</p>
            <est-address
              [formGroup]="addressInformationControl"
              [isLoadingSignal]="isAddressLoadingSignal"
              [lookups]="addressLookupsOptions"
            ></est-address>

            <!-- Contact Person (required)  && Contact email && Phone -->
            <div class="flex flex-wrap mb-4">
              <est-input
                appliedClass="mr-4 w-[18.75rem] max-breakpoint-ipad-screen:mb-4"
                [control]="contactPersonNameControl"
                label="CONTACT_PERSON"
              ></est-input>
              <est-input
                appliedClass="mr-4 w-[18.75rem] max-breakpoint-ipad-screen:mb-4"
                [control]="contactPersonEmailControl"
                label="CONTACT_EMAIL"
                type="email"
              ></est-input>
              <div>
                <est-phone-input [control]="phoneNumberControl"></est-phone-input>
              </div>
            </div>
            <!-- website -->
            <est-input appliedClass="w-[18.75rem]" [control]="websiteControl" label="WEBSITE"></est-input>
          </div>
        </div>
      </mat-step>
      <!-----------------------------------------------end mat step 1    ----------------------------------------------->
      <mat-step>
        <div class="mx-6">
          <p class="mb-4 font-medium mt-6">{{ "DIVISIONS.THUMBNAIL_DIVISION" | translate }}</p>
          <p class="font-light mb-6">{{ "DIVISIONS.THUMBNAIL_LOGO" | translate }}</p>
          <p class="font-light text-sm mb-2">{{ "DIVISIONS.RECOMMENDED_IMAGE" | translate }}: 300 x 200px</p>
          <est-upload-logo-brand
            [fileSize]="logoThumbFilesizeControl"
            [imageDimensions]="logoThumbFileDimensionControl"
            [imageName]="logoThumbFilenameControl"
            [image]="imageControl"
          ></est-upload-logo-brand>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
    @if (stepper.selectedIndex === 0) {
      <est-button
        [label]="'CANCEL'"
        [appliedClasses]="'!py-3 !px-6'"
        (click)="closeDialog()"
        buttonType="button"
        buttonStyleType="button--tertiary"
      >
      </est-button>
      <div class="flex gap-4">
        <est-button
          label="SAVE"
          appliedClasses="!py-3 !px-6"
          buttonStyleType="button--secondary"
          buttonType="submit"
          [isLoading]="isLoading"
        >
        </est-button>

        <est-button
          label="NEXT"
          appliedClasses="!py-3 !px-6"
          buttonStyleType="button--primary"
          buttonType="button"
          (click)="moveToNextStepIfValid()"
          [disabled]="isLoading && stepper.selectedIndex === 0"
          [isLoading]="isLoading && stepper.selectedIndex !== 0"
        >
        </est-button>
      </div>
    } @else {
      <est-button
        [label]="'BACK'"
        appliedClasses="!py-3 !px-6"
        (click)="stepper.previous()"
        buttonStyleType="button--tertiary"
        buttonType="button"
      >
      </est-button>
      <est-button
        label="SAVE"
        appliedClasses="!py-3 !px-6"
        buttonStyleType="button--primary"
        buttonType="submit"
        [isLoading]="isLoading"
      >
      </est-button>
    }
  </div>
  @if (isPageLoading) {
    <div class="absolute z-30 inset-0">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</form>
