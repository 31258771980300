<div class="pt-6 px-6 relative">
  <div class="flex gap-2 items-center">
    <p class="text-xl font-medium mb-1">
      {{ "PROPERTIES.CHANGE_COMPANY_DIALOG.CHANGE_COMPANY" | translate }}
    </p>
    <button
      [matTooltip]="'PROPERTIES.CHANGE_COMPANY_DIALOG.CHANGE_COMPANY' | translate"
      class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
    >
      ?
    </button>
  </div>
  <div class="absolute start-0 end-0">
    <hr class="mt-3 border-black-100" />
  </div>
  <div class="my-8 w-96">
    <p class="font-light text-sm mb-6">{{ "PROPERTIES.CHANGE_COMPANY_DIALOG.TITLE" | translate }}</p>
    <p>{{ "WARNING" | translate }}!</p>
    <p class="font-light text-sm mb-6">{{ "PROPERTIES.CHANGE_COMPANY_DIALOG.SUBTITLE" | translate }}</p>
    <p class="font-light text-sm mb-6">{{ "PROPERTIES.CHANGE_COMPANY_DIALOG.DESCRIPTION" | translate }}</p>
    <div class="flex gap-2 items-center mb-4">
      <p class="text-l font-medium mb-1">
        {{ "PROPERTIES.CHANGE_COMPANY_DIALOG.SELECT_COMPANY" | translate }}
      </p>
      <button
        [matTooltip]="'PROPERTIES.CHANGE_COMPANY_DIALOG.SELECT_COMPANY' | translate"
        class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
      >
        ?
      </button>
    </div>
    <p class="font-light text-sm mb-4">{{ "PROPERTIES.CHANGE_COMPANY_DIALOG.DESCRIPTION" | translate }}</p>
    <est-company-select-field [control]="companyIdControl"></est-company-select-field>
  </div>
</div>
<div class="flex justify-between bg-black-50 px-6 py-2.5 border-t border-black-300">
  <est-button
    appliedClasses="uppercase !px-6 !py-3"
    label="CANCEL"
    buttonStyleType="button--tertiary"
    [isLoading]="false"
    buttonType="button"
    (click)="closeDialog()"
  ></est-button>
  <est-button
    appliedClasses="uppercase !px-6 !py-3"
    label="CONFIRM"
    buttonStyleType="button--primary"
    [isLoading]="false"
    buttonType="button"
    (click)="saveData()"
  ></est-button>
</div>
