export enum Orientation {
  North = "North",
  South = "South",
  East = "East",
  West = "West",
  NorthEast = "NorthEast",
  NorthWest = "NorthWest",
  SouthEast = "SouthEast",
  SouthWest = "SouthWest",
}
