import { Component, Input, input } from "@angular/core";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-stepper-navigation",
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, NgClass],
  templateUrl: "./stepper-navigation.component.html",
  styleUrl: "./stepper-navigation.component.scss",
})
export class StepperNavigationComponent {
  @Input({ required: true }) selectedIndex = 0;
  buttonsLabels = input.required<string[]>({});
}
