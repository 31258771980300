import { Component, DestroyRef, effect, inject, input, OnInit, signal, WritableSignal } from "@angular/core";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { TranslateModule } from "@ngx-translate/core";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { PropertiesFacilitiesService } from "@root/data/market/properties/services/properties-facilities.service";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { PropertyFacilityLocation } from "@root/data/market/properties/enums/property-facility-location.enum";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { MatDialog } from "@angular/material/dialog";
import { AddPropertyFacilityComponent } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/add-property-facility/add-property-facility.component";
import { PROPERTY_FACILITIES_TABLE_HEADERS } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/property-facilities.headers";
import { EditPropertyFacilityComponent } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/edit-property-facility/edit-property-facility.component";
import { IEditPropertyFacilityInputs } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/edit-property-facility/edit-property-facility-inputs.interface";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { generateTranslatedDropdownOptionsFromEnum } from "@root/shared/utilities/dropdown-options.utilities";
import { PropertyFacilityLocationTranslationMapper } from "@root/data/market/properties/mappers/property-facility-location-translation.mapper";
import { IPropertyFacilitiesPaginationPayload } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/property-facilities-pagination-payload.interface";
import { PropertiesFacilitiesCommunicationService } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/properties-facilities-comminication.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ITogglePropertyFacilityResponse } from "@root/data/market/properties/models/toggle-property-facility-response.model";
import { FacilityDetailsComponent } from "@root/shared/facility-details/facility-details.component";
import { IAddFacilityInputs } from "@root/shared/abstracts/base-add-facility/add-facility-inputs.interface";

@Component({
  selector: "est-property-facilities",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    ButtonComponent,
    TranslateModule,
    SelectFieldComponent,
    SearchInputComponent,
    SmallListTableComponent,
    PaginationComponent,
    FacilityDetailsComponent,
  ],
  templateUrl: "./property-facilities.component.html",
  styleUrl: "./property-facilities.component.scss",
})
export class PropertyFacilitiesComponent extends BasePaginatedTableWithSearchComponent implements OnInit {
  readonly headers = PROPERTY_FACILITIES_TABLE_HEADERS;
  readonly locationsOptions: IDropdownOption[] = generateTranslatedDropdownOptionsFromEnum(
    PropertyFacilityLocation,
    PropertyFacilityLocationTranslationMapper,
  );
  readonly filtersPredefinedOptions: IDropdownOption[] = [
    {
      label: "ALL",
      value: "",
    },
  ];
  propertyId = input.required<number>();
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  tableActions: ITableAction[] = [
    {
      callbackFn: (propertyFacility) => this.#openEditPropertyFacilityModal(propertyFacility.id),
      labelFn: () => "EDIT",
    },
    {
      callbackFn: () => {},
      labelFn: () => "DELETE",
    },
  ];
  categoriesOptions: IDropdownOption[] = [];
  locationSignal = signal<PropertyFacilityLocation | null>(null);
  categoryIdSignal = signal<number>(0);
  readonly loadDataEffect$ = effect(() => {
    this.#loadDataWithParams();
  });
  protected readonly FacilityDetailsComponent = FacilityDetailsComponent;
  readonly #propertiesFacilitiesService = inject(PropertiesFacilitiesService);
  readonly #propertiesFacilitiesCommunicationService = inject(PropertiesFacilitiesCommunicationService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #matDialog = inject(MatDialog);

  ngOnInit() {
    this.#loadCategories();
    this.#reloadDataOnPropertyFacilityChange();
  }

  loadData(params: IPropertyFacilitiesPaginationPayload): void {
    this.isTableLoading = true;
    this.#propertiesFacilitiesService.getPaginatedAssignedPropertiesFacilities(params).subscribe((paginatedData) => {
      copyPaginatedSignalResponse(this.paginatedData, paginatedData);
      this.isTableLoading = false;
    });
  }

  openAddPropertyFacilityModal(): void {
    this.#matDialog.open<AddPropertyFacilityComponent, IAddFacilityInputs>(AddPropertyFacilityComponent, {
      width: "40rem",
      height: "35rem",
      data: {
        entityId: this.propertyId(),
        description: "PROPERTY_FACILITIES.ADD_FACILITY_DESCRIPTION",
        header: "PROPERTY_FACILITIES.NAME",
      },
    });
  }

  togglePropertyFacility(row: ISmallListTableInput): void {
    this.#propertiesFacilitiesService
      .togglePropertyFacility({
        propertyId: this.propertyId(),
        assignedPropertyFacilityId: row.id,
      })
      .subscribe({
        next: (res: ITogglePropertyFacilityResponse) => {
          row["isActive"] = res.isActive;
        },
        error: () => {
          row["isActive"] = !row["isActive"];
        },
      });
  }

  #loadDataWithParams(): void {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      propertyId: this.propertyId(),
      ...(this.locationSignal() ? { location: this.locationSignal()! } : {}),
      ...(this.categoryIdSignal() ? { categoryId: this.categoryIdSignal() } : {}),
    });
  }

  #loadCategories(): void {
    this.#propertiesFacilitiesService
      .getPropertiesCategoriesOptions()
      .subscribe((categoriesOptions) => [(this.categoriesOptions = categoriesOptions)]);
  }

  #openEditPropertyFacilityModal(facilityId: number): void {
    this.#matDialog.open<EditPropertyFacilityComponent, IEditPropertyFacilityInputs>(EditPropertyFacilityComponent, {
      width: "28rem",
      maxHeight: "95dvh",
      data: {
        propertyId: this.propertyId(),
        facilityId,
      },
    });
  }

  #reloadDataOnPropertyFacilityChange(): void {
    this.#propertiesFacilitiesCommunicationService.propertyFacilityModified$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.#loadDataWithParams();
        },
      });
  }
}
