<div [ngClass]="appliedClass()" class="w-[18.75rem]" id="postfix-input">
  <div class="flex h-full w-full">
    <mat-form-field
      [attr.aria-labelledby]="ariaLabel() | translate"
      [attr.aria-required]="isRequired"
      appearance="outline"
      class="w-full !border-e-0"
      hideRequiredMarker
      id="postfix-email"
      subscriptSizing="dynamic"
    >
      @if (label()) {
        <mat-label>{{ label() | translate }} {{ addRequiredToLabel() | translate }}</mat-label>
      }
      <input
        (focusout)="emitFocusOut($event)"
        [errorStateMatcher]="matcher"
        [formControl]="emailControl"
        [min]="minValue()"
        [name]="name()"
        [placeholder]="placeholder() | translate"
        [type]="type()"
        matInput
      />
    </mat-form-field>

    <div
      id="postfix-text"
      [ngClass]="{
        '!border-error-200': postfixControl().touched && postfixControl().dirty && postfixControl().invalid
      }"
      class="text-black-500 flex justify-center items-center border-y border-e px-4 border-black-300 rounded-r-lg font-light text-sm whitespace-nowrap"
    >
      {{ postfixDomain() ? postfixDomain() : postFixExitValue() }}
    </div>
  </div>

  @if (postfixControl().touched && postfixControl().dirty && postfixControl().invalid) {
    @switch (postfixControl().invalid) {
      @case (postfixControl().hasError("required")) {
        <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
          <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
          {{ label() | translate }} {{ "VALIDATION.IS_REQUIRED" | translate }}
        </mat-error>
      }
      @case (postfixControl().hasError("email")) {
        <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
          <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
          {{ label() | translate }} {{ "VALIDATION.IS_NOT_A_VALID_EMAIL" | translate }}
        </mat-error>
      }

      @case (postfixControl().hasError("mat-pattern")) {
        <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
          <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
          {{ label() | translate }} {{ "VALIDATION.IS_NOT_VALID" | translate }}
        </mat-error>
      }
      @default {
        <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
          <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
          {{ label() | translate }} {{ "VALIDATION.IS_INVALID" | translate }}
        </mat-error>
      }
    }
  }
</div>
