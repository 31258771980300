import { Routes } from "@angular/router";
import { MainComponent } from "./views/main/main.component";
import { mainRoutes } from "./views/main/main.routes";
import { AuthGuard } from "./core/guards/auth.guard";
import { UserGuard } from "./core/guards/user.guard";
import { RoleGuard } from "./core/guards/role.guard";
import { DivisionNotFoundComponent } from "./views/division-not-found/division-not-found.component";
import { NoDivisionGuard } from "@root/core/guards/no-division.guard";

export const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuard, UserGuard],
    canActivateChild: [RoleGuard],
    children: mainRoutes,
  },
  {
    path: "division-not-found",
    component: DivisionNotFoundComponent,
    canActivate: [NoDivisionGuard],
  },
  {
    path: "**",
    redirectTo: "/",
  },
];
