import { Component } from "@angular/core";
import { BasePropertyMediaPackageComponent } from "@root/shared/abstracts/base-property-media-package/base-property-media-package.component";
import { BASE_PROPERTY_MEDIA_PACKAGE_IMPORTS } from "@root/shared/abstracts/base-property-media-package/base-property-media-package.imports";
import { PropertyMediaPackageFormControls } from "@root/shared/abstracts/base-property-media-package/property-media-package.form-controls";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
@Component({
  selector: "est-create-property-media-package",
  standalone: true,
  imports: BASE_PROPERTY_MEDIA_PACKAGE_IMPORTS,
  templateUrl:
    "../../../../../../../../shared/abstracts/base-property-media-package/base-property-media-package.component.html",
  styleUrl: "./create-property-media-package.component.scss",
  providers: [PropertyMediaPackageFormControls],
})
export class CreatePropertyMediaPackageComponent extends BasePropertyMediaPackageComponent {
  override isEdit = false;
  override submitForm(): void {
    this.createPackage();
  }
  createPackage() {
    this.isSubmittingForm = true;
    this.propertiesMediaPackagesService
      .createPropertyMediaPackage(this.propertyId(), this.createPropertyMediaPackageFormControls.formGroup.value)
      .subscribe({
        next: () => {
          this.router.navigate(["../"], { relativeTo: this.route });
          this.snackBarService.open(SnackbarType.Success);
        },
        error: () => (this.isSubmittingForm = false),
      });
  }
}
