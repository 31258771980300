<div class="pb-5">
  <div class="pb-1 overflow-x-auto flex gap-4 items-center whitespace-nowrap">
    @for (buttonLabel of buttonsLabels(); track $index) {
      <div
        [ngClass]="{ active: selectedIndex === $index }"
        type="button"
        class="px-4 py-3 rounded-lg bg-white border border-black-300 font-light"
      >
        {{ $index + 1 + ". " + (buttonLabel | translate) }}
      </div>
      @if (!$last) {
        <img width="6" height="10" ngSrc="assets/images/icons/chevron-right.svg" [alt]="'NEXT' | translate" />
      }
    }
  </div>
</div>
