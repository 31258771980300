import { Route } from "@angular/router";
import { OptionsComponent } from "./options/options.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { TemplatesComponent } from "./templates/templates.component";

export const communicationRoutes: Route[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "options",
  },
  {
    path: "templates",
    component: TemplatesComponent,
    data: {
      name: "SETTINGS.COMMUNICATION.TEMPLATES.NAME",
    },
  },
  {
    path: "configuration",
    component: ConfigurationComponent,
    data: {
      name: "SETTINGS.COMMUNICATION.CONFIGURATION.NAME",
    },
  },
  {
    path: "options",
    component: OptionsComponent,
    data: {
      name: "SETTINGS.COMMUNICATION.OPTIONS.NAME",
    },
  },
];
