import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal, WritableSignal } from "@angular/core";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ILinkedPackageUnit } from "@root/data/market/linked/models/linked-package-unit.model";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { M_SQUARE } from "@root/shared/constants/global.constants";
import { OperatorFunction } from "rxjs/internal/types";
import { map } from "rxjs/internal/operators/map";

export const mapLinkedUnitPackageToSignalResponseSmallTableInput = (): OperatorFunction<
  IPaginatedResponse<ILinkedPackageUnit> & { selectedUnits: number },
  ISignalPaginatedResponse<ISmallListTableInput<string>> & { selectedUnits: WritableSignal<number> }
> => {
  return map((paginatedUsers: IPaginatedResponse & { selectedUnits: number }) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedUsers),
      results: signal(mapUnitsToSmallListInput(paginatedUsers.results)),
      selectedUnits: signal(paginatedUsers.selectedUnits),
    };
  });
};

export const mapUnitsToSmallListInput = (linkedUnits: ILinkedPackageUnit[]): ISmallListTableInput<string>[] => {
  return linkedUnits.map((units) => ({
    ...units,
    id: units.unitId,
    unitArea: `${units.unitArea} ${M_SQUARE}`,
    outdoorSpaceTotalArea: `${units.outdoorSpaceTotalArea} ${M_SQUARE}`,
    price: `${units.price} DKK`,
    mouseoverMenuInputs: {
      lastChangedDate: units.lastUpdatedAt,
      outdoorSpacesOverview: units.outdoorSpacesOverview,
    },
  }));
};
