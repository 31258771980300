<est-select-search-field
  [appliedClass]="appliedClasses()"
  [control]="control()"
  [dropdownOptions]="paginatedOptions.results()"
  [isLoading]="isLoading"
  [isNoMoreResults]="isNoMoreResults"
  [searchSignal]="searchSignal"
  [selectSignal]="selectSignal"
  containsAllOption
  dropdownLabel="GEOGRAPHY"
></est-select-search-field>
