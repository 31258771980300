import { Component, DestroyRef, effect, EffectRef, inject, signal, WritableSignal } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatDialog } from "@angular/material/dialog";
import { MarketingQuestionPopupComponent } from "@root/shared/marketing-question-popup/marketing-question-popup.component";
import { IMarketingQuestionPopupInputs } from "@root/shared/marketing-question-popup/marketing-question-popup-inputs.interface";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { MarketingQuestionsService } from "@root/data/market/marketing-questions/services/marketing-questions.service";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { MarketingQuestionsTableHeader } from "@root/views/main/organization/organization-settings/components/marketing/components/surveys/components/marketing-questions/marketing-questions-table-header";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { finalize } from "rxjs";
import { ExternalTitlesPopupComponent } from "@root/shared/external-titles-popup/external-titles-popup.component";

@Component({
  selector: "est-marketing-questions",
  standalone: true,
  imports: [
    UnderDevelopmentComponent,
    SmallHeaderComponent,
    ButtonComponent,
    PaginationComponent,
    SmallListTableComponent,
  ],
  templateUrl: "./marketing-questions.component.html",
  styleUrl: "./marketing-questions.component.scss",
})
export class MarketingQuestionsComponent extends BasePaginatedTableWithSearchComponent {
  readonly #marketingQuestionsService = inject(MarketingQuestionsService);
  override headers: ITableHeader[] = MarketingQuestionsTableHeader;
  restRow: WritableSignal<null | number> = signal(null);
  #isDeletingMarketingQuestion = signal(false);
  readonly #destroyRef = inject(DestroyRef);
  protected readonly ExternalTitlesPopupComponent = ExternalTitlesPopupComponent;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "internalTitle",
    sortDescending: false,
  });
  override loadDataEffect$: EffectRef = effect(() => this.#loadDocumentsWithParams());
  override loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#marketingQuestionsService
      .getPaginatedMarketingQuestions(params, SourceLevel.Organisation)
      .subscribe((paginatedMarketingQuestions) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedMarketingQuestions);
        this.isTableLoading = false;
      });
  }
  override actions: ITableAction[] = [
    {
      isVisible: (row: ISmallListTableInput) => row["isInheritanceActive"] === true,
      callbackFn: (row: any) => this.updateMarketingInheritance(row, true),
      labelFn: () => "DEACTIVATE",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["isInheritanceActive"] === false,
      callbackFn: (row: any) => this.updateMarketingInheritance(row, true),
      labelFn: () => "ACTIVATE",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["source"] === SourceLevel.Organisation,
      callbackFn: (row: ISmallListTableInput) => this.#openEditPopup(row.id as unknown as string),
      labelFn: () => "EDIT",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["source"] === SourceLevel.Organisation,
      callbackFn: (row: ISmallListTableInput) => this.#openDeletePopup(row),
      labelFn: () => "DELETE",
    },
  ];
  readonly #matDialog = inject(MatDialog);
  readonly #dialogService = inject(DialogService);

  #loadDocumentsWithParams(): void {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
    });
  }

  openCreatePopup() {
    this.#matDialog
      .open<MarketingQuestionPopupComponent, IMarketingQuestionPopupInputs>(MarketingQuestionPopupComponent, {
        width: "75rem",
        maxHeight: "95dvh",
        data: {
          sourceLevel: SourceLevel.Organisation,
          isEditPopup: false,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#loadDocumentsWithParams();
      });
  }

  #openEditPopup(marketingQuestionId: string) {
    this.#matDialog
      .open<MarketingQuestionPopupComponent, IMarketingQuestionPopupInputs>(MarketingQuestionPopupComponent, {
        width: "75rem",
        maxHeight: "95dvh",
        data: {
          sourceLevel: SourceLevel.Organisation,
          isEditPopup: true,
          marketingQuestionId: marketingQuestionId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#loadDocumentsWithParams();
      });
  }

  #openDeletePopup(row: ISmallListTableInput) {
    this.#dialogService.open(
      {
        title: "SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.DELETE.NAME",
        callBack: () => this.#deleteMarketingQuestion(row.id),
        submitLabel: "DELETE",
        isInputIncluded: false,
        descriptions: ["SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.DELETE.CONFIRMATION"],
        isSubmitLoading: this.#isDeletingMarketingQuestion,
      },
      "confirmation",
    );
  }

  #deleteMarketingQuestion(id: number) {
    this.#isDeletingMarketingQuestion.set(true);
    this.#marketingQuestionsService
      .deleteMarketingQuestion({
        sourceLevel: SourceLevel.Organisation,
        ids: { marketingQuestionId: id.toString() },
      })
      .pipe(
        finalize(() => {
          this.#isDeletingMarketingQuestion.set(false);
          this.#dialogService.close();
        }),
      )
      .subscribe(() => this.#loadDocumentsWithParams());
  }

  updateMarketingInheritance(row: any, isAction?: boolean) {
    if (row["source"] !== SourceLevel.Organisation) {
      this.#marketingQuestionsService
        .toggleBlockedMarketingQuestion({
          sourceLevel: SourceLevel.Organisation,
          ids: { marketingQuestionId: row.id },
        })
        .subscribe(() => {
          if (isAction) {
            this.#toggleRowInheritanceButton(row);
          }
        });
    } else {
      this.#marketingQuestionsService
        .toggleMarketingQuestionInheritance({
          sourceLevel: SourceLevel.Organisation,
          ids: { marketingQuestionId: row.id },
        })
        .subscribe(() => {
          if (isAction) {
            this.#toggleRowInheritanceButton(row);
          }
        });
    }
  }

  #toggleRowInheritanceButton(row: any) {
    row.isInheritanceActive = !row.isInheritanceActive;
  }
}
