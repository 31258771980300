import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UnderDevelopmentComponent } from "../../../../../../shared/under-development/under-development.component";

@Component({
  selector: "est-integrations",
  standalone: true,
  imports: [TranslateModule, UnderDevelopmentComponent],
  templateUrl: "./integrations.component.html",
  styleUrl: "./integrations.component.scss",
})
export class IntegrationsComponent {}
