import { inject, Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable()
export class BasePetTypeFormControls {
  readonly #fb = inject(FormBuilder);
  readonly formGroup = this.#fb.group({
    petTypeId: this.#fb.control<number | null>(null, [Validators.required]),
    isApplicationRequired: this.#fb.control<boolean>(false),
    isSizeRestricted: this.#fb.control<boolean>(false),
    isSpeciesRestricted: this.#fb.control<boolean>(false),
    animalSize: this.#fb.control<number | null>(null, [Validators.required]),
    notAllowedSpeciesIds: this.#fb.control<number[]>([]),
  });

  checkAnimalSizeInputToggling = (): void => {
    if (this.formGroup.controls.isSizeRestricted.value) this.formGroup.controls.animalSize.enable();
    else this.formGroup.controls.animalSize.disable();
  };

  readonly #toggleAnimalSizeInputSubscription$ = this.formGroup.controls.isSizeRestricted.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe(this.checkAnimalSizeInputToggling);
}
