import { UnitHousingType } from "@root/data/market/import/units/enums/unit-housing-type.enum";
import { UnitListingType } from "@root/data/market/import/units/enums/unit-listing-type.enum";
import { UnitRentalCondition } from "@root/data/market/import/units/enums/unit-rental-condition.enum";
import { UnitUsageType } from "@root/data/market/import/units/enums/unit-usage-type.enum";
import { PaymentFrequency } from "@root/shared/enums/payment-frequency.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import {
  generateDropdownOptionsFromList,
  generateDropdownOptionsFromNumber,
  generateDropdownOptionsFromObject,
} from "@root/shared/utilities/dropdown-options.utilities";
import { UNITS_COLUMN_NAMES } from "@root/views/main/toolbox/import-data/constants/units-fields-names.constants";

export const UNIT_COLUMNS_DROPDOWN_OPTIONS_MAPPER = new Map<string, IDropdownOption[]>([
  [UNITS_COLUMN_NAMES.paymentFrequency, generateDropdownOptionsFromObject(PaymentFrequency)],
  [UNITS_COLUMN_NAMES.rentalCondition, generateDropdownOptionsFromObject(UnitRentalCondition)],
  [UNITS_COLUMN_NAMES.usageType, generateDropdownOptionsFromObject(UnitUsageType)],
  [UNITS_COLUMN_NAMES.housingType, generateDropdownOptionsFromObject(UnitHousingType)],
  [UNITS_COLUMN_NAMES.listingType, generateDropdownOptionsFromObject(UnitListingType)],
  [UNITS_COLUMN_NAMES.nonCancellationPeriodMonths, generateDropdownOptionsFromNumber(12, true)],
  [UNITS_COLUMN_NAMES.rentalPeriodMonths, generateDropdownOptionsFromList([3, 12, 16, 24, 36], true)],
  [UNITS_COLUMN_NAMES.noticePeriodDays, generateDropdownOptionsFromList([0, 14, 30, 60, 90], true)],
]);
