import { Component, inject, input, OnInit } from "@angular/core";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { EventMainDetailsComponent } from "./event-main-details/event-main-details.component";
import { SeriesEventsInstancesWrapperComponent } from "./series-events-instances-wrapper/series-events-instances-wrapper.component";
import { EventDetailsSignalStateService } from "./services/event-details-signal-state.service";
import { SingleEventInstanceWrapperComponent } from "./single-event-instance-wrapper/single-event-instance-wrapper.component";

@Component({
  selector: "est-event-details",
  standalone: true,
  imports: [
    EventMainDetailsComponent,
    SingleEventInstanceWrapperComponent,
    SeriesEventsInstancesWrapperComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./event-details.component.html",
  styleUrl: "./event-details.component.scss",
})
export class EventDetailsComponent implements OnInit {
  propertyId = input.required<number>();
  eventId = input.required<number>();

  readonly #eventDetailsSignalStateService = inject(EventDetailsSignalStateService);

  eventDetails = this.#eventDetailsSignalStateService.eventDetails;
  isMainDetailsLoading = this.#eventDetailsSignalStateService.isEventDetailsLoading;

  ngOnInit(): void {
    this.#eventDetailsSignalStateService.propertyId.set(this.propertyId());
    this.#eventDetailsSignalStateService.eventId.set(this.eventId());
  }
}
