<est-dialog-header
  label="TOOLBOX.IMPORT_DATA.REQUIRED_FIELDS_MISSING_TITLE"
  tooltipLabel="TOOLBOX.IMPORT_DATA.REQUIRED_FIELDS_MISSING_TITLE"
></est-dialog-header>
<div class="p-6 text-sm font-light">
  <p class="mb-6">{{ "TOOLBOX.IMPORT_DATA.REQUIRED_FIELDS_MISSING_DESCRIPTION" | translate }}</p>
  <p class="mb-4">{{ "CONTACT_ESTATETOOL_IF_IN_DOUBT" | translate }}</p>
  <p class="font-normal mb-2">{{ "TOOLBOX.IMPORT_DATA.MISSING_REQUIRED_FIELDS" | translate }}:</p>
  <ul class="list-disc ps-6">
    @for (columnField of componentInputs.missingFields; track $index) {
      <li>{{ columnField }}</li>
    }
  </ul>
</div>
<div class="px-6 py-2.5 flex justify-end items-center bg-black-50">
  <est-button (click)="matDialog.closeAll()" buttonStyleType="button--primary" buttonType="button" label="TRY_AGAIN" />
</div>
