<div class="flex justify-between gap-2 gap-y-4 mb-6 max-xl:flex-col">
  <est-small-header
    heading="SETTINGS.USERS.ACTIVE_USERS"
    subheading="SETTINGS.USERS.ACTIVATED_USERS_DESCRIPTION"
    tooltip="Active Users"
  ></est-small-header>
  <div class="flex gap-4">
    <est-search-input [searchSignal]="searchSignal" placeholder="SETTINGS.USERS.SEARCH_FOR_USER"></est-search-input>
    <button
      [matMenuTriggerFor]="menu"
      class="button--primary uppercase flex items-center gap-x-2 !h-11 min-w-[8.438rem]"
      type="button"
    >
      <img [ngSrc]="'/assets/images/icons/plus.svg'" alt="plus icon" height="12" priority width="12" />
      <span class="text-sm"> {{ "SETTINGS.USERS.CREATE_USER" | translate }}</span>
    </button>
  </div>
</div>
<div class="text-end mb-4 gap-4 flex xl:justify-end">
  <est-select-field
    [dropDownOptions]="loginStatusOptions"
    [predefinedOptions]="loginStatusPredefinedOptions"
    [selectMultipleOptions]="false"
    [selectSignal]="loginStatusSignal"
    appliedClass="!w-40"
    initialValue=""
  ></est-select-field>
  <est-select-search-field
    (scrollEnd)="loadDivisionsOptions($event)"
    [currentPage]="paginatedDivisionsOptions.currentPage()"
    [dropdownOptions]="paginatedDivisionsOptions.results()"
    [isLoading]="isDivisionsLoading"
    [isNoMoreResults]="isNoMoreDivisionsResults"
    [searchSignal]="divisionsOptionsSearch"
    [selectSignal]="divisionSignal"
    [totalPages]="paginatedDivisionsOptions.totalPages()"
    containsAllOption="true"
    dropdownLabel="DIVISIONS.SELECT_DIVISION"
  ></est-select-search-field>
</div>
<est-small-list-table
  [actions]="actions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [iconMenuComponentType]="UserDivisionRolesOverviewComponent"
  [isLoading]="isTableLoading"
  [isMouseoverIconVisibleFn]="isMouseoverIconVisible"
  [sortingFilterSignal]="sortingFilterSignal"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
<mat-menu class="mt-1" #menu="matMenu">
  <button (click)="openCreateSystemAdminDialog()" mat-menu-item>
    {{ "SETTINGS.USERS.CREATE_NEW_SYSTEM_ADMIN" | translate }}
  </button>
  <button (click)="openCreateUserDialog()" mat-menu-item>{{ "SETTINGS.USERS.CREATE_NEW_USER" | translate }}</button>
</mat-menu>
