import { Component, inject, input, OnDestroy, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CurrencyConversionDialogComponent } from "@root/shared/currency-conversion-dialog/currency-conversion-dialog.component";
import { ICurrencyDetails } from "@root/data/market/units/models/currency-details.model";
import { Subscription } from "rxjs";

@Component({
  selector: "est-currency-conversion",
  standalone: true,
  imports: [TranslateModule, ButtonComponent, PostfixTextInputComponent],
  template: `<div class="flex items-baseline gap-3 flex-wrap">
    <est-postfix-text-input
      [control]="control()"
      label="ECONOMY.BASE_RENT"
      applyNegativeValuesValidation
      [postfixText]="postText() ?? ''"
      appliedClass="!mt-4"
      [currencyCode]="currencyCode()"
      (focusout)="inputFocusout.emit($event)"
    ></est-postfix-text-input>
    <est-button
      (click)="openCurrencyConversionDialogComponent()"
      buttonStyleType="button--secondary"
      label="ECONOMY.CURRENCY_CONVERSION"
      buttonType="button"
    ></est-button>
  </div> `,
})
export class CurrencyConversionComponent implements OnDestroy {
  control = input.required<FormControl>();
  postText = input<string>();
  currencyCode = input<string>();
  selectedCurrencies = output<Set<ICurrencyDetails>>();
  unitId = input<string>();
  dialog = inject(MatDialog);
  currencyConversionDialogSubscription!: Subscription;
  inputFocusout = output<FocusEvent>();

  openCurrencyConversionDialogComponent() {
    this.currencyConversionDialogSubscription = this.dialog
      .open(CurrencyConversionDialogComponent, {
        width: "28rem",
        data: {
          unitId: this.unitId(),
          baseRent: this.control().value,
        },
      })
      .afterClosed()
      .subscribe((currencies) => {
        if (!currencies) return;
        this.selectedCurrencies.emit(currencies);
      });
  }
  ngOnDestroy() {
    if (this.currencyConversionDialogSubscription) {
      this.currencyConversionDialogSubscription.unsubscribe();
    }
  }
}
