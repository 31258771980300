<div class="p-6 relative w-[575px]">
  <hr class="mt-8 border-black-100 absolute start-0 end-0" />
  <div class="flex flex-row items-center justify-between">
    <p class="text-sm font-medium mb-1">
      {{ "TERMS_AND_CONDITIONS.PET_POLICY.PET_DETAILS" | translate }}
    </p>
    <div class="text-xs font-light">
      {{ "LAST_CHANGE" | translate }}: <span>{{ lastChangedDate() | customDate }}</span>
    </div>
  </div>

  <div class="flex flex-row my-6 justify-items-start gap-40">
    <div>
      <div class="text-xs font-medium mb-1">
        {{ "INTERNAL_TITLE" | translate }}
      </div>
      @if (internalTitle()) {
        <p class="font-light text-xs">{{ internalTitle()?.title }}</p>
      } @else {
        <div>-</div>
      }
    </div>

    <div>
      <div class="text-xs font-medium mb-1">
        {{ "LANGUAGE" | translate }}
      </div>
      <div class="flex">
        @if (internalTitle()) {
          <img class="max-w-5 max-h-3 me-2" [src]="internalTitle()!.language.flagImageUrl" [alt]="'FLAG' | translate" />
          <p class="font-light text-xs">{{ internalTitle()!.language.name }}</p>
        } @else {
          <p>-</p>
        }
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-items-start gap-40">
    <div>
      <div class="text-xs font-medium mb-1">
        {{ "EXTERNAL_TITLE" | translate }}
      </div>
      @if (externalTitles()) {
        @for (title of externalTitles(); track title) {
          <p class="font-light text-xs">{{ title.title }}</p>
        }
      } @else {
        <p>-</p>
      }
    </div>

    <div>
      <div class="text-xs font-medium mb-1">
        {{ "LANGUAGE" | translate }}
      </div>
      <div class="flex">
        @if (externalTitles()) {
          @for (title of externalTitles(); track title) {
            <img class="max-w-4 max-h-3 me-2" [src]="title.language.flagImageUrl" [alt]="'FLAG' | translate" />
            <p class="font-light text-xs">{{ title.language.name }}</p>
          }
        } @else {
          <div>-</div>
        }
      </div>
    </div>
  </div>
</div>
