import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { IEventDetailsOverview } from "@root/data/market/event/models/event-details-overview.model";
import { ILinkedPackageUnit } from "@root/data/market/linked/models/linked-package-unit.model";
import { IEventFilterPayload } from "@root/shared/interfaces/event-filter-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EventsRepository {
  readonly #http = inject(HttpClient);

  getAllEvents(params: IEventFilterPayload, id: number): Observable<IEventDetailsOverview[]> {
    return this.#http.get<IEventDetailsOverview[]>(APIS.propertyEvents.getAllEvents(id), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  listUnits(
    params: IPaginationSortPayload & { propertyIds: number[] },
  ): Observable<IPaginatedResponse<ILinkedPackageUnit>> {
    return this.#http.get<IPaginatedResponse<ILinkedPackageUnit>>(APIS.unit.getUnitsInProperty, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }
}
