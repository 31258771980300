import { AbstractControl, FormGroup } from "@angular/forms";

export const appendStylingData = (formGroup: FormGroup) => {
  const formData = new FormData();
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field) as AbstractControl;
    if (control && control.value !== null) {
      if (control instanceof FormGroup) {
        appendStylingObject(formData, control);
      } else {
        formData.append(field, control.value);
      }
    }
  });
  return formData;
};

export const appendStylingObject = (formDate: FormData, stylingDetails: any) => {
  Object.keys(stylingDetails.controls).forEach((field) => {
    const control = stylingDetails.get(field);
    if (control && control.value !== null) {
      control && formDate.append(`StylingDetailsDTO.${field}`, control.value);
    }
  });
  return formDate;
};
