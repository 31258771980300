import {
  booleanAttribute,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
  Signal,
} from "@angular/core";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { inputErrorStateMatcher } from "@root/shared/input/error-handler";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PaymentFrequency } from "@root/shared/enums/payment-frequency.enum";
import { PaymentFrequencyTranslationMapper } from "@root/shared/mappers/payment-frequency-translation.mapper";
import { NgClass } from "@angular/common";

@Component({
  selector: "est-postfix-text-input",
  standalone: true,
  imports: [MatError, MatIcon, MatLabel, MatFormField, ReactiveFormsModule, MatInput, TranslateModule, NgClass],
  templateUrl: "./postfix-text-input.component.html",
  styleUrl: "./postfix-text-input.component.scss",
})
export class PostfixTextInputComponent implements OnInit {
  label = input.required<string>();
  postfixText = input<string>();
  currencyCode = input<string>();
  paymentFrequency = input<PaymentFrequency | null | undefined>(null);
  ariaLabel: Signal<string> = computed(() => `${this.label()} label`);
  placeholder = input<string>("");
  type = input<string>("text");
  control = input(new FormControl());
  name = input("");
  appliedClass = input<string>("");
  matcher = new inputErrorStateMatcher();
  isRequired: boolean = false;
  removeWhiteSpaces = input<boolean>(true);
  minValue = input<number | null>(null);
  maxValue = input<number | null>(null);
  applyNegativeValuesValidation = input(false, { transform: booleanAttribute });
  @Output() focusedOut: EventEmitter<string> = new EventEmitter();
  readonly #translateService = inject(TranslateService);

  get currencyPaymentFrequencyText(): string | null {
    if (!this.currencyCode() || !this.paymentFrequency()) return null;
    return `${this.currencyCode()}/${this.#translateService.instant(
      PaymentFrequencyTranslationMapper.get(this.paymentFrequency()!)!,
    )}`;
  }

  ngOnInit() {
    this.isRequired = this.control().hasValidator(Validators.required);
    if (this.applyNegativeValuesValidation()) {
      this.control().addValidators(Validators.min(0));

      this.control().updateValueAndValidity();
    }
  }

  addRequiredToLabel() {
    return this.control().hasValidator(Validators.required) ? "REQUIRED" : "";
  }

  emitFocusOut(value: FocusEvent) {
    if (value.target instanceof HTMLInputElement) {
      this.focusedOut.emit(value.target.value);
    }
  }
}
