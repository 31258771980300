import { Component, inject, input, OnInit, ViewChild } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InputComponent } from "@root/shared/input/input.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { StepperNavigationComponent } from "@root/shared/stepper-navigation/stepper-navigation.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { TranslateModule } from "@ngx-translate/core";
import { BasePropertyFacilityPackage } from "@root/shared/abstracts/base-property-package-facility/base-property-package-facility.abstract";
import { addPatchJsonParser, updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { IPatchBody } from "@root/shared/interfaces/patch-body.interface";
import { OperationTypeEnum } from "@root/core/enum/operation-type.enum";
import { Router } from "@angular/router";

@Component({
  selector: "est-edit-property-facility-package",
  standalone: true,
  imports: [
    ButtonComponent,
    InputComponent,
    MatSlideToggle,
    MatStep,
    MatStepper,
    PaginationComponent,
    ReactiveFormsModule,
    SearchInputComponent,
    SelectFieldComponent,
    SmallHeaderComponent,
    SmallListTableComponent,
    StepperNavigationComponent,
    SubheaderComponent,
    TranslateModule,
    LoadingOverlayComponent,
  ],
  templateUrl: "./edit-property-facility-package.component.html",
  styleUrl: "./edit-property-facility-package.component.scss",
})
export class EditPropertyFacilityPackageComponent extends BasePropertyFacilityPackage implements OnInit {
  override isEdit = true;
  isPageLoading: boolean = false;
  packageId = input<number>(0);
  @ViewChild("stepper", { static: true }) matStepper!: MatStepper;
  private patchPayload: IPatchBody[] = [];
  #router = inject(Router);

  ngOnInit(): void {
    this.getFacilityPackageDetails();
    this.getLocationsLookUps();
    this.getCategoriesLookUps();
    this.packageIdSignal.set(this.packageId());
  }

  getFacilityPackageDetails() {
    this.isPageLoading = true;
    this.propertiesFacilitiesPackagesService.getFacilityPackageDetails(this.packageId()).subscribe({
      next: (data) => {
        this.packageForm.patchValue(data);
        this.isPageLoading = false;
      },
      error: () => {
        this.isPageLoading = false;
      },
    });
  }

  editFacilityPackage() {
    this.isLoading = true;
    const controlPaths = updateNestedControlsPathAndValue(this.packageForm);
    this.constructPatchPayload(controlPaths);
    if (this.patchPayload.length) {
      this.propertiesFacilitiesPackagesService
        .ediFacilityPackageDetails(this.packageId(), this.patchPayload, this.selectAllItemsOperation())
        .subscribe({
          next: () => {
            this.#router.navigate([`properties/${this.id.value}/marketing/facilities/facility-package`]);
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          },
        });
      return;
    }
    this.#router.navigate([`properties/${this.id.value}/marketing/facilities/facility-package`]);
  }

  nextStep() {
    if (this.internalTitle.invalid) {
      this.internalTitle.markAsDirty();
      this.internalTitle.markAsTouched();
      return;
    }
    this.matStepper.next();
  }

  private constructPatchPayload(controlPaths: { [key: string]: any }) {
    this.patchPayload = [];
    //--- push add and remove operations
    this.patchPayload.push({ op: OperationTypeEnum.Add, path: `/facilityIds`, value: Array.from(this.addedIds) });
    this.removedIds.forEach((removedId) => {
      this.patchPayload.push({ op: OperationTypeEnum.Remove, path: `/facilityIds/${removedId}` });
    });
    //--- push replace operations
    this.patchPayload.push(...addPatchJsonParser(controlPaths));
  }
}
