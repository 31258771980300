import { inject, Injectable } from "@angular/core";
import { IAvailableCurrencies } from "@root/data/market/units/models/unit-available-currencies.model";
import { IUnitEconomyDetails } from "@root/data/market/units/models/unit-economy-details.model";
import { UnitsRepository } from "@root/data/market/units/repositories/units.repository";
import { convertCurrencyToIsoFormat } from "@root/data/market/units/utilities/economy.utilities";
import { map, Observable } from "rxjs";
import { IUnitDetails } from "../models/unit-details.model";
import { IUnitHiddenFieldsModel } from "../models/unit-hidden-fields.model";
import { IUnitTermsAndConditions } from "@root/data/market/units/models/unit-terms-and-conditions.model";
import { IPetTypePolicy } from "@root/data/market/pets/models/pet-type-policy.model";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { UnitType } from "@root/data/market/units/enums/unit-type.enum";
import { mapUnitsToSignalResidentialUnitsInputOperator } from "@root/data/market/units/utilities/residential-units.utilities";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class UnitsService {
  readonly #translateService = inject(TranslateService);
  readonly #unitsRepository = inject(UnitsRepository);

  getUnitDetails(unitId: string): Observable<IUnitDetails> {
    return this.#unitsRepository.getUnitDetails(unitId);
  }

  getTermsAndConditions(unitId: string): Observable<IUnitTermsAndConditions> {
    return this.#unitsRepository.getTermsAndConditions(unitId);
  }

  updateUnitTermsAndConditions(unitId: string, unitTermsAndConditionsForm: { [key: string]: any }): Observable<void> {
    return this.#unitsRepository.updateTermsAndConditions(unitId, unitTermsAndConditionsForm);
  }

  updateUnitDetails(unitId: string, unitDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#unitsRepository.updateUnitDetails(unitId, unitDetailsForm);
  }

  getAllCurrenciesForUnit(unitId: string): Observable<IAvailableCurrencies> {
    return this.#unitsRepository.getAllCurrenciesForUnit(unitId);
  }

  getUnitEconomyDetailsById(unitId: string): Observable<IUnitEconomyDetails> {
    return this.#unitsRepository.getUnitEconomyDetailsById(unitId).pipe(
      map((unitEconomyDetails) => {
        return convertCurrencyToIsoFormat(unitEconomyDetails);
      }),
    );
  }

  getHiddenFields(unitId: string): Observable<IUnitHiddenFieldsModel> {
    return this.#unitsRepository.getHiddenFields(unitId);
  }

  updateUnitEconomyField(unitId: string, path: string, value: any): Observable<void> {
    return this.#unitsRepository.updateUnitEconomyField(unitId, path, value);
  }

  updateUnitPetTypePolicies(unitId: string, petTypePolicies: IPetTypePolicy[]): Observable<void> {
    return this.#unitsRepository.updateUnitPetTypePolicy(unitId, petTypePolicies);
  }

  getPaginatedUnitsInProperty(
    params: IPaginationSortPayload & {
      propertyIds: string[];
    } & { unitType: UnitType },
  ): Observable<ISignalPaginatedResponse<ILargeListTableInput<string>>> {
    return this.#unitsRepository
      .getPaginatedUnitsInProperty(params)
      .pipe(mapUnitsToSignalResidentialUnitsInputOperator(this.#translateService));
  }

  toggleFavoriteUnit(unitId: string): Observable<boolean> {
    return this.#unitsRepository.toggleFavoriteUnit(unitId);
  }

  makeUnitAvailable(unitId: string): Observable<boolean> {
    return this.#unitsRepository.makeUnitAvailable(unitId);
  }

  toggleAdvertiseUnit(unitId: string): Observable<boolean> {
    return this.#unitsRepository.toggleAdvertiseUnit(unitId);
  }
}
