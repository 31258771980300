import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { RouterOutlet } from "@angular/router";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";

@Component({
  selector: "est-marketing",
  standalone: true,
  imports: [TranslateModule, RouterOutlet, NavigationTabsComponent, SubheaderComponent],
  templateUrl: "./marketing.component.html",
  styleUrl: "./marketing.component.scss",
})
export class MarketingComponent {
  protected marketingTabs: INavigationTab[] = [
    {
      label: "SETTINGS.MARKETING.SURVEYS.NAME",
      routerLink: "surveys",
    },
    {
      label: "SETTINGS.MARKETING.BADGES.NAME",
      routerLink: "badges",
    },
    {
      label: "SETTINGS.MARKETING.LABELS.NAME",
      routerLink: "labels",
    },
    {
      label: "SETTINGS.MARKETING.POI.NAME",
      routerLink: "poi",
    },
    {
      label: "SETTINGS.MARKETING.QR_CODES.NAME",
      routerLink: "qr-codes",
    },
    {
      label: "SETTINGS.MARKETING.PROMOTION_LABELS.NAME",
      routerLink: "promotion-labels",
    },
    {
      label: "SETTINGS.MARKETING.CERT_LABELS.NAME",
      routerLink: "certification-labels",
    },
    {
      label: "SETTINGS.MARKETING.FORM_BUILDER.NAME",
      routerLink: "form-builder",
    },
  ];
}
