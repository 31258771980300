import { Component, inject, input, OnInit, signal, WritableSignal } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { InputComponent } from "@root/shared/input/input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { GeographicalLocationComponent } from "@root/shared/geographical-location/geographical-location.component";
import { StaticAddressComponent } from "@root/shared/static-address/static-address.component";
import { constructInitialAddressLookups, formatAddress } from "@root/shared/utilities/address.utilities";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { UnitsService } from "@root/data/market/units/services/units.service";
import { IUnitDetails } from "@root/data/market/units/models/unit-details.model";
import { updateControlAndPatch, updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { IUnitHiddenFieldsModel } from "@root/data/market/units/models/unit-hidden-fields.model";
import { UnitHiddenFieldsEnum } from "@root/data/market/units/enums/unit-hidden-fields.enum";
import { UnitMainDetailsFormControls } from "./unit-main-details.form-controls";
import { MatError } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "est-main-details",
  standalone: true,
  templateUrl: "./unit-main-details.component.html",
  styleUrl: "./unit-main-details.component.scss",
  imports: [
    SubheaderComponent,
    LoadingOverlayComponent,
    ReactiveFormsModule,
    TooltipComponent,
    TranslateModule,
    InputComponent,
    SelectFieldComponent,
    MatSlideToggle,
    DatepickerComponent,
    GeographicalLocationComponent,
    StaticAddressComponent,
    MatError,
    MatIcon,
  ],
  providers: [UnitMainDetailsFormControls],
})
export class UnitMainDetailsComponent implements OnInit {
  public get hiddenFieldsEnum(): typeof UnitHiddenFieldsEnum {
    return UnitHiddenFieldsEnum;
  }

  hiddenFields: string[] = [];
  showFloor: WritableSignal<boolean> = signal(true);
  showSide: WritableSignal<boolean> = signal(true);
  mainDetailsFormControls = inject(UnitMainDetailsFormControls);
  lookupsService = inject(LookupsService);
  unitsService = inject(UnitsService);
  isLoading = false;
  unitId = input<string>();
  formattedAddressSignal: WritableSignal<string> = signal<string>("");
  isGeographicalLocationLoadingSignal = signal(false);
  addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  translationService = inject(TranslateService);
  housingTypeLookupOptions: IDropdownOption[] = [];
  unitTypesLookupOptions: IDropdownOption[] = [];
  unitSalesTypesLookupOptions: IDropdownOption[] = [];

  ngOnInit(): void {
    this.getHiddenFields();
    this.#getAddressLookupsOptions();
    this.getUnitDetails();
    this.#getUnitSpecificationLookups();
  }

  getHiddenFields() {
    this.unitsService.getHiddenFields(this.unitId()!).subscribe((unitHiddenFields: IUnitHiddenFieldsModel) => {
      this.hiddenFields = unitHiddenFields.hiddenInputs;
      this.showFloor.set(!this.hiddenFields.includes(this.hiddenFieldsEnum.AddressFloor));
      this.showSide.set(!this.hiddenFields.includes(this.hiddenFieldsEnum.AddressSide));
    });
  }

  #getUnitSpecificationLookups(): void {
    this.#getHousingTypeLookupOptions();
    this.#getUnitTypesLookupOptions();
    this.#getUnitSalesTypeLookupOptions();
  }

  #getAddressLookupsOptions(): void {
    this.lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }

  checkedUnitUnderRenovation(checkedValue: boolean) {
    if (!checkedValue) {
      this.mainDetailsFormControls.renovationStartDate.disable();
      this.mainDetailsFormControls.renovationEndDate.disable();
    } else {
      this.mainDetailsFormControls.renovationStartDate.enable();
      this.mainDetailsFormControls.renovationEndDate.enable();
    }
    this.mainDetailsFormControls.isUnderRenovation.setValue(checkedValue);
    this.updateUnitDetails();
  }

  getUnitIdNumberTranslation(key: string, unitIdNumber: number) {
    return this.translationService.instant(key).replace("id", unitIdNumber);
  }

  // static property id is added for testing
  updateCaseIdControl() {
    this.mainDetailsFormControls.caseId.setValue(this.mainDetailsFormControls.customUnitId1.value + "Property Id");
  }

  #getHousingTypeLookupOptions() {
    this.lookupsService.getHousingTypeLookup().subscribe((housingLookupOptions) => {
      this.housingTypeLookupOptions = housingLookupOptions;
    });
  }

  #getUnitTypesLookupOptions() {
    this.lookupsService.getUnitTypesLookup().subscribe((unitTypesLookupOptions) => {
      this.unitTypesLookupOptions = unitTypesLookupOptions;
    });
  }

  #getUnitSalesTypeLookupOptions() {
    this.lookupsService.getUnitSalesType().subscribe((unitSalesTypeLookupOptions) => {
      this.unitSalesTypesLookupOptions = unitSalesTypeLookupOptions;
    });
  }

  getUnitDetails() {
    this.isLoading = true;
    this.unitsService.getUnitDetails(this.unitId()!).subscribe((response: IUnitDetails) => {
      this.isLoading = false;
      this.mainDetailsFormControls.mainDetailsForm.patchValue(response);
      this.updateCaseIdControl();
      this.checkedUnitUnderRenovation(this.mainDetailsFormControls.isUnderRenovation.value);
      this.formattedAddressSignal.set(formatAddress(this.mainDetailsFormControls.addressInformation));
    });
  }

  updateUnitDetails() {
    const controlPaths = updateNestedControlsPathAndValue(this.mainDetailsFormControls.mainDetailsForm);
    !!Object.keys(controlPaths).length &&
      this.unitsService.updateUnitDetails(this.unitId()!, controlPaths).subscribe({
        next: () => {
          this.updateCaseIdControl();
          this.formattedAddressSignal.set(formatAddress(this.mainDetailsFormControls.addressInformation));
        },
      });
  }

  addressSelectChange(event: { name: string; value: number }) {
    const control = this.mainDetailsFormControls.mainDetailsForm.get(event.name);
    control?.setValue(event.value);
    this.updateUnitDetails();
  }

  updateDetailsOnUpdateGeoLocation(updated: boolean) {
    if (updated) this.updateUnitDetails();
  }

  updateControlAndPatchNewValue(value: string, fieldName: string) {
    updateControlAndPatch(
      value,
      fieldName,
      this.mainDetailsFormControls.mainDetailsForm,
      this.updateUnitDetails.bind(this),
    );
  }
}
