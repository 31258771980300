<est-dialog-header
  label="PROPERTY_FACILITIES.ADD_FACILITY"
  tooltipLabel="PROPERTY_FACILITIES.ADD_FACILITY"
></est-dialog-header>
<div class="p-6">
  <p class="text-sm font-light mb-6">{{ componentInputs.description | translate }}</p>
  <hr class="absolute start-0 end-0 border-black-100" />
  <div class="flex justify-between items-center mt-12 mb-5">
    <div>
      <div class="flex gap-2 items-center mb-2">
        <p class="text-sm font-medium">
          {{ componentInputs.header | translate }}
        </p>
        <est-tooltip [tooltip]="componentInputs.header"></est-tooltip>
      </div>
      <p class="text-sm font-light">
        {{ "PROPERTY_FACILITIES.FACILITIES.NAME" | translate }}:
        <span>{{ totalFacilitiesNo }}</span>
      </p>
    </div>
    <est-search-input [searchSignal]="searchSignal"></est-search-input>
  </div>
  @if (paginatedFacilities.results().length > 0) {
    <div class="grid grid-cols-[repeat(2,minmax(0,0.485fr))] text-xs font-medium mb-2">
      <p class="ps-14">{{ "PROPERTY_FACILITIES.FACILITIES.FACILITY" | translate }}</p>
      <p>{{ "CATEGORY" | translate }}</p>
    </div>
  }
  <div #facilitiesView (scroll)="loadNextPage()" class="pe-1 max-h-48 overflow-auto relative">
    @for (propertyFacility of paginatedFacilities.results(); track propertyFacility.id) {
      <div
        [ngClass]="{ '!bg-black-50': checkboxHashmap.get(propertyFacility.id) }"
        class="relative grid grid-cols-2 text-sm font-light mb-1 border border-black-300 bg-white rounded-lg"
      >
        <div class="flex items-center ps-14">
          <p>{{ propertyFacility.name }}</p>
        </div>
        <div class="flex justify-between items-center">
          <p>{{ propertyFacility.categoryName }}</p>
          <mat-checkbox
            [disabled]="disabledFacilitiesIds.includes(propertyFacility.id)"
            [checked]="disabledFacilitiesIds.includes(propertyFacility.id) || checkboxHashmap.get(propertyFacility.id)"
            (change)="checkboxHashmap.set(propertyFacility.id, $event.checked)"
          ></mat-checkbox>
        </div>
        <div
          class="absolute w-6 h-6 flex items-center justify-center rounded border border-black-300 top-0 bottom-0 m-auto start-4 bg-white"
        >
          @if (propertyFacility.iconUrl) {
            <img class="w-3 h-3" [alt]="'ICON' | translate" [src]="propertyFacility.iconUrl" />
          }
        </div>
      </div>
    }
    @if (isLoading) {
      <div class="text-center p-3">
        <est-spinner width="30" height="30"></est-spinner>
      </div>
    }
  </div>
</div>
<div class="dialog__footer">
  <est-button (click)="matDialog.closeAll()" buttonStyleType="button--tertiary" buttonType="button" label="CANCEL" />
  <est-button
    (click)="addFacility()"
    [isLoading]="isAddingFacility"
    buttonStyleType="button--primary"
    buttonType="button"
    label="ADD"
  />
</div>
