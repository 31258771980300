import { Route } from "@angular/router";
import { MarketingQuestionsComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/surveys/components/marketing-questions/marketing-questions.component";
import { DemographicQuestionsComponent } from "@root/views/main/organization/organization-settings/components/marketing/components/surveys/components/demographic-questions/demographic-questions.component";

export const surveysRoutes: Route[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "marketing-questions",
  },
  {
    path: "marketing-questions",
    component: MarketingQuestionsComponent,
    data: {
      name: "SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.NAME",
    },
  },
  {
    path: "demographic-questions",
    component: DemographicQuestionsComponent,
    data: {
      name: "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.NAME",
    },
  },
];
