import { Component, inject, input, OnInit } from "@angular/core";
import { ImageCroppedEvent, ImageCropperModule, ImageTransform } from "ngx-image-cropper";
import { NgStyle } from "@angular/common";
import { MatSlider, MatSliderThumb } from "@angular/material/slider";
import { MatDialog } from "@angular/material/dialog";
import { ImageUploadDialogComponent } from "../image-upload-dialog/image-upload-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormControl, FormsModule } from "@angular/forms";

@Component({
  selector: "est-image-upload",
  standalone: true,
  imports: [NgStyle, ImageCropperModule, MatSlider, MatSliderThumb, TranslateModule, FormsModule],
  templateUrl: "./image-upload.component.html",
  styleUrl: "./image-upload.component.scss",
})
export class ImageUploadComponent implements OnInit {
  title = input.required<string>();
  description = input.required<string>();
  imageFormControl = input.required<FormControl<File | null | string>>();
  imagePlaceHolderText = input<string>();
  displayedImage: string | null = "";
  originalImage: string | null = "";
  croppedImage: string | null = "";
  imageTransform: ImageTransform = {
    translateUnit: "px",
    scale: 1,
  };
  imageScale = 1;
  isEditMode: boolean = false;
  dialog = inject(MatDialog);

  ngOnInit() {
    if (this.imageFormControl().value) {
      this.displayedImage = this.imageFormControl().value as string;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    const reader = new FileReader();
    reader.readAsDataURL(<Blob>event.blob);
    reader.onload = () => {
      this.croppedImage = reader.result as string;
    };
  }

  handleZooming(event: Event) {
    this.imageScale = Number((event.target as HTMLInputElement).value);
    this.imageTransform = {
      ...this.imageTransform,
      scale: this.imageScale / 10,
    };
  }

  openDialog(): void {
    this.dialog
      .open(ImageUploadDialogComponent, {})
      .afterClosed()
      .subscribe((image) => {
        if (!image) return;
        this.imageFormControl().setValue(image);
        const reader = new FileReader();
        reader.onload = () => {
          this.displayedImage = reader.result as string;
          this.originalImage = this.displayedImage;
        };
        reader.readAsDataURL(image);
      });
  }

  toggleEditMode(isEnabled: boolean) {
    this.isEditMode = isEnabled;
    if (this.isEditMode) {
      this.displayedImage = this.originalImage;
    }
  }

  saveCroppedImage() {
    this.isEditMode = false;
    this.displayedImage = this.croppedImage;
    this.resetConfigs();
  }

  removeImage() {
    this.displayedImage = null;
    this.imageFormControl().setValue(null);
    this.resetConfigs();
  }

  resetConfigs(): void {
    this.imageScale = 1;
    this.imageTransform = {
      translateUnit: "px",
    };
  }
}
