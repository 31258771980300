import { Component, effect, EffectRef, inject, input, output, signal, WritableSignal } from "@angular/core";
import { FormControl, NonNullableFormBuilder } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { LargeListTableComponent } from "@root/shared/large-list-table/large-list-table.component";
import { LINKED_DIVISIONS_TABLE_HEADERS } from "@root/shared/linked-divisions-table-popup/linked-divisions.header";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";

@Component({
  selector: "est-linked-divisions-table",
  standalone: true,
  imports: [
    SearchInputComponent,
    SelectFieldComponent,
    SmallListTableComponent,
    PaginationComponent,
    LargeListTableComponent,
    TranslateModule,
  ],
  templateUrl: "./linked-divisions-table.component.html",
  styleUrl: "./linked-divisions-table.component.scss",
})
export class LinkedDivisionsTableComponent extends BasePaginatedTableWithSearchComponent {
  headers: ITableHeader[] = LINKED_DIVISIONS_TABLE_HEADERS;

  readonly #divisionService = inject(DivisionService);
  readonly #fb = inject(NonNullableFormBuilder);
  divisionIdsFC = this.#fb.control<number[]>([]);

  selectedDivisionMapper: Map<string, FormControl<number[]>> = new Map([["divisionIds", this.divisionIdsFC]]);
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });

  linkingSource = input.required<LinkingSource>();
  stepIndex = input.required<number>();
  isEdit = input.required<boolean>();
  updateLinkDivisionsIds = output<number[]>();

  readonly loadDataEffect$: EffectRef = effect(() => {
    if (this.stepIndex() === 0) {
      this.loadData({
        sortBy: this.sortingFilterSignal().sortBy,
        sortDescending: this.sortingFilterSignal().sortDescending,
        pageSize: this.paginatedData.pageSize(),
        pageNumber: this.paginatedData.currentPage(),
        search: this.searchSignal(),
      });
    }
  });

  loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#divisionService.getPaginatedTableInputDivisionsOverview(params).subscribe({
      next: (paginatedDivisions) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedDivisions);
        this.isTableLoading = false;
      },
      error: () => (this.isTableLoading = false),
    });
  }

  addAndRemoveItem() {
    const addingIds = new Set([...this.divisionIdsFC.value]);
    this.updateLinkDivisionsIds.emit(Array.from(addingIds));
  }

  addAndRemoveItems() {
    const addingIds = new Set([...this.divisionIdsFC.value]);
    this.updateLinkDivisionsIds.emit(Array.from(addingIds));
  }
}
