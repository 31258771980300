import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DemographicQuestionsRepository } from "@root/data/market/demographic-questions/repositories/demographic-questions.repository";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { Observable } from "rxjs/internal/Observable";
import { mapDemographicQuestionsToSignalResponseSmallTableInput } from "../utilities/demographic-questions.utilities";

@Injectable({
  providedIn: "root",
})
export class DemographicQuestionsService {
  readonly #translateService = inject(TranslateService);
  readonly #demographicQuestionsRepository = inject(DemographicQuestionsRepository);

  getPaginatedDemographicQuestions(
    params: IPaginationSortPayload,
    sourceLevel: SourceLevel,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput<number>>> {
    return this.#demographicQuestionsRepository
      .getPaginatedDemographicQuestions(params, sourceLevel)
      .pipe(mapDemographicQuestionsToSignalResponseSmallTableInput(this.#translateService));
  }

  deleteDemographicQuestion(sourceLevel: SourceLevel, id: number): Observable<void> {
    return this.#demographicQuestionsRepository.deleteDemographicQuestion(sourceLevel, id);
  }

  toggleDemographicQuestionInheritance(sourceLevel: SourceLevel, id: number): Observable<void> {
    return this.#demographicQuestionsRepository.toggleDemographicQuestionInheritance(sourceLevel, id);
  }
}
