import { inject, Injectable } from "@angular/core";
import { PropertiesFacilitiesRepository } from "@root/data/market/properties/repositories/properties-facilities.repository";
import { map, Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapPaginatedPropertiesFacilitiesToSmallListTableInput } from "@root/data/market/properties/utilities/properties-facilities.utilities";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { IPropertyFacilityLookup } from "@root/data/market/properties/models/property-facility-lookup.model";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPropertyFacilitiesPaginationPayload } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/property-facilities-pagination-payload.interface";
import { ITogglePropertyFacilityPayload } from "@root/data/market/properties/models/toggle-property-facility-payload.model";
import { ITogglePropertyFacilityResponse } from "@root/data/market/properties/models/toggle-property-facility-response.model";
import { IPropertyFacility } from "@root/data/market/properties/models/property-facility.model";
import { IEditPropertyFacilityPayload } from "@root/data/market/properties/models/edit-property-facility-payload.model";

@Injectable({
  providedIn: "root",
})
export class PropertiesFacilitiesService {
  readonly #propertiesFacilitiesRepository = inject(PropertiesFacilitiesRepository);

  getAssignedPropertyFacilitiesIds(propertyId: number): Observable<number[]> {
    return this.#propertiesFacilitiesRepository.getAssignedPropertyFacilitiesIds(propertyId);
  }

  getPaginatedAssignedPropertiesFacilities(
    payload: IPropertyFacilitiesPaginationPayload,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#propertiesFacilitiesRepository
      .getAllAssignedPropertiesFacilities(payload)
      .pipe(mapPaginatedPropertiesFacilitiesToSmallListTableInput());
  }

  togglePropertyFacility(payload: ITogglePropertyFacilityPayload): Observable<ITogglePropertyFacilityResponse> {
    return this.#propertiesFacilitiesRepository.togglePropertyFacility(payload);
  }

  addPropertyFacility(propertyId: number, propertiesFacilitiesIds: number[]): Observable<void> {
    return this.#propertiesFacilitiesRepository.addPropertyFacility(propertyId, propertiesFacilitiesIds);
  }

  editPropertyFacility(payload: Partial<IEditPropertyFacilityPayload>) {
    return this.#propertiesFacilitiesRepository.editPropertyFacility(payload);
  }

  getPaginatedPropertiesFacilities(
    payload: IPaginationPayload,
  ): Observable<ISignalPaginatedResponse<IPropertyFacilityLookup>> {
    return this.#propertiesFacilitiesRepository.getAllPropertiesFacilities(payload).pipe(
      map((paginatedData) => ({
        ...mapToSignalPaginatedResponse(paginatedData),
      })),
    );
  }

  getPropertiesCategoriesOptions(): Observable<IDropdownOption[]> {
    return this.#propertiesFacilitiesRepository.getPropertiesCategories().pipe(
      map((categories) => {
        return categories.map((category) => ({
          value: category.id,
          label: category.name,
        }));
      }),
    );
  }

  getPropertyFacilityDetails(propertyId: number, facilityId: number): Observable<IPropertyFacility> {
    return this.#propertiesFacilitiesRepository.getPropertyFacilityDetails(propertyId, facilityId);
  }
}
