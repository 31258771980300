import { Injectable, inject } from "@angular/core";
import { AuthService } from "../../auth/services/auth.service";
import { IUserDivisionRole } from "../../users/models/user-division-role.model";

@Injectable({
  providedIn: "root",
})
export class SetCurrentDivisionService {
  readonly authService = inject(AuthService);

  setCurrentUserDivisionRole(userDivisionRole: IUserDivisionRole, reloadWindow = true) {
    this.authService.currentUserDivisionRole = userDivisionRole;
    this.authService.saveLastUserDivision(userDivisionRole.divisionId).subscribe(() => {
      if (reloadWindow) window.location.reload();
    });
  }
}
