import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-property-layout",
  standalone: true,
  templateUrl: "./property-layout.component.html",
  styleUrl: "./property-layout.component.scss",
  imports: [UnderDevelopmentComponent],
})
export class PropertyLayoutComponent {}
