import { Component, DestroyRef, effect, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { InputComponent } from "@root/shared/input/input.component";
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { LargeListTableComponent } from "@root/shared/large-list-table/large-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PropertiesFacilitiesPackagesService } from "@root/data/market/properties/services/properties-facilities-packages.service";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { DuplicationOption } from "@root/data/market/properties/enums/duplication-option.enum";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { DUPLICATE_DIALOG } from "@root/views/main/property/property-marketing/components/facilities/facility-package/duplicate-dialog/duplicate-dialog-header";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { MatError } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { UpdateFacilityPackageListCommunicationService } from "@root/views/main/property/property-marketing/components/facilities/facility-package/update-facility-package-list-communication.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "est-duplicate-dialog",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    DialogHeaderComponent,
    TranslateModule,
    InputComponent,
    SelectFieldComponent,
    SearchInputComponent,
    LargeListTableComponent,
    PaginationComponent,
    ButtonComponent,
    MatTooltip,
    SmallListTableComponent,
    ReactiveFormsModule,
    LoadingOverlayComponent,
    MatError,
    MatIcon,
  ],
  templateUrl: "./duplicate-dialog.component.html",
  styleUrl: "./duplicate-dialog.component.scss",
})
export class DuplicateDialogComponent extends BasePaginatedTableWithSearchComponent implements OnInit {
  control = new FormControl();
  headers = DUPLICATE_DIALOG;
  isLoading: boolean = false;
  updateFacilityPackageListCommunicationService = inject(UpdateFacilityPackageListCommunicationService);
  isPackageDetailsLoading: boolean = false;
  readonly dialog = inject(MatDialog);
  componentInputs: { packageId: number } = inject(MAT_DIALOG_DATA);
  #propertiesFacilitiesPackagesService = inject(PropertiesFacilitiesPackagesService);
  fb = inject(NonNullableFormBuilder);
  isSelectedPropertyError: boolean = false;
  private readonly destroyRef$ = inject(DestroyRef);

  duplicationOptions: IDropdownOption[] = [
    { label: "PACKAGES.FACILITY_PACKAGES.DUPLICATE_PACKAGE.ONLY_FACILITIES", value: DuplicationOption.Facilities },
    {
      label: "PACKAGES.FACILITY_PACKAGES.DUPLICATE_PACKAGE.FACILITIES_AND_SETTING",
      value: DuplicationOption.FacilitiesAndSettings,
    },
  ];
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });

  internalTitleControl = this.fb.control<string>("", Validators.required);
  originalName = this.fb.control({ value: "", disabled: true });
  duplicationOptionControl = this.fb.control<DuplicationOption | undefined>(undefined, Validators.required);
  propertyIdsControl = this.fb.control<number[]>([], Validators.required);
  facilityIdsMapper: Map<string, FormControl<number[]>> = new Map([["propertyId", this.propertyIdsControl]]);

  duplicateFromGroup = this.fb.group({
    internalTitle: this.internalTitleControl,
    duplicationOption: this.duplicationOptionControl,
    propertyIds: this.propertyIdsControl,
  });

  ngOnInit() {
    this.getPackageDetails();
    this.propertyIdsControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe(() => {
      this.propertyIdsControl.value ? (this.isSelectedPropertyError = false) : (this.isSelectedPropertyError = true);
    });
  }

  getPackageDetails() {
    this.isPackageDetailsLoading = true;
    this.#propertiesFacilitiesPackagesService.getFacilityPackageDetails(this.componentInputs.packageId).subscribe({
      next: (data) => {
        this.duplicateFromGroup.patchValue(data);
        this.originalName.setValue(data.internalTitle ?? "");
        this.isPackageDetailsLoading = false;
      },
      error: () => (this.isPackageDetailsLoading = false),
    });
  }

  readonly loadDataEffect$ = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
    });
  });

  loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#propertiesFacilitiesPackagesService.getPropertiesForLinking(params).subscribe({
      next: (paginatedProperty) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedProperty);
        this.isTableLoading = false;
      },
      error: () => {
        this.isTableLoading = false;
      },
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  duplicatePackage() {
    if (this.duplicateFromGroup.invalid) {
      markAllControlsAsTouchedAndDirty(this.duplicateFromGroup);
      this.isSelectedPropertyError = true;
      return;
    }

    this.#propertiesFacilitiesPackagesService
      .duplicatePackagePackage(this.componentInputs.packageId, this.duplicateFromGroup.value)
      .subscribe({
        next: () => {
          this.updateFacilityPackageListCommunicationService.reloadFacilityPackageListPage$.next();
          this.closeDialog();
        },
        error: () => this.closeDialog(),
      });
  }
}
