import {
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  Bold,
  Code,
  type EditorConfig,
  Essentials,
  FontBackgroundColor,
  FontFamily,
  FontSize,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  List,
  Paragraph,
  RemoveFormat,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  HorizontalLine,
  Underline,
  Undo,
} from "ckeditor5";

const CK_CONFIG: EditorConfig = {
  toolbar: {
    items: [
      "bold",
      "italic",
      "underline",
      "removeFormat",
      "|",
      "fontFamily",
      "|",
      "fontBackgroundColor",
      "|",
      "bulletedList",
      "numberedList",
      "alignment",
      "|",
      "fontSize",
      "-",
      "insertTable",
      "|",
      "link",
      "imageUpload",
      "horizontalLine",
      "|",
      "code",
      "|",
      "accessibilityHelp",
    ],
    shouldNotGroupWhenFull: true,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    Bold,
    Code,
    Essentials,
    FontBackgroundColor,
    FontFamily,
    FontSize,
    HorizontalLine,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    LinkImage,
    List,
    Paragraph,
    RemoveFormat,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    Undo,
  ],
  fontFamily: {
    supportAllValues: true,
  },
  fontSize: {
    options: [10, 12, 14, "default", 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40],
    supportAllValues: true,
  },
  image: {
    toolbar: [
      "toggleImageCaption",
      "imageTextAlternative",
      "|",
      "imageStyle:inline",
      "imageStyle:wrapText",
      "imageStyle:breakText",
      "|",
      "resizeImage",
    ],
  },
  initialData: "",
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: "https://",
    decorators: {
      toggleDownloadable: {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "file",
        },
      },
    },
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true,
    },
  },
  menuBar: {
    isVisible: true,
  },
  placeholder: "Type or paste your content here!",
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
  },
};

export default CK_CONFIG;
