<button
  [disabled]="disabled()"
  [ngClass]="{ selected: selected.checked && !initialSelected(), 'cursor-default': disabled() }"
  id="select-option"
  class="select-none w-full flex justify-between items-center relative"
  (click)="toggleSelection()"
>
  <p class="font-light text-sm">{{ label() | translate }}</p>
  <mat-checkbox
    class="pointer-events-none"
    [disabled]="disabled()"
    [checked]="initialSelected()"
    #selected
    (change)="toggleSelection()"
  ></mat-checkbox>
</button>
