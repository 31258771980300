import { ITableHeader } from "../interfaces/table-header.interface";
import { TableHeaderType } from "../enums/table-header-type.enum";

export const createTeamTableHeaders: ITableHeader[] = [
  {
    label: "",
    key: "teamMember",
    type: TableHeaderType.Checkbox,
    isSortable: false,
  },
  {
    label: "NUMBER",
    key: "number",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "FIRST_NAME",
    key: "firstName",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    label: "LAST_NAME",
    key: "lastName",
    type: TableHeaderType.Text,
    isSortable: false,
  },
  {
    label: "SETTINGS.USERS.ROLE",
    key: "role",
    type: TableHeaderType.Text,
    isSortable: false,
    needsTranslation: true,
  },
  {
    label: "SETTINGS.USERS.JOINED",
    key: "joined",
    type: TableHeaderType.Date,
    isSortable: false,
  },
  {
    label: "SETTINGS.USERS.LAST_ACTIVE",
    key: "lastActive",
    type: TableHeaderType.Date,
    isSortable: false,
  },
  {
    label: "TEAMS.TEAM_MANAGER",
    key: "teamMangerId",
    type: TableHeaderType.Radio,
    isSortable: false,
  },
  {
    label: "TEAMS.TEAM_LEADERS",
    key: "teamLeader",
    type: TableHeaderType.Checkbox,
    isSortable: false,
  },
];
