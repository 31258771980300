import { NgClass } from "@angular/common";
import { AfterViewInit, Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { BaseLinkedItemsComponent } from "@root/shared/abstracts/base-linked-items/base-linked-items";
import { LinkedUnitsTableComponent } from "@root/shared/abstracts/base-linked-items/linked-units/linked-units-table.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { LinkedCompaniesTableComponent } from "../abstracts/base-linked-items/linked-companies-table/linked-companies-table.component";
import { LinkedDivisionsTableComponent } from "../abstracts/base-linked-items/linked-divisions-table/linked-divisions-table.component";
import { LinkedPropertiesTableComponent } from "../abstracts/base-linked-items/linked-properties-table/linked-properties-table.component";

@Component({
  selector: "est-edit-link-dialog",
  standalone: true,
  imports: [
    ButtonComponent,
    LinkedUnitsTableComponent,
    LinkedDivisionsTableComponent,
    LinkedCompaniesTableComponent,
    LinkedPropertiesTableComponent,
    DialogHeaderComponent,
    FormsModule,
    MatProgressBar,
    MatStep,
    NgClass,
    MatStepper,
    TranslateModule,
    LoadingOverlayComponent,
    MatTooltip,
    TooltipComponent,
  ],
  templateUrl: "../abstracts/base-linked-items/base-linked-items.html",
})
export class EditLinkDialogComponent extends BaseLinkedItemsComponent implements AfterViewInit {
  isEdit = true;

  ngAfterViewInit(): void {
    this.changeDec.detectChanges();
  }
}
