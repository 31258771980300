import { APIS } from "@root/core/api/market.apis";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";

export const LinkingSourceApiMapper = new Map<
  LinkingSource,
  {
    getLinkedUnitsList: (propertyId: number | undefined, packageId: number | undefined) => string;
    updateLinkedUnits: (propertyId: number | undefined, packageId: number | undefined) => string;
  }
>([
  [LinkingSource.DEFAULT, { getLinkedUnitsList: () => APIS.unit.getUnitsInProperty, updateLinkedUnits: () => "" }],
  [
    LinkingSource.FACILITY_PACKAGE,
    {
      getLinkedUnitsList: (propertyId: number | undefined, packageId: number | undefined) =>
        APIS.linking.facilityPackages.listLinkedUnits(propertyId!, packageId!),
      updateLinkedUnits: (_propertyId: number | undefined, facilityPackageId: number | undefined) =>
        APIS.linking.facilityPackages.updateLinkedUnits(facilityPackageId!),
    },
  ],
  [
    LinkingSource.MEDIA_PACKAGE,
    {
      getLinkedUnitsList: (propertyId: number | undefined, mediaPackageId: number | undefined) =>
        APIS.linking.mediaPackages.listLinkedUnits(propertyId!, mediaPackageId!),
      updateLinkedUnits: (propertyId: number | undefined, mediaPackageId: number | undefined) =>
        APIS.linking.mediaPackages.updateLinkedUnits(propertyId!, mediaPackageId!),
    },
  ],
  [
    LinkingSource.TEXT_PACKAGE,
    {
      getLinkedUnitsList: (propertyId: number | undefined, mediaPackageId: number | undefined) =>
        APIS.linking.textPackages.listLinkedUnits(propertyId!, mediaPackageId!),
      updateLinkedUnits: (propertyId: number | undefined, mediaPackageId: number | undefined) =>
        APIS.linking.textPackages.updateLinkedUnits(propertyId!, mediaPackageId!),
    },
  ],
]);
