export enum EventType {
  PublicEvent = "Public",
  PrivateEvent = "Private",
  PrivateViewing = "PrivateViewing",
}

export const EventTypeTranslationMapper = new Map<EventType, string>([
  [EventType.PublicEvent, "EVENTS.PUBLIC_EVENT"],
  [EventType.PrivateEvent, "EVENTS.PRIVATE_EVENT"],
  [EventType.PrivateViewing, "EVENTS.PRIVATE_VIEWING"],
]);
