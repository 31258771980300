export const UNITS_COLUMN_NAMES = {
  housingType: "HousingType",
  listingType: "ListingType",
  constructionYear: "ConstructionYear",
  floorPlanType: "FloorPlanType",
  stage: "Stage",
  plotNumber: "PlotNumber",
  administrationStartDate: "AdminstrationStartDate",
  administrationEndDate: "AdminstrationEndDate",
  vacancyDate: "VacancyDate",
  longitude: "Longitude",
  latitude: "Latitude",
  isUnderRenovation: "IsUnderRenovation",
  renovationStartDate: "RenovationStartDate",
  renovationEndDate: "RenovationEndDate",
  seoTitle: "SeoTitle",
  metaDescription: "MetaDescription",
  externalLink: "ExternalLink",
  energyLabel: "EnergyLabel",
  floor: "Floor",
  houseNumber: "HouseNumber",
  street: "Street",
  door: "Door",
  zipCode: "ZipCode",
  city: "City",
  country: "Country",
  paymentFrequency: "PaymentFrequency",
  salePrice: "SalePrice",
  rentPrice: "RentPrice",
  acontoHeat: "AcontoHeat",
  acontoWater: "AcontoWater",
  acontoElectricity: "AcontoElectricity",
  acontoResidentsSociety: "AcontoResidentsSociety",
  internetFee: "InternetFee",
  antennaFee: "AntennaFee",
  usageType: "UsageType",
  usageTypeVisible: "UsageTypeVisible",
  depositMonths: "DepositMonths",
  prepaidMonths: "PrepaidMonths",
  nonCancellationPeriodMonths: "NonCancellationPeriodMonths",
  rentalCondition: "RentalCondition",
  rentalPeriodMonths: "RentalPeriodMonths",
  noticePeriodDays: "NoticePeriodDays",
  interiorMaintenance: "InteriorMaintenance",
  exteriorMaintenance: "ExteriorMaintenance",
} as const;
