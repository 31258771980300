import { Routes } from "@angular/router";
import { ResidentialComponent } from "./residential/residential.component";
import { ParkingLotComponent } from "./parking-lot/parking-lot.component";
import { StorageRoomComponent } from "./storage-room/storage-room.component";
import { CommercialComponent } from "./commercial/commercial.component";

export const propertyUnitsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "residential",
  },
  {
    path: "residential",
    component: ResidentialComponent,
    data: {
      name: "UNIT_TYPES.RESIDENTIAL",
    },
  },
  {
    path: "parking-lot",
    component: ParkingLotComponent,
    data: {
      name: "UNIT_TYPES.PARKING_LOT",
    },
  },
  {
    path: "storage-room",
    component: StorageRoomComponent,
    data: {
      name: "UNIT_TYPES.STORAGE",
    },
  },
  {
    path: "commercial",
    component: CommercialComponent,
    data: {
      name: "UNIT_TYPES.COMMERCIAL",
    },
  },
];
