<div
  class="rounded-md flex ps-4 pe-5 justify-between items-center bg-black-100 border border-black-300 border-dashed gap-4 py-4 w-[27.75rem] h-[6.25rem]"
>
  <div class="flex gap-4 items-center">
    @if (displayImage) {
      <img
        [src]="displayImage"
        [alt]="'UPLOADED_DIVISION_LOGO' | translate"
        class="ms-4 w-[6.25rem] h-[4.25rem] rounded-md"
      />
    } @else {
      <div class="ms-4 w-[6.25rem] h-[4.25rem] bg-white rounded-md flex justify-center items-center">
        <img
          ngSrc="assets/images/icons/upload-image-placeholder.svg"
          priority
          width="27"
          height="39"
          [alt]="'UPLOADED_DIVISION_LOGO' | translate"
        />
      </div>
    }
    <div class="flex flex-col">
      <p class="text-xs w-32 truncate">{{ "FILE_NAME" | translate }}: {{ imageName().value }}</p>
      @if (fileSizeConvertToBytes === 0) {
        <p class="text-xs">{{ "FILE_SIZE" | translate }}:</p>
      } @else {
        <p class="text-xs">{{ "FILE_SIZE" | translate }}: {{ transferBytes(fileSizeConvertToBytes) }}</p>
      }
      <p class="text-xs">{{ "IMAGE_SIZE" | translate }}: {{ imageDimensions().value }}</p>
    </div>
  </div>
  <div class="relative overflow-hidden inline-block cursor-pointer">
    <button (click)="imageInput.click()" class="button--tertiary !px-6 !py-3 uppercase" type="button">
      {{ (image().value ? "CHANGE" : "UPLOAD") | translate }}
    </button>
    <input class="absolute invisible" #imageInput (change)="selectImage($event)" type="file" accept="image/*" />
  </div>
</div>
