import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const ORGANIZATION_DIVISION_HEADER: ITableHeader[] = [
  {
    label: "DIVISIONS.DIVISION",
    key: "name",
    isSortable: true,
    type: TableHeaderType.ImageWithHeaderAndSubHeader,
    maxTextLength: 70,
  },
  {
    label: "PROPERTIES.NAME",
    key: "properties",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "UNITS",
    key: "units",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "SETTINGS.USERS.ACTIVATED_USERS",
    key: "activeUsers",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "CREATED",
    key: "createdOn",
    isSortable: true,
    type: TableHeaderType.Date,
  },
  {
    label: "UNITS_OVERVIEW",
    key: "unitsOverview",
    isSortable: false,
    type: TableHeaderType.OverViewStatus,
  },
];
