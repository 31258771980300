import { Component } from "@angular/core";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";

@Component({
  selector: "est-system-configurations",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet, SubheaderComponent],
  templateUrl: "./system-configurations.component.html",
  styleUrl: "./system-configurations.component.scss",
})
export class SystemConfigurationsComponent {
  tabs: INavigationTab[] = [
    {
      label: "GENERAL",
      routerLink: "general",
    },
    {
      label: "LANGUAGE",
      routerLink: "language",
    },
    {
      label: "UNIT_STATUS.NAME",
      routerLink: "unit-status",
    },
    {
      label: "NOTES",
      routerLink: "notes",
    },
    {
      label: "SETTINGS.SYSTEM_CONFIGURATIONS.PLACEMENT_ID",
      routerLink: "placement-id",
    },
    {
      label: "SETTINGS.SYSTEM_CONFIGURATIONS.CUSTOMER_CATEGORY.NAME",
      routerLink: "customer-category",
    },
    {
      label: "SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_STATUS.NAME",
      routerLink: "follow-up-status",
    },
    {
      label: "SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_TASK.NAME",
      routerLink: "follow-up-task",
    },
  ];
}
