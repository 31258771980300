import { ICity } from "../models/city.model";
import { map, OperatorFunction } from "rxjs";
import { signal } from "@angular/core";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";

export const mapCitiesToSignalPaginatedDropdownOptions = (): OperatorFunction<
  ICity[],
  ISignalPaginatedResponse<IDropdownOption>
> => {
  return map((cities) => ({
    ...constructInitialSignalPaginatedResponse(),
    results: signal(
      cities.map((city) => ({
        label: `${city.name} - ${city.vat}&nbsp;<span class="text-black-500">(${city.country})</span>`,
        value: city.name,
      })),
    ),
  }));
};
