import { Component, OnInit, inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { generateDropdownOptionsFromObject } from "@root/shared/utilities/dropdown-options.utilities";
import { UNITS_COLUMN_NAMES } from "@root/views/main/toolbox/import-data/constants/units-fields-names.constants";
import {
  ImportTypeTranslationMapper,
  UnitTypeTranslationMapper,
} from "@root/views/main/toolbox/import-data/import-data.mappers";
import { ImportColumnsSelectFieldComponent } from "@root/views/main/toolbox/import-data/import-table/match-columns/import-columns-select-field/import-columns-select-field.component";
import { IMatchColumnsInputs } from "@root/views/main/toolbox/import-data/import-table/match-columns/match-columns-inputs.interface";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";

@Component({
  selector: "est-match-columns",
  standalone: true,
  imports: [
    TranslateModule,
    TooltipComponent,
    ImportColumnsSelectFieldComponent,
    ButtonComponent,
    DialogHeaderComponent,
  ],
  templateUrl: "./match-columns.component.html",
  styleUrl: "./match-columns.component.scss",
})
export class MatchColumnsComponent implements OnInit {
  readonly #matDialog = inject(MatDialog);
  componentInputs: IMatchColumnsInputs = inject(MAT_DIALOG_DATA);
  protected readonly UnitTypeTranslationMapper = UnitTypeTranslationMapper;
  protected readonly ImportTypeTranslationMapper = ImportTypeTranslationMapper;
  databaseFieldsOptions: IDropdownOption[] = [];
  disabledDatabaseFields: string[] = [];
  mappedUnitFieldControl = new FormControl<string | undefined>(undefined);

  ngOnInit(): void {
    this.#setDatabaseFieldsOptions();
    this.#setDisabledDatabaseFields();
    this.#initializeFormControl();
  }

  close(): void {
    this.#matDialog.closeAll();
  }

  #setDatabaseFieldsOptions(): void {
    this.databaseFieldsOptions = generateDropdownOptionsFromObject(UNITS_COLUMN_NAMES);
  }

  #setDisabledDatabaseFields(): void {
    const mappedDatabaseFields = new Set<string>(this.componentInputs.csvColumnToDtoFieldHashMap.values());
    for (const key in UNITS_COLUMN_NAMES) {
      const fieldName = UNITS_COLUMN_NAMES[key as keyof typeof UNITS_COLUMN_NAMES];
      if (mappedDatabaseFields.has(fieldName)) {
        this.disabledDatabaseFields.push(fieldName);
      }
    }
  }

  #initializeFormControl(): void {
    this.mappedUnitFieldControl.setValue(
      this.componentInputs.csvColumnToDtoFieldHashMap.get(this.componentInputs.csvColumnName),
    );
  }
}
