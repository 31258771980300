<form (change)="updateFormattedAddressSignal()" (ngSubmit)="submitFormIfValidAndAddressNotLoading()">
  <div class="flex border-b border-black-100 py-6 px-6 justify-between">
    <div class="flex gap-2 items-center sticky top-0">
      <p class="text-xl font-medium mb-1">
        {{ "PROPERTIES.CREATE_PROPERTY" | translate }}
      </p>
      <button
        [matTooltip]="'PROPERTIES.CREATE_PROPERTY' | translate"
        class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        type="button"
      >
        ?
      </button>
    </div>
    <button (click)="closeDialog()" type="button">
      <img
        [alt]="'CLOSE_ICON' | translate"
        height="44"
        ngSrc="assets/images/icons/close-icon.svg"
        priority
        width="44"
      />
    </button>
  </div>
  <mat-progress-bar [value]="getProgressbarValue()"></mat-progress-bar>
  <div class="relative">
    @if (isPropertyLoading) {
      <div class="absolute inset-0 bg-white/50 z-10"></div>
    }
    <div
      [ngClass]="{ '!overflow-y-hidden': isPropertyLoading }"
      class="h-[calc(100dvh-14rem)] overflow-y-auto pb-8 relative"
    >
      <mat-stepper #stepper animationDuration="0">
        <!-- First step -->
        <mat-step [stepControl]="propertyDetailsFormGroup">
          <div class="form-block">
            <p class="form-block__title">{{ "COMPANIES.COMPANY" | translate }}</p>
            <p class="form-block__description">{{ "PROPERTIES.FORM.SELECT_COMPANY_MSG" | translate }}</p>
            <est-company-select-field [control]="companyIdControl" />
          </div>
          <mat-divider></mat-divider>
          <div class="form-block">
            <p class="form-block__title">{{ "PROPERTIES.FORM.PROPERTY_DETAILS" | translate }}</p>
            <p class="form-block__description">{{ "PROPERTIES.FORM.PROPERTY_DETAILS_MSG" | translate }}</p>
            <div class="w-[38.5rem] max-lg:w-[32rem]">
              <est-input
                [control]="propertyNameControl"
                [label]="'PROPERTIES.FORM.PROPERTY_NAME'"
                appliedClass="w-full"
              ></est-input>
              <div class="flex gap-4 mt-4">
                <div class="flex-1">
                  <est-input
                    [control]="propertyNumberControl"
                    [label]="'PROPERTIES.FORM.PROPERTY_NUMBER'"
                    appliedClass="w-full"
                    type="number"
                  ></est-input>
                </div>
                <div class="flex-1">
                  <est-input
                    [control]="cadastralNumberControl"
                    [label]="'PROPERTIES.FORM.CADASTRAL_NUMBER'"
                    appliedClass="w-full"
                    type="number"
                  ></est-input>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="form-block">
            <p class="form-block__title">{{ "PROPERTIES.FORM.PROPERTY_ADDRESS" | translate }}</p>
            <p class="form-block__description">{{ "PROPERTIES.FORM.PROPERTY_ADDRESS_MSG" | translate }}</p>
            <est-property-address
              [propertyAddressControls]="form.controls.propertyAddress"
              [addressLookups]="addressLookupsOptions"
              [constructSecondaryAddress]="addSecondaryAddress"
              [formattedPrimaryAddress]="formattedPrimaryAddressSignal"
              [formattedSecondaryAddress]="formattedSecondaryAddressSignal"
            ></est-property-address>
          </div>
        </mat-step>
        <!-- Second step -->
        <mat-step [stepControl]="teamIdsControl">
          <div class="form-block !pb-0">
            <p class="form-block__title">
              {{ "PROPERTIES.FORM.ASSIGN_PROPERTIES_TITLE" | translate }}
            </p>
            <p class="form-block__description max-w-[47rem] !mb-6">
              {{ "PROPERTIES.FORM.ASSIGN_PROPERTIES_DESCRIPTION" | translate }}
            </p>
            <est-list-select-teams
              [control]="teamIdsControl"
              [divisionIds]="[authService.currentUserDivisionRole!.divisionId]"
              [stepIndex]="matStepper.selectedIndex"
              [controlMapper]="teamIdsControlMapper"
            ></est-list-select-teams>
          </div>
        </mat-step>
        <!-- Third step -->
        <mat-step>
          <est-creation-success-message
            [createdBy]="authService.getFullName()!"
            [name]="propertyNameControl.value"
            description="PROPERTIES.FORM.PROPERTY_CREATED_MSG"
          ></est-creation-success-message>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
  <div
    [ngClass]="{ '!justify-end': isPropertyCreated }"
    class="py-3 bg-black-50 flex border border-black-100 px-6 justify-between"
  >
    @if (stepper.selectedIndex === 0) {
      <est-button
        label="CANCEL"
        (click)="closeDialog()"
        buttonType="button"
        buttonStyleType="button--tertiary"
        appliedClasses="form-button"
      ></est-button>
    } @else if (!isPropertyCreated) {
      <est-button
        [disabled]="isPropertyLoading"
        label="BACK"
        (click)="stepper.previous()"
        buttonType="button"
        buttonStyleType="button--tertiary"
        appliedClasses="form-button"
      ></est-button>
    }
    @if (stepper.selectedIndex === totalTabsNo - 2) {
      <est-button
        [isLoading]="isPropertyLoading"
        buttonStyleType="button--primary"
        buttonType="submit"
        label="CREATE"
        appliedClasses="form-button"
      />
    } @else if (!isPropertyCreated) {
      <est-button
        label="NEXT"
        [disabled]="isDataBeingFetchedOrLoading()"
        buttonType="submit"
        buttonStyleType="button--primary"
        appliedClasses="form-button"
      ></est-button>
    } @else {
      <div class="flex gap-4">
        <est-button
          imageSrc="assets/images/icons/plus-black.svg"
          imageAlt="ADD"
          label="PROPERTIES.CREATE_UNITS"
          buttonType="button"
          buttonStyleType="button--tertiary"
          appliedClasses="form-button"
        ></est-button>
        <est-button
          label="PROPERTIES.GO_TO_PROPERTY"
          (click)="navigateToProperty()"
          buttonType="button"
          buttonStyleType="button--primary"
          appliedClasses="form-button"
        ></est-button>
      </div>
    }
  </div>
</form>
