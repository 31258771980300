<div class="flex justify-end mb-6">
  <est-search-input [searchSignal]="searchSignal"></est-search-input>
</div>

@if (
  dataResponse && dataResponse.division && !dataResponse.propertiesCount && !!dataResponse.division.companies.length
) {
  <div class="flex items-center justify-center py-8">
    <p [attr.aria-label]="'PROPERTIES.NO_RESULT' | translate" class="text-xs font-light text-black-400">
      {{ "PROPERTIES.NO_RESULT" | translate }}
    </p>
  </div>
} @else if (isLoading) {
  <est-spinner></est-spinner>
} @else {
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      matTreeNodePaddingIndent="0"
      class="flex justify-between !min-h-0 !ml-10"
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      matTreeNodePadding
    >
      @if (
        node.item !== DIVISION_HIERARCHY_RESULT.NO_PROPERTIES && node.item !== DIVISION_HIERARCHY_RESULT.NO_COMPANIES
      ) {
        <mat-checkbox
          class="font-light text-sm"
          [checked]="checklistSelection.isSelected(node)"
          (change)="lastChildSelectionToggle(node)"
          >{{ node.item }}

          @if (getPropertyDetails(node, dataResponse); as details) {
            <span> ({{ details.listingType | translate }} ,{{ details.unitsNumber }} {{ "UNITS" | translate }})</span>
          }
        </mat-checkbox>
      } @else if (node.item === DIVISION_HIERARCHY_RESULT.NO_PROPERTIES) {
        <p class="text-xs font-light text-black-400 my-1 ml-3">{{ "PROPERTIES.NO_PROPERTIES" | translate }}</p>
      } @else if (node.item === DIVISION_HIERARCHY_RESULT.NO_COMPANIES) {
        <p class="text-xs font-light text-black-400 my-1 ml-3">{{ "COMPANIES.NO_COMPANIES" | translate }}</p>
      }
      @if (node.index === 0) {
        <p class="text-[0.625rem] font-light text-black-400">{{ "PROPERTIES.PROPERTY" | translate }}</p>
      }
    </mat-tree-node>
    <mat-tree-node
      [ngClass]="'ml-' + node.level * 6"
      matTreeNodePaddingIndent="0"
      class="border-y border-black-100 flex justify-between !min-h-0"
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
    >
      <div class="flex items-center">
        <mat-checkbox
          [ngClass]="{ 'text-lg font-medium': node.level === 0 }"
          [checked]="isDescendantsAllSelected(node)"
          [indeterminate]="isDescendantsPartiallySelected(node)"
          (change)="itemSelectionToggle(node)"
          [disabled]="disableNodeCheckbox(node)"
        >
          <span [ngClass]="{ '!text-black': disableNodeCheckbox(node) }">
            {{ node.item }} ( {{ getDirectSelectedChildrenCount(node) }}/{{
              disableNodeCheckbox(node) ? 0 : getAllChildrenCount(node)
            }}
            )</span
          >
        </mat-checkbox>

        <button class="flex" matTreeNodeToggle [attr.aria-label]="'toggle ' + node.item">
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
      </div>

      @if (node.level === 0) {
        <p class="text-[0.625rem] font-light text-black-400">{{ "DIVISIONS.DIVISION" | translate }}</p>
      } @else {
        <p class="text-[0.625rem] font-light text-black-400">{{ "COMPANIES.COMPANY" | translate }}</p>
      }
    </mat-tree-node>
  </mat-tree>
}
