import { inject, Injectable } from "@angular/core";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { CompaniesRepository } from "../repositories/companies.repository";
import { mapCompaniesToSignalLargeListInputOperator } from "../utilities/companies.utilities";
import { ICreateCompanyPayload } from "@root/data/market/companies/models/create-company-payload.model";
import { mapPropertiesToSignalLargeListInputOperator } from "@root/data/market/properties/utilities/properties.utilities";
import { ISignalPaginatedPropertyResponse } from "@root/shared/interfaces/signal-paginated-property-response.interface";
import { PropertyType } from "@root/data/market/properties/enums/property-type.enum";
import { ICompanyDetails } from "@root/data/market/companies/models/company-details.model";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  readonly #companiesRepository = inject(CompaniesRepository);

  getCompanies(
    params: IPaginationSortPayload & {
      city?: string;
      divisionIds?: number[];
    },
  ): Observable<ISignalPaginatedResponse<ILargeListTableInput>> {
    return this.#companiesRepository.getPaginatedCompanies(params).pipe(mapCompaniesToSignalLargeListInputOperator());
  }

  createNewCompany(companyData: ICreateCompanyPayload): Observable<number> {
    return this.#companiesRepository.createNewCompany(companyData);
  }

  updateCompany(companyData: ICreateCompanyPayload, companyId: number): Observable<void> {
    return this.#companiesRepository.updateCompanyDetails(companyData, companyId);
  }

  getCompanyDetails(id: number): Observable<ICompanyDetails> {
    return this.#companiesRepository.getCompanyDetails(id);
  }

  patchCompanyDetails(id: number, body: Partial<ICompanyDetails>): Observable<void> {
    return this.#companiesRepository.patchCompanyDetails(id, body);
  }

  getProperties(
    params: IPaginationSortPayload & {
      isFavourite?: boolean;
      propertyType?: PropertyType;
      companyIds: number[];
    },
  ): Observable<ISignalPaginatedPropertyResponse<ILargeListTableInput>> {
    return this.#companiesRepository.getProperties(params).pipe(mapPropertiesToSignalLargeListInputOperator());
  }

  deleteCompany(companyId: number): Observable<void> {
    return this.#companiesRepository.deleteCompany(companyId);
  }
}
