import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { DateTime } from "luxon";
import { isISODateFormat } from "@root/shared/utilities/date.utilities";

export const TimeZoneInterceptor: HttpInterceptorFn = (req, next): Observable<any> => {
  const authService = inject(AuthService);
  if (!req.body || typeof req.body !== "object") {
    return next(req);
  }

  const timezone = authService.getOrganisationTimeZone();
  if (!timezone) {
    return next(req);
  }
  const offset = parseUtcOffset(timezone.utcOffset);
  const modifiedBody = addUTCOffsetToDates(req.body, offset);
  const modifiedReq = req.clone({ body: modifiedBody });
  return next(modifiedReq);
};
function parseUtcOffset(utcOffset: string): number {
  const [hours, minutes, seconds] = utcOffset.split(":").map(Number);
  return hours + minutes / 60 + seconds / 3600;
}

function addUTCOffsetToDates(obj: any, offset: number): any {
  if (!obj || typeof obj !== "object") {
    return obj;
  }
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (isDateString(value)) {
      const date = DateTime.fromISO(value).toUTC(0, { keepLocalTime: true });
      const adjustedDate = date.minus({ hours: offset });
      obj[key] = adjustedDate.toISO();
    } else if (typeof value === "object" && value !== null) {
      addUTCOffsetToDates(value, offset);
    }
  }

  return obj;
}

function isDateString(value: any): boolean {
  if (typeof value !== "string") return false;
  const isDateValidBool = isISODateFormat(value);
  return isDateValidBool;
}
