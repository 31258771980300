import { Component } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { TranslateModule } from "@ngx-translate/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-medias",
  standalone: true,
  imports: [
    ButtonComponent,
    PaginationComponent,
    SearchInputComponent,
    SelectFieldComponent,
    SmallHeaderComponent,
    SmallListTableComponent,
    TranslateModule,
    NavigationTabsComponent,
    RouterOutlet,
    SubheaderComponent,
  ],
  templateUrl: "./medias.component.html",
  styleUrl: "./medias.component.scss",
})
export class MediasComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "PROPERTY_MEDIAS.NAME",
      routerLink: "property-medias",
    },
    {
      label: "PACKAGES.MEDIA_PACKAGES.NAME",
      routerLink: "media-package",
    },
  ];
}
