import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";

export const LINKED_PROPERTIES_TABLE_HEADERS: ITableHeader[] = [
  {
    label: "",
    key: "propertiesIds",
    isSortable: false,
    type: TableHeaderType.Checkbox,
  },
  {
    label: "LINKED_PROPERTIES.TABLE.PROPERTY",
    key: "name",
    isSortable: true,
    type: TableHeaderType.Text,
    needsTranslation: true,
    maxTextLength: 70,
  },
  {
    label: "LINKED_PROPERTIES.TABLE.DIVISION",
    key: "division",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "LINKED_PROPERTIES.TABLE.COMPANY",
    key: "company",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "LINKED_PROPERTIES.TABLE.CITY",
    key: "city",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "TYPE",
    key: "propertyType",
    isSortable: true,
    type: TableHeaderType.Text,
    needsTranslation: true,
  },
  {
    label: "LINKED_PROPERTIES.TABLE.UNITS",
    key: "units",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    label: "OVERALL_STATUS",
    isSortable: true,
    key: "overallStatus",
    type: TableHeaderType.OverViewStatus,
  },
];
