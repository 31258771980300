import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-customer-page",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet, SubheaderComponent],
  templateUrl: "./customer-page.component.html",
  styleUrl: "./customer-page.component.scss",
})
export class CustomerPageComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "SYSTEM_MODULES.CUSTOMER_PAGE.STYLING",
      routerLink: "styling",
    },
    {
      label: "SYSTEM_MODULES.CUSTOMER_PAGE.TEXT",
      routerLink: "text",
    },
    {
      label: "SYSTEM_MODULES.CUSTOMER_PAGE.COMMUNICATION",
      routerLink: "communication",
    },
    {
      label: "SYSTEM_MODULES.CUSTOMER_PAGE.OPTIONS",
      routerLink: "options",
    },
  ];
}
