import { Component, inject, input, OnInit, signal, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import { getControlInFormGroup, markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { PropertyTextsFormControls } from "@root/shared/abstracts/base-property-texts/property-texts.form-controls";
import { PropertyTextsService } from "@root/data/market/properties/services/properties-texts.service";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { finalize } from "rxjs";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";

@Component({
  template: "",
})
export abstract class BasePropertyTextsComponent implements OnInit {
  @ViewChild("stepper", { static: true }) stepper!: MatStepper;
  readonly propertyId = input.required<number>();
  stepperIndex = 0;
  buttonLabels = ["SETTINGS.NAME", "PROPERTY_TEXTS.ADD_TEXT_FIELD"];
  readonly createPropertyTextsFormControls = inject(PropertyTextsFormControls);
  readonly propertyTextsService = inject(PropertyTextsService);
  readonly systemConfigurationService = inject(SystemConfigurationsService);
  isSubmittingForm = false;
  isPageLoading = false;
  parentRoute = "";
  abstract isEdit: boolean;
  protected readonly router = inject(Router);
  protected readonly route = inject(ActivatedRoute);
  protected readonly snackBarService = inject(SnackbarService);
  protected readonly getControlInFormGroup = getControlInFormGroup;
  editTextDetailsSignal = signal(false);

  extractParentRoute() {
    const route = this.router.url.split("/");
    this.parentRoute = route[5];
  }

  languages: ILanguage[] = [];

  ngOnInit() {
    this.extractParentRoute();
    this.getSystemLanguageConfigurations();
  }

  get formControls() {
    return this.createPropertyTextsFormControls.formGroup.controls;
  }

  constructTextFieldsFormArray() {
    this.languages.forEach((allowedLanguage) => {
      const textFieldFormGroup = this.createPropertyTextsFormControls.constructTextFieldsFormGroup(
        allowedLanguage.languageId!,
      );
      this.createPropertyTextsFormControls.textFieldsFormArray.push(textFieldFormGroup);
    });
  }

  getLanguageFromId(languageId: string): ILanguage {
    return this.languages.find((allowedLanguage) => {
      return allowedLanguage.languageId === languageId;
    })!;
  }

  abstract submitForm(): void;

  goToNextPage() {
    this.stepper.selected!.stepControl.updateValueAndValidity();
    markAllControlsAsTouchedAndDirty(this.stepper.selected!.stepControl as FormGroup);
    if (this.stepper.selected?.stepControl.valid) {
      if (this.stepperIndex < this.stepper.steps.length - 1) {
        this.stepperIndex++;
      }
    }
  }

  getSystemLanguageConfigurations() {
    this.systemConfigurationService
      .getLanguageSystemConfiguration()
      .pipe(
        finalize(() => {
          this.editTextDetailsSignal.set(true);
        }),
      )
      .subscribe((response) => {
        this.languages = response.allowedLanguages.map((allowedLanguage) => allowedLanguage.language);
        const defaultEndUserLanguage = { ...response.defaultEndUserLanguage, isDefault: true };
        this.languages.unshift(defaultEndUserLanguage);
        this.constructTextFieldsFormArray();
      });
  }

  goToPreviousPage() {
    this.stepperIndex--;
  }
}
