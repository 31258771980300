<div class="overflow-auto max-h-[95dvh]">
  <div class="p-6 relative">
    <div class="pb-6">
      <h3 class="font-medium text-xl">{{ "DIVISIONS.DELETE_DIVISION.TITLE" | translate }}</h3>
    </div>

    <hr class="border-black-100 absolute start-0 end-0" />

    <div class="mt-6 mb-8 text-sm font-light">
      <p class="mb-6">{{ "DIVISIONS.DELETE_DIVISION.BODY.QUESTION" | translate }}</p>
      <h5 class="font-bold">{{ "DIVISIONS.DELETE_DIVISION.BODY.WARNING.TITLE" | translate }}</h5>
      <p class="mb-6">{{ "DIVISIONS.DELETE_DIVISION.BODY.WARNING.PARAGRAPH" | translate }}</p>
      <p class="mb-6">{{ "DIVISIONS.DELETE_DIVISION.BODY.NOTE_PARAGRAPH" | translate }}</p>
    </div>

    <est-datepicker
      label="DIVISIONS.DELETE_DIVISION.BODY.DATE_PLACEHOLDER"
      [control]="getControlInFormGroup(deleteDivisionFormGroup, 'deletionDate')"
      appliedClasses="w-[18.75rem]"
    >
    </est-datepicker>
  </div>

  <div class="py-3 bg-black-50 flex border border-black-100 px-6 justify-between z-10">
    <est-button
      appliedClasses="!px-6"
      (click)="matDialog.closeAll()"
      buttonType="button"
      buttonStyleType="button--tertiary"
      label="CANCEL"
    ></est-button>
    <est-button
      appliedClasses="!px-6"
      [disabled]="!deleteDivisionFormGroup.valid"
      [isLoading]="isDeleteDivisionLoading()"
      (click)="deleteDivision()"
      buttonType="button"
      buttonStyleType="button--primary"
      label="CONFIRM"
    ></est-button>
  </div>
</div>
