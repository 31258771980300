import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { RouterOutlet } from "@angular/router";
import { PropertyMainDetailsComponent } from "./property-main-details/property-main-details.component";

@Component({
  selector: "est-property-details",
  standalone: true,
  templateUrl: "./property-details.component.html",
  styleUrl: "./property-details.component.scss",
  imports: [PropertyMainDetailsComponent, NavigationTabsComponent, RouterOutlet],
})
export class PropertyDetailsComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "PROPERTIES.DETAILS.MAIN_DETAILS.NAME",
      routerLink: "main-details",
    },
    {
      label: "PROPERTIES.DETAILS.TERMS_AND_CONDITIONS",
      routerLink: "terms-and-conditions",
    },
    {
      label: "PROPERTIES.DETAILS.PROPERTY_LAYOUT",
      routerLink: "property-layout",
    },
  ];
}
