import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { UnderDevelopmentComponent } from "../../../../../../shared/under-development/under-development.component";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "est-communication",
  standalone: true,
  imports: [TranslateModule, UnderDevelopmentComponent, SubheaderComponent, NavigationTabsComponent, RouterOutlet],
  templateUrl: "./communication.component.html",
  styleUrl: "./communication.component.scss",
})
export class CommunicationComponent {
  protected communicationTabs: INavigationTab[] = [
    {
      label: "SETTINGS.COMMUNICATION.TEMPLATES.NAME",
      routerLink: "templates",
    },
    {
      label: "SETTINGS.COMMUNICATION.CONFIGURATION.NAME",
      routerLink: "configuration",
    },
    {
      label: "SETTINGS.COMMUNICATION.OPTIONS.NAME",
      routerLink: "options",
    },
  ];
}
