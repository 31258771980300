import { Component, input, OnInit } from "@angular/core";
import { InputComponent } from "@root/shared/input/input.component";
import { FormGroup } from "@angular/forms";
import { LanguageDisplayComponent } from "@root/shared/language-display/language-display.component";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { castControlFromAbstractToFormControl } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-external-title-input",
  standalone: true,
  imports: [InputComponent, LanguageDisplayComponent, MatSlideToggle, TranslateModule, SmallHeaderComponent],
  templateUrl: "./external-title-input.component.html",
  styleUrl: "./external-title-input.component.scss",
})
export class ExternalTitleInputComponent implements OnInit {
  formGroup = input.required<FormGroup>();
  language = input.required<ILanguage>();
  isDefault = input<boolean>(false);
  label = input<string>("EXTERNAL_TITLE");
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;

  ngOnInit() {
    if (this.isDefault() && this.formGroup().controls["enabled"] !== undefined) {
      this.formGroup().controls["enabled"].patchValue(true);
    }

    if (this.formGroup().controls["enabled"] !== undefined && !this.formGroup().controls["enabled"].value) {
      this.formGroup().disable();
    }

    this.formGroup().controls["languageId"].patchValue(this.language().languageId);
  }

  toggleEnabled() {
    this.formGroup().controls["enabled"].patchValue(!this.formGroup().controls["enabled"].value);
    if (this.formGroup().controls["enabled"].value) {
      this.formGroup().enable();
    } else {
      this.formGroup().disable();
    }
  }
}
