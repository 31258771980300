import { Component, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterOutlet } from "@angular/router";
import { MatChipListbox, MatChipOption } from "@angular/material/chips";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { LayoutComponent } from "./core/layout/layout.component";
import { GlobalLoaderComponent } from "./shared/global-loader/global-loader.component";
import { GlobalLoaderService } from "./shared/ui-services/global-loader.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { NotificationHub } from "./core/services/signalR/hubs/notificationHub";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";

@Component({
  selector: "est-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatChipListbox,
    MatChipOption,
    MatSlideToggle,
    LayoutComponent,
    GlobalLoaderComponent,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  globalLoaderService = inject(GlobalLoaderService);
  notificationHub = inject(NotificationHub);
  #snackbarService = inject(SnackbarService);

  readonly #messageListener = this.notificationHub.notificationMessage.subject
    .pipe(takeUntilDestroyed())
    .subscribe((message) => {
      this.#snackbarService.open(SnackbarType.Success, message.message);
    });
}
