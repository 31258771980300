<est-select-search-field
  [control]="control()"
  [disabledOptions]="disabledOptions()"
  [dropdownLabel]="'DATABASE_FIELD'"
  [dropdownOptions]="renderedDropdownOptions"
  [searchDebounceTime]="0"
  [searchSignal]="searchSignal"
  [selectSignal]="selectSignal"
  appliedClass="!w-full"
></est-select-search-field>
