import { Component, DestroyRef, inject, input, OnInit, output } from "@angular/core";
import { SortablejsModule } from "nxt-sortablejs";
import { MediaCardComponent } from "@root/shared/media-card/media-card.component";
import { NgFor } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { Options } from "sortablejs";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { PropertyMediaType } from "@root/data/market/properties/enums/property-media-type.enum";
import { MatDialog } from "@angular/material/dialog";
import { MediaDetailsComponent } from "@root/views/main/property/property-marketing/components/media-details/media-details.component";
import { IMediaDetailsInputs } from "@root/views/main/property/property-marketing/components/media-details/media-details-inputs.interface";
import { getFileSize, getImageFileDimensions, getVideoFileDimensions } from "@root/shared/utilities/files.utilities";
import { FileType } from "@root/shared/enums/file-type.enum";
import { getFileType } from "@root/shared/utilities/media.utilities";
import { IMediaFormGroup } from "@root/views/main/property/property-marketing/components/media-form-group.interface";
import { IUploadedPropertyMediaPackageDetails } from "@root/data/market/properties/models/uploaded-property-media-package-details.model";

@Component({
  selector: "est-media-sortable-grid",
  standalone: true,
  imports: [SortablejsModule, MediaCardComponent, NgFor],
  templateUrl: "./media-sortable-grid.component.html",
  styleUrl: "./media-sortable-grid.component.scss",
})
export class MediaSortableGridComponent implements OnInit {
  readonly authService = inject(AuthService);
  fileUploadControl = input.required<FormControl>();
  formArray = input.required<FormArray<FormGroup<IMediaFormGroup>>>();
  medias = input<IUploadedPropertyMediaPackageDetails[]>([]);
  changeControl = output<{ id: number; control: any }>();

  options: Options = {
    swapThreshold: 2,
    animation: 150,
    handle: ".handle",
    bubbleScroll: true,
    dragClass: "!opacity-70",
    forceFallback: true,
    onEnd: () => {
      this.formArray().updateValueAndValidity();
    },
  };
  readonly #destroyRef = inject(DestroyRef);
  readonly #matDialog = inject(MatDialog);
  readonly #fb = inject(NonNullableFormBuilder);

  ngOnInit() {
    this.#subscribeToAddFile();
  }

  getFileName(index: number): string {
    const file = this.formArray().at(index).controls.file.value;
    if (file) return file.name;
    else return this.medias()[index]?.fileName || "";
  }

  async constructFormGroup(file: File): Promise<FormGroup<IMediaFormGroup>> {
    const fileType = getFileType(file.name);
    const fileTypeControl = this.#fb.control<FileType>(fileType);
    const dimensions =
      getFileType(file!.name) === FileType.Video
        ? await getVideoFileDimensions(file!)
        : await getImageFileDimensions(file!);
    return this.#fb.group({
      id: this.#fb.control<number | undefined>(undefined),
      file: this.#fb.control<File | undefined>(file),
      mediaType: this.#fb.control<PropertyMediaType | undefined>(undefined, Validators.required),
      fileType: fileTypeControl,
      alternateText: this.#fb.control<string | undefined>(undefined),
      title: this.#fb.control<string | undefined>(undefined),
      mediaText: this.#fb.control<string | undefined>(undefined),
      description: this.#fb.control<string | undefined>(undefined),
      filePath: this.#fb.control<string | undefined>(undefined),
      width: this.#fb.control<number | undefined>(dimensions.width),
      height: this.#fb.control<number | undefined>(dimensions.height),
    });
  }

  async addFile(file: File) {
    this.formArray().push(await this.constructFormGroup(file));
  }

  deleteMedia(index: number) {
    this.formArray().removeAt(index);
  }

  async openMediaDetails(index: number) {
    this.#matDialog
      .open<MediaDetailsComponent, IMediaDetailsInputs>(MediaDetailsComponent, {
        data: await this.#constructMediaDetailsInputs(index),
        maxHeight: "95dvh",
      })
      .afterClosed()
      .subscribe((change: { id: number; control: any }) => {
        change && this.changeControl.emit(change);
      });
  }

  async #constructMediaDetailsInputs(index: number): Promise<IMediaDetailsInputs> {
    const file = this.formArray().at(index).controls.file.value;
    const mediaFormGroup = this.formArray().at(index);
    if (file) {
      return await this.#constructMediaDetailsFromFile(file, mediaFormGroup);
    } else {
      const media = this.medias()[index];
      return this.#constructMediaDetailsFromMedia(media, mediaFormGroup);
    }
  }

  async #constructMediaDetailsFromFile(
    file: File,
    mediaFormGroup: FormGroup<IMediaFormGroup>,
  ): Promise<IMediaDetailsInputs> {
    return {
      mediaFormGroup,
      fileName: file!.name,
      fileSizeAndUnit: getFileSize(file!),
      fileType: getFileType(file!.name),
      uploadedAt: new Date().toISOString(),
      uploadedBy: this.authService.getFullName()!,
    };
  }

  #constructMediaDetailsFromMedia(
    media: IUploadedPropertyMediaPackageDetails,
    mediaFormGroup: FormGroup<IMediaFormGroup>,
  ): IMediaDetailsInputs {
    return {
      mediaFormGroup,
      fileName: media.fileName,
      fileSizeAndUnit: `${media.fileSize} ${media.fileSizeUnit}`,
      fileType: media.fileType,
      uploadedAt: media.createdOn ?? media.createdAt,
      uploadedBy: media.createdBy,
      filePath: media.fileUrl ?? media.filePath,
    };
  }

  #subscribeToAddFile() {
    this.fileUploadControl()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((files: FileList) => {
        // Reset file upload control on successful addition to formArray
        this.fileUploadControl().setValue([], {
          emitEvent: false,
        });
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          this.addFile(file);
        }
      });
  }
}
