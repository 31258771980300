import { inject, Injectable } from "@angular/core";
import { RoomRepository } from "@root/data/market/rooms/repositories/room.repository";
import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoomService<T> {
  readonly #roomRepository = inject(RoomRepository);

  create(unitLayoutId: string, roomType: RoomType): Observable<number> {
    return this.#roomRepository.create(unitLayoutId, roomType).pipe(map((obj) => obj.id));
  }

  delete(id: number, roomType: RoomType): Observable<void> {
    return this.#roomRepository.delete(id, roomType);
  }

  getById(id: number, roomType: RoomType): Observable<T> {
    return this.#roomRepository.getById(id, roomType);
  }

  update(id: number, body: Partial<T>, roomType: RoomType): Observable<void> {
    return this.#roomRepository.update(id, body, roomType);
  }
}
