import { Routes } from "@angular/router";
import { PropertyMarketingComponent } from "@root/views/main/property/property-marketing/property-marketing.component";
import { PropertyDetailsComponent } from "@root/views/main/property/property-details/property-details.component";
import { PropertyDocumentsComponent } from "@root/views/main/property/property-documents/property-documents.component";
import { PropertyEventsComponent } from "@root/views/main/property/property-events/property-events.component";
import { PropertyOverviewComponent } from "@root/views/main/property/property-overview/property-overview.component";
import { PropertySettingComponent } from "@root/views/main/property/property-setting/property-setting.component";
import { PropertyUnitsComponent } from "@root/views/main/property/property-units/property-units.component";
import { propertyMarketingRoutes } from "@root/views/main/property/property-marketing/property-marketing.routes";
import { propertyDetailsRoutes } from "./property-details/property-details.routes";
import { propertyUnitsRoutes } from "./property-units/property-units.routes";
import { propertyDocumentsRoutes } from "@root/views/main/property/property-documents/property-documents.routes";
import { PropertyEventsRoutes } from "@root/views/main/property/property-events/property-events.routes";

export const propertyRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "details",
  },
  {
    path: "marketing",
    component: PropertyMarketingComponent,
    data: {
      name: "PROPERTY.MARKETING.NAME",
    },
    children: propertyMarketingRoutes,
  },
  {
    path: "details",
    component: PropertyDetailsComponent,
    data: {
      name: "PROPERTY.DETAILS",
    },
    children: propertyDetailsRoutes,
  },
  {
    path: "documents",
    component: PropertyDocumentsComponent,
    data: {
      name: "PROPERTY.DOCUMENTS",
    },
    children: propertyDocumentsRoutes,
  },
  {
    path: "events",
    component: PropertyEventsComponent,
    data: {
      name: "PROPERTY.EVENTS",
    },
    children: PropertyEventsRoutes,
  },
  {
    path: "overview",
    component: PropertyOverviewComponent,
    data: {
      name: "OVERVIEW",
    },
  },
  {
    path: "setting",
    component: PropertySettingComponent,
    data: {
      name: "PROPERTY.SETTINGS",
    },
  },
  {
    path: "units",
    component: PropertyUnitsComponent,
    data: {
      name: "PROPERTY.UNITS",
    },
    children: propertyUnitsRoutes,
  },
];
