import { Component, inject } from "@angular/core";
import { BaseCompositeSelectSearchFieldComponent } from "@root/shared/abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";

@Component({
  selector: "est-property-select-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./property-select-field.component.html",
  styleUrl: "./property-select-field.component.scss",
})
export class PropertySelectFieldComponent extends BaseCompositeSelectSearchFieldComponent {
  readonly #propertiesService = inject(PropertiesService);

  getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#propertiesService.getPropertiesOptions({
      pageNumber,
      pageSize: 10,
      search: this.searchSignal(),
      ...(this.control().value && { selectedId: this.control().value }),
    });
  }
}
