import { Component, inject, OnInit } from "@angular/core";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { UnitsService } from "@root/data/market/units/services/units.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { getControlInFormGroup, getControlValueInFormGroup } from "@root/shared/utilities/form.utilities";
import { ButtonComponent } from "@root/shared/button/button.component";
import { IPetPolicyComponentInputs } from "@root/shared/pet-policy-modal/ipetpolicy-component-inputs";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { finalize } from "rxjs";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { PetsService } from "@root/data/market/pets/services/pets.service";
import { IPetTypePolicy } from "@root/data/market/pets/models/pet-type-policy.model";

@Component({
  selector: "est-pet-policy-modal",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    TranslateModule,
    PostfixTextInputComponent,
    MatSlideToggle,
    ReactiveFormsModule,
    ButtonComponent,
    FormsModule,
    TooltipComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./pet-policy-modal.component.html",
  styleUrl: "./pet-policy-modal.component.scss",
})
export class PetPolicyModalComponent implements OnInit {
  componentInputs: IPetPolicyComponentInputs = inject(MAT_DIALOG_DATA);
  isUpdatingData = false;
  isDataLoading = false;
  protected dialogRef: MatDialogRef<PetPolicyModalComponent> = inject(MatDialogRef);
  protected readonly getControlValueInFormGroup = getControlValueInFormGroup;
  protected readonly getControlInFormGroup = getControlInFormGroup;
  readonly #unitsService = inject(UnitsService);
  readonly #petsService = inject(PetsService);
  readonly #fb = inject(NonNullableFormBuilder);
  petPolicyFormArray = this.#fb.array<FormGroup>([]);

  ngOnInit() {
    this.#getPetPolicies();
  }

  submitForm() {
    const petPolicies = this.petPolicyFormArray.controls.map((control) => control.value);
    this.isUpdatingData = true;
    this.#unitsService
      .updateUnitPetTypePolicies(this.componentInputs.unitId, petPolicies)
      .pipe(finalize(() => (this.isUpdatingData = false)))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  #getPetPolicies() {
    this.isDataLoading = true;
    this.#petsService
      .getPetPolicies(this.componentInputs.unitId)
      .pipe(finalize(() => (this.isDataLoading = false)))
      .subscribe((petPolicies) => {
        petPolicies.forEach((petPolicy) => {
          this.petPolicyFormArray.push(this.#constructPetPolicyFormGroup(petPolicy));
        });
        this.#checkIfChangesAllowed();
      });
  }

  #constructPetPolicyFormGroup(petPolicy: IPetTypePolicy) {
    return this.#fb.group({
      id: this.#fb.control(petPolicy.id),
      animalType: this.#fb.control(petPolicy.animalType),
      animalSize: this.#fb.control(petPolicy.animalSize, Validators.required),
      isSizeRestricted: this.#fb.control(petPolicy.isSizeRestricted),
      isSpeciesRestricted: this.#fb.control(petPolicy.isSpeciesRestricted),
      isApplicationRequired: this.#fb.control(petPolicy.isApplicationRequired),
    });
  }

  #checkIfChangesAllowed() {
    if (!this.componentInputs.changesAllowed) {
      this.petPolicyFormArray.disable();
    }
  }
}
