import { inject, Injectable } from "@angular/core";
import { GeneralSystemSettingsRepository } from "@root/data/market/generalSystemSettings/repositories/generalSystemSettings.repository";
import { IGeneralSystemSettings } from "@root/data/market/generalSystemSettings/models/general-system-settings.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GeneralSystemSettingsService {
  readonly #generalSystemSettingsRepository = inject(GeneralSystemSettingsRepository);

  getGeneralSystemDefaultSettings() {
    return this.#generalSystemSettingsRepository.getGeneralSystemDefaultSettings();
  }

  patchSystemDefaultSettingsConfiguration(body: Partial<IGeneralSystemSettings>): Observable<void> {
    return this.#generalSystemSettingsRepository.patchSystemDefaultSettingsConfiguration(body);
  }
}
