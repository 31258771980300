import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-property-marketing",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./property-marketing.component.html",
  styleUrl: "./property-marketing.component.scss",
})
export class PropertyMarketingComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "PROPERTY_FACILITIES.FACILITIES.NAME",
      routerLink: "facilities",
    },
    {
      label: "PROPERTY.MARKETING.MEDIAS",
      routerLink: "medias",
    },
    {
      label: "PROPERTY.MARKETING.TEXTS",
      routerLink: "texts",
    },
  ];
}
