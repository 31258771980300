<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    [name]="'Jesper Thorkildsen'"
    label="COMPANIES.NAME"
    tooltip="Companies"
  ></est-subheader>
  <div class="flex flex-row justify-between mt-12 mb-6">
    <est-small-header
      appliedClasses="!mb-2"
      subheadingAppliedClasses="!w-[36.1875rem]"
      heading="COMPANIES.OVERVIEW.TITLE"
      subheading="COMPANIES.OVERVIEW.DESCRIPTION"
      tooltip="COMPANIES.OVERVIEW.TOOLTIP"
    ></est-small-header>
    <est-button
      (click)="openCreateCompanyModal()"
      appliedClasses="!px-6"
      buttonStyleType="button--primary"
      buttonType="button"
      imageAlt="ADD"
      imageSrc="assets/images/icons/plus.svg"
      label="COMPANIES.CREATE_COMPANY"
    ></est-button>
  </div>
  <div class="flex justify-between items-center mb-6 gap-x-8 gap-y-6 flex-wrap-reverse">
    <est-geography-select-field [selectSignal]="citySignal" appliedClasses="w-[12rem]"></est-geography-select-field>
    <est-search-input [searchSignal]="searchSignal" appliedClass="!w-[18.75rem]"></est-search-input>
  </div>
  <est-large-list-table
    [actions]="actions"
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
    includeImage="false"
  />
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
