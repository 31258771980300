import { Component } from "@angular/core";
import { LayoutComponent } from "../../core/layout/layout.component";
import { RouterLink, RouterOutlet } from "@angular/router";
import { BreadcrumbComponent } from "../../core/components/breadcrumb/breadcrumb.component";

@Component({
  selector: "est-main",
  standalone: true,
  imports: [LayoutComponent, BreadcrumbComponent, RouterLink, RouterOutlet],
  templateUrl: "./main.component.html",
  styleUrl: "./main.component.scss",
})
export class MainComponent {}
