<div [ngClass]="appliedClass()">
  <div
    class="flex justify-between items-center w-[37.5rem] bg-black-100 p-4 rounded-md border border-dashed border-black-400"
  >
    <div class="flex gap-4 items-center text-xs font-light">
      <div class="bg-white rounded px-8 py-4 text-center font-light">
        <img [alt]="'UPLOAD_FILE.NAME'" height="39" ngSrc="assets/images/icons/upload-file-image.svg" width="27" />
      </div>
      @if (!control().value) {
        <p>{{ "UPLOAD_FILE.NO_FILE_UPLOADED" | translate }}</p>
      } @else {
        <div>
          <p>
            {{ "FILE_NAME" | translate }}: <span>{{ control().value!.name }}</span>
          </p>
          <p>
            {{ "FILE_SIZE" | translate }}: <span>{{ getFileSizeInKb(control().value!) }}</span>
          </p>
        </div>
      }
    </div>
    <est-button
      (click)="openUploadFileDialog()"
      buttonStyleType="button--tertiary"
      buttonType="button"
      label="UPLOAD"
    ></est-button>
  </div>
  @if (shouldShowControlError(control())) {
    <mat-error class="!text-error-200 text-[0.5rem] f !flex items-center pt-1">
      <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
      {{ "VALIDATION.FILE_IS_REQUIRED" | translate }}
    </mat-error>
  }
</div>
