import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const PET_TYPE_POLICIES_HEADERS: ITableHeader[] = [
  {
    key: "active",
    label: "Active",
    type: TableHeaderType.Toggle,
    isSortable: true,
  },
  {
    key: "name",
    label: "Pet",
    type: TableHeaderType.TextWithIcon,
    isSortable: true,
  },
  {
    key: "applicationRequired",
    label: "Application required",
    needsTranslation: true,
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "sizeRestriction",
    label: "Size restrictions",
    needsTranslation: true,
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "speciesRestriction",
    needsTranslation: true,
    label: "Species restrictions",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "createdAt",
    label: "Added to unit",
    type: TableHeaderType.Date,
    isSortable: true,
  },
];
