<est-dialog-header label="EVENTS.SCHEDULE_REPETITION" tooltipLabel="EVENTS.SCHEDULE_REPETITION"></est-dialog-header>
<div class="p-6">
  <est-small-header
    appliedClasses="!mb-2"
    heading="DATE_AND_TIME"
    subheading="EVENTS.SCHEDULE_REPETITION_DESCRIPTION"
    subheadingAppliedClasses="mb-6"
    tooltip="DATE_AND_TIME"
  ></est-small-header>
  <div class="flex gap-4 mb-6">
    <div>
      <p class="font-medium text-sm mb-2">{{ "EVENTS.START_REPETITION" | translate }}</p>
      <est-datepicker [control]="innerStartDateFormControl" appliedClasses="!w-[9.375rem]"></est-datepicker>
    </div>
    <div>
      <p class="font-medium text-sm mb-2">{{ "EVENTS.END_TYPE" | translate }}</p>
      <est-select-field
        [control]="innerRepetitionFormGroup.controls.endType"
        [dropDownOptions]="endTypesOptions"
        [selectMultipleOptions]="false"
        appliedClass="!w-[9.375rem]"
      ></est-select-field>
    </div>
    @if (innerRepetitionFormGroup.controls.endType.value === PropertyEventRepetitionEndType.AfterOccurences) {
      <div>
        <p class="font-medium text-sm mb-2">{{ "OCCURRENCES" | translate }}</p>
        <est-select-field
          [dropDownOptions]="occurrencesOptions"
          [selectMultipleOptions]="false"
          [control]="innerRepetitionFormGroup.controls.occurrences"
          appliedClass="!w-[9.375rem]"
        ></est-select-field>
      </div>
    } @else if (innerRepetitionFormGroup.controls.endType.value === PropertyEventRepetitionEndType.OnThisDate) {
      <div>
        <p class="font-medium text-sm mb-2">{{ "EVENTS.END_REPETITION" | translate }}</p>
        <est-datepicker
          [control]="innerRepetitionFormGroup.controls.endDate"
          appliedClasses="!w-[9.375rem]"
        ></est-datepicker>
      </div>
    }
  </div>
  <p class="font-medium text-sm mb-2">{{ "EVENTS.REPETITION_CYCLE" | translate }}</p>
  <est-select-field
    [control]="innerRepetitionFormGroup.controls.repetitionCycle"
    [dropDownOptions]="repetitionCycleOptions"
    [selectMultipleOptions]="false"
  ></est-select-field>
  @if (innerRepetitionFormGroup.valid && innerStartDateFormControl.valid) {
    <p class="mt-2 text-sm font-light">
      @switch (innerRepetitionFormGroup.value.repetitionCycle) {
        @case (RepetitionCycle.Weekly) {
          <span [innerHTML]="('EVENTS.HAPPENS_EVERY_WEEK' | translate) + ' '"></span>
        }
        @case (RepetitionCycle.Monthly) {
          <span
            [innerHTML]="
              ('EVENTS.HAPPENS_EVERY_MONTH'
                | translate: { number: DateTime.fromISO(innerStartDateFormControl.value!).day }) + ' '
            "
          ></span>
        }
        @default {
          <span [innerHTML]="('EVENTS.HAPPENS_EVERY_DAY' | translate) + ' '"></span>
        }
      }
      <span
        [innerHTML]="
          'EVENTS.STARTING_AND_ENDING' | translate: { startDate: getEventStartDate(), endDate: eventEndDate }
        "
      ></span>
    </p>
  }
</div>
<div class="dialog__footer !static mt-6">
  <est-button
    (click)="matDialog.closeAll()"
    buttonStyleType="button--tertiary"
    buttonType="button"
    label="CANCEL"
  ></est-button>
  <est-button (click)="saveChanges()" buttonStyleType="button--primary" buttonType="button" label="CONFIRM">
  </est-button>
</div>
