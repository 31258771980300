<est-subheader
  [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
  name="Jesper Thorkildsen"
  label="PROPERTIES.MAIN_DETAILS.TITLE"
  tooltip="PROPERTIES.MAIN_DETAILS.TITLE"
></est-subheader>

<div class="border-2 rounded-lg mt-12 p-6 border-black-300 !bg-black-50">
  <div class="flex mb-4">
    <p
      [ngClass]="[eventDetails()?.eventType === eventType.PublicEvent ? 'bg-event-public-50 ' : 'bg-event-private-50']"
      class="py-1 px-6 rounded-md text-xs font-medium"
    >
      {{ eventTypeTranslationMapper.get(eventDetails()?.eventType!)! | translate }}
    </p>
  </div>

  <div class="flex items-end">
    <div class="w-full columns-3 max-breakpoint-small-desktop-screen:!columns-auto">
      <!-- First Col -->
      <div class="flex flex-col gap-4">
        <div class="flex gap-2">
          <img
            ngSrc="assets/images/icons/calendar-lines-pen-regular.svg"
            [alt]="'ICON' | translate"
            priority
            width="14"
            height="16"
          />
          <p class="font-light text-sm">{{ eventDetails()?.internalTitle }}</p>
        </div>
        <div class="flex gap-2">
          <img
            ngSrc="assets/images/icons/rectangle-ad.svg"
            [alt]="'ICON' | translate"
            priority
            width="14"
            height="16"
          />
          <p class="font-light text-sm">{{ eventDetails()?.externalTitle }}</p>
        </div>
        <div class="flex gap-2">
          <img ngSrc="assets/images/icons/location.svg" [alt]="'ICON' | translate" priority width="14" height="16" />
          <p class="font-light text-sm">{{ eventDetails()?.address }}</p>
        </div>
        <div class="flex gap-2">
          <img ngSrc="assets/images/icons/person.svg" [alt]="'ICON' | translate" priority width="14" height="16" />
          <p class="font-light text-sm">{{ eventDetails()?.responsibleAgent }}</p>
        </div>
      </div>

      <!-- Second Col -->
      <div class="flex flex-col gap-4">
        <div class="flex gap-2">
          <img
            ngSrc="assets/images/icons/date-details.svg"
            [alt]="'ICON' | translate"
            priority
            width="14"
            height="16"
          />
          <p class="font-light text-sm">
            {{ formatDateToGetWithDay(eventDetails()?.startDate!) }}
          </p>
        </div>
        <div class="flex gap-2">
          <img ngSrc="assets/images/icons/time.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
          <p class="font-light text-sm">
            {{ getEventStartTime() }} - {{ getEventEndTime() }} ({{
              getFormattedTimeDifference(eventDetails()?.startDate!, eventDetails()?.endDate!)
            }})
          </p>
        </div>
        <div class="flex gap-2">
          <img
            ngSrc="assets/images/icons/arrows-repeat.svg"
            [alt]="'ICON' | translate"
            priority
            width="16"
            height="16"
          />
          <p class="font-light text-sm">
            {{
              eventDetails()?.repetitionCycle
                ? ("EVENTS.DETAILS.REPEATS" | translate) + " " + eventDetails()?.repetitionCycle
                : ("EVENTS.DETAILS.NO_REPERITION" | translate)
            }}
          </p>
        </div>
        <div class="flex gap-2">
          <img
            ngSrc="assets/images/icons/flag-checkered.svg"
            [alt]="'ICON' | translate"
            priority
            width="16"
            height="16"
          />
          <p class="font-light text-sm">{{ formatDateToGetWithDay(eventDetails()?.endDate!) }}</p>
        </div>
      </div>

      <!-- Third Col -->
      <div class="flex flex-col gap-4">
        <div class="flex gap-2">
          <img ngSrc="assets/images/icons/users.svg" [alt]="'ICON' | translate" priority width="14" height="16" />
          <p class="font-light text-sm">
            {{
              eventDetails()?.isBookingEnabled
                ? ("EVENTS.EVENT_WITH_BOOKING" | translate)
                : ("EVENTS.EVENT_WITHOUT_BOOKING" | translate)
            }}
          </p>
        </div>
        <div class="flex gap-2">
          <img ngSrc="assets/images/icons/user-police.svg" [alt]="'ICON' | translate" priority width="14" height="16" />
          <p class="font-light text-sm">
            {{ getEventBookingType(eventDetails()?.bookingType) }}
          </p>
        </div>
        <div class="flex gap-2">
          <img
            ngSrc="assets/images/icons/person-to-door.svg"
            [alt]="'ICON' | translate"
            priority
            width="14"
            height="16"
          />
          <p class="font-light text-sm">
            {{
              eventDetails()?.maxAttendants ? ("EVENTS.TOTAL_SPOTS" | translate) + eventDetails()?.maxAttendants : "-"
            }}
          </p>
        </div>
        <div class="flex gap-2 items-center">
          <div
            [ngClass]="eventDetails()?.isBookingEnabled ? 'bg-success-dark' : 'bg-red-900'"
            class="rounded-full w-4 h-4"
          ></div>

          <p class="font-light text-sm pe-2.5">
            {{ getEventStatus(eventDetails()?.isBookingEnabled) | translate }}
          </p>
        </div>
      </div>
    </div>

    <div>
      <button
        type="button"
        [matMenuTriggerFor]="actionsMenu"
        [attr.aria-label]="'DROPDOWN_MENU' | translate"
        (click)="$event.stopPropagation()"
        class="cursor-pointer flex items-center bg-white rounded border border-black-300"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        <button mat-menu-item type="button" (click)="editEventDetails()">
          {{ (eventDetails()?.repetitionCycle ? "EVENTS.EDIT_SERIES" : "EVENTS.EDIT_EVENT") | translate }}
        </button>
        <button mat-menu-item type="button">
          {{ (eventDetails()?.repetitionCycle ? "EVENTS.DELETE_SERIES" : "EVENTS.DELETE_EVENT") | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
