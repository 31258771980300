<est-small-list-table
  (toggleItem)="togglePropertyPetTypePolicy($event.row)"
  [data]="paginatedData.results()"
  [headers]="headers"
  [isLoading]="isTableLoading"
  emptyCellValue="-"
  [sortingFilterSignal]="sortingFilterSignal"
  [iconMenuComponentType]="PetDialogComponent"
  [actions]="contextMenuActions"
>
</est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
