import { Component, inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DivisionFormClass } from "@root/shared/abstracts/division-form/division-form.abstract";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "@root/shared/input/input.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { MatDivider } from "@angular/material/divider";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { NgOptimizedImage } from "@angular/common";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectOwnerFieldComponent } from "@root/shared/select-division-owner-field/select-owner-field.component";
import { TranslateModule } from "@ngx-translate/core";
import { UploadLogoBrandComponent } from "@root/shared/upload-logo-brand/upload-logo-brand.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { addressDivisionFormDataMapper } from "@root/data/market/division/utilities/address-division-form-data.utilities";

@Component({
  selector: "est-edit-division",
  standalone: true,
  imports: [
    FormsModule,
    InputComponent,
    LoadingOverlayComponent,
    MatDivider,
    MatProgressBar,
    MatSlideToggle,
    MatStep,
    MatStepper,
    NgOptimizedImage,
    PhoneInputComponent,
    SelectFieldComponent,
    SelectOwnerFieldComponent,
    TranslateModule,
    UploadLogoBrandComponent,
    AddressComponent,
    ButtonComponent,
    DialogHeaderComponent,
    ReactiveFormsModule,
  ],
  templateUrl: "./edit-division.component.html",
  styleUrl: "./edit-division.component.scss",
})
export class EditDivisionComponent extends DivisionFormClass implements OnInit {
  totalTabsNo: number = 2;
  componentInputs: { divisionId: number } = inject(MAT_DIALOG_DATA);
  isPageLoading = false;

  ngOnInit() {
    this.getAddressLookupsOptions();
    this.getDivisionById();
  }

  getDivisionById() {
    this.isPageLoading = true;
    this.divisionService.getDivisionDetails(this.componentInputs.divisionId).subscribe({
      next: (data) => {
        this.divisionForm.patchValue(data);
        this.ownerIdControl.setValue(Number(data.ownerId));
        data.logoUrl && this.imageControl.setValue(data.logoUrl);
        this.logoThumbFilesizeControl.setValue(data.logoThumbFileSize);
        this.logoThumbFilenameControl.setValue(data.logoThumbFileName);
        this.phoneNumberControl.setValue({
          internationalNumber: data.divisionDetails.contactPersonPhoneNo,
          number: data.divisionDetails.contactPersonPhoneNo,
          countryCode: data.divisionDetails.contactPersonIsdCode,
        });
        this.isPageLoading = false;
      },
      error: () => {
        this.isPageLoading = false;
        this.dialog.closeAll();
      },
    });
  }

  submitForm(): void {
    this.isLoading = true;
    const divisionFormData = addressDivisionFormDataMapper(this.divisionForm, this.phoneNumberControl);
    this.divisionService.updateDivisionDetails(divisionFormData, this.componentInputs.divisionId).subscribe({
      next: () => {
        this.isLoading = false;
        this.closeDialogAndUpdateTable();
      },
      error: () => {
        this.isLoading = false;
        this.dialog.closeAll();
      },
    });
  }
}
