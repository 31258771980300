<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    [name]="'Jesper Thorkildsen'"
    label="DETAILS.NAME"
    tooltip="DETAILS.NAME"
  ></est-subheader>
  @if (!isPageLoading) {
    <form [formGroup]="companyDetailsFormControls.formGroup">
      <p class="font-medium text-base pb-4 pt-10">{{ "COMPANIES.COMPANY" | translate }}</p>
      <div class="flex flex-wrap gap-4">
        <est-input
          (focusout)="patchCompanyDetailChanges()"
          label="COMPANIES.FORM.COMPANY_NAME"
          appliedClass="w-[15rem]"
          [control]="companyDetailsFormControls.name"
        ></est-input>
        <est-input
          label="CREATED"
          appliedClass="w-[15rem]"
          [control]="companyDetailsFormControls.createdAt"
        ></est-input>
        <est-input
          label="COMPANIES.FORM.COMPANY_OWNER"
          appliedClass="w-[15rem]"
          [control]="companyDetailsFormControls.companyOwner"
        ></est-input>
      </div>
      <div class="absolute start-0 end-0">
        <hr class="mt-6 border-black-100" />
      </div>
      <div class="pt-10">
        <p class="font-medium text-base pb-4">{{ "COMPANIES.FORM.COMPANY_DETAILS" | translate }}</p>
        <est-address
          [formGroup]="companyDetailsFormControls.addressInformation"
          [lookups]="addressLookupsOptions"
          [isLoadingSignal]="isAddressLoadingSignal"
          [formattedAddressSignal]="formattedAddressSignal"
          (selectedValueChange)="patchCompanyDetailChanges($event)"
        ></est-address>
        <div class="flex flex-wrap mb-4 gap-4" (focusout)="patchCompanyDetailChanges()">
          <est-input
            appliedClass="mr-4 w-[18.75rem]"
            [control]="companyDetailsFormControls.contactPersonName"
            [label]="'CONTACT_PERSON'"
          ></est-input>
          <est-input
            appliedClass="mr-4 w-[18.75rem] "
            [control]="companyDetailsFormControls.contactPersonEmail"
            label="CONTACT_EMAIL"
            [type]="'email'"
          ></est-input>
          <est-phone-input [control]="companyDetailsFormControls.phone"></est-phone-input>
        </div>
        <est-input
          (focusout)="patchCompanyDetailChanges()"
          appliedClass="w-[18.75rem]"
          [control]="companyDetailsFormControls.websiteUrl"
          label="WEBSITE"
        ></est-input>
      </div>
      <div class="absolute start-0 end-0">
        <hr class="mt-6 border-black-100" />
      </div>
    </form>
  } @else {
    <div class="py-32 relative">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</div>
