import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { PropertyEventRepetitionFormGroup } from "@root/views/main/property/property-events/create-property-event/property-event-repetition-formgroup.type";
import { FormBuilder, Validators } from "@angular/forms";
import { PropertyEventRepetitionEndType } from "@root/data/market/properties/enums/property-event-repetition-end-type.enum";
import { RepetitionCycle } from "@root/shared/enums/repitition-cycle";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { ICreatePropertyEventRepetitionInputs } from "@root/shared/property-event-repetition-popup/property-event-repetition-inputs.interface";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import {
  generateDropdownOptionsFromNumber,
  generateTranslatedDropdownOptionsFromEnum,
} from "@root/shared/utilities/dropdown-options.utilities";
import { PropertyEventRepetitionEndTypeTranslationMapper } from "@root/views/main/property/property-events/mappers/property-event-booking-type-translation.mapper copy";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RepetitionCycleTranslationMapper } from "@root/views/main/property/property-events/mappers/repetition-cycle-translation.mapper";
import { DateTime } from "luxon";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import {
  getEventRepetitionEndDate,
  getEventRepetitionStartDate,
} from "@root/shared/utilities/event.repeitition.utilities";

@Component({
  selector: "est-property-event-confirmation-popup",
  standalone: true,
  imports: [
    TranslateModule,
    TooltipComponent,
    ButtonComponent,
    DialogHeaderComponent,
    SmallHeaderComponent,
    DatepickerComponent,
    SelectFieldComponent,
  ],
  templateUrl: "./property-event-repetition-popup.component.html",
  styleUrl: "./property-event-repetition-popup.component.scss",
})
export class PropertyEventRepetitionPopupComponent implements OnInit {
  readonly componentInputs: ICreatePropertyEventRepetitionInputs = inject(MAT_DIALOG_DATA);
  readonly matDialog = inject(MatDialog);
  readonly endTypesOptions = generateTranslatedDropdownOptionsFromEnum(
    PropertyEventRepetitionEndType,
    PropertyEventRepetitionEndTypeTranslationMapper,
  );
  readonly occurrencesOptions = generateDropdownOptionsFromNumber(99, false, 1);
  repetitionCycleOptions = generateTranslatedDropdownOptionsFromEnum(RepetitionCycle, RepetitionCycleTranslationMapper);
  protected readonly RepetitionCycle = RepetitionCycle;
  protected readonly DateTime = DateTime;
  protected readonly PropertyEventRepetitionEndType = PropertyEventRepetitionEndType;
  readonly #fb = inject(FormBuilder);
  /*
   * Temporarily holds the value in the modal reptition form
   * Used for updating the mainframe repetition form once hitting save
   */
  readonly innerRepetitionFormGroup: PropertyEventRepetitionFormGroup = this.#fb.group({
    endDate: this.#fb.control<string | null>(null, Validators.required),
    occurrences: this.#fb.control<number>(1, {
      nonNullable: true,
      validators: Validators.required,
    }),
    endType: this.#fb.control<PropertyEventRepetitionEndType | null>(null, Validators.required),
    repetitionCycle: this.#fb.control<RepetitionCycle | null>(null, Validators.required),
  });
  readonly innerStartDateFormControl = this.#fb.control<string | null>(null, Validators.required);
  readonly #translateService = inject(TranslateService);

  readonly #updateRepetitionFormGroupOnTypeChange$ = this.innerRepetitionFormGroup.controls.endType.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe((endType) => {
      if (!endType) return;
      if (endType === PropertyEventRepetitionEndType.AfterOccurences) {
        this.innerRepetitionFormGroup.controls.occurrences.enable();
        this.innerRepetitionFormGroup.controls.endDate.disable();
      } else {
        this.innerRepetitionFormGroup.controls.occurrences.disable();
        this.innerRepetitionFormGroup.controls.endDate.enable();
      }
    });

  readonly #updateRepetitionCycleOptionsOnDateChange$ = this.innerStartDateFormControl.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe((isoDate) => {
      if (!isoDate) return;
      const dateTime = DateTime.fromISO(isoDate);
      this.repetitionCycleOptions = [
        {
          value: RepetitionCycle.Daily,
          label: RepetitionCycleTranslationMapper.get(RepetitionCycle.Daily)!,
        },
        {
          value: RepetitionCycle.WeekDays,
          label: RepetitionCycleTranslationMapper.get(RepetitionCycle.WeekDays)!,
        },
        {
          value: RepetitionCycle.Weekly,
          label: RepetitionCycleTranslationMapper.get(RepetitionCycle.Weekly)!,
          subLabel: dateTime.toLocaleString({ weekday: "long" }),
        },
        {
          value: RepetitionCycle.Monthly,
          label: RepetitionCycleTranslationMapper.get(RepetitionCycle.Monthly)!,
          subLabel: `${dateTime.day} ${this.#translateService.instant("IN_THE_MONTH")}`,
        },
      ];
    });

  get eventEndDate(): string {
    if (this.innerRepetitionFormGroup.invalid || this.innerStartDateFormControl.invalid) return "";
    return getEventRepetitionEndDate({
      endDate: this.innerRepetitionFormGroup.value.endDate,
      startDate: this.innerStartDateFormControl.value!,
      repetitionCycle: this.innerRepetitionFormGroup.value.repetitionCycle!,
      repetitionEndType: this.innerRepetitionFormGroup.value.endType!,
      occurrences: this.innerRepetitionFormGroup.value.occurrences,
    });
  }

  ngOnInit(): void {
    this.#copyInitialFormValue();
  }

  getEventStartDate(): string {
    if (this.innerStartDateFormControl.invalid || this.innerRepetitionFormGroup.controls.repetitionCycle.invalid)
      return "";
    return getEventRepetitionStartDate({
      startDate: this.innerStartDateFormControl.value!,
      repetitionCycle: this.innerRepetitionFormGroup.value.repetitionCycle!,
    });
  }

  saveChanges() {
    if (this.innerRepetitionFormGroup.invalid) {
      markAllControlsAsTouchedAndDirty(this.innerRepetitionFormGroup);
      return;
    }
    this.componentInputs.repetitionForm.enable();
    this.componentInputs.repetitionForm.patchValue(this.innerRepetitionFormGroup.value);
    this.componentInputs.startDateControl.setValue(this.innerStartDateFormControl.value);
    this.matDialog.closeAll();
  }

  #copyInitialFormValue() {
    this.innerRepetitionFormGroup.patchValue(this.componentInputs.repetitionForm.value);
    this.innerStartDateFormControl.setValue(this.componentInputs.startDateControl.value);
  }
}
