import { RoomType } from "@root/data/market/units/enums/room-type.enum";

export const RoomTypesAddons = [
  {
    type: RoomType.Bedroom,
    controlName: "bedrooms",
    quantityControlName: "bedroomQuantity",
    visibleControlName: "bedroomsVisible",
  },
  {
    type: RoomType.LivingRoom,
    controlName: "livingRooms",
    quantityControlName: "livingRoomQuantity",
    visibleControlName: "livingRoomsVisible",
  },
  {
    type: RoomType.AdditionalRoom,
    controlName: "additionalRooms",
    quantityControlName: "additionalRoomQuantity",
    visibleControlName: "additionalRoomsVisible",
  },
  {
    type: RoomType.Restroom,
    controlName: "restrooms",
    quantityControlName: "restroomQuantity",
    visibleControlName: "restroomsVisible",
  },
  {
    type: RoomType.Balcony,
    controlName: "balconies",
    quantityControlName: "balconyQuantity",
    visibleControlName: "balconiesVisible",
  },
  {
    type: RoomType.Terrace,
    controlName: "terraces",
    quantityControlName: "terraceQuantity",
    visibleControlName: "terracesVisible",
  },
  {
    type: RoomType.Garden,
    controlName: "gardens",
    quantityControlName: "gardenQuantity",
    visibleControlName: "gardensVisible",
  },
  {
    type: RoomType.InternalStorageRoom,
    controlName: "internalStorageRooms",
    quantityControlName: "internalStorageRoomQuantity",
    visibleControlName: "internalStorageRoomsVisible",
  },
];
