import { NgClass, NgOptimizedImage, NgStyle } from "@angular/common";
import { Component, inject, input, output, signal } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IEventDetailsOverview } from "@root/data/market/event/models/event-details-overview.model";
import { PropertiesEventsService } from "@root/data/market/properties/services/properties-events.service";
import { EventType, EventTypeTranslationMapper } from "@root/shared/enums/event-type";
import { LinkedUnitsTablePopupComponent } from "@root/shared/linked-units-table-popup/linked-units-table-popup.component";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { formatDateToGetWithDay, formatDateToTodayDateString } from "@root/shared/utilities/date.utilities";
import { finalize } from "rxjs/internal/operators/finalize";

@Component({
  selector: "est-event-card",
  standalone: true,
  imports: [
    MatExpansionModule,
    NgOptimizedImage,
    LinkedUnitsTablePopupComponent,
    MatMenu,
    TranslateModule,
    MatMenuTrigger,
    MatIcon,
    MatMenuItem,
    NgClass,
    NgStyle,
  ],
  templateUrl: "./event-card.component.html",
  styleUrl: "./event-card.component.scss",
})
export class EventCardComponent {
  protected readonly formatDateToGetWithDay = formatDateToGetWithDay;
  protected readonly EventType = EventType;
  protected eventTypeTranslationMapper = EventTypeTranslationMapper;
  readonly #dialogService = inject(DialogService);
  readonly #router = inject(Router);
  readonly #propertiesEventsService = inject(PropertiesEventsService);
  eventDetails = input.required<IEventDetailsOverview>();
  propertyId = input.required<number>();
  translationService = inject(TranslateService);
  isStoppedRepetition = output<boolean>();
  readonly #isStopRepetition = signal(false);

  todayDate = formatDateToTodayDateString(new Date());
  openCard = output<boolean>();
  getEventStatus(isAvailable?: boolean) {
    return isAvailable ? "UNIT_STATUS.AVAILABLE" : "EVENTS.OCCUPIED_THE_EVENT_IS_FULL_BOOKED";
  }

  getEventBookingType(type: string | undefined) {
    if (!type) return `${this.translationService.instant("NOT_PROVIDED")}`;
    return `${this.translationService.instant("EVENTS." + type.toUpperCase())} ${this.translationService.instant("EVENTS.ATTENDANCE")}`;
  }

  openEventDetails() {
    this.#router.navigate([`properties/${this.propertyId()}/events/${this.eventDetails()?.id}/event-details`], {
      queryParams: { instanceId: this.eventDetails()?.instanceId },
    });
  }

  editEventDetails(eventId: number) {
    this.#router.navigate([`properties/${this.propertyId()}/events/edit/${eventId}`]);
  }

  openStopRepetition(eventId: number) {
    this.#dialogService.open(
      {
        title: "EVENTS.STOP_REPETITION",
        tooltipLabel: "EVENTS.STOP_REPETITION",
        callBack: () => this.stopRepetition(eventId),
        submitLabel: "CONFIRM",
        isInputIncluded: false,
        descriptions: ["EVENTS.STOP_REPETITION_CONFIRMATION"],
        isSubmitLoading: this.#isStopRepetition,
        cancelLabel: "CANCEL",
      },
      "confirmation",
    );
  }

  stopRepetition(eventId: number) {
    this.#isStopRepetition.set(true);
    this.#propertiesEventsService
      .stopRepetitionOnEventById(this.propertyId(), eventId)
      .pipe(
        finalize(() => {
          this.#isStopRepetition.set(false);
          this.#dialogService.close();
        }),
      )
      .subscribe({
        next: () => {
          this.isStoppedRepetition.emit(true);
        },
      });
  }

  isExpanded = false;

  expandCard() {
    this.isExpanded = !this.isExpanded;
    this.openCard.emit(this.isExpanded);
  }

  getStyleBasedOnDate() {
    return this.eventDetails().startDay === this.todayDate &&
      !this.eventDetails().isDraft &&
      !this.eventDetails().isArchived
      ? "border-success-dark !bg-success-100"
      : "border-black-300 !bg-black-50 ";
  }
}
