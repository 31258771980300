<div class="p-6 mt-12 w-full bg-black-100 rounded-lg">
  <div
    class="flex whitespace-nowrap gap-y-4 flex-wrap justify-between p-6 rounded-t-lg border-black-300 border bg-white"
  >
    <div class="flex items-center">
      <img
        [alt]="'ESTATETOOL_LOGO' | translate"
        [ngSrc]="propertyUnitStatus().imageUrl ?? '/assets/images/division-logo-placeholder.jpg'"
        class="rounded"
        height="100"
        priority
        width="100"
      />

      <div class="ps-4">
        <p class="font-medium">{{ propertyUnitStatus().propertyName }}</p>
        <p class="capitalize font-light text-xs text-black-500">{{ propertyUnitStatus().address }}</p>
        <p class="capitalize font-light text-xs text-black-500">{{ propertyUnitStatus().country }}</p>
      </div>
    </div>
    <div class="flex flex-wrap gap-10 items-center">
      <div>
        <p class="text-sm font-light mb-2">{{ "UNIT_STATUS.AVAILABLE" | translate }}</p>
        <p class="font-medium text-xl">{{ propertyUnitStatus().availableUnits }}</p>
      </div>
      <mat-divider [vertical]="true" class="h-5"></mat-divider>
      <div>
        <p class="text-sm font-light mb-2">{{ "UNIT_STATUS.IN_PROCESS" | translate }}</p>
        <p class="font-medium text-xl">{{ propertyUnitStatus().inProcessUnits }}</p>
      </div>
      <mat-divider [vertical]="true" class="h-5"></mat-divider>
      <div>
        <p class="text-sm font-light mb-2">{{ "UNIT_STATUS.COMPLETED" | translate }}</p>
        <p class="font-medium text-xl">{{ propertyUnitStatus().completedUnits }}</p>
      </div>
      <mat-divider [vertical]="true" class="h-5"></mat-divider>
      <div>
        <p class="text-sm font-light mb-2">{{ "UNIT_STATUS.NOT_ACTIVE" | translate }}</p>
        <p class="font-medium text-xl">{{ propertyUnitStatus().notActiveUnits }}</p>
      </div>
      <mat-divider [vertical]="true" class="h-5"></mat-divider>
      <div>
        <p class="text-sm font-light mb-2">{{ "UNIT_STATUS.TOTAL_UNITS" | translate }}</p>
        <p class="font-medium text-xl">{{ propertyUnitStatus().totalUnits }}</p>
      </div>
    </div>
  </div>
</div>
