<est-small-header
  heading="SETTINGS.MARKETING.SURVEYS.NAME"
  subheading="SETTINGS.MARKETING.SURVEYS.DESCRIPTION"
  tooltip="SETTINGS.MARKETING.SURVEYS.NAME"
></est-small-header>
<div>
  <div class="mt-6">
    <est-navigation-tabs [tabs]="surveyTabs"></est-navigation-tabs>
    <hr class="border-black-100 absolute start-0 end-0" />
    <div class="mt-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
