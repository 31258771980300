import { Component, input } from "@angular/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { IOutdoorSpacesOverview } from "@root/shared/interfaces/outdoor-spaces-overview.interface";
import { M_SQUARE } from "@root/shared/constants/global.constants";

@Component({
  selector: "est-outdoor-space-for-unit",
  standalone: true,
  imports: [CustomDatePipe, TranslateModule],
  templateUrl: "./outdoor-space-for-unit.component.html",
  styleUrl: "./outdoor-space-for-unit.component.scss",
})
export class OutdoorSpaceForUnitComponent {
  lastChangedDate = input<string>();
  outdoorSpacesOverview = input<IOutdoorSpacesOverview[]>();
  protected readonly M_SQUARE = M_SQUARE;
}
