import { Component, inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { MatProgressBar } from "@angular/material/progress-bar";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { InputComponent } from "@root/shared/input/input.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { ListSelectPropertiesComponent } from "@root/shared/list-select-properties/list-select-properties.component";
import { ListSelectTeamsComponent } from "@root/shared/list-select-teams/list-select-teams.component";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SelectDivisionFieldComponent } from "@root/shared/select-search-division/select-division-field.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { UploadFileDropZoneComponent } from "@root/shared/upload-file-drop-zone/upload-file-drop-zone.component";
import { getFileSize } from "@root/shared/utilities/files.utilities";
import { IFileUploadInputs } from "@root/shared/file-upload-dialog/file-upload-inputs.interface";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";

@Component({
  selector: "est-file-upload-dialog",
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltip,
    MatProgressBar,
    NgOptimizedImage,
    ButtonComponent,
    CreationSuccessMessageComponent,
    InputComponent,
    LanguageDropdownComponent,
    ListSelectPropertiesComponent,
    ListSelectTeamsComponent,
    MatStep,
    MatStepper,
    NgIf,
    PhoneInputComponent,
    SelectDivisionFieldComponent,
    SelectFieldComponent,
    UploadFileDropZoneComponent,
  ],
  templateUrl: "./file-upload-dialog.component.html",
  styleUrl: "./file-upload-dialog.component.scss",
})
export class FileUploadDialogComponent implements OnInit {
  componentInputs: IFileUploadInputs = inject(MAT_DIALOG_DATA);
  innerControl = new FormControl<File[] | File | null>(null); // Holds the value to be applied to the provided control on close
  protected readonly FileExtension = FileExtension;
  protected readonly getFileSize = getFileSize;
  readonly #matDialog = inject(MatDialog);
  readonly #snackbarService = inject(SnackbarService);

  get control(): FormControl<File[] | File | null> {
    return this.componentInputs.control; // This should be an array if isMultiple is true
  }

  get controlFilesArray(): File[] | null {
    if (!this.innerControl.value) return null;
    if (this.componentInputs.isMultiple) {
      return this.innerControl.value as File[];
    }
    return [this.innerControl.value as File];
  }

  ngOnInit() {
    if (!this.control.value && this.componentInputs.isMultiple) {
      this.innerControl.setValue([]);
    } else {
      this.innerControl.setValue(this.control.value);
    }
  }

  updateControl(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    if (element.files && element.files.length > 0) {
      if (this.componentInputs.isMultiple) {
        this.#uploadMultipleFiles(element.files);
      } else {
        this.#uploadSingleFile(element.files[0]);
      }
    }
    element.value = "";
  }

  saveAndCloseDialog(): void {
    this.control.setValue(this.innerControl.value);
    this.closeDialog();
  }

  closeDialog(): void {
    this.#matDialog.closeAll();
  }

  getFilename(file: File): string {
    return file.name;
  }

  deleteFile(index: number) {
    if (this.componentInputs.isMultiple) {
      (this.innerControl.value as File[]).splice(index, 1);
    } else {
      this.innerControl.setValue(null);
    }
  }

  #uploadSingleFile(file: File) {
    if (this.#isFileSizeBelowMaxSize(file)) {
      this.innerControl.setValue(file);
    } else {
      this.#snackbarService.open(SnackbarType.Error, "VALIDATION.SINGLE_FILE_EXCEEDS_MAX_SIZE");
    }
  }

  #uploadMultipleFiles(fileList: FileList) {
    const elligebleFiles: File[] = this.#filterFilesExceedingMaxSize(Array.from(fileList));
    if (elligebleFiles.length < fileList.length) {
      // Files were not uploaded
      if (fileList.length === 1) {
        this.#snackbarService.open(SnackbarType.Error, "VALIDATION.SINGLE_FILE_EXCEEDS_MAX_SIZE");
      } else {
        this.#snackbarService.open(SnackbarType.Error, "VALIDATION.MULTIPLE_FILE_EXCEEDS_MAX_SIZE");
      }
    }
    this.innerControl.setValue([...(this.innerControl.value as File[]), ...elligebleFiles]);
  }

  #filterFilesExceedingMaxSize(files: File[]) {
    if (!this.componentInputs.maxSizeInMb) return files;
    const elligebleFiles: File[] = [];
    files.forEach((file) => {
      if (this.#isFileSizeBelowMaxSize(file)) elligebleFiles.push(file);
    });
    return elligebleFiles;
  }

  #isFileSizeBelowMaxSize(file: File) {
    if (!this.componentInputs.maxSizeInMb) return true;
    return file.size / 1024 / 1024 < this.componentInputs.maxSizeInMb;
  }
}
