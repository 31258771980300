import { NgClass } from "@angular/common";
import { Component, ElementRef, inject, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { OrganizationService } from "@root/data/market/organisation/services/organisation.service";
import { ISysAdminUser } from "@root/data/market/users/models/user-system-admin.model";
import { BaseCompositeSelectSearchFieldComponent } from "@root/shared/abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IDropdownWithRoleNameOption } from "@root/shared/interfaces/dropdown-with-role-name-option.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { isElementScrolledToTheBottom } from "@root/shared/utilities/element-poistion.utilities";
import { finalize } from "rxjs";
import { Observable } from "rxjs/internal/Observable";

@Component({
  selector: "est-transfer-ownership",
  standalone: true,
  imports: [
    TranslateModule,
    ButtonComponent,
    MatTooltip,
    SearchInputComponent,
    MatRadioGroup,
    MatRadioButton,
    MatOption,
    NgClass,
    FormsModule,
    SpinnerComponent,
    SmallHeaderComponent,
  ],
  templateUrl: "./transfer-ownership.component.html",
  styleUrl: "./transfer-ownership.component.scss",
})
export class TransferOwnershipComponent extends BaseCompositeSelectSearchFieldComponent {
  readonly componentInputs: { currentOwnerUserId: number; currentOwnerName: string } = inject(MAT_DIALOG_DATA);
  readonly #dialogRef: MatDialogRef<TransferOwnershipComponent> = inject(MatDialogRef);
  readonly #lookupsService = inject(LookupsService);
  readonly #organisationService = inject(OrganizationService);

  isTransferOwnershipLoading = false;

  organisationSysAdmins!: Array<ISysAdminUser>;

  organisationOwner: IDropdownWithRoleNameOption = {
    label: this.componentInputs.currentOwnerName,
    value: this.componentInputs.currentOwnerUserId,
  };

  @ViewChild("containerUi") containerUi!: ElementRef<HTMLElement>;

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#lookupsService.getSystemAdminsInOrganisation({
      search: this.searchSignal(),
      pageNumber,
      pageSize: this.paginatedOptions.pageSize(),
      ...(this.control().value && { selectedId: this.control().value }),
    });
  }

  getSysAdminUsersWithoutCurrentOwner(allSystemAdmins: IDropdownWithRoleNameOption[]): IDropdownWithRoleNameOption[] {
    return allSystemAdmins.filter(
      (systemAdmin) => Number(systemAdmin.value) !== this.componentInputs.currentOwnerUserId,
    );
  }

  scrollEnd() {
    if (this.isLoading) return;
    if (
      this.paginatedOptions.totalPages() &&
      this.paginatedOptions.currentPage() >= this.paginatedOptions.totalPages()!
    ) {
      return;
    }
    if (isElementScrolledToTheBottom(this.containerUi.nativeElement, 52)) {
      this.isLoading = true;
      const nextPageIndex = this.paginatedOptions.currentPage() + 1;
      this.loadOptions(nextPageIndex);
    }
  }

  transferOwnership(): void {
    this.isTransferOwnershipLoading = true;
    const newOwnerUserId = this.organisationOwner.value;
    const newOwnerName = this.organisationOwner.label;
    this.#organisationService
      .transferOwnership(newOwnerUserId)
      .pipe(finalize(() => (this.isTransferOwnershipLoading = false)))
      .subscribe(() => this.closeDialog(newOwnerName, newOwnerUserId));
  }

  protected closeDialog = (newOwnerFullName?: string, newOwnerUserId?: number) => {
    this.#dialogRef.close({ newOwnerFullName, newOwnerUserId });
  };
}
