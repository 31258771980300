import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ICompanyOverview } from "../models/company-overview.model";
import { Observable } from "rxjs/internal/Observable";
import { ICreateCompanyPayload } from "@root/data/market/companies/models/create-company-payload.model";
import { PropertyType } from "@root/data/market/properties/enums/property-type.enum";
import { IPropertyOverview } from "@root/data/market/properties/models/property-overview.model";
import { IPaginatedPropertyResponse } from "@root/shared/interfaces/paginated-property-response.interface";
import { ICompanyDetails } from "@root/data/market/companies/models/company-details.model";

@Injectable({
  providedIn: "root",
})
export class CompaniesRepository {
  readonly #http = inject(HttpClient);

  getPaginatedCompanies(
    params: IPaginationSortPayload & {
      city?: string;
      divisionIds?: number[];
    },
  ): Observable<IPaginatedResponse<ICompanyOverview>> {
    return this.#http.get<IPaginatedResponse<ICompanyOverview>>(APIS.companies.getAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  createNewCompany(companyData: ICreateCompanyPayload): Observable<number> {
    return this.#http.post<number>(APIS.companies.create, companyData);
  }

  updateCompanyDetails(companyData: ICreateCompanyPayload, companyId: number): Observable<void> {
    return this.#http.put<void>(APIS.companies.update(companyId), companyData);
  }

  getCompanyDetails(id: number): Observable<ICompanyDetails> {
    return this.#http.get<ICompanyDetails>(APIS.companies.getDetails(id));
  }

  patchCompanyDetails(id: number, body: Partial<ICompanyDetails>): Observable<void> {
    return this.#http.patch<void>(APIS.companies.patchDetails(id), body);
  }

  getProperties(
    params: IPaginationSortPayload & {
      isFavourite?: boolean;
      propertyType?: PropertyType | "";
      companyIds: number[];
    },
  ): Observable<IPaginatedPropertyResponse<IPropertyOverview>> {
    return this.#http.get<IPaginatedPropertyResponse<IPropertyOverview>>(APIS.companies.getProperties, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  deleteCompany(companyId: number): Observable<void> {
    return this.#http.delete<void>(APIS.companies.delete(companyId));
  }
}
