import { Component, effect, inject, OnInit, signal } from "@angular/core";
import { BasePetTypeFormControls } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/base-pet-type.form-controls";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPetTypeFormInputs } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/pet-type-form-inputs.interface";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { PetsService } from "@root/data/market/pets/services/pets.service";
import { finalize, Observable } from "rxjs";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";

@Component({
  template: "",
})
/*
    Responsible for pet types policies Add / Edit

    This component has a predefined template: base-pet-type-form.component.html
 */
export abstract class BasePetTypeFormComponent implements OnInit {
  abstract isEdit: boolean;
  allowedPetTypesOptions: IDropdownOption[] = [];
  notAllowedSpeciesOptions: IDropdownOption[] = [];
  protected assignedPetTypesIds: number[] = [];
  protected readonly petsService = inject(PetsService);
  protected readonly matDialog = inject(MatDialog);
  protected readonly petTypeFormControls = inject(BasePetTypeFormControls);
  protected readonly componentInputs: IPetTypeFormInputs = inject(MAT_DIALOG_DATA);
  protected readonly notAllowedSpeciesSearchSignal = signal("");
  protected isLoading = false;
  protected isSubmittingForm = false;
  protected isLoadingSpecies = false;
  readonly #snackbarService = inject(SnackbarService);
  readonly #loadNotAllowedSpeciesWithSearch$ = effect(() => {
    this.notAllowedSpeciesSearchSignal();
    const petId = this.formControls.petTypeId.value;
    if (!petId) return;
    this.isLoadingSpecies = true;
    this.petsService
      .getPetTypeSpecies(this.notAllowedSpeciesSearchSignal(), petId)
      .pipe(finalize(() => (this.isLoadingSpecies = false)))
      .subscribe((options) => (this.notAllowedSpeciesOptions = options));
  });

  get formControls() {
    return this.petTypeFormControls.formGroup.controls;
  }

  ngOnInit() {
    this.petTypeFormControls.checkAnimalSizeInputToggling();
  }

  abstract getSubmitFormServiceCall(): Observable<void>;

  protected loadPetSpeciesOptions(petTypeId: number): void {
    this.petsService.getPetTypeSpecies(this.notAllowedSpeciesSearchSignal(), petTypeId).subscribe((options) => {
      this.notAllowedSpeciesOptions = options;
    });
  }

  protected submitForm(): void {
    if (this.petTypeFormControls.formGroup.invalid) {
      markAllControlsAsTouchedAndDirty(this.petTypeFormControls.formGroup);
      return;
    }
    this.isSubmittingForm = true;
    this.getSubmitFormServiceCall().subscribe({
      next: () => {
        this.#snackbarService.open(SnackbarType.Success);
        this.matDialog.closeAll();
      },
      error: () => (this.isSubmittingForm = false),
    });
  }
}
