import { Observable } from "rxjs";
import { IParseCsvResponse } from "@root/data/market/import/models/parse-csv-response.model";
import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIS } from "@root/core/api/market.apis";
import { ICreateUnitsPayload } from "@root/data/market/import/models/create-units-payload.model";

@Injectable({
  providedIn: "root",
})
export class ImportRepository {
  #http = inject(HttpClient);

  parseCsv(formData: FormData): Observable<IParseCsvResponse> {
    return this.#http.post<IParseCsvResponse>(APIS.import.parseUnits, formData);
  }

  downloadTemplate(): Observable<Blob> {
    return this.#http.get(APIS.import.downloadTemplate, {
      responseType: "blob",
    });
  }

  createUnits(payload: ICreateUnitsPayload): Observable<number> {
    return this.#http.post<number>(APIS.import.createUnits, payload);
  }
}
