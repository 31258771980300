import { Component, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { castControlFromAbstractToFormControl } from "@root/shared/utilities/form.utilities";
import {
  RoomTypeNameTranslationMapper,
  RoomTypeQuantityTranslationMapper,
} from "@root/data/market/units/mappers/room-type-translation.mapper";
import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { IRoomDropdownOption } from "@root/views/main/units/unit-details/components/unit-layout/components/room-input-fields/room-dropdown-option.interface";
import { RoomTypeHasConnectedRoomHashMap } from "@root/data/market/units/mappers/room-type.mapper";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { EndUserVisibilityInputComponent } from "@root/shared/end-user-visibility-input/end-user-visibility-input.component";
import { UnitLayoutRoomTypeSwitcher } from "@root/views/main/units/unit-details/components/unit-layout/unit-layout-room-type.switcher";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import {
  generateDropdownOptionsFromNumber,
  generateTranslatedDropdownOptionsFromEnum,
} from "@root/shared/utilities/dropdown-options.utilities";
import { RestroomType } from "@root/data/market/units/enums/restroom-type.enum";
import { RestroomTypeTranslationMapper } from "@root/data/market/units/mappers/restroom-type-translation.mapper";
import { RoomInputFieldsBinder } from "@root/views/main/units/unit-details/components/unit-layout/components/room-input-fields/room-input-fields.binder";
import { mapConnectionRoom } from "@root/views/main/units/unit-details/components/unit-layout/unit-layout-form.utilities";
import { MatDialog } from "@angular/material/dialog";
import { RoomPopupComponent } from "@root/views/main/units/unit-details/components/unit-layout/components/room-popup/room-popup.component";
import { IRoomModalInputs } from "@root/views/main/units/unit-details/components/unit-layout/components/room-popup/room-popup-inputs.interface";
import { NgClass } from "@angular/common";
import { RoomService } from "@root/data/market/rooms/services/room.service";

@Component({
  selector: "est-room-input-fields",
  standalone: true,
  imports: [
    SelectFieldComponent,
    TranslateModule,
    MatTooltip,
    PostfixTextInputComponent,
    ButtonComponent,
    EndUserVisibilityInputComponent,
    TooltipComponent,
    NgClass,
  ],
  templateUrl: "./room-input-fields.component.html",
  styleUrl: "./room-input-fields.component.scss",
})
export class RoomInputFieldsComponent implements OnInit {
  unitLayoutId = input.required<string>();
  roomType = input.required<RoomType>();
  quantityControl = input.required<FormControl<number>>();
  visibleControl = input.required<FormControl<boolean>>();
  formArray = input.required<FormArray<FormGroup>>();
  doesTotalRoomCountMatch = input.required<boolean>();
  roomNames = input.required<IRoomDropdownOption[]>();
  roomNameDropdownOptions: IRoomDropdownOption[] = [];
  internalFloors = input.required<IDropdownOption[]>();
  appliedClasses = input<string>("");
  balconyTypes = input.required<IDropdownOption[]>();
  terraceTypes = input.required<IDropdownOption[]>();
  connectionRooms = input.required<IDropdownOption[]>();
  quantityOptions: IDropdownOption[] = generateDropdownOptionsFromNumber(5, false);
  restroomTypes: IDropdownOption[] = generateTranslatedDropdownOptionsFromEnum(
    RestroomType,
    RestroomTypeTranslationMapper,
  );
  roomTypeSwitcher = inject(UnitLayoutRoomTypeSwitcher);
  readonly #roomService = inject(RoomService);
  readonly #translateService = inject(TranslateService);
  readonly #binder = inject(RoomInputFieldsBinder);
  @Output() valuesChanged = new EventEmitter<void>();
  readonly #matDialog = inject(MatDialog);
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;
  protected readonly RoomTypeQuantityTranslationMapper = RoomTypeQuantityTranslationMapper;
  protected readonly RoomTypeNameTranslationMapper = RoomTypeNameTranslationMapper;
  protected readonly RoomTypeHasConnectedRoom = RoomTypeHasConnectedRoomHashMap;
  protected readonly RoomType = RoomType;

  ngOnInit() {
    this.#filterRoomNames();
    if (!RoomTypeHasConnectedRoomHashMap.get(this.roomType())!) {
      return;
    }
    this.#binder.onConnectionRoomRemoved.subscribe((roomId) => this.#onConnectionRoomRemoved(roomId));
  }

  protected updateRoomCount(roomCount: number): void {
    let currentCount = this.formArray().length;
    if (roomCount === currentCount) return;
    while (roomCount > currentCount) {
      this.#addRoom();
      currentCount++;
    }
    while (roomCount < currentCount) {
      this.#removeRoom(currentCount - 1);
      currentCount--;
    }
  }

  protected openRoomPopup(roomId: number, index: number, roomNameId: number): void {
    let restroomType: RestroomType | undefined = undefined;
    if (this.roomType() === RoomType.Restroom) {
      restroomType = this.formArray().at(index).controls["restroomType"].value;
    }
    this.#matDialog.open<RoomPopupComponent, IRoomModalInputs>(RoomPopupComponent, {
      data: {
        roomType: this.roomType(),
        roomId: roomId,
        roomIndex: index + 1,
        roomSubTitle: this.#getRoomName(roomNameId),
        restroomType,
      },
      width: "28.125rem",
    });
  }

  protected connectionRoomNameChanged(roomNameId: number, roomId: number, index: number): void {
    if (RoomTypeHasConnectedRoomHashMap.get(this.roomType())!) {
      this.valuesChanged.emit();
      return;
    }
    const existingConnectionRoom = this.connectionRooms().find((x) => x.value == roomId);
    if (existingConnectionRoom) {
      existingConnectionRoom.subLabel = this.#getRoomName(roomNameId);
    } else {
      this.connectionRooms().push(
        mapConnectionRoom(
          this.#translateService.instant(RoomTypeNameTranslationMapper.get(this.roomType())!),
          this.#getRoomName(roomNameId),
          roomId,
          index,
        ),
      );
    }
    this.valuesChanged.emit();
  }

  #onConnectionRoomRemoved(roomId: number) {
    for (const formGroup of this.formArray().controls) {
      const connectedRoomIdControl = castControlFromAbstractToFormControl(formGroup.controls["connectedRoomId"]);
      if (connectedRoomIdControl.value !== roomId) {
        continue;
      }
      connectedRoomIdControl.setValue(null);
    }
  }

  #addRoom(): void {
    this.#roomService.create(this.unitLayoutId(), this.roomType()).subscribe((roomId) => {
      const formGroupFunction = this.roomTypeSwitcher.roomTypeFormGroup.get(this.roomType())!;
      const formGroup = formGroupFunction(undefined);
      formGroup.patchValue({ id: roomId });
      this.formArray().push(formGroup);

      if (RoomTypeHasConnectedRoomHashMap.get(this.roomType())!) {
        this.valuesChanged.emit();
        return;
      }

      this.connectionRooms().push({
        label: `${this.#translateService.instant(RoomTypeNameTranslationMapper.get(this.roomType())!)} #${this.formArray().length}`,
        value: formGroup.controls["id"].value,
      });
      this.valuesChanged.emit();
    });
  }

  #removeRoom(index: number): void {
    const roomId = this.formArray().at(index).controls["id"].value as number;
    this.#roomService.delete(roomId, this.roomType()).subscribe(() => {
      if (!RoomTypeHasConnectedRoomHashMap.get(this.roomType())!) {
        const connectionRoomIndex = this.connectionRooms().findIndex((x) => x.value === roomId);
        this.connectionRooms().splice(connectionRoomIndex, 1);
        this.#binder.emitConnectionRoomRemoved(roomId);
      }

      index = this.formArray().value.findIndex((x) => x.id === roomId);
      this.formArray().removeAt(index);
      this.valuesChanged.emit();
    });
  }

  #getRoomName(roomNameId: number): string | undefined {
    const roomName = this.roomNames().find((x) => x.value === roomNameId);
    if (roomName?.value === null) {
      return undefined;
    }
    return roomName?.label;
  }

  #filterRoomNames() {
    this.roomNameDropdownOptions = this.roomNames().filter((roomName) => roomName.roomType === this.roomType());
    this.roomNameDropdownOptions.unshift({
      label: "NONE",
      value: null,
      roomType: this.roomType(),
    });
  }
}
