import { Component, inject, OnInit } from "@angular/core";
import { MatStep, MatStepperModule } from "@angular/material/stepper";
import { InputComponent } from "@root/shared/input/input.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogActions } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { UploadLogoBrandComponent } from "@root/shared/upload-logo-brand/upload-logo-brand.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { GlobalLoaderComponent } from "@root/shared/global-loader/global-loader.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SelectOwnerFieldComponent } from "@root/shared/select-division-owner-field/select-owner-field.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ICreateCompanyPayload } from "@root/data/market/companies/models/create-company-payload.model";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { EditCompanyComponent } from "@root/views/main/division/division-companies/edit-company/edit-company.component";
import { CompanyFormControls } from "@root/views/main/division/division-companies/company-form-controls";

import { BaseCompanyForm } from "@root/shared/abstracts/company-form/company-form.abstract";
import { Router } from "@angular/router";

@Component({
  selector: "est-form-create-company",
  standalone: true,
  imports: [
    MatStep,
    InputComponent,
    TranslateModule,
    ReactiveFormsModule,
    MatTooltip,
    MatDialogActions,
    MatStepperModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    NgOptimizedImage,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatDividerModule,
    MatSlideToggle,
    UploadLogoBrandComponent,
    SelectFieldComponent,
    AddressComponent,
    SelectSearchFieldComponent,
    PhoneInputComponent,
    NgClass,
    ButtonComponent,
    GlobalLoaderComponent,
    LoadingOverlayComponent,
    SelectOwnerFieldComponent,
    CreationSuccessMessageComponent,
  ],
  templateUrl: "./create-company-form.component.html",
  styleUrl: "./create-company-form.component.scss",
  providers: [CompanyFormControls],
})
export class CreateCompanyFormComponent extends BaseCompanyForm implements OnInit {
  router = inject(Router);

  override totalTabsNo = 2;
  isLoading: boolean = false;
  protected readonly authService = inject(AuthService);
  companyId: number = 0;

  ngOnInit(): void {
    this.#getAddressLookupsOptions();
    this.mapPhoneNumberOnContact();
  }

  #getAddressLookupsOptions(): void {
    this.lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }

  isDataBeingLoading(): boolean {
    return this.isAddressLoadingSignal();
  }

  moveToNextStepOrSubmitFormIfValid(): void {
    if (this.matStepper.selectedIndex === this.totalTabsNo - 2 && this.companyFormControls.companyFormGroup.valid) {
      this.submitForm();
      return;
    }
    this.moveToNextStepIfValid();
  }

  openEditCompanyModal(): void {
    this.dialog.closeAll();
    this.dialog.open(EditCompanyComponent, {
      minWidth: "75dvw",
      maxHeight: "95dvh",
      data: {
        companyId: this.companyId,
      },
    });
  }

  navigateToCompany(): void {
    if (this.companyId === 0) {
      this.dialog.closeAll();
      return;
    }

    this.router.navigate([`companies/${this.companyId}`]);
    this.dialog.closeAll();
  }

  override submitForm() {
    this.isLoading = true;
    const createCompanyData: ICreateCompanyPayload = this.companyFormControls.companyFormGroup
      .value as ICreateCompanyPayload;
    this.companiesService.createNewCompany(createCompanyData).subscribe({
      next: (response: number) => {
        this.matStepper.next();
        this.companyId = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }
}
