import { FormControl } from "@angular/forms";
import { ITeamMember } from "../../../../shared/interfaces/team-member.interface";
import { DestroyRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

// Refactoring this component to be based on emit
export const setAndUpdateTeamMemberAndTeamLeader = (
  teamMemberControl: FormControl<number[]>,
  teamLeaderControl: FormControl<number[]>,
  teamUsersControl: FormControl<ITeamMember[]>,
  teamMengerIdControl: FormControl<number | null>,
  destroyRef: DestroyRef,
) => {
  teamMemberControl.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((ids) => {
    updateTeamUsers(ids, teamMemberControl, teamLeaderControl, teamUsersControl); //update team user control relate to change in teamMember only
    syncTeamLeadersAndTeamMangerWhenTeamUserRemoved(ids, teamLeaderControl, teamMengerIdControl);
  });
  teamLeaderControl.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((ids) => {
    updateTeamUsers(ids, teamMemberControl, teamLeaderControl, teamUsersControl);
    addTeamLeaderAsTeamMember(ids, teamMemberControl);
  });
};

const updateTeamUsers = (
  ids: number[],
  teamMemberControl: FormControl<number[]>,
  teamLeaderControl: FormControl<number[]>,
  teamUsersControl: FormControl<ITeamMember[]>,
) => {
  const currentMembers = teamMemberControl.value;
  const teamUsersIds = [...new Set([...currentMembers, ...ids])];
  const users = teamUsersIds.map((teamMemberId: number) => ({
    userId: teamMemberId,
    isTeamLeader: teamLeaderControl.value.includes(teamMemberId),
  }));
  teamUsersControl.setValue(users);
};
const addTeamLeaderAsTeamMember = (ids: number[], teamMemberControl: FormControl<number[]>) => {
  const currentMembers = teamMemberControl.value;
  const newMembers = ids.filter((id) => !currentMembers.includes(id));
  if (newMembers.length > 0) {
    teamMemberControl.setValue([...currentMembers, ...newMembers]);
  }
};
// when sync between team member and team leader
const syncTeamLeadersAndTeamMangerWhenTeamUserRemoved = (
  ids: number[],
  teamLeaderControl: FormControl<number[]>,
  teamMangerControl: FormControl<number | null>,
) => {
  const leaders = teamLeaderControl.value.filter((id) => ids.includes(id));
  if (teamMangerControl.value && !ids.includes(teamMangerControl.value)) {
    teamMangerControl.setValue(null);
  }
  if (leaders.length !== teamLeaderControl.value.length) {
    teamLeaderControl.setValue(leaders);
  }
};

export const setTeamMangerAsTeamMember = (
  teamMemberControl: FormControl<number[]>,
  teamMangerId: FormControl<number | null>,
  destroyRef: DestroyRef,
) => {
  teamMangerId.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((id) => {
    if (id && !teamMemberControl.value.includes(id)) {
      teamMemberControl.setValue([...teamMemberControl.value, id]);
    }
  });
};
export const handleErrorOnRemoveTeamManager = (
  teamManagerId: FormControl<number | null>,
  destroyRef: DestroyRef,
  callback: (hasValue: boolean) => void,
): void => {
  teamManagerId.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe((value) => {
    const hasValue = !value;
    callback(hasValue);
  });
};
