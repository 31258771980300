import { AbstractControl, FormGroup } from "@angular/forms";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import {
  CONTACT_COUNTRY_CODE,
  CONTACT_PERSON_PHONE_NUMBER,
  DETAILS_ADDRESS_INFORMATION,
  DIVISION_DETAILS,
} from "@root/shared/constants/address-keys-form-date.constants";

export const addressDivisionFormDataMapper = (formGroup: FormGroup, phoneNumber: AbstractControl<IPhoneNumber>) => {
  const formDate = new FormData();
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormGroup && control.value instanceof Object) {
      if (field === DIVISION_DETAILS) {
        getAddressInformFormData(formDate, control, field, phoneNumber);
      }
    } else {
      control && formDate.append(field, control.value);
    }
  });
  return formDate;
};

const getAddressInformFormData = (
  formDate: FormData,
  form: FormGroup,
  parent: string,
  phoneNumber: AbstractControl<IPhoneNumber>,
) => {
  Object.keys(form.controls).forEach((field) => {
    const control = form.get(field);
    if (control instanceof FormGroup && field !== CONTACT_PERSON_PHONE_NUMBER && field !== CONTACT_COUNTRY_CODE) {
      getAddressInformFormData(formDate, control, DETAILS_ADDRESS_INFORMATION, phoneNumber);
    } else if (field !== CONTACT_PERSON_PHONE_NUMBER && field !== CONTACT_COUNTRY_CODE) {
      if (control && control.value !== null) {
        formDate.append(`${parent}.` + field, control.value);
      }
    } else if (field === CONTACT_PERSON_PHONE_NUMBER && isPhoneNumber(phoneNumber.value) && control) {
      appendDataOfPhoneNumberAndCountryCode(formDate, control, phoneNumber);
    }
  });
  return formDate;
};

const appendDataOfPhoneNumberAndCountryCode = (
  formData: FormData,
  control: AbstractControl<string>,
  phoneNumber: AbstractControl<IPhoneNumber>,
) => {
  const number = phoneNumber.value.number ? phoneNumber.value.number : "";
  const countryCode = phoneNumber.value.countryCode;
  control.setValue(number);
  formData.append(`${DIVISION_DETAILS}.${CONTACT_PERSON_PHONE_NUMBER}`, number);
  formData.append(`${DIVISION_DETAILS}.${CONTACT_COUNTRY_CODE}`, countryCode);

  return formData;
};

const isPhoneNumber = (value: any): value is IPhoneNumber => {
  return value && typeof value === "object" && "internationalNumber" in value && "countryCode" in value;
};
