import { Component, DestroyRef, inject, input, OnInit, signal } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";

import { TooltipComponent } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { PropertyTextsService } from "@root/data/market/properties/services/properties-texts.service";
import { TranslateService } from "@ngx-translate/core";
import { PropertyTextsCommunicationService } from "./property-texts-communication.service";
import { ICardAction } from "@root/shared/interfaces/card-Action.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TextCardComponent } from "@root/shared/text-card/text-card.component";
import { finalize } from "rxjs/internal/operators/finalize";
import { ITextsParams } from "@root/shared/interfaces/property-texts-params.interface";
import { IPropertyTextCard } from "@root/data/market/properties/models/property-text-card.interface";
import { IDuplicatePackageInput } from "@root/shared/interfaces/duplicate-package-input.interface";
import { DuplicatePropertyTextComponent } from "./duplicate-property-text/duplicate-property-text.component";

@Component({
  selector: "est-property-texts",
  standalone: true,
  imports: [
    ButtonComponent,
    ButtonComponent,
    RouterLink,
    TooltipComponent,
    SmallHeaderComponent,
    LoadingOverlayComponent,
    TextCardComponent,
  ],
  templateUrl: "./property-texts.component.html",
  styleUrl: "./property-texts.component.scss",
})
export class PropertyTextsComponent implements OnInit {
  readonly router = inject(Router);
  readonly #translate = inject(TranslateService);
  readonly #matDialog = inject(MatDialog);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogService = inject(DialogService);
  readonly #propertyTextsService = inject(PropertyTextsService);
  readonly #propertyTextsCommunicationService = inject(PropertyTextsCommunicationService);
  readonly #route = inject(ActivatedRoute);

  propertyId = input.required<number>();

  #isDeletingTextField = signal(false);
  isPageLoading: boolean = false;
  propertyTexts!: IPropertyTextCard[];

  actions: ICardAction[] = [
    {
      label: "PROPERTY_TEXTS.EDIT_TEXT",
      callbackFn: (_, propertyText: IPropertyTextCard) => this.#routeToEditText(propertyText.textFieldId),
    },
    {
      label: "PROPERTY_TEXTS.DUPLICATE_TEXT.TITLE",
      callbackFn: (_, propertyText: IPropertyTextCard) => this.#openDuplicateDialog(propertyText),
    },
    {
      label: "PROPERTY_TEXTS.DELETE.TITLE",
      callbackFn: (id: number, propertyText: IPropertyTextCard) =>
        this.#openDeleteDialog(id, propertyText.internalTitle),
    },
  ];

  ngOnInit() {
    this.#getPropertyTexts();
    this.#subscribeToReloadListPage();
  }

  #getPropertyTexts() {
    this.isPageLoading = true;
    const params: ITextsParams = { propertyId: this.propertyId(), isPackage: false };

    this.#propertyTextsService
      .getPropertyTextOverview(params)
      .pipe(finalize(() => (this.isPageLoading = false)))
      .subscribe((data) => (this.propertyTexts = data));
  }

  #routeToEditText(id: number) {
    this.router.navigate(["edit", id], { relativeTo: this.#route });
  }

  #openDuplicateDialog(propertyTextPackage: IPropertyTextCard) {
    this.#matDialog.open<DuplicatePropertyTextComponent, IDuplicatePackageInput>(DuplicatePropertyTextComponent, {
      height: "90dvh",
      width: "75rem",
      data: {
        propertyId: this.propertyId(),
        originalName: propertyTextPackage.internalTitle,
        originalPackageId: propertyTextPackage.id,
      },
    });
  }

  #openDeleteDialog(id: number, textFieldName: string): void {
    this.#dialogService.open(
      {
        title: "PROPERTY_TEXTS.DELETE.TITLE",
        callBack: () => this.#deletePropertyText(id),
        submitLabel: "IM_SURE",
        isInputIncluded: false,
        descriptions: ["PROPERTY_TEXTS.DELETE.ARE_YOU_SURE", this.#getTextFieldName(textFieldName)],
        isSubmitLoading: this.#isDeletingTextField,
        additionalData: { id: id, textFieldName: textFieldName },
      },
      "confirmation",
    );
  }

  #deletePropertyText(textId: number): void {
    this.#isDeletingTextField.set(true);
    const params: ITextsParams = { isPackage: false };
    this.#propertyTextsService
      .deletePropertyText(this.propertyId(), textId, params)
      .pipe(
        finalize(() => {
          this.#isDeletingTextField.set(false);
          this.#matDialog.closeAll();
        }),
      )
      .subscribe({ next: () => this.#propertyTextsCommunicationService.reloadPropertyTextListPage$.next() });
  }

  #getTextFieldName(textFieldName: string) {
    return this.#translate.instant("PROPERTY_TEXTS.DELETE.TEXT_FIELD_NAME", { textFieldName });
  }

  #subscribeToReloadListPage() {
    this.#propertyTextsCommunicationService.reloadPropertyTextListPage$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#getPropertyTexts();
      });
  }
}
