<div class="overflow-auto max-h-[95dvh]">
  <div class="pt-6 px-6 relative">
    <est-small-header
      appliedClasses="font-medium text-xl "
      [heading]="'ORGANISATION.DETAILS.TRANSFER_OWNERSHIP.TITLE' | translate"
      [tooltip]="'ORGANISATION.DETAILS.TRANSFER_OWNERSHIP.TOOLTIP' | translate"
    >
    </est-small-header>

    <hr class="border-black-100 absolute start-0 end-0" />

    <div class="mt-8 mb-8 text-sm">
      <h5 [innerHTML]="'ORGANISATION.DETAILS.TRANSFER_OWNERSHIP.WARNING' | translate"></h5>
    </div>

    <div class="mt-6">
      <h5 class="text-sm font-medium">
        {{ "ORGANISATION.DETAILS.TRANSFER_OWNERSHIP.USERS_IN_ORGANISATION.TITLE" | translate }}
      </h5>
      <p class="text-xs font-light">
        {{ "ORGANISATION.DETAILS.TRANSFER_OWNERSHIP.USERS_IN_ORGANISATION.USERS_NUMBER" | translate: { number: 2 } }}
      </p>

      <est-search-input appliedClass="mt-6 mb-4" [searchSignal]="searchSignal"></est-search-input>

      <div class="h-60 overflow-y-auto" #containerUi (scroll)="scrollEnd()">
        <mat-radio-group required #organisationOwnerRef="ngModel" [(ngModel)]="organisationOwner">
          @for (user of getSysAdminUsersWithoutCurrentOwner(paginatedOptions.results()); track user.value) {
            <div
              class="flex justify-between border px-4 py-1 items-center rounded-md w-full mb-2"
              [ngClass]="organisationOwner.value === user.value ? 'bg-primary-10 border-primary' : 'bg-white border'"
            >
              <div class="flex">
                <p
                  [ngClass]="organisationOwner.value === user.value ? 'font-normal' : 'font-light'"
                  class="text-sm pe-1"
                >
                  {{ user.label }}
                </p>
                <p class="font-light text-sm text-black-500">( {{ user.roleName ?? "" | translate }})</p>
              </div>
              <mat-radio-button [value]="user" [aria-label]="user.label"> </mat-radio-button>
            </div>
          }
        </mat-radio-group>

        @if (isLoading) {
          <div class="pb-2">
            <est-spinner height="30" width="30" />
          </div>
        }
        @if (!isLoading && !paginatedOptions.results().length) {
          <p class="text-sm text-center p-2 text-black-600">
            {{ "NO_RESULTS" | translate }}
          </p>
          <mat-option class="!hidden"></mat-option>
        }
      </div>
    </div>
  </div>

  <div class="py-3 bg-black-50 flex border border-black-100 px-6 justify-between z-10">
    <est-button
      appliedClasses="!px-6"
      (click)="closeDialog()"
      buttonType="button"
      buttonStyleType="button--tertiary"
      label="CANCEL"
    ></est-button>
    <est-button
      appliedClasses="!px-6"
      [disabled]="organisationOwnerRef.invalid"
      [isLoading]="isTransferOwnershipLoading"
      (click)="transferOwnership()"
      buttonType="button"
      buttonStyleType="button--primary"
      label="ORGANISATION.DETAILS.TRANSFER_OWNERSHIP.BUTTON"
    ></est-button>
  </div>
</div>
