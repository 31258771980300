import { HttpInterceptorFn } from "@angular/common/http";
import { APIS } from "../api/market.apis";
import { inject } from "@angular/core";
import { AuthService } from "../../data/market/auth/services/auth.service";

export const divisionIdInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  if (req.url.startsWith(APIS.getLoggedInUser)) {
    return next(req);
  }

  const newReq = req.clone({
    headers: req.headers.set("X-division-id", authService.currentUserDivisionRole?.divisionId.toString() || ""),
  });

  return next(newReq);
};
