import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, inject, input, model } from "@angular/core";
import { MatDivider } from "@angular/material/divider";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

@Component({
  selector: "est-select-field-two-way-bind",
  standalone: true,
  imports: [NgClass, MatFormField, TranslateModule, MatLabel, MatSelect, MatOption, MatDivider, NgOptimizedImage],
  templateUrl: "./select-field-two-way-bind.component.html",
  styleUrl: "./select-field-two-way-bind.component.scss",
})
export class SelectFieldTwoWayBindComponent {
  // This should be used for two way binding only
  dropDownOptions = input.required<IDropdownOption[]>();
  dropDownLabel = input<string>("");
  appliedClass = input<string>("");
  selectMultipleOptions = input.required<boolean>();
  value = model.required<any>();
  protected readonly translateService = inject(TranslateService);
}
