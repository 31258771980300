import { Component, inject } from "@angular/core";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { PropertyType } from "@root/data/market/properties/enums/property-type.enum";
import { BasePropertiesTableComponent } from "@root/shared/abstracts/base-properties-table/base-properties-table.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CreatePropertyComponent } from "@root/shared/create-property/create-property.component";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { LargeListTableComponent } from "@root/shared/large-list-table/large-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";

@Component({
  selector: "est-division-properties",
  standalone: true,
  imports: [
    SubheaderComponent,
    ButtonComponent,
    LargeListTableComponent,
    PaginationComponent,
    SearchInputComponent,
    SelectFieldComponent,
  ],
  templateUrl: "../../../../shared/abstracts/base-properties-table/base-properties-table.component.html",
})
export class DivisionPropertiesComponent extends BasePropertiesTableComponent {
  readonly #divisionsService = inject(DivisionService);

  override openCreatePropertyModal(): void {
    this.dialog.open(CreatePropertyComponent, {
      minWidth: "75dvw",
      maxHeight: "95dvh",
    });
  }

  override loadData(params: IPaginationSortPayload & { isFavourite?: boolean; propertyType?: PropertyType }): void {
    this.isTableLoading = true;
    this.#divisionsService.getProperties(params).subscribe((paginatedProperties) => {
      copyPaginatedSignalResponse(this.paginatedData, paginatedProperties);
      this.isTableLoading = false;
      this.updatePortfolioPredefinedOptions(
        paginatedProperties.totalPropertiesAccessible(),
        paginatedProperties.totalFavourites(),
      );
      this.cachedTotalProperties = paginatedProperties.totalPropertiesAccessible();
    });
  }

  override loadDataWithCorrectParams(): void {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      ...(this.isFavouriteSignal()
        ? {
            IsFavourite: this.isFavouriteSignal(),
          }
        : {}),
      ...(this.propertyTypeSignal() ? { propertyType: this.propertyTypeSignal() } : {}),
    });
  }
}
