import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { ICompanyDetails } from "@root/data/market/companies/models/company-details.model";
import { IAddressFormGroup } from "@root/shared/interfaces/address-form-group.interface";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";

@Injectable({
  providedIn: "root",
})
export class CompanyDetailsFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  name = this.#fb.control("", Validators.required);
  companyOwner = this.#fb.control("");
  contactPersonName = this.#fb.control("", Validators.required);
  contactPersonIsdCode = this.#fb.control("");
  contactPersonEmail = this.#fb.control("", [Validators.required, Validators.email]);
  contactPersonPhoneNo = this.#fb.control("", Validators.required);
  phone = this.#fb.control<IPhoneNumber>(constructInitialPhoneNumberValue());
  websiteUrl = this.#fb.control("");
  companyName = this.#fb.control("", Validators.required);
  careOf = this.#fb.control("");
  vat = this.#fb.control("", Validators.required);
  countryId = this.#fb.control("", Validators.required);
  streetName = this.#fb.control("", Validators.required);
  houseNumber = this.#fb.control("", Validators.required);
  floorId = this.#fb.control("", { updateOn: "change" });
  sideId = this.#fb.control("", { updateOn: "change" });
  door = this.#fb.control("");
  zipCode = this.#fb.control("", Validators.required);
  city = this.#fb.control("", Validators.required);
  createdAt = this.#fb.control("");
  addressInformation = this.#fb.group<IAddressFormGroup>({
    companyName: this.companyName,
    careOf: this.careOf,
    vat: this.vat,
    countryId: this.countryId,
    streetName: this.streetName,
    houseNumber: this.houseNumber,
    floor: this.floorId,
    side: this.sideId,
    door: this.door,
    zipCode: this.zipCode,
    city: this.city,
  });

  companyDetails = this.#fb.group({
    addressInformation: this.addressInformation,
    contactPersonName: this.contactPersonName,
    contactPersonIsdCode: this.contactPersonIsdCode,
    contactPersonEmail: this.contactPersonEmail,
    contactPersonPhoneNo: this.contactPersonPhoneNo,
    websiteUrl: this.websiteUrl,
  });

  formGroup = this.#fb.group({
    name: this.name,
    companyOwner: this.companyOwner,
    companyDetails: this.companyDetails,
  });

  patchValue(data: Partial<ICompanyDetails>) {
    this.formGroup.patchValue(data);
  }
}
