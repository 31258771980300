import { Component, effect, input, Input, OnInit, signal } from "@angular/core";
import { FormControl } from "@angular/forms";
import { IDropdownOption } from "../../interfaces/dropdown-option.interface";

@Component({
  template: "",
  styles: "",
})
export abstract class BaseCompositeSelectLocalSearchFieldComponent implements OnInit {
  // This is very similar to BasecompositeSelectSearchField except it is used for select fields
  // with local client-side searching and no pagination

  @Input() selectSignal = signal<any | undefined>(undefined);
  disabledOptions = input<(number | string)[]>([]);
  control = input<FormControl>(new FormControl());
  searchSignal = signal("");
  dropdownOptions = input<IDropdownOption[]>([]);
  renderedDropdownOptions: IDropdownOption[] = [];
  #isInitialized = false;

  ngOnInit(): void {
    this.#filterRenderedOptionsBySearch();
  }

  readonly #searchAndFilterOptionsEffect$ = effect(() => {
    // Workaround to make sure renderedDropdownOptions is rendered OnInit
    this.searchSignal();
    if (this.#isInitialized) {
      this.#filterRenderedOptionsBySearch();
    } else {
      this.#isInitialized = true;
    }
  });

  #filterRenderedOptionsBySearch(): void {
    this.renderedDropdownOptions = this.dropdownOptions().filter((option) => {
      return option.value.toString().toLowerCase().includes(this.searchSignal().toLowerCase().trim());
    });
  }
}
