import { Component, WritableSignal, inject, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ButtonComponent } from "@root/shared/button/button.component";
import { NgStyle } from "@angular/common";
import { NgClass } from "@angular/common";
import { MAT_DIALOG_DATA, MatDialogClose } from "@angular/material/dialog";
import { AssignPropertiesToTeamTreeComponent } from "@root/shared/assign-properties-to-team-tree/assign-properties-to-team-tree.component";
import { TeamsService } from "@root/data/market/teams/services/teams.service";
import { MatDialogRef } from "@angular/material/dialog";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { TeamsCommunicationService } from "@root/views/main/organization/organization-settings/components/users/teams/teams-communication.service";

@Component({
  selector: "est-assign-properties-to-team",
  standalone: true,
  templateUrl: "./assign-properties-to-team.component.html",
  styleUrls: ["./assign-properties-to-team.component.scss"],
  imports: [
    TranslateModule,
    MatTooltipModule,
    SearchInputComponent,
    MatTreeModule,
    MatIconModule,
    FormsModule,
    MatCheckboxModule,
    ButtonComponent,
    NgStyle,
    NgClass,
    MatDialogClose,
    AssignPropertiesToTeamTreeComponent,
  ],
})
export class AssignPropertiesToTeamComponent {
  searchSignal: WritableSignal<string> = signal("");
  totalPropertiesAvailable: number = 0;
  selectedPropertiesArray!: number[];
  teamService = inject(TeamsService);
  dialogRef = inject(MatDialogRef);
  snackBarService = inject(SnackbarService);
  #reloadTeamTableList = inject(TeamsCommunicationService);
  isLoading = false;
  componentInputs: { teamId: number } = inject(MAT_DIALOG_DATA);

  updatePropertiesCount(count: number): void {
    this.totalPropertiesAvailable = count;
  }

  updateSelectedPropertiesArray(properties: number[]): void {
    this.selectedPropertiesArray = properties;
  }

  assignProperties() {
    this.isLoading = true;
    this.teamService
      .assignProperties(this.componentInputs.teamId, this.selectedPropertiesArray)
      .pipe()
      .subscribe(() => {
        this.isLoading = false;
        this.#reloadTeamTableList.reloadTeamPage$.next();
        this.dialogRef.close();
        this.snackBarService.open(SnackbarType.Success);
      });
  }
}
