import { UNITS_COLUMN_NAMES } from "@root/views/main/toolbox/import-data/constants/units-fields-names.constants";

export const UNITS_FIELDS_REQUIRED_HASHMAP = new Map<string, boolean>([
  [UNITS_COLUMN_NAMES.housingType, true],
  [UNITS_COLUMN_NAMES.listingType, false],
  [UNITS_COLUMN_NAMES.constructionYear, false],
  [UNITS_COLUMN_NAMES.floorPlanType, true],
  [UNITS_COLUMN_NAMES.stage, false],
  [UNITS_COLUMN_NAMES.plotNumber, false],
  [UNITS_COLUMN_NAMES.administrationStartDate, false],
  [UNITS_COLUMN_NAMES.administrationEndDate, false],
  [UNITS_COLUMN_NAMES.vacancyDate, false],
  [UNITS_COLUMN_NAMES.longitude, true],
  [UNITS_COLUMN_NAMES.latitude, true],
  [UNITS_COLUMN_NAMES.isUnderRenovation, false],
  [UNITS_COLUMN_NAMES.renovationStartDate, false],
  [UNITS_COLUMN_NAMES.renovationEndDate, false],
  [UNITS_COLUMN_NAMES.seoTitle, false],
  [UNITS_COLUMN_NAMES.metaDescription, false],
  [UNITS_COLUMN_NAMES.externalLink, false],
  [UNITS_COLUMN_NAMES.energyLabel, false],
  [UNITS_COLUMN_NAMES.floor, false],
  [UNITS_COLUMN_NAMES.houseNumber, true],
  [UNITS_COLUMN_NAMES.street, true],
  [UNITS_COLUMN_NAMES.door, false],
  [UNITS_COLUMN_NAMES.zipCode, true],
  [UNITS_COLUMN_NAMES.city, true],
  [UNITS_COLUMN_NAMES.country, true],
  [UNITS_COLUMN_NAMES.paymentFrequency, true],
  [UNITS_COLUMN_NAMES.salePrice, false],
  [UNITS_COLUMN_NAMES.rentPrice, false],
  [UNITS_COLUMN_NAMES.acontoHeat, false],
  [UNITS_COLUMN_NAMES.acontoWater, false],
  [UNITS_COLUMN_NAMES.acontoElectricity, false],
  [UNITS_COLUMN_NAMES.acontoResidentsSociety, false],
  [UNITS_COLUMN_NAMES.internetFee, false],
  [UNITS_COLUMN_NAMES.antennaFee, false],
  [UNITS_COLUMN_NAMES.depositMonths, true],
  [UNITS_COLUMN_NAMES.prepaidMonths, true],
  [UNITS_COLUMN_NAMES.nonCancellationPeriodMonths, true],
  [UNITS_COLUMN_NAMES.rentalCondition, false],
  [UNITS_COLUMN_NAMES.rentalPeriodMonths, false],
  [UNITS_COLUMN_NAMES.interiorMaintenance, false],
  [UNITS_COLUMN_NAMES.exteriorMaintenance, false],
  [UNITS_COLUMN_NAMES.usageType, true],
  [UNITS_COLUMN_NAMES.usageTypeVisible, false],
  [UNITS_COLUMN_NAMES.noticePeriodDays, true],
]);
