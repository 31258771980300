import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonToggle } from "@angular/material/button-toggle";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { OrganizationService } from "@root/data/market/organisation/services/organisation.service";
import { REGEX_PATTERNS } from "@root/shared/constants/regex-patterns.constants";
import { InputComponent } from "@root/shared/input/input.component";
import { PostfixEmailInputComponent } from "@root/shared/postfix-email-input/postfix-email-input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { getControlInFormGroup, updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { LoadingOverlayComponent } from "../../../../../../../shared/loading-overlay/loading-overlay.component";
import { PostfixTextInputComponent } from "../../../../../../../shared/postfix-text-input/postfix-text-input.component";
import { SelectFieldComponent } from "../../../../../../../shared/select-field/select-field.component";

@Component({
  selector: "est-options",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    TranslateModule,
    MatButtonToggle,
    MatSlideToggle,
    InputComponent,
    SelectFieldComponent,
    LoadingOverlayComponent,
    ReactiveFormsModule,
    PostfixEmailInputComponent,
    PostfixTextInputComponent,
  ],
  templateUrl: "./options.component.html",
  styleUrl: "./options.component.scss",
})
export class OptionsComponent implements OnInit {
  readonly #formBuilder = inject(NonNullableFormBuilder);
  readonly #organisationService = inject(OrganizationService);

  isPageLoading = false;
  brandingTemplates = [];
  organisationCommunicationSettingsFG: FormGroup = this.#buildForm();

  ngOnInit(): void {
    this.#getOrganisationCommunicationSettings();
  }

  #buildForm(): FormGroup {
    return this.#formBuilder.group(
      {
        allowSettingChangesOnLevelsBelow: this.#formBuilder.control(false),
        defaultEmailSenderName: this.#formBuilder.control(""),
        defaultFromEmailAddress: this.#formBuilder.control(""),
        defaultReplyToEmail: this.#formBuilder.control("", [
          Validators.email,
          Validators.pattern(REGEX_PATTERNS.email),
        ]),
        brandingTemplate: this.#formBuilder.control(0),
        activateTextMessagesNotifications: this.#formBuilder.control(false),
        defaultMessageSenderName: this.#formBuilder.control(""),
      },
      { updateOn: "change" },
    );
  }

  #getOrganisationCommunicationSettings() {
    this.isPageLoading = true;
    this.#organisationService.getOrganisationCommunicationSettings().subscribe({
      next: (orgCommunicationSettings) => {
        this.organisationCommunicationSettingsFG.patchValue(orgCommunicationSettings);
        this.isPageLoading = false;
      },
      error: () => {
        this.isPageLoading = false;
      },
    });
  }

  getControl(controlName: string): FormControl<any> {
    return getControlInFormGroup(this.organisationCommunicationSettingsFG, controlName);
  }

  #sendPatchRequest(): void {
    if (this.organisationCommunicationSettingsFG.invalid) return;
    const controlPaths = updateNestedControlsPathAndValue(this.organisationCommunicationSettingsFG);
    !!Object.keys(controlPaths).length &&
      this.#organisationService.patchOrganisationCommunicationSettings(controlPaths).subscribe();
  }

  formPatched() {
    this.#sendPatchRequest();
  }
}
