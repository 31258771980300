import { Routes } from "@angular/router";
import { FacilityPackageComponent } from "@root/views/main/property/property-marketing/components/facilities/facility-package/facility-package.component";
import { PropertyFacilitiesComponent } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/property-facilities.component";

export const facilitiesRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "property-facilities",
  },
  {
    path: "property-facilities",
    component: PropertyFacilitiesComponent,
    data: {
      name: "PROPERTY_FACILITIES.NAME",
    },
  },
  {
    path: "facility-package",
    component: FacilityPackageComponent,
    data: {
      name: "PROPERTY_FACILITIES.FACILITIES.FACILITY_PACKAGE",
    },
  },
];
