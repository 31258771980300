import { DivisionCompaniesComponent } from "./division-companies/division-companies.component";
import { DivisionCustomisationComponent } from "./division-customisation/division-customisation.component";
import { DivisionDetailsComponent } from "./division-details/division-details.component";
import { Routes } from "@angular/router";
import { DivisionPropertiesComponent } from "./division-properties/division-properties.component";
import { DivisionDocumentsComponent } from "./division-documents/division-documents.component";
import { DivisionSystemModulesComponent } from "./division-system-modules/division-system-modules.component";
import { DivisionSettingsComponent } from "./division-settings/division-settings.component";

export const divisionRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "details",
  },
  {
    path: "details",
    component: DivisionDetailsComponent,
    data: {
      name: "DETAILS.NAME",
    },
  },
  {
    path: "customisation",
    component: DivisionCustomisationComponent,
    data: {
      name: "CUSTOMISATION.NAME",
    },
  },
  {
    path: "companies",
    component: DivisionCompaniesComponent,
    data: {
      name: "COMPANIES.NAME",
    },
  },
  {
    path: "properties",
    component: DivisionPropertiesComponent,
    data: {
      name: "PROPERTIES.NAME",
    },
  },
  {
    path: "documents",
    component: DivisionDocumentsComponent,
    data: {
      name: "DOCUMENTS.NAME",
    },
  },
  {
    path: "system-modules",
    component: DivisionSystemModulesComponent,
    data: {
      name: "SYSTEM_MODULES.NAME",
    },
  },
  {
    path: "settings",
    component: DivisionSettingsComponent,
    data: {
      name: "SETTINGS.NAME",
    },
  },
];
