<div class="text-end pe-6">
  <est-search-input [searchSignal]="searchSignal"></est-search-input>
</div>
<est-small-list-table
  [data]="paginatedData.results()"
  [headers]="headers"
  [sortingFilterSignal]="sortingFilterSignal"
  [checkboxControlMapper]="controlMapper()"
  [radioButtonControl]="mangerId()"
  [isLoading]="isTableLoading"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>

@if (isSelectedTeamMangerError()) {
  <mat-error class="!text-error-200 text-md font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ "TEAMS.SELECT_TEAM_MANAGER" | translate }} {{ "VALIDATION.IS_REQUIRED" | translate }}
  </mat-error>
}
