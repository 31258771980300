import { Component, inject } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InputComponent } from "@root/shared/input/input.component";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "est-companies-overview",
  standalone: true,
  imports: [ButtonComponent, InputComponent],
  templateUrl: "./companies-overview.component.html",
  styleUrl: "./companies-overview.component.scss",
})
export class CompaniesOverviewComponent {
  companyId = new FormControl();
  router = inject(Router);
  navigateToCompanyById() {
    this.router.navigate([`companies/${this.companyId.value}`]);
  }
}
