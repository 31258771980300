<div class="px-6 pt-4 pb-20 relative">
  <p class="mb-4 font-medium text-xl">{{ "CHANGE_ORDER" | translate }}</p>
  <hr class="absolute inset-x-0 border-black-100" />
  <p class="mt-12 text-sm font-light mb-6">
    {{ "CREATE_PROPERTY_MEDIA_PACKAGE.CHANGE_ORDER_OF_MEDIAS_CONFIRMATION" | translate }}
  </p>
  <p class="text-sm font-light mb-6">
    {{ "CREATE_PROPERTY_MEDIA_PACKAGE.PACKAGE_IS_LINKED" | translate: { units: componentInputs.linkedUnits } }}
  </p>
  <p class="text-sm font-light mb-1">
    {{ "CREATE_PROPERTY_MEDIA_PACKAGE.SELECT_WHAT_HAPPENS_WITH_ORDER" | translate }}
  </p>
  <mat-radio-group class="text-sm font-light">
    <div class="flex flex-col">
      <mat-radio-button>{{ "CREATE_PROPERTY_MEDIA_PACKAGE.OVERWRITE_SORT_ORDER" | translate }}</mat-radio-button>
      <mat-radio-button>{{ "CREATE_PROPERTY_MEDIA_PACKAGE.DO_NOT_CHANGE_SORT_ORDER" | translate }}</mat-radio-button>
    </div>
  </mat-radio-group>
  <div class="dialog__footer">
    <est-button
      (click)="cancel()"
      buttonStyleType="button--tertiary"
      buttonType="button"
      label="COLD_FEET"
    ></est-button>
    <est-button (click)="confirm()" buttonStyleType="button--primary" buttonType="button" label="IM_SURE"></est-button>
  </div>
</div>
