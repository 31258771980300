import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { OrganizationService } from "@root/data/market/organisation/services/organisation.service";
import { appendStylingData } from "@root/data/market/organisation/utilities/organization.utilities";
import { UpdateLayoutServices } from "@root/data/market/update-layout/update-layout.service";
import { ColorPickerInputComponent } from "@root/shared/color-picker-input/color-picker-input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { UploadLogoBrandComponent } from "@root/shared/upload-logo-brand/upload-logo-brand.component";
import { StylingFormControls } from "./styling.form-controls";

@Component({
  selector: "est-styling",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SmallHeaderComponent,
    TranslateModule,
    MatSlideToggle,
    UploadLogoBrandComponent,
    ColorPickerInputComponent,
  ],
  templateUrl: "./styling.component.html",
  styleUrl: "./styling.component.scss",
})
export class StylingComponent implements OnInit {
  #organizationService = inject(OrganizationService);
  destroyRef = inject(DestroyRef);
  stylingFormControls = inject(StylingFormControls);

  #updateLayoutServices = inject(UpdateLayoutServices);

  ngOnInit(): void {
    this.#organizationService.getOrganizationStyling(1).subscribe((data) => {
      this.stylingFormControls.organizationFormGroup.controls.stylingDetailsDTO.patchValue(data);
    });
    this.setImages();
  }

  setImages() {
    this.stylingFormControls.stylingDetails.controls.logoUrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.stylingFormControls.organizationFormGroup.controls.logo.setValue(data);
      });
    this.stylingFormControls.stylingDetails.controls.headerImageMobileUrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.stylingFormControls.organizationFormGroup.controls.headerImageMobile.setValue(data);
      });
    this.stylingFormControls.stylingDetails.controls.headerImageDesktopTabletUrl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.stylingFormControls.organizationFormGroup.controls.headerImageDesktopTablet.setValue(data);
      });
  }

  updateStyling() {
    const stylingDetails = appendStylingData(this.stylingFormControls.organizationFormGroup);
    this.#organizationService.updateOrganizationStyling(stylingDetails).subscribe(() => {
      this.#updateLayoutServices.organizationLogo$.next();
    });
  }
}
