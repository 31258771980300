<p class="mb-2 text-sm font-medium">{{ "VISIBLE_ON_END_USER_SERVICE" | translate }}</p>
<mat-radio-group
  id="end-user-visibility-input"
  [formControl]="control()"
  (change)="selectionChanged.emit($event.value)"
  class="flex flex-col gap-2 font-light text-sm"
>
  <mat-radio-button [value]="true">{{ "YES" | translate }}</mat-radio-button>
  <mat-radio-button [value]="false">{{ "NO" | translate }}</mat-radio-button>
</mat-radio-group>
