import { inject, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { InternalFloor } from "@root/data/market/units/enums/internal-floor.enum";
import { mergeFormGroups } from "@root/shared/utilities/form.utilities";
import { RestroomType } from "@root/data/market/units/enums/restroom-type.enum";
import { IUnitLayout } from "@root/data/market/units/models/unit-layout.model";
import { Orientation } from "@root/data/market/units/enums/orientation.enum";
import { IBedroom } from "@root/data/market/rooms/models/bedroom.model";
import { IRoom } from "@root/data/market/rooms/models/room.model";
import { ILivingRoom } from "@root/data/market/rooms/models/living-room.model";
import { IAdditionalRoom } from "@root/data/market/rooms/models/additional-room.model";
import { IBathroom } from "@root/data/market/rooms/models/bathroom.model";
import { IInternalStorageRoom } from "@root/data/market/rooms/models/internal-storage-room.model";
import { IGarden } from "@root/data/market/rooms/models/garden.model";
import { IBalcony } from "@root/data/market/rooms/models/balcony.model";
import { ITerrace } from "@root/data/market/rooms/models/terrace.model";
import { NullableBoolean } from "@root/shared/type/nullable-boolean.type";

@Injectable({
  providedIn: "root",
})
export class UnitLayoutFormControls {
  readonly #fb = inject(FormBuilder);

  constructRoom(room?: IRoom): FormGroup {
    return this.#fb.group({
      id: this.#fb.nonNullable.control(room?.id ?? 0),
      area: this.#fb.control(room?.area ?? null, { updateOn: "blur", validators: [Validators.min(1)] }),
      roomNameId: this.#fb.control(room?.roomName?.id ?? null),
      showRoomOrientation: this.#fb.nonNullable.control(room?.showRoomOrientation ?? false),
      roomOrientation: this.#fb.control(room?.roomOrientation ?? null),
    });
  }

  constructBedroom(bedroom?: IBedroom): FormGroup {
    const bedroomFormGroup = this.#fb.group({
      builtInCloset: this.#fb.control(bedroom?.builtInCloset ?? null),
      directAccessRestroom: this.#fb.control(bedroom?.directAccessRestroom ?? null),
      directAccessOutside: this.#fb.control(bedroom?.directAccessOutside ?? null),
      location: this.#fb.control(bedroom?.location ?? InternalFloor.First),
    });
    return mergeFormGroups(this.constructRoom(bedroom), bedroomFormGroup);
  }

  constructLivingRoom(livingRoom?: ILivingRoom): FormGroup {
    const livingRoomFormGroup = this.#fb.group({
      location: this.#fb.control(livingRoom?.location ?? InternalFloor.First),
    });
    return mergeFormGroups(this.constructRoom(livingRoom), livingRoomFormGroup);
  }

  constructAdditionalRoom(additionalRoom?: IAdditionalRoom): FormGroup {
    const additionalRoomFormGroup = this.#fb.group({
      location: this.#fb.control(additionalRoom?.location ?? InternalFloor.First),
    });
    return mergeFormGroups(this.constructRoom(additionalRoom), additionalRoomFormGroup);
  }

  constructRestroom(restroom?: IBathroom): FormGroup {
    const restroomFormGroup = this.#fb.group({
      restroomType: this.#fb.nonNullable.control(restroom?.restroomType ?? RestroomType.Restroom),
      mirror: this.#fb.control(restroom?.mirror ?? null),
      storageUnderSink: this.#fb.control(restroom?.storageUnderSink ?? null),
      wideEntrance: this.#fb.control(restroom?.wideEntrance ?? null),
      stepFreeAccess: this.#fb.control(restroom?.stepFreeAccess ?? null),
      towelHooks: this.#fb.control(restroom?.towelHooks ?? null),
      toiletGrabBar: this.#fb.control(restroom?.toiletGrabBar ?? null),
      connectedRoomId: this.#fb.control(restroom?.connectedRoom?.id ?? null),
      shower: this.#fb.control(restroom?.shower ?? null),
      bathtub: this.#fb.control(restroom?.bathtub ?? null),
      mirrorWithLight: this.#fb.control(restroom?.mirrorWithLight ?? null),
      towelRack: this.#fb.control(restroom?.towelRack ?? null),
      toiletPaperHolder: this.#fb.control(restroom?.toilerPaperHolder ?? null),
      bidet: this.#fb.control(restroom?.bidet ?? null),
      showerGrabBar: this.#fb.control(restroom?.showerGrabBar ?? null),
      stepFreeShower: this.#fb.control(restroom?.stepFreeShower ?? null),
      bathChair: this.#fb.control(restroom?.bathChair ?? null),
    });
    return mergeFormGroups(restroomFormGroup, this.constructRoom(restroom));
  }

  constructInternalStorageRoom(internalStorageRoom?: IInternalStorageRoom): FormGroup {
    const internalStorageRoomFormGroup = this.#fb.group({
      connectedRoomId: this.#fb.control(internalStorageRoom?.connectedRoom?.id ?? null),
    });
    return mergeFormGroups(this.constructRoom(internalStorageRoom), internalStorageRoomFormGroup);
  }

  constructGarden(garden?: IGarden): FormGroup {
    const gardenFormGroup = this.#fb.group({
      stepFreeAccess: this.#fb.control(garden?.stepFreeAccess ?? null),
      wideEntrance: this.#fb.control(garden?.wideEntrance ?? null),
      fenced: this.#fb.control(garden?.fenced ?? null),
      hedge: this.#fb.control(garden?.hedge ?? null),
      connectedRoomId: this.#fb.control(garden?.connectedRoom?.id ?? null),
    });
    return mergeFormGroups(this.constructRoom(garden), gardenFormGroup);
  }

  constructBalcony(balcony?: IBalcony): FormGroup {
    const balconyFormGroup = this.#fb.group({
      stepFreeAccess: this.#fb.control(balcony?.stepFreeAccess ?? null),
      wideEntrance: this.#fb.control(balcony?.wideEntrance ?? null),
      balconyTypeId: this.#fb.control(balcony?.balconyType?.id ?? null),
      connectedRoomId: this.#fb.control(balcony?.connectedRoom?.id ?? null),
    });
    return mergeFormGroups(this.constructRoom(balcony), balconyFormGroup);
  }

  constructTerrace(terrace?: ITerrace): FormGroup {
    const terraceFormGroup = this.#fb.group({
      stepFreeAccess: this.#fb.control(terrace?.stepFreeAccess ?? null),
      wideEntrance: this.#fb.control(terrace?.wideEntrance ?? null),
      terraceTypeId: this.#fb.control(terrace?.terraceType?.id ?? null),
      connectedRoomId: this.#fb.control(terrace?.connectedRoom?.id ?? null),
    });
    return mergeFormGroups(this.constructRoom(terrace), terraceFormGroup);
  }

  constructUnitLayout(unitLayout: IUnitLayout): FormGroup {
    return this.#fb.group(
      {
        orientation: this.#fb.control<Orientation | null>(unitLayout.unitOrientation),
        internalFloors: this.#fb.nonNullable.control(unitLayout.internalFloors),
        area: this.#fb.nonNullable.control(unitLayout.area, {
          updateOn: "blur",
          validators: [Validators.required, Validators.min(1)],
        }),
        registeredArea: this.#fb.control<number | null>(unitLayout.registeredArea, {
          updateOn: "blur",
          validators: [Validators.min(1)],
        }),
        plotArea: this.#fb.control<number | null>(unitLayout.plotArea, {
          updateOn: "blur",
          validators: [Validators.min(1)],
        }),
        commonArea: this.#fb.control<number | null>(unitLayout.commonArea, {
          updateOn: "blur",
          validators: [Validators.min(1)],
        }),
        internalArea: this.#fb.control<number | null>(unitLayout.internalArea, {
          updateOn: "blur",
          validators: [Validators.min(1)],
        }),
        handicapAccessible: this.#fb.nonNullable.control(unitLayout.handicapAccessible),
        handicapAccessibleVisible: this.#fb.nonNullable.control(unitLayout.handicapAccessibleVisible),
        stepFreeEntrance: this.#fb.control<NullableBoolean>(unitLayout.stepFreeEntrance),
        stepFreeEntrancePath: this.#fb.control<NullableBoolean>(unitLayout.stepFreeEntrance),
        wideEntrance: this.#fb.control<NullableBoolean>(unitLayout.wideEntrance),
        parkingAccessible: this.#fb.control<NullableBoolean>(unitLayout.parkingAccessible),
        stepFreeBedroomEntrance: this.#fb.control<NullableBoolean>(unitLayout.stepFreeBedroomEntrance),
        wideBedroomEntrance: this.#fb.control<NullableBoolean>(unitLayout.wideBedroomEntrance),
        restroomGrabBar: this.#fb.control<NullableBoolean>(unitLayout.restroomGrabBar),
        showerChair: this.#fb.control<NullableBoolean>(unitLayout.showerChair),
        stepFreeShower: this.#fb.control<NullableBoolean>(unitLayout.stepFreeShower),
        stepFreeBathroomEntrance: this.#fb.control<NullableBoolean>(unitLayout.stepFreeBathroomEntrance),
        wideBathroomEntrance: this.#fb.control<NullableBoolean>(unitLayout.wideBathroomEntrance),
        bathroomGrabBar: this.#fb.control<NullableBoolean>(unitLayout.bathroomGrabBar),
        studentFriendly: this.#fb.nonNullable.control(unitLayout.studentFriendly),
        studentFriendlyVisible: this.#fb.nonNullable.control(unitLayout.studentFriendlyVisible),
        seniorFriendly: this.#fb.nonNullable.control(unitLayout.seniorFriendly),
        seniorFriendlyVisible: this.#fb.nonNullable.control(unitLayout.seniorFriendlyVisible),
        shareFriendly: this.#fb.nonNullable.control(unitLayout.shareFriendly),
        shareFriendlyVisible: this.#fb.nonNullable.control(unitLayout.shareFriendlyVisible),
        roomQuantity: this.#fb.nonNullable.control(unitLayout.roomQuantity, [Validators.required]),
        bedroomQuantity: this.#fb.nonNullable.control(unitLayout.bedroomQuantity),
        bedroomsVisible: this.#fb.nonNullable.control(unitLayout.bedroomsVisible),
        bedrooms: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.bedrooms.map((bedroom) => this.constructBedroom(bedroom)),
        ),
        livingRoomQuantity: this.#fb.nonNullable.control(unitLayout.livingRoomQuantity),
        livingRoomsVisible: this.#fb.nonNullable.control(unitLayout.livingRoomsVisible),
        livingRooms: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.livingRooms.map((livingRoom) => this.constructLivingRoom(livingRoom)),
        ),
        additionalRoomQuantity: this.#fb.nonNullable.control(unitLayout.additionalRoomQuantity),
        additionalRoomsVisible: this.#fb.nonNullable.control(unitLayout.additionalRoomsVisible),
        additionalRooms: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.additionalRooms.map((additionalRoom) => this.constructAdditionalRoom(additionalRoom)),
        ),
        restroomQuantity: this.#fb.nonNullable.control(unitLayout.restroomQuantity),
        restroomsVisible: this.#fb.nonNullable.control(unitLayout.restroomsVisible),
        restrooms: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.restrooms.map((restroom) => this.constructRestroom(restroom)),
        ),
        internalStorageRoomQuantity: this.#fb.nonNullable.control(unitLayout.internalStorageRoomQuantity),
        internalStorageRoomsVisible: this.#fb.nonNullable.control(unitLayout.internalStorageRoomsVisible),
        internalStorageRooms: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.internalStorageRooms.map((internalStorageRoom) =>
            this.constructInternalStorageRoom(internalStorageRoom),
          ),
        ),
        gardenQuantity: this.#fb.nonNullable.control(unitLayout.gardenQuantity),
        gardensVisible: this.#fb.nonNullable.control(unitLayout.gardensVisible),
        gardens: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.gardens.map((garden) => this.constructGarden(garden)),
        ),
        terraceQuantity: this.#fb.nonNullable.control(unitLayout.terraceQuantity),
        terracesVisible: this.#fb.nonNullable.control(unitLayout.terracesVisible),
        terraces: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.terraces.map((terrace) => this.constructTerrace(terrace)),
        ),
        balconyQuantity: this.#fb.nonNullable.control(unitLayout.balconyQuantity),
        balconiesVisible: this.#fb.nonNullable.control(unitLayout.balconiesVisible),
        balconies: this.#fb.nonNullable.array<FormGroup>(
          unitLayout.balconies.map((balcony) => this.constructBalcony(balcony)),
        ),
      },
      { updateOn: "change" },
    );
  }
}
