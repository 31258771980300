<form (focusout)="patchChangedValues()" [formGroup]="formGroup">
  @if (isLoading) {
    <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
  } @else {
    <div class="pb-6">
      <est-small-header
        heading="SETTINGS.SYSTEM_CONFIGURATIONS.DEFAULT_SYSTEM_LANGUAGE.TITLE"
        subheading="SETTINGS.SYSTEM_CONFIGURATIONS.DEFAULT_SYSTEM_LANGUAGE.DESCRIPTION"
        tooltip="SETTINGS.SYSTEM_CONFIGURATIONS.DEFAULT_SYSTEM_LANGUAGE.TITLE"
      ></est-small-header>
      <est-language-dropdown
        appliedClasses="mt-4 w-[20.375rem]"
        [selectWithLanguageId]="true"
        [countries]="languageOptions"
        [selectMultipleOptions]="false"
        [control]="defaultSystemLanguage"
        (selectionChanged)="patchChangedValues()"
      ></est-language-dropdown>
    </div>

    <hr class="border-black-100 absolute start-0 end-0" />

    <div class="py-6">
      <est-small-header
        heading="SETTINGS.SYSTEM_CONFIGURATIONS.DEFAULT_END_USER_LANGUAGE.TITLE"
        subheading="SETTINGS.SYSTEM_CONFIGURATIONS.DEFAULT_END_USER_LANGUAGE.DESCRIPTION"
        tooltip="SETTINGS.SYSTEM_CONFIGURATIONS.DEFAULT_END_USER_LANGUAGE.TITLE"
      ></est-small-header>
      <est-language-dropdown
        appliedClasses="mt-4 w-[20.375rem]"
        [selectWithLanguageId]="true"
        [countries]="languageOptions"
        [selectMultipleOptions]="false"
        [control]="defaultEndUserLanguage"
        (selectionChanged)="patchChangedValues()"
      ></est-language-dropdown>
    </div>

    <hr class="border-black-100 absolute start-0 end-0" />

    <div class="pt-6">
      <est-small-header
        heading="SETTINGS.SYSTEM_CONFIGURATIONS.OTHER_LANGUAGES.TITLE"
        subheading="SETTINGS.SYSTEM_CONFIGURATIONS.OTHER_LANGUAGES.DESCRIPTION"
        tooltip="SETTINGS.SYSTEM_CONFIGURATIONS.OTHER_LANGUAGES.TITLE"
      ></est-small-header>

      <div class="flex flex-col gap-4 mt-6">
        @for (otherLanguage of otherLanguages; track otherLanguage) {
          <est-additional-language-entry
            (deleteLanguage)="removeAllowedLanguageFromList($event)"
            [otherLanguage]="otherLanguage"
          ></est-additional-language-entry>
        }
        <est-button
          (click)="openAddLanguagesPopup()"
          buttonType="button"
          buttonStyleType="button--secondary"
          label="SETTINGS.SYSTEM_CONFIGURATIONS.OTHER_LANGUAGES.ADD_LANGUAGE"
          imagePosition="before"
          imageSrc="assets/images/icons/plus-black.svg"
        ></est-button>
      </div>
    </div>
  }
</form>
