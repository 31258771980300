import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-property-events",
  standalone: true,
  imports: [UnderDevelopmentComponent, NavigationTabsComponent, RouterOutlet, SubheaderComponent],
  templateUrl: "./property-events.component.html",
  styleUrl: "./property-events.component.scss",
})
export class PropertyEventsComponent {
  protected tabs: INavigationTab[] = [
    {
      routerLink: "all",
      label: "ALL",
    },
    {
      routerLink: "upcoming-events",
      label: "EVENTS.UPCOMING_EVENTS",
    },
    {
      routerLink: "Previous-events",
      label: "EVENTS.PREVIOUS_EVENTS",
    },
    {
      routerLink: "archived-events",
      label: "EVENTS.ARCHIVED_EVENTS",
    },
    {
      routerLink: "draft-events",
      label: "EVENTS.DRAFT_EVENTS",
    },
  ];
}
