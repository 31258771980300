import { inject, Injectable } from "@angular/core";
import { SystemConfigurationsRepository } from "@root/data/market/system-configurations/repositories/system-configurations.repository";
import { Observable } from "rxjs";
import { ISystemLanguageConfiguration } from "@root/data/market/system-configurations/language/models/system-language-configuration.model";

@Injectable({
  providedIn: "root",
})
export class SystemConfigurationsService {
  readonly #systemConfigurationsRepository = inject(SystemConfigurationsRepository);

  getLanguageSystemConfiguration(): Observable<ISystemLanguageConfiguration> {
    return this.#systemConfigurationsRepository.getSystemLanguageConfiguration();
  }

  patchSystemLanguageConfiguration(body: Partial<ISystemLanguageConfiguration>): Observable<void> {
    return this.#systemConfigurationsRepository.patchSystemLanguageConfiguration(body);
  }

  toggleSystemLanguageConfigurationAllowedLanguageActiveInEndUser(languageId: string): Observable<void> {
    return this.#systemConfigurationsRepository.toggleSystemLanguageConfigurationAllowedLanguageActiveInEndUser(
      languageId,
    );
  }

  deleteAllowedLanguage(allowedLanguageId: number): Observable<void> {
    return this.#systemConfigurationsRepository.deleteAllowedLanguage(allowedLanguageId);
  }

  addAllowedLanguages(languageIds: string[]): Observable<void> {
    return this.#systemConfigurationsRepository.addAllowedLanguages(languageIds);
  }
}
