<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    [name]="'Jesper Thorkildsen'"
    label="DETAILS.NAME"
    tooltip="DETAILS.NAME"
  ></est-subheader>

  @if (!isPageLoading) {
    <form [formGroup]="divisionFormGroup">
      <div (focusout)="updateDivisionDetails()">
        <p class="font-medium text-base pb-4 pt-10">{{ "DIVISIONS.DIVISION" | translate }}</p>
        <div class="flex flex-wrap gap-4">
          <est-input label="DIVISIONS.FORM.NAME" appliedClass="w-[15rem]" [control]="nameControl"></est-input>
          <est-datepicker label="CREATED" [control]="createdOnControl" appliedClasses="w-[18.75rem]"></est-datepicker>
          <est-input
            label="DIVISIONS.FORM.DIVISION_OWNER"
            appliedClass="w-[15rem]"
            [control]="ownerControl"
          ></est-input>
        </div>
      </div>
      <div class="absolute start-0 end-0">
        <hr class="mt-6 border-black-100" />
      </div>
      <div class="pt-10">
        <p class="font-medium text-base pb-4">{{ "DIVISIONS.FORM.DIVISION_DETAILS" | translate }}</p>
        <est-address
          [formGroup]="divisionAddressFormGroup"
          [lookups]="addressLookupsOptions"
          [isLoadingSignal]="isAddressLoadingSignal"
          [formattedAddressSignal]="formattedAddressSignal"
          (selectedValueChange)="patchAddressFields($event)"
        ></est-address>
        <div class="flex flex-wrap mb-4" (focusout)="updateDivisionDetails()">
          <est-input
            appliedClass="mr-4 w-[18.75rem] max-breakpoint-small-desktop-screen:mb-4"
            [control]="contactPersonNameControl"
            [label]="'CONTACT_PERSON'"
          ></est-input>
          <est-input
            appliedClass="mr-4 w-[18.75rem] "
            [control]="contactPersonEmailControl"
            label="CONTACT_EMAIL"
            [type]="'email'"
          ></est-input>
          <est-phone-input [control]="phoneControl"></est-phone-input>
        </div>
        <est-input
          (focusout)="updateDivisionDetails()"
          appliedClass="w-[18.75rem]"
          [control]="websiteUrlControl"
          label="WEBSITE"
        ></est-input>
      </div>
      <div class="absolute start-0 end-0">
        <hr class="mt-6 border-black-100" />
      </div>
      <div class="pt-10">
        <div class="w-1/2">
          <p class="font-medium text-base pb-4">{{ "DIVISIONS.FORM.DOMAIN" | translate }}</p>
          <p class="text-secondary text-sm">{{ "DIVISIONS.DOMAIN_DESCRIPTION" | translate }}</p>
          <div class="flex gap-x-4 my-4">
            <est-input
              label=""
              placeholder=".estatetool.io"
              appliedClass="w-[15rem]"
              [control]="domainControl"
            ></est-input>
            <est-button
              appliedClasses="uppercase"
              label="DIVISIONS.CHANGE_DOMAIN"
              buttonStyleType="button--primary"
              buttonType="button"
              (click)="changeDomain()"
            ></est-button>
          </div>
        </div>
      </div>
    </form>
  } @else {
    <div class="py-32 relative">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</div>
