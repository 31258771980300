import { Component, input } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { INavigationTab } from "src/app/shared/navigation-tabs/navigation-tab.interface";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { MatChipsModule } from "@angular/material/chips";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NgClass } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AuthorizedRoleDirective } from "../../core/directives/authorized-role.directive";

@Component({
  selector: "est-navigation-tabs",
  standalone: true,
  imports: [
    MatTabsModule,
    RouterLink,
    RouterOutlet,
    MatChipsModule,
    MatButtonToggleModule,
    NgClass,
    RouterLinkActive,
    TranslateModule,
    AuthorizedRoleDirective,
  ],
  templateUrl: "./navigation-tabs.component.html",
  styleUrl: "./navigation-tabs.component.scss",
})
export class NavigationTabsComponent {
  tabs = input.required({
    transform: (value: INavigationTab[]) =>
      value.map((tap: INavigationTab) => {
        return { ...tap, label: tap.label.toUpperCase() };
      }),
  });
}
