import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-unit-status",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./unit-status.component.html",
  styleUrl: "./unit-status.component.scss",
})
export class UnitStatusComponent {}
