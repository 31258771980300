<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <div class="flex">
    <est-input label="COMPANY_ID" [control]="companyId" appliedClass="!w-[18rem] !me-2"></est-input>
    <est-button
      label="NAVIGATE_TO_COMPANY"
      buttonStyleType="button--primary"
      buttonType="button"
      (click)="navigateToCompanyById()"
    ></est-button>
  </div>
</div>
