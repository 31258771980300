<div [ngClass]="appliedClass()" class="w-[18.75rem]" id="postfix-input">
  <div class="flex h-full w-full">
    <mat-form-field
      [attr.aria-labelledby]="ariaLabel() | translate"
      [attr.aria-required]="isRequired"
      appearance="outline"
      class="w-full"
      hideRequiredMarker
      id="postfix-text-input"
      subscriptSizing="dynamic"
    >
      @if (label()) {
        <mat-label>{{ label() | translate }} {{ addRequiredToLabel() | translate }}</mat-label>
      }
      <input
        (focusout)="emitFocusOut($event)"
        [errorStateMatcher]="matcher"
        [formControl]="control()"
        [min]="minValue()"
        [name]="name()"
        [placeholder]="placeholder() | translate"
        [type]="type()"
        matInput
      />
    </mat-form-field>
    <div
      [innerHTML]="postfixText() ? (postfixText()! | translate) : currencyPaymentFrequencyText"
      [ngClass]="{ '!border-error-200': control().touched && control().dirty && control().invalid }"
      class="flex justify-center items-center border-y border-e px-4 border-black-300 rounded-r-lg bg-black-100 font-semibold text-sm whitespace-nowrap"
      id="postfix-text"
    ></div>
  </div>

  @if (control().touched && control().dirty && control().invalid) {
    @if (control().errors?.["required"]) {
      <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
        <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
        {{ label() | translate }} {{ "VALIDATION.IS_REQUIRED" | translate }}
      </mat-error>
    }
    @if (control().errors?.["max"]) {
      <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
        <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
        {{ label() | translate }} {{ "VALIDATION.INVALID_VALUE" | translate }}
      </mat-error>
    }
    @if (applyNegativeValuesValidation() && control().errors?.["min"]) {
      <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
        <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
        {{ label() | translate }} {{ "VALIDATION.NEGATIVE_VALUE" | translate }}
      </mat-error>
    }
  }
</div>
