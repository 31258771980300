import { Component, input } from "@angular/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-creation-fail-message",
  standalone: true,
  imports: [CustomDatePipe, NgOptimizedImage, TranslateModule],
  templateUrl: "./creation-fail-message.component.html",
  styleUrl: "./creation-fail-message.component.scss",
})
export class CreationFailMessageComponent {
  mainTitle = input.required<string>();
  mainDescription = input.required<string>();
  secondaryTitle = input.required<string>();
  secondaryDescription = input.required<string>();
}
