import { inject, Injectable } from "@angular/core";
import { PetsRepository } from "@root/data/market/pets/repositories/pets.repository";
import { Observable } from "rxjs";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { mapNamedEntitiesToDropdownOptions } from "@root/data/market/shared/utilities/named-entities.utilities";
import { IPetTypePolicy } from "@root/data/market/pets/models/pet-type-policy.model";
import { INamedEntity } from "@root/shared/interfaces/named-entity";

@Injectable({
  providedIn: "root",
})
export class PetsService {
  readonly #petsRepository = inject(PetsRepository);

  getAllPetTypes(): Observable<IDropdownOption[]> {
    return this.#petsRepository.getAllPetTypes().pipe(mapNamedEntitiesToDropdownOptions());
  }

  getPetPolicies(unitId: string): Observable<IPetTypePolicy[]> {
    return this.#petsRepository.getPetPolicies(unitId);
  }

  getPetTypeSpecies(search: string, petTypeId: number): Observable<IDropdownOption[]> {
    return this.#petsRepository.getPetTypeSpecies(search, petTypeId).pipe(mapNamedEntitiesToDropdownOptions());
  }

  getNotAllowedSpeciesIds(notAllowedSpecies: INamedEntity[]): number[] {
    return notAllowedSpecies.map((species) => species.id);
  }
}
