<form>
  <div class="flex items-center justify-between p-6">
    <div class="flex gap-2 items-center">
      <p class="text-xl font-medium">{{linkedDialogTitle() | translate }}</p>
      <est-tooltip
        [tooltip]="isEdit ? 'LINKED_UNITS.EDIT_LINK.NAME':'LINKED_UNITS.CREATE_LINK.NAME' | translate"
      ></est-tooltip>
    </div>
    <est-button
      label="LINKED_UNITS.CHANGE_TO_HIERARCHY_VIEW"
      buttonStyleType="button--secondary"
      buttonType="button"
      imageSrc="assets/images/icons/hierarchy-icon.svg"
      imagePosition="after"
      imageAlt="HIERARCHY_ICON"
    ></est-button>
  </div>
  <mat-progress-bar [value]="getProgressbarValue()"></mat-progress-bar>
  <div class="relative px-6 pt-6">
    @if (isUnitsLoading) {
    <est-loading-overlay class="py-32 relative"></est-loading-overlay>
    }
    <div class="max-h-[calc(100dvh-14rem)] overflow-y-auto pb-8 relative">
      <mat-stepper #stepper animationDuration="0" [selectedIndex]="getSelectedIndex()">
        <!-- Step 1 selecting Divisions -->
        <mat-step [stepControl]="selectedDivisionsFC">
          <p class="text-base font-medium pb-6">{{ "LINKED_DIVISIONS.SELECT_DIVISIONS" | translate }}</p>
          <p
            class="text-sm font-light pb-6"
            [innerHTML]="'LINKED_DIVISIONS.CREATE_LINK.SELECT_DIVISIONS_DESCRIPTION' | translate "
          ></p>
          <est-linked-divisions-table
            [linkingSource]="linkingSource"
            [stepIndex]="stepper.selectedIndex"
            [isEdit]="isEdit"
            (updateLinkDivisionsIds)="updateDivisionsIds($event)"
          ></est-linked-divisions-table>
        </mat-step>

        <!-- Step 2 selecting Companies -->
        <mat-step [stepControl]="selectedCompaniesFC">
          <p class="text-base font-medium pb-6">{{ "LINKED_COMPANIES.SELECT_COMPANIES" | translate }}</p>
          <p
            class="text-sm font-light pb-6"
            [innerHTML]="'LINKED_COMPANIES.CREATE_LINK.SELECT_COMPANIES_DESCRIPTION' | translate "
          ></p>

          <est-linked-companies-table
            [linkingSource]="linkingSource"
            [divisionIds]="divisionIdsSignal()"
            [stepIndex]="stepper.selectedIndex"
            [isEdit]="isEdit"
            (updateLinkCompaniesIds)="updateCompaniesIds($event)"
          ></est-linked-companies-table>
        </mat-step>

        <!-- Step 3 selecting Properties -->
        <mat-step [stepControl]="selectedPropertiesFC">
          <p class="text-base font-medium pb-6">{{ "LINKED_PROPERTIES.SELECT_PROPERTIES" | translate }}</p>
          <p
            class="text-sm font-light pb-6"
            [innerHTML]="'LINKED_PROPERTIES.CREATE_LINK.SELECT_PROPERTIES_DESCRIPTION' | translate"
          ></p>

          <est-linked-properties-table
            [linkingSource]="linkingSource"
            [companyIds]="companyIdsSignal()"
            [stepIndex]="stepper.selectedIndex"
            [isEdit]="isEdit"
            (updateLinkPropertiesIds)="updatePropertiesIds($event)"
          ></est-linked-properties-table>
        </mat-step>

        <!-- Step 4 selecting Units -->
        <mat-step [stepControl]="selectedUnitsFC">
          <p class="text-base font-medium pb-6">{{ "LINKED_UNITS.SELECT_UNITS" | translate }}</p>
          <p class="text-sm font-light pb-6">{{ "LINKED_UNITS.CREATE_LINK.SELECT_UNITS_DESCRIPTION" | translate }}</p>

          <est-linked-units-table
            [linkingSource]="linkingSource"
            [stepIndex]="stepper.selectedIndex"
            [propertyIds]="propertyIdsSignal()"
            [packageId]="componentInputs.packageId"
            [isEdit]="isEdit"
            [(selectAllOperation)]="selectAllUnitsOperationSignal"
            [(addedUnitIds)]="addedUnitIds"
            [(removedUnitIds)]="removedUnitIds"
          ></est-linked-units-table>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
  <div class="py-3 bg-black-50 flex border border-black-100 px-6 justify-between">
    @if(isPreviousButtonShown(stepper)){
    <est-button buttonStyleType="button--secondary" (click)="stepper.previous()" buttonType="button" label="BACK" />
    }@else{
    <est-button buttonStyleType="button--secondary" (click)="matDialog.closeAll()" buttonType="button" label="CANCEL" />
    }
    <!-- submit or next btns -->
    @if (isLastStep(stepper)) {
    <est-button
      [disabled]="isStepButtonDisabled(stepper.selectedIndex)"
      buttonStyleType="button--primary"
      buttonType="submit"
      [label]="isEdit? 'LINKED_UNITS.EDIT_LINK.UPDATE_LINK' : 'LINKED_UNITS.CREATE_LINK.LINK_TO_SELECTED_UNITS'"
      (click)="submitForm()"
    />
    } @else {
    <est-button
      [disabled]="isStepButtonDisabled(stepper.selectedIndex)"
      buttonStyleType="button--primary"
      buttonType="button"
      label="NEXT"
      (click)=" nextStep()"
    />
    }
  </div>
</form>
