import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-facilities",
  standalone: true,
  imports: [UnderDevelopmentComponent, SubheaderComponent, NavigationTabsComponent, RouterOutlet],
  templateUrl: "./facilities.component.html",
  styleUrl: "./facilities.component.scss",
})
export class FacilitiesComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "PROPERTY_FACILITIES.NAME",
      routerLink: "property-facilities",
    },
    {
      label: "PROPERTY_FACILITIES.FACILITIES.FACILITY_PACKAGE",
      routerLink: "facility-package",
    },
  ];
}
