<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem]">
  <est-subheader
    date="Mon Feb 19 2024 14:20:06 GMT+0200"
    label="PROPERTY_FACILITIES.FACILITIES.NAME"
    name="Jesper Thorkildsen"
    tooltip="PROPERTY_FACILITIES.FACILITIES.NAME"
  ></est-subheader>
  <div>
    <div class="mt-12">
      <est-navigation-tabs [tabs]="tabs"></est-navigation-tabs>
      <hr class="border-black-100 absolute start-0 end-0" />
      <div class="mt-10">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
