import { Component, ElementRef, inject, input, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { NgClass } from "@angular/common";
import { IUserReplacementComponentInputs } from "../../user-replacement-component-inputs.interface";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatOption } from "@angular/material/autocomplete";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { BaseCompositeSelectSearchFieldComponent } from "@root/shared/abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { isElementScrolledToTheBottom } from "@root/shared/utilities/element-poistion.utilities";
import { NonNullableFormBuilder, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "est-user-replacement",
  standalone: true,
  imports: [
    TranslateModule,
    SearchInputComponent,
    MatDialogModule,
    MatTooltip,
    MatRadioButton,
    NgClass,
    ButtonComponent,
    MatOption,
    SpinnerComponent,
    ReactiveFormsModule,
    MatRadioGroup,
  ],
  templateUrl: "./user-replacement.component.html",
  styleUrl: "./user-replacement.component.scss",
})
export class UserReplacementComponent extends BaseCompositeSelectSearchFieldComponent {
  readonly componentInputs: IUserReplacementComponentInputs = inject(MAT_DIALOG_DATA);
  userId: number = 0;
  divisionId = input<number | undefined>();
  readonly #lookupsService = inject(LookupsService);
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #dialogRef: MatDialogRef<UserReplacementComponent> = inject(MatDialogRef);
  selectReplaceUser = this.#fb.control<number | null>(null);
  form = this.#fb.group({
    userId: this.selectReplaceUser,
  });
  protected cancel = (userId?: number | null) => this.#dialogRef.close({ replaceId: userId });
  @ViewChild("containerUi") containerUi!: ElementRef<HTMLElement>;
  replacementUser() {
    this.cancel(this.selectReplaceUser.value);
  }
  scrollEnd() {
    if (this.isLoading) return;
    if (
      this.paginatedOptions.totalPages() &&
      this.paginatedOptions.currentPage() >= this.paginatedOptions.totalPages()!
    ) {
      return;
    }
    if (isElementScrolledToTheBottom(this.containerUi.nativeElement, 52)) {
      this.isLoading = true;
      const nextPageIndex = this.paginatedOptions.currentPage() + 1;
      this.loadOptions(nextPageIndex);
    }
  }

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#lookupsService.getUsersLookUpsInDivision({
      search: this.searchSignal(),
      pageNumber,
      pageSize: this.paginatedOptions.pageSize(),
      divisionId: this.componentInputs.divisionId,
      ...(this.control().value && { selectedId: this.control().value }),
    });
  }
}
