import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder } from "@angular/forms";

@Injectable()
export class PropertyTermsAndConditionsFormControls {
  readonly #formBuilder = inject(NonNullableFormBuilder);
  isPetAllowed = this.#formBuilder.control<boolean>(false);
  isUnitChangeAllowed = this.#formBuilder.control<boolean>(false);
  numberOfPetsAllowed = this.#formBuilder.control<number>(0);

  petPolicyFormGroup = this.#formBuilder.group({
    isPetAllowed: this.isPetAllowed,
    isUnitChangeAllowed: this.isUnitChangeAllowed,
    numberOfPetsAllowed: this.numberOfPetsAllowed,
  });
}
