import { Component } from "@angular/core";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-property-documents",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet, SubheaderComponent],
  templateUrl: "./property-documents.component.html",
  styleUrl: "./property-documents.component.scss",
})
export class PropertyDocumentsComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "DOCUMENTS.NAME",
      routerLink: "documents",
    },
    {
      label: "DOCUMENTS.DOCUMENT_PACKAGES.NAME",
      routerLink: "document-packages",
    },
    {
      label: "DOCUMENTS.SIGNING_PACKAGES.NAME",
      routerLink: "signing-packages",
    },
  ];
}
