import { CurrencyFormatConverterPipe } from "@root/shared/pipe/currency-format-converter.pipe";

const currencyConverter = new CurrencyFormatConverterPipe();
export const calculateBaseRentFromExchangeRate = (exchangeRate: number, rent: number, currencyIso: string) => {
  const rentNumber = parseFloat(rent.toString().replace(/,/g, ""));
  const calculatedBaseRent = rentNumber * exchangeRate;
  return currencyConverter.transform(calculatedBaseRent, currencyIso);
};

export const calculateConversionRate = (exchangeRate: number, currencyIso: string, defaultCurrency: string): string => {
  return `(${currencyConverter.transform(1000, defaultCurrency, 0)} ${defaultCurrency} = ${currencyConverter.transform(1000 * exchangeRate, currencyIso)} ${currencyIso})`;
};

export const convertCurrencyToIsoFormat = (payload: any) => {
  return Object.keys(payload).reduce(
    (acc, key) => {
      if (typeof payload[key] === "number" && payload[key] !== 0) {
        acc[key] = currencyConverter.transform(payload[key] as number, payload.defaultCurrency.iso);
      } else {
        acc[key] = payload[key];
      }
      return acc;
    },
    {} as typeof payload,
  );
};
