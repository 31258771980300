import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UnderDevelopmentComponent } from "../../../../shared/under-development/under-development.component";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-organization-system-modules",
  standalone: true,
  imports: [TranslateModule, UnderDevelopmentComponent, NavigationTabsComponent, RouterOutlet, SubheaderComponent],
  templateUrl: "./organization-system-modules.component.html",
  styleUrl: "./organization-system-modules.component.scss",
})
export class OrganizationSystemModulesComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "SYSTEM_MODULES.CUSTOMER_FUNNEL",
      routerLink: "customerFunnel",
    },
    {
      label: "SYSTEM_MODULES.SIGNING",
      routerLink: "signing",
    },
    {
      label: "SYSTEM_MODULES.SCREENING",
      routerLink: "screening",
    },
    {
      label: "SYSTEM_MODULES.CUSTOMER_PAGE.NAME",
      routerLink: "customerPage",
    },
    {
      label: "SYSTEM_MODULES.UNIT_SELECTOR",
      routerLink: "unitSelector",
    },
    {
      label: "SYSTEM_MODULES.MY_PAGE",
      routerLink: "myPage",
    },
    {
      label: "SYSTEM_MODULES.CONTRACT",
      routerLink: "contract",
    },
    {
      label: "SYSTEM_MODULES.COMPLIANCE",
      routerLink: "compliance",
    },
  ];
}
