import { Component, inject, OnInit } from "@angular/core";
import { MatDivider } from "@angular/material/divider";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialogActions, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { InputComponent } from "@root/shared/input/input.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { BaseSystemAdminForm } from "@root/shared/abstracts/system-admin-form/system-admin-form.abstract";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { UsersService } from "@root/data/market/users/services/users.service";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { VALIDATION_ERROR_KEY } from "@root/shared/constants/error-keys.constants";

@Component({
  selector: "est-create-system-admin",
  standalone: true,
  imports: [
    MatDivider,
    InputComponent,
    CommonModule,
    ReactiveFormsModule,
    LanguageDropdownComponent,
    SelectFieldComponent,
    NgOptimizedImage,
    PhoneInputComponent,
    TranslateModule,
    MatDialogClose,
    MatDialogActions,
    SelectSearchFieldComponent,
    ButtonComponent,
  ],
  templateUrl: "./create-system-admin.component.html",
  styleUrl: "./create-system-admin.component.scss",
})
export class CreateSystemAdminComponent extends BaseSystemAdminForm implements OnInit {
  readonly #dialogRef = inject(MatDialogRef);
  readonly #usersService = inject(UsersService);
  readonly #snackbarService = inject(SnackbarService);

  ngOnInit() {
    this.retrieveSystemLanguages();
  }

  retrieveSystemLanguages(): void {
    this.#usersService.getAllLanguages().subscribe((languages: ILanguage[]) => {
      this.languages = languages;
    });
  }

  createSystemAdmin() {
    if (this.systemAdminForm.invalid) {
      this.systemAdminForm.markAllAsTouched();
      Object.keys(this.systemAdminForm.controls).forEach((key: string) => {
        const control = this.systemAdminForm.get(key);
        control && control.markAsDirty();
      });
    } else {
      this.isLoading = true;
      this.#usersService.createNewUser(this.systemAdminForm, true).subscribe({
        next: () => {
          this.isLoading = false;
          this.#dialogRef.close();
          this.#snackbarService.open(SnackbarType.Success);
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          if (error.error?.ValidationErrors) {
            error.error.ValidationErrors.forEach((validationError: any) => {
              const { Field, Errors } = validationError;
              const formControl = this.systemAdminForm.get(Field.toLowerCase());
              if (formControl && Errors.length > 0) {
                formControl.setErrors({ [VALIDATION_ERROR_KEY]: Errors[0] });
              }
            });
          }
        },
      });
    }
  }
}
