import { Component, effect, EffectRef, inject, input, signal, WritableSignal } from "@angular/core";
import { BasePaginatedTableWithSearchComponent } from "../abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableHeader } from "../interfaces/table-header.interface";
import { ITableSortingFilter } from "../interfaces/table-sorting-filter.interface";
import { createTeamTableHeaders } from "./create-team-table.headers";
import { copyPaginatedSignalResponse } from "../utilities/signals.utilities";
import { SmallListTableComponent } from "../small-list-table/small-list-table.component";
import { SearchInputComponent } from "../search-input/search-input.component";
import { PaginationComponent } from "../pagination/pagination.component";
import { FormControl } from "@angular/forms";
import { UsersService } from "../../data/market/users/services/users.service";
import { MatError } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { IPaginationSortPayload } from "../interfaces/pagination-sort-payload.interface";
import { UserStatus } from "@root/data/market/users/enums/user-status.enum";
@Component({
  selector: "est-team-member-table",
  standalone: true,
  imports: [SmallListTableComponent, SearchInputComponent, PaginationComponent, MatError, MatIcon, TranslateModule],
  templateUrl: "./team-member-table.component.html",
  styleUrl: "./team-member-table.component.scss",
})
export class TeamMemberTableComponent extends BasePaginatedTableWithSearchComponent {
  divisionId = input<number | undefined>(undefined);
  usersService = inject(UsersService);
  mangerId = input<FormControl<number | null>>(new FormControl<number | null>(null));
  stepIndex = input<number>();
  controlMapper = input<Map<string, FormControl<any[] | null>>>(new Map([["", new FormControl<any[] | null>([])]]));
  isSelectedTeamMangerError = input<boolean>();
  headers: ITableHeader[] = createTeamTableHeaders;
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal<ITableSortingFilter>({
    sortBy: "number",
    sortDescending: false,
  });
  readonly loadDataEffect$: EffectRef = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      divisionId: this.divisionId(),
      status: UserStatus.Active,
    });
  });

  loadData(params: IPaginationSortPayload & { divisionId?: number } & { status?: UserStatus }): void {
    if (this.divisionId() && this.stepIndex() === 1) {
      this.isTableLoading = true;
      this.usersService.getAllUser(params).subscribe((paginatedUsers) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedUsers);
        this.isTableLoading = false;
      });
    }
  }
}
