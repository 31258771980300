import { Component, inject, input } from "@angular/core";
import { BaseCompositeSelectSearchFieldComponent } from "@root/shared/abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { PropertyTextsService } from "@root/data/market/properties/services/properties-texts.service";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";

@Component({
  selector: "est-select-placement-id",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./select-placement-id.component.html",
  styleUrl: "./select-placement-id.component.scss",
})
export class SelectPlacementIdComponent extends BaseCompositeSelectSearchFieldComponent {
  propertyTextService = inject(PropertyTextsService);
  label = input.required<string>();

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.propertyTextService.getTextPlacements({
      search: this.searchSignal(),
      pageNumber,
      pageSize: this.paginatedOptions.pageSize(),
      ...(this.control().value && { selectedId: this.control().value }),
    });
  }
}
