import { Component, inject, signal, WritableSignal } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { IDeleteDivisionPayload } from "@root/data/market/division/models/division-delete.model";
import { OrganizationService } from "@root/data/market/organisation/services/organisation.service";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { getControlInFormGroup } from "@root/shared/utilities/form.utilities";
import { DateNotBeforeTodayValidator } from "@root/shared/validators/date-validators";
import { OrganisationDivisionsCommunicationService } from "../services/organisation-details-communication.service";

@Component({
  selector: "est-delete-division",
  standalone: true,
  imports: [TranslateModule, DatepickerComponent, ButtonComponent, SpinnerComponent],
  templateUrl: "./delete-division.component.html",
  styleUrl: "./delete-division.component.scss",
})
export class DeleteDivisionComponent {
  componentInputs: Pick<IDeleteDivisionPayload, "divisionId"> = inject(MAT_DIALOG_DATA);
  readonly matDialog = inject(MatDialog);
  readonly #formBuilder = inject(NonNullableFormBuilder);
  readonly #organizationService = inject(OrganizationService);
  readonly #snackbarService = inject(SnackbarService);
  readonly #organisationDivisionsCommunicationService = inject(OrganisationDivisionsCommunicationService);

  isDeleteDivisionLoading: WritableSignal<boolean> = signal(false);

  getControlInFormGroup = getControlInFormGroup;

  deleteDivisionFormGroup = this.#formBuilder.group({
    divisionId: this.#formBuilder.control(this.componentInputs.divisionId),
    deletionDate: this.#formBuilder.control<string>("", [Validators.required, DateNotBeforeTodayValidator()]),
  });

  deleteDivision() {
    this.isDeleteDivisionLoading.set(true);
    this.#organizationService.deleteDivision(this.deleteDivisionFormGroup.value as IDeleteDivisionPayload).subscribe({
      next: () => {
        this.isDeleteDivisionLoading.set(false);
        this.#organisationDivisionsCommunicationService.reloadOrganisationDivisionsPage$.next();
        this.matDialog.closeAll();
        this.#snackbarService.open(SnackbarType.Success);
      },
      error: () => {
        this.isDeleteDivisionLoading.set(false);
      },
    });
  }
}
