import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ICity } from "../models/city.model";
import { APIS } from "../../../../core/api/market.apis";

@Injectable({
  providedIn: "root",
})
export class CitiesRepository {
  readonly #http = inject(HttpClient);

  getCities(search: string): Observable<ICity[]> {
    return this.#http.get<ICity[]>(APIS.cities.getAll, {
      params: new HttpParams({
        fromObject: { search },
      }),
    });
  }
}
