import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UnderDevelopmentComponent } from "../../../../../../shared/under-development/under-development.component";

@Component({
  selector: "est-property",
  standalone: true,
  imports: [TranslateModule, UnderDevelopmentComponent],
  templateUrl: "./property.component.html",
  styleUrl: "./property.component.scss",
})
export class PropertyComponent {}
