import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";

export const LIST_USERS_FOR_EVENT_HEADERS: ITableHeader[] = [
  {
    key: "assignedUsersIds",
    label: "",
    isSortable: false,
    type: TableHeaderType.Checkbox,
  },
  {
    key: "name",
    label: "AGENT_NAME",
    isSortable: true,
    type: TableHeaderType.TextWithIcon,
  },
  {
    key: "teams",
    label: "TEAMS.TEAM",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    key: "availability",
    label: "AVAILABILITY",
    isSortable: true,
    type: TableHeaderType.Text,
    needsTranslation: true,
  },
];
