import { MediaPackageNotificationMessage } from "@root/core/services/signalR/models/mediaPackageNotificationMessage";
import { Observable, Subject } from "rxjs";

export interface INotificationClientListeners {
  notificationMessage: MethodListener<MediaPackageNotificationMessage>;
}

export interface INotificationServerMethods {
  sendMessageToUser(message: string, userId: string): Observable<void>;
  broadcastMessage(message: string): Observable<void>;
  packageDuplicatedAsync(message: MediaPackageNotificationMessage): Observable<void>;
}

export class MethodListener<T> {
  subject: Subject<T>;
  methodName: string;

  static listeners: MethodListener<any>[] = [];

  constructor(methodName: string) {
    this.methodName = methodName;
    this.subject = new Subject<T>();
    MethodListener.listeners.push(this);
  }
}
