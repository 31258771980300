import { Component, EventEmitter, input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-end-user-visibility-input",
  standalone: true,
  imports: [MatRadioGroup, MatRadioButton, TranslateModule, ReactiveFormsModule],
  templateUrl: "./end-user-visibility-input.component.html",
  styleUrl: "./end-user-visibility-input.component.scss",
})
export class EndUserVisibilityInputComponent {
  control = input.required<FormControl<boolean>>();
  @Output() selectionChanged = new EventEmitter<boolean>();
}
