import { Routes } from "@angular/router";
import { DivisionCompaniesComponent } from "@root/views/main/division/division-companies/division-companies.component";
import { CompaniesOverviewComponent } from "@root/views/main/companies/companies-overview/companies-overview.component";

export const companiesRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "overview",
  },
  {
    path: "overview",
    component: DivisionCompaniesComponent,
    data: {
      name: "OVERVIEW",
    },
  },
  {
    path: "menu2",
    component: CompaniesOverviewComponent,
    data: {
      name: "COMPANIES.COMPANY",
    },
  },
];
