<est-stepper-navigation
  [buttonsLabels]="IMPORT_DATA_NAVIGATION_LABELS"
  [selectedIndex]="stepper.selectedIndex"
></est-stepper-navigation>
<form (ngSubmit)="moveToNextStepIfValid()" class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative pb-24">
  <mat-stepper #stepper animationDuration="0">
    <mat-step [stepControl]="firstStepControl">
      <est-subheader
        date="Mon Feb 19 2024 14:20:06 GMT+0200"
        label="TOOLBOX.IMPORT_DATA.IMPORT_TYPE"
        name="Jesper Thorkildsen"
        tooltip="TOOLBOX.IMPORT_DATA.IMPORT_TYPE"
      ></est-subheader>
      <est-small-header
        appliedClasses="mt-12"
        heading="TOOLBOX.IMPORT_DATA.SELECT_IMPORT_TYPE_TITLE"
        subheading="TOOLBOX.IMPORT_DATA.SELECT_IMPORT_TYPE_DESCRIPTION"
        tooltip="TOOLBOX.IMPORT_DATA.SELECT_IMPORT_TYPE_TITLE"
      ></est-small-header>
      <p class="font-light mt-3 text-sm mb-6">{{ "TOOLBOX.IMPORT_DATA.SELECT_TYPE_OF_DATA" | translate }}</p>
      <div class="flex gap-2 mb-4">
        <p class="font-medium">{{ "TOOLBOX.IMPORT_DATA.IMPORT_TYPE" | translate }}</p>
        <est-tooltip tooltip="TOOLBOX.IMPORT_DATA.IMPORT_TYPE"></est-tooltip>
      </div>
      <est-select-field
        [control]="importTypeControl"
        [dropDownOptions]="IMPORT_TYPES_OPTIONS"
        [selectMultipleOptions]="false"
        appliedClass="!w-[18.75rem] mb-6"
        dropDownLabel="TOOLBOX.IMPORT_DATA.SELECT_IMPORT_TYPE_TITLE"
      ></est-select-field>
      <div class="flex gap-2 mb-4">
        <p class="font-medium">{{ "TOOLBOX.IMPORT_DATA.UNIT_TYPE" | translate }}</p>
        <est-tooltip tooltip="TOOLBOX.IMPORT_DATA.UNIT_TYPE"></est-tooltip>
      </div>
      <est-select-field
        [control]="unitTypeControl"
        [dropDownOptions]="UNIT_TYPES_OPTIONS"
        [selectMultipleOptions]="false"
        appliedClass="!w-[18.75rem] mb-6"
        dropDownLabel="TOOLBOX.IMPORT_DATA.SELECT_UNIT_TYPE"
      ></est-select-field>
      <div [ngClass]="{ hidden: !isImportTypeAndUnitTypeSelected }">
        <div class="flex gap-2 mb-4">
          <p class="font-medium">{{ "PROPERTIES.PROPERTY" | translate }}</p>
          <est-tooltip tooltip="PROPERTIES.PROPERTY"></est-tooltip>
        </div>
        <est-property-select-field #propertySelect [control]="propertyIdControl"></est-property-select-field>
      </div>
    </mat-step>
    <mat-step [stepControl]="csvFileControl">
      <est-subheader
        date="Mon Feb 19 2024 14:20:06 GMT+0200"
        label="TOOLBOX.IMPORT_DATA.UPLOAD_CSV_FILE"
        name="Jesper Thorkildsen"
        tooltip="TOOLBOX.IMPORT_DATA.UPLOAD_CSV_FILE"
      ></est-subheader>
      <est-small-header
        appliedClasses="mt-12"
        heading="TOOLBOX.IMPORT_DATA.UPLOAD_YOUR_CSV_FILE_TITLE"
        subheading="TOOLBOX.IMPORT_DATA.UPLOAD_YOUR_CSV_FILE_DESCRIPTION"
        tooltip="TOOLBOX.IMPORT_DATA.UPLOAD_YOUR_CSV_FILE_TITLE"
      ></est-small-header>
      <p class="font-light mt-3 text-sm mb-6">{{ "TOOLBOX.IMPORT_DATA.SELECT_TYPE_OF_DATA" | translate }}</p>
      <div class="flex-col gap-2 font-medium mb-12">
        <p>
          {{ "TOOLBOX.IMPORT_DATA.IMPORT_TYPE" | translate }}:
          <span class="font-light">{{ ImportTypeTranslationMapper.get(importTypeControl.value!)! | translate }}</span>
        </p>
        <p>
          {{ "TOOLBOX.IMPORT_DATA.UNIT_TYPE" | translate }}:
          <span class="font-light">{{ UnitTypeTranslationMapper.get(unitTypeControl.value!)! | translate }}</span>
        </p>
        <p>
          {{ "PROPERTIES.PROPERTY" | translate }}:
          <span class="font-light">{{ propertySelect.selectedDropdownOptionSignal()?.label }}</span>
        </p>
      </div>
      <p class="font-medium text-sm mb-4">{{ "TOOLBOX.IMPORT_DATA.UPLOAD_CSV_FILE" | translate }}</p>
      <est-file-upload
        (fileUpload)="destroyDataTables()"
        [control]="csvFileControl"
        [supportedTypes]="[FileExtension.CSV, FileExtension.XLSX]"
        appliedClass="mb-6"
      ></est-file-upload>
      <p class="font-medium text-sm mb-4">{{ "TOOLBOX.IMPORT_DATA.NEED_HELP_WITH_IMPORT" | translate }}</p>
      <p class="font-light text-sm mb-6">{{ "TOOLBOX.IMPORT_DATA.DOWNLOAD_TEMPLATE_MSG" | translate }}</p>
      <est-button
        (click)="downloadTemplate()"
        buttonStyleType="button--secondary"
        buttonType="button"
        imageAlt="TOOLBOX.IMPORT_DATA.DOWNLOAD_TEMPLATE"
        imageSrc="assets/images/icons/download.svg"
        label="TOOLBOX.IMPORT_DATA.DOWNLOAD_TEMPLATE"
      ></est-button>
    </mat-step>
    <mat-step>
      @if (parsedCsvResponse) {
        <est-subheader
          date="Mon Feb 19 2024 14:20:06 GMT+0200"
          label="TOOLBOX.IMPORT_DATA.IMPORT_TYPE"
          name="Jesper Thorkildsen"
          tooltip="TOOLBOX.IMPORT_DATA.DATA_OVERVIEW"
        ></est-subheader>
        <est-small-header
          appliedClasses="mt-12"
          heading="TOOLBOX.IMPORT_DATA.DATA_IMPORTED_BY_CSV"
          subheading="TOOLBOX.IMPORT_DATA.DATA_IMPORTED_BY_CSV_MSG"
          tooltip="TOOLBOX.IMPORT_DATA.DATA_IMPORTED_BY_CSV"
        ></est-small-header>
        <div class="flex font-medium mt-4 mb-12 gap-12">
          <div class="flex-col gap-2">
            <p>
              {{ "TOOLBOX.IMPORT_DATA.IMPORT_TYPE" | translate }}:
              <span class="font-light">{{
                ImportTypeTranslationMapper.get(importTypeControl.value!)! | translate
              }}</span>
            </p>
            <p>
              {{ "TOOLBOX.IMPORT_DATA.UNIT_TYPE" | translate }}:
              <span class="font-light">{{ UnitTypeTranslationMapper.get(unitTypeControl.value!)! | translate }}</span>
            </p>
            <p>
              {{ "PROPERTIES.PROPERTY" | translate }}:
              <span class="font-light">{{ propertySelect.selectedDropdownOptionSignal()?.label }}</span>
            </p>
          </div>
          <div class="flex-col gap-2">
            <p>
              {{ "TOOLBOX.IMPORT_DATA.IDENTIFIED_UNITS" | translate }}:
              <span class="font-light">{{ parsedCsvResponse.data.length }}</span>
            </p>
            <p>
              {{ "TOOLBOX.IMPORT_DATA.RECOGNISED_COLUMNS" | translate }}:
              <span class="font-light">{{ parsedCsvResponse.recognizedColumnsNo }}</span>
            </p>
            <p>
              {{ "TOOLBOX.IMPORT_DATA.NOT_RECOGNISED_COLUMNS" | translate }}:
              <span class="font-light">{{ parsedCsvResponse.unrecognizedColumnsNo }}</span>
            </p>
          </div>
        </div>
        @if (showImportData) {
          <est-import-table
            selectMode
            [checkboxHashMap]="checkboxHashMap"
            [parsedCsvResponse]="parsedCsvResponse"
            [csvColumnToDtoFieldHashMap]="csvColumnToDtoFieldHashMap"
            [data]="csvData"
            [headers]="csvHeaders"
            [importType]="importTypeControl.value!"
            [unitType]="unitTypeControl.value!"
          ></est-import-table>
        }
      }
    </mat-step>
    <mat-step>
      @if (isImportingSuccess === undefined) {
        <est-subheader
          date="Mon Feb 19 2024 14:20:06 GMT+0200"
          label="TOOLBOX.IMPORT_DATA.FINALISE_IMPORT"
          name="Jesper Thorkildsen"
          tooltip="TOOLBOX.IMPORT_DATA.FINALISE_IMPORT"
        ></est-subheader>
        <est-small-header
          appliedClasses="mt-12"
          heading="TOOLBOX.IMPORT_DATA.QUALITY_ASSURE_DATA"
          subheading="TOOLBOX.IMPORT_DATA.QUALITY_ASSURE_DATA_MSG"
          tooltip="TOOLBOX.IMPORT_DATA.QUALITY_ASSURE_DATA"
        ></est-small-header>
      }
      @switch (isImportingSuccess) {
        @case (undefined) {
          <div class="flex font-medium mt-4 mb-12 gap-12">
            <div class="flex-col gap-2">
              <p>
                {{ "TOOLBOX.IMPORT_DATA.IMPORT_TYPE" | translate }}:
                <span class="font-light">{{
                  ImportTypeTranslationMapper.get(importTypeControl.value!)! | translate
                }}</span>
              </p>
              <p>
                {{ "TOOLBOX.IMPORT_DATA.UNIT_TYPE" | translate }}:
                <span class="font-light">{{ UnitTypeTranslationMapper.get(unitTypeControl.value!)! | translate }}</span>
              </p>
              <p>
                {{ "PROPERTIES.PROPERTY" | translate }}:
                <span class="font-light">{{ propertySelect.selectedDropdownOptionSignal()?.label }}</span>
              </p>
            </div>
            <div class="flex-col gap-2">
              <p>
                {{ "TOOLBOX.IMPORT_DATA.UNITS_WILL_BE_UPDATED" | translate }}:
                <span class="font-light">0</span>
                <span class="font-light">
                  {{ "UNITS" | translate }}
                </span>
              </p>
              <p>
                {{ "TOOLBOX.IMPORT_DATA.UNITS_WILL_BE_CREATED" | translate }}:
                <span class="font-light">{{ finalizedData.length }}</span>
                <span class="font-light">
                  {{ "UNITS" | translate }}
                </span>
              </p>
              <p>
                {{ "TOOLBOX.IMPORT_DATA.UNITS_WILL_BE_NOT_BE_MODIFIED" | translate }}:
                <span class="font-light">0</span>
                <span class="font-light">
                  {{ "UNITS" | translate }}
                </span>
              </p>
            </div>
          </div>
          @if (showFinalizeData) {
            <est-import-table
              [csvColumnToDtoFieldHashMap]="csvColumnToDtoFieldHashMap"
              [data]="finalizedData"
              [headers]="finalizedCsvHeaders"
              showStatus
            ></est-import-table>
          }
        }
        @case (true) {
          <est-creation-success-message
            [createdBy]="authService.getFullName()"
            description="TOOLBOX.IMPORT_DATA.UNITS_IMPORT_SUCCESS_MSG"
            name="TOOLBOX.IMPORT_DATA.NEW_UNITS"
            [namePrefix]="unitsImportedNo.toString()"
          ></est-creation-success-message>
        }
        @case (false) {
          <est-creation-fail-message
            mainTitle="TOOLBOX.IMPORT_FAIL_MESSAGE.TITLE"
            mainDescription="TOOLBOX.IMPORT_FAIL_MESSAGE.DESCRIPTION"
            secondaryTitle="TOOLBOX.IMPORT_FAIL_MESSAGE.SECONDARY_TITLE"
            secondaryDescription="TOOLBOX.IMPORT_FAIL_MESSAGE.SECONDARY_DESCRIPTION"
          ></est-creation-fail-message>
        }
      }
    </mat-step>
  </mat-stepper>
  @switch (isImportingSuccess) {
    @case (undefined) {
      <div
        [ngClass]="{ '!justify-end': stepper.selectedIndex === 0 }"
        class="py-3 bg-black-50 flex justify-between border border-black-100 px-6 z-10 absolute bottom-0 start-0 end-0"
      >
        @if (stepper.selectedIndex > 0) {
          <est-button
            (click)="stepper.previous()"
            [label]="'BACK'"
            buttonStyleType="button--tertiary"
            buttonType="button"
          >
          </est-button>
        }
        <est-button [isLoading]="isLoading" [label]="'NEXT'" buttonStyleType="button--primary" buttonType="submit">
        </est-button>
      </div>
    }
    @case (true) {
      <div class="py-3 bg-black-50 flex justify-end border border-black-100 px-6 z-10 absolute bottom-0 start-0 end-0">
        <est-button
          label="TOOLBOX.IMPORT_DATA.GO_TO_UNIT_LIST"
          buttonStyleType="button--primary"
          buttonType="button"
          (click)="navigateToUnitList()"
        >
        </est-button>
      </div>
    }
    @case (false) {
      <div class="py-3 bg-black-50 flex justify-end border border-black-100 px-6 z-10 absolute bottom-0 start-0 end-0">
        <est-button label="TRY_AGAIN" buttonStyleType="button--primary" buttonType="button" (click)="resetForm()">
        </est-button>
      </div>
    }
  }
</form>
