import { Component, effect, input, OnInit } from "@angular/core";
import { BasePropertyTextsComponent } from "@root/shared/abstracts/base-property-texts/base-property-texts.component";
import { PropertyTextsFormControls } from "@root/shared/abstracts/base-property-texts/property-texts.form-controls";
import { BASE_PROPERTY_TEXTS_IMPORTS } from "@root/shared/abstracts/base-property-texts/base-property-texts.imports";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TextEditorAccordionComponent } from "@root/views/main/property/property-marketing/components/texts/property-texts/text-editor-accordion/text-editor-accordion.component";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { finalize } from "rxjs";

@Component({
  selector: "est-edit-property-text-fields",
  standalone: true,
  imports: [
    BASE_PROPERTY_TEXTS_IMPORTS,
    SelectSearchFieldComponent,
    SelectFieldComponent,
    TextEditorAccordionComponent,
  ],
  templateUrl: "../../../../../../../../shared/abstracts/base-property-texts/base-property-texts.component.html",
  styleUrl: "./edit-property-text-fields.component.scss",
  providers: [PropertyTextsFormControls],
})
export class EditPropertyTextFieldsComponent extends BasePropertyTextsComponent implements OnInit {
  readonly textFieldId = input.required<number>();
  loadTextDetailsEffect$ = effect(() => {
    if (this.editTextDetailsSignal()) this.getPropertyTextDetails();
  });

  override ngOnInit() {
    this.isPageLoading = true;
    super.ngOnInit();
  }

  submitForm(): void {
    this.editTextField();
  }

  editTextField(): void {
    this.isSubmittingForm = true;
    this.propertyTextsService
      .editPropertyText(this.propertyId(), this.textFieldId(), this.createPropertyTextsFormControls.formGroup.value)
      .subscribe({
        next: () => {
          this.router.navigate(["../../"], { relativeTo: this.route });
          this.snackBarService.open(SnackbarType.Success);
        },
        error: () => (this.isSubmittingForm = false),
      });
  }

  getPropertyTextDetails(): void {
    this.isPageLoading = true;
    this.propertyTextsService
      .getTextFieldDetails(this.textFieldId())
      .pipe(finalize(() => (this.isPageLoading = false)))
      .subscribe((res) => {
        this.createPropertyTextsFormControls.formGroup.patchValue(res);
        this.createPropertyTextsFormControls.textFieldsFormArray.patchValue(res.textFieldLanguages);
        this.updateDisabledHeadlineState();
      });
  }

  updateDisabledHeadlineState() {
    this.createPropertyTextsFormControls.textFieldsFormArray.controls.forEach((formGroup) => {
      if (formGroup.get("isHeadlineEnabled")?.value === true) {
        formGroup.get("headline")?.enable();
      }
    });
  }

  override isEdit = true;
}
