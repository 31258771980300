import { Routes } from "@angular/router";
import { PropertyMediasComponent } from "@root/views/main/property/property-marketing/components/medias/property-medias/property-medias.component";
import { PropertyMediaPackagesComponent } from "@root/views/main/property/property-marketing/components/medias/property-media-packages/property-media-packages.component";

export const propertyMediaPackageRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "property-medias",
  },
  {
    path: "property-medias",
    component: PropertyMediasComponent,
    data: {
      name: "PROPERTY_MEDIAS.NAME",
    },
  },
  {
    path: "media-package",
    component: PropertyMediaPackagesComponent,
    data: {
      name: "PACKAGES.MEDIA_PACKAGES.NAME",
    },
  },
];
