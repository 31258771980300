import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "@root/core/api/market.apis";
import { IPetType } from "@root/data/market/pets/models/pet-type.model";
import { IPetTypePolicy } from "@root/data/market/pets/models/pet-type-policy.model";
import { IPetSpecies } from "@root/data/market/pets/models/pet-species.model";

@Injectable({
  providedIn: "root",
})
export class PetsRepository {
  readonly #http = inject(HttpClient);

  getAllPetTypes(): Observable<IPetType[]> {
    return this.#http.get<IPetType[]>(APIS.petTypes.getPetTypes);
  }

  getPetPolicies(unitId: string) {
    return this.#http.get<IPetTypePolicy[]>(APIS.unit.getPetPolicies, {
      params: new HttpParams({
        fromObject: { unitId },
      }),
    });
  }

  getPetTypeSpecies(search: string, petTypeId: number): Observable<IPetSpecies[]> {
    return this.#http.get<IPetSpecies[]>(APIS.petTypes.getPetTypeSpecies, {
      params: new HttpParams({
        fromObject: { petTypeId, search },
      }),
    });
  }
}
