import { inject, Injectable, WritableSignal } from "@angular/core";
import { mapLinkedUnitPackageToSignalResponseSmallTableInput } from "@root/data/market/linked/utilities/linked-package.utilities";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPatchBody } from "@root/shared/interfaces/patch-body.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { Observable } from "rxjs/internal/Observable";
import { LinkingPackagesRepository } from "../repositories/linking-packages.repository";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";

@Injectable({
  providedIn: "root",
})
export class LinkingPackagesService {
  readonly #linkingPackagesRepository = inject(LinkingPackagesRepository);

  /**
   * Generic listing for units within a single property.
   * Used for lisitng the units for create linking.
   * @param queryParams (params: IPaginationSortPayload & { propertyIds?: number[] })
   * @returns All Units within a given property
   */
  getPaginatedUnitsInProperty(
    params: IPaginationSortPayload & { propertyIds?: number[] },
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput<string>> & { selectedUnits: WritableSignal<number> }> {
    return this.#linkingPackagesRepository
      .getPaginatedUnitsInProperty(params)
      .pipe(mapLinkedUnitPackageToSignalResponseSmallTableInput());
  }

  /**
   *  Used for listing linked units in "View Linked Units" & "Change Linking"
   * @param queryParams (params: IPaginationSortPayload & { filterLinked?: boolean })
   * @param routeParams propertyId: number, packageId: number
   * @param linkingSource Enum for API hash mapper
   * @returns List of linked units within a specific property & specific package
   */
  getLinkedUnitsList(
    params: IPaginationSortPayload & { filterLinked?: boolean },
    propertyId: number,
    packageId: number,
    linkingSource: LinkingSource,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput<string>> & { selectedUnits: WritableSignal<number> }> {
    return this.#linkingPackagesRepository
      .getLinkedUnitsList(params, propertyId, packageId, linkingSource)
      .pipe(mapLinkedUnitPackageToSignalResponseSmallTableInput());
  }

  updateLinkingPackages(
    packageId: number,
    units: IPatchBody[],
    linkingSource: LinkingSource,
    propertyId: number,
    SelectAllUnits: SelectionOperation,
  ): Observable<void> {
    return this.#linkingPackagesRepository.updateLinkedUnits(
      packageId,
      units,
      linkingSource,
      propertyId,
      SelectAllUnits,
    );
  }
}
