import { Component, inject, input, OnInit } from "@angular/core";
import { InputComponent } from "@root/shared/input/input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { OtherFacilitiesFormControls } from "@root/views/main/units/unit-details/components/facilities/unit-other-facilities/other-facilities.form-controls";
import { UnitFacilitiesService } from "@root/data/market/units/services/unit-facilities.service";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-unit-other-facilities",
  standalone: true,
  imports: [InputComponent, ReactiveFormsModule, TooltipComponent, TranslateModule, LoadingOverlayComponent],
  templateUrl: "./unit-other-facilities.component.html",
  styleUrl: "./unit-other-facilities.component.scss",
})
export class UnitOtherFacilitiesComponent implements OnInit {
  unitId = input.required<string>();
  isLoading = false;
  readonly otherFacilitiesFormControls = inject(OtherFacilitiesFormControls);
  #facilityId = 0;
  readonly #unitFacilitiesService = inject(UnitFacilitiesService);
  readonly #translateService = inject(TranslateService);
  otherFacilitiesLabels = [1, 2, 3].map(
    (num) => `${this.#translateService.instant("UNIT_FACILITIES.OTHER_FACILITY")} #${num}`,
  );

  get otherFacilitiesForm() {
    return this.otherFacilitiesFormControls.form.controls;
  }

  ngOnInit() {
    this.#loadOtherFacilities();
  }

  updateOtherFacilities(): void {
    const updatedControlPaths = updateNestedControlsPathAndValue(this.otherFacilitiesFormControls.form);
    this.#unitFacilitiesService
      .updateUnitOtherFacilities(updatedControlPaths, this.unitId(), this.#facilityId)
      .subscribe();
  }

  #loadOtherFacilities(): void {
    this.isLoading = true;
    this.#unitFacilitiesService.getUnitOtherFacilities(this.unitId()).subscribe({
      next: (res) => {
        this.otherFacilitiesFormControls.form.patchValue(res);
        this.#facilityId = res.id;
        this.isLoading = false;
      },
    });
  }
}
