<est-dialog-header [label]="dialogTitle" [tooltipLabel]="dialogTitle"></est-dialog-header>
<div class="p-6 relative max-h-[47.5rem] overflow-auto">
  <p class="font-light text-sm mb-6">{{ dialogDescription | translate }}</p>
  <est-small-list-table
    (heart)="toggleFavouriteCustomer($event)"
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
  ></est-small-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
<div class="dialog__footer !sticky !justify-end">
  <est-button
    (click)="matDialog.closeAll()"
    buttonStyleType="button--primary"
    buttonType="button"
    label="CLOSE"
  ></est-button>
</div>
