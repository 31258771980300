<div class="flex gap-2 mb-4 items-center font-medium">
  <p>
    {{ "UNIT_FACILITIES.OTHER_FACILITIES" | translate }}
    <span class="text-black-500">{{ "(" + ("UNIT_FACILITIES.ONLY_USED_ON_RENTAL_AGREEMENTS" | translate) + ")" }}</span>
  </p>
  <est-tooltip tooltip="UNIT_FACILITIES.OTHER_FACILITIES"></est-tooltip>
</div>
<p class="font-light text-sm mb-4">{{ "UNIT_FACILITIES.OTHER_FACILITIES_DESCRIPTION" | translate }}</p>
<form
  (change)="updateOtherFacilities()"
  [formGroup]="otherFacilitiesFormControls.form"
  class="flex items-center gap-4 relative flex-wrap"
>
  @if (isLoading) {
    <est-loading-overlay></est-loading-overlay>
  }
  <est-input [control]="otherFacilitiesForm.otherFacility1" [label]="otherFacilitiesLabels[0]"></est-input>
  <est-input [control]="otherFacilitiesForm.otherFacility2" [label]="otherFacilitiesLabels[1]"></est-input>
  <est-input [control]="otherFacilitiesForm.otherFacility3" [label]="otherFacilitiesLabels[2]"></est-input>
</form>
