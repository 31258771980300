import { Component, inject, input, OnInit, WritableSignal } from "@angular/core";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { EventType, EventTypeTranslationMapper } from "@root/shared/enums/event-type";
import { DatepickerRangeInputComponent } from "@root/shared/datepicker-range-input/datepicker-range-input.component";
import { RouterLink } from "@angular/router";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";

@Component({
  selector: "est-event-filter",
  standalone: true,
  imports: [
    MatMenu,
    SearchInputComponent,
    SelectFieldComponent,
    MatMenuTrigger,
    NgOptimizedImage,
    MatMenuItem,
    TranslateModule,
    DatepickerRangeInputComponent,
    RouterLink,
  ],
  templateUrl: "./event-filter.component.html",
  styleUrl: "./event-filter.component.scss",
})
export class EventFilterComponent implements OnInit {
  searchSignal = input.required<WritableSignal<string>>();
  eventTypeSignal = input.required<WritableSignal<string>>();
  agentsSignal = input.required<WritableSignal<number | null>>();
  startDateSignal = input.required<WritableSignal<Date | null>>();
  endDateSignal = input.required<WritableSignal<Date | null>>();
  eventTypeControl = new FormControl();
  agentControl = new FormControl();
  isStartDateIncluded = input.required<boolean>();
  isEndDateIncluded = input.required<boolean>();
  propertyId = input.required<number>();
  eventTypeOptions: IDropdownOption[] = [
    { label: "ALL", value: "" },
    { label: EventTypeTranslationMapper.get(EventType.PublicEvent)!, value: EventType.PublicEvent },
    { label: EventTypeTranslationMapper.get(EventType.PrivateEvent)!, value: EventType.PrivateEvent },
    { label: EventTypeTranslationMapper.get(EventType.PrivateViewing)!, value: EventType.PrivateViewing },
  ];
  agentsOptions: IDropdownOption[] = [];
  lookupsService = inject(LookupsService);
  ngOnInit() {
    this.lookupsService.getAllAgentsLookup(this.propertyId()).subscribe((data) => {
      this.agentsOptions = [{ label: "ALL", value: "" }, ...data];
    });
  }
}
