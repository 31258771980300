import { inject } from "@angular/core";
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { ILanguage } from "../../interfaces/language.interface";
import { IPhoneNumber } from "../../interfaces/phone-number.interface";
import { REGEX_PATTERNS } from "../../constants/regex-patterns.constants";
import { BaseStepperComponent } from "../stepper/base-stepper.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { UsersService } from "@root/data/market/users/services/users.service";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { getControlInFormArray } from "@root/shared/utilities/form.utilities";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { areArraysEqual } from "@root/shared/utilities/array.utilities";
import { IPropertyIdAccess } from "@root/shared/list-select-properties/property-id-access.interface";
import { UpdateUserListCommunicationService } from "@root/views/main/organization/organization-settings/components/users/update-user-list-communication.service";
import { UserStatus } from "@root/data/market/users/enums/user-status.enum";

export abstract class BaseUserForm extends BaseStepperComponent {
  formBuilder = inject(NonNullableFormBuilder);
  readonly router = inject(Router);
  readonly authService = inject(AuthService);
  readonly usersService = inject(UsersService);
  readonly dialog = inject(MatDialog);
  readonly updateUserListService = inject(UpdateUserListCommunicationService);
  readonly getControlInFormArray = getControlInFormArray;
  propertiesAccessLevelHashMap = new Map<number, string>();
  propertiesAccessStatusHashMap = new Map<number, boolean>();
  paginationValues = constructInitialSignalPaginatedResponse();
  languages: ILanguage[] = [];
  roleDropdownOptions: IDropdownOption[] = [];
  teamIds: number[] = [];
  divisionIds: number[] = [];
  isPageLoading = false;
  firstNameControl = this.formBuilder.control("", Validators.required);
  lastNameControl = this.formBuilder.control("", Validators.required);
  emailControl = this.formBuilder.control("", [Validators.required, Validators.pattern(REGEX_PATTERNS.email)]);
  phoneNumberControl = new FormControl<IPhoneNumber>({ internationalNumber: "", countryCode: "DK" }, [
    Validators.required,
  ]) as FormControl<IPhoneNumber>;
  titleControl = this.formBuilder.control("", Validators.required);
  workPlaceControl = this.formBuilder.control("", Validators.required);
  systemLanguageControl = this.formBuilder.control<ILanguage | string>("", [Validators.required]);
  divisionsFormArray = new FormArray([this.constructUserDivisionRoleGroup()]);
  teamIdsControl = this.formBuilder.control<number[]>([]);
  teamIdsControlMapper = new Map<string, FormControl<any[] | null>>([["teamIds", this.teamIdsControl]]);
  imageControl = this.formBuilder.control<string | null>(null);
  status = this.formBuilder.control<UserStatus | undefined>(undefined);
  propertiesControl = this.formBuilder.control<IPropertyIdAccess[]>([]);
  userForm = this.formBuilder.group({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    phoneNumber: this.phoneNumberControl,
    title: this.titleControl,
    workPlace: this.workPlaceControl,
    systemLanguage: this.systemLanguageControl,
    divisions: this.divisionsFormArray,
    teamIds: this.teamIdsControl,
    profilePictureUrl: this.imageControl,
    properties: this.propertiesControl,
    status: this.status,
  });
  firstStepControl = this.formBuilder.group({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    email: this.emailControl,
    phoneNumber: this.phoneNumberControl,
    title: this.titleControl,
    workPlace: this.workPlaceControl,
    systemLanguage: this.systemLanguageControl,
    divisions: this.divisionsFormArray,
  });

  get dynamicDivisionIds(): number[] {
    return this.divisionsFormArray.controls.map((divisionControl) => divisionControl.get("divisionId")?.value);
  }

  constructUserDivisionRoleGroup(): FormGroup {
    return this.formBuilder.group({
      divisionId: ["", Validators.required],
      roleId: ["", Validators.required],
    });
  }

  constructExitUserDivisionRoleGroup(division: any): FormGroup {
    return this.formBuilder.group({
      divisionId: [division.divisionId, Validators.required],
      roleId: [division.roleId, Validators.required],
    });
  }

  addUserDivisionRole() {
    this.divisionsFormArray.push(this.constructUserDivisionRoleGroup());
  }

  resetForm() {
    this.matStepper.reset();
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  retrieveSystemLanguages(): void {
    this.usersService.getAllLanguages().subscribe((languages: ILanguage[]) => {
      this.languages = languages;
    });
  }

  retrieveUserRoles(): void {
    this.usersService.getAllUserRoles().subscribe((roles: IDropdownOption[]) => {
      this.roleDropdownOptions = roles.filter((role) => role.value !== 7);
    });
  }

  getDisabledDivisionIds(): number[] {
    return this.divisionsFormArray.controls
      .map((control) => control.get("divisionId")?.value)
      .filter((id) => id !== null);
  }

  updateDivisionIds() {
    // Prevents unnecessary requests in the teams table
    if (!areArraysEqual(this.divisionIds, this.dynamicDivisionIds)) this.divisionIds = this.dynamicDivisionIds;
  }

  clearPropertiesHashMaps(): void {
    this.propertiesAccessLevelHashMap.clear();
    this.propertiesAccessStatusHashMap.clear();
  }

  updateTeamIdsAndResetHashmapsIfTeamsChanged() {
    const previousTeamIds = this.teamIds;
    this.teamIds = this.teamIdsControl.value;
    if (!areArraysEqual(previousTeamIds, this.teamIds)) {
      this.clearPropertiesHashMaps();
    }
  }
}
