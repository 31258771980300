import { Component, DestroyRef, inject, input, OnInit } from "@angular/core";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { UsageTypeSelectFieldComponent } from "@root/shared/usage-type-select-field/usage-type-select-field.component";
import { generateDropdownOptionsFromNumber } from "@root/shared/utilities/dropdown-options.utilities";
import {
  UNIT_DEPOSIT_MONTHS_OPTIONS,
  UNIT_MAINTENANCE_OPTIONS,
  UNIT_NON_CANCELLATION_PERIOD_OPTIONS,
  UNIT_NOTICE_PERIOD_OPTIONS,
  UNIT_PREPAID_MONTHS_OPTIONS,
} from "@root/views/main/units/unit-details/components/unit-terms-and-conditions/unit-terms-and-conditions.constants";
import { UnitTermsAndConditionsFormControls } from "@root/views/main/units/unit-details/components/unit-terms-and-conditions/unit-terms-and-conditions.form-controls";
import { PetPolicyModalComponent } from "@root/shared/pet-policy-modal/pet-policy-modal.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UnitsService } from "@root/data/market/units/services/units.service";
import { MatDialog } from "@angular/material/dialog";
import { ICurrencyDetails } from "@root/data/market/units/models/currency-details.model";
import { IUnitTermsAndConditions } from "@root/data/market/units/models/unit-terms-and-conditions.model";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { finalize, forkJoin } from "rxjs";
import { CurrencyFieldComponent } from "@root/views/main/units/unit-details/components/economy/currency-field/currency-field.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { calculateBaseRentFromExchangeRate } from "@root/data/market/units/utilities/economy.utilities";
import { UnitListingType } from "@root/data/market/import/units/enums/unit-listing-type.enum";
import { PetsService } from "@root/data/market/pets/services/pets.service";

@Component({
  selector: "est-unit-terms-and-conditions",
  standalone: true,
  imports: [
    SubheaderComponent,
    ReactiveFormsModule,
    TooltipComponent,
    TranslateModule,
    MatRadioGroup,
    MatRadioButton,
    SelectFieldComponent,
    MatSlideToggle,
    UsageTypeSelectFieldComponent,
    CurrencyFieldComponent,
    ButtonComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./unit-terms-and-conditions.component.html",
  styleUrl: "./unit-terms-and-conditions.component.scss",
  providers: [UnitTermsAndConditionsFormControls],
})
export class UnitTermsAndConditionsComponent implements OnInit {
  unitId = input.required<string>();
  dialog = inject(MatDialog);
  isLoading = false;
  noneOption: IDropdownOption = {
    label: "NONE",
    value: 0,
  };
  unitPetOptions: IDropdownOption[] = [];
  numberOfPetsOptions: IDropdownOption[] = generateDropdownOptionsFromNumber(5, false, 1);
  selectedPetTypes: number[] = [];
  unitListingType?: string;
  baseRent: number = 0;
  currencies: ICurrencyDetails[] = [];
  protected readonly termsAndConditionsFormControls = inject(UnitTermsAndConditionsFormControls);
  protected readonly UNIT_NOTICE_PERIOD_OPTIONS = UNIT_NOTICE_PERIOD_OPTIONS;
  protected readonly UNIT_MAINTENANCE_OPTIONS = UNIT_MAINTENANCE_OPTIONS;
  protected readonly UNIT_NON_CANCELLATION_PERIOD_OPTIONS = UNIT_NON_CANCELLATION_PERIOD_OPTIONS;
  protected readonly UNIT_PREPAID_MONTHS_OPTIONS = UNIT_PREPAID_MONTHS_OPTIONS;
  protected readonly UNIT_DEPOSIT_MONTHS_OPTIONS = UNIT_DEPOSIT_MONTHS_OPTIONS;
  protected readonly calculateBaseRentFromExchangeRate = calculateBaseRentFromExchangeRate;
  protected readonly UnitListingType = UnitListingType;
  readonly #unitsService = inject(UnitsService);
  readonly #petsService = inject(PetsService);
  readonly #destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.#loadData();
    this.#patchOnFormChange();
  }

  setTermsAndConditions(termsAndConditions: IUnitTermsAndConditions) {
    this.baseRent = termsAndConditions.rentalAgreementsTerms.baseRent;
    this.unitListingType = termsAndConditions.rentalAgreementsTerms.unitListingType;
    this.termsAndConditionsFormControls.patchValue(termsAndConditions);

    if (termsAndConditions.petPolicy !== null) {
      this.selectedPetTypes = termsAndConditions.petPolicy.petTypes.map((petType) => petType.id);
      this.termsAndConditionsFormControls.petPolicyVisibleControl.setValue(
        termsAndConditions.petPolicy.isEndUserVisible,
      );
    }
  }

  updateUnitTermsAndConditions() {
    const controlPaths = updateNestedControlsPathAndValue(
      this.termsAndConditionsFormControls.termsAndConditionsFormGroup,
      true,
    );

    if (Object.keys(controlPaths).length > 0) {
      this.#unitsService.updateUnitTermsAndConditions(this.unitId(), controlPaths).subscribe();
    }
  }

  togglePetPolicyControls(checkedValue: boolean) {
    if (checkedValue) {
      this.termsAndConditionsFormControls.petPolicyAllowedControl.disable();
      this.termsAndConditionsFormControls.numberOfPetsControl.disable();
      this.termsAndConditionsFormControls.petTypesControl.disable();
      this.termsAndConditionsFormControls.petPolicyVisibleControl.disable();
    } else {
      this.termsAndConditionsFormControls.petPolicyAllowedControl.enable();
      this.termsAndConditionsFormControls.numberOfPetsControl.enable();
      this.termsAndConditionsFormControls.petTypesControl.enable();
      this.termsAndConditionsFormControls.petPolicyVisibleControl.enable();
    }
  }

  openPetPolicyDialog() {
    this.dialog.open(PetPolicyModalComponent, {
      data: {
        unitId: this.unitId(),
        changesAllowed: !this.termsAndConditionsFormControls.followPropertyPetRestrictionsControl.value,
      },
      width: "28rem",
      maxHeight: "90dvh",
    });
  }

  #loadData() {
    this.isLoading = true;

    forkJoin([
      this.#petsService.getAllPetTypes(),
      this.#unitsService.getTermsAndConditions(this.unitId()!),
      this.#unitsService.getAllCurrenciesForUnit(this.unitId()!),
    ])
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe({
        next: ([petTypes, termsAndConditions, currencies]) => {
          this.unitPetOptions = petTypes;
          this.setTermsAndConditions(termsAndConditions);
          this.currencies = currencies.availableCurrencies.filter((currency) => currency.isUsed);
        },
      });
  }

  #patchOnFormChange() {
    this.termsAndConditionsFormControls.termsAndConditionsFormGroup.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.updateUnitTermsAndConditions();
      });
  }
}
