<div class="flex flex-col w-full">
  <div class="flex justify-between items-center mb-6">
    <est-small-header
      heading="SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.NAME"
      tooltip="SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.NAME"
      subheading="SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.DESCRIPTION"
    ></est-small-header>
    <est-button
      imageSrc="assets/images/icons/plus.svg"
      imagePosition="before"
      imageAlt="+"
      buttonStyleType="button--primary"
      buttonType="button"
      label="CREATE_NEW"
      (click)="openCreatePopup()"
    ></est-button>
  </div>
  <est-small-list-table
    [actions]="actions"
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
    [showOrHideLoaderOnClickedRow]="restRow"
    [iconMenuComponentType]="ExternalTitlesPopupComponent"
    (toggleItem)="updateMarketingInheritance($event.row)"
  ></est-small-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
