import { map, OperatorFunction } from "rxjs";
import { IParseCsvResponse } from "@root/data/market/import/models/parse-csv-response.model";
import { UNITS_FIELDS_TYPES_HASHMAP } from "@root/views/main/toolbox/import-data/constants/units-fields-types.constants";

import { UnitFieldType } from "@root/data/market/import/enums/unit-field-type.enum";
import { convertDateStringIntoAnotherDateString, convertStringDateToIso } from "@root/shared/utilities/date.utilities";
import { SUPPORTED_DATE_FORMAT_FOR_DISPLAY } from "@root/shared/constants/supported-date-formats.constants";
import { IParseCsvDataValueDetails } from "@root/data/market/import/models/parse-csv-data-value-details.model";
import { isValueInDropdownOptions } from "@root/shared/utilities/dropdown-options.utilities";
import { UNIT_COLUMNS_DROPDOWN_OPTIONS_MAPPER } from "@root/views/main/toolbox/import-data/mappers/unit-columns-dropdown-options.mapper";

export const transformUnitValues = (): OperatorFunction<IParseCsvResponse, IParseCsvResponse> => {
  // Transforms each unit value according to its column type (BOOLEAN or DATE or ENUM)
  return map((res: IParseCsvResponse) => {
    res.data.forEach((row) => {
      transformCsvDataValueDetails(row.values);
    });
    return res;
  });
};

export const getMappedUnitValue = (value: string, columnName: string): string => {
  const columnType = UNITS_FIELDS_TYPES_HASHMAP.get(columnName);
  switch (columnType) {
    case UnitFieldType.Boolean:
      return (value === "1" || value.toLowerCase() === "true").toString();
    case UnitFieldType.Date:
      return convertDateStringIntoAnotherDateString(value, SUPPORTED_DATE_FORMAT_FOR_DISPLAY) || "";
    case UnitFieldType.Enum: {
      const mappedOptions = UNIT_COLUMNS_DROPDOWN_OPTIONS_MAPPER.get(columnName)!;
      if (!isValueInDropdownOptions(value, mappedOptions)) return "";
      break;
    }
    case UnitFieldType.Integer:
      if (isNaN(+value)) {
        return "";
      }
      break;
  }
  return value;
};

export const mapCsvFieldsToDtoFieldsAndConvertTypes = (
  units: { [key: string]: string }[],
  csvFieldToDtoFieldMapper: Map<string, string>,
): {
  [key: string]: any;
}[] => {
  const newUnits: { [key: string]: any }[] = [];
  units.forEach((unit) => {
    newUnits.push(constructMappedUnit(unit, csvFieldToDtoFieldMapper));
  });
  return newUnits;
};

const constructMappedUnit = (
  unit: { [key: string]: string },
  csvFieldToDtoFieldMapper: Map<string, string>,
): {
  [key: string]: any;
} => {
  const newUnit: { [key: string]: any } = {};
  for (const key in unit) {
    const mappedDtoKey = csvFieldToDtoFieldMapper.get(key);
    if (mappedDtoKey) {
      // Check if the column is mapped
      const mappedValue = convertDtoFieldDataType(mappedDtoKey, unit[key]);
      if (mappedValue !== "") newUnit[mappedDtoKey] = mappedValue; // Map the key from csv field name into the dto coming from the mapper if not left empty
    }
  }
  return newUnit;
};

const convertDtoFieldDataType = (key: string, value: string): any => {
  if (UNITS_FIELDS_TYPES_HASHMAP.get(key) === UnitFieldType.Boolean)
    return value === "1" || value.toLowerCase() === "true";
  else if (UNITS_FIELDS_TYPES_HASHMAP.get(key) === UnitFieldType.Integer && value !== "") return +value;
  else if (UNITS_FIELDS_TYPES_HASHMAP.get(key) === UnitFieldType.Date) {
    const dateIsoString = convertStringDateToIso(value);
    return dateIsoString || "";
  } else return value;
};

const transformCsvDataValueDetails = (valueDetails: IParseCsvDataValueDetails[]): void => {
  valueDetails.forEach((valueDetail) => {
    valueDetail.value = getMappedUnitValue(valueDetail.value, valueDetail.columnName);
  });
};
