import { Component, DestroyRef, effect, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { MatDialog } from "@angular/material/dialog";
import { CreateTeamFormComponent } from "./create-team-form/create-team-form.component";
import { NgOptimizedImage } from "@angular/common";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { EditTeamFormComponent } from "./edit-team-form/edit-team-form.component";
import { AssignPropertiesToTeamComponent } from "./assign-properties-to-team/assign-properties-to-team.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { teamsTableHeaders } from "@root/views/main/organization/organization-settings/components/users/teams/teams-table.headers";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { TeamsService } from "@root/data/market/teams/services/teams.service";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { TeamStatusTranslationMapper } from "@root/data/market/teams/mappers/team-status-translation.mapper";
import { TeamStatus } from "@root/data/market/teams/enums/team-status.enum";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ListTeamAssignedPropertiesComponent } from "@root/views/main/organization/organization-settings/components/users/teams/list-team-assigned-properties/list-team-assigned-properties.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TeamsCommunicationService } from "@root/views/main/organization/organization-settings/components/users/teams/teams-communication.service";

@Component({
  selector: "est-teams",
  standalone: true,
  imports: [
    TranslateModule,
    UnderDevelopmentComponent,
    NgOptimizedImage,
    SearchInputComponent,
    ButtonComponent,
    SmallHeaderComponent,
    SmallListTableComponent,
    PaginationComponent,
  ],
  templateUrl: "./teams.component.html",
  styleUrl: "./teams.component.scss",
})
export class TeamsComponent extends BasePaginatedTableWithSearchComponent implements OnInit {
  override headers = teamsTableHeaders;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  override loadDataEffect$ = effect(() => {
    this.loadTeam();
  });
  override actions: ITableAction[] = [
    {
      labelFn: () => "TEAMS.EDIT_TEAM",
      callbackFn: (team) => this.openEditTeamModal(team.id),
    },
    {
      labelFn: () => "SETTINGS.USERS.ADD_USERS_TO_TEAM",
      callbackFn: () => {},
      isVisible: (team) => team["status"] === TeamStatusTranslationMapper.get(TeamStatus.Active),
    },
    {
      labelFn: () => "SETTINGS.USERS.ASSIGN_PROPERTIES_TO_TEAM.TITLE",
      callbackFn: (team) => this.openAssignPropertiesModal(team.id),
      isVisible: (team) => team["status"] === TeamStatusTranslationMapper.get(TeamStatus.Active),
    },
    {
      labelFn: () => "TEAMS.DEACTIVATE_TEAM",
      callbackFn: (team) => this.openDeactivateDialog(team.id),
      isVisible: (team) => team["status"] === TeamStatusTranslationMapper.get(TeamStatus.Active),
    },
    {
      labelFn: () => "TEAMS.REACTIVATE_TEAM",
      callbackFn: (team) => this.openReactivateDialog(team.id),
      isVisible: (team) => team["status"] === TeamStatusTranslationMapper.get(TeamStatus.InActive),
    },
  ];
  matDialog = inject(MatDialog);
  confirmationDialog = inject(DialogService);
  isDeactivateTeamLoading: WritableSignal<boolean> = signal(false);
  isActivateTeamLoading: WritableSignal<boolean> = signal(false);
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly teamsCommunicationService = inject(TeamsCommunicationService);
  readonly #teamsService = inject(TeamsService);

  ngOnInit() {
    this.#subscribeToUpdateTeamPage$();
  }

  override loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#teamsService.getPaginatedSmallListTableInputTeams(params).subscribe((paginatedTeams) => {
      copyPaginatedSignalResponse(this.paginatedData, paginatedTeams);
      this.isTableLoading = false;
    });
  }

  isTeamPopupIconAvailable(team: ISmallListTableInput): boolean {
    return team["assignedPropertiesNo"] > 0;
  }

  openAssignedPropertiesModal(team: ISmallListTableInput): void {
    this.matDialog.open<ListTeamAssignedPropertiesComponent, { teamId: number }>(ListTeamAssignedPropertiesComponent, {
      minWidth: "65dvw",
      maxHeight: "95dvh",
      data: {
        teamId: team.id,
      },
    });
  }

  openCreateTeamModal() {
    this.matDialog.open(CreateTeamFormComponent, {
      minWidth: "65dvw",
      maxHeight: "95dvh",
    });
  }

  openEditTeamModal(teamId: number) {
    this.matDialog.open<EditTeamFormComponent, { teamId: number }>(EditTeamFormComponent, {
      minWidth: "65dvw",
      maxHeight: "95dvh",
      data: {
        teamId,
      },
    });
  }

  openAssignPropertiesModal(teamId: number) {
    this.matDialog.open<AssignPropertiesToTeamComponent, { teamId: number }>(AssignPropertiesToTeamComponent, {
      minWidth: "65dvw",
      maxHeight: "95dvh",
      data: {
        teamId,
      },
    });
  }

  openDeactivateDialog(teamId: number) {
    this.confirmationDialog.open(
      {
        tooltipLabel: "TEAMS.DIALOG.DEACTIVATE_THE_TEAM",
        title: "TEAMS.DIALOG.DEACTIVATE_THE_TEAM",
        callBack: () => this.deactivateTeam(teamId),
        submitLabel: "DEACTIVATE",
        isInputIncluded: false,
        descriptions: [
          "TEAMS.DIALOG.DEACTIVATING_THE_TEAM_REVOKE",
          "TEAMS.DIALOG.USER_KEEP_ACCESS",
          "TEAMS.DIALOG.ARE_YOU_SURE_YOU_WANT_DEACTIVATE",
        ],
        isSubmitLoading: this.isDeactivateTeamLoading,
      },
      "confirmation",
    );
  }

  deactivateTeam(teamId: number) {
    this.isDeactivateTeamLoading.set(true);
    this.#teamsService.deactivateTeam(teamId).subscribe(() => {
      this.isDeactivateTeamLoading.set(false);
      this.teamsCommunicationService.reloadTeamPage$.next();
      this.matDialog.closeAll();
    });
  }

  openReactivateDialog(teamId: number) {
    this.confirmationDialog.open(
      {
        tooltipLabel: "TEAMS.ACTIVE_DIALOG.ACTIVATE_THE_TEAM",
        title: "TEAMS.ACTIVE_DIALOG.ACTIVATE_THE_TEAM",
        callBack: () => this.reactivateTeam(teamId),
        submitLabel: "ACTIVATE",
        isInputIncluded: false,
        descriptions: ["TEAMS.ACTIVE_DIALOG.REACTIVATE_TEAM", "TEAMS.ACTIVE_DIALOG.ARE_SURE_ACTIVATE"],
        isSubmitLoading: this.isActivateTeamLoading,
      },
      "confirmation",
    );
  }

  reactivateTeam(teamId: number) {
    this.isActivateTeamLoading.set(true);
    this.#teamsService.reactivateTeam(teamId).subscribe(() => {
      this.isActivateTeamLoading.set(false);
      this.teamsCommunicationService.reloadTeamPage$.next();
      this.matDialog.closeAll();
    });
  }

  loadTeam() {
    this.loadData({
      search: this.searchSignal(),
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
    });
  }

  #subscribeToUpdateTeamPage$(): void {
    this.teamsCommunicationService.reloadTeamPage$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.loadTeam();
    });
  }
}
