import { Component } from "@angular/core";
import { INavigationTab } from "src/app/shared/navigation-tabs/navigation-tab.interface";
import { NavigationTabsComponent } from "../../../shared/navigation-tabs/navigation-tabs.component";
import { RouterModule } from "@angular/router";

@Component({
  selector: "est-division",
  standalone: true,
  imports: [NavigationTabsComponent, RouterModule],
  templateUrl: "./division.component.html",
  styleUrl: "./division.component.scss",
})
export class DivisionComponent {
  tabs: INavigationTab[] = [
    {
      label: "DETAILS.NAME",
      routerLink: "details",
    },
    {
      label: "CUSTOMISATION.NAME",
      routerLink: "customisation",
    },
    {
      label: "COMPANIES.NAME",
      routerLink: "companies",
    },
    {
      label: "PROPERTIES.NAME",
      routerLink: "properties",
    },
    {
      label: "DOCUMENTS.NAME",
      routerLink: "documents",
    },
    {
      label: "SYSTEM_MODULES.NAME",
      routerLink: "system-modules",
    },
    {
      label: "SETTINGS.NAME",
      routerLink: "settings",
    },
  ];
}
