import { inject, Injectable } from "@angular/core";
import { RoomTypeApiMapper } from "@root/data/market/rooms/mappers/room-type-api.mapper";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoomRepository<T> {
  readonly #http = inject(HttpClient);
  readonly #roomApiMap = RoomTypeApiMapper;

  create(unitLayoutId: string, roomType: RoomType): Observable<{ id: number }> {
    return this.#http.post<{ id: number }>(this.#roomApiMap.get(roomType)!.create, `"${unitLayoutId}"`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    });
  }

  delete(id: number, roomType: RoomType): Observable<void> {
    return this.#http.delete<void>(this.#roomApiMap.get(roomType)!.delete(id));
  }

  getById(id: number, roomType: RoomType): Observable<T> {
    return this.#http.get<T>(this.#roomApiMap.get(roomType)!.getById(id));
  }

  update(id: number, body: Partial<T>, roomType: RoomType): Observable<void> {
    return this.#http.put<void>(this.#roomApiMap.get(roomType)!.update(id), body);
  }
}
