import { NgClass, NgOptimizedImage, NgStyle } from "@angular/common";
import { Component, DestroyRef, HostListener, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SetCurrentDivisionService } from "@root/data/market/division/utilities/set-current-division.service";
import { UpdateLayoutServices } from "@root/data/market/update-layout/update-layout.service";
import { AuthService } from "../../data/market/auth/services/auth.service";
import { UserRole } from "../../data/market/users/enums/user-role.enum";
import { KeycloakAuthService } from "../authentication/keycloak-auth.service";
import { AuthorizedRoleDirective } from "../directives/authorized-role.directive";
import { DropdownMenuDirective } from "../directives/dropdown-menu.directive";
import { ISidebarNavigationTab } from "./sidebar-navigation-tab.interface";

@Component({
  selector: "est-layout",
  standalone: true,
  imports: [
    TranslateModule,
    MatChipsModule,
    MatToolbarModule,
    RouterOutlet,
    MatListModule,
    MatSidenavModule,
    NgOptimizedImage,
    RouterModule,
    MatIconModule,
    MatBadgeModule,
    NgStyle,
    NgClass,
    AuthorizedRoleDirective,
    DropdownMenuDirective,
    MatButtonModule,
  ],
  templateUrl: "./layout.component.html",
  styleUrl: "./layout.component.scss",
})
export class LayoutComponent {
  readonly authService = inject(AuthService);
  readonly setCurrentDivisionService = inject(SetCurrentDivisionService);
  #updateLayoutServices = inject(UpdateLayoutServices);
  organizationLogo: string = "";
  setCurrentUserDivisionRole = this.setCurrentDivisionService.setCurrentUserDivisionRole;
  destroyRef = inject(DestroyRef);

  updateOrganizationLogo = this.#updateLayoutServices.organizationLogo$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      this.authService.refreshUser();
    });

  windowBreakPoint: number = 1100;
  sidebarListItem: ISidebarNavigationTab[] = [
    {
      title: "DASHBOARD",
      routeLink: "dashboard",
      imageSrc: "assets/images/icons/dashbord-icon.svg",
      imageWidth: 16,
      imageHeight: 14,
      imageAlt: "DASHBOARD",
    },
    {
      title: "ORGANIZATION",
      routeLink: "organization",
      imageSrc: "assets/images/icons/organization-icon.svg",
      imageWidth: 16,
      imageHeight: 13,
      imageAlt: "ORGANIZATION",
      authorizedRole: UserRole.SystemAdmin,
    },
    {
      title: "DIVISIONS.DIVISION",
      routeLink: "division",
      imageSrc: "assets/images/icons/division.svg",
      imageWidth: 16,
      imageHeight: 12,
      imageAlt: "DIVISION",
      authorizedRole: UserRole.DivisionAdmin,
    },
    {
      title: "COMPANIES.NAME",
      routeLink: "companies",
      imageSrc: "assets/images/icons/companies-icon.svg",
      imageWidth: 16,
      imageHeight: 16,
      imageAlt: "COMPANIES",
      authorizedRole: UserRole.GenericUser,
    },
    {
      title: "PROPERTIES.NAME",
      routeLink: "properties",
      imageSrc: "assets/images/icons/properties-icon.svg",
      imageWidth: 16,
      imageHeight: 13,
      imageAlt: "PROPERTIES",
      authorizedRole: UserRole.GenericUser,
    },
    {
      title: "TOOLBOX.NAME",
      routeLink: "toolbox",
      imageSrc: "assets/images/icons/toolbox.svg",
      imageWidth: 16,
      imageHeight: 16,
      imageAlt: "TOOLBOX.NAME",
      authorizedRole: UserRole.DivisionAdmin,
    },
  ];
  customerFunnel: ISidebarNavigationTab[] = [
    {
      title: "MY_ACTIVITIES",
      routeLink: "",
      imageSrc: "assets/images/icons/actives-icon.svg",
      imageWidth: 16,
      imageHeight: 14,
      imageAlt: "MY_ACTIVITIES",
    },
    {
      title: "NOTIFICATIONS",
      routeLink: "",
      imageSrc: "assets/images/icons/notification-icon.svg",
      imageWidth: 18,
      imageHeight: 18,
      imageAlt: "NOTIFICATIONS",
    },
  ];
  keycloakService = inject(KeycloakAuthService);
  screenWidth: number = window.innerWidth;

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  logout() {
    this.keycloakService.logout();
  }
}
