<est-subheader
  [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
  [name]="'Jesper Thorkildsen'"
  label="SETTINGS.USERS.NAME"
  tooltip="Users tooltip"
></est-subheader>
<div class="mt-12">
  <est-navigation-tabs [tabs]="usersTabs"></est-navigation-tabs>
</div>
<div class="absolute start-0 end-0">
  <hr class="border-black-100" />
</div>
<div class="mt-12">
  <router-outlet></router-outlet>
</div>
