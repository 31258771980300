import { Component, input } from "@angular/core";
import { DatePipe } from "@angular/common";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule } from "@ngx-translate/core";
import { IPetTitle } from "@root/shared/interfaces/pet-type-title.interface";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";

@Component({
  selector: "est-pet-dialog",
  standalone: true,
  imports: [DatePipe, TooltipComponent, TranslateModule, CustomDatePipe],
  templateUrl: "./pet-dialog.component.html",
  styleUrl: "./pet-dialog.component.scss",
})
export class PetDialogComponent {
  internalTitle = input<IPetTitle>();
  externalTitles = input<IPetTitle[]>();
  lastChangedDate = input.required<string>();
}
