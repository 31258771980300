import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TooltipComponent } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { ICreatePropertyEventDraftConfirmationInputs } from "@root/shared/property-event-draft-confirmation-popup/property-event-draft-confirmation-inputs.interface";

@Component({
  selector: "est-property-event-confirmation-popup",
  standalone: true,
  imports: [TranslateModule, TooltipComponent, ButtonComponent, DialogHeaderComponent],
  templateUrl: "./property-event-draft-confirmation-popup.component.html",
  styleUrl: "./property-event-draft-confirmation-popup.component.scss",
})
export class PropertyEventDraftConfirmationPopupComponent {
  readonly matDialog = inject(MatDialog);
  readonly componentInputs: ICreatePropertyEventDraftConfirmationInputs = inject(MAT_DIALOG_DATA);
}
