import { Component, inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { PaymentFrequencySelectFieldComponent } from "@root/shared/payment-frequency-select-field/payment-frequency-select-field.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { IEditPropertyFacilityInputs } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/edit-property-facility/edit-property-facility-inputs.interface";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { PropertiesFacilitiesService } from "@root/data/market/properties/services/properties-facilities.service";
import { finalize } from "rxjs";
import { IPropertyFacility } from "@root/data/market/properties/models/property-facility.model";
import { PropertyFacilityLocationTranslationMapper } from "@root/data/market/properties/mappers/property-facility-location-translation.mapper";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { EditPropertyFacilityFormControls } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/edit-property-facility/edit-property-facility.form-controls";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PropertiesFacilitiesCommunicationService } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/properties-facilities-comminication.service";

@Component({
  selector: "est-edit-property-facility",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    TranslateModule,
    TooltipComponent,
    MatSlideToggle,
    PaymentFrequencySelectFieldComponent,
    PostfixTextInputComponent,
    ButtonComponent,
    LoadingOverlayComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: "./edit-property-facility.component.html",
  styleUrl: "./edit-property-facility.component.scss",
})
export class EditPropertyFacilityComponent implements OnInit {
  readonly componentInputs: IEditPropertyFacilityInputs = inject(MAT_DIALOG_DATA);
  readonly matDialog = inject(MatDialog);
  isDataLoading = false;
  isSubmittingForm = false;
  facilityDetails?: IPropertyFacility;
  readonly editPropertyFacilityFormControls = inject(EditPropertyFacilityFormControls);
  protected readonly PropertyFacilityLocationTranslationMapper = PropertyFacilityLocationTranslationMapper;
  readonly #propertiesFacilitiesService = inject(PropertiesFacilitiesService);
  readonly #propertiesFacilitiesCommunicationService = inject(PropertiesFacilitiesCommunicationService);

  get form() {
    return this.editPropertyFacilityFormControls.form.controls;
  }

  ngOnInit() {
    this.#loadFacilityDetails();
  }

  submitForm() {
    this.isSubmittingForm = true;
    this.#propertiesFacilitiesService
      .editPropertyFacility({
        ...this.editPropertyFacilityFormControls.form.value,
        propertyId: this.componentInputs.propertyId,
        assignedPropertyFacilityId: this.componentInputs.facilityId,
      })
      .pipe(finalize(() => (this.isSubmittingForm = false)))
      .subscribe({
        next: () => {
          this.#propertiesFacilitiesCommunicationService.propertyFacilityModified$.next();
          this.matDialog.closeAll();
        },
      });
  }

  toggleDistanceControl(showDistance: boolean | undefined): void {
    if (showDistance && this.facilityDetails?.isDistanceEnabled) {
      this.form.distance.enable();
    } else {
      this.form.distance.disable();
    }
  }

  togglePaymentControls(paymentRequiredValue: boolean): void {
    if (paymentRequiredValue) {
      this.form.paymentFrequency.enable();
      this.form.price.enable();
    } else {
      this.form.paymentFrequency.disable();
      this.form.price.disable();
    }
  }

  #loadFacilityDetails(): void {
    this.isDataLoading = true;
    this.#propertiesFacilitiesService
      .getPropertyFacilityDetails(this.componentInputs.propertyId, this.componentInputs.facilityId)
      .pipe(finalize(() => (this.isDataLoading = false)))
      .subscribe((facilityDetails) => {
        this.facilityDetails = facilityDetails;
        this.editPropertyFacilityFormControls.patchValue(facilityDetails);
        this.togglePaymentControls(facilityDetails.isPaymentRequired);
        this.toggleDistanceControl(facilityDetails.showDistance);
      });
  }
}
