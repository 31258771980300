import { Component, inject, ViewChild } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { StepperNavigationComponent } from "@root/shared/stepper-navigation/stepper-navigation.component";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { NgClass } from "@angular/common";
import { FormArray, FormBuilder, FormControl, FormsModule, Validators } from "@angular/forms";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";

import {
  ImportTypeTranslationMapper,
  UnitTypeTranslationMapper,
} from "@root/views/main/toolbox/import-data/import-data.mappers";
import { PropertySelectFieldComponent } from "@root/shared/property-select-field/property-select-field.component";
import { FileUploadComponent } from "@root/shared/file-upload/file-upload.component";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { UnitType } from "@root/data/market/import/units/enums/unit-type.enum";
import { ImportType } from "@root/data/market/import/units/enums/import-type.enum";
import { ImportService } from "@root/data/market/import/services/import.service";
import { IParseCsvResponse } from "@root/data/market/import/models/parse-csv-response.model";
import { ImportTableComponent } from "@root/views/main/toolbox/import-data/import-table/import-table.component";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";
import { IParseCsvColumn } from "@root/data/market/import/models/parse-csv-column.model";
import { IParseCsvData } from "@root/data/market/import/models/parse-csv-data.model";
import { downloadFileBlob } from "@root/shared/utilities/files.utilities";
import { finalize } from "rxjs";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { CreationFailMessageComponent } from "@root/shared/creation-fail-message/creation-fail-message.component";
import {
  IMPORT_DATA_NAVIGATION_LABELS,
  IMPORT_TYPES_OPTIONS,
  UNIT_TYPES_OPTIONS,
} from "@root/views/main/toolbox/import-data/constants/import-data.constants";
import { UNITS_FIELDS_REQUIRED_HASHMAP } from "@root/views/main/toolbox/import-data/constants/units-fields-required.constants";
import { UNITS_COLUMN_NAMES } from "@root/views/main/toolbox/import-data/constants/units-fields-names.constants";
import { MatDialog } from "@angular/material/dialog";
import { RequiredFieldsMissingComponent } from "@root/views/main/toolbox/import-data/required-fields-missing/required-fields-missing.component";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
import { Router } from "@angular/router";

@Component({
  selector: "est-import-data",
  standalone: true,
  imports: [
    UnderDevelopmentComponent,
    StepperNavigationComponent,
    MatStepper,
    MatStep,
    SubheaderComponent,
    SmallHeaderComponent,
    TranslateModule,
    TooltipComponent,
    SelectFieldComponent,
    PropertySelectFieldComponent,
    ButtonComponent,
    NgClass,
    FormsModule,
    FileUploadComponent,
    ImportTableComponent,
    CreationSuccessMessageComponent,
    CreationFailMessageComponent,
  ],
  templateUrl: "./import-data.component.html",
  styleUrl: "./import-data.component.scss",
})
export class ImportDataComponent {
  @ViewChild("stepper", { static: true }) matStepper!: MatStepper;
  isLoading = false;
  parsedCsvResponse?: IParseCsvResponse;
  isImportingSuccess?: boolean;
  unitsImportedNo = 0;
  showImportData = false; // Destroys the import table component if not shown to avoid some bugs related to material columns
  // Table inputs
  csvHeaders: ITableHeader[] = [];
  finalizedCsvHeaders: ITableHeader[] = [];
  csvData: { [key: string]: string }[] = [];
  csvColumnToDtoFieldHashMap = new Map<string, string>();
  checkboxHashMap = new Map<number, boolean>();
  showFinalizeData = false; // Same idea for the second table
  finalizedData: { [key: string]: string }[] = [];
  readonly authService = inject(AuthService);
  protected readonly FileExtension = FileExtension;
  protected readonly IMPORT_TYPES_OPTIONS = IMPORT_TYPES_OPTIONS;
  protected readonly UNIT_TYPES_OPTIONS = UNIT_TYPES_OPTIONS;
  protected readonly IMPORT_DATA_NAVIGATION_LABELS = IMPORT_DATA_NAVIGATION_LABELS;
  protected readonly ImportTypeTranslationMapper = ImportTypeTranslationMapper;
  protected readonly UnitTypeTranslationMapper = UnitTypeTranslationMapper;
  readonly #fb = inject(FormBuilder);
  readonly #router = inject(Router);
  // Form Controls
  importTypeControl: FormControl<ImportType | null> = this.#fb.control(null, [Validators.required]);
  unitTypeControl: FormControl<UnitType | null> = this.#fb.control(null, [Validators.required]);
  propertyIdControl: FormControl<number | null> = this.#fb.control(null, [Validators.required]);
  csvFileControl: FormControl<File | null> = this.#fb.control(null, [Validators.required]);
  // Step Controls
  firstStepControl = this.#fb.array([this.importTypeControl, this.unitTypeControl, this.propertyIdControl]);
  #unmappedRequiredFields: string[] = [];
  readonly #importService = inject(ImportService);
  readonly #matDialog = inject(MatDialog);

  get isImportTypeAndUnitTypeSelected(): boolean {
    return !!this.importTypeControl.value && !!this.unitTypeControl.value;
  }

  moveToNextStepIfValid(): void {
    const currentStepControl = this.matStepper.selected!.stepControl as FormArray;
    if (currentStepControl && !currentStepControl.valid) {
      markAllControlsAsTouchedAndDirty(currentStepControl);
      return;
    }
    this.#executeStepAction();
  }

  downloadTemplate() {
    this.#importService.downloadTemplate().subscribe((blob) => downloadFileBlob(blob, "Units Template"));
  }

  buildFinalizedData(): void {
    this.showFinalizeData = true;
    this.csvData.forEach((row, index) => {
      if (this.checkboxHashMap.get(index) && !this.#isRowContainingEmptyRequiredFields(row)) {
        this.finalizedData.push({
          ...row,
        });
      }
    });
    // Filter out unmapped columns
    this.finalizedCsvHeaders = this.csvHeaders.filter((header) => this.csvColumnToDtoFieldHashMap.get(header.key));
  }

  destroyDataTables(): void {
    this.showImportData = false;
    this.showFinalizeData = false;
  }

  importUnits(): void {
    this.isLoading = true;
    this.#importService
      .createUnits(
        {
          propertyId: this.propertyIdControl.value!,
          type: this.unitTypeControl.value!,
          units: this.finalizedData,
        },
        this.csvColumnToDtoFieldHashMap,
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (unitsNo) => this.#showSuccessScreen(unitsNo),
        error: () => this.#showErrorScreen(),
      });
  }

  resetForm(): void {
    this.importTypeControl.reset();
    this.csvFileControl.reset();
    this.propertyIdControl.reset();
    this.unitTypeControl.reset();
    this.isImportingSuccess = undefined;
    this.destroyDataTables();
    this.#clearTableData();
    this.matStepper.selectedIndex = 0;
  }

  navigateToUnitList(): void {
    this.#router.navigate([`properties/${this.propertyIdControl.value}/units/residential`]);
  }

  #isRowContainingEmptyRequiredFields(row: { [key: string]: string }): boolean {
    for (const rowKey in row) {
      if (
        this.csvColumnToDtoFieldHashMap.has(rowKey) &&
        UNITS_FIELDS_REQUIRED_HASHMAP.get(this.csvColumnToDtoFieldHashMap.get(rowKey)!) &&
        row[rowKey] === ""
      ) {
        return true;
      }
    }
    return false;
  }

  #showSuccessScreen(unitsNo: number): void {
    this.unitsImportedNo = unitsNo;
    this.isImportingSuccess = true;
  }

  #showErrorScreen(): void {
    this.isImportingSuccess = false;
  }

  #parseCsv(): void {
    this.isLoading = true;
    this.#importService.parseCsv(this.csvFileControl.value!).subscribe({
      next: (response) => {
        this.parsedCsvResponse = response;
        this.#setupImportTable(response);
        this.showImportData = true;
        this.isLoading = false;
        this.matStepper.selectedIndex = 2;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  #openRequiredFieldsMissingModal(): void {
    this.#matDialog.open<RequiredFieldsMissingComponent, { missingFields: string[] }>(RequiredFieldsMissingComponent, {
      width: "28rem",
      data: {
        missingFields: this.#unmappedRequiredFields,
      },
    });
  }

  #executeStepAction(): void {
    if (this.matStepper.selectedIndex === 1) {
      this.#parseCsv();
      return;
    } else if (this.matStepper.selectedIndex === 2) {
      this.finalizedData = [];
      this.#setUnmappedRequiredFields();
      if (this.#unmappedRequiredFields.length !== 0) {
        this.#openRequiredFieldsMissingModal();
        return;
      }
      this.buildFinalizedData();
    } else if (this.matStepper.selectedIndex === 3) {
      this.importUnits();
      return;
    }
    this.matStepper.next();
  }

  #setUnmappedRequiredFields(): void {
    const unmappedRequiredColumns: string[] = [];
    const mappedFields = new Set<string>(this.csvColumnToDtoFieldHashMap.values());
    for (const key in UNITS_COLUMN_NAMES) {
      const fieldName = UNITS_COLUMN_NAMES[key as keyof typeof UNITS_COLUMN_NAMES];
      if (!mappedFields.has(fieldName) && UNITS_FIELDS_REQUIRED_HASHMAP.get(fieldName)) {
        unmappedRequiredColumns.push(fieldName);
      }
    }
    this.#unmappedRequiredFields = unmappedRequiredColumns;
  }

  #setupImportTable(response: IParseCsvResponse): void {
    this.#clearTableData();
    this.#setupTableColumns(response.columnMappings);
    this.#setupTableData(response.data);
  }

  #setupTableColumns(columns: IParseCsvColumn[]): void {
    columns.forEach((column) => {
      this.csvHeaders.push({
        key: column.columnName,
        type: TableHeaderType.Text,
        isSortable: false,
        label: column.columnName,
      });
      if (column.mappedTo) {
        this.csvColumnToDtoFieldHashMap.set(column.columnName, column.mappedTo);
      }
    });
  }

  #setupTableData(data: IParseCsvData[]): void {
    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      const row = data[rowIndex];
      const tableRowObject: { [key: string]: string } = {};
      row.values.forEach((column) => {
        tableRowObject[column.columnName] = column.value;
      });
      this.csvData.push(tableRowObject);
      this.checkboxHashMap.set(rowIndex, true);
    }
  }

  #clearTableData(): void {
    this.csvHeaders = [];
    this.csvData = [];
    this.finalizedData = [];
    this.checkboxHashMap.clear();
    this.csvColumnToDtoFieldHashMap.clear();
  }
}
