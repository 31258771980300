import { inject, Injectable } from "@angular/core";
import { PropertiesFacilitiesPackagesRepository } from "@root/data/market/properties/repositories/properties-facilities-packages.repository";
import { IPropertyFacilityPackageDetails } from "@root/data/market/properties/models/property-facility-package-details.model";
import { Observable } from "rxjs";
import { IFacilityCard } from "@root/shared/interfaces/facility-card.interface";
import { mapLinkedFacilityPackageListUnitToSignalResponseSmallTableInput } from "@root/data/market/properties/utilities/properties-facilities.utilities";
import { IDuplicatePackage } from "@root/shared/interfaces/duplicate-package.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";

@Injectable({
  providedIn: "root",
})
export class PropertiesFacilitiesPackagesService {
  readonly #propertiesFacilitiesPackageRepository = inject(PropertiesFacilitiesPackagesRepository);

  createFacilityPackage(
    packageDetails: IPropertyFacilityPackageDetails,
    selectAllFacilities: SelectionOperation,
  ): Observable<void> {
    return this.#propertiesFacilitiesPackageRepository.createFacilityPackage(packageDetails, selectAllFacilities);
  }

  getFacilitiesPackageList(propertyId: number): Observable<IFacilityCard[]> {
    return this.#propertiesFacilitiesPackageRepository.getFacilitiesPackageList(propertyId);
  }

  getFacilityPackageDetails(id: number): Observable<IPropertyFacilityPackageDetails> {
    return this.#propertiesFacilitiesPackageRepository.getFacilityPackageDetails(id);
  }
  ediFacilityPackageDetails(
    id: number,
    facilityPackageDetails: { [key: string]: any },
    selectAllFacilities: SelectionOperation,
  ): Observable<void> {
    return this.#propertiesFacilitiesPackageRepository.editFacilityPackageDetails(
      id,
      facilityPackageDetails,
      selectAllFacilities,
    );
  }
  deletePackage(id: number): Observable<void> {
    return this.#propertiesFacilitiesPackageRepository.deletePackage(id);
  }

  duplicatePackagePackage(id: number, packageDetails: IDuplicatePackage): Observable<void> {
    return this.#propertiesFacilitiesPackageRepository.duplicatePackagePackage(id, packageDetails);
  }

  getPropertiesForLinking(params: IPaginationSortPayload): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#propertiesFacilitiesPackageRepository
      .getPropertiesForLinking(params)
      .pipe(mapLinkedFacilityPackageListUnitToSignalResponseSmallTableInput());
  }
}
