import { Routes } from "@angular/router";
import { UnitMainDetailsComponent } from "@root/views/main/units/unit-details/components/unit-main-details/unit-main-details.component";
import { EconomyComponent } from "@root/views/main/units/unit-details/components/economy/economy.component";
import { UnitTermsAndConditionsComponent } from "@root/views/main/units/unit-details/components/unit-terms-and-conditions/unit-terms-and-conditions.component";
import { UnitLayoutComponent } from "@root/views/main/units/unit-details/components/unit-layout/unit-layout.component";
import { FacilitiesComponent } from "@root/views/main/units/unit-details/components/facilities/facilities.component";

export const unitDetailsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "main-details",
  },
  {
    path: "main-details",
    component: UnitMainDetailsComponent,
    data: {
      name: "UNIT.UNIT_DETAILS.MAIN_DETAILS",
    },
  },
  {
    path: "economy",
    component: EconomyComponent,
    data: {
      name: "UNIT.UNIT_DETAILS.ECONOMY",
    },
  },
  {
    path: "terms-and-conditions",
    component: UnitTermsAndConditionsComponent,
    data: {
      name: "UNIT.UNIT_DETAILS.TERMS_AND_CONDITIONS",
    },
  },
  {
    path: "unit-layout",
    component: UnitLayoutComponent,
    data: {
      name: "UNIT.UNIT_DETAILS.UNIT_LAYOUT",
    },
  },
  {
    path: "facilities",
    component: FacilitiesComponent,
    data: {
      name: "UNIT.UNIT_DETAILS.FACILITIES",
    },
  },
];
