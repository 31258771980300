import { PropertyMediaPackageDuplicationType } from "@root/views/main/property/property-marketing/enums/property-media-package-duplication-type.enum";

export const PropertyMediaPackageDuplicationTypeTranslationMapper = new Map<
  PropertyMediaPackageDuplicationType,
  string
>([
  [
    PropertyMediaPackageDuplicationType.MediasAndSettings,
    "PACKAGES.MEDIA_PACKAGES.DUPLICATE_PACKAGE.MEDIAS_AND_SETTING",
  ],
  [PropertyMediaPackageDuplicationType.Media, "PACKAGES.MEDIA_PACKAGES.DUPLICATE_PACKAGE.ONLY_MEDIAS"],
]);
