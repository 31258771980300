import { Component, inject } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { Router, RouterLink } from "@angular/router";
import { FormControl } from "@angular/forms";
import { InputComponent } from "@root/shared/input/input.component";

@Component({
  selector: "est-properties-overview",
  standalone: true,
  imports: [ButtonComponent, RouterLink, InputComponent],
  templateUrl: "./properties-overview.component.html",
  styleUrl: "./properties-overview.component.scss",
})
export class PropertiesOverviewComponent {
  unitId = new FormControl();
  propertyId = new FormControl();
  router = inject(Router);
  navigateToUnitById() {
    this.router.navigate([`unit/${this.unitId.value}`]);
  }
  navigateToPropertyById() {
    this.router.navigate([`properties/${this.propertyId.value}`]);
  }
}
