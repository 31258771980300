import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-commercial",
  standalone: true,
  templateUrl: "./commercial.component.html",
  styleUrl: "./commercial.component.scss",
  imports: [UnderDevelopmentComponent],
})
export class CommercialComponent {}
