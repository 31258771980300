import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { PropertiesMediaPackagesRepository } from "../repositories/properties-media-packages.repository";
import { IPropertyMediaPackageOverview } from "../models/property-media-package-overview.interface";
import { buildPropertyMediaPackageFormData } from "@root/data/market/properties/utilities/properties-media-packages-form-data.utilities";
import { IPropertyMediaPackage } from "@root/data/market/properties/models/property-media-package.model";
import { ICreateEditPropertyMediaPackagePayload } from "@root/data/market/properties/models/create-edit-property-media-package-payload.model";

@Injectable({
  providedIn: "root",
})
export class PropertiesMediaPackagesService {
  readonly #propertiesMediaPackageRepository = inject(PropertiesMediaPackagesRepository);

  getPropertyMediaPackages(propertyId: number): Observable<IPropertyMediaPackageOverview[]> {
    return this.#propertiesMediaPackageRepository.getPropertyMediaPackages(propertyId);
  }

  createPropertyMediaPackage(
    propertyId: number,
    body: Partial<ICreateEditPropertyMediaPackagePayload>,
  ): Observable<void> {
    const formData = buildPropertyMediaPackageFormData(body);
    return this.#propertiesMediaPackageRepository.createPropertyMediaPackage(propertyId, formData);
  }

  getPropertyMediaPackageDetails(propertyId: number, packageId: number): Observable<Partial<IPropertyMediaPackage>> {
    return this.#propertiesMediaPackageRepository.getPropertyMediaPackageDetails(propertyId, packageId);
  }

  editPropertyMediaPackage(
    propertyId: number,
    packageId: number,
    body: Partial<ICreateEditPropertyMediaPackagePayload>,
  ): Observable<void> {
    const formData = buildPropertyMediaPackageFormData(body);
    return this.#propertiesMediaPackageRepository.editPropertyMediaPackage(propertyId, packageId, formData);
  }

  deletePropertyMediaPackage(propId: number, mediaPackageId: number): Observable<void> {
    return this.#propertiesMediaPackageRepository.deletePropertyMediaPackage(propId, mediaPackageId);
  }

  duplicatePropertyMediaPackage(
    propertyId: number,
    mediaPackageId: number,
    body: {
      [key: string]: any;
    },
  ): Observable<void> {
    return this.#propertiesMediaPackageRepository.duplicatePropertyMediaPackage(propertyId, mediaPackageId, body);
  }
}
