<est-subheader
  date="Mon Feb 19 2024 14:20:06 GMT+0200"
  label="SETTINGS.COMMUNICATION.NAME"
  name="Jesper Thorkildsen"
  tooltip="SETTINGS.COMMUNICATION.NAME"
></est-subheader>
<div>
  <div class="mt-12">
    <est-navigation-tabs [tabs]="communicationTabs"></est-navigation-tabs>
    <hr class="border-black-100 absolute start-0 end-0" />
    <div class="mt-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
