import { PropertyEventRepetitionEndType } from "@root/data/market/properties/enums/property-event-repetition-end-type.enum";
import { DateTime } from "luxon";
import { RepetitionCycle } from "@root/shared/enums/repitition-cycle";
import { getDateAfterWeekDaysOccurrences } from "@root/shared/utilities/date.utilities";

interface IGetEventRepetitionEndDateInput {
  repetitionEndType: PropertyEventRepetitionEndType;
  repetitionCycle: RepetitionCycle;
  endDate?: string | null;
  occurrences?: number | null;
  startDate: string;
}

interface IGetEventRepetitionStartDateInput {
  startDate: string;
  repetitionCycle: RepetitionCycle;
}

export const getEventRepetitionEndDate = (inputs: IGetEventRepetitionEndDateInput): string => {
  validateGetEventRepetitionDateInputs(inputs);
  if (inputs.repetitionEndType === PropertyEventRepetitionEndType.OnThisDate) {
    return getEventRepetitionEndDateOnThisDate(inputs);
  } else {
    return getEventRepetitionEndDateWithOccurrences(inputs);
  }
};

export const getEventRepetitionStartDate = (inputs: IGetEventRepetitionStartDateInput): string => {
  let date = DateTime.fromISO(inputs.startDate);
  // Skip days till Start of the week (Monday)
  if (inputs.repetitionCycle === RepetitionCycle.WeekDays && date.weekday > 5) {
    date = date.plus({ day: 8 - date.weekday });
  }
  return date.toFormat("DDDD");
};

const getEventRepetitionEndDateWithOccurrences = (inputs: IGetEventRepetitionEndDateInput): string => {
  let endDate = DateTime.fromISO(inputs.startDate!);

  switch (inputs.repetitionCycle) {
    case RepetitionCycle.Daily:
      endDate = endDate.plus({ days: inputs.occurrences! });
      break;
    case RepetitionCycle.Weekly:
      endDate = endDate.plus({ weeks: inputs.occurrences! });
      break;
    case RepetitionCycle.Monthly:
      endDate = endDate.plus({ months: inputs.occurrences! });
      break;
    case RepetitionCycle.WeekDays:
      endDate = getDateAfterWeekDaysOccurrences(endDate, inputs.occurrences!);
  }

  return endDate.toFormat("DDDD");
};

const getEventRepetitionEndDateOnThisDate = (inputs: IGetEventRepetitionEndDateInput): string => {
  let date = DateTime.fromISO(inputs.endDate!);
  if (date.weekday > 5 && inputs.repetitionCycle === RepetitionCycle.WeekDays) {
    date = date.minus({ day: date.weekday - 5 }); // Stop at the last Friday
  } else if (inputs.repetitionCycle === RepetitionCycle.Weekly) {
    let currentDate = DateTime.fromISO(inputs.startDate);
    while (currentDate.plus({ week: 1 }).startOf("day") <= date.startOf("day")) {
      currentDate = currentDate.plus({ week: 1 });
    }
    date = currentDate;
  } else if (inputs.repetitionCycle === RepetitionCycle.Monthly) {
    let currentDate = DateTime.fromISO(inputs.startDate);
    while (currentDate.plus({ month: 1 }).startOf("day") <= date.startOf("day")) {
      currentDate = currentDate.plus({ month: 1 });
    }
    date = currentDate;
  }
  return date.toFormat("DDDD");
};

const validateGetEventRepetitionDateInputs = (inputs: IGetEventRepetitionEndDateInput) => {
  if (inputs.repetitionEndType === PropertyEventRepetitionEndType.OnThisDate) {
    if (!inputs.endDate) {
      throw new Error("Repetition endDate is required if repetitionEndType is OnThisDate");
    }
  } else {
    if (!inputs.occurrences) {
      throw new Error("Occurrences is required if repetitionEndType is AfterOccurrences");
    }
  }
};
