import { Component, DestroyRef, inject, input, OnInit, signal } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { UnitsService } from "@root/data/market/units/services/units.service";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { ICurrencyDetails } from "@root/data/market/units/models/currency-details.model";
import { PaymentInterval } from "@root/shared/enums/payment-interval.enum";
import { CurrencyFieldComponent } from "@root/views/main/units/unit-details/components/economy/currency-field/currency-field.component";
import { calculateBaseRentFromExchangeRate } from "@root/data/market/units/utilities/economy.utilities";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { CurrencyConversionComponent } from "@root/shared/currency-conversion/currency-conversion.component";
import { CurrencyFormatConverterPipe } from "@root/shared/pipe/currency-format-converter.pipe";
import { PaymentFrequencySelectFieldComponent } from "@root/shared/payment-frequency-select-field/payment-frequency-select-field.component";
import { PaymentFrequency } from "@root/shared/enums/payment-frequency.enum";

@Component({
  selector: "est-economy",
  standalone: true,
  imports: [
    UnderDevelopmentComponent,
    SubheaderComponent,
    SmallHeaderComponent,
    TranslateModule,
    MatTooltip,
    PostfixTextInputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    SpinnerComponent,
    CurrencyFieldComponent,
    LoadingOverlayComponent,
    CurrencyConversionComponent,
    PaymentFrequencySelectFieldComponent,
  ],
  templateUrl: "./economy.component.html",
  styleUrl: "./economy.component.scss",
})
export class EconomyComponent implements OnInit {
  fb = inject(NonNullableFormBuilder);
  unitService = inject(UnitsService);
  destroyRef = inject(DestroyRef);
  isLoading: boolean = true;
  paymentIntervalSignal = signal<string>(PaymentInterval.Monthly);
  defaultPaymentCurrency = signal<string>("");
  unitId = input<string>();
  protected readonly calculateBaseRentFromExchangeRate = calculateBaseRentFromExchangeRate;
  currencyConverter = new CurrencyFormatConverterPipe();
  baseRentControl = this.fb.control(0, [Validators.required, Validators.min(1)]);
  acontoHeatControl = this.fb.control(0, Validators.min(0));
  acontoWaterControl = this.fb.control(0, Validators.min(0));
  acontoElectricityControl = this.fb.control(0, Validators.min(0));
  acontoCoolingControl = this.fb.control(0, Validators.min(0));
  acontoOperatingCostsControl = this.fb.control(0, Validators.min(0));
  residentsRepresentationFeeControl = this.fb.control(0, Validators.min(0));
  internetFeeControl = this.fb.control(0, Validators.min(1));
  antennaFeeControl = this.fb.control(0, Validators.min(1));
  otherCurrenciesControl = this.fb.control<ICurrencyDetails[] | null>(null);
  defaultCurrencyControl = this.fb.control<{ id: number; iso: string } | null>(null);
  paymentFrequencyControl = this.fb.control<PaymentFrequency | null>(null, Validators.required);
  economyFormGroup = this.fb.group({
    defaultCurrency: this.defaultCurrencyControl,
    paymentFrequency: this.paymentFrequencyControl,
    baseRent: this.baseRentControl,
    acontoHeat: this.acontoHeatControl,
    acontoWater: this.acontoWaterControl,
    acontoElectricity: this.acontoElectricityControl,
    acontoCooling: this.acontoCoolingControl,
    acontoOperatingCosts: this.acontoOperatingCostsControl,
    residentsRepresentationFee: this.residentsRepresentationFeeControl,
    internetFee: this.internetFeeControl,
    antennaFee: this.antennaFeeControl,
    otherCurrencies: this.otherCurrenciesControl,
  });

  getSelectedCurrencies(currencies: Set<ICurrencyDetails>) {
    this.updateEconomyDetails(Array.from(currencies), "otherCurrencies");
  }

  ngOnInit() {
    this.getUnitEconomyDetails();
  }

  getUnitEconomyDetails() {
    this.unitService.getUnitEconomyDetailsById(this.unitId()!).subscribe((details) => {
      this.economyFormGroup.patchValue(details);
      this.defaultPaymentCurrency.set(details.defaultCurrency.iso);
      this.paymentIntervalSignal.set(details.paymentFrequency);
      this.isLoading = false;
    });
  }

  getPaymentIntervalAndCurrency() {
    return this.defaultCurrencyControl.value?.iso + "/" + this.paymentIntervalSignal();
  }

  convertCurrencyToNumber(danishCurrency: string) {
    const numberString = danishCurrency.replace(/,/g, "");
    return parseFloat(numberString);
  }

  updateEconomyDetails(value: any, field: string) {
    let targetValue: number;
    if (value instanceof FocusEvent) {
      targetValue = this.convertCurrencyToNumber((value.target as HTMLInputElement).value);
    } else {
      targetValue = value;
    }
    const control = this.economyFormGroup.get(field);
    const isChange = control?.value !== targetValue;
    if ((isChange || field === "paymentFrequency") && control?.valid) {
      this.unitService.updateUnitEconomyField(this.unitId()!, field, targetValue).subscribe(() => {
        this.isLoading = true;
        this.getUnitEconomyDetails();
      });
    }
  }
}
