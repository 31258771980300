<div class="rounded-lg overflow-hidden border border-black-300 relative">
  <div class="absolute border border-black-300 rounded top-4 end-4 bg-white p-1 z-20 cursor-grab handle select-none">
    <img
      [alt]="'DRAG' | translate"
      class="handle"
      height="13"
      ngSrc="assets/images/icons/crossed-arrows.svg"
      priority
      width="13"
    />
  </div>
  <div class="h-40 relative">
    @if (mediaFormGroup().controls.fileType.value !== FileType.Video) {
      <img
        class="h-full object-cover w-full"
        draggable="false"
        [alt]="'PROPERTY_MEDIAS.MEDIA_IMAGE' | translate"
        [src]="mediaComputedUrl()"
      />
    } @else {
      <div class="relative h-full">
        <video
          #video
          (click)="togglePlayVideo()"
          (pause)="isVideoPaused.set(true)"
          (play)="isVideoPaused.set(false)"
          [src]="mediaComputedUrl()"
          class="h-full w-full object-cover"
        ></video>
        @if (isVideoPaused()) {
          <button
            type="button"
            (click)="togglePlayVideo()"
            class="absolute inset-0 flex items-center justify-center cursor-pointer z-10"
          >
            <img
              draggable="false"
              [alt]="'PLAY' | translate"
              ngSrc="assets/images/video-play.png"
              width="64"
              height="64"
              priority
            />
          </button>
        }
      </div>
    }
  </div>
  <div class="p-4 flex flex-col gap-4 text-sm bg-black-50">
    <est-select-field
      [control]="mediaFormGroup().controls.mediaType"
      [dropDownOptions]="mediaTypeOptions"
      [selectMultipleOptions]="false"
      (changeSelectValue)="emitValueChange()"
      appliedClass="!w-full"
      dropDownLabel="PROPERTY_MEDIAS.SELECT_MEDIA_TYPE"
    ></est-select-field>
    <div>
      <p class="font-medium">{{ "FILE_NAME" | translate }}</p>
      <p class="font-light whitespace-nowrap truncate">{{ fileNameNoExtension() | translate }}</p>
    </div>
    <div>
      <p class="font-medium">{{ "FILE_TYPE" | translate }}</p>
      <p class="font-light">
        {{ (mediaFormGroup().controls.fileType.value === FileType.Video ? "VIDEO" : "IMAGE") | translate }} ({{
          getFileExtension(fileName())
        }})
      </p>
    </div>
    <div class="flex items-center gap-2">
      <p class="font-light">{{ "IMAGE_QUALITY" | translate }}</p>
      <est-status-badge [score]="MediaQuality.Good"></est-status-badge>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-black-500 font-light text-xs">
        <p>{{ "DOCUMENTS.UPLOADED_BY" | translate }}:</p>
        <p>{{ uploadInfo() }}</p>
      </div>
      <div>
        <mat-menu #actionsMenu="matMenu">
          <button (click)="open.emit()" mat-menu-item type="button">
            {{ "EDIT_DETAILS" | translate }}
          </button>
          <button (click)="delete.emit()" mat-menu-item type="button">
            {{ "DELETE" | translate }}
          </button>
        </mat-menu>
        <button
          [attr.aria-label]="'DROPDOWN_MENU' | translate"
          [matMenuTriggerFor]="actionsMenu"
          class="cursor-pointer flex items-center bg-white justify-center rounded border border-black-300"
          type="button"
        >
          <mat-icon class="scale-75">more_vert</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
