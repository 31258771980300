import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { IDeleteDivisionPayload } from "../../division/models/division-delete.model";
import { IOrganisationCommunicationSettings } from "../models/organisation-communication-settings.model";
import { IOrganisationDetails } from "../models/organisation.model";
import { IOrganizationStyling } from "../models/organization-styling.model";
import { OrganisationRepository } from "../repositories/organisation.repository";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  #organisationRepo = inject(OrganisationRepository);

  getOrganisationDetails(): Observable<IOrganisationDetails> {
    return this.#organisationRepo.getOrganisationDetails();
  }

  patchOrganisationDetails(organisationDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#organisationRepo.patchOrganisationDetails(organisationDetailsForm);
  }

  deleteDivision(divisionDeleteDetails: IDeleteDivisionPayload): Observable<void> {
    return this.#organisationRepo.deleteDivision(divisionDeleteDetails);
  }

  transferOwnership(newOwnerUserId: number) {
    return this.#organisationRepo.transferOrganizationOwnership(newOwnerUserId);
  }

  getOrganisationCommunicationSettings(): Observable<IOrganisationCommunicationSettings> {
    return this.#organisationRepo.getOrganizationCommunicationSettings();
  }

  patchOrganisationCommunicationSettings(organisationCommunicationSettingsForm: {
    [key: string]: any;
  }): Observable<void> {
    return this.#organisationRepo.patchOrganizationCommunicationSettings(organisationCommunicationSettingsForm);
  }

  getOrganizationStyling(id: number): Observable<IOrganizationStyling> {
    return this.#organisationRepo.getOrganizationStyling(id);
  }

  updateOrganizationStyling(details: FormData): Observable<void> {
    return this.#organisationRepo.updateOrganizationStyling(details);
  }
}
