import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { APIS } from "@root/core/api/market.apis";

export const RoomTypeApiMapper = new Map<
  RoomType,
  {
    create: string;
    delete: (id: number) => string;
    update: (id: number) => string;
    getById: (id: number) => string;
  }
>([
  [
    RoomType.Bedroom,
    {
      create: APIS.rooms.bedroom.create,
      delete: APIS.rooms.bedroom.delete,
      update: APIS.rooms.bedroom.update,
      getById: APIS.rooms.bedroom.getById,
    },
  ],
  [
    RoomType.LivingRoom,
    {
      create: APIS.rooms.livingRoom.create,
      delete: APIS.rooms.livingRoom.delete,
      update: APIS.rooms.livingRoom.update,
      getById: APIS.rooms.livingRoom.getById,
    },
  ],
  [
    RoomType.AdditionalRoom,
    {
      create: APIS.rooms.additionalRoom.create,
      delete: APIS.rooms.additionalRoom.delete,
      update: APIS.rooms.additionalRoom.update,
      getById: APIS.rooms.additionalRoom.getById,
    },
  ],
  [
    RoomType.Restroom,
    {
      create: APIS.rooms.restroom.create,
      delete: APIS.rooms.restroom.delete,
      update: APIS.rooms.restroom.update,
      getById: APIS.rooms.restroom.getById,
    },
  ],
  [
    RoomType.Toilet,
    {
      create: APIS.rooms.restroom.create,
      delete: APIS.rooms.restroom.delete,
      update: APIS.rooms.restroom.update,
      getById: APIS.rooms.restroom.getById,
    },
  ],
  [
    RoomType.Bathroom,
    {
      create: APIS.rooms.bathroom.create,
      delete: APIS.rooms.bathroom.delete,
      update: APIS.rooms.bathroom.update,
      getById: APIS.rooms.bathroom.getById,
    },
  ],
  [
    RoomType.InternalStorageRoom,
    {
      create: APIS.rooms.internalStorageRoom.create,
      delete: APIS.rooms.internalStorageRoom.delete,
      update: APIS.rooms.internalStorageRoom.update,
      getById: APIS.rooms.internalStorageRoom.getById,
    },
  ],
  [
    RoomType.Garden,
    {
      create: APIS.rooms.garden.create,
      delete: APIS.rooms.garden.delete,
      update: APIS.rooms.garden.update,
      getById: APIS.rooms.garden.getById,
    },
  ],
  [
    RoomType.Terrace,
    {
      create: APIS.rooms.terrace.create,
      delete: APIS.rooms.terrace.delete,
      update: APIS.rooms.terrace.update,
      getById: APIS.rooms.terrace.getById,
    },
  ],
  [
    RoomType.Balcony,
    {
      create: APIS.rooms.balcony.create,
      delete: APIS.rooms.balcony.delete,
      update: APIS.rooms.balcony.update,
      getById: APIS.rooms.balcony.getById,
    },
  ],
]);
