<est-subheader
  date="Mon Feb 19 2024 14:20:06 GMT+0200"
  label="SETTINGS.SYSTEM_CONFIGURATIONS.NAME"
  name="Jesper Thorkildsen"
  tooltip="SETTINGS.SYSTEM_CONFIGURATIONS.NAME"
></est-subheader>
<div class="mt-12">
  <est-navigation-tabs class="mt-12" [tabs]="tabs"></est-navigation-tabs>
  <hr class="border-black-100 absolute start-0 end-0" />
  <div class="mt-10">
    <router-outlet></router-outlet>
  </div>
</div>
