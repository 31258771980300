import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const listTeamAssignedPropertiesHeaders: ITableHeader[] = [
  {
    label: "PROPERTIES.FORM.PROPERTY_NAME",
    key: "name",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "PROPERTIES.ADDRESS",
    key: "address",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    label: "PROPERTIES.LISTING_TYPE",
    key: "listingType",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
  },
  {
    label: "PROPERTIES.NUMBER_OF_UNITS",
    key: "unitsNumber",
    type: TableHeaderType.Text,
    isSortable: true,
    partialTranslationKey: "UNITS",
  },
  {
    label: "PROPERTIES.ACTIVE_USERS",
    key: "activeUsers",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
  },
];
