import { Injectable, inject } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { constructAddressFormGroup } from "@root/shared/utilities/form.utilities";
import { PaymentFrequency } from "@root/shared/enums/payment-frequency.enum";
import { IPropertyAddressFormGroup } from "@root/shared/property-address/property-address-form-group.model";

@Injectable()
export class PropertyMainDetailsFormControls {
  readonly #formBuilder = inject(NonNullableFormBuilder);
  company = this.#formBuilder.control({ value: "", disabled: true });
  companyId = this.#formBuilder.control<number>({ value: 0, disabled: true });
  propertyName = this.#formBuilder.control("", Validators.required);
  propertyNumber = this.#formBuilder.control<string>("", Validators.required);
  cadastralNumber = this.#formBuilder.control<string>("");
  timeZone = this.#formBuilder.control<number>(0, Validators.required);
  propertyConcept = this.#formBuilder.control({ value: "1", disabled: true });
  defaultSalesType = this.#formBuilder.control({ value: [""], disabled: true });
  unitType = this.#formBuilder.control({ value: [""], disabled: true });
  housingType = this.#formBuilder.control({ value: [""], disabled: true });
  usageType = this.#formBuilder.control({ value: [""], disabled: true });
  paymentFrequency = this.#formBuilder.control<PaymentFrequency | null>(null, Validators.required);
  allowPaymentFrequencyChangesOnUnitLevel = this.#formBuilder.control<boolean>(true);
  allowCurrencyChangesOnUnitLevel = this.#formBuilder.control<boolean>(true);
  currencyId = this.#formBuilder.control(0, Validators.required);
  primaryAddressInformation = constructAddressFormGroup(this.#formBuilder, false);
  secondaryAddressInformation = constructAddressFormGroup(this.#formBuilder, false);

  mainDetailsForm = this.#formBuilder.group({
    company: this.#formBuilder.group({
      name: this.company,
      id: this.companyId,
    }),
    propertyDetails: this.#formBuilder.group({
      name: this.propertyName,
      propertyNumber: this.propertyNumber,
      cadastralNumber: this.cadastralNumber,
      timeZone: this.timeZone,
      propertyConcept: this.propertyConcept,
      salesTypes: this.defaultSalesType,
      unitTypes: this.unitType,
      housingTypes: this.housingType,
      usageTypes: this.usageType,
    }),
    propertyAddress: this.#formBuilder.group<IPropertyAddressFormGroup>({
      primaryAddress: this.#formBuilder.group({
        addressInformation: this.primaryAddressInformation,
      }),
    }),
    defaultPaymentFrequency: this.#formBuilder.group({
      paymentFrequency: this.paymentFrequency,
      allowPaymentFrequencyChangesOnUnitLevel: this.allowPaymentFrequencyChangesOnUnitLevel,
    }),
    defaultCurrency: this.#formBuilder.group({
      currencyId: this.currencyId,
      allowCurrencyChangesOnUnitLevel: this.allowCurrencyChangesOnUnitLevel,
    }),
  });

  addSecondaryAddress = () => {
    this.mainDetailsForm.controls.propertyAddress.addControl(
      "secondaryAddress",
      this.#formBuilder.group({
        addressInformation: this.secondaryAddressInformation,
      }),
    );
  };
}
