import { Routes } from "@angular/router";
import { CompanySettingsComponent } from "@root/views/main/company/company-settings/company-settings.component";
import { CompanyDocumentsComponent } from "@root/views/main/company/company-documents/company-documents.component";
import { CompanyDetailsComponent } from "@root/views/main/company/company-details/company-details.component";
import { CompanyPropertiesComponent } from "@root/views/main/company/company-properties/company-properties.component";

export const companyRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "details",
  },
  {
    path: "details",
    component: CompanyDetailsComponent,
    data: {
      name: "DETAILS.NAME",
    },
  },
  {
    path: "properties",
    component: CompanyPropertiesComponent,
    data: {
      name: "PROPERTIES.NAME",
    },
  },
  {
    path: "documents",
    component: CompanyDocumentsComponent,
    data: {
      name: "DOCUMENTS.NAME",
    },
  },
  {
    path: "settings",
    component: CompanySettingsComponent,
    data: {
      name: "SETTINGS.NAME",
    },
  },
];
