import { Injectable, inject } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { constructAddressFormGroup } from "@root/shared/utilities/form.utilities";
import { REGEX_PATTERNS } from "@root/shared/constants/regex-patterns.constants";
import { IPhoneNumber } from "@root/shared/interfaces/phone-number.interface";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";

@Injectable()
export class CompanyFormControls {
  readonly formBuilder = inject(NonNullableFormBuilder);
  detailsAddressInformation = constructAddressFormGroup(this.formBuilder, true, false);
  companyName = this.formBuilder.control<string>("", Validators.required);
  ownerId = this.formBuilder.control<number | null>(null, Validators.required);
  website = this.formBuilder.control<string>("");
  contactPersonName = this.formBuilder.control<string>("", Validators.required);
  contactPersonEmail = this.formBuilder.control<string>("", [
    Validators.required,
    Validators.pattern(REGEX_PATTERNS.email),
  ]);
  contactPersonIsdCode = this.formBuilder.control<string | null>("", Validators.required);
  contactPersonPhoneNo = this.formBuilder.control<string | null>("", Validators.required);
  phoneNumber = this.formBuilder.control<IPhoneNumber>(constructInitialPhoneNumberValue(), Validators.required);
  companyDetails = this.formBuilder.group({
    addressInformation: this.detailsAddressInformation,
    websiteUrl: this.website,
    contactPersonName: this.contactPersonName,
    contactPersonEmail: this.contactPersonEmail,
    contactPersonIsdCode: this.contactPersonIsdCode,
    contactPersonPhoneNo: this.contactPersonPhoneNo,
  });

  companyFormGroup = this.formBuilder.group({
    name: this.companyName,
    ownerId: this.ownerId,
    companyDetails: this.companyDetails,
    phoneNumber: this.phoneNumber,
  });
}
