import { Component, inject, input, OnInit } from "@angular/core";
import { NonNullableFormBuilder } from "@angular/forms";
import { PropertyMediaType } from "@root/data/market/properties/enums/property-media-type.enum";
import { IUploadedPropertyMediaPackageDetails } from "@root/data/market/properties/models/uploaded-property-media-package-details.model";
import { BasePropertyMediaPackageComponent } from "@root/shared/abstracts/base-property-media-package/base-property-media-package.component";
import { BASE_PROPERTY_MEDIA_PACKAGE_IMPORTS } from "@root/shared/abstracts/base-property-media-package/base-property-media-package.imports";
import { PropertyMediaPackageFormControls } from "@root/shared/abstracts/base-property-media-package/property-media-package.form-controls";
import { FileType } from "@root/shared/enums/file-type.enum";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { finalize } from "rxjs";

@Component({
  selector: "est-edit-property-media-package",
  standalone: true,
  templateUrl:
    "../../../../../../../../shared/abstracts/base-property-media-package/base-property-media-package.component.html",
  imports: BASE_PROPERTY_MEDIA_PACKAGE_IMPORTS,
  styleUrl: "./edit-property-media-package.component.scss",
  providers: [PropertyMediaPackageFormControls],
})
export class EditPropertyMediaPackageComponent extends BasePropertyMediaPackageComponent implements OnInit {
  readonly packageId = input.required<number>();
  override isEdit = true;
  readonly #fb = inject(NonNullableFormBuilder);

  ngOnInit(): void {
    this.#getMediaPackageDetails();
  }

  override submitForm(): void {
    this.#editPackage();
  }

  #editPackage(): void {
    this.isSubmittingForm = true;
    this.propertiesMediaPackagesService
      .editPropertyMediaPackage(
        this.propertyId(),
        this.packageId(),
        this.createPropertyMediaPackageFormControls.formGroup.value,
      )
      .subscribe({
        next: () => {
          this.router.navigate(["../../"], { relativeTo: this.route });
          this.snackBarService.open(SnackbarType.Success);
        },
        error: () => (this.isSubmittingForm = false),
      });
  }

  #getMediaPackageDetails(): void {
    this.isPageLoading = true;
    this.propertiesMediaPackagesService
      .getPropertyMediaPackageDetails(this.propertyId(), this.packageId())
      .pipe(finalize(() => (this.isPageLoading = false)))
      .subscribe((res) => {
        this.createPropertyMediaPackageFormControls.formGroup.patchValue(res);
        this.linkedUnitsCount = res.numberOfLinkedUnits!;
        this.packageMedias = res.medias as IUploadedPropertyMediaPackageDetails[];
        this.#populateMediasFormArray(this.packageMedias);
      });
  }

  #populateMediasFormArray(medias: IUploadedPropertyMediaPackageDetails[]): void {
    medias.forEach((media) => {
      this.formControls.medias.push(
        this.#fb.group({
          id: this.#fb.control<number | undefined>(media.id),
          file: this.#fb.control<File | undefined>(undefined),
          mediaType: this.#fb.control<PropertyMediaType | undefined>(media.mediaType),
          fileType: this.#fb.control<FileType>(media.fileType),
          alternateText: this.#fb.control<string | undefined>(media.alternateText),
          title: this.#fb.control<string | undefined>(media.title),
          mediaText: this.#fb.control<string | undefined>(media.mediaText),
          description: this.#fb.control<string | undefined>(media.description),
          filePath: this.#fb.control<string | undefined>(media.fileUrl),
          width: this.#fb.control<number | undefined>(media.width),
          height: this.#fb.control<number | undefined>(media.height),
        }),
      );
    });
  }
}
