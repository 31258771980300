import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { UsersService } from "@root/data/market/users/services/users.service";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { UpdateUserListCommunicationService } from "../update-user-list-communication.service";
import { IUser } from "@root/data/market/users/models/user.model";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { MatDialog } from "@angular/material/dialog";

@Injectable({ providedIn: "root" })
export class HandlingUserArchivingService {
  #dialogService = inject(DialogService);
  #translate = inject(TranslateService);
  #authService = inject(AuthService);
  #usersService = inject(UsersService);
  #updateUserListService = inject(UpdateUserListCommunicationService);
  #dialog = inject(MatDialog);

  #isArchiveUserLoading: WritableSignal<boolean> = signal(false);

  archiveUser(row: IUser) {
    if (row.isOrganisationOwner || row.ownedDivisions.length > 0 || row.managedTeams.length > 0) {
      this.#openWarningDialogArchiveUser(row);
    } else {
      this.#openConfirmationArchiveUser(row);
    }
  }

  userName(row: ISmallListTableInput): string {
    return this.#translate.instant("SETTINGS.USERS.USER_NAME", {
      firstName: row["firstName"],
      lastName: row["lastName"],
    });
  }

  #openWarningDialogArchiveUser(row: IUser) {
    this.#dialogService.open(
      {
        tooltipLabel: "SETTINGS.USERS.ARCHIVE_DIALOG.ARCHIVE_USER_DETAILS",
        title: "SETTINGS.USERS.ARCHIVE_DIALOG.ARCHIVE_USER_DETAILS",
        submitLabel: "DEACTIVATE",
        isInputIncluded: false,
        descriptions: [this.#getEntityTypeAndName(row), "SETTINGS.USERS.ARCHIVE_DIALOG.NOT_POSSIBLE_TO_ARCHIVE"],
      },
      "warning",
    );
  }

  #openConfirmationArchiveUser(row: IUser) {
    this.#dialogService.open(
      {
        tooltipLabel: "SETTINGS.USERS.ARCHIVE_DIALOG.USER_WITHOUT_RELATION.TOOLTIP",
        title: "SETTINGS.USERS.ARCHIVE_DIALOG.USER_WITHOUT_RELATION.TITLE",
        callBack: () => this.#archiveUser(row as any),
        submitLabel: "CONFIRM",
        isInputIncluded: false,
        descriptions: [
          "SETTINGS.USERS.ARCHIVE_DIALOG.USER_WITHOUT_RELATION.BODY.QUESTION",
          this.userName(row),
          "SETTINGS.USERS.ARCHIVE_DIALOG.USER_WITHOUT_RELATION.BODY.INFO",
        ],
        isSubmitLoading: this.#isArchiveUserLoading,
      },
      "confirmation",
    );
  }

  #archiveUser(row: IUser) {
    this.#isArchiveUserLoading.set(true);
    this.#usersService.archiveUser(row.id).subscribe(() => {
      this.#isArchiveUserLoading.set(false);
      this.#updateUserListService.reloadUserListPage$.next();
      this.#dialog.closeAll();
    });
  }

  #getEntityTypeAndName(row: IUser) {
    if (row.isOrganisationOwner) {
      return this.#translate.instant("SETTINGS.USERS.ARCHIVE_DIALOG.TRYING_TO_ARCHIVE_A_USE", {
        entityType: this.#translate.instant("ORGANIZATION_OWNER"),
        entityName: this.#authService.getOrganisationName(),
        entity: this.#translate.instant("ORGANIZATION"),
      });
    } else if (row.ownedDivisions.length > 0) {
      return this.#translate.instant("SETTINGS.USERS.ARCHIVE_DIALOG.TRYING_TO_ARCHIVE_A_USE", {
        entityType: this.#translate.instant("DIVISION_OWNER"),
        entityName: row.ownedDivisions[0],
        entity: this.#translate.instant("DIVISIONS.DIVISION"),
      });
    } else {
      return this.#translate.instant("SETTINGS.USERS.ARCHIVE_DIALOG.TRYING_TO_ARCHIVE_A_USE", {
        entityType: this.#translate.instant("TEAM_MANAGER"),
        entityName: row.managedTeams[0],
        entity: this.#translate.instant("TEAMS.TEAM"),
      });
    }
  }
}
