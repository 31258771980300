import { Component, EventEmitter, input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { EndUserVisibilityInputComponent } from "@root/shared/end-user-visibility-input/end-user-visibility-input.component";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";

@Component({
  selector: "est-visibility-controlled-toggle-input",
  standalone: true,
  imports: [MatSlideToggle, EndUserVisibilityInputComponent, ReactiveFormsModule, TranslateModule, TooltipComponent],
  templateUrl: "./visibility-controlled-toggle-input.component.html",
  styleUrl: "./visibility-controlled-toggle-input.component.scss",
})
export class VisibilityControlledToggleInputComponent {
  header = input.required<string>();
  label = input.required<string>();
  valueControl = input.required<FormControl<boolean>>();
  visibilityControl = input.required<FormControl<boolean>>();
  @Output() valuesChanged = new EventEmitter<void>();
}
