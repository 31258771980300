import { Orientation } from "@root/data/market/units/enums/orientation.enum";

export const OrientationTranslationMapper = new Map<Orientation, string>([
  [Orientation.North, "ORIENTATIONS.NORTH"],
  [Orientation.South, "ORIENTATIONS.SOUTH"],
  [Orientation.East, "ORIENTATIONS.EAST"],
  [Orientation.West, "ORIENTATIONS.WEST"],
  [Orientation.NorthEast, "ORIENTATIONS.NORTH_EAST"],
  [Orientation.NorthWest, "ORIENTATIONS.NORTH_WEST"],
  [Orientation.SouthEast, "ORIENTATIONS.SOUTH_EAST"],
  [Orientation.SouthWest, "ORIENTATIONS.SOUTH_WEST"],
]);
