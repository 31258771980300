import { Component, OnInit, inject } from "@angular/core";
import { CompanySelectFieldComponent } from "@root/shared/company-select-field/company-select-field.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { NonNullableFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "est-select-company",
  standalone: true,
  templateUrl: "./select-company-dialog.component.html",
  styleUrl: "./select-company-dialog.component.scss",
  imports: [CompanySelectFieldComponent, ButtonComponent, TranslateModule, MatTooltip],
})
export class SelectCompanyDialogComponent implements OnInit {
  protected dialogRef: MatDialogRef<SelectCompanyDialogComponent> = inject(MatDialogRef);
  protected propertiesService = inject(PropertiesService);
  componentInputs: { propertyId: string; companyId: number } = inject(MAT_DIALOG_DATA);
  protected readonly fb = inject(NonNullableFormBuilder);
  companyIdControl = this.fb.control<number | undefined>(undefined, Validators.required);

  ngOnInit(): void {
    this.companyIdControl.setValue(this.componentInputs.companyId);
  }
  saveData() {
    this.dialogRef.close(this.companyIdControl.value);
    this.#changeCompany();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  #changeCompany() {
    this.propertiesService.changeCompany(this.componentInputs.propertyId, this.companyIdControl.value!).subscribe();
  }
}
