<div class="bg-white rounded-[0.625rem] r-screen-[25rem] relative py-6">
  <est-subheader
    appliedClass="px-6"
    date="Mon Feb 19 2024 14:20:06 GMT+0200"
    label="UNIT.UNIT_DETAILS.FACILITIES"
    name="Jesper Hansen"
    tooltip="UNIT.UNIT_DETAILS.FACILITIES"
  />
  <div class="px-6">
    <est-unit-facilities [unitId]="unitId()" />
  </div>
  <hr class="bg-black-100 my-6" />
  <div class="px-6">
    <est-unit-other-facilities [unitId]="unitId()" />
  </div>
  <hr class="bg-black-100 my-6" />
  <div class="px-6">
    <est-unit-property-facilities [unitId]="unitId()" />
  </div>
</div>
