<h1 class="h1">{{ breadcrumbItems[0].name | translate }}</h1>
<div class="flex items-center gap-2">
  @for (breadcrumbItem of breadcrumbItems; track breadcrumbItem.url) {
    @if (!$last) {
      <p class="font-light cursor-pointer text-black text-base" [routerLink]="breadcrumbItem.url">
        {{ breadcrumbItem.name | translate }}
      </p>
      <img ngSrc="assets/images/icons/chevron-right.svg" priority width="6" height="10" alt="" />
    } @else {
      <p class="font-medium text-primary text-base">{{ breadcrumbItem.name | translate }}</p>
    }
  }
</div>
