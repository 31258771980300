import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventType, EventTypeTranslationMapper } from "@root/shared/enums/event-type";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import {
  formatDateToGetWithDay,
  formatDateToShortTime,
  getFormattedTimeDifference,
} from "@root/shared/utilities/date.utilities";
import { EventDetailsSignalStateService } from "../services/event-details-signal-state.service";

@Component({
  selector: "est-event-main-details",
  standalone: true,
  imports: [
    SubheaderComponent,
    NgClass,
    TranslateModule,
    MatIcon,
    NgOptimizedImage,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ],
  templateUrl: "./event-main-details.component.html",
  styleUrl: "./event-main-details.component.scss",
})
export class EventMainDetailsComponent {
  readonly #router = inject(Router);
  readonly #translationService = inject(TranslateService);
  readonly #eventDetailsSignalStateService = inject(EventDetailsSignalStateService);
  eventDetails = this.#eventDetailsSignalStateService.eventDetails;
  propertyId = this.#eventDetailsSignalStateService.propertyId;

  protected readonly eventType = EventType;
  protected readonly eventTypeTranslationMapper = EventTypeTranslationMapper;
  protected readonly formatDateToGetWithDay = formatDateToGetWithDay;
  protected readonly getFormattedTimeDifference = getFormattedTimeDifference;

  getEventStartTime(): string {
    const eventStartDate = this.eventDetails()?.startDate;
    return formatDateToShortTime(new Date(eventStartDate!));
  }
  getEventEndTime(): string {
    const eventEndDate = this.eventDetails()?.endDate;
    return formatDateToShortTime(new Date(eventEndDate!));
  }
  getEventStatus(isAvailable?: boolean) {
    return isAvailable ? "UNIT_STATUS.AVAILABLE" : "EVENTS.OCCUPIED_THE_EVENT_IS_FULL_BOOKED";
  }
  getEventBookingType(type: string | undefined) {
    if (!type) return `${this.#translationService.instant("NOT_PROVIDED")}`;
    return `${this.#translationService.instant("EVENTS." + type.toUpperCase())} ${this.#translationService.instant("EVENTS.ATTENDANCE")}`;
  }
  editEventDetails() {
    this.#router.navigate([`properties/${this.propertyId()}/events/edit/${this.eventDetails()!.id}`]);
  }
}
