<est-dialog-header label="ECONOMY.CURRENCY_CONVERSION" tooltipLabel="ECONOMY.CURRENCY_CONVERSION"></est-dialog-header>
<div class="px-6 pt-6">
  <p class="text-sm font-medium pb-2">{{ "ECONOMY.CURRENCY_CONVERSION_DIALOG.TITLE" | translate }}</p>
  @if (componentInputs.unitId) {
    <p class="text-sm font-light mb-6">{{ "ECONOMY.CURRENCY_CONVERSION_DIALOG.DESCRIPTION" | translate }}</p>
  } @else {
    @for (description of propertiesDescription; track description) {
      <p class="text-sm font-light mb-6">{{ description | translate }}</p>
    }
  }
</div>

@if (isLoading) {
  <div class="py-6">
    <est-spinner width="35" height="35" appliedClasses="!fill-primary"></est-spinner>
  </div>
} @else {
  <div class="flex justify-between items-center sticky top-0 bg-white z-10 border-t border-black-100">
    <div class="flex gap-2 items-center px-6 pt-6">
      <p class="text-sm font-medium">{{ "ECONOMY.CURRENCY_CONVERSION_DIALOG.SUB_TITLE" | translate }}</p>
      <button
        [matTooltip]="'ECONOMY.CURRENCY_CONVERSION_DIALOG.SUB_TITLE' | translate"
        class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
      >
        ?
      </button>
    </div>
  </div>

  <div class="ps-6 pb-6">
    @if (componentInputs.propertyId) {
      <p class="text-sm font-light py-4 pe-6">
        {{ "PROPERTIES.DETAILS.CURRENCY_CONVERSION_DIALOG.SELECT_CURRENCY_NOTE" | translate }}
      </p>
    }
    @for (currency of currencies; track $index) {
      <div>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="emitSelectedItem($event.checked, currency)"
          [aria-label]="currency.iso"
          [checked]="currency.isUsed"
        >
          <div class="flex justify-start items-center gap-2">
            <img class="w-6 h-4 rounded-sm" [src]="currency.flagUrl" [alt]="'FLAG' | translate" />
            <span class="pe-2 text-sm font-light text-black-500"> {{ currency.iso }} </span>
            @if (componentInputs.unitId) {
              <span>
                {{ calculateBaseRentFromExchangeRate(currency.exchangeRate, componentInputs.baseRent, currency.iso) }}
              </span>
            } @else {
              <p>
                {{ "PROPERTIES.DETAILS.CURRENCY_CONVERSION_DIALOG.EXCHANGE_RATE" | translate }}:
                {{ currency.exchangeRate.toFixed(2) }}
                <span class="pt-2 text-black-500 text-sm font-light">
                  {{
                    calculateConversionRate(currency.exchangeRate, currency.iso, componentInputs.defaultCurrency!)
                  }}</span
                >
              </p>
            }
          </div>
        </mat-checkbox>
      </div>
    }
    <p class="pt-2 text-black-500 text-sm font-light">
      {{ "ECONOMY.CURRENCY_CONVERSION_DIALOG.UPDATED_AT" | translate }}
      {{ updatedDate | date: DATE_PIPE_FORMAT }}
    </p>
  </div>
}

<div class="flex justify-between bg-black-50 px-6 py-3 sticky bottom-0 z-10 border-t border-black-300">
  <est-button label="CANCEL" (click)="cancel()" buttonType="button" buttonStyleType="button--tertiary" />

  <est-button label="save" buttonStyleType="button--primary" buttonType="submit" (click)="saveData()" />
</div>
