import { inject, Inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import * as signalR from "@microsoft/signalr";
import { environment } from "src/environments/environment";
import { MethodListener } from "@root/core/services/signalR/interfaces/notificationHub.interface";
import { KeycloakAuthService } from "@root/core/authentication/keycloak-auth.service";

export abstract class BaseSignalRHub {
  protected hubConnection: signalR.HubConnection;
  private connectionStateSubject = false;
  private methodListeners: MethodListener<any>[] = [];
  keycloakAuthService = inject(KeycloakAuthService);

  protected constructor(@Inject(String) private hubUrl: string = "") {
    const token = this.keycloakAuthService.getToken();

    // Build the connection with auth token + hub url

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.messageHandlerUrl}${hubUrl}`, {
        accessTokenFactory: () => {
          return token;
        },
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    // On close, set the connection state to false and call onDisconnected

    this.hubConnection.onclose(() => {
      this.connectionStateSubject = false;
      this.onDisconnected().subscribe();
    });
  }

  startConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {
          this.#collectMethodListeners();
          this.#registerListeners();
          console.log("SignalR connection started");
          this.onConnected().subscribe(() => {
            observer.next();
            observer.complete();
          });
        })
        .catch((err) => {
          console.error("Error starting SignalR connection: ", err);
          observer.error(err);

          // Try to reconnect after 5 seconds
          setTimeout(() => this.startConnection().subscribe(), 5000);
        });
    });
  }

  // Pushes the method listeners into an array to be registered
  #collectMethodListeners(): void {
    const listeners: MethodListener<any>[] = [];
    for (const key of Object.keys(this)) {
      const value = (this as any)[key];
      if (value instanceof MethodListener) {
        listeners.push(value);
      }
    }
    this.methodListeners = listeners;
  }
  // Registers the method listeners

  protected onConnected(): Observable<void> {
    this.connectionStateSubject = true;
    console.log("SignalR on Connected");
    return of(void 0).pipe(
      catchError((err) => {
        console.error("Error on connection: ", err);
        return of(void 0);
      }),
    );
  }

  protected onDisconnected(): Observable<void> {
    console.log("SignalR on Disconnected");
    return of(void 0).pipe(
      catchError((err) => {
        console.error("Error on disconnection: ", err);
        return of(void 0);
      }),
    );
  }

  #registerListeners(): void {
    this.methodListeners.forEach((listener) => {
      this.hubConnection.on(listener.methodName, (data) => {
        listener.subject.next(data);
      });
    });
  }

  isConnectionStarted(): boolean {
    return this.hubConnection.state === "Connected";
  }

  getConnectionState(): string {
    return this.hubConnection.state;
  }

  // Example for sending message
  sendMessage(methodName: string, message: string): void {
    // Difference between invoke and send is that invoke returns a promise with the result of the method called on the server
    // Send is just sending the message to the server
    this.hubConnection
      .send(methodName, message)
      // .invoke(methodName, message)
      .then(() => {
        console.log("Message sent");
      })
      .catch((err) => {
        console.error("Error sending message: ", err);
      });
  }

  // Example for stopping connection
  stopConnection(): void {
    this.hubConnection
      .stop()
      .then(() => {
        console.log("SignalR connection stopped");
      })
      .catch((err) => {
        console.error("Error stopping SignalR connection: ", err);
      });
  }

  get connectionState$(): boolean {
    return this.connectionStateSubject;
  }
}
