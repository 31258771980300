import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { IAddLanguagesComponentInputs } from "@root/views/main/organization/organization-settings/components/system-configurations/components/language/components/add-languages-popup/add-languages.component-inputs";
import { ButtonComponent } from "@root/shared/button/button.component";
import { LanguageDisplayComponent } from "@root/shared/language-display/language-display.component";
import { SelectOptionComponent } from "@root/shared/select-option/select-option.component";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";

@Component({
  selector: "est-add-languages-popup",
  standalone: true,
  imports: [TranslateModule, TooltipComponent, ButtonComponent, LanguageDisplayComponent, SelectOptionComponent],
  templateUrl: "./add-languages-popup.component.html",
  styleUrl: "./add-languages-popup.component.scss",
})
export class AddLanguagesPopupComponent {
  readonly #matDialog = inject(MatDialog);
  readonly #systemConfigurationService = inject(SystemConfigurationsService);
  componentInput: IAddLanguagesComponentInputs = inject(MAT_DIALOG_DATA);
  protected isLoading: boolean = false;
  protected isFormSubmitting: boolean = false;
  languageIds: string[] = [];

  getLanguageOptionState(language: ILanguage) {
    return !!this.componentInput.disabledLanguages.find((x) => x.languageId === language.languageId);
  }

  onOptionToggled(languageId: string) {
    if (this.languageIds.find((x) => x === languageId)) {
      this.languageIds = this.languageIds.filter((x) => x !== languageId);
    } else {
      this.languageIds.push(languageId);
    }
  }

  addAndCloseDialog() {
    this.isFormSubmitting = true;
    this.#systemConfigurationService.addAllowedLanguages(this.languageIds).subscribe(() => {
      this.closeDialog();
    });
  }

  closeDialog() {
    this.#matDialog.closeAll();
  }
}
