import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const LIST_SELECT_USER_ACCESSED_PROPERTIES_HEADERS: ITableHeader[] = [
  {
    key: "propertyIds",
    isSortable: false,
    label: "",
    type: TableHeaderType.Checkbox,
  },
  {
    key: "name",
    isSortable: true,
    type: TableHeaderType.Text,
    label: "PROPERTIES.PROPERTY",
  },
  {
    key: "company",
    isSortable: true,
    type: TableHeaderType.Text,
    label: "COMPANIES.COMPANY",
  },
  {
    key: "address",
    isSortable: true,
    type: TableHeaderType.Text,
    label: "ADDRESS.CITY",
  },
  {
    key: "propertyType",
    isSortable: true,
    type: TableHeaderType.Text,
    label: "TYPE",
    needsTranslation: true,
  },
  {
    key: "units",
    isSortable: true,
    type: TableHeaderType.Text,
    label: "UNITS",
  },
  {
    key: "unitsOverview",
    isSortable: false,
    type: TableHeaderType.OverViewStatus,
    label: "UNITS_OVERVIEW",
  },
];
