<div class="w-[36rem] py-4 px-6 relative">
  <div class="flex items-center justify-between mb-4">
    <p class="text-sm font-medium">{{ "LINKED_UNITS.TABLE.OUTDOOR_SPACE_ON_THE_UNIT" | translate }}</p>
    <p class="text-xs font-light">
      {{ "LAST_CHANGE" | translate }}: <span>{{ lastChangedDate() ?? "" | customDate }}</span>
    </p>
  </div>
  <hr class="absolute start-0 end-0 border-black-300" />
</div>
<div>
  <div class="grid grid-cols-3 gap-y-2 px-6 pb-6">
    <p class="font-medium text-xs">{{ "LINKED_UNITS.TABLE.OUTDOOR_SPACE" | translate }}</p>
    <p class="font-medium text-xs">{{ "TYPE" | translate }}</p>
    <p class="font-medium text-xs">{{ "PROPERTY_FACILITIES.FACILITY_LOCATIONS.AREA" | translate }}</p>
    @for (outDoor of outdoorSpacesOverview(); track outDoor.area) {
      <p class="font-light text-xs">{{ outDoor.outdoorSpace + " :" }}</p>
      <p class="font-light text-xs">{{ outDoor.type }}</p>
      <p class="font-light text-xs">{{ outDoor.area + M_SQUARE }}</p>
    }
  </div>
</div>
