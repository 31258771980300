import { ChangeDetectorRef, Component, inject, signal } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { OperationTypeEnum } from "@root/core/enum/operation-type.enum";
import { LinkingPackagesService } from "@root/data/market/linked/services/linking-packages.service";
import { BaseStepperComponent } from "@root/shared/abstracts/stepper/base-stepper.component";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { IPatchBody } from "@root/shared/interfaces/patch-body.interface";
import { UpdateFacilityPackageListCommunicationService } from "@root/views/main/property/property-marketing/components/facilities/facility-package/update-facility-package-list-communication.service";
import { UpdateMediaPackageListCommunicationService } from "@root/views/main/property/property-marketing/components/medias/property-media-packages/update-media-package-list-communication.service";
import { PropertyTextPackagesCommunicationService } from "@root/views/main/property/property-marketing/components/texts/property-text-packages/property-text-packages-communication.service";
import { finalize } from "rxjs/internal/operators/finalize";

@Component({
  standalone: true,
  template: "",
})
export abstract class BaseLinkedItemsComponent extends BaseStepperComponent {
  protected readonly changeDec = inject(ChangeDetectorRef);
  readonly #fb = inject(NonNullableFormBuilder);

  #linkingPackagesService = inject(LinkingPackagesService);
  #updateFacilityPackageListCommunicationService = inject(UpdateFacilityPackageListCommunicationService);
  #updateMediaPackageListCommunicationService = inject(UpdateMediaPackageListCommunicationService);
  #propertyTextPackagesCommunicationService = inject(PropertyTextPackagesCommunicationService);

  componentInputs: {
    divisionId: number;
    companyId: number;
    propertyId: number;
    packageId: number;
    stepName: string;
    linkedText: string;
    linkingSource: LinkingSource;
  } = inject(MAT_DIALOG_DATA);

  linkingSource: LinkingSource = this.componentInputs.linkingSource ?? LinkingSource.DEFAULT;
  isUnitsLoading: boolean = false;
  abstract isEdit: boolean;
  data: any[] = [];
  totalTabsNo: number = 4;
  matDialog = inject(MatDialog);

  divisionIdsSignal = signal<number[]>([this.componentInputs.divisionId] || []);
  companyIdsSignal = signal<number[]>([this.componentInputs.companyId] || []);
  propertyIdsSignal = signal<number[]>([this.componentInputs.propertyId] || []);
  addedUnitIds = signal<Set<string>>(new Set());
  removedUnitIds = signal<Set<string>>(new Set());
  selectAllUnitsOperationSignal = signal<SelectionOperation>(SelectionOperation.DEFAULT);

  selectedDivisionsFC = this.#fb.control<number[]>([], Validators.required);
  selectedCompaniesFC = this.#fb.control<number[]>([], Validators.required);
  selectedPropertiesFC = this.#fb.control<number[]>([], Validators.required);
  selectedUnitsFC = this.#fb.control<string[]>([], Validators.required);

  contextBody: IPatchBody[] = [];

  getSelectedIndex() {
    switch (this.componentInputs.stepName) {
      case SourceLevel.Division:
        return 0;
      case SourceLevel.Company:
        return 1;
      case SourceLevel.Property:
        return 2;
      default:
        return 3;
    }
  }

  isPreviousButtonShown(stepper: MatStepper) {
    return this.getSelectedIndex() < stepper.selectedIndex ? true : false;
  }

  isLastStep(stepper: MatStepper): boolean {
    if (stepper.steps.length - stepper.selectedIndex === 1) {
      return true;
    }
    return false;
  }

  isStepButtonDisabled(stepIndex: number): boolean {
    let isInvalid = false;
    switch (stepIndex) {
      case 0:
        isInvalid = this.selectedDivisionsFC.invalid;
        break;
      case 1:
        isInvalid = this.selectedCompaniesFC.invalid;
        break;
      case 2:
        isInvalid = this.selectedPropertiesFC.invalid;
        break;

      default:
        break;
    }

    return isInvalid;
  }

  nextStep() {
    this.moveToNextStepIfValid();
  }

  linkedDialogTitle() {
    return this.isEdit ? "LINKED_UNITS.EDIT_LINK.NAME" : "LINKED_UNITS.CREATE_LINK.NAME";
  }

  updateDivisionsIds(ids: number[]) {
    this.divisionIdsSignal.set(ids);
    this.selectedDivisionsFC.setValue(ids);
  }
  updateCompaniesIds(ids: number[]) {
    this.companyIdsSignal.set(ids);
    this.selectedCompaniesFC.setValue(ids);
  }
  updatePropertiesIds(ids: number[]) {
    this.propertyIdsSignal.set(ids);
    this.selectedPropertiesFC.setValue(ids);
  }

  submitForm(): void {
    if (this.componentInputs.linkingSource === LinkingSource.DEFAULT) {
      this.#createLink();
    } else {
      this.#updateLinking();
    }
  }

  #createLink() {
    console.log("N/A... To be implemented when linking from a higher level");
  }

  #updateLinking() {
    this.constructPatchPayload();
    if (this.contextBody.length === 0 && this.selectAllUnitsOperationSignal() === SelectionOperation.DEFAULT) {
      this.matDialog.closeAll();
      return;
    }
    this.#linkingPackagesService
      .updateLinkingPackages(
        this.componentInputs.packageId,
        this.contextBody,
        this.componentInputs.linkingSource,
        this.componentInputs.propertyId,
        this.selectAllUnitsOperationSignal(),
      )
      .pipe(finalize(() => this.matDialog.closeAll()))
      .subscribe({
        next: () => {
          this.reloadListPage();
        },
      });
  }

  private reloadListPage() {
    switch (this.componentInputs.linkingSource) {
      case LinkingSource.FACILITY_PACKAGE:
        this.#updateFacilityPackageListCommunicationService.reloadFacilityPackageListPage$.next();
        break;
      case LinkingSource.MEDIA_PACKAGE:
        this.#updateMediaPackageListCommunicationService.reloadMediaPackageListPage$.next();
        break;
      case LinkingSource.TEXT_PACKAGE:
        this.#propertyTextPackagesCommunicationService.reloadPropertyTextPackagesPage$.next();
        break;
      default:
        break;
    }
  }

  constructPatchPayload() {
    this.contextBody = [];
    this.removedUnitIds().forEach((unitId) => {
      this.contextBody.push({ op: OperationTypeEnum.Remove, path: `/unitIds/${unitId}` });
    });
    this.addedUnitIds().size &&
      this.contextBody.push({ op: OperationTypeEnum.Add, path: `/unitIds`, value: Array.from(this.addedUnitIds()) });
  }
}
