import { Component, DestroyRef, inject, input, OnInit, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { TooltipComponent } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IPropertyTextCard } from "@root/data/market/properties/models/property-text-card.interface";
import { PropertyTextsService } from "@root/data/market/properties/services/properties-texts.service";
import { ButtonComponent } from "@root/shared/button/button.component";
import { CreateLinkDialogComponent } from "@root/shared/create-link-dialog/create-link-dialog.component";
import { EditLinkDialogComponent } from "@root/shared/edit-link-dialog/edit-link-dialog.component";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { ICardAction } from "@root/shared/interfaces/card-Action.interface";
import { IDuplicatePackageInput } from "@root/shared/interfaces/duplicate-package-input.interface";
import { ITextsParams } from "@root/shared/interfaces/property-texts-params.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TextCardComponent } from "@root/shared/text-card/text-card.component";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { finalize } from "rxjs/internal/operators/finalize";
import { BaseDuplicatePropertyTextComponent } from "../base-duplicate-property-text/base-duplicate-property-text.component";
import { PropertyTextPackagesCommunicationService } from "./property-text-packages-communication.service";

@Component({
  selector: "est-property-text-packages",
  standalone: true,
  imports: [
    ButtonComponent,
    ButtonComponent,
    RouterLink,
    TooltipComponent,
    SmallHeaderComponent,
    LoadingOverlayComponent,
    TextCardComponent,
  ],
  templateUrl: "./property-text-packages.component.html",
  styleUrl: "./property-text-packages.component.scss",
})
export class PropertyTextPackagesComponent implements OnInit {
  readonly router = inject(Router);
  readonly #translate = inject(TranslateService);
  readonly #matDialog = inject(MatDialog);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogService = inject(DialogService);
  readonly #propertyTextsService = inject(PropertyTextsService);
  readonly #propertyTextPackagesCommunicationService = inject(PropertyTextPackagesCommunicationService);
  readonly #route = inject(ActivatedRoute);

  propertyId = input.required<number>();

  #isDeletingPackage = signal(false);
  isPageLoading: boolean = false;
  textPackages!: IPropertyTextCard[];
  linkingSource: LinkingSource = LinkingSource.TEXT_PACKAGE;

  actions: ICardAction[] = [
    {
      label: "PACKAGES.EDIT_PACKAGE",
      callbackFn: (_, propertyText: IPropertyTextCard) => this.#routeToEditPackage(propertyText.textFieldId),
    },
    {
      label: "PACKAGES.CHANGE_LINK",
      callbackFn: (id: number) => this.#openChangeLinkDialog(id),
      isVisible: (id: number) => this.isLinkedCreated(id),
    },
    {
      label: "PACKAGES.CREATE_LINK",
      callbackFn: (id: number) => this.#openCreateLinkDialog(id),
      isVisible: (id: number) => !this.isLinkedCreated(id),
    },
    {
      label: "PACKAGES.DUPLICATE_PACKAGE.NAME",
      callbackFn: (_, propertyTextPackage: IPropertyTextCard) => this.#openDuplicateDialog(propertyTextPackage),
    },
    {
      label: "PACKAGES.DELETE_PACKAGE",
      callbackFn: (id: number, textPackage: IPropertyTextCard) =>
        this.#openDeleteDialog(id, textPackage.internalTitle, textPackage.numberOfLinkedUnits),
    },
  ];

  ngOnInit() {
    this.#getPropertyTextPackages();
    this.#subscribeToReloadListPage();
  }

  #getPropertyTextPackages() {
    this.isPageLoading = true;
    const params: ITextsParams = { propertyId: this.propertyId(), isPackage: true };

    this.#propertyTextsService
      .getPropertyTextOverview(params)
      .pipe(finalize(() => (this.isPageLoading = false)))
      .subscribe((data) => (this.textPackages = data));
  }

  isLinkedCreated(id: number) {
    const text = this.textPackages.find((text) => text.id === id);
    return text && text.numberOfLinkedUnits > 0 ? true : false;
  }

  #openCreateLinkDialog(id: number) {
    this.#matDialog.open(CreateLinkDialogComponent, {
      width: "77dvw",
      data: {
        propertyId: this.propertyId(),
        packageId: id,
        stepName: SourceLevel.Unit,
        linkingSource: this.linkingSource,
      },
    });
  }

  #openChangeLinkDialog(id: number) {
    this.#matDialog.open(EditLinkDialogComponent, {
      width: "77dvw",
      data: {
        propertyId: this.propertyId(),
        packageId: id,
        stepName: SourceLevel.Unit,
        linkingSource: this.linkingSource,
      },
    });
  }

  #routeToEditPackage(id: number) {
    this.router.navigate(["edit", id], { relativeTo: this.#route });
  }

  #openDuplicateDialog(propertyTextPackage: IPropertyTextCard) {
    this.#matDialog.open<BaseDuplicatePropertyTextComponent, IDuplicatePackageInput>(
      BaseDuplicatePropertyTextComponent,
      {
        height: "90dvh",
        width: "75rem",
        data: {
          propertyId: this.propertyId(),
          originalName: propertyTextPackage.internalTitle,
          originalPackageId: propertyTextPackage.id,
        },
      },
    );
  }

  #openDeleteDialog(id: number, packageName: string, numberOfLinkedUnits: number): void {
    this.#dialogService.open(
      {
        title: "PACKAGES.DELETE_PACKAGE",
        callBack: () => this.#deletePropertyTextPackage(id),
        submitLabel: "IM_SURE",
        isInputIncluded: false,
        descriptions: [
          "PACKAGES.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_PACKAGE",
          this.#getPackageName(packageName),
          this.#getDeleteTextWithNumberOfLinkedUnits(numberOfLinkedUnits),
          "PACKAGES.THEREFORE_ONLY_DELETE",
        ],
        isSubmitLoading: this.#isDeletingPackage,
        additionalData: { id: id, packageName: packageName },
      },
      "confirmation",
    );
  }

  #deletePropertyTextPackage(textId: number): void {
    this.#isDeletingPackage.set(true);
    const params: ITextsParams = { isPackage: true };
    this.#propertyTextsService
      .deletePropertyText(this.propertyId(), textId, params)
      .pipe(
        finalize(() => {
          this.#isDeletingPackage.set(false);
          this.#matDialog.closeAll();
        }),
      )
      .subscribe({ next: () => this.#propertyTextPackagesCommunicationService.reloadPropertyTextPackagesPage$.next() });
  }

  #getPackageName(packageName?: string) {
    return this.#translate.instant("PACKAGES.PACKAGE_NAME", { packageName });
  }

  #getDeleteTextWithNumberOfLinkedUnits(numberOfLinkedUnits?: number) {
    return this.#translate.instant("PACKAGES.TEXT_PACKAGES.IS_LINKED", { numberOfLinkedUnits });
  }

  #subscribeToReloadListPage() {
    this.#propertyTextPackagesCommunicationService.reloadPropertyTextPackagesPage$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.#getPropertyTextPackages();
      });
  }
}
