import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-property-units",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./property-units.component.html",
  styleUrl: "./property-units.component.scss",
})
export class PropertyUnitsComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "UNIT_TYPES.RESIDENTIAL",
      routerLink: "residential",
    },
    {
      label: "UNIT_TYPES.PARKING_LOT",
      routerLink: "parking-lot",
    },
    {
      label: "UNIT_TYPES.STORAGE",
      routerLink: "storage-room",
    },
    {
      label: "UNIT_TYPES.COMMERCIAL",
      routerLink: "commercial",
    },
  ];
}
