import { inject, Injectable } from "@angular/core";
import { CitiesRepository } from "../repositories/cities.repository";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { mapCitiesToSignalPaginatedDropdownOptions } from "../utilities/cities.utilities";

@Injectable({
  providedIn: "root",
})
export class CitiesService {
  readonly #citiesRepository = inject(CitiesRepository);

  getCitiesOptions(searchQuery: string): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.#citiesRepository.getCities(searchQuery).pipe(mapCitiesToSignalPaginatedDropdownOptions());
  }
}
