export enum UnitUsageType {
  StudentAccommodation = "StudentAccommodation",
  SeniorAccommodation = "SeniorAccommodation",
  Shortstay = "Shortstay",
  Longstay = "Longstay",
  Industrial = "Industrial",
  Clinic = "Clinic",
  Warehouse = "Warehouse",
  ProductionCentre = "ProductionCentre",
  Office = "Office",
  DedicatedDesk = "DedicatedDesk",
  PrivateOffice = "PrivateOffice",
}
