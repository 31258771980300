<p class="text-sm font-medium pb-4">
  {{ "LINKED_UNITS.TOTAL_UNITS" | translate: { count: paginatedData.totalCount() } }}
</p>
<p class="text-sm font-medium pb-6">
  {{ "LINKED_UNITS.SELECTED_UNITS" | translate: { count: selectedUnitsComputed } }}
</p>
<div class="flex justify-end gap-6 pb-6 flex-wrap">
  <est-select-field
    [control]="disabledDropdownFilterControl"
    [dropDownOptions]="[]"
    [selectMultipleOptions]="false"
    dropDownLabel="PROPERTIES.SELECT_PROPERTY"
  ></est-select-field>
  <est-select-field
    [control]="disabledDropdownFilterControl"
    [dropDownOptions]="[]"
    [selectMultipleOptions]="false"
    dropDownLabel="LINKED_UNITS.SET_UNIT_FILTER"
  ></est-select-field>
  <est-search-input [searchSignal]="searchSignal"></est-search-input>
</div>
<est-small-list-table
  [data]="paginatedData.results()"
  [headers]="headers"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
  [checkboxControlMapper]="selectedUnitMapper"
  [iconMenuComponentType]="outdoorSpaceForUnitComponent"
  (checkItem)="addAndRemoveItem($event)"
  (checkAllItems)="checkAllUnits($event)"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
