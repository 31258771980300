import { Component, inject, input, OnInit, output, signal, WritableSignal } from "@angular/core";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { NonNullableFormBuilder } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatDialog } from "@angular/material/dialog";
import { AddUnitsToTheEventPopupComponent } from "@root/views/main/property/property-events/create-property-event/selected-units/add-units-to-the-event-popup/add-units-to-the-event-popup.component";
import { OutdoorSpaceForUnitComponent } from "@root/shared/outdoor-space-for-unit/outdoor-space-for-unit.component";
import { UNITS_TABLE_HEADERS } from "@root/views/main/property/property-events/create-property-event/selected-units/selected-units.header";

@Component({
  selector: "est-selected-units",
  standalone: true,
  imports: [SmallListTableComponent, TranslateModule, TooltipComponent, ButtonComponent],
  templateUrl: "./selected-units.component.html",
  styleUrl: "./selected-units.component.scss",
})
export class SelectedUnitsComponent implements OnInit {
  protected readonly headers: ITableHeader[] = UNITS_TABLE_HEADERS.slice(1);
  protected readonly outdoorSpaceForUnitComponent = OutdoorSpaceForUnitComponent;
  sortingFilterSignal!: WritableSignal<ITableSortingFilter>;
  #fb = inject(NonNullableFormBuilder);
  readonly #matDialog = inject(MatDialog);
  unitsIds: string[] = [];
  selectedUnits = signal<any[]>([]);
  propertyId = input.required<number>();
  selectedUnitsIds = output<string[]>();
  fetchSelectedUnits = input<any[]>();
  selectedUnitsId = input<string[]>();
  addNewUnits() {
    this.#matDialog
      .open(AddUnitsToTheEventPopupComponent, {
        width: "77dvw",
        data: {
          propertyId: [this.propertyId()],
          selectedId: this.unitsIds,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.selectedUnits.set(data);
          this.unitsIds = [];
          data.forEach((unit: any) => {
            this.unitsIds.push(unit.id);
          });
          this.selectedUnitsIds.emit(this.unitsIds);
        }
      });
  }
  ngOnInit() {
    if (this.fetchSelectedUnits() && this.fetchSelectedUnits()?.length !== 0) {
      this.selectedUnits.set(this.fetchSelectedUnits()!);
      this.unitsIds = this.selectedUnitsId()!;
    }
  }
}
