import { Component, inject, OnInit } from "@angular/core";
import { BasePetTypeFormComponent } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/base-pet-type-form.component";
import { Observable } from "rxjs";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { BasePetTypeFormControls } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/base-pet-type.form-controls";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";

@Component({
  selector: "est-edit-property-pet-type",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    TranslateModule,
    SelectFieldComponent,
    TooltipComponent,
    MatSlideToggle,
    PostfixTextInputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    SelectSearchFieldComponent,
    FormsModule,
  ],
  providers: [BasePetTypeFormControls],

  templateUrl:
    "../../../../../../shared/abstracts/base-pet-type-form/base-pet-type-form/base-pet-type-form.component.html",
})
export class EditPropertyPetTypeComponent extends BasePetTypeFormComponent implements OnInit {
  override isEdit = true;

  readonly #propertiesService = inject(PropertiesService);

  override ngOnInit() {
    this.isLoading = true;
    super.ngOnInit();
    this.#loadPetTypePolicy();
    this.#loadPetTypes();
    this.isLoading = false;
  }

  override getSubmitFormServiceCall(): Observable<void> {
    return this.#propertiesService.updatePropertyPetTypePolicy(
      this.componentInputs.propertyId,
      this.componentInputs.petTypePolicyId!,
      this.petTypeFormControls.formGroup.value,
    );
  }

  #loadPetTypePolicy() {
    this.#propertiesService
      .getPropertyPetTypePolicy(this.componentInputs.propertyId, this.componentInputs.petTypePolicyId!)
      .subscribe((petTypePolicy) => {
        this.petTypeFormControls.formGroup.patchValue(petTypePolicy);
        this.formControls.petTypeId.disable();
        this.loadPetSpeciesOptions(petTypePolicy.petTypeId);
      });
  }

  #loadPetTypes(): void {
    this.petsService.getAllPetTypes().subscribe((petTypes) => {
      this.allowedPetTypesOptions = petTypes;
    });
  }
}
