<div class="container">
  <p class="text-sm font-medium">{{ "SETTINGS.USERS.USER_ROLES_FOR" | translate: { name: name() } }}</p>
  <div class="absolute start-0 end-0">
    <hr class="mt-4 border-black-100" />
  </div>
  <table>
    <tr>
      <th [attr.aria-label]="'SETTINGS.USERS.ROLE' | translate">{{ "SETTINGS.USERS.ROLE" | translate }}</th>
      <th [attr.aria-label]="'DIVISIONS.DIVISION' | translate">{{ "DIVISIONS.DIVISION" | translate }}</th>
      <th [attr.aria-label]="'SETTINGS.USERS.JOINED' | translate">{{ "SETTINGS.USERS.JOINED" | translate }}</th>
    </tr>
    @for (userDivisionRole of userDivisionRoles(); track $index) {
      <tr>
        <td
          [attr.aria-label]="
            ('SETTINGS.USERS.ROLE' | translate) + ' ' + (RoleNameMapper.get(userDivisionRole.roleName)! | translate)
          "
        >
          {{ RoleNameMapper.get(userDivisionRole.roleName)! | translate }}
        </td>
        <td [attr.aria-label]="'DIVISIONS.DIVISION' + ' ' + userDivisionRole.divisionName">
          {{ userDivisionRole.divisionName }}
        </td>
        <td
          [attr.aria-label]="
            ('SETTINGS.USERS.JOINED' | translate) + ' ' + getFormattedJoinedDate(userDivisionRole.joinedOn)
          "
        >
          {{ getFormattedJoinedDate(userDivisionRole.joinedOn) }}
        </td>
      </tr>
    }
  </table>
</div>
