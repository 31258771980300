import { Component, effect, EffectRef, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { FormControl, NonNullableFormBuilder } from "@angular/forms";
import { OutdoorSpaceForUnitComponent } from "@root/shared/outdoor-space-for-unit/outdoor-space-for-unit.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ButtonComponent } from "@root/shared/button/button.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";

import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { finalize } from "rxjs";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { ICheckedItem } from "@root/shared/interfaces/checked-item.interface";
import { IAllCheckedItems } from "@root/shared/interfaces/checked-items.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { UNITS_TABLE_HEADERS } from "@root/views/main/property/property-events/create-property-event/selected-units/selected-units.header";
import { EventsService } from "@root/data/market/event/services/events.service";

@Component({
  selector: "est-add-units-to-the-event-popup",
  standalone: true,
  imports: [
    PaginationComponent,
    SmallListTableComponent,
    ButtonComponent,
    SearchInputComponent,
    SelectFieldComponent,
    DialogHeaderComponent,
    TranslateModule,
  ],
  templateUrl: "./add-units-to-the-event-popup.component.html",
  styleUrl: "./add-units-to-the-event-popup.component.scss",
})
export class AddUnitsToTheEventPopupComponent extends BasePaginatedTableWithSearchComponent implements OnInit {
  headers: ITableHeader[] = UNITS_TABLE_HEADERS;
  fb = inject(NonNullableFormBuilder);
  unitIds = this.fb.control<string[]>([]);
  selectedUnitMapper: Map<string, FormControl<string[]>> = new Map([["ids", this.unitIds]]);
  protected readonly outdoorSpaceForUnitComponent = OutdoorSpaceForUnitComponent;

  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "unitNo",
    sortDescending: false,
  });
  protected dialogRef: MatDialogRef<AddUnitsToTheEventPopupComponent> = inject(MatDialogRef);

  #eventsService = inject(EventsService);
  componentInputs: { propertyId: number[]; selectedId: string[] } = inject(MAT_DIALOG_DATA);
  protected cancel = () => this.dialogRef.close();
  rowOfSelectedUnits: ISmallListTableInput[] = [];

  readonly loadDataEffect$: EffectRef = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      propertyIds: this.componentInputs.propertyId,
    });
  });

  override loadData(params: IPaginationSortPayload & { propertyIds: number[] }): void {
    this.isTableLoading = true;
    this.#eventsService
      .geUnitsList(params)
      .pipe(finalize(() => (this.isTableLoading = false)))
      .subscribe({
        next: (paginatedLinkedUnit) => {
          copyPaginatedSignalResponse(this.paginatedData, paginatedLinkedUnit);
          this.paginatedData.results().forEach((data) => {
            if (this.unitIds.value.includes(data["unitId"])) {
              this.rowOfSelectedUnits.push(data);
            }
          });
        },
      });
  }

  ngOnInit(): void {
    if (this.componentInputs.selectedId) {
      this.unitIds.setValue(this.componentInputs.selectedId);
    } else {
      this.unitIds.setValue([]);
    }
  }

  getItem(event: ICheckedItem) {
    if (event.checked) {
      this.rowOfSelectedUnits.push(event.row);
      this.unitIds.value.push(event.row["unitId"]);
    } else {
      this.rowOfSelectedUnits = this.rowOfSelectedUnits.filter((row) => row.id !== event.row.id);
      const ids: string[] = this.unitIds.value.filter((row) => row !== event.row["unitId"]);
      this.unitIds.setValue(ids);
    }
  }

  getAllItems(event: IAllCheckedItems) {
    this.rowOfSelectedUnits = [];
    if (event.checked) {
      this.rowOfSelectedUnits.push(...event.rows);
      event.rows.forEach((data) => {
        this.unitIds.value.push(data["unitId"]);
      });
    } else {
      this.rowOfSelectedUnits = [];
      this.unitIds.setValue([]);
    }
  }

  addUnits() {
    this.dialogRef.close(this.rowOfSelectedUnits);
  }
}
