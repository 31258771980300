<div class="bg-black-50 border border-black-300 p-6 rounded-lg relative">
  <div
    class="lg:m-auto grid grid-cols-3 grid-rows-2 gap-2 h-36 bg-white border border-black-300 rounded-lg px-[1.375rem] py-[0.5rem]"
  >
    @if (mediaPackage().mediaUrls.length <= 6) {
      @for (media of mediaPackage().mediaUrls; track $index) {
        @if (media.includes("mp4")) {
          <video class="card__media">
            <source [src]="media" type="video/mp4" />
          </video>
        } @else {
          <img class="card__media" [src]="media" [alt]="'PROPERTY_MEDIAS.MEDIA_IMAGE' | translate" />
        }
      }
    } @else {
      @for (media of mediaPackage().mediaUrls | slice: 0 : 5; track $index) {
        @if (media.includes("mp4")) {
          <video class="card__media">
            <source [src]="media" type="video/mp4" />
          </video>
        } @else {
          <img class="card__media" [src]="media" [alt]="'PROPERTY_MEDIAS.MEDIA_IMAGE' | translate" />
        }
      }
      <div class="flex items-center justify-center">
        <p class="font-black text-sm lg:my-auto">+{{ mediaPackage().mediaUrls.length - 5 }}</p>
      </div>
    }
  </div>
  <hr class="absolute start-0 end-0 border-black-100 my-6" />
  <div class="mt-12 mb-4">
    <p class="font-medium text-sm">{{ "PACKAGES.INTERNAL_TITLE" | translate }}</p>
    <p class="font-light text-sm">{{ mediaPackage().internalTitle }}</p>
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "PACKAGES.ITEMS_IN_THE_PACKAGE" | translate }}</p>
    <p class="font-light text-sm">
      {{ mediaPackage().mediaUrls.length }} {{ "PACKAGES.MEDIA_PACKAGES.NAME" | translate }}
    </p>
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "PACKAGES.LINKED" | translate }}</p>
    @if (mediaPackage().numberOfLinkedUnits > 0) {
      <button
        class="flex gap-1 items-center"
        type="button"
        [attr.aria-label]="'PACKAGES.LINKED' | translate"
        [matMenuTriggerFor]="menu"
        (click)="isClicked = true"
      >
        <img ngSrc="assets/images/icons/linked-icon.svg" [alt]="'ICON' | translate" priority width="16" height="16" />
        <span class="font-light text-sm"> {{ "PACKAGES.LINKED" | translate }}</span>
        <mat-menu class="menu--large" #menu="matMenu" (closed)="isClicked = false">
          <est-linked-units-table-popup
            [packageId]="mediaPackage().id"
            (click)="$event.stopPropagation()"
            [isClicked]="isClicked"
            [propertyId]="propertyId()"
            [linkingSource]="linkingSource()"
          ></est-linked-units-table-popup>
        </mat-menu>
      </button>
    } @else {
      <p class="text-sm font-light text-black-500">
        {{ "PACKAGES.NO_LINK_CREATED" | translate }}
      </p>
    }
  </div>
  <div class="mb-4">
    <p class="font-medium text-sm">{{ "STATUS" | translate }}</p>
    @if (mediaPackage().isActive) {
      <p class="font-light text-sm">{{ "ACTIVE" | translate }}</p>
    } @else {
      <p class="font-light text-sm">{{ "UNIT_STATUS.NOT_ACTIVE" | translate }}</p>
    }
  </div>
  <div>
    <p class="font-medium text-sm">{{ "PACKAGES.UPDATED" | translate }}</p>
    <p class="font-light text-sm">
      {{ mediaPackage().lastUpdatedAt | customDate: true }}
      <span class="text-black-500">{{ mediaPackage().lastUpdatedBy }}</span>
    </p>
  </div>

  <div class="mt-6 flex justify-between items-end">
    <div>
      <p class="text-xs font-light text-black-500">{{ "PACKAGES.CREATED_BY" | translate }}</p>
      <p class="text-xs font-light text-black-500">
        {{ mediaPackage().createdBy }}, {{ mediaPackage().createdOn | date }}
      </p>
    </div>
    <div>
      <button
        [attr.aria-label]="'DROPDOWN_MENU' | translate"
        [matMenuTriggerFor]="actionsMenu"
        class="cursor-pointer flex items-center bg-white justify-center rounded border border-black-300"
        type="button"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        @for (action of actions(); track action.label) {
          @if (!action.isVisible || action.isVisible.call(this, mediaPackage().id)) {
            <button
              mat-menu-item
              type="button"
              [attr.aria-label]="action.label | translate"
              (keypress.enter)="action.callbackFn(mediaPackage().id)"
              (click)="action.callbackFn(mediaPackage().id, mediaPackage())"
            >
              {{ action.label | translate }}
            </button>
          }
        }
      </mat-menu>
    </div>
  </div>
</div>
