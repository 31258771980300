<est-dialog-header label="EVENTS.ADD_UNITS_TO_EVENT" tooltipLabel="EVENTS.ADD_UNITS_TO_EVENT"></est-dialog-header>
<p class="text-sm font-light py-6 px-6">{{ "EVENTS.ADD_UNITS_TO_EVENT_DESCRIPTION" | translate }}</p>

<div class="p-6 h-[37rem] overflow-auto">
  <div class="flex justify-end gap-6 pb-6 flex-wrap">
    <est-select-field
      [dropDownOptions]="[]"
      [selectMultipleOptions]="false"
      dropDownLabel="PROPERTIES.SELECT_PROPERTY"
    ></est-select-field>
    <est-select-field
      [dropDownOptions]="[]"
      [selectMultipleOptions]="false"
      dropDownLabel="LINKED_UNITS.SET_UNIT_FILTER"
    ></est-select-field>
    <est-search-input [searchSignal]="searchSignal"></est-search-input>
  </div>
  <est-small-list-table
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
    [checkboxControlMapper]="selectedUnitMapper"
    [iconMenuComponentType]="outdoorSpaceForUnitComponent"
    (checkItem)="getItem($event)"
    (checkAllItems)="getAllItems($event)"
  ></est-small-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
<div class="py-3 bg-black-50 flex border border-black-100 px-6 justify-between">
  <est-button buttonStyleType="button--tertiary" (click)="cancel()" buttonType="button" label="CANCEL" />
  <est-button buttonStyleType="button--primary" buttonType="button" label="EVENTS.ADD_TO_EVENT" (click)="addUnits()" />
</div>
