import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-property",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./property.component.html",
  styleUrl: "./property.component.scss",
})
export class PropertyComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "OVERVIEW",
      routerLink: "overview",
    },
    {
      label: "PROPERTY.UNITS",
      routerLink: "units",
    },
    {
      label: "PROPERTY.DETAILS",
      routerLink: "details",
    },
    {
      label: "PROPERTY.DOCUMENTS",
      routerLink: "documents",
    },
    {
      label: "PROPERTY.EVENTS",
      routerLink: "events",
    },
    {
      label: "PROPERTY.MARKETING.NAME",
      routerLink: "marketing",
    },
    {
      label: "PROPERTY.SETTINGS",
      routerLink: "setting",
    },
  ];
}
