import { Route } from "@angular/router";
import { CustomerPageComponent } from "@root/views/main/organization/organization-system-modules/customer-page/customer-page.component";
import { CustomerFunnelComponent } from "@root/views/main/organization/organization-system-modules/customer-funnel/customer-funnel.component";
import { customerPageRoutes } from "@root/views/main/organization/organization-system-modules/customer-page/customer-page.routes";
import { SigningComponent } from "@root/views/main/organization/organization-system-modules/signing/signing.component";
import { ScreeningComponent } from "@root/views/main/organization/organization-system-modules/screening/screening.component";
import { UnitSelectorComponent } from "@root/views/main/organization/organization-system-modules/unitselector/unit-selector.component";
import { MyPageComponent } from "@root/views/main/organization/organization-system-modules/my-page/my-page.component";
import { ContractComponent } from "@root/views/main/organization/organization-system-modules/contract/contract.component";
import { ComplianceComponent } from "@root/views/main/organization/organization-system-modules/compliance/compliance.component";

export const organizationSystemModulesRoutes: Route[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "customerFunnel",
  },
  {
    path: "customerPage",
    component: CustomerPageComponent,
    data: {
      name: "SYSTEM_MODULES.CUSTOMER_PAGE.NAME",
    },
    children: customerPageRoutes,
  },
  {
    path: "customerFunnel",
    component: CustomerFunnelComponent,
    data: {
      name: "SYSTEM_MODULES.CUSTOMER_FUNNEL",
    },
  },
  {
    path: "signing",
    component: SigningComponent,
    data: {
      name: "SYSTEM_MODULES.SIGNING",
    },
  },
  {
    path: "screening",
    component: ScreeningComponent,
    data: {
      name: "SYSTEM_MODULES.SCREENING",
    },
  },
  {
    path: "unitSelector",
    component: UnitSelectorComponent,
    data: {
      name: "SYSTEM_MODULES.UNIT_SELECTOR",
    },
  },
  {
    path: "myPage",
    component: MyPageComponent,
    data: {
      name: "SYSTEM_MODULES.MY_PAGE",
    },
  },
  {
    path: "contract",
    component: ContractComponent,
    data: {
      name: "SYSTEM_MODULES.CONTRACT",
    },
  },
  {
    path: "compliance",
    component: ComplianceComponent,
    data: {
      name: "SYSTEM_MODULES.COMPLIANCE",
    },
  },
];
