import { Component, DestroyRef, inject, input, OnInit } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { FacilityPackagesCardComponent } from "@root/shared/facility-packages-card/facility-packages-card.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { IUploadMultiFilesInputs } from "@root/shared/interfaces/upload-multi-files-component-inputs.interface";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { MatDialog } from "@angular/material/dialog";
import { UploadMediaComponent } from "@root/shared/upload-media/upload-media.component";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
import { MediaSortableGridComponent } from "@root/views/main/property/property-marketing/components/media-sortable-grid/media-sortable-grid.component";
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder } from "@angular/forms";
import { CreatePropertyMediaPackageFormControls } from "@root/views/main/property/property-marketing/components/medias/property-media-packages/create-property-media-package/create-property-media-package.form-controls";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { IMediaFormGroup } from "@root/views/main/property/property-marketing/components/media-form-group.interface";
import { PropertyMediaType } from "@root/data/market/properties/enums/property-media-type.enum";
import { FileType } from "@root/shared/enums/file-type.enum";
import { IUploadedPropertyMediaPackageDetails } from "@root/data/market/properties/models/uploaded-property-media-package-details.model";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { areArraysEqual } from "@root/shared/utilities/array.utilities";

@Component({
  selector: "est-property-medias",
  standalone: true,
  imports: [
    ButtonComponent,
    FacilityPackagesCardComponent,
    LoadingOverlayComponent,
    SmallHeaderComponent,
    MediaSortableGridComponent,
  ],
  providers: [CreatePropertyMediaPackageFormControls],
  templateUrl: "./property-medias.component.html",
  styleUrl: "./property-medias.component.scss",
})
export class PropertyMediasComponent implements OnInit {
  readonly #matDialog = inject(MatDialog);
  fileTypes: FileExtension[] = [FileExtension.JPG, FileExtension.PNG, FileExtension.JPEG, FileExtension.MP4];
  fileUploadControl = new FormControl();
  readonly propertyMediaOverviewFormControls = inject(CreatePropertyMediaPackageFormControls);
  readonly #propertyService = inject(PropertiesService);
  propertyId = input.required<number>();
  isLoading = false;
  get formControls() {
    return this.propertyMediaOverviewFormControls.formGroup.controls;
  }
  packageMedias: IUploadedPropertyMediaPackageDetails[] = [];
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #destroyRef$ = inject(DestroyRef);
  previousSortArray: number[] = [];
  ngOnInit() {
    this.fetchDataAndPatchForm();
    this.updateOverViewMedia();
  }
  updateOverViewMedia() {
    this.propertyMediaOverviewFormControls.formGroup.controls.medias.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef$))
      .subscribe((data) => {
        const sortArray: number[] = [];
        data.forEach((media) => {
          media.id && sortArray.push(media.id);
        });
        if (!areArraysEqual(sortArray, this.previousSortArray)) {
          this.updateSortingList(sortArray);
        }
      });
  }
  updateSortingList(sortArray: number[]) {
    this.#propertyService.editPropertyMedia({ mediaOrder: sortArray }, this.propertyId()).subscribe({
      next: () => {
        this.previousSortArray = [...sortArray];
        this.fetchDataAndPatchForm();
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  fetchDataAndPatchForm() {
    this.isLoading = true;
    this.#propertyService.getPropertyMediaOverview(this.propertyId()).subscribe((data) => {
      const mediaFormGroups = data.map((media) => this.createMediaFormGroup(media));
      const mediasFormArray = this.propertyMediaOverviewFormControls.formGroup.get("medias") as FormArray;
      mediasFormArray.clear();
      mediaFormGroups.forEach((formGroup) => mediasFormArray.push(formGroup));
      this.isLoading = false;
      this.packageMedias = data as unknown as IUploadedPropertyMediaPackageDetails[];
    });
  }

  private createMediaFormGroup(media: any): FormGroup<IMediaFormGroup> {
    return this.#fb.group({
      id: this.#fb.control<number | undefined>(media.id),
      file: this.#fb.control<File | null>(media.file),
      filePath: this.#fb.control<string>(media.filePath),
      mediaType: this.#fb.control<PropertyMediaType | undefined>(media.mediaType),
      fileType: this.#fb.control<FileType>(media.fileType),
      alternateText: this.#fb.control<string | undefined>(media.alternateText),
      title: this.#fb.control<string | undefined>(media.title),
      mediaText: this.#fb.control<string | undefined>(media.mediaText),
      description: this.#fb.control<string | undefined>(media.description),
      width: this.#fb.control<number | undefined>(media.width),
      height: this.#fb.control<number | undefined>(media.height),
    }) as FormGroup<IMediaFormGroup>;
  }

  addMedia() {
    this.#matDialog
      .open<UploadMediaComponent, IUploadMultiFilesInputs & { propertyId: number }>(UploadMediaComponent, {
        data: {
          sourceLevel: SourceLevel.Property,
          supportedTypes: this.fileTypes,
          maxSize: 100,
          isActivated: false,
          propertyId: this.propertyId(),
        },
        width: "45rem",
        maxHeight: "70dvh",
      })
      .afterClosed()
      .subscribe((isUploading: boolean) => {
        if (isUploading !== undefined && !isUploading) this.fetchDataAndPatchForm();
      });
  }

  changeControl(event: { id: number; control: any }) {
    if (Object.keys(event.control).length === 0) return;
    this.#propertyService
      .editPropertyMedia(event.control, this.propertyId(), event.id)
      .subscribe({ next: () => this.fetchDataAndPatchForm(), error: () => (this.isLoading = false) });
  }
}
