import { Component, computed, effect, inject, input, output, signal, WritableSignal } from "@angular/core";
import { FormControl } from "@angular/forms";
import { PropertiesEventsService } from "@root/data/market/properties/services/properties-events.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ICheckedItem } from "@root/shared/interfaces/checked-item.interface";
import { IAllCheckedItems } from "@root/shared/interfaces/checked-items.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { LIST_USERS_FOR_EVENT_HEADERS } from "@root/shared/list-users-for-event/list-users-for-event.headers";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { UserIdCardComponent } from "@root/shared/user-id-card/user-id-card.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";

@Component({
  selector: "est-list-users-for-event",
  standalone: true,
  imports: [SmallHeaderComponent, SearchInputComponent, SmallListTableComponent, PaginationComponent],
  templateUrl: "./list-users-for-event.component.html",
  styleUrl: "./list-users-for-event.component.scss",
})
export class ListUsersForEventComponent extends BasePaginatedTableWithSearchComponent {
  readonly #propertiesEventsService = inject(PropertiesEventsService);
  readonly propertyId = input.required<number>();
  readonly eventDate = input.required<string | null>();
  readonly checkboxControl = input<FormControl<number[]>>(
    new FormControl<number[]>([], {
      nonNullable: true,
    }),
  );
  readonly checkboxControlMapper = computed(
    () => new Map<string, FormControl>([["assignedUsersIds", this.checkboxControl()]]),
  );
  override headers: ITableHeader[] = LIST_USERS_FOR_EVENT_HEADERS;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  override loadDataEffect$ = effect(() => {
    this.loadData({
      eventDate: this.eventDate() || "",
      pageNumber: this.paginatedData.currentPage(),
      pageSize: this.paginatedData.pageSize(),
      search: this.searchSignal(),
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
    });
  });
  protected readonly UserIdCardComponent = UserIdCardComponent;

  readonly checkItem = output<ICheckedItem>();
  readonly checkAllItems = output<IAllCheckedItems>();

  override loadData(params: IPaginationSortPayload & { eventDate: string }): void {
    if (!this.eventDate()) return;
    this.isTableLoading = true;
    this.#propertiesEventsService.getAvailableUsers(this.propertyId(), params).subscribe({
      next: (data) => {
        copyPaginatedSignalResponse(this.paginatedData, data);
        this.isTableLoading = false;
      },
      error: () => (this.isTableLoading = false),
    });
  }
}
