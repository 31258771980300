import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { UnitType } from "@root/data/market/import/units/enums/unit-type.enum";
import { ImportType } from "@root/data/market/import/units/enums/import-type.enum";

export const IMPORT_DATA_NAVIGATION_LABELS = [
  "TOOLBOX.IMPORT_DATA.IMPORT_TYPE",
  "TOOLBOX.IMPORT_DATA.UPLOAD_CSV_FILE",
  "TOOLBOX.IMPORT_DATA.DATA_OVERVIEW",
  "TOOLBOX.IMPORT_DATA.FINALISE_IMPORT",
];

export const UNIT_TYPES_OPTIONS: IDropdownOption[] = [
  {
    label: "UNIT_TYPES.RESIDENTIAL",
    value: UnitType.Residential,
  },
];

export const IMPORT_TYPES_OPTIONS: IDropdownOption[] = [
  {
    label: "IMPORT_TYPES.UNIT_IMPORT",
    value: ImportType.Unit,
  },
];
