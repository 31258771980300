import { booleanAttribute, Component, EventEmitter, input, Output, ViewChild } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "est-select-option",
  standalone: true,
  imports: [MatCheckbox, TranslateModule, NgClass],
  templateUrl: "./select-option.component.html",
  styleUrl: "./select-option.component.scss",
})
export class SelectOptionComponent {
  label = input.required<string>();
  value = input.required<any>();
  initialSelected = input(false, { transform: booleanAttribute });
  disabled = input(false, { transform: booleanAttribute });
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("selected") checkbox!: MatCheckbox;

  toggleSelection() {
    if (this.disabled()) {
      return;
    }
    this.checkbox.toggle();
    this.selectionChanged.emit(this.value());
  }
}
