import { Component, DestroyRef, effect, inject, signal } from "@angular/core";
import {
  constructInitialSignalPaginatedResponse,
  copyPaginatedSignalResponse,
} from "@root/shared/utilities/signals.utilities";
import { UserDivisionRolesOverviewComponent } from "@root/shared/user-division-roles-overview/user-division-roles-overview.component";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { Observable } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { UserLoginStatus } from "@root/data/market/users/enums/user-login-status.enum";
import { UsersService } from "@root/data/market/users/services/users.service";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { RoleNameMapper } from "@root/data/market/users/mappers/role-name.mapper";
import { UserRole } from "@root/data/market/users/enums/user-role.enum";
import { TranslateService } from "@ngx-translate/core";
import { UpdateUserListCommunicationService } from "@root/views/main/organization/organization-settings/components/users/update-user-list-communication.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  standalone: true,
  template: "",
  styles: "",
})
export abstract class BaseUsersComponent extends BasePaginatedTableWithSearchComponent {
  readonly updateUserListService = inject(UpdateUserListCommunicationService);
  readonly #destroyRef = inject(DestroyRef);
  readonly translate = inject(TranslateService);
  readonly authService = inject(AuthService);
  dialog = inject(MatDialog);

  sortingFilterSignal = signal({
    sortBy: "number",
    sortDescending: false,
  });
  divisionSignal = signal<number | undefined>(undefined);
  loginStatusSignal = signal<UserLoginStatus | "">("");
  loadDataEffect$ = effect(() => {
    this.loadUserDate();
  });
  paginatedDivisionsOptions: ISignalPaginatedResponse<IDropdownOption> = constructInitialSignalPaginatedResponse();
  divisionsOptionsSearch = signal("");
  isDivisionsLoading = false;
  isNoMoreDivisionsResults = false;
  protected readonly usersService = inject(UsersService);
  protected readonly UserDivisionRolesOverviewComponent = UserDivisionRolesOverviewComponent;

  private resetPaginationOnFilteringEffect$ = effect(
    () => {
      this.divisionSignal();
      this.loginStatusSignal();
      this.paginatedData.currentPage.set(1);
    },
    { allowSignalWrites: true },
  );
  private readonly divisionsService = inject(DivisionService);
  private readonly searchDivisionsOptionsEffect$ = effect(
    () => {
      this.resetDivisionsOptions();
      this.loadDivisionsOptions(1);
    },
    {
      allowSignalWrites: true,
    },
  );

  loadUserDate() {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      ...(this.divisionSignal() ? { divisionId: this.divisionSignal() } : {}),
      ...(this.loginStatusSignal() ? { loginStatus: this.loginStatusSignal() } : {}),
    });
  }

  abstract getPaginatedSmallTableInputUsers(
    params: IPaginationSortPayload,
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>>;

  isMouseoverIconVisible(user: ISmallListTableInput): boolean {
    return !(
      user["organisationRole"] === RoleNameMapper.get(UserRole.SystemAdmin) || user["userDivisionRoles"].length === 0
    );
  }

  loadData(params: IPaginationSortPayload & { divisionId?: number; loginStatus?: UserLoginStatus | "" }): void {
    this.isTableLoading = true;
    this.getPaginatedSmallTableInputUsers(params).subscribe((paginatedUsers) => {
      copyPaginatedSignalResponse(this.paginatedData, paginatedUsers);
      this.isTableLoading = false;
    });
  }

  resetDivisionsOptions(): void {
    this.paginatedDivisionsOptions.currentPage.set(1);
    this.paginatedDivisionsOptions.results.set([]);
    this.isNoMoreDivisionsResults = false;
  }

  loadDivisionsOptions(pageNumber: number): void {
    this.paginatedDivisionsOptions.currentPage.set(pageNumber);
    this.isDivisionsLoading = true;
    this.divisionsService
      .getPaginatedDivisionOptions({
        search: this.divisionsOptionsSearch(),
        pageNumber,
        pageSize: this.paginatedDivisionsOptions.pageSize(),
      })
      .subscribe((divisionsOptions) => {
        this.paginatedDivisionsOptions.totalPages.set(divisionsOptions.totalPages());
        this.paginatedDivisionsOptions.results.set(
          this.paginatedDivisionsOptions.results().concat(divisionsOptions.results()),
        );
        this.isDivisionsLoading = false;
        if (divisionsOptions.results().length === 0) {
          this.isNoMoreDivisionsResults = true;
        }
      });
  }

  subscribeToUpdateList$() {
    this.updateUserListService.reloadUserListPage$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.loadUserDate();
    });
  }
}
