<div class="bg-white rounded-[0.625rem] r-screen-[25rem] relative p-6">
  <est-subheader
    date="Mon Feb 19 2024 14:20:06 GMT+0200"
    name="Jesper Thorkildsen"
    label="ECONOMY.NAME"
    tooltip="ECONOMY.NAME"
  ></est-subheader>
  <div class="mb-11"></div>
  <div class="relative">
    <div class="flex justify-between items-center">
      <div class="flex gap-2 items-center mb-2">
        <p class="text-base font-medium">{{ "ECONOMY.LISTING_DETAILS" | translate }}</p>
        <button
          [matTooltip]="'ECONOMY.LISTING_DETAILS' | translate"
          class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        >
          ?
        </button>
      </div>
    </div>
    <form [formGroup]="economyFormGroup">
      <est-payment-frequency-select-field
        [control]="paymentFrequencyControl"
        appliedClasses="mt-2"
        (valueChanged)="updateEconomyDetails($event, 'paymentFrequency')"
      ></est-payment-frequency-select-field>
      <est-currency-conversion
        [unitId]="unitId()"
        [control]="baseRentControl"
        [currencyCode]="defaultPaymentCurrency()"
        [postText]="getPaymentIntervalAndCurrency()"
        (selectedCurrencies)="getSelectedCurrencies($event)"
        (inputFocusout)="updateEconomyDetails($event, 'baseRent')"
      ></est-currency-conversion>
      @for (value of otherCurrenciesControl.value; track $index) {
        <div>
          <est-currency-field
            label="ECONOMY.BASE_RENT"
            [postfixText]="value?.iso + '/' + this.paymentIntervalSignal()"
            appliedClass="!mt-4"
            [value]="
              calculateBaseRentFromExchangeRate(
                value.exchangeRate,
                baseRentControl.value ? baseRentControl.value : 0,
                value.iso
              )
            "
          ></est-currency-field>
        </div>
      }

      <hr class="border-black-100 absolute start-0 end-0 my-6" />
      <div class="flex gap-2 items-center mt-10">
        <p class="text-base font-medium">{{ "ECONOMY.OPERATIONAL_COSTS" | translate }}</p>
        <button
          [matTooltip]="'ECONOMY.OPERATIONAL_COSTS' | translate"
          class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        >
          ?
        </button>
      </div>
      <div class="flex gap-3 flex-wrap items-baseline">
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="acontoHeatControl"
          label="ECONOMY.HEAT_PAYMENT"
          [postfixText]="getPaymentIntervalAndCurrency()"
          appliedClass="!mt-4"
          (focusout)="updateEconomyDetails($event, 'acontoHeat')"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="acontoWaterControl"
          label="ECONOMY.WATER_PAYMENT"
          [postfixText]="getPaymentIntervalAndCurrency()"
          (focusout)="updateEconomyDetails($event, 'acontoWater')"
        ></est-postfix-text-input>
      </div>
      <div class="flex gap-3 flex-wrap items-baseline mb-4">
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="acontoElectricityControl"
          label="ECONOMY.ELECTRICITY_PAYMENT"
          appliedClass="!mt-4 "
          [postfixText]="getPaymentIntervalAndCurrency()"
          (focusout)="updateEconomyDetails($event, 'acontoElectricity')"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="acontoCoolingControl"
          label="ECONOMY.COOLING_PAYMENT"
          [postfixText]="getPaymentIntervalAndCurrency()"
          (focusout)="updateEconomyDetails($event, 'acontoCooling')"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="acontoOperatingCostsControl"
          label="ECONOMY.OPERATION_COSTS_PAYMENT"
          [postfixText]="getPaymentIntervalAndCurrency()"
          (focusout)="updateEconomyDetails($event, 'acontoOperatingCosts')"
        ></est-postfix-text-input>
      </div>
      <div class="flex gap-3 flex-wrap items-baseline">
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="residentsRepresentationFeeControl"
          label="ECONOMY.RESIDENTS_REPRESENTATION_FEE"
          (focusout)="updateEconomyDetails($event, 'residentsRepresentationFee')"
          [postfixText]="getPaymentIntervalAndCurrency()"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="antennaFeeControl"
          label="ECONOMY.ANTENNA_FEE"
          [postfixText]="getPaymentIntervalAndCurrency()"
          (focusout)="updateEconomyDetails($event, 'antennaFee')"
        ></est-postfix-text-input>
        <est-postfix-text-input
          [currencyCode]="defaultPaymentCurrency()"
          applyNegativeValuesValidation
          [control]="internetFeeControl"
          label="ECONOMY.INTERNET_FEE"
          [postfixText]="getPaymentIntervalAndCurrency()"
          (focusout)="updateEconomyDetails($event, 'internetFee')"
        ></est-postfix-text-input>
      </div>
    </form>
    @if (isLoading) {
      <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
    }
  </div>
</div>
