import { JsonPipe } from "@angular/common";
import { Component, computed, inject, OnInit } from "@angular/core";
import { FormGroup, NonNullableFormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { FileType } from "@root/shared/enums/file-type.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { getFileExtension } from "@root/shared/utilities/files.utilities";
import { IMediaDetailsInputs } from "@root/views/main/property/property-marketing/components/media-details/media-details-inputs.interface";
import { PROPERTY_MEDIA_TYPE_OPTIONS } from "@root/views/main/property/property-marketing/constants/property-media.constants";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { PropertyMediaType } from "@root/data/market/properties/enums/property-media-type.enum";
import { IMediaFormGroup } from "@root/views/main/property/property-marketing/components/media-form-group.interface";
import { TextAreaComponent } from "@root/shared/text-area/text-area.component";
import { InputComponent } from "@root/shared/input/input.component";
import { StatusBadgeComponent } from "@root/shared/status-badge/status-badge.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MediaQuality } from "@root/shared/enums/media-quality.enum";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-media-details",
  standalone: true,
  templateUrl: "./media-details.component.html",
  styleUrl: "./media-details.component.scss",
  imports: [
    JsonPipe,
    DialogHeaderComponent,
    TranslateModule,
    CustomDatePipe,
    SelectFieldComponent,
    SelectFieldComponent,
    TextAreaComponent,
    InputComponent,
    StatusBadgeComponent,
    ButtonComponent,
  ],
})
export class MediaDetailsComponent implements OnInit {
  readonly matDialog = inject(MatDialog);
  readonly #fb = inject(NonNullableFormBuilder);
  protected readonly FileType = FileType;
  protected readonly getFileExtension = getFileExtension;
  protected readonly MediaQuality = MediaQuality;
  protected dialogRef: MatDialogRef<MediaDetailsComponent> = inject(MatDialogRef);

  mediaTypeOptions: IDropdownOption[] = PROPERTY_MEDIA_TYPE_OPTIONS;
  componentInputs: IMediaDetailsInputs = inject(MAT_DIALOG_DATA);
  mediaUrl = computed(() => {
    const file = this.componentInputs.mediaFormGroup.controls.file.value;
    if (file) return URL.createObjectURL(file);
    else return this.componentInputs.filePath;
  });
  formGroup!: FormGroup<IMediaFormGroup>;

  ngOnInit() {
    this.#copyFormGroup();
  }

  saveChanges() {
    this.componentInputs.mediaFormGroup.patchValue(this.formGroup.value);
    console.log(this.componentInputs.mediaFormGroup.controls.mediaText.value);
    this.dialogRef.close({
      id: this.formGroup.controls.id.value,
      control: updateNestedControlsPathAndValue(this.formGroup),
    });
  }

  #copyFormGroup() {
    const originalFormGroup = this.componentInputs.mediaFormGroup;
    this.formGroup = this.#fb.group<IMediaFormGroup>({
      id: this.#fb.control<number | undefined>(originalFormGroup.controls.id.value),
      file: this.#fb.control<File>(originalFormGroup.controls.file.value!),
      mediaType: this.#fb.control<PropertyMediaType | undefined>(originalFormGroup.controls.mediaType.value),
      fileType: this.#fb.control<FileType>(originalFormGroup.controls.fileType.value),
      alternateText: this.#fb.control<string | undefined>(originalFormGroup.controls.alternateText.value),
      title: this.#fb.control<string | undefined>(originalFormGroup.controls.title.value),
      mediaText: this.#fb.control<string | undefined>(originalFormGroup.controls.mediaText.value),
      description: this.#fb.control<string | undefined>(originalFormGroup.controls.description.value),
      filePath: this.#fb.control<string | undefined>(originalFormGroup.controls.filePath?.value),
      width: this.#fb.control<number | undefined>(originalFormGroup.controls.width.value),
      height: this.#fb.control<number | undefined>(originalFormGroup.controls.height.value),
    });
  }
}
