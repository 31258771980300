import { Component, effect, inject, input, output } from "@angular/core";
import { BaseCheckboxTreeComponent } from "@root/shared/abstracts/base-checkbox-tree/base-checkbox-tree.component";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { IDivisionTree } from "@root/shared/interfaces/division-tree.interface";
import { IFlatNodeItem } from "@root/shared/interfaces/division-tree-flat-node-item.interface";
import { IPropertiesParams } from "@root/shared/interfaces/properties-params.interface";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CommonModule } from "@angular/common";
import { INodeItem } from "@root/shared/interfaces/division-tree-node-item.interface";
import { PropertyTypeTranslationMapper } from "@root/data/market/properties/mappers/property-type-translation.mapper";
import { TranslateModule } from "@ngx-translate/core";
import { DIVISION_HIERARCHY_RESULT } from "../constants/global.constants";
import { propertyNodeCreators, nodeCreatorIdentifier } from "./Mappers/assign-properties-to-tree.mapper";

@Component({
  selector: "est-assign-properties-to-team-tree",
  standalone: true,
  templateUrl: "./assign-properties-to-team-tree.component.html",
  styleUrl: "./assign-properties-to-team-tree.component.scss",
  imports: [
    SearchInputComponent,
    SpinnerComponent,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    CommonModule,
    TranslateModule,
  ],
})
export class AssignPropertiesToTeamTreeComponent extends BaseCheckboxTreeComponent<IDivisionTree, IPropertiesParams> {
  teamId = input.required<number>();
  totalProperties = output<number>();
  selectedPropertyIdsChange = output<number[]>();
  isLoading: boolean = false;
  dataResponse!: IDivisionTree;
  propertiesService = inject(PropertiesService);
  propertyTypeTranslationMapper = PropertyTypeTranslationMapper;
  override loadDataEffect$ = effect(() => {
    this.loadData({
      search: this.searchSignal(),
      teamId: this.teamId(),
    });
  });
  DIVISION_HIERARCHY_RESULT = DIVISION_HIERARCHY_RESULT;
  override loadData(params: IPropertiesParams): void {
    this.isLoading = true;
    this.propertiesService.getPropertiesHierarchy(params).subscribe((res) => {
      this.dataSource.data = this.mapResponseToTree(res);
      this.dataResponse = res;
      this.totalProperties.emit(res.propertiesCount);
      this.checkPreSelected(res);
      this.isLoading = false;
    });
  }

  checkPreSelected(divisionData: IDivisionTree) {
    const nodeMap = new Map(this.treeControl.dataNodes.map((node) => [node.id, node]));
    const allProperties = divisionData.division.companies.flatMap((company) => company.properties);
    allProperties.forEach((property) => {
      if (property.hasTeam) {
        const node = nodeMap.get(property.id);
        if (node) {
          this.lastChildSelectionToggle(node);
        }
      }
    });

    this.updateSelectedProperties(this.dataResponse);
  }

  // override functions to implement the final selection payload logic based on the response
  override itemSelectionToggle = (node: IFlatNodeItem): void => {
    this.parentItemSelectionToggle(node);
    this.checkAllParentsSelection(node);
    this.updateSelectedProperties(this.dataResponse);
  };

  override lastChildSelectionToggle = (node: IFlatNodeItem): void => {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.updateSelectedProperties(this.dataResponse);
  };

  // prepare total selected properties ids for payload
  updateSelectedProperties(divisionData: IDivisionTree): void {
    const allProperties = divisionData.division.companies.flatMap((company) => company.properties);
    const nodeMap = new Map(this.treeControl.dataNodes.map((node) => [node.id, node]));
    const selectedPropertyIds = allProperties
      .filter((property) => {
        const node = nodeMap.get(property.id);
        return node && this.checklistSelection.isSelected(node);
      })
      .map((property) => property.id);
    this.selectedPropertyIdsChange.emit(selectedPropertyIds);
  }

  getPropertyDetails(node: IFlatNodeItem, divisionData: IDivisionTree): { unitsNumber: number; listingType: string } {
    const allProperties = divisionData.division.companies.flatMap((company) => company.properties);
    const property = allProperties.find((property) => property.id === node.id);
    if (property) {
      const unitNumber = property.unitsNumber;
      const propertyType = this.propertyTypeTranslationMapper.get(property.listingType)!;
      return { unitsNumber: unitNumber, listingType: propertyType };
    }

    return { unitsNumber: 0, listingType: "" };
  }

  createNode(type: string, data?: any, index?: number): INodeItem {
    const nodeCreator = propertyNodeCreators.get(type);
    if (nodeCreator) {
      return nodeCreator(data, index);
    } else {
      throw new Error(`Invalid node type: ${type}`);
    }
  }

  override mapResponseToTree(data: IDivisionTree): INodeItem[] {
    const rootNode = this.createNode(nodeCreatorIdentifier.ROOT, data);

    if (data.division.companies.length === 0) {
      const noCompanyNode = this.createNode(nodeCreatorIdentifier.NO_COMPANY);
      rootNode.children?.push(noCompanyNode);
    } else {
      data.division.companies.forEach((company: any) => {
        const companyNode = this.createNode(nodeCreatorIdentifier.COMPANY, company);
        if (company.properties && company.properties.length > 0) {
          company.properties.forEach((property: any, index: number) => {
            const propertyNode = this.createNode(nodeCreatorIdentifier.PROPERTY, property, index);
            companyNode.children?.push(propertyNode);
          });
        } else if (company.properties && company.properties.length === 0) {
          const noPropertiesNode = this.createNode(nodeCreatorIdentifier.NO_PROPERTIES);
          companyNode.children?.push(noPropertiesNode);
        }

        rootNode.children?.push(companyNode);
      });
    }

    return [rootNode];
  }

  disableNodeCheckbox(node: IFlatNodeItem): boolean {
    if (this.flatNodeMap.get(node)?.children?.some((child) => child.item === DIVISION_HIERARCHY_RESULT.NO_COMPANIES)) {
      return true;
    }

    return (
      this.flatNodeMap.get(node)?.children?.some((child) => {
        return Object.values(child).some((value) => {
          return value === DIVISION_HIERARCHY_RESULT.NO_PROPERTIES;
        });
      }) ?? false
    );
  }
}
