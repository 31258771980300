<div class="mt-10" [ngClass]="appliedClasses()">
  <div class="flex gap-2 items-center mb-4">
    <p class="font-medium">{{ RoomTypeNameTranslationMapper.get(roomType())! | translate }}</p>
    <est-tooltip [tooltip]="RoomTypeNameTranslationMapper.get(roomType())!"></est-tooltip>
  </div>
  <est-select-field
    appliedClass="mb-4"
    [dropDownLabel]="RoomTypeQuantityTranslationMapper.get(roomType())!"
    [dropDownOptions]="quantityOptions"
    [selectMultipleOptions]="false"
    [control]="quantityControl()"
    (changeSelectValue)="updateRoomCount($event)"
  ></est-select-field>

  @if (doesTotalRoomCountMatch()) {
    <p class="text-error-100 text-xs font-light mb-4">
      {{ "UNIT_LAYOUT.TOTAL_NUMBER_OF_ROOMS_MATCH_ERROR" | translate }}
    </p>
  }

  <div class="flex flex-col gap-4 mb-4 relative">
    @for (formGroup of formArray().controls; track formGroup; let index = $index) {
      <div class="flex flex-col">
        <p class="text-base font-medium mb-2">
          {{ RoomTypeNameTranslationMapper.get(roomType())! | translate }} #{{ index + 1 }}
        </p>

        <div class="flex gap-4 flex-wrap items-baseline">
          @if (roomType() === RoomType.Terrace) {
            <est-select-field
              appliedClass="!w-[9.375rem]"
              dropDownLabel="TYPE"
              [control]="castControlFromAbstractToFormControl(formGroup.controls['terraceTypeId'])"
              [dropDownOptions]="terraceTypes()"
              [selectMultipleOptions]="false"
              (changeSelectValue)="valuesChanged.emit()"
            ></est-select-field>
          }

          @if (roomType() === RoomType.Balcony) {
            <est-select-field
              appliedClass="!w-[9.375rem]"
              dropDownLabel="TYPE"
              [control]="castControlFromAbstractToFormControl(formGroup.controls['balconyTypeId'])"
              [dropDownOptions]="balconyTypes()"
              [selectMultipleOptions]="false"
              (changeSelectValue)="valuesChanged.emit()"
            ></est-select-field>
          }

          @if (roomType() === RoomType.Restroom) {
            <est-select-field
              appliedClass="!w-[9.375rem]"
              dropDownLabel="TYPE"
              [control]="castControlFromAbstractToFormControl(formGroup.controls['restroomType'])"
              [dropDownOptions]="restroomTypes"
              [selectMultipleOptions]="false"
              (changeSelectValue)="valuesChanged.emit()"
            ></est-select-field>
          }

          <est-select-field
            dropDownLabel="NAME"
            [control]="castControlFromAbstractToFormControl(formGroup.controls['roomNameId'])"
            [dropDownOptions]="roomNameDropdownOptions"
            (changeSelectValue)="connectionRoomNameChanged($event, formGroup.controls['id'].value, index)"
            [selectMultipleOptions]="false"
          ></est-select-field>

          @if (RoomTypeHasConnectedRoom.get(roomType())!) {
            <est-select-field
              dropDownLabel="UNIT_LAYOUT.CONNECTED_ROOM"
              [control]="castControlFromAbstractToFormControl(formGroup.controls['connectedRoomId'])"
              [dropDownOptions]="connectionRooms()"
              [selectMultipleOptions]="false"
              (changeSelectValue)="valuesChanged.emit()"
            ></est-select-field>
          } @else {
            <est-select-field
              dropDownLabel="LOCATION"
              [control]="castControlFromAbstractToFormControl(formGroup.controls['location'])"
              [dropDownOptions]="internalFloors()"
              [selectMultipleOptions]="false"
              (changeSelectValue)="valuesChanged.emit()"
            ></est-select-field>
          }

          <est-postfix-text-input
            appliedClass="!w-[9.375rem]"
            label="UNIT_LAYOUT.ROOM_AREA"
            [control]="castControlFromAbstractToFormControl(formGroup.controls['area'])"
            postfixText="m<sup>2</sup>"
            type="number"
            applyNegativeValuesValidation
          ></est-postfix-text-input>

          @if (roomType() !== RoomType.InternalStorageRoom) {
            <est-button
              label="DETAILS.NAME"
              buttonStyleType="button--secondary"
              buttonType="button"
              appliedClasses="!h-[1.5rem]"
              (click)="openRoomPopup(formGroup.controls['id'].value, index, formGroup.controls['roomNameId'].value)"
            ></est-button>
          }
        </div>
      </div>
    }
  </div>
  <est-end-user-visibility-input
    [control]="visibleControl()"
    (selectionChanged)="valuesChanged.emit()"
  ></est-end-user-visibility-input>
</div>
