import { Component, input } from "@angular/core";
import { IPropertyFacilityTitle } from "@root/data/market/properties/models/property-facility-title.model";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";

@Component({
  selector: "est-facility-details",
  standalone: true,
  imports: [TranslateModule, CustomDatePipe],
  templateUrl: "./facility-details.component.html",
  styleUrl: "./facility-details.component.scss",
})
export class FacilityDetailsComponent {
  lastChangedDate = input.required<string>();
  iconUrl = input<string>();
  internalTitle = input.required<IPropertyFacilityTitle>();
  externalTitles = input.required<IPropertyFacilityTitle[]>();
}
