import { Routes } from "@angular/router";
import { FacilitiesComponent } from "@root/views/main/property/property-marketing/components/facilities/facilities.component";
import { MediasComponent } from "@root/views/main/property/property-marketing/components/medias/medias.component";
import { TextsComponent } from "@root/views/main/property/property-marketing/components/texts/texts.component";
import { facilitiesRoutes } from "@root/views/main/property/property-marketing/components/facilities/facilities.routes";
import { propertyMediaPackageRoutes } from "@root/views/main/property/property-marketing/components/medias/property-media-package.routes";
import { PropertyTextsRoutes } from "./components/texts/property-texts.routes";

export const propertyMarketingRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "facilities",
  },
  {
    path: "facilities",
    component: FacilitiesComponent,
    data: {
      name: "PROPERTY_FACILITIES.FACILITIES.NAME",
    },
    children: facilitiesRoutes,
  },
  {
    path: "medias",
    component: MediasComponent,
    data: {
      name: "PROPERTY.MARKETING.MEDIAS",
    },
    children: propertyMediaPackageRoutes,
  },
  {
    path: "texts",
    component: TextsComponent,
    data: {
      name: "PROPERTY.MARKETING.TEXTS",
    },
    children: PropertyTextsRoutes,
  },
];
