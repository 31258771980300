import { Component, inject } from "@angular/core";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { SelectSearchFieldComponent } from "../select-search-field/select-search-field.component";
import { ISignalPaginatedResponse } from "../interfaces/signal-paginated-response.interface";
import { LookupsService } from "../../data/market/lookups/services/lookups.service";
import { BaseCompositeSelectSearchFieldComponent } from "../abstracts/base-composite-select-search-field/base-composite-select-search-field.component";
import { Observable } from "rxjs";

@Component({
  selector: "est-company-select-field",
  standalone: true,
  imports: [SelectSearchFieldComponent],
  templateUrl: "./company-select-field.component.html",
  styleUrl: "./company-select-field.component.scss",
})
export class CompanySelectFieldComponent extends BaseCompositeSelectSearchFieldComponent {
  private readonly lookupsService = inject(LookupsService);

  override getHttpServiceCall(pageNumber: number): Observable<ISignalPaginatedResponse<IDropdownOption>> {
    return this.lookupsService.getCompaniesLookupOptions({
      search: this.searchSignal(),
      pageNumber,
      pageSize: this.paginatedOptions.pageSize(),
      ...(this.control().value && { selectedId: this.control().value }),
    });
  }
}
