import { Component, inject, OnInit } from "@angular/core";
import { BaseSystemAdminForm } from "@root/shared/abstracts/system-admin-form/system-admin-form.abstract";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InputComponent } from "@root/shared/input/input.component";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { MAT_DIALOG_DATA, MatDialogClose } from "@angular/material/dialog";
import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { ImageUploadComponent } from "@root/shared/image-upload/image-upload.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { UpdateUserListCommunicationService } from "@root/views/main/organization/organization-settings/components/users/update-user-list-communication.service";
import { ILanguage } from "@root/shared/interfaces/language.interface";

@Component({
  selector: "est-edit-system-admin",
  standalone: true,
  imports: [
    ButtonComponent,
    InputComponent,
    LanguageDropdownComponent,
    MatDialogClose,
    NgIf,
    PhoneInputComponent,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    MatTooltip,
    ImageUploadComponent,
    NgOptimizedImage,
    DialogHeaderComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./edit-system-admin.component.html",
  styleUrl: "./edit-system-admin.component.scss",
})
export class EditSystemAdminComponent extends BaseSystemAdminForm implements OnInit {
  componentInputs: { userId: number } = inject(MAT_DIALOG_DATA);
  isPageLoading: boolean = true;
  readonly updateUserListService = inject(UpdateUserListCommunicationService);

  editSystemAdmin() {
    this.userService.editUser(this.systemAdminForm, true, this.componentInputs.userId).subscribe(() => {
      this.closeDialog();
      this.updateUserListService.reloadUserListPage$.next();
    });
  }

  ngOnInit() {
    this.retrieveSystemLanguages();
    this.getSystemAdminDetails();
  }

  getSystemAdminDetails() {
    this.userService.getUserDetailsById(this.componentInputs.userId).subscribe((details) => {
      this.isPageLoading = false;
      this.systemAdminForm.patchValue({
        ...details.userDTO,
        phoneNumber: {
          internationalNumber: details.userDTO.phoneNumber,
          countryCode: details.userDTO.phoneCountryCode,
          number: details.userDTO.phoneNumber,
        },
      });
    });
  }

  retrieveSystemLanguages(): void {
    this.userService.getAllLanguages().subscribe((languages: ILanguage[]) => {
      this.languages = languages;
    });
  }
}
