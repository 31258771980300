import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { AdditionalLanguageEntryComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/language/components/additional-language-entry/additional-language-entry.component";
import { IAllowedLanguage } from "@root/data/market/system-configurations/language/models/other-language.model";
import { SystemConfigurationsService } from "@root/data/market/system-configurations/services/system-configurations.service";
import { NonNullableFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { LanguageDropdownComponent } from "@root/shared/language-dropdown/language-dropdown.component";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { UsersService } from "@root/data/market/users/services/users.service";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";
import { MatDialog } from "@angular/material/dialog";
import { AddLanguagesPopupComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/language/components/add-languages-popup/add-languages-popup.component";
import { IAddLanguagesComponentInputs } from "@root/views/main/organization/organization-settings/components/system-configurations/components/language/components/add-languages-popup/add-languages.component-inputs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "est-language",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    SelectFieldComponent,
    ButtonComponent,
    AdditionalLanguageEntryComponent,
    LanguageDropdownComponent,
    LoadingOverlayComponent,
    ReactiveFormsModule,
  ],
  templateUrl: "./language.component.html",
  styleUrl: "./language.component.scss",
})
export class LanguageComponent implements OnInit {
  readonly #systemConfigurationService = inject(SystemConfigurationsService);
  readonly #userService = inject(UsersService);
  readonly #fb = inject(NonNullableFormBuilder);
  readonly #matDialog = inject(MatDialog);
  readonly #destroyRef = inject(DestroyRef);
  protected isLoading: boolean = false;
  protected languageOptions: ILanguage[] = [];
  protected otherLanguages: IAllowedLanguage[] = [];

  defaultSystemLanguage = this.#fb.control("");
  defaultEndUserLanguage = this.#fb.control("");

  formGroup = this.#fb.group({
    defaultSystemLanguageId: this.defaultSystemLanguage,
    defaultEndUserLanguageId: this.defaultEndUserLanguage,
  });

  ngOnInit(): void {
    this.isLoading = true;
    this.#userService.getAllLanguages().subscribe((languages) => {
      this.languageOptions = languages;
      this.#getLanguageConfiguration();
    });
  }

  #getLanguageConfiguration() {
    this.isLoading = true;
    this.#systemConfigurationService.getLanguageSystemConfiguration().subscribe((languageConfiguration) => {
      this.defaultSystemLanguage.patchValue(languageConfiguration.defaultSystemLanguage.languageId!);
      this.defaultEndUserLanguage.patchValue(languageConfiguration.defaultEndUserLanguage.languageId!);
      this.otherLanguages = languageConfiguration.allowedLanguages;
      this.isLoading = false;
    });
  }

  patchChangedValues() {
    const controlPath = updateNestedControlsPathAndValue(this.formGroup);
    if (Object.keys(controlPath).length) {
      this.#systemConfigurationService.patchSystemLanguageConfiguration(controlPath).subscribe();
    }
  }

  removeAllowedLanguageFromList(allowedLanguage: IAllowedLanguage) {
    this.otherLanguages = this.otherLanguages.filter((x) => x.id !== allowedLanguage.id);
  }

  openAddLanguagesPopup() {
    const disabledLanguages = this.otherLanguages.map((x) => x.language);
    disabledLanguages.push(this.languageOptions.find((x) => x.languageId === this.defaultEndUserLanguage.value)!);

    this.#matDialog
      .open<AddLanguagesPopupComponent, IAddLanguagesComponentInputs>(AddLanguagesPopupComponent, {
        data: {
          disabledLanguages: disabledLanguages,
          languages: this.languageOptions,
        },
        width: "28.125rem",
        height: "32.0625rem",
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => this.#getLanguageConfiguration());
  }
}
