<form (ngSubmit)="submitForm()">
  <div class="sticky top-0 bg-white z-10">
    <div class="flex border-b border-black-100 py-6 px-6 justify-between">
      <div class="flex gap-2 items-center sticky top-0">
        <p class="text-xl font-medium mb-1">
          {{ "TEAMS.EDIT_TEAM" | translate }}
        </p>
        <button
          type="button"
          [matTooltip]="'TEAMS.EDIT_TEAM' | translate"
          class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        >
          ?
        </button>
      </div>
      <button (click)="closeDialog()" type="button">
        <img
          [alt]="'CLOSE_ICON' | translate"
          height="44"
          ngSrc="assets/images/icons/close-icon.svg"
          priority
          width="44"
        />
      </button>
    </div>
    <mat-progress-bar [value]="getProgressbarValue()"></mat-progress-bar>
  </div>
  @if (!isPageLoading) {
    <div class="pb-8 h-[calc(100dvh-14rem)] overflow-y-auto">
      <mat-stepper #stepper [linear]="true" animationDuration="0">
        <!----------------------------------------------- start mat step 1    ----------------------------------------------->
        <mat-step [stepControl]="teamFormGroup">
          <div class="grid grid-cols-8">
            <div class="col-span-6 max-breakpoint-ipad-screen:col-span-8">
              <div class="border-b">
                <div class="px-6 py-4">
                  <p class="font-bold mb-4">{{ "TEAMS.EDIT_EXISTING_TEAM" | translate }}</p>
                  <p class="font-light text-sm">{{ "TEAMS.A_TEAM_BELONG_TO_DIVISION" | translate }}</p>
                  <p class="font-light text-sm mb-7">{{ "TEAMS.SYSTEM_USERS_GAINS_ACCESS" | translate }}</p>
                  <p class="font-medium mb-4">{{ "DIVISIONS.DIVISION" | translate }}</p>
                  <p class="font-light text-sm mb-6 w-9/12">
                    {{ "TEAMS.SELECT_DIVISION_UNDER_THE_TEAM" | translate }}
                  </p>
                  <est-select-division-field
                    [control]="divisionId"
                    [selectSignal]="selectedDivisionId"
                  ></est-select-division-field>
                </div>
              </div>
              <div class="border-b">
                <div class="p-6">
                  <p class="font-medium mb-2">{{ "TEAMS.TEAM_NAME" | translate }}</p>
                  <p class="font-light text-sm mb-6">{{ "TEAMS.GIVE_THE_TEAM_NAME" | translate }}</p>
                  <est-input
                    [control]="name"
                    [appliedClass]="'w-[18.75rem]'"
                    [label]="'TEAMS.TEAM_NAME'"
                    [name]="'name'"
                  >
                  </est-input>
                </div>
              </div>
              <div class="p-6">
                <p class="font-medium mb-2">{{ "TEAMS.TEAM_MANAGER" | translate }}</p>
                <p class="font-light text-sm mb-6">
                  {{ "TEAMS.A_TEAM_MUST_ALWAYS_HAVE_MANAGER" | translate }}
                </p>
                <est-select-user-field
                  [control]="teamManagerId"
                  [divisionId]="selectedDivisionId()"
                ></est-select-user-field>
              </div>
            </div>

            <!-- ----------------------------------------------------------image-------------------------------------------------------------------------->
            <div
              class="col-span-2 border-l pt-4 px-6 max-breakpoint-ipad-screen:col-span-8 max-breakpoint-ipad-screen:border-t max-breakpoint-ipad-screen:flex max-breakpoint-ipad-screen:justify-start"
            >
              <est-image-upload
                [title]="'TEAMS.TEAM_IMAGE'"
                [description]="'TEAMS.GIVE_THE_TEAM_RECOGNISABLE'"
                [imageFormControl]="profilePicture"
                [imagePlaceHolderText]="name.value"
              ></est-image-upload>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="teamFormGroup">
          <div class="p-6 relative">
            @if (isRequestLoading) {
              <div class="absolute inset-0 bg-white/50 flex items-center justify-center z-10"></div>
            }
            <p class="text-sm font-medium mb-4">{{ "TEAMS.WHICH_USERS_SHOULD_BE_MEMBERS" | translate }}</p>
            <p class="text-sm font-light">{{ "TEAMS.BELOW_IS_A_LIST" | translate }}</p>
            <est-team-member-table
              [controlMapper]="controlMapper"
              [divisionId]="divisionId.value"
              [mangerId]="teamManagerId"
              [stepIndex]="stepper.selectedIndex"
              [isSelectedTeamMangerError]="isSelectedTeamMangerId"
            ></est-team-member-table>
          </div>
        </mat-step>
      </mat-stepper>
    </div>

    <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
      @if (stepper.selectedIndex === 0) {
        <est-button
          [label]="'CANCEL'"
          [appliedClasses]="'!py-3 !px-6'"
          (click)="closeDialog()"
          buttonType="button"
          buttonStyleType="button--tertiary"
        >
        </est-button>
      } @else if (stepper.selectedIndex !== 2) {
        <est-button
          [label]="'BACK'"
          [appliedClasses]="'!py-3 !px-6'"
          (click)="stepper.previous()"
          buttonStyleType="button--tertiary"
          buttonType="button"
          [disabled]="isRequestLoading"
        >
        </est-button>
      }
      <div class="flex gap-3">
        @if (stepper.selectedIndex === 0) {
          <est-button
            [label]="'SAVE_AND_CLOSE'"
            [appliedClasses]="'!py-3 !px-6'"
            (click)="submitForm()"
            buttonStyleType="button--secondary"
            buttonType="button"
            [isLoading]="isRequestLoading"
          >
          </est-button>
          <est-button
            (click)="moveToNextStepIfValid()"
            [label]="'NEXT'"
            [appliedClasses]="'!py-3 !px-6'"
            buttonStyleType="button--primary"
            buttonType="button"
            [isLoading]="isTableLoading"
          >
          </est-button>
        } @else {
          <est-button
            [label]="'SAVE'"
            [appliedClasses]="'!py-3 !px-6 me-4'"
            (click)="submitForm()"
            buttonStyleType="button--primary"
            buttonType="button"
            [isLoading]="isRequestLoading"
          >
          </est-button>
        }
      </div>
    </div>
  } @else {
    <div class="py-32 relative">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</form>
