import { Component, DestroyRef, effect, inject, signal, WritableSignal } from "@angular/core";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { MatDialog } from "@angular/material/dialog";
import { CreateCompanyFormComponent } from "./form-create-company/create-company-form.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgOptimizedImage } from "@angular/common";
import { GeographySelectFieldComponent } from "@root/shared/geography-select-field/geography-select-field.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { divisionCompaniesHeaders } from "./division-companies.headers";
import { CompaniesService } from "@root/data/market/companies/services/companies.service";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { LargeListTableComponent } from "@root/shared/large-list-table/large-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { first } from "rxjs";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { Router } from "@angular/router";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { EditCompanyComponent } from "@root/views/main/division/division-companies/edit-company/edit-company.component";
import { CompaniesCommunicationService } from "@root/views/main/division/division-companies/companies-communication.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";

@Component({
  selector: "est-division-companies",
  standalone: true,
  imports: [
    NgOptimizedImage,
    SubheaderComponent,
    TranslateModule,
    GeographySelectFieldComponent,
    SearchInputComponent,
    ButtonComponent,
    LargeListTableComponent,
    PaginationComponent,
    SmallHeaderComponent,
  ],
  templateUrl: "./division-companies.component.html",
  styleUrl: "./division-companies.component.scss",
})
export class DivisionCompaniesComponent extends BasePaginatedTableWithSearchComponent {
  dialog = inject(MatDialog);
  #router = inject(Router);
  destroyRef = inject(DestroyRef);
  #companyCommunicationService = inject(CompaniesCommunicationService);
  readonly #companyReloadSubscription$ = this.#companyCommunicationService.reloadCompaniesTable$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      this.refreshTable();
    });
  readonly #companiesService = inject(CompaniesService);
  readonly #dialogService = inject(DialogService);
  headers = divisionCompaniesHeaders.map((header) => {
    if (header.key === "name") {
      return {
        ...header,
        routerLink: (id: any) => this.goToCompany(id),
      };
    }
    return header;
  });
  citySignal = signal<string>("");
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  readonly #isDeletingCompany = signal(false);

  override actions: ITableAction[] = [
    {
      callbackFn: (row: ILargeListTableInput) => this.openEditCompanyModal(row),
      labelFn: () => "COMPANIES.EDIT_COMPANY",
    },
    {
      callbackFn: (row: ILargeListTableInput) => this.goToCompany(row.id),
      labelFn: () => "COMPANIES.GO_TO_COMPANY",
    },
    {
      callbackFn: (row: ILargeListTableInput) => this.openDeleteDialog(row),
      labelFn: () => "COMPANIES.DELETE_COMPANY.TITLE",
    },
  ];

  refreshTable = () => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      ...(this.citySignal() ? { city: this.citySignal() } : {}),
    });
  };
  readonly loadDataEffect$ = effect(this.refreshTable);

  override loadData(params: IPaginationSortPayload): void;
  loadData(params: IPaginationSortPayload & { city?: string }): void {
    this.isTableLoading = true;
    this.#companiesService.getCompanies(params).subscribe((paginatedProperties) => {
      copyPaginatedSignalResponse(this.paginatedData, paginatedProperties);
      this.isTableLoading = false;
    });
  }

  openCreateCompanyModal() {
    const onClose = this.dialog
      .open(CreateCompanyFormComponent, {
        minWidth: "75dvw",
        maxHeight: "95dvh",
      })
      .afterClosed();
    onClose.pipe(first()).subscribe(() => {
      this.refreshTable();
    });
  }

  openEditCompanyModal(row: ILargeListTableInput) {
    this.dialog.closeAll();
    this.dialog
      .open(EditCompanyComponent, {
        minWidth: "75dvw",
        maxHeight: "95dvh",
        data: {
          companyId: row.id,
        },
      })
      .afterClosed();
  }

  goToCompany(companyId: number) {
    this.#router.navigate([`companies/${companyId}`]);
  }

  openDeleteDialog(row: ILargeListTableInput): void {
    this.#dialogService.open(
      {
        title: "COMPANIES.DELETE_COMPANY.TITLE",
        tooltipLabel: "COMPANIES.DELETE_COMPANY.TOOLTIP",
        callBack: () => this.deleteCompany(row.id),
        submitLabel: "DELETE",
        isInputIncluded: false,
        descriptions: ["COMPANIES.DELETE_COMPANY.QUESTION", "COMPANIES.DELETE_COMPANY.DELETE_CONFIRMATION"],
        isSubmitLoading: this.#isDeletingCompany,
        cancelLabel: "CANCEL",
      },
      "confirmation",
    );
  }

  deleteCompany(companyId: number) {
    this.#isDeletingCompany.set(true);
    this.#companiesService.deleteCompany(companyId).subscribe({
      next: () => this.refreshTable(),
      error: () => this.#isDeletingCompany.set(false),
      complete: () => {
        this.#isDeletingCompany.set(false);
        this.#dialogService.close();
      },
    });
  }
}
