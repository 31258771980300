import { Component, computed, inject, signal } from "@angular/core";
import { BaseStepperComponent } from "@root/shared/abstracts/stepper/base-stepper.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { CustomerInformationComponent } from "@root/shared/customer-card/components/customer-card-details/components/customer-information/customer-information.component";
import { InviteCustomerOnsiteFormControls } from "@root/shared/invite-customer-onsite/invite-customer-onsite.form-controls";
import { ButtonComponent } from "@root/shared/button/button.component";
import { InviteCustomerOnsiteFacadeService } from "@root/shared/invite-customer-onsite/invite-customer-onsite-facade.service";
import { IMatchCustomerResponse } from "@root/data/market/properties/models/match-customer-response.model";
import { finalize } from "rxjs";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { CustomerMatchingComponent } from "@root/shared/customer-matching/customer-matching.component";
import { CustomerFollowUpComponent } from "@root/views/main/property/property-events/customer-follow-up/customer-follow-up.component";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { SelectSearchAvailableResponsibleAgentsComponent } from "@root/views/main/property/property-events/customer-follow-up/select-search-available-responsible-agents/select-search-available-responsible-agents.component";
import { FormControl, FormGroup } from "@angular/forms";
import { IUpdateCustomerPayloadModel } from "@root/data/market/legalEntities/models/update-customer-payload.model";
import { SendInvitationToCustomerComponent } from "@root/shared/send-invitiation-to-customer/send-invitation-to-customer.component";
import { JsonPipe } from "@angular/common";

@Component({
  selector: "est-invite-customer-onsite",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatProgressBar,
    MatStepper,
    MatStep,
    CustomerInformationComponent,
    ButtonComponent,
    CustomerMatchingComponent,
    CustomerFollowUpComponent,
    TranslateModule,
    TooltipComponent,
    SelectSearchAvailableResponsibleAgentsComponent,
    JsonPipe,
    SendInvitationToCustomerComponent,
  ],
  templateUrl: "./invite-customer-onsite.component.html",
  styleUrl: "./invite-customer-onsite.component.scss",
  providers: [InviteCustomerOnsiteFormControls, InviteCustomerOnsiteFacadeService],
})
export class InviteCustomerOnsiteComponent extends BaseStepperComponent {
  override readonly totalTabsNo = 5;
  readonly isNextButtonLoading = signal(false);
  readonly isSubmitting = signal(false);
  readonly isButtonsDisabled = computed(() => this.isNextButtonLoading() || this.isSubmitting());
  readonly matchingCustomerResponse = signal<IMatchCustomerResponse | null>(null);
  readonly inviteCustomerOnsiteFacadeService = inject(InviteCustomerOnsiteFacadeService);

  get form() {
    return this.inviteCustomerOnsiteFacadeService.inviteCustomerFormControls.form;
  }

  get formControls() {
    return this.inviteCustomerOnsiteFacadeService.inviteCustomerFormControls.form.controls;
  }

  get responsibleAgentIdControl(): FormControl {
    return (this.formControls.customer as FormGroup).controls["responsibleAgentId"] as FormControl;
  }

  get customerFullname() {
    const firstName = this.formControls.customer.value.firstName;
    const lastName = this.formControls.customer.value.lastName;
    if (!firstName || !lastName) return "";
    return `${firstName} ${lastName}`;
  }

  resetMatchingCustomer() {
    this.matchingCustomerResponse.set(null);
  }

  updateCustomer() {
    if (!this.matchingCustomerResponse()) return;
    this.isSubmitting.set(true);
    this.inviteCustomerOnsiteFacadeService
      .updateCustomer(
        this.matchingCustomerResponse()!.matchResult!.id,
        this.formControls.customer.value as IUpdateCustomerPayloadModel,
      )
      .pipe(finalize(() => this.isSubmitting.set(false)))
      .subscribe({
        next: () => {
          this.formControls.id.setValue(this.matchingCustomerResponse()!.matchResult!.id);
          this.matStepper.next();
        },
      });
  }

  validateCustomerInformationAndMatch() {
    if (this.form.invalid) {
      markAllControlsAsTouchedAndDirty(this.form);
      return;
    }
    this.#matchCustomer();
  }

  inviteCustomer() {
    if (this.form.invalid) {
      markAllControlsAsTouchedAndDirty(this.form);
      return;
    }
    this.isSubmitting.set(true);
    this.inviteCustomerOnsiteFacadeService
      .inviteCustomer()
      .pipe(finalize(() => this.isSubmitting.set(false)))
      .subscribe({
        next: (r) => {
          console.log(r);
        },
      });
  }

  override moveToNextStepIfValid(): void {
    if (this.matStepper.selectedIndex === 0 && !this.matchingCustomerResponse()) {
      this.validateCustomerInformationAndMatch();
      return;
    }
    super.moveToNextStepIfValid();
  }

  override submitForm(): void {
    throw new Error("Method not implemented.");
  }

  #matchCustomer() {
    if (!this.form.value.customer) return;
    this.isNextButtonLoading.set(true);
    this.inviteCustomerOnsiteFacadeService
      .matchCustomer(this.form.value.customer)!
      .pipe(finalize(() => this.isNextButtonLoading.set(false)))
      .subscribe({
        next: (res) => {
          if (res.matchResult) {
            this.matchingCustomerResponse.set(res);
          } else {
            this.matStepper.next();
          }
        },
      });
  }
}
