<div class="flex justify-between gap-x-2 gap-y-4 mb-6 max-2xl:flex-col">
  <est-small-header
    heading="SETTINGS.USERS.PENDING_USERS"
    subheading="SETTINGS.USERS.PENDING_USERS_DESCRIPTION"
    tooltip="Pending Users"
  ></est-small-header>
  <div class="flex gap-4 self-end">
    <est-select-search-field
      (scrollEnd)="loadDivisionsOptions($event)"
      [currentPage]="paginatedDivisionsOptions.currentPage()"
      [dropdownOptions]="paginatedDivisionsOptions.results()"
      [isLoading]="isDivisionsLoading"
      [isNoMoreResults]="isNoMoreDivisionsResults"
      [searchSignal]="divisionsOptionsSearch"
      [selectSignal]="divisionSignal"
      [totalPages]="paginatedDivisionsOptions.totalPages()"
      appliedClass="max-2xl:w-64"
      containsAllOption="true"
      dropdownLabel="DIVISIONS.SELECT_DIVISION"
    ></est-select-search-field>
    <est-search-input
      [searchSignal]="searchSignal"
      appliedClass="max-2xl:w-64"
      placeholder="SETTINGS.USERS.SEARCH_FOR_USER"
    ></est-search-input>
  </div>
</div>

<est-small-list-table
  [actions]="actions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [iconMenuComponentType]="UserDivisionRolesOverviewComponent"
  [isLoading]="isTableLoading"
  [isMouseoverIconVisibleFn]="isMouseoverIconVisible"
  [sortingFilterSignal]="sortingFilterSignal"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
