import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";

@Component({
  selector: "est-parking-lot",
  standalone: true,
  templateUrl: "./parking-lot.component.html",
  styleUrl: "./parking-lot.component.scss",
  imports: [UnderDevelopmentComponent],
})
export class ParkingLotComponent {}
