import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { Observable } from "rxjs/internal/Observable";
import { ITextsParams } from "@root/shared/interfaces/property-texts-params.interface";
import { IPropertyTextCard } from "../models/property-text-card.interface";
import { IPropertyDuplicateEntity } from "@root/shared/interfaces/property-texts-duplicate.interface";
import { ITextFieldDetails } from "@root/data/market/properties/models/text-field-details.model";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ILookUpItem } from "@root/data/market/lookups/models/look-up-item.model";

@Injectable({
  providedIn: "root",
})
export class PropertyTextsRepository {
  readonly #http = inject(HttpClient);

  getPropertyTextOverview(params: ITextsParams): Observable<IPropertyTextCard[]> {
    return this.#http.get<IPropertyTextCard[]>(APIS.properties.propertyTexts.getAll, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  deletePropertyText(propertyId: number, textId: number, params: ITextsParams): Observable<void> {
    return this.#http.delete<void>(APIS.properties.propertyTexts.delete(propertyId, textId), {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  duplicatePropertyText(
    propertyId: number,
    textId: number,
    data: Partial<IPropertyDuplicateEntity>,
    params: ITextsParams,
  ): Observable<void> {
    return this.#http.post<void>(APIS.properties.propertyTexts.duplicate(propertyId, textId), data, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getTextPlacements(params: IPaginationPayload): Observable<IPaginatedResponse<ILookUpItem>> {
    return this.#http.get<IPaginatedResponse<ILookUpItem>>(APIS.properties.propertyTexts.getTextPlacements, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  getTextFieldDetails(textFieldId: number): Observable<ITextFieldDetails> {
    return this.#http.get<ITextFieldDetails>(APIS.properties.propertyTexts.getTextField(textFieldId));
  }

  createPropertyText(propertyId: number, propertyTextFormData: FormData, params: ITextsParams): Observable<void> {
    return this.#http.post<void>(APIS.properties.propertyTexts.createPropertyText(propertyId), propertyTextFormData, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  editPropertyText(propertyId: number, textFieldId: number, propertyTextFormData: FormData): Observable<void> {
    return this.#http.put<void>(
      APIS.properties.propertyTexts.editPropertyText(propertyId, textFieldId),
      propertyTextFormData,
    );
  }
}
