<div class="flex flex-wrap align-baseline items-center">
  <est-language-display [language]="otherLanguage().language"></est-language-display>

  <est-button
    appliedClasses="w-[1.5rem] h-[1.5rem] !p-0 ms-2 me-4 !rounded"
    buttonType="button"
    buttonStyleType="button--tertiary"
    label=""
    imagePosition="center"
    imageSrc="assets/images/icons/trash-icon.svg"
    (click)="onDeleteLanguage()"
    appliedSpinnerClasses="!fill-primary"
    [isLoading]="isDeleting"
  ></est-button>

  <mat-slide-toggle [checked]="otherLanguage().activeInEndUserServices" (change)="toggleLanguage()"
    >{{ "SETTINGS.SYSTEM_CONFIGURATIONS.OTHER_LANGUAGES.ACTIVATE" | translate }}
  </mat-slide-toggle>
</div>
