import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { APIS } from "@root/core/api/market.apis";

export const FollowUpTaskApiMapper = new Map<
  SourceLevel,
  {
    get: (id?: string) => string;
    getAll: (id?: string) => string;
    delete: (id?: string) => string;
    update: (id?: string) => string;
    create: (id?: string) => string;
    toggleInheritance: (id?: string) => string;
    toggleBlocked?: (id?: string) => string;
  }
>([
  [
    SourceLevel.Organisation,
    {
      get: () => APIS.followUpTasks.organisation.getFollowUpTask,
      getAll: () => APIS.followUpTasks.organisation.getAllFollowUpTasks,
      delete: () => APIS.followUpTasks.organisation.deleteFollowUpTask,
      update: () => APIS.followUpTasks.organisation.updateFollowUpTask,
      create: () => APIS.followUpTasks.organisation.createFollowUpTask,
      toggleInheritance: () => APIS.followUpTasks.organisation.toggleFollowUpTaskInheritance,
    },
  ],
]);
