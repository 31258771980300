<div>
  <est-dialog-header [label]="internalTranslationKeys.popupTitle" [tooltipLabel]="internalTranslationKeys.popupTitle" />

  <div class="p-6 flex flex-col gap-6">
    <div>
      <p class="font-medium mb-2">{{ internalTranslationKeys.newPackageHeader | translate }}</p>
      <p class="font-light text-sm">
        {{ newPackageSubHeaderTranslationKey | translate }}
      </p>
    </div>
    <div>
      <div class="flex items-center gap-2 mb-4">
        <p class="text-sm font-medium">{{ internalTranslationKeys.originalName | translate }}</p>
        <est-tooltip [tooltip]="internalTranslationKeys.originalName" />
      </div>
      <est-input
        [control]="originalPackageControl"
        appliedClass="!w-[37.5rem]"
        [label]="internalTranslationKeys.originalName"
      />
    </div>
    <div>
      <div class="flex items-center gap-2 mb-4">
        <p class="text-sm font-medium">{{ "PROPERTY_FACILITIES.INTERNAL_TITLE" | translate }}</p>
        <est-tooltip tooltip="PROPERTY_FACILITIES.INTERNAL_TITLE" />
      </div>
      <est-input
        [control]="form.controls.internalTitle"
        appliedClass="!w-[37.5rem]"
        label="PROPERTY_FACILITIES.INTERNAL_TITLE"
      />
    </div>
    <div>
      <div class="flex items-center gap-2 mb-4">
        <p class="text-sm font-medium">{{ "PACKAGES.DUPLICATE_PACKAGE.DUPLICATION_OPTION" | translate }}</p>
        <est-tooltip tooltip="PACKAGES.DUPLICATE_PACKAGE.DUPLICATION_OPTION" />
      </div>
      <est-select-field
        [control]="form.controls.duplicationOption"
        [dropDownOptions]="duplicationOptions"
        [selectMultipleOptions]="false"
        appliedClass="!w-[37.5rem]"
        dropDownLabel="PACKAGES.DUPLICATE_PACKAGE.DUPLICATION_OPTION"
      />
    </div>
  </div>
  <hr class="border-black-100" />
  <div class="p-6">
    <est-list-select-user-accessed-properties
      [control]="form.controls.propertyIds"
      [selectPropertySubHeaderTranslationKey]="internalTranslationKeys.selectPropertySubHeader"
    ></est-list-select-user-accessed-properties>
  </div>
</div>
<div class="dialog__footer !sticky z-20">
  <est-button
    (click)="matDialog.closeAll()"
    buttonStyleType="button--tertiary"
    buttonType="button"
    label="CANCEL"
  ></est-button>
  <est-button
    (click)="duplicatePackage()"
    [isLoading]="isLoading()"
    buttonStyleType="button--primary"
    buttonType="button"
    label="DUPLICATE"
  ></est-button>
</div>
