import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule, provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideRouter, withComponentInputBinding, withRouterConfig } from "@angular/router";
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from "keycloak-angular";
import { keycloakInitializer } from "./core/authentication/keycloak-initializer";
import { divisionIdInterceptor } from "./core/interceptors/division-id.interceptor";
import { SystemLanguage } from "@root/shared/enums/language.enum";
import { routes } from "@root/app.routes";
import { errorInterceptor } from "@root/core/interceptors/error.interceptor";
import { patchRequestInterceptor } from "@root/core/interceptors/patch-request.interceptor";
import { TimeZoneInterceptor } from "@root/core/interceptors/timezone.interceptor";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: "always" })),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        (req, next) => {
          return inject(KeycloakBearerInterceptor).intercept(req, { handle: (r) => next(r) });
        },
        TimeZoneInterceptor,
        divisionIdInterceptor,
        errorInterceptor,
        patchRequestInterceptor,
      ]),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService],
    },
    KeycloakService,
    importProvidersFrom(
      KeycloakAngularModule,
      HttpClientModule,
      TranslateModule.forRoot({
        defaultLanguage: SystemLanguage.English,
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: KeycloakBearerInterceptor,
      useClass: KeycloakBearerInterceptor,
    },
    provideAnimationsAsync(),
  ],
};
