<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <est-subheader
    [date]="'Mon Feb 19 2024 14:20:06 GMT+0200'"
    [name]="'Jesper Thorkildsen'"
    label="DETAILS.NAME"
    [tooltip]="'DETAILS.NAME'"
  ></est-subheader>

  @if (!isPageLoading) {
    <form [formGroup]="organisationDetailsFG">
      <!-- FIRST_SECTION -->
      <div class="mb-6" (focusout)="updateOrganisationDetails()">
        <div class="flex items-end justify-between">
          <p class="font-medium text-base pb-4 pt-10">
            {{ "ORGANISATION.DETAILS.ORGANISATION_NAME.TITLE" | translate }}

            <est-tooltip [tooltip]="'ORGANISATION.DETAILS.ORGANISATION_NAME.TITLE'"></est-tooltip>
          </p>
          <div class="flex text-xs font-light text-black-500">
            <div class="text-right mr-4">
              <p>{{ "ORGANISATION.DETAILS.ORGANISATION_NAME.ORGANISATION_CREATED_AT" | translate }}:</p>
              <p>{{ "ORGANISATION.DETAILS.ORGANISATION_NAME.OWNER" | translate }}:</p>
            </div>
            <div>
              <p>
                {{ getControl("createdAt").value }}
              </p>
              <p>
                {{ getControl("organisationOwner").value }}
              </p>
            </div>
          </div>
        </div>
        <est-input
          label="ORGANISATION.DETAILS.ORGANISATION_NAME.NAME"
          appliedClass="w-[15rem]"
          [control]="getControl('name')"
        ></est-input>
        <div class="flex mt-4 gap-4">
          <est-input
            label="ORGANISATION.DETAILS.ORGANISATION_NAME.ORGANISATION_OWNER"
            appliedClass="w-[15rem]"
            [control]="getControl('organisationOwner')"
          ></est-input>
          @if (isOrganisationOwner) {
            <est-button
              label="ORGANISATION.DETAILS.ORGANISATION_NAME.BUTTON_LABEL"
              buttonType="button"
              appliedClasses="!px-6 uppercase"
              [buttonStyleType]="'button--primary'"
              (click)="openTransferOwnershipModal()"
            ></est-button>
          }
        </div>
      </div>

      <div class="absolute start-0 end-0">
        <hr class="border-black-100" />
      </div>

      <!-- SECOND_SECTION -->
      <div class="mt-10">
        <p class="font-medium text-base pb-4">
          {{ "ORGANISATION.DETAILS.TITLE" | translate }}
          <est-tooltip [tooltip]="'ORGANISATION.DETAILS.TITLE'"></est-tooltip>
        </p>
        <est-address
          [formGroup]="addressFormGroup"
          [lookups]="addressLookupsOptions"
          [isLoadingSignal]="isAddressLoadingSignal"
          [formattedAddressSignal]="formattedAddressSignal"
          (selectedValueChange)="patchAddressFields($event)"
        ></est-address>
        <div class="flex flex-wrap mb-4 gap-4" (focusout)="updateOrganisationDetails()">
          <est-input
            appliedClass="mr-4 w-[18.75rem]"
            [control]="getControl('contactPersonName')"
            [label]="'CONTACT_PERSON'"
          ></est-input>
          <est-input
            appliedClass="mr-4 w-[18.75rem] "
            [control]="getControl('contactPersonEmail')"
            label="CONTACT_EMAIL"
            [type]="'email'"
          ></est-input>
          <est-phone-input [control]="phoneFormControl"></est-phone-input>
        </div>
        <est-input
          appliedClass="w-[18.75rem]"
          [control]="getControl('websiteUrl')"
          label="WEBSITE"
          (focusout)="updateOrganisationDetails()"
        ></est-input>
      </div>

      <div class="absolute start-0 end-0">
        <hr class="mt-6 border-black-100" />
      </div>

      <!-- THIRD_SECTION -->
      <div class="mt-12">
        <est-small-header
          heading="ORGANISATION.DETAILS.DOMAIN.TITLE"
          subheading="ORGANISATION.DETAILS.DOMAIN.SUB_TITLE"
          subheadingAppliedClasses="xl:max-w-[33rem]"
        ></est-small-header>

        <div class="flex mt-6 gap-4" (focusout)="updateOrganisationDetails()">
          <est-input
            label="ORGANISATION.DETAILS.DOMAIN.PLACEHOLDER"
            appliedClass="w-[15rem]"
            [control]="getControl('domainName')"
          ></est-input>
          <est-button
            label="ORGANISATION.DETAILS.DOMAIN.BUTTON_LABEL"
            buttonType="button"
            appliedClasses="!px-6 uppercase"
            [buttonStyleType]="'button--primary'"
            (click)="changeDomain()"
          ></est-button>
        </div>
      </div>
    </form>
  } @else {
    <div class="py-32 relative">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</div>
