<est-small-header
  appliedClasses="mt-6"
  heading="EVENTS.DETAILS.SERIES_EVENT.TITLE"
  subheading="EVENTS.DETAILS.SERIES_EVENT.DESCRIPTION"
  subheadingAppliedClasses="!max-w-[85%] mb-6"
  tooltip="EVENTS.DETAILS.SERIES_EVENT.TOOLTIP"
></est-small-header>

<hr class="absolute start-0 end-0 border-black-100" />

<div class="flex gap-4 mt-12 mb-6">
  @for (button of filterButtons; track button.label) {
    <est-button
      [label]="button.label | translate"
      labelCaseSensitiveClass="capitalize"
      [buttonStyleType]="button.getButtonStyleType()"
      buttonType="button"
      (click)="button.callBackFn()"
    ></est-button>
  }
</div>

<hr class="absolute start-0 end-0 border-black-100" />

<div class="mt-12">
  <div class="w-full grid grid-cols-5 gap-4 !pr-[2.7rem] !pl-[3.7rem]">
    @for (sortingKey of sortingKeys; track $index) {
      <div class="flex gap-2 items-center">
        <p class="font-medium text-xs">{{ sortingKey.label | translate }}</p>
        <button class="outline-none" type="button" (click)="sortingKey.callbackFn()">
          <img
            ngSrc="assets/images/icons/double-vert-chevron.svg"
            priority="true"
            width="8"
            height="10"
            [alt]="'SORT' | translate"
          />
        </button>
      </div>
    }
  </div>
  @for (instance of eventInstances(); track instance.id) {
    <div class="my-3">
      <mat-accordion>
        <mat-expansion-panel
          #mep="matExpansionPanel"
          hideToggle="true"
          [expanded]="isInstanceExpanded(instance.id)"
          (opened)="mep.expanded = true"
          (closed)="mep.expanded = false"
        >
          <mat-expansion-panel-header
            [ngClass]="[
              isInstanceExpanded(instance.id)
                ? 'border-l-success-dark border-2 border-b-black-300 '
                : 'border-l-black-600 border-2'
            ]"
            class="!items-start !px-2 !py-4 border-l-4 !h-28 xl:!h-20"
          >
            <est-event-instance-details
              [panelOpenState]="mep.expanded"
              [eventInstance]="instance"
            ></est-event-instance-details>
          </mat-expansion-panel-header>
          <est-single-event-instance-wrapper
            [panelOpenState]="mep.expanded"
            [eventInstanceForSeriesEvent]="instance"
          ></est-single-event-instance-wrapper>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  }
</div>

<est-pagination [paginationValues]="paginatedEventInstances"></est-pagination>
