import { ActivatedRoute } from "@angular/router";
import { USER_ROLES_ORDERED } from "../constants/global.constants";
import { UserRole } from "../../data/market/users/enums/user-role.enum";

export const isUserAuthorized = (userRole: UserRole | undefined, authorizedRole: UserRole | undefined): boolean => {
  if (!authorizedRole) {
    return true;
  }
  if (!userRole) {
    return false;
  }
  return USER_ROLES_ORDERED.indexOf(userRole) >= USER_ROLES_ORDERED.indexOf(authorizedRole);
};

export const getAuthorizedUserRoleFromRoute = (route: ActivatedRoute): UserRole | undefined => {
  let snapshot = route.snapshot;
  while (snapshot.firstChild) {
    snapshot = snapshot.firstChild;
    if (snapshot.data["authorizedRole"]) {
      return snapshot.data["authorizedRole"];
    }
  }
  return snapshot.data["authorizedRole"];
};
