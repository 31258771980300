<!-- <p class="text-sm font-medium pb-4">
  {{ "LINKED_UNITS.TOTAL_UNITS" | translate: { count: paginatedData.totalCount() } }}
</p>
<p class="text-sm font-medium pb-6">
  {{ "LINKED_UNITS.SELECTED_UNITS" | translate: { count: unitIds.value.length } }}
</p> -->
<div class="flex justify-end gap-6 pb-6 flex-wrap">
  <est-search-input [searchSignal]="searchSignal"></est-search-input>
</div>
<est-large-list-table
  [data]="paginatedData.results()"
  [headers]="headers"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
  [checkboxControlMapper]="selectedDivisionMapper"
  (checkItem)="addAndRemoveItem()"
  (checkAllItems)="addAndRemoveItems()"
>
</est-large-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
