import { Routes } from "@angular/router";
import { PropertyMainDetailsComponent } from "./property-main-details/property-main-details.component";
import { PropertyLayoutComponent } from "./property-layout/property-layout.component";
import { PropertyTermsAndConditionsComponent } from "@root/views/main/property/property-details/property-terms-and-conditions/property-terms-and-conditions.component";

export const propertyDetailsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "main-details",
  },
  {
    path: "main-details",
    component: PropertyMainDetailsComponent,
    data: {
      name: "PROPERTIES.DETAILS.MAIN_DETAILS.NAME",
    },
  },
  {
    path: "terms-and-conditions",
    component: PropertyTermsAndConditionsComponent,
    data: {
      name: "PROPERTIES.DETAILS.TERMS_AND_CONDITIONS",
    },
  },
  {
    path: "property-layout",
    component: PropertyLayoutComponent,
    data: {
      name: "PROPERTIES.DETAILS.PROPERTY_LAYOUT",
    },
  },
];
