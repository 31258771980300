import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-company",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./company.component.html",
  styleUrl: "./company.component.scss",
})
export class CompanyComponent {
  tabs: INavigationTab[] = [
    {
      label: "DETAILS.NAME",
      routerLink: "details",
    },
    {
      label: "PROPERTIES.NAME",
      routerLink: "properties",
    },
    {
      label: "DOCUMENTS.NAME",
      routerLink: "documents",
    },
    {
      label: "SETTINGS.NAME",
      routerLink: "settings",
    },
  ];
}
