import { Component, inject, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { constructAddressFormGroup } from "../../utilities/form.utilities";
import { BaseStepperComponent } from "../stepper/base-stepper.component";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { IPropertyAddressFormGroup } from "@root/shared/property-address/property-address-form-group.model";

@Component({
  template: "",
  styles: "",
})
export abstract class BasePropertyFormComponent extends BaseStepperComponent {
  isPropertyLoading = false;
  isAddressLoadingSignal = signal(false);
  isGeographicalLocationLoadingSignal = signal(false);
  formattedAddressSignal = signal("");
  protected readonly lookupsService = inject(LookupsService);
  protected readonly dialog = inject(MatDialog);
  protected readonly fb = inject(NonNullableFormBuilder);
  teamIdsControl = this.fb.control<number[]>([], Validators.required);
  // form controls
  companyIdControl = this.fb.control<number | null>(null, Validators.required);
  propertyNameControl = this.fb.control("", Validators.required);
  propertyNumberControl = this.fb.control("", Validators.required);
  cadastralNumberControl = this.fb.control(""); // Address form groups
  primaryAddressInformation = constructAddressFormGroup(this.fb, false);
  secondaryAddressInformation = constructAddressFormGroup(this.fb, false);
  // Form structure to be sent to backend
  // Do not move this below step form groups
  form = this.fb.group({
    companyId: this.companyIdControl,
    propertyName: this.propertyNameControl,
    propertyNumber: this.propertyNumberControl,
    cadastralNumber: this.cadastralNumberControl,
    propertyAddress: this.fb.group<IPropertyAddressFormGroup>({
      primaryAddress: this.fb.group({
        addressInformation: this.primaryAddressInformation,
      }),
    }),
    assignedToTeamsIds: this.teamIdsControl,
  });

  propertyDetailsFormGroup = this.fb.group({
    companyId: this.companyIdControl,
    propertyName: this.propertyNameControl,
    propertyNumber: this.propertyNumberControl,
    cadastralNumber: this.cadastralNumberControl,
    propertyAddress: this.fb.group<IPropertyAddressFormGroup>({
      primaryAddress: this.fb.group({
        addressInformation: this.primaryAddressInformation,
      }),
    }),
  });

  addSecondaryAddress = () => {
    this.form.controls.propertyAddress.addControl(
      "secondaryAddress",
      this.fb.group({
        addressInformation: this.secondaryAddressInformation,
      }),
    );
  };

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
