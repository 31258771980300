<div class="flex overflow-x-auto gap-4 pb-2 mb-4">
  @for (navigateTab of tabs(); track navigateTab.label) {
    <div *estAuthorizedRole="navigateTab.authorizedRole">
      <input class="hidden" type="radio" [id]="navigateTab.label" [value]="navigateTab.routerLink" />
      <label
        [routerLink]="navigateTab.routerLink"
        routerLinkActive="tab--active"
        [for]="navigateTab.label"
        class="capitalize font-light grid place-items-center w-full px-4 min-w-fit py-2.5 text-black bg-white border border-black-300 rounded-lg cursor-pointer"
      >
        <span class="text-nowrap">
          {{ navigateTab.label | translate }}
        </span>
      </label>
    </div>
  }
</div>
