<div class="rounded-lg w-[50rem] bg-white">
  <div class="flex justify-between items-center px-6 py-4 border-b border-black-300 text-sm">
    <p class="font-medium">{{ "AGENT_DETAILS" | translate }}</p>
    <p class="font-light text-sm">
      {{ "LAST_CHANGE" | translate }}: <span>{{ lastChanged() | customDate: true }}</span>
    </p>
  </div>
  <div class="px-6 flex">
    <div class="flex-1 flex-grow-[2] border-e pt-4 pb-6 border-black-300">
      <div class="flex gap-6 items-center">
        @if (imageUrl()) {
          <img class="object-cover w-24 h-24 rounded-full" [alt]="'USER_IMAGE' | translate" [src]="imageUrl()" />
        } @else {
          <img
            class="object-cover w-24 h-24 rounded-full"
            [alt]="'USER_IMAGE' | translate"
            src="assets/images/user-placeholder.png"
          />
        }
        <div class="grid grid-cols-2 pe-10 gap-y-4 gap-x-16">
          <div>
            <p class="font-medium text-xs mb-1">{{ "FIRST_NAME" | translate }}</p>
            <p class="font-light text-xs">{{ firstName() }}</p>
          </div>
          <div>
            <p class="font-medium text-xs mb-1">{{ "LAST_NAME" | translate }}</p>
            <p class="font-light text-xs">{{ lastName() }}</p>
          </div>
          <div>
            <p class="font-medium text-xs mb-1">{{ "PHONE" | translate }}</p>
            <p class="font-light text-xs">{{ phoneNumber() }}</p>
          </div>
          <div>
            <p class="font-medium text-xs mb-1">{{ "EMAIL" | translate }}</p>
            <p class="font-light text-xs">{{ email() }}</p>
          </div>
          <div>
            <p class="font-medium text-xs mb-1">{{ "TITLE" | translate }}</p>
            <p class="font-light text-xs">{{ title() }}</p>
          </div>
          <div>
            <p class="font-medium text-xs mb-1">{{ "SETTINGS.USERS.WORKPLACE" | translate }}</p>
            <p class="font-light text-xs">{{ workPlace() }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 pt-4 pb-6 ps-6">
      <div class="mb-4">
        <p class="font-medium text-xs mb-1">{{ "SETTINGS.USERS.JOINED" | translate }}</p>
        <p class="font-light text-xs">{{ joinedAt() | customDate: true }}</p>
      </div>
      <div class="mb-4">
        <p class="font-medium text-xs mb-1">{{ "SETTINGS.USERS.LAST_ACTIVE" | translate }}</p>
        <p class="font-light text-xs">{{ lastActive() | customDate: true }}</p>
      </div>
      <div>
        <p class="font-medium text-xs mb-1">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE" | translate }}</p>
        <p class="font-light text-xs">{{ systemLanguage() }}</p>
      </div>
    </div>
  </div>
</div>
