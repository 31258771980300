<est-stepper-navigation [buttonsLabels]="buttonLabels" [selectedIndex]="stepperIndex"></est-stepper-navigation>
<div class="bg-black-200 rounded-[0.625rem] p-6">
  <div class="bg-white rounded-[0.625rem] r-screen-[25rem] relative pb-20 overflow-hidden">
    @if (isPageLoading) {
      <est-loading-overlay></est-loading-overlay>
    }
    <mat-stepper #stepper [selectedIndex]="stepperIndex" animationDuration="0">
      <mat-step [stepControl]="createPropertyTextsFormControls.firstStepGroup">
        <div class="p-6 pe-16">
          <est-subheader
            date="Mon Feb 19 2024 14:20:06 GMT+0200"
            label="SETTINGS.NAME"
            name="Jesper Thorkildsen"
            tooltip="SETTINGS.NAME"
          ></est-subheader>
          <est-small-header
            appliedClasses="mt-12"
            heading="PROPERTY_TEXTS.FIELD_SETTING"
            subheading="PROPERTY_TEXTS.SETTINGS_DESCRIPTION"
            subheadingAppliedClasses="!max-w-[85%]"
            tooltip="PROPERTY_TEXTS.FIELD_SETTING"
          ></est-small-header>
          <est-small-header
            appliedClasses="mt-6"
            heading="PROPERTY_FACILITIES.INTERNAL_TITLE"
            subheading="PROPERTY_TEXTS.AN_INTERNAL_TITLE_USED"
            subheadingAppliedClasses="!max-w-[60%]"
            tooltip="PROPERTY_FACILITIES.INTERNAL_TITLE"
          ></est-small-header>
          <est-input
            [control]="formControls.internalTitle"
            appliedClass="mt-5 !w-[37.5rem]"
            label="PROPERTY_FACILITIES.INTERNAL_TITLE"
          ></est-input>
        </div>
        <hr class="border-black-100" />
        <div class="p-6 pe-16">
          <p class="font-medium">
            {{ "PROPERTY_TEXTS.PLACEMENT_ID" | translate }}
          </p>
          <p class="text-sm mt-4 font-light">
            {{ "PROPERTY_TEXTS.PLACEMENT_ID_SUBTITLE" | translate }}
          </p>

          <est-select-placement-id
            [label]="'PROPERTY_TEXTS.PLACEMENT'"
            [control]="formControls.placementId"
          ></est-select-placement-id>
        </div>

        @if (parentRoute === "text-packages") {
          <hr class="border-black-100" />
          <div class="p-6 pe-16">
            <est-small-header
              heading="PROPERTY_TEXTS.ALLOW_DELINKING"
              subheading="PROPERTY_TEXTS.ALLOW_DELINKING_DESCRIPTION"
              subheadingAppliedClasses="!max-w-[85%] mb-4"
              tooltip="PROPERTY_TEXTS.ALLOW_DELINKING"
            ></est-small-header>
            <mat-slide-toggle [formControl]="formControls.allowDelinkingOnUnitLevel" class="text-sm font-light"
              >{{ "PROPERTY_TEXTS.ALLOW_DELINKING_TOGGLE" | translate }}
            </mat-slide-toggle>
          </div>
          <hr class="border-black-100" />
        }
        <div class="p-6 pe-16">
          <est-small-header
            [heading]="
              parentRoute === 'property-texts'
                ? 'PROPERTY_TEXTS.ACTIVATE_PROPERTY_TEXT'
                : 'PROPERTY_TEXTS.ACTIVATE_PACKAGE'
            "
            subheading="PROPERTY_TEXTS.ENABLE_THE_SETTING"
            subheadingAppliedClasses="!max-w-[85%] mb-4"
            [tooltip]="
              parentRoute === 'property-texts'
                ? 'PROPERTY_TEXTS.ACTIVATE_PROPERTY_TEXT'
                : 'PROPERTY_TEXTS.ACTIVATE_PACKAGE'
            "
          ></est-small-header>
          @if (parentRoute === "property-texts") {
            <mat-slide-toggle [formControl]="formControls.isActive" class="text-sm font-light"
              >{{ "PROPERTY_TEXTS.ACTIVATE_PROPERTY" | translate }}
            </mat-slide-toggle>
          } @else {
            <mat-slide-toggle [formControl]="formControls.isActive" class="text-sm font-light"
              >{{ "PROPERTY_TEXTS.ACTIVATE_PACKAGE" | translate }}
            </mat-slide-toggle>
          }
        </div>
      </mat-step>
      <mat-step [stepControl]="createPropertyTextsFormControls.secondStepGroup">
        <div class="p-6 pe-16">
          <est-subheader
            date="Mon Feb 19 2024 14:20:06 GMT+0200"
            label="PROPERTY_TEXTS.ADD_TEXT_FIELD"
            name="Jesper Thorkildsen"
            tooltip="PROPERTY_TEXTS.ADD_TEXT_FIELD"
          ></est-subheader>
          <est-small-header
            appliedClasses="mt-12"
            heading="PROPERTY_TEXTS.TEXT_FIELD"
            subheading="PROPERTY_TEXTS.TEXTS_DESCRIPTION"
            subheadingAppliedClasses="!max-w-[85%]"
            tooltip="PROPERTY_TEXTS.TEXT_FIELD"
          ></est-small-header>

          @for (
            textFieldFormGroup of createPropertyTextsFormControls.textFieldsFormArray.controls;
            track textFieldFormGroup.controls.languageId.value
          ) {
            <est-text-editor-accordion
              [allowedLanguage]="getLanguageFromId(textFieldFormGroup.controls.languageId.value)"
              [textFieldFormGroup]="textFieldFormGroup"
            ></est-text-editor-accordion>
          }
        </div>
      </mat-step>
    </mat-stepper>
    <div class="dialog__footer">
      @if (stepperIndex === 0) {
        <est-button
          buttonStyleType="button--tertiary"
          buttonType="button"
          label="CANCEL"
          [routerLink]="isEdit ? '../../' : '../'"
        ></est-button>
        <est-button
          (click)="goToNextPage()"
          buttonStyleType="button--primary"
          buttonType="button"
          label="NEXT"
        ></est-button>
      } @else {
        <est-button
          (click)="goToPreviousPage()"
          buttonStyleType="button--tertiary"
          buttonType="button"
          label="BACK"
        ></est-button>
        <div class="flex items-center gap-6">
          <est-button
            [disabled]="formControls.textFieldLanguages.invalid"
            [isLoading]="isSubmittingForm"
            (click)="submitForm()"
            buttonStyleType="button--primary"
            buttonType="button"
            [label]="isEdit ? 'SAVE' : 'PROPERTY_TEXTS.CREATE_TEXT_FIELD'"
          ></est-button>
        </div>
      }
    </div>
  </div>
</div>
