import { DestroyRef, effect, inject, signal, WritableSignal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, NonNullableFormBuilder, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { UnitFacilitiesService } from "@root/data/market/units/services/unit-facilities.service";
import { FacilityLookupsService } from "@root/data/market/lookups/services/facility-lookups.service";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ADD_PROPERTIES_FACILITIES_TABLE_HEADERS } from "@root/views/main/property/property-marketing/components/facilities/facility-package/create-new-property-facility-package/add-properties-facilities.headers";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import {
  constructInitialSignalPaginatedResponseWithSelected,
  copyPaginatedSignalResponse,
} from "@root/shared/utilities/signals.utilities";
import { IUnitFacilityLookups } from "@root/shared/interfaces/unit-facility-lookups.interface";
import { PropertiesFacilitiesPackagesService } from "@root/data/market/properties/services/properties-facilities-packages.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { tap } from "rxjs";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { FacilityDetailsComponent } from "@root/shared/facility-details/facility-details.component";
import { ISignalPaginatedSelectedResponse } from "@root/shared/interfaces/signal-paginated-selected-response.interface";
import { ICheckedItem } from "@root/shared/interfaces/checked-item.interface";
import { IAllCheckedItems } from "@root/shared/interfaces/checked-items.interface";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";

export abstract class BasePropertyFacilityPackage extends BasePaginatedTableWithSearchComponent {
  override paginatedData = constructInitialSignalPaginatedResponseWithSelected();
  isLoading: boolean = false;
  translateService = inject(TranslateService);
  facilityLookupsService = inject(FacilityLookupsService);
  location = inject(Location);
  propertiesFacilitiesPackagesService = inject(PropertiesFacilitiesPackagesService);
  isEdit = false;
  locationSignal = signal<number>(0);
  categoryIdSignal = signal<number>(0);
  packageIdSignal = signal<number>(0);
  selectAllItemsOperation = signal<SelectionOperation>(SelectionOperation.DEFAULT);
  buttonsLabels: string[] = ["PROPERTY.SETTINGS", "PROPERTY_FACILITIES.ADD_FACILITIES"];
  fb = inject(NonNullableFormBuilder);
  internalTitle = this.fb.control<string>("", Validators.required);
  isActive = this.fb.control<boolean>(false);
  allowDeactivatingOnUnit = this.fb.control<boolean>(false);
  facilityIds = this.fb.control<number[]>([]);
  id = this.fb.control(0);
  packageForm = this.fb.group({
    propertyId: this.id,
    internalTitle: this.internalTitle,
    isActive: this.isActive,
    facilityIds: this.facilityIds,
    allowDeactivatingOnUnit: this.allowDeactivatingOnUnit,
  });
  locationsLookupOptions!: IDropdownOption[];
  categoriesLookupOptions!: IDropdownOption[];
  readonly filtersPredefinedOptions: IDropdownOption[] = [
    {
      label: "ALL",
      value: "",
    },
  ];
  facilityIdsMapper: Map<string, FormControl<number[]>> = new Map([["facilityId", this.facilityIds]]);
  override actions: ITableAction[] = [
    {
      callbackFn: () => {},
      labelFn: () => "PROPERTY_FACILITIES.EDIT_FACILITY_DETAILS",
    },
  ];
  readonly headers = ADD_PROPERTIES_FACILITIES_TABLE_HEADERS;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "facility",
    sortDescending: false,
  });
  readonly loadDataEffect$ = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      ...(this.locationSignal() ? { locationId: this.locationSignal() } : {}),
      ...(this.categoryIdSignal() ? { categoryId: this.categoryIdSignal() } : {}),
      ...(this.packageIdSignal() ? { facilityPackageId: this.packageIdSignal() } : {}),
    });
  });
  protected readonly FacilityDetailsComponent = FacilityDetailsComponent;
  protected readonly unitFacilitiesService = inject(UnitFacilitiesService);
  protected readonly destroyRef = inject(DestroyRef);
  protected removedIds = new Set<number>();
  protected addedIds = new Set<number>();

  get selectedFacilitiesCount() {
    if (this.selectAllItemsOperation() === SelectionOperation.SELECT_ALL) {
      return this.paginatedData.totalCount() - this.removedIds.size;
    }

    if (this.selectAllItemsOperation() === SelectionOperation.DESELECT_ALL) {
      return this.addedIds.size;
    }

    if (this.isEdit) {
      return this.paginatedData.selected() + this.addedIds.size - this.removedIds.size;
    }

    return this.facilityIds.value.length;
  }

  stepFormTile(index: number, title: string) {
    return index + 1 + ". " + this.translateService.instant(title);
  }

  loadData(params: IUnitFacilityLookups): void {
    this.isTableLoading = true;
    this.unitFacilitiesService
      .getUnitFacilityList(params)
      .pipe(tap((facilities) => this.#isFacilityUsed(facilities.results())))
      .subscribe({
        next: (facilities) => {
          this.#handleResponse(facilities);
        },
        complete: () => (this.isTableLoading = false),
      });
  }

  #handleResponse(paginatedLinkedResponse: ISignalPaginatedSelectedResponse<ISmallListTableInput>): void {
    copyPaginatedSignalResponse(this.paginatedData, paginatedLinkedResponse);
    this.paginatedData.selected.set(paginatedLinkedResponse.selected());
  }

  getLocationsLookUps() {
    this.facilityLookupsService.getUnitFacilityLocationsLookups().subscribe((lookups) => {
      this.locationsLookupOptions = lookups;
    });
  }

  getCategoriesLookUps() {
    this.facilityLookupsService.getUnitFacilityCategoriesLookups().subscribe((lookups) => {
      this.categoriesLookupOptions = lookups;
    });
  }

  #isFacilityUsed(facilities: ISmallListTableInput<number>[]) {
    facilities.forEach((row) => {
      const facilityId = row.id;
      const isUsed = row["isUsed"];
      if (this.removedIds.has(facilityId)) return;

      if (this.selectAllItemsOperation() === SelectionOperation.SELECT_ALL) {
        this.#addFacilityIdToControl(facilityId);
      }

      if (this.isEdit && this.selectAllItemsOperation() === SelectionOperation.DEFAULT) {
        isUsed && this.#addFacilityIdToControl(facilityId);
      }
    });
  }

  #addFacilityIdToControl(id: number) {
    if (!this.facilityIds.value.includes(id)) {
      this.facilityIds.value.push(id);
    }
  }

  addAndRemoveItem(event: ICheckedItem) {
    event.checked ? this.addItem(event.row) : this.removeItem(event.row);
  }

  checkCurrentPageItems(event: IAllCheckedItems) {
    this.selectAllItemsOperation.set(event.checked ? SelectionOperation.SELECT_ALL : SelectionOperation.DESELECT_ALL);
    this.addedIds.clear();
    this.removedIds.clear();
    if (this.selectAllItemsOperation() === SelectionOperation.DESELECT_ALL) this.facilityIds.reset([]);
  }

  protected addItem(row: ISmallListTableInput<number>): void {
    const isUsed = row["isUsed"];
    const id = row["id"];
    this.removedIds.delete(id);
    if (!isUsed || this.selectAllItemsOperation() !== SelectionOperation.SELECT_ALL) {
      this.addedIds.add(id);
    }
  }

  protected removeItem(row: ISmallListTableInput<number>): void {
    const isUsed = row["isUsed"];
    const id = row["id"];
    this.addedIds.delete(id);
    if (isUsed || this.selectAllItemsOperation() !== SelectionOperation.DESELECT_ALL) {
      this.removedIds.add(id);
    }
  }
}
