<form (ngSubmit)="moveToNextStepOrSubmitFormIfValid()">
  <div class="flex border-b border-black-100 py-6 px-6 justify-between">
    <div class="flex gap-2 items-center sticky top-0">
      <p class="text-xl font-medium mb-1">
        {{ "COMPANIES.CREATE_COMPANY" | translate }}
      </p>
      <button
        [matTooltip]="'COMPANIES.CREATE_COMPANY' | translate"
        class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
        type="button"
      >
        ?
      </button>
    </div>
    <button (click)="dialog.closeAll()" type="button">
      <img
        [alt]="'CLOSE_ICON' | translate"
        height="44"
        ngSrc="assets/images/icons/close-icon.svg"
        priority
        width="44"
      />
    </button>
  </div>
  <div class="h-[calc(100dvh-14rem)] overflow-y-auto pb-8">
    <mat-stepper #stepper animationDuration="0">
      <mat-step [stepControl]="companyFormControls.companyFormGroup">
        <div class="mt-6">
          <p class="mb-4 font-medium px-6">{{ "COMPANIES.FORM.COMPANY_NAME" | translate }}</p>
          <div class="px-6 pb-4">
            <div class="mb-4">
              <est-input
                [appliedClass]="'w-[18.75rem] '"
                [control]="companyFormControls.companyName"
                [id]="'input'"
                [label]="'COMPANIES.FORM.COMPANY_NAME'"
                [name]="'name'"
              ></est-input>
            </div>
            <est-select-owner-field
              [label]="'COMPANIES.FORM.COMPANY_OWNER'"
              [control]="companyFormControls.ownerId"
            ></est-select-owner-field>
          </div>
          <mat-divider></mat-divider>
          <div class="px-6 pt-6">
            <p class="mb-4 font-medium">{{ "COMPANIES.FORM.COMPANY_DETAILS" | translate }}</p>
            <est-address
              [formGroup]="companyFormControls.detailsAddressInformation"
              [isLoadingSignal]="isAddressLoadingSignal"
              [lookups]="addressLookupsOptions"
            ></est-address>
            <div class="flex flex-wrap mb-4">
              <est-input
                [appliedClass]="'mr-4 w-[18.75rem] max-breakpoint-ipad-screen:mb-4'"
                [control]="companyFormControls.companyDetails.controls.contactPersonName"
                [id]="'input'"
                [label]="'CONTACT_PERSON'"
                [name]="'contactPersonName'"
              ></est-input>
              <est-input
                [appliedClass]="'max-breakpoint-ipad-screen:mb-4 mr-4 w-[18.75rem] '"
                [control]="companyFormControls.companyDetails.controls.contactPersonEmail"
                [id]="'input'"
                [label]="'CONTACT_EMAIL'"
                [name]="'contactEmail'"
                [type]="'email'"
              ></est-input>
              <div>
                <est-phone-input [control]="companyFormControls.phoneNumber"></est-phone-input>
              </div>
            </div>
            <est-input
              [appliedClass]="'w-[18.75rem]'"
              [control]="companyFormControls.companyDetails.controls.websiteUrl"
              [id]="'input'"
              [label]="'WEBSITE'"
              [name]="'websiteUrl'"
            ></est-input>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <est-creation-success-message
          [createdBy]="authService.getFullName()!"
          [name]="companyFormControls.companyName.value"
          description="PROPERTIES.FORM.PROPERTY_CREATED_MSG"
        ></est-creation-success-message>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="py-3 bg-black-50 flex border border-black-100 px-6 justify-between sticky bottom-0">
    @if (stepper.selectedIndex === 0) {
      <est-button
        [disabled]="isDataBeingLoading()"
        label="CANCEL"
        (click)="dialog.closeAll()"
        buttonType="button"
        buttonStyleType="button--tertiary"
        appliedClasses="form-button"
      />
      <est-button
        [disabled]="isDataBeingLoading()"
        buttonStyleType="button--primary"
        label="CREATE"
        buttonType="submit"
        [isLoading]="isLoading"
        appliedClasses="form-button"
      />
    } @else {
      <est-button
        [disabled]="isDataBeingLoading()"
        label="BACK"
        (click)="openEditCompanyModal()"
        buttonType="button"
        buttonStyleType="button--tertiary"
        appliedClasses="form-button"
      />
      <est-button
        label="COMPANIES.GO_TO_COMPANY"
        (click)="navigateToCompany()"
        buttonType="button"
        buttonStyleType="button--primary"
        appliedClasses="form-button"
      ></est-button>
    }
  </div>
</form>
