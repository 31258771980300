import { DestroyRef, inject, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { IDropdownOption } from "../../interfaces/dropdown-option.interface";
import { IAddressLookUp } from "../../interfaces/address-look-up.interface";
import { constructInitialAddressLookups } from "../../utilities/address.utilities";
import { BaseStepperComponent } from "../stepper/base-stepper.component";
import { CompaniesService } from "@root/data/market/companies/services/companies.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CompanyFormControls } from "@root/views/main/division/division-companies/company-form-controls";

export abstract class BaseCompanyForm extends BaseStepperComponent {
  companyFormControls = inject(CompanyFormControls);
  readonly companiesService = inject(CompaniesService);
  readonly lookupsService = inject(LookupsService);
  dialog = inject(MatDialog);
  isAddressLoadingSignal = signal(false);
  addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  protected readonly destroyRef = inject(DestroyRef);

  mapPhoneNumberOnContact() {
    this.companyFormControls.phoneNumber.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.companyFormControls.contactPersonPhoneNo.setValue(value?.number ?? "");
      this.companyFormControls.contactPersonIsdCode.setValue(value?.countryCode ?? "");
    });
  }
}
