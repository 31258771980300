import { Component, DestroyRef, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressBar } from "@angular/material/progress-bar";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDivider } from "@angular/material/divider";
import { CompanySelectFieldComponent } from "@root/shared/company-select-field/company-select-field.component";
import { InputComponent } from "@root/shared/input/input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreationSuccessMessageComponent } from "@root/shared/creation-success-message/creation-success-message.component";
import { AddressComponent } from "@root/shared/address/address.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { GeographicalLocationComponent } from "@root/shared/geographical-location/geographical-location.component";
import { ListSelectTeamsComponent } from "@root/shared/list-select-teams/list-select-teams.component";
import { BasePropertyFormComponent } from "@root/shared/abstracts/base-property-form/base-property-form.abstract";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { constructInitialAddressLookups, formatAddress } from "@root/shared/utilities/address.utilities";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { PropertyAddressComponent } from "@root/shared/property-address/property-address.component";
import { updateAllValueAndValidityInFormGroup } from "@root/shared/utilities/form.utilities";
import { PropertiesCommunicationService } from "@root/shared/abstracts/base-properties-table/properties-communication.service";
import { Router } from "@angular/router";

@Component({
  selector: "est-create-property",
  standalone: true,
  imports: [
    MatTooltip,
    TranslateModule,
    MatProgressBar,
    NgOptimizedImage,
    MatStepperModule,
    CompanySelectFieldComponent,
    MatDivider,
    InputComponent,
    SelectFieldComponent,
    MatSlideToggle,
    PhoneInputComponent,
    SearchInputComponent,
    SmallListTableComponent,
    PaginationComponent,
    NgClass,
    FormsModule,
    CreationSuccessMessageComponent,
    AddressComponent,
    ButtonComponent,
    GeographicalLocationComponent,
    ReactiveFormsModule,
    ListSelectTeamsComponent,
    PropertyAddressComponent,
  ],
  templateUrl: "./create-property.component.html",
  styleUrl: "./create-property.component.scss",
})
export class CreatePropertyComponent extends BasePropertyFormComponent implements OnInit {
  isPropertyCreated = false;
  router = inject(Router);
  propertyId: number = 0;
  addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  teamIdsControlMapper = new Map<string, FormControl<any[] | null>>([["teamIds", this.teamIdsControl]]);
  override totalTabsNo = 3;
  protected readonly authService = inject(AuthService);
  protected readonly destroyRef = inject(DestroyRef);
  readonly #propertiesService = inject(PropertiesService);
  readonly formattedPrimaryAddressSignal: WritableSignal<string> = signal<string>("");
  readonly formattedSecondaryAddressSignal: WritableSignal<string> = signal<string>("");
  readonly #propertiesCommunicationService = inject(PropertiesCommunicationService);

  ngOnInit(): void {
    this.getAddressLookupsOptions();
  }

  submitFormIfValidAndAddressNotLoading(): void {
    if (this.isDataBeingFetchedOrLoading()) return;
    if (this.matStepper.selectedIndex === this.totalTabsNo - 2) {
      this.submitForm();
      return;
    }

    this.moveToNextStepIfValid();
  }

  isDataBeingFetchedOrLoading(): boolean {
    return this.isPropertyLoading || this.isAddressLoadingSignal() || this.isGeographicalLocationLoadingSignal();
  }

  override submitForm(): void {
    this.isPropertyLoading = true;
    updateAllValueAndValidityInFormGroup(this.form);
    this.#propertiesService.createProperty(this.form.value).subscribe({
      next: (response: number) => {
        this.matStepper.next();
        this.isPropertyLoading = false;
        this.isPropertyCreated = true;
        this.propertyId = response;
        this.#propertiesCommunicationService.reloadPropertiesPage$.next();
      },
      error: () => {
        this.isPropertyLoading = false;
      },
    });
  }

  updateFormattedAddressSignal() {
    this.formattedPrimaryAddressSignal.set(
      formatAddress(this.form.controls.propertyAddress.controls.primaryAddress.controls.addressInformation),
    );
    if (this.form.controls.propertyAddress.controls.secondaryAddress) {
      this.formattedSecondaryAddressSignal.set(
        formatAddress(this.form.controls.propertyAddress.controls.secondaryAddress.controls.addressInformation),
      );
    }
  }

  navigateToProperty() {
    if (this.propertyId === 0) {
      this.dialog.closeAll();
    }

    this.router.navigate([`/properties/${this.propertyId}`]);
    this.dialog.closeAll();
  }

  private getAddressLookupsOptions(): void {
    this.lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }
}
