import { inject, Injectable } from "@angular/core";
import { FacilityLookupsRepository } from "@root/data/market/lookups/repositories/facility-lookups.repository";
import { map, Observable } from "rxjs";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { mapLookupItemsToDropdownOptions } from "@root/data/market/lookups/utilities/lookups.utilties";

@Injectable({
  providedIn: "root",
})
export class FacilityLookupsService {
  readonly #facilityLookupsRepository = inject(FacilityLookupsRepository);

  getUnitFacilityLocationsLookups(): Observable<IDropdownOption[]> {
    return this.#facilityLookupsRepository.getUnitFacilityLocationsLookups().pipe(
      map((lookUpsOptions) => {
        return mapLookupItemsToDropdownOptions(lookUpsOptions);
      }),
    );
  }

  getUnitFacilityCategoriesLookups(): Observable<IDropdownOption[]> {
    return this.#facilityLookupsRepository.getUnitFacilityCategoriesLookups().pipe(
      map((lookUpsOptions) => {
        return mapLookupItemsToDropdownOptions(lookUpsOptions);
      }),
    );
  }
}
