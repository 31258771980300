<form [formGroup]="systemAdminForm" (ngSubmit)="createSystemAdmin()">
  <div [ngClass]="{ 'absolute inset-0 bg-black/20 z-[100]': isLoading }" *ngIf="isLoading"></div>
  <div class="flex flex-col items-start p-6 sticky border-b border-black-100 top-0 bg-white z-10">
    <div class="flex gap-2 items-center">
      <p class="text-2xl font-medium mb-1">{{ "SETTINGS.USERS.CREATE_NEW_SYSTEM_ADMIN" | translate }}</p>
      <button
        class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
      >
        ?
      </button>
    </div>
  </div>

  <div class="pb-8 h-[calc(100dvh-14rem)] overflow-y-auto">
    <p class="w-[52%] ps-6 pt-6 text-sm">{{ "SETTINGS.USERS.CREATE_SYSTEM_ADMIN_SUBTITLE" | translate }}</p>
    <!--  user information -->
    <div class="flex flex-col p-6 border-b border-black-100 flex-wrap">
      <p class="font-medium mb-4">{{ "SETTINGS.USERS.USER_INFORMATION" | translate }}</p>
      <div class="flex gap-4 mb-4">
        <est-input
          [appliedClass]="'w-[18.75rem]'"
          [label]="'SETTINGS.USERS.FIRST_NAME' | translate"
          [name]="'firstName'"
          [control]="firstName"
        />
        <est-input
          [appliedClass]="'w-[18.75rem]'"
          [label]="'SETTINGS.USERS.LAST_NAME' | translate"
          [name]="'lastName'"
          [control]="lastName"
        />
      </div>
      <div class="flex gap-4 mb-4">
        <est-input [appliedClass]="'w-[18.75rem]'" [label]="'EMAIL' | translate" [name]="'email'" [control]="email" />
        <est-input
          [appliedClass]="'w-[18.75rem]'"
          [label]="'SETTINGS.USERS.WORKPLACE' | translate"
          [name]="'workPlace'"
          [control]="workPlace"
        />
      </div>
      <div class="flex gap-4 mb-4">
        <est-input
          [appliedClass]="'w-[18.75rem]'"
          [label]="'SETTINGS.USERS.TITLE' | translate"
          [name]="'title'"
          [control]="title"
        />
        <est-phone-input [control]="phoneNumber" />
      </div>
    </div>
    <!--  system language -->
    <div class="flex flex-col p-6 border-b border-black-100">
      <p class="font-medium mb-4">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE" | translate }}</p>
      <p class="text-sm mb-6">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE_SUBTITLE" | translate }}</p>
      <est-language-dropdown
        [appliedClasses]="'w-[300px] h-[44px]'"
        selectMultipleOptions="false"
        [countries]="languages"
        [control]="systemLanguage"
      />
    </div>
  </div>

  <div class="flex justify-between bg-black-50 px-6 py-3 sticky bottom-0 z-10">
    <button
      mat-dialog-close
      class="button--tertiary !px-6 !bg-white uppercase tracking-[0.5px] flex items-center gap-2"
    >
      {{ "SETTINGS.USERS.CANCEL" | translate }}
    </button>
    <est-button
      [isLoading]="isLoading"
      [label]="'CREATE' | translate"
      [buttonStyleType]="'button--primary'"
      [buttonType]="'submit'"
    >
    </est-button>
  </div>
</form>
