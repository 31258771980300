<div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
  <div class="flex mb-5">
    <est-input label="UNIT_ID" [control]="unitId" appliedClass="!w-[18rem] !me-2"></est-input>
    <est-button
      label="NAVIGATE_TO_UNIT"
      buttonStyleType="button--primary"
      buttonType="button"
      (click)="navigateToUnitById()"
    ></est-button>
  </div>
  <div class="flex">
    <est-input label="PROPERTY_ID" [control]="propertyId" appliedClass="!w-[18rem] !me-2"></est-input>
    <est-button
      label="NAVIGATE_TO_PROPERTY"
      buttonStyleType="button--primary"
      buttonType="button"
      (click)="navigateToPropertyById()"
    ></est-button>
  </div>
</div>
