import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";

export const mapFollowUpTaskToSignalResponseSmallTableInput = (): OperatorFunction<any, any> => {
  return map((paginatedResponse: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedResponse),
      results: signal(paginatedResponse.results as ISmallListTableInput[]),
    };
  });
};
