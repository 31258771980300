import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyLocalMapper } from "@root/shared/mappers/currency-local-mapper";

@Pipe({
  name: "currencyFormatConverter",
  standalone: true,
})
export class CurrencyFormatConverterPipe implements PipeTransform {
  transform(value: number | string, currencyCode: string, minimumFraction: number = 2): string | number {
    if (!value) return "";
    const removeCommasAndDotsAndConvertToNumber: number = parseFloat(value.toString().replace(/,/g, ""));
    const mappedValue = this.convertToString(removeCommasAndDotsAndConvertToNumber, minimumFraction, currencyCode);

    return mappedValue;
  }

  convertToString(value: number, minimumFraction: number = 2, currencyCode: string): string {
    value = Math.round(value * 10 * minimumFraction) / (10 * minimumFraction);

    let [integerPart, fractionalPart] = value.toString().split(".");
    if (currencyCode) {
      return value.toLocaleString(CurrencyLocalMapper.get(currencyCode), { minimumFractionDigits: minimumFraction });
    } else {
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if (fractionalPart) {
        fractionalPart = fractionalPart.padEnd(2, "0");
      } else {
        fractionalPart = "00";
      }
      return `${integerPart}.${fractionalPart}`;
    }
  }
}
