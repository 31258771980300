<div class="max-w-screen-max-screen">
  <div class="bg-black-200 rounded-[0.625rem] p-6">
    <div class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative">
      <est-button
        (click)="open()"
        appliedClasses="mb-4"
        buttonStyleType="button--primary"
        buttonType="button"
        label="Open customer card"
      ></est-button>
      <est-button
        (click)="openInviteCustomerOnsiteFlow()"
        buttonStyleType="button--primary"
        buttonType="button"
        label="Invtie customer onsite"
      >
      </est-button>
    </div>
  </div>
</div>
