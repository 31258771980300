import { booleanAttribute, Component, DestroyRef, inject, input, OnInit, output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { MatDialog } from "@angular/material/dialog";
import { FileUploadDialogComponent } from "@root/shared/file-upload-dialog/file-upload-dialog.component";
import { IFileUploadInputs } from "@root/shared/file-upload-dialog/file-upload-inputs.interface";
import { getFileSize } from "@root/shared/utilities/files.utilities";
import { MatError } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { shouldShowControlRequiredError } from "@root/shared/utilities/form.utilities";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";

@Component({
  selector: "est-file-upload",
  standalone: true,
  imports: [NgClass, NgOptimizedImage, TranslateModule, ButtonComponent, MatError, MatIcon],
  templateUrl: "./file-upload.component.html",
  styleUrl: "./file-upload.component.scss",
})
export class FileUploadComponent implements OnInit {
  control = input.required<FormControl<File | null>>();
  appliedClass = input("");
  supportedTypes = input.required<FileExtension[]>();
  fileUpload = output<File>();
  protected readonly getFileSizeInKb = getFileSize;
  protected readonly shouldShowControlError = shouldShowControlRequiredError;
  maxSizeInMb = input<number>();
  readonly #destroyRef$ = inject(DestroyRef);
  #matDialog = inject(MatDialog);
  isMultiple = input(false, { transform: booleanAttribute });

  openUploadFileDialog(): void {
    this.#matDialog.open<FileUploadDialogComponent, IFileUploadInputs>(FileUploadDialogComponent, {
      data: {
        control: this.control(),
        isMultiple: this.isMultiple(),
        supportedTypes: this.supportedTypes(),
        maxSizeInMb: this.maxSizeInMb(),
      },
      width: "40rem",
    });
  }

  ngOnInit() {
    this.#emitEventOnUploading();
  }

  #emitEventOnUploading(): void {
    this.control()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef$))
      .subscribe(() => {
        if (this.control().value) {
          this.fileUpload.emit(this.control().value!);
        }
      });
  }
}
