<div class="w-[36rem] py-4 px-6 relative">
  <div class="flex items-center justify-between mb-4">
    <p class="text-sm font-medium">{{ "PROPERTY_FACILITIES.FACILITIES.FACILITIES_DETAILS" | translate }}</p>
    <p class="text-xs font-light">
      {{ "LAST_CHANGE" | translate }}: <span>{{ lastChangedDate() | customDate }}</span>
    </p>
  </div>
  <hr class="absolute start-0 end-0 border-black-300" />
  <p class="mt-10 font-medium text-xs mb-2">{{ "ICON" | translate }}</p>
  <div class="rounded border border-black-300 flex justify-center items-center w-12 h-12 mb-6">
    @if (iconUrl()) {
      <img [alt]="'ICON' | translate" [src]="iconUrl()" class="max-w-8 max-h-8" />
    }
  </div>
  <div class="grid grid-cols-[repeat(2,1fr)] gap-y-2 font-medium text-xs whitespace-nowrap mb-6">
    <p class="mb-2">{{ "PROPERTY_FACILITIES.INTERNAL_TITLE" | translate }}</p>
    <p class="mb-2">{{ "LANGUAGE" | translate }}</p>
    <p class="font-light text-xs">{{ internalTitle().title }}</p>
    <div class="flex items-center gap-2 font-light text-xs">
      <img [alt]="'FLAG' | translate" [src]="internalTitle().language.flagImageUrl" class="w-5" />
      <p>
        {{ internalTitle().language.name }} <span class="text-black-500">({{ "SYSTEM_DEFAULT" | translate }})</span>
      </p>
    </div>
  </div>
  @if (externalTitles().length > 0) {
    <div class="grid grid-cols-[repeat(2,1fr)] gap-y-2 font-medium text-xs whitespace-nowrap">
      <p class="mb-2">{{ "PROPERTY_FACILITIES.EXTERNAL_TITLE" | translate }}</p>
      <p class="mb-2">{{ "LANGUAGE" | translate }}</p>
      @for (externalTitle of externalTitles(); track $index) {
        <p class="font-light">{{ externalTitle.title }}</p>
        <div class="flex items-center gap-2 mb-2">
          <img [alt]="'FLAG' | translate" [src]="externalTitle.language.flagImageUrl" class="w-5" />
          <p class="font-light">
            {{ externalTitle.language.name }}
            @if ($first) {
              <span class="text-black-500">({{ "END_USER_DEFAULT" | translate }})</span>
            }
          </p>
        </div>
      }
    </div>
  }
</div>
