import { UnitFieldType } from "@root/data/market/import/enums/unit-field-type.enum";
import { UNITS_COLUMN_NAMES } from "@root/views/main/toolbox/import-data/constants/units-fields-names.constants";

export const UNITS_FIELDS_TYPES_HASHMAP = new Map<string, UnitFieldType>([
  [UNITS_COLUMN_NAMES.housingType, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.listingType, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.constructionYear, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.floorPlanType, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.stage, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.plotNumber, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.administrationStartDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.administrationEndDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.vacancyDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.longitude, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.latitude, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.isUnderRenovation, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.renovationStartDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.renovationEndDate, UnitFieldType.Date],
  [UNITS_COLUMN_NAMES.seoTitle, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.metaDescription, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.externalLink, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.energyLabel, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.floor, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.houseNumber, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.street, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.door, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.zipCode, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.city, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.country, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.paymentFrequency, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.salePrice, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.rentPrice, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoHeat, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoWater, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoElectricity, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.acontoResidentsSociety, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.internetFee, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.antennaFee, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.depositMonths, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.prepaidMonths, UnitFieldType.Integer],
  [UNITS_COLUMN_NAMES.nonCancellationPeriodMonths, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.rentalCondition, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.rentalPeriodMonths, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.interiorMaintenance, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.exteriorMaintenance, UnitFieldType.String],
  [UNITS_COLUMN_NAMES.usageType, UnitFieldType.Enum],
  [UNITS_COLUMN_NAMES.usageTypeVisible, UnitFieldType.Boolean],
  [UNITS_COLUMN_NAMES.noticePeriodDays, UnitFieldType.Enum],
]);
