import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { IAvailableCurrencies } from "@root/data/market/units/models/unit-available-currencies.model";
import { IUnitEconomyDetails } from "@root/data/market/units/models/unit-economy-details.model";
import { Observable } from "rxjs";
import { IUnitDetails } from "../models/unit-details.model";
import { IUnitTermsAndConditions } from "../models/unit-terms-and-conditions.model";
import { IUnitHiddenFieldsModel } from "../models/unit-hidden-fields.model";
import { IPetTypePolicy } from "@root/data/market/pets/models/pet-type-policy.model";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IUnitResponseOverview } from "@root/data/market/units/models/unit-response-overview.model";
import { UnitType } from "@root/data/market/units/enums/unit-type.enum";

@Injectable({
  providedIn: "root",
})
export class UnitsRepository {
  readonly #http = inject(HttpClient);

  getUnitDetails(id: string): Observable<IUnitDetails> {
    return this.#http.get<IUnitDetails>(APIS.unit.getUnitDetails(id));
  }

  getHiddenFields(id: string): Observable<IUnitHiddenFieldsModel> {
    return this.#http.get<IUnitHiddenFieldsModel>(APIS.unit.getHiddenUnitInputs(id), {
      params: new HttpParams({
        fromObject: { id },
      }),
    });
  }

  getAllCurrenciesForUnit(unitId: string): Observable<IAvailableCurrencies> {
    return this.#http.get<IAvailableCurrencies>(APIS.currency.getAvailableCurrencies, {
      params: new HttpParams({
        fromObject: { unitId },
      }),
    });
  }

  getUnitEconomyDetailsById(unitId: string): Observable<IUnitEconomyDetails> {
    return this.#http.get<IUnitEconomyDetails>(APIS.unit.getEconomyDetailsForUnit, {
      params: new HttpParams({
        fromObject: { unitId },
      }),
    });
  }

  getTermsAndConditions(unitId: string): Observable<IUnitTermsAndConditions> {
    return this.#http.get<IUnitTermsAndConditions>(APIS.unit.getTermsAndConditions, {
      params: new HttpParams({
        fromObject: { unitId },
      }),
    });
  }

  updateUnitDetails(id: string, unitTermsAndConditionsForm: { [key: string]: any }): Observable<void> {
    return this.#http.patch<void>(APIS.unit.updateUnitDetails(id), unitTermsAndConditionsForm);
  }

  updateTermsAndConditions(id: string, unitDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#http.patch<void>(APIS.unit.updateUnitTermsAndConditions(id), unitDetailsForm);
  }

  updateUnitEconomyField(unitId: string, path: string, value: any): Observable<void> {
    const requestBody: { [key: string]: any } = {};
    requestBody[path] = value;
    return this.#http.patch<void>(APIS.unit.updateUnitEconomyField(unitId), requestBody);
  }

  updateUnitPetTypePolicy(unitId: string, petTypePolicies: IPetTypePolicy[]) {
    return this.#http.post<void>(APIS.unit.updateUnitPetTypePolicy(unitId), petTypePolicies);
  }

  getPetPolicies(unitId: string) {
    return this.#http.get<IPetTypePolicy[]>(APIS.unit.getPetPolicies, {
      params: new HttpParams({
        fromObject: { unitId },
      }),
    });
  }

  getPaginatedUnitsInProperty(
    params: IPaginationSortPayload & {
      propertyIds: string[];
    } & { unitType: UnitType },
  ): Observable<IPaginatedResponse<IUnitResponseOverview>> {
    return this.#http.get<IPaginatedResponse<IUnitResponseOverview>>(APIS.unit.getUnitsInProperty, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  toggleFavoriteUnit(unitId: string): Observable<boolean> {
    return this.#http.post<boolean>(APIS.unit.toggleFavoriteUnit(unitId), {});
  }

  makeUnitAvailable(unitId: string): Observable<boolean> {
    return this.#http.post<boolean>(APIS.unit.makeUnitAvailable(unitId), {});
  }

  toggleAdvertiseUnit(unitId: string): Observable<boolean> {
    return this.#http.post<boolean>(APIS.unit.toggleAdvertiseUnit(unitId), {});
  }
}
