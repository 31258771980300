import { Routes } from "@angular/router";
import { PropertyTextsComponent } from "./property-texts/property-texts.component";
import { PropertyTextPackagesComponent } from "./property-text-packages/property-text-packages.component";

export const PropertyTextsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "property-texts",
  },
  {
    path: "property-texts",
    component: PropertyTextsComponent,
    data: {
      name: "PROPERTY_TEXTS.TAB_NAME",
    },
  },
  {
    path: "text-packages",
    component: PropertyTextPackagesComponent,
    data: {
      name: "PACKAGES.TEXT_PACKAGES.NAME",
    },
  },
];
