import { Component, EventEmitter, Inject, Input, input, Output, signal, WritableSignal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { transferBytesUtility } from "../utilities/transfer-bytes.utility";
import { SmallHeaderComponent } from "../small-header/small-header.component";
import { UploadFileDropZoneComponent } from "@root/shared/upload-file-drop-zone/upload-file-drop-zone.component";
import { ButtonComponent } from "../button/button.component";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
@Component({
  selector: "est-image-upload-dialog",
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltip,
    MatDividerModule,
    SmallHeaderComponent,
    MatDialogClose,
    UploadFileDropZoneComponent,
    ButtonComponent,
  ],
  templateUrl: "./image-upload-dialog.component.html",
  styleUrl: "./image-upload-dialog.component.scss",
})
export class ImageUploadDialogComponent {
  image = input<FormControl<string | null>>();
  @Input() imageName: FormControl<string | null> = new FormControl(null);
  fileSize = input<FormControl<string | null>>();
  imageDimensions: WritableSignal<string> = signal("");
  @Output() img: EventEmitter<File | null> = new EventEmitter<File | null>();
  fileSizeConvertToBytes: number = 0;
  displayImage: string | null = null;
  imageToSentToCreateUserComponent: File | null = null;
  protected readonly FileExtension = FileExtension;
  protected readonly transferBytes = transferBytesUtility;

  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  fileChangeEvent(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    if (element.files && element.files.length > 0) {
      this.imageToSentToCreateUserComponent = element.files[0];
      this.fileSizeConvertToBytes = this.imageToSentToCreateUserComponent.size;
      this.fileSize()?.setValue(this.imageToSentToCreateUserComponent.size.toString());
      this.imageName?.setValue(this.imageToSentToCreateUserComponent.name);
      this.setImageUrlOnLoadedWithImageDimensions(this.imageToSentToCreateUserComponent);
    }
  }

  setImageUrlOnLoadedWithImageDimensions(image: File) {
    const reader = new FileReader();
    reader.onload = (image: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = image.target?.result as string;
      this.image()?.setValue(img.src);
      img.onload = () => {
        this.imageDimensions.set(`${img.width} X ${img.height}`);
      };
      this.displayImage = img.src;
    };
    reader.readAsDataURL(image);
  }

  sendImageToCreateUserComponent() {
    this.dialogRef.close(this.imageToSentToCreateUserComponent);
  }
}
