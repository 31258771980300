<div class="flex flex-col items-center sticky min-h-[41.8rem]">
  <div class="text-center mb-6 pt-16">
    <p class="text-[2rem] font-bold mb-4">{{ mainTitle() | translate }}</p>
    <p class="font-light">{{ mainDescription() | translate }}</p>
  </div>
  <div class="bg-black-50 border border-black-300 px-6 py-4 text-center rounded-lg">
    <p class="text-xl mb-2.5 font-medium">
      {{ secondaryTitle() | translate }}
    </p>
    <p class="text-sm font-light">
      {{ secondaryDescription() | translate }}
    </p>
  </div>
  <img
    [alt]="mainTitle() | translate"
    class="absolute bottom-0"
    draggable="false"
    height="286"
    ngSrc="assets/images/times-red-rounded.png"
    priority
    width="256"
  />
</div>
