<div
  [ngClass]="{ 'bg-success-50': score() === MediaQuality.Good, 'bg-error-300': score() === MediaQuality.Bad }"
  class="px-1.5 rounded-sm w-fit"
>
  <p class="text-[0.56rem] uppercase">
    @switch (score()) {
      @case (MediaQuality.Good) {
        {{ "GOOD" | translate }}
      }
      @case (MediaQuality.Bad) {
        {{ "BAD" | translate }}
      }
    }
  </p>
</div>
