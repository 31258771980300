import { Component, inject } from "@angular/core";
import { PropertiesFacilitiesService } from "@root/data/market/properties/services/properties-facilities.service";
import { constructInitialSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { IPropertyFacilityLookup } from "@root/data/market/properties/models/property-facility-lookup.model";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { Observable, tap } from "rxjs";
import { PropertiesFacilitiesCommunicationService } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/properties-facilities-comminication.service";
import {
  BASE_ADD_FACILITY_COMPONENT_IMPORTS,
  BaseAddFacilityComponent,
} from "@root/shared/abstracts/base-add-facility/base-add-facility.component";
import { IFacilityLookup } from "@root/data/market/shared/models/facility-lookup.model";

@Component({
  selector: "est-add-property-facility",
  standalone: true,
  imports: BASE_ADD_FACILITY_COMPONENT_IMPORTS,
  templateUrl: "../../../../../../../../shared/abstracts/base-add-facility/base-add-facility.component.html",
})
export class AddPropertyFacilityComponent extends BaseAddFacilityComponent {
  override paginatedFacilities = constructInitialSignalPaginatedResponse<IPropertyFacilityLookup>();
  readonly #propertiesFacilitiesService = inject(PropertiesFacilitiesService);
  readonly #propertiesFacilitiesCommunicationService = inject(PropertiesFacilitiesCommunicationService);

  protected getAddFacilityServiceCall(): Observable<void> {
    const propertiesFacilitiesIds = this.getSelectedFacilitiesIds();
    return this.#propertiesFacilitiesService
      .addPropertyFacility(+this.componentInputs.entityId, propertiesFacilitiesIds)
      .pipe(
        tap(() => {
          this.#propertiesFacilitiesCommunicationService.propertyFacilityModified$.next();
        }),
      );
  }

  protected getAssignedIdsServiceCall(): Observable<number[]> {
    return this.#propertiesFacilitiesService.getAssignedPropertyFacilitiesIds(+this.componentInputs.entityId);
  }

  protected getLoadDataServiceCall(): Observable<ISignalPaginatedResponse<IFacilityLookup>> {
    return this.#propertiesFacilitiesService.getPaginatedPropertiesFacilities({
      search: this.searchSignal(),
      pageSize: this.paginatedFacilities.pageSize(),
      pageNumber: this.paginatedFacilities.currentPage(),
    });
  }
}
