<div class="p-6">
  <div class="flex items-center mb-2 gap-2">
    <p class="font-medium">{{ "EVENTS.SELECTED_UNITS" | translate }}</p>
    <est-tooltip tooltip="EVENTS.SELECTED_UNITS"></est-tooltip>
  </div>
  <div class="flex justify-between items-center mb-6">
    <p class="font-light text-sm w-[40rem]">{{ "EVENTS.SELECTED_UNITS_DESCRIPTION" | translate }}</p>
    <est-button
      label="EVENTS.ADD_UNITS_TO_EVENT"
      appliedClasses="uppercase !px-6 !py-3"
      buttonStyleType="button--primary"
      [isLoading]="false"
      buttonType="button"
      imagePosition="after"
      imageAlt="ADD"
      imageSrc="assets/images/icons/plus.svg"
      (click)="addNewUnits()"
    ></est-button>
  </div>
  <est-small-list-table
    [data]="selectedUnits()"
    [headers]="headers"
    [isLoading]="false"
    [sortingFilterSignal]="sortingFilterSignal"
    [iconMenuComponentType]="outdoorSpaceForUnitComponent"
  ></est-small-list-table>
</div>
