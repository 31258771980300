import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const DUPLICATE_DIALOG: ITableHeader[] = [
  {
    key: "propertyId",
    label: "",
    type: TableHeaderType.Checkbox,
    isSortable: true,
  },
  {
    key: "name",
    label: "PROPERTIES.PROPERTY",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "company",
    label: "COMPANIES.COMPANY",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "city",
    label: "ADDRESS.CITY",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "type",
    label: "TYPE",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "units",
    label: "UNITS",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "unitsOverview",
    label: "OVERALL_STATUS",
    type: TableHeaderType.OverViewStatus,
    isSortable: false,
  },
];
