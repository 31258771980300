<div class="p-6 pb-[5.5rem] overflow-auto max-h-[calc(95dvh)]">
  <p class="font-medium text-xl mb-6">{{ "ASSIGNED_PROPERTIES" | translate }}</p>
  <hr class="absolute start-0 end-0 border-black-100" />
  <div class="flex justify-between gap-x-20 gap-y-6 items-end mb-6 max-lg:flex-col max-lg:items-start">
    <div>
      <p class="mt-12 text-sm font-medium mb-6">{{ "SETTINGS.USERS.ASSIGNED_PROPERTIES_TEAM" | translate }}</p>
      <p class="text-sm font-light">{{ "SETTINGS.USERS.ASSIGNED_PROPERTIES_TEAM_DESCRIPTION" | translate }}</p>
    </div>
    <est-search-input [searchSignal]="searchSignal"></est-search-input>
  </div>
  <est-small-list-table
    [data]="paginatedData.results()"
    [headers]="headers"
    [isLoading]="isTableLoading"
    [sortingFilterSignal]="sortingFilterSignal"
  ></est-small-list-table>
  <est-pagination [paginationValues]="paginatedData"></est-pagination>
</div>
<div class="absolute start-0 end-0 bg-black-50 border-t border-black-100 px-6 py-[0.625rem] bottom-0 z-10">
  <est-button
    (click)="matDialog.closeAll()"
    appliedClasses="!px-6"
    buttonStyleType="button--tertiary"
    buttonType="button"
    label="CLOSE"
  ></est-button>
</div>
