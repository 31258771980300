import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoomInputFieldsBinder {
  readonly #connectionRoomRemoved = new Subject<number>();
  onConnectionRoomRemoved = this.#connectionRoomRemoved.asObservable();

  emitConnectionRoomRemoved(roomId: number) {
    this.#connectionRoomRemoved.next(roomId);
  }
}
