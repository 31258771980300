<est-event-title [eventTitle]="title" [totalEvents]="allEvents.length " />
<est-event-filter
  [agentsSignal]="agentsSignal"
  [endDateSignal]="endDateSignal"
  [eventTypeSignal]="eventTypeSignal"
  [searchSignal]="searchSignal"
  [startDateSignal]="startDateSignal"
  [isStartDateIncluded]="isStartDateIncluded"
  [isEndDateIncluded]="isEndDateIncluded"
  [propertyId]="propertyId()"
></est-event-filter>
<div class="mt-6 relative">
  <div id="main-box" class="grid">
    @for (event of allEvents; track $index) {
    <est-event-card
      [eventDetails]="event"
      [propertyId]="propertyId()"
      (isStoppedRepetition)="loadDataAfterStopRepetition($event)"
      (openCard)="cardIsExpanded($event ,$index)"
    ></est-event-card>

    }
  </div>

  @if(!isLoading && !allEvents.length){
  <div class="bg-black-50 flex items-center justify-center py-8 rounded border border-black-300">
    <p [attr.aria-label]="'NO_RESULTS' | translate" class="text-xs font-light">{{ "NO_RESULTS" | translate }}</p>
  </div>
  } @if(isLoading){
  <est-loading-overlay appliedClasses="py-10"></est-loading-overlay>
  }
</div>
