import { Component } from "@angular/core";
import { RouterLink, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NavigationTabsComponent } from "../../../../shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "src/app/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-organization-settings",
  standalone: true,
  imports: [RouterOutlet, RouterLink, TranslateModule, NavigationTabsComponent],
  templateUrl: "./organization-settings.component.html",
  styleUrl: "./organization-settings.component.scss",
})
export class OrganizationSettingsComponent {
  settingsTabs: INavigationTab[] = [
    {
      label: "SETTINGS.USERS.NAME",
      routerLink: "users",
    },
    {
      label: "SETTINGS.SYSTEM_CONFIGURATIONS.NAME",
      routerLink: "system-configurations",
    },
    {
      label: "SETTINGS.MARKETING.NAME",
      routerLink: "marketing",
    },
    {
      label: "PROPERTIES.PROPERTY",
      routerLink: "property",
    },
    {
      label: "SETTINGS.COMMUNICATION.NAME",
      routerLink: "communication",
    },
    {
      label: "SETTINGS.INTEGRATIONS.NAME",
      routerLink: "integrations",
    },
  ];
}
