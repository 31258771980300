import { Component, computed, effect, inject, input, signal, WritableSignal } from "@angular/core";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { LIST_SELECT_USER_ACCESSED_PROPERTIES_HEADERS } from "@root/shared/list-select-user-accessed-properties/list-select-user-accessed-properties.headers";
import { TranslateModule } from "@ngx-translate/core";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { FormControl } from "@angular/forms";
import { MatError } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { EMPTY_ARRAY_ERROR_KEY } from "@root/shared/constants/error-keys.constants";

@Component({
  selector: "est-list-select-user-accessed-properties",
  standalone: true,
  imports: [TranslateModule, SearchInputComponent, SmallListTableComponent, PaginationComponent, MatError, MatIcon],
  templateUrl: "./list-select-user-accessed-properties.component.html",
  styleUrl: "./list-select-user-accessed-properties.component.scss",
})
export class ListSelectUserAccessedPropertiesComponent extends BasePaginatedTableWithSearchComponent {
  readonly selectPropertySubHeaderTranslationKey = input.required<string>();
  readonly control = input.required<FormControl>();
  readonly checkboxControlMapper = computed(() => new Map<string, FormControl>([["propertyIds", this.control()]]));
  headers: ITableHeader[] = LIST_SELECT_USER_ACCESSED_PROPERTIES_HEADERS;
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "name",
    sortDescending: false,
  });
  readonly loadDataEffect$ = effect(() => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      search: this.searchSignal(),
      pageNumber: this.paginatedData.currentPage(),
    });
  });
  protected readonly EMPTY_ARRAY_ERROR_KEY = EMPTY_ARRAY_ERROR_KEY;
  readonly #divisionService = inject(DivisionService);

  loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#divisionService.getProperties(params).subscribe((properties) => {
      copyPaginatedSignalResponse(this.paginatedData, properties);
      this.isTableLoading = false;
    });
  }
}
