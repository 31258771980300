<mat-form-field [ngClass]="appliedClasses()" id="datepicker">
  <mat-date-range-input
    [rangePicker]="picker"
    [min]="!isStartDateIncluded() ? today : ''"
    [max]="!isEndDateIncluded() ? today : ''"
  >
    <input matStartDate [value]="null" (dateChange)="startDateSignal()?.set($event.value)" placeholder="yyyy-mm-dd" />
    <input matEndDate [value]="null" (dateChange)="endDateSignal()?.set($event.value)" placeholder="yyyy-mm-dd" />
  </mat-date-range-input>
  <mat-label>{{ label() | translate }}</mat-label>
  <mat-datepicker-toggle [disableRipple]="true" [for]="picker" matIconSuffix>
    <img
      [alt]="'CHOOSE_DATE' | translate"
      height="14"
      matDatepickerToggleIcon
      ngSrc="assets/images/icons/calendar.svg"
      priority
      width="14"
    />
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
