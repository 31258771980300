import { inject, Injectable } from "@angular/core";
import { ImportRepository } from "@root/data/market/import/repositories/import.repository";
import { Observable } from "rxjs";
import { IParseCsvResponse } from "@root/data/market/import/models/parse-csv-response.model";
import { mapCsvFileToFormData } from "@root/data/market/import/utilities/import-form-data.utilities";
import { ICreateUnitsPayload } from "@root/data/market/import/models/create-units-payload.model";
import {
  mapCsvFieldsToDtoFieldsAndConvertTypes,
  transformUnitValues,
} from "@root/data/market/import/utilities/import.utilities";

@Injectable({
  providedIn: "root",
})
export class ImportService {
  readonly #importRepository = inject(ImportRepository);

  parseCsv(file: File): Observable<IParseCsvResponse> {
    const formData = mapCsvFileToFormData(file);
    return this.#importRepository.parseCsv(formData).pipe(transformUnitValues());
  }

  downloadTemplate(): Observable<Blob> {
    return this.#importRepository.downloadTemplate();
  }

  createUnits(payload: ICreateUnitsPayload, csvFieldToDtoFieldMapper: Map<string, string>): Observable<number> {
    payload.units = mapCsvFieldsToDtoFieldsAndConvertTypes(payload.units, csvFieldToDtoFieldMapper);
    return this.#importRepository.createUnits(payload);
  }
}
