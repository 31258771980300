<div class="flex justify-between gap-8 flex-wrap items-start mb-4">
  <est-small-header
    heading="TEAMS.NAME"
    subheading="SETTINGS.USERS.TEAMS_DESCRIPTION"
    tooltip="Teams"
  ></est-small-header>
  <div class="flex gap-4 items-center">
    <est-search-input [searchSignal]="searchSignal" placeholder="TEAMS.SEARCH_FOR_TEAM" />
    <est-button
      (click)="openCreateTeamModal()"
      buttonStyleType="button--primary"
      buttonType="button"
      imageAlt="ADD"
      imageSrc="assets/images/icons/plus.svg"
      label="TEAMS.CREATE_TEAM"
    />
  </div>
</div>
<est-small-list-table
  (itemPopup)="openAssignedPropertiesModal($event)"
  [actions]="actions"
  [data]="paginatedData.results()"
  [headers]="headers"
  [isLoading]="isTableLoading"
  [isPopupIconAvailable]="isTeamPopupIconAvailable"
  [sortingFilterSignal]="sortingFilterSignal"
></est-small-list-table>
<est-pagination [paginationValues]="paginatedData"></est-pagination>
