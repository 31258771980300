import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPropertyFacilityOverview } from "@root/data/market/properties/models/property-facility-overview.model";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { PropertyFacilityLocationTranslationMapper } from "@root/data/market/properties/mappers/property-facility-location-translation.mapper";
import { IPackageProperty } from "@root/data/market/properties/models/package-property.model";
import { mapUnitsOverviewToUnitStatuses } from "@root/data/utilities/units-overview.utilities";

export const mapPaginatedPropertiesFacilitiesToSmallListTableInput = (): OperatorFunction<
  IPaginatedResponse<IPropertyFacilityOverview>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((paginatedResponse) => ({
    ...mapToSignalPaginatedResponse(paginatedResponse),
    results: signal(mapPropertiesFacilitiesToSmallListTable(paginatedResponse.results)),
  }));
};

const mapPropertiesFacilitiesToSmallListTable = (
  propertiesFacilities: IPropertyFacilityOverview[],
): ISmallListTableInput[] => {
  return propertiesFacilities.map((propertyFacility) => ({
    ...propertyFacility,
    isAccessible: propertyFacility.isAccessible ? "YES" : "NO",
    isPaymentRequired: propertyFacility.isPaymentRequired ? "YES" : "NO",
    location: PropertyFacilityLocationTranslationMapper.get(propertyFacility.location)!,
    mouseoverMenuInputs: {
      externalTitles: propertyFacility.externalTitles,
      internalTitle: propertyFacility.internalTitle,
      lastChangedDate: propertyFacility.lastModified,
      iconUrl: propertyFacility.iconUrl,
    },
  }));
};

export const mapLinkedFacilityPackageListUnitToSignalResponseSmallTableInput = (): OperatorFunction<
  IPaginatedResponse<IPackageProperty>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((paginatedUnits: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedUnits),
      results: signal(mapLinkedFacilityPackageListToSmallListTableInput(paginatedUnits.results)),
    };
  });
};
const mapLinkedFacilityPackageListToSmallListTableInput = (
  facilitiesPackage: IPackageProperty[],
): ISmallListTableInput[] => {
  return facilitiesPackage.map((facility) => ({
    ...facility,
    unitStatuses: mapUnitsOverviewToUnitStatuses(facility.unitsOverview),
  }));
};
