import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder } from "@angular/forms";
import { PaymentFrequency } from "@root/shared/enums/payment-frequency.enum";
import { IPropertyFacility } from "@root/data/market/properties/models/property-facility.model";

@Injectable({ providedIn: "root" })
export class EditPropertyFacilityFormControls {
  readonly #fb = inject(NonNullableFormBuilder);
  form = this.#fb.group({
    isAccessible: this.#fb.control<boolean>(false),
    isPaymentRequired: this.#fb.control<boolean>(false),
    paymentFrequency: this.#fb.control<PaymentFrequency | undefined>(undefined),
    price: this.#fb.control<number | undefined>(undefined),
    showDistance: this.#fb.control<boolean | undefined>(undefined),
    distance: this.#fb.control<number | undefined>(undefined),
  });

  patchValue(value: Partial<IPropertyFacility>) {
    this.form.patchValue(value);
  }
}
