<div>
  <div class="flex flex-col items-start p-6 sticky border-b border-black-100 top-0 bg-white z-10">
    <div class="flex gap-2 items-center">
      <p class="text-2xl font-medium mb-1">{{ "SETTINGS.USERS.ASSIGN_PROPERTIES_TO_TEAM.TITLE" | translate }}</p>
      <button
        [matTooltip]="'SETTINGS.USERS.ASSIGN_PROPERTIES_TO_TEAM.TITLE' | translate"
        class="text-xs text-white bg-primary rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
      >
        ?
      </button>
    </div>
  </div>

  <div class="p-6 border-b border-black-100">
    <p class="font-bold mb-4">
      {{ "SETTINGS.USERS.ASSIGN_PROPERTIES_TO_TEAM.TITLE" | translate }}
    </p>
    <p class="text-sm mb-4 font-light max-w-[62.5rem]">
      {{ "SETTINGS.USERS.ASSIGN_PROPERTIES_TO_TEAM.SUBTITLE" | translate }}
    </p>
    <p class="font-bold">
      {{ "SETTINGS.USERS.ASSIGN_PROPERTIES_TO_TEAM.TEAM_ACCESS" | translate }}:
      {{ selectedPropertiesArray ? selectedPropertiesArray.length : 0 }}
      {{ "SETTINGS.USERS.ASSIGN_PROPERTIES_TO_TEAM.OUT_OF" | translate }}
      {{ totalPropertiesAvailable }}
      {{ "PROPERTIES.NAME" | translate }}
    </p>
  </div>
  <div class="p-6">
    <div>
      <est-assign-properties-to-team-tree
        (totalProperties)="updatePropertiesCount($event)"
        (selectedPropertyIdsChange)="updateSelectedPropertiesArray($event)"
        [teamId]="componentInputs.teamId"
      ></est-assign-properties-to-team-tree>
    </div>
  </div>
  <div class="flex justify-between bg-black-50 px-6 py-3 sticky bottom-0 z-10">
    <est-button mat-dialog-close label="CLOSE" buttonStyleType="button--tertiary" buttonType="button"> </est-button>

    <est-button
      (click)="assignProperties()"
      label="SAVE"
      buttonStyleType="button--primary"
      buttonType="button"
      [isLoading]="isLoading"
    >
    </est-button>
  </div>
</div>
