import { Component, inject } from "@angular/core";
import {
  BASE_ADD_FACILITY_COMPONENT_IMPORTS,
  BaseAddFacilityComponent,
} from "@root/shared/abstracts/base-add-facility/base-add-facility.component";
import { Observable, tap } from "rxjs";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IFacilityLookup } from "@root/data/market/shared/models/facility-lookup.model";
import { UnitFacilitiesService } from "@root/data/market/units/services/unit-facilities.service";
import { UnitFacilitiesCommunicationService } from "@root/views/main/units/unit-details/components/facilities/unit-facilities/unit-facilities-communication.service";

@Component({
  selector: "est-add-unit-facility",
  standalone: true,
  imports: BASE_ADD_FACILITY_COMPONENT_IMPORTS,
  templateUrl: "../../../../../../../../shared/abstracts/base-add-facility/base-add-facility.component.html",
})
export class AddUnitFacilityComponent extends BaseAddFacilityComponent {
  readonly #unitFacilitiesService = inject(UnitFacilitiesService);
  readonly #unitFacilitiesCommunicationService = inject(UnitFacilitiesCommunicationService);

  protected getAddFacilityServiceCall(): Observable<void> {
    return this.#unitFacilitiesService
      .assignUnitFacility(this.componentInputs.entityId.toString(), this.getSelectedFacilitiesIds())
      .pipe(
        tap(() => {
          this.#unitFacilitiesCommunicationService.unitFacilitiesModified$.next();
        }),
      );
  }

  protected getAssignedIdsServiceCall(): Observable<number[]> {
    return this.#unitFacilitiesService.getUnitAssignedFacilitiesIds(this.componentInputs.entityId.toString());
  }

  protected getLoadDataServiceCall(): Observable<ISignalPaginatedResponse<IFacilityLookup>> {
    return this.#unitFacilitiesService.getUnitFacilitiesLookups({
      search: this.searchSignal(),
      pageNumber: this.paginatedFacilities.currentPage(),
      pageSize: this.paginatedFacilities.pageSize(),
    });
  }
}
