import { inject, Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class OtherFacilitiesFormControls {
  readonly #fb = inject(FormBuilder);
  form = this.#fb.group({
    otherFacility1: this.#fb.control<string | null>(null),
    otherFacility2: this.#fb.control<string | null>(null),
    otherFacility3: this.#fb.control<string | null>(null),
  });
}
