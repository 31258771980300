import { Component } from "@angular/core";
import { BASE_DUPLICATE_PACKAGE_IMPORTS } from "@root/shared/abstracts/base-duplicate-package/base-duplicate-package.imports";
import { BaseDuplicatePropertyTextComponent } from "../../base-duplicate-property-text/base-duplicate-property-text.component";

@Component({
  selector: "est-duplicate-property-text",
  standalone: true,
  imports: BASE_DUPLICATE_PACKAGE_IMPORTS,
  templateUrl: "/src/app/shared/abstracts/base-duplicate-package/base-duplicate-package.component.html",
  styleUrl: "./duplicate-property-text.component.scss",
})
export class DuplicatePropertyTextComponent extends BaseDuplicatePropertyTextComponent {
  override newPackageSubHeaderTranslationKey = "PROPERTY_TEXTS.DUPLICATE_TEXT.TO_DUPLICATE_A_TEXT";

  constructor() {
    super();
    this.isPackage = false;
    this.translationKeys = {
      popupTitle: "PROPERTY_TEXTS.DUPLICATE_TEXT.TITLE",
      newPackageHeader: "PROPERTY_TEXTS.DUPLICATE_TEXT.NEW_TEXT",
      originalName: "PROPERTY_TEXTS.DUPLICATE_TEXT.ORIGINAL_TEXT_NAME",
      selectPropertySubHeader: "",
    };
  }
}
