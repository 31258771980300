import { Component, effect, EffectRef, inject, input, model, signal, WritableSignal } from "@angular/core";
import { FormControl, NonNullableFormBuilder } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { LinkingPackagesService } from "@root/data/market/linked/services/linking-packages.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";
import { LINKED_UNITS_TABLE_HEADERS } from "@root/shared/headers/linked-units.header";
import { ICheckedItem } from "@root/shared/interfaces/checked-item.interface";
import { IAllCheckedItems } from "@root/shared/interfaces/checked-items.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { OutdoorSpaceForUnitComponent } from "@root/shared/outdoor-space-for-unit/outdoor-space-for-unit.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import {
  constructInitialSignalPaginatedResponseWithSelectedUnit,
  copyPaginatedSignalResponse,
} from "@root/shared/utilities/signals.utilities";
import { finalize } from "rxjs/internal/operators/finalize";

@Component({
  selector: "est-linked-units-table",
  standalone: true,
  imports: [SearchInputComponent, SelectFieldComponent, SmallListTableComponent, PaginationComponent, TranslateModule],
  templateUrl: "./linked-units-table.component.html",
  styleUrl: "./linked-units-table.component.scss",
})
export class LinkedUnitsTableComponent extends BasePaginatedTableWithSearchComponent {
  headers: ITableHeader[] = LINKED_UNITS_TABLE_HEADERS;

  protected readonly outdoorSpaceForUnitComponent = OutdoorSpaceForUnitComponent;
  override paginatedData = constructInitialSignalPaginatedResponseWithSelectedUnit();

  fb = inject(NonNullableFormBuilder);
  #linkingPackagesService = inject(LinkingPackagesService);

  stepIndex = input.required<number>();
  linkingSource = input.required<LinkingSource>();
  propertyIds = input<number[]>([]);
  packageId = input<number>();
  isEdit = input<boolean>();
  addedUnitIds = model.required<Set<string>>();
  removedUnitIds = model.required<Set<string>>();
  selectAllOperation = model.required<SelectionOperation>();

  unitIds = this.fb.control<string[]>([]);
  disabledDropdownFilterControl = this.fb.control({ value: "", disabled: true });

  get selectedUnitsComputed() {
    if (this.selectAllOperation() === SelectionOperation.SELECT_ALL) {
      return this.paginatedData.totalCount() - this.removedUnitIds().size;
    }

    if (this.selectAllOperation() === SelectionOperation.DESELECT_ALL) {
      return this.addedUnitIds().size;
    }

    if (this.isEdit()) {
      return this.paginatedData.selectedUnits() + this.addedUnitIds().size - this.removedUnitIds().size;
    }

    return this.unitIds.value.length;
  }

  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "unitNo",
    sortDescending: false,
  });

  selectedUnitMapper: Map<string, FormControl<string[]>> = new Map([["ids", this.unitIds]]);

  readonly loadDataEffect$: EffectRef = effect(() => {
    if (this.stepIndex() === 3) {
      this.loadData({
        sortBy: this.sortingFilterSignal().sortBy,
        sortDescending: this.sortingFilterSignal().sortDescending,
        pageSize: this.paginatedData.pageSize(),
        pageNumber: this.paginatedData.currentPage(),
        search: this.searchSignal(),
        ...(this.isEdit() ? { filterLinked: false } : { propertyIds: this.propertyIds() }),
      });
    }
  });

  loadData(params: IPaginationSortPayload & { filterLinked?: boolean; propertyIds?: number[] }) {
    this.isTableLoading = true;

    if (this.isEdit()) {
      this.#linkingPackagesService
        .getLinkedUnitsList(params, this.propertyIds()[0], this.packageId()!, this.linkingSource())
        .pipe(finalize(() => (this.isTableLoading = false)))
        .subscribe((paginatedLinkedUnit) => this.#handleResponse(paginatedLinkedUnit));
    } else {
      this.#linkingPackagesService
        .getPaginatedUnitsInProperty(params)
        .pipe(finalize(() => (this.isTableLoading = false)))
        .subscribe((paginatedLinkedUnit) => this.#handleResponse(paginatedLinkedUnit));
    }
  }

  #handleResponse(
    paginatedLinkedUnit: ISignalPaginatedResponse<ISmallListTableInput<string>> & {
      selectedUnits: WritableSignal<number>;
    },
  ): void {
    copyPaginatedSignalResponse(this.paginatedData, paginatedLinkedUnit);
    this.paginatedData.selectedUnits.set(paginatedLinkedUnit.selectedUnits());
    this.#addingAllUnitInHashMapper(paginatedLinkedUnit.results());
  }

  #addingAllUnitInHashMapper(paginatedLinkedUnit: ISmallListTableInput<string>[]) {
    paginatedLinkedUnit.forEach((unit) => {
      const unitId = unit["unitId"];
      const isUsed = unit["isUsed"];
      if (this.removedUnitIds().has(unitId) || this.unitIds.value.includes(unitId)) return;

      if (this.selectAllOperation() === SelectionOperation.SELECT_ALL) {
        this.unitIds.value?.push(unitId);
      }
      if (this.isEdit() && this.selectAllOperation() === SelectionOperation.DEFAULT) {
        isUsed && this.unitIds.value.push(unitId);
      }
    });
  }

  addAndRemoveItem(event: ICheckedItem) {
    event.checked ? this.addUnit(event.row) : this.removeUnit(event.row);
  }

  checkAllUnits(event: IAllCheckedItems) {
    this.selectAllOperation.set(event.checked ? SelectionOperation.SELECT_ALL : SelectionOperation.DESELECT_ALL);
    this.addedUnitIds.update((set) => {
      set.clear();
      return set;
    });
    this.removedUnitIds.update((set) => {
      set.clear();
      return set;
    });
    if (this.selectAllOperation() === SelectionOperation.DESELECT_ALL) this.unitIds.reset([]);
  }

  private removeUnit(row: ISmallListTableInput<string | number>): void {
    const isUsed = row["isUsed"];
    const unitId = row["unitId"];
    this.addedUnitIds.update((set) => {
      set.delete(unitId);
      return set;
    });
    if (isUsed || this.selectAllOperation() !== SelectionOperation.DESELECT_ALL) {
      this.removedUnitIds.update((set) => set.add(unitId));
    }
  }

  private addUnit(row: ISmallListTableInput<string | number>): void {
    const isUsed = row["isUsed"];
    const unitId = row["unitId"];
    this.removedUnitIds.update((set) => {
      set.delete(unitId);
      return set;
    });
    if (!isUsed || this.selectAllOperation() !== SelectionOperation.SELECT_ALL) {
      this.addedUnitIds.update((set) => set.add(unitId));
    }
  }
}
