import { Component, inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { ListSelectPropertiesComponent } from "@root/shared/list-select-properties/list-select-properties.component";
import { SelectDivisionFieldComponent } from "@root/shared/select-search-division/select-division-field.component";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { concatMap, OperatorFunction } from "rxjs";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { ISignalPaginatedPropertyLookupResponse } from "@root/shared/interfaces/signal-property-lookup-response.interface";
import { FormControl } from "@angular/forms";
import { UsersService } from "@root/data/market/users/services/users.service";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { IPropertyIdAccess } from "@root/shared/list-select-properties/property-id-access.interface";
import { RoleNameMapper } from "@root/data/market/users/mappers/role-name.mapper";

@Component({
  selector: "est-property-access",
  standalone: true,
  imports: [
    TranslateModule,
    ListSelectPropertiesComponent,
    SelectDivisionFieldComponent,
    SpinnerComponent,
    ButtonComponent,
  ],
  templateUrl: "./property-access.component.html",
  styleUrl: "./property-access.component.scss",
})
export class PropertyAccessComponent implements OnInit {
  componentInputs: { userId: number } = inject(MAT_DIALOG_DATA);
  matDialog = inject(MatDialog);
  divisionSignal = signal<number | null>(null);
  divisionsPaginatedData: ISignalPaginatedResponse<IDropdownOption> | null = null;
  propertiesPaginatedData: ISignalPaginatedPropertyLookupResponse<ISmallListTableInput> | null = null;
  propertiesAccessControl = new FormControl<IPropertyIdAccess[]>([], {
    nonNullable: true,
  });
  accessedPropertiesNo = 0;
  isUpdateLoading = false;
  protected readonly RoleNameMapper = RoleNameMapper;
  readonly #divisionService = inject(DivisionService);
  readonly #propertiesService = inject(PropertiesService);
  readonly #usersService = inject(UsersService);
  readonly #snackbarService = inject(SnackbarService);

  get isDataLoaded(): boolean {
    return !!this.divisionsPaginatedData && !!this.propertiesPaginatedData;
  }

  ngOnInit() {
    this.#loadDivisionsThenProperties();
  }

  updateAccessedPropertiesNo(isAccessed: boolean): void {
    isAccessed ? this.accessedPropertiesNo++ : this.accessedPropertiesNo--;
  }

  updatePropertiesAccess(): void {
    this.isUpdateLoading = true;
    this.#usersService
      .editUserPropertyAccess({
        userId: this.componentInputs.userId,
        properties: this.propertiesAccessControl.value,
      })
      .subscribe(() => {
        this.isUpdateLoading = false;
        this.matDialog.closeAll();
        this.#snackbarService.open(SnackbarType.Success);
      });
  }

  #loadDivisionsThenProperties(): void {
    this.#divisionService
      .getPaginatedDivisionOptions({
        search: "",
        pageNumber: 1,
        pageSize: 10,
        userId: this.componentInputs.userId,
      })
      .pipe(this.#loadPropertiesFromDivisionsOperator())
      .subscribe((propertiesPaginatedData) => {
        this.propertiesPaginatedData = propertiesPaginatedData;
        this.accessedPropertiesNo = this.propertiesPaginatedData.accessedProperties();
      });
  }

  #loadPropertiesFromDivisionsOperator(): OperatorFunction<
    ISignalPaginatedResponse<IDropdownOption>,
    ISignalPaginatedPropertyLookupResponse<ISmallListTableInput>
  > {
    return concatMap((divisionsPaginatedData) => {
      this.divisionsPaginatedData = divisionsPaginatedData;
      return this.#propertiesService.getPaginatedSmallListTableInputPropertiesLookups({
        search: "",
        sortBy: "name",
        pageNumber: 1,
        pageSize: 10,
        sortDescending: false,
        divisionIds: [this.divisionsPaginatedData!.results()[0].value],
        userId: this.componentInputs.userId,
      });
    });
  }
}
