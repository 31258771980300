import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { UnitStatus } from "@root/data/market/division/enums/unit-status.enum";

export const unitStatusOptions: IDropdownOption[] = [
  {
    label: "UNIT_STATUS.AVAILABLE",
    value: UnitStatus.Available,
  },
  {
    label: "UNIT_STATUS.COMPLETED",
    value: UnitStatus.Completed,
  },
  {
    label: "UNIT_STATUS.IN_PROCESS",
    value: UnitStatus.InProcess,
  },
  {
    label: "UNIT_STATUS.NOT_ACTIVE",
    value: UnitStatus.NotActive,
  },
  {
    label: "ALL",
    value: UnitStatus.All,
  },
];
