import { Component, computed, ElementRef, input, output, signal, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { TranslateModule } from "@ngx-translate/core";
import { StatusBadgeComponent } from "@root/shared/status-badge/status-badge.component";
import { DateTime } from "luxon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatIcon } from "@angular/material/icon";
import { NgOptimizedImage } from "@angular/common";
import { PROPERTY_MEDIA_TYPE_OPTIONS } from "@root/views/main/property/property-marketing/constants/property-media.constants";
import { FileType } from "@root/shared/enums/file-type.enum";
import { getFileExtension } from "@root/shared/utilities/files.utilities";
import { IMediaFormGroup } from "@root/views/main/property/property-marketing/components/media-form-group.interface";
import { MediaQuality } from "@root/shared/enums/media-quality.enum";

@Component({
  selector: "est-media-card",
  standalone: true,
  imports: [
    SelectFieldComponent,
    TranslateModule,
    StatusBadgeComponent,
    MatMenu,
    MatMenuItem,
    MatIcon,
    MatMenuTrigger,
    NgOptimizedImage,
  ],
  templateUrl: "./media-card.component.html",
  styleUrl: "./media-card.component.scss",
})
export class MediaCardComponent {
  protected readonly getFileExtension = getFileExtension;
  protected readonly MediaQuality = MediaQuality;
  @ViewChild("video") video?: ElementRef<HTMLVideoElement>;
  mediaFormGroup = input.required<FormGroup<IMediaFormGroup>>();
  uploadedBy = input.required<string>();
  fileName = input.required<string>();
  uploadDate = input<string>(new Date().toISOString());
  isVideoPaused = signal(true);
  delete = output<void>();
  open = output<void>();
  onDropDownChange = output<{ id: number; control: any }>();
  fileNameNoExtension = computed(() => {
    const fileNameSplits = this.fileName().split(".");
    if (fileNameSplits.length > 1) fileNameSplits.pop();
    return fileNameSplits.join(".");
  });

  mediaComputedUrl = computed(() => {
    if (this.mediaFormGroup().controls.filePath.value) return this.mediaFormGroup().controls.filePath.value;
    return URL.createObjectURL(this.mediaFormGroup().controls.file.value!);
  });

  uploadInfo = computed(() => `${this.uploadedBy()}, ${DateTime.fromISO(this.uploadDate()).toFormat("DD")}`);
  mediaTypeOptions: IDropdownOption[] = PROPERTY_MEDIA_TYPE_OPTIONS;
  protected readonly FileType = FileType;

  togglePlayVideo(): void {
    if (!this.video) return;
    if (this.video.nativeElement.paused) {
      this.video.nativeElement.play();
    } else {
      this.video.nativeElement.pause();
    }
  }

  emitValueChange() {
    this.onDropDownChange.emit({
      id: this.mediaFormGroup().controls.id.value!,
      control: { mediaType: this.mediaFormGroup().controls.mediaType.value },
    });
  }
}
