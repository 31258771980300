import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";
import { MediaQuality } from "@root/shared/enums/media-quality.enum";

@Component({
  selector: "est-status-badge",
  standalone: true,
  imports: [TranslateModule, NgClass],
  templateUrl: "./status-badge.component.html",
  styleUrl: "./status-badge.component.scss",
})
export class StatusBadgeComponent {
  score = input.required<MediaQuality>();
  protected readonly MediaQuality = MediaQuality;
}
