import { NgOptimizedImage } from "@angular/common";
import { Component, input } from "@angular/core";
import { MatDivider } from "@angular/material/divider";
import { TranslateModule } from "@ngx-translate/core";
import { IPropertyUnitStatus } from "@root/data/market/properties/models/property-unit-status.model";

@Component({
  selector: "est-property-overview",
  standalone: true,
  imports: [NgOptimizedImage, MatDivider, TranslateModule],
  templateUrl: "./property-unit-status.component.html",
  styleUrl: "./property-unit-status.component.scss",
})
export class PropertyUnitStatusComponent {
  propertyUnitStatus = input.required<IPropertyUnitStatus>();
}
