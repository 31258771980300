import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";

export const ATTENDED_CUSTOMERS_HEADERS: ITableHeader[] = [
  {
    key: "isFavourite",
    type: TableHeaderType.FavouriteToggle,
    label: "",
    isSortable: false,
  },
  {
    key: "name",
    label: "CUSTOMER",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "phoneNumber",
    label: "PHONE",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "email",
    label: "EMAIL",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "attendanceDate",
    label: "CONFIRMED_ATTENDANCE",
    type: TableHeaderType.Date,
    includeTimeInDate: true,
    isSortable: false,
  },
  {
    key: "responsibleAgentName",
    label: "RESPONSIBLE_AGENT",
    type: TableHeaderType.Text,
    isSortable: false,
  },
];

export const DROPOUTS_HEADERS: ITableHeader[] = [
  ...ATTENDED_CUSTOMERS_HEADERS.slice(0, 3),
  {
    key: "confirmationDate",
    label: "CONFIRM_APPOINTMENT",
    type: TableHeaderType.Date,
    includeTimeInDate: true,
    isSortable: true,
  },
  {
    key: "responsibleAgentName",
    label: "RESPONSIBLE_AGENT",
    type: TableHeaderType.Text,
    isSortable: true,
  },
];
