<div [ngClass]="appliedClass()" class="h-[2.75rem] w-[18.75rem]">
  <div class="flex h-full w-full">
    <mat-form-field
      [attr.aria-labelledby]="ariaLabel() | translate"
      appearance="outline"
      class="w-full"
      hideRequiredMarker
      id="postfix-text-input"
      subscriptSizing="dynamic"
    >
      <mat-label>{{ label() | translate }}</mat-label>
      <input [value]="value()" type="string" matInput disabled />
    </mat-form-field>
    <div
      class="flex justify-center items-center border-y border-e px-4 border-black-300 rounded-r-lg bg-black-100 font-semibold text-sm whitespace-nowrap"
    >
      {{ postfixText() }}
    </div>
  </div>
</div>
