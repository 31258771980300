<form (ngSubmit)="submitFormOnlyIfValid()" [formGroup]="userForm">
  <div class="sticky top-0 bg-white z-10">
    <div class="flex py-6 px-6 justify-between">
      <div class="flex gap-2 items-center sticky top-0">
        <p class="text-xl font-medium mb-1">
          {{ "SETTINGS.USERS.CREATE_NEW_USER" | translate }}
        </p>
        <button
          [matTooltip]="'SETTINGS.USERS.CREATE_NEW_USER' | translate"
          class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 inline-block text-center -translate-y-0.5 cursor-pointer"
          type="button"
        >
          ?
        </button>
      </div>
      <button (click)="closeDialog()" type="button">
        <img
          [alt]="'CLOSE_ICON' | translate"
          height="44"
          ngSrc="assets/images/icons/close-icon.svg"
          priority
          width="44"
        />
      </button>
    </div>
    <mat-progress-bar [value]="getProgressbarValue()"></mat-progress-bar>
  </div>
  <div class="pb-8 max-h-[calc(100dvh-14rem)] overflow-y-auto relative">
    <mat-stepper #stepper (selectionChange)="updateStepData($event)" [linear]="true" animationDuration="0">
      <!-- First step -->
      <mat-step [stepControl]="firstStepControl">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12">
            <div class="p-6">
              <p class="font-medium mb-3">{{ "SETTINGS.USERS.USER_INFORMATION" | translate }}</p>
              <div class="flex flex-wrap md:flex-nowrap gap-4 mb-4">
                <est-input
                  [appliedClass]="'w-[18.75rem]'"
                  [control]="firstNameControl"
                  [name]="'firstName'"
                  label="SETTINGS.USERS.FIRST_NAME"
                />
                <est-input
                  [appliedClass]="'w-[18.75rem]'"
                  [control]="lastNameControl"
                  [name]="'lastName'"
                  label="SETTINGS.USERS.LAST_NAME"
                />
              </div>
              <div class="flex flex-wrap md:flex-nowrap gap-4 mb-4">
                <est-input [appliedClass]="'w-[18.75rem]'" [control]="emailControl" [name]="'email'" label="EMAIL" />

                <est-phone-input [control]="phoneNumberControl" />
              </div>
              <div class="flex flex-wrap md:flex-nowrap gap-4">
                <est-input
                  [appliedClass]="'w-[18.75rem]'"
                  [control]="titleControl"
                  [name]="'title'"
                  label="SETTINGS.USERS.TITLE"
                />

                <est-input
                  [appliedClass]="'w-[18.75rem]'"
                  [control]="workPlaceControl"
                  [name]="'workPlace'"
                  label="SETTINGS.USERS.WORKPLACE"
                />
              </div>
            </div>
            <hr class="border-black-100 absolute w-full" />
            <!--  system language -->
            <div class="flex flex-col p-6 flex-wrap">
              <p class="font-medium mb-4">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE" | translate }}</p>
              <p class="text-sm font-light mb-6">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE_SUBTITLE" | translate }}</p>
              <est-language-dropdown
                [appliedClasses]="'w-[300px] h-[44px]'"
                [control]="systemLanguageControl"
                [countries]="languages"
                selectMultipleOptions="false"
              />
            </div>
            <hr class="border-black-100 absolute w-full" />
            <div class="flex flex-col p-6 items-start">
              <p class="font-medium mb-4">{{ "SETTINGS.USERS.USER_ROLES" | translate }}</p>
              <p class="text-sm font-light mb-6">{{ "SETTINGS.USERS.USER_ROLES_SUBTITLE" | translate }}</p>
              @for (division of divisionsFormArray.controls; track $index) {
                <div class="mb-8">
                  <p class="font-medium my-4">{{ "SETTINGS.USERS.USER_ROLE_NO" | translate }} {{ $index + 1 }}</p>
                  <p class="text-sm font-light mb-6">
                    {{ "SETTINGS.USERS.USER_ROLE_SUBTITLE" | translate }}
                  </p>
                  <div class="flex gap-4 flex-wrap">
                    <div>
                      <p class="font-medium mb-4 text-sm">{{ "DIVISIONS.DIVISION" | translate }}</p>
                      <est-select-division-field
                        [disabledOptions]="getDisabledDivisionIds()"
                        [control]="getControlInFormArray(divisionsFormArray, 'divisionId', $index)"
                      />
                    </div>
                    <div class="mb-6 md:mb-0">
                      <p class="font-medium mb-4 text-sm">{{ "SETTINGS.USERS.USER_ROLE" | translate }}</p>
                      <est-select-field
                        [dropDownLabel]="'SETTINGS.USERS.SELECT_ROLE'"
                        [dropDownOptions]="roleDropdownOptions"
                        [control]="getControlInFormArray(divisionsFormArray, 'roleId', $index)"
                        [selectMultipleOptions]="false"
                        [appliedClass]="'!w-[18.75rem]'"
                      />
                    </div>
                    @if ($index > 0) {
                      <div class="mt-12 w-6 h-6 border border-black-300 rounded flex items-center justify-center">
                        <button (click)="removeUserDivisionRole($index)" type="button">
                          <img
                            property="true"
                            width="12"
                            height="12"
                            ngSrc="assets/images/icons/trash-icon.svg"
                            [alt]="'REMOVE' | translate"
                          />
                        </button>
                      </div>
                    }
                  </div>
                </div>
              }
              <button
                (click)="addUserDivisionRole()"
                class="button--secondary uppercase tracking-[0.5px] flex items-center gap-2 mb-8"
                type="button"
              >
                <img
                  [alt]="'ADD' | translate"
                  [ngSrc]="'/assets/images/icons/Add-icon.svg'"
                  height="12"
                  priority
                  width="12"
                />
                <span class="text-sm">{{ "SETTINGS.USERS.CREATE_EXTRA_USER_ROLE" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </mat-step>
      <!-- Second step -->
      <mat-step [stepControl]="teamIdsControl">
        <div class="p-6">
          <p class="font-medium mb-4">
            {{ "SETTINGS.USERS.ASSIGN_USER_TO_TEAMS" | translate }}
          </p>
          <p class="form-block__description max-w-[47rem] !mb-6">
            {{ "SETTINGS.USERS.ASSIGN_USER_SUBTITLE" | translate }}
          </p>
          <div class="text-end mb-6"></div>
          <est-list-select-teams
            [control]="teamIdsControl"
            [divisionIds]="divisionIds"
            [controlMapper]="teamIdsControlMapper"
            [stepIndex]="stepper.selectedIndex"
          ></est-list-select-teams>
        </div>
      </mat-step>
      <!-- Third step -->
      <mat-step>
        <div class="p-6">
          <p class="font-medium mb-4">
            {{ "SETTINGS.USERS.ASSIGN_PROPERTIES" | translate }}
          </p>
          <p class="form-block__description max-w-[47rem] !mb-6">
            {{ "SETTINGS.USERS.ASSIGN_PROPERTIES_SUBTITLE" | translate }}
          </p>
          <est-list-select-properties
            [accessLevelControl]="propertiesControl"
            [divisionIds]="divisionIds"
            [propertiesAccessLevelHashMap]="propertiesAccessLevelHashMap"
            [propertiesAccessStatusHashMap]="propertiesAccessStatusHashMap"
            [teamIds]="teamIds"
            tableAppliedClasses="mb-4"
          ></est-list-select-properties>
        </div>
      </mat-step>
      <mat-step>
        <est-creation-success-message
          [createdBy]="authService.getFullName()!"
          [name]="firstNameControl.value"
          description="PROPERTIES.FORM.PROPERTY_CREATED_MSG"
        ></est-creation-success-message>
      </mat-step>
    </mat-stepper>
  </div>

  <div
    [ngClass]="stepper.selectedIndex !== 3 ? 'justify-between' : 'justify-end'"
    class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10"
  >
    @if (stepper.selectedIndex === 0) {
      <est-button
        [label]="'CANCEL'"
        [appliedClasses]="'!py-3 !px-6'"
        (click)="closeDialog()"
        buttonType="button"
        buttonStyleType="button--tertiary"
      >
      </est-button>
    } @else if (stepper.selectedIndex !== 3) {
      <est-button
        [label]="'BACK'"
        [appliedClasses]="'!py-3 !px-6'"
        (click)="stepper.previous()"
        buttonStyleType="button--tertiary"
        buttonType="button"
        [disabled]="isPageLoading"
      >
      </est-button>
    }
    <div class="flex text-end">
      @if (stepper.selectedIndex === 3) {
        <est-button
          [label]="'SETTINGS.USERS.CREATE_NEW_USER'"
          [appliedClasses]="'!py-3 !px-6 mr-4'"
          (click)="resetForm()"
          buttonStyleType="button--secondary"
          buttonType="button"
          [imageSrc]="'assets/images/icons/Add-icon.svg'"
          [imageAlt]="'ADD_ICON'"
        >
        </est-button>
        <est-button
          [label]="'SETTINGS.USERS.GO_TO_USERS'"
          [appliedClasses]="'!py-3 !px-6'"
          (click)="navigateToPendingUsersList()"
          buttonStyleType="button--primary"
          buttonType="button"
        >
        </est-button>
      } @else if (stepper.selectedIndex !== 2) {
        <est-button
          [label]="'NEXT'"
          [appliedClasses]="'!py-3 !px-6'"
          buttonStyleType="button--primary"
          buttonType="submit"
          [isLoading]="isPageLoading"
        >
        </est-button>
      } @else {
        <est-button
          [label]="'CREATE'"
          [appliedClasses]="'!py-3 !px-6'"
          buttonStyleType="button--primary"
          buttonType="submit"
          [isLoading]="isPageLoading"
        >
        </est-button>
      }
    </div>
  </div>
</form>
