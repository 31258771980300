<est-dialog-header label="FACILITY_DETAILS" tooltipLabel="FACILITY_DETAILS"></est-dialog-header>
<form (ngSubmit)="submitForm()" class="p-6 pb-20 relative">
  <p class="mb-6 text-sm font-light">{{ "PROPERTY_FACILITIES.FACILITIES.EDIT_FACILITY_DESCRIPTION" | translate }}</p>
  <hr class="absolute start-0 end-0 border-black-100" />
  <div class="min-h-36">
    @if (!isDataLoading && facilityDetails) {
      <div class="mt-12 mb-2 grid grid-cols-2 text-sm font-light">
        <div>
          <div class="flex flex-col gap-6">
            <div>
              <p class="font-medium mb-2">{{ "PROPERTY_FACILITIES.FACILITIES.FACILITY" | translate }}</p>
              <p>{{ facilityDetails.name }}</p>
            </div>
            <div>
              <p class="font-medium mb-2">{{ "CATEGORY" | translate }}</p>
              <p>{{ facilityDetails.categoryName }}</p>
            </div>
            <div>
              <p class="font-medium mb-2">{{ "LOCATION" | translate }}</p>
              <p>{{ PropertyFacilityLocationTranslationMapper.get(facilityDetails.location)! | translate }}</p>
            </div>
          </div>
        </div>
        <div>
          <p class="font-medium mb-2">{{ "ICON" | translate }}</p>
          <div class="w-12 h-12 rounded border border-black-300 flex items-center justify-center">
            @if (facilityDetails.iconUrl) {
              <img [alt]="'ICON' | translate" class="w-7 h-7" [src]="facilityDetails.iconUrl" />
            }
          </div>
        </div>
      </div>
      <hr class="absolute start-0 end-0 border-black-100" />
      <div class="flex flex-col mt-6 gap-4 text-sm font-light">
        <div class="flex items-start gap-2">
          <p class="font-medium">{{ "SETTINGS.NAME" | translate }}</p>
          <est-tooltip tooltip="SETTINGS.NAME"></est-tooltip>
        </div>
        <mat-slide-toggle [formControl]="form.isAccessible">
          {{ "PROPERTY_FACILITIES.FACILITY_AVAILABLE_IN_PROPERTY" | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle
          class="mb-2"
          (change)="togglePaymentControls($event.checked)"
          [formControl]="form.isPaymentRequired"
        >
          {{ "PROPERTY_FACILITIES.FACILITIES.PAYMENT_REQUIRED" | translate }}
        </mat-slide-toggle>
        @if (form.isPaymentRequired.value) {
          <est-payment-frequency-select-field [control]="form.paymentFrequency"></est-payment-frequency-select-field>
          <est-postfix-text-input
            [paymentFrequency]="form.paymentFrequency.value"
            [control]="form.price"
            currencyCode="DKK"
            label="PRICE"
            type="number"
          ></est-postfix-text-input>
        }
        @if (facilityDetails.isDistanceEnabled) {
          <mat-slide-toggle (change)="toggleDistanceControl($event.checked)" [formControl]="form.showDistance"
            >{{ "SHOW_DISTANCE" | translate }}
          </mat-slide-toggle>
        }
        @if (form.showDistance.value && facilityDetails.isDistanceEnabled) {
          <est-postfix-text-input
            [control]="form.distance"
            postfixText="METERS"
            label="DISTANCE"
            type="number"
          ></est-postfix-text-input>
        }
      </div>
    } @else {
      <est-loading-overlay></est-loading-overlay>
    }
  </div>
  <div
    class="absolute bg-black-100 flex items-center justify-between start-0 end-0 bottom-0 border-t border-black-300 px-6 py-2.5"
  >
    <est-button
      (click)="matDialog.closeAll()"
      buttonStyleType="button--tertiary"
      buttonType="button"
      label="CANCEL"
    ></est-button>
    <est-button
      [isLoading]="isSubmittingForm"
      buttonStyleType="button--primary"
      buttonType="submit"
      label="SAVE"
    ></est-button>
  </div>
</form>
