import { Component, DestroyRef, OnInit, inject, input } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule } from "@ngx-translate/core";
import { PropertyTermsAndConditionsFormControls } from "@root/views/main/property/property-details/property-terms-and-conditions/property-terms-and-conditions.form-controls";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { generateDropdownOptionsFromNumber } from "@root/shared/utilities/dropdown-options.utilities";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ButtonComponent } from "@root/shared/button/button.component";
import { AddPropertyPetTypeComponent } from "@root/views/main/property/property-details/property-terms-and-conditions/add-property-pet-type/add-property-pet-type.component";
import { MatDialog } from "@angular/material/dialog";
import { IPetTypeFormInputs } from "@root/shared/abstracts/base-pet-type-form/base-pet-type-form/pet-type-form-inputs.interface";
import { PetTypesTableComponent } from "@root/views/main/property/property-details/property-terms-and-conditions/pet-types-table/pet-types-table.component";
import { PetDialogComponent } from "@root/views/main/property/property-details/property-terms-and-conditions/pet-dialog/pet-dialog.component";
import { PropertiesRepository } from "@root/data/market/properties/repositories/properties.repository";
import { updateNestedControlsPathAndValue } from "@root/shared/utilities/form.utilities";

@Component({
  selector: "est-property-unit-terms-and-conditions",
  standalone: true,
  templateUrl: "./property-terms-and-conditions.component.html",
  styleUrl: "./property-terms-and-conditions.component.scss",
  imports: [
    UnderDevelopmentComponent,
    SubheaderComponent,
    TooltipComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggle,
    SelectFieldComponent,
    ButtonComponent,
    AddPropertyPetTypeComponent,
    PetTypesTableComponent,
    PetDialogComponent,
  ],
  providers: [PropertyTermsAndConditionsFormControls],
})
export class PropertyTermsAndConditionsComponent implements OnInit {
  readonly propertyTermsAndConditionsFormControls = inject(PropertyTermsAndConditionsFormControls);
  numberOfPetsOptions: IDropdownOption[] = generateDropdownOptionsFromNumber(5, false, 1);
  #propertiesRepository = inject(PropertiesRepository);
  propertyId = input.required<number>();
  petPolicyId?: number;
  readonly #destroyRef = inject(DestroyRef);
  matDialog = inject(MatDialog);
  ngOnInit(): void {
    this.getPropertyPetPolicy();
    this.#patchOnFormChange();
  }

  getPropertyPetPolicy() {
    this.#propertiesRepository.getPropertyPetPolicy(this.propertyId()).subscribe((propertyPetPolicy) => {
      this.petPolicyId = propertyPetPolicy.id;
      this.propertyTermsAndConditionsFormControls.petPolicyFormGroup.patchValue(propertyPetPolicy);
    });
  }

  updatePropertyPetPolicy() {
    const controlPaths = updateNestedControlsPathAndValue(
      this.propertyTermsAndConditionsFormControls.petPolicyFormGroup,
      true,
    );

    if (Object.keys(controlPaths).length > 0) {
      this.#propertiesRepository.patchPropertyPetPolicy(this.propertyId(), controlPaths).subscribe();
    }
  }

  addPetTypeOnClick() {
    this.matDialog.open<AddPropertyPetTypeComponent, IPetTypeFormInputs>(AddPropertyPetTypeComponent, {
      data: {
        petPolicyId: this.petPolicyId,
        propertyId: this.propertyId(),
      },
      width: "28rem",
      maxHeight: "95dvh",
    });
  }

  #patchOnFormChange() {
    this.propertyTermsAndConditionsFormControls.petPolicyFormGroup.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.updatePropertyPetPolicy();
      });
  }
}
