<div class="flex flex-col items-center">
  <div class="text-sm mb-6">
    <p class="text-sm mb-2 font-bold">{{ title() | translate }}</p>
    <div class="max-w-80">
      <p class="font-light">
        {{ description() | translate }}
      </p>
    </div>
  </div>
  <div class="flex-col flex items-center">
    @if (!isEditMode) {
      @if (displayedImage && imageFormControl().value) {
        <img
          class="w-56 h-56 mb-6 bg-primary text-white uppercase flex items-center justify-center rounded-full font-medium text-xl object-cover"
          [src]="displayedImage"
          [alt]="'TEAMS.TEAM_IMAGE' | translate"
        />
      } @else {
        <div
          class="w-56 h-56 mb-6 bg-primary text-white uppercase flex items-center justify-center rounded-full font-medium text-7xl"
        >
          {{ imagePlaceHolderText()?.charAt(0) }}
        </div>
      }

      @if (!displayedImage) {
        <button
          (click)="openDialog()"
          class="button--tertiary uppercase !py-0.5 !px-5 !bg-white !text-sm flex justify-between gap-x-2 items-center"
          type="button"
        >
          <img src="assets/images/icons/upload-icon.svg" alt="upload image icon" />
          <span>{{ "UPLOAD" | translate }}</span>
        </button>
      } @else {
        <div class="flex items-center gap-x-4">
          <button
            (click)="toggleEditMode(true)"
            class="button--tertiary uppercase !py-0.5 !px-5 !bg-white !text-sm flex justify-between gap-x-2 items-center"
            type="button"
          >
            <img src="assets/images/icons/edit-icon.svg" alt="Edit image icon" />

            <span>{{ "EDIT" | translate }}</span>
          </button>
          <button
            (click)="removeImage()"
            class="button--tertiary uppercase !py-0.5 !px-5 !bg-white !text-sm flex justify-between gap-x-2 items-center"
            type="button"
          >
            <img src="assets/images/icons/trash-icon.svg" alt="remove image icon" />

            <span> {{ "REMOVE" | translate }}</span>
          </button>
        </div>
      }
    }
  </div>
  @if (isEditMode) {
    <div>
      <image-cropper
        [allowMoveImage]="true"
        [imageBase64]="displayedImage!"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)"
        cropperFrameAriaLabel="upload-image"
        [roundCropper]="true"
        [hideResizeSquares]="true"
        [alignImage]="'center'"
        [(transform)]="imageTransform"
      ></image-cropper>
    </div>
    <div class="flex flex-col items-center">
      <mat-slider min="10" max="100" step="0.1">
        <input (input)="handleZooming($event)" matSliderThumb />
      </mat-slider>

      <button (click)="saveCroppedImage()" class="button--tertiary uppercase !py-0.5 !px-7 !bg-white !text-sm">
        {{ "SAVE" | translate }}
      </button>
    </div>
  }
</div>
