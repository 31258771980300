@if (!isSeriesEvent()) {
  <est-event-instance-details [eventInstance]="eventInstanceComputedSignal()"></est-event-instance-details>

  <est-small-header
    appliedClasses="mt-6"
    heading="EVENTS.DETAILS.SINGLE_EVENT.TITLE"
    subheading="EVENTS.DETAILS.SINGLE_EVENT.DESCRIPTION"
    subheadingAppliedClasses="!max-w-[85%] mb-6"
    tooltip="EVENTS.DETAILS.SINGLE_EVENT.TOOLTIP"
  ></est-small-header>

  <hr class="absolute start-0 end-0 border-black-100" />
}

<div class="flex gap-4 mb-6" [ngClass]="[isSeriesEvent() ? 'mt-6' : 'mt-12']">
  <est-button
    [label]="'EVENTS.DETAILS.CONFIRMED_CUSTOMERS' | translate"
    [buttonStyleType]="showConfirmed ? 'button--quaternary' : 'button--tertiary'"
    buttonType="button"
    (click)="showConfirmedCustomers()"
  ></est-button>
  <est-button
    [label]="'EVENTS.DETAILS.ATTENDED_CUSTOMERS' | translate"
    [buttonStyleType]="!showConfirmed ? 'button--quaternary' : 'button--tertiary'"
    buttonType="button"
    (click)="showAttendedCustomers()"
  ></est-button>
</div>

<hr class="absolute start-0 end-0 border-black-100" />

@defer (when  (isSeriesEvent() && panelOpenState()) || !isSeriesEvent()) {
  <div class="mt-12">
    @if (showConfirmed) {
      @if (isBookingWithTimeSlot()) {
        <est-confirmed-customers-with-time-slots
          [eventInstanceId]="eventInstanceComputedSignal().id"
          [propertyId]="propertyId!"
        ></est-confirmed-customers-with-time-slots>
      } @else {
        <est-confirmed-customers
          [eventInstanceId]="eventInstanceComputedSignal().id"
          [propertyId]="propertyId!"
        ></est-confirmed-customers>
      }
    } @else {
      <est-attended-customers
        [eventInstanceId]="eventInstanceComputedSignal().id"
        [propertyId]="propertyId!"
      ></est-attended-customers>
    }
  </div>
}
