<div class="flex justify-between items-end mb-5">
  <est-small-header
    appliedClasses="!text-xl"
    heading="PACKAGES.MEDIA_PACKAGES.NAME"
    subheading="PACKAGES.MEDIA_PACKAGES.DESCRIPTION"
    tooltip="PACKAGES.MEDIA_PACKAGES.NAME"
  ></est-small-header>
  <est-button
    buttonStyleType="button--primary"
    buttonType="button"
    imageAlt="ADD"
    imagePosition="before"
    imageSrc="assets/images/icons/plus.svg"
    label="PACKAGES.CREATE_PACKAGE"
    routerLink="create"
  ></est-button>
</div>
<div class="relative min-h-60">
  <div class="grid grid-cols-2 lg:grid-cols-3 gap-6">
    @for (media of mediaPackages; track media.id) {
      <est-media-packages-card
        [actions]="actions"
        [mediaPackage]="media"
        [propertyId]="propertyId()"
        [linkingSource]="linkingSource"
      ></est-media-packages-card>
    }
  </div>
  @if (isPageLoading) {
    <est-loading-overlay></est-loading-overlay>
  }
</div>
