import { Directive, HostListener, Input, OnInit, Renderer2, inject } from "@angular/core";

@Directive({
  selector: "[estDropdownMenu]",
  standalone: true,
})
export class DropdownMenuDirective implements OnInit {
  @Input({ required: true, alias: "estDropdownMenu" }) element!: HTMLElement;
  renderer = inject(Renderer2);
  isVisible = false;

  @HostListener("click")
  @HostListener("keyup.enter")
  toggleMenu(): void {
    if (!this.isVisible) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
    this.isVisible = !this.isVisible;
  }

  ngOnInit(): void {
    this.hideMenu();
  }

  showMenu(): void {
    this.renderer.removeClass(this.element, "hidden");
  }

  hideMenu(): void {
    this.renderer.addClass(this.element, "hidden");
  }
}
