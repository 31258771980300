import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-units",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./units.component.html",
  styleUrl: "./units.component.scss",
})
export class UnitsComponent {
  protected tabs: INavigationTab[] = [
    {
      label: "OVERVIEW",
      routerLink: "overview",
    },
    {
      label: "UNIT.CONTRACTS",
      routerLink: "contracts",
    },
    {
      label: "UNIT.UNIT_DETAILS.NAME",
      routerLink: "unit-details",
    },
    {
      routerLink: "texts",
      label: "UNIT.TEXTS",
    },
    {
      routerLink: "media",
      label: "UNIT.MEDIA",
    },
    {
      routerLink: "marketing",
      label: "UNIT.MARKETING",
    },
    {
      routerLink: "setting",
      label: "UNIT.SETTINGS",
    },
  ];
}
