import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { RouterOutlet } from "@angular/router";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";

@Component({
  selector: "est-unit-details",
  standalone: true,
  imports: [NavigationTabsComponent, RouterOutlet],
  templateUrl: "./unit-details.component.html",
  styleUrl: "./unit-details.component.scss",
})
export class UnitDetailsComponent {
  protected tabs: INavigationTab[] = [
    {
      routerLink: "main-details",
      label: "UNIT.UNIT_DETAILS.MAIN_DETAILS",
    },
    {
      routerLink: "economy",
      label: "UNIT.UNIT_DETAILS.ECONOMY",
    },
    {
      routerLink: "terms-and-conditions",
      label: "UNIT.UNIT_DETAILS.TERMS_AND_CONDITIONS",
    },
    {
      routerLink: "unit-layout",
      label: "UNIT.UNIT_DETAILS.UNIT_LAYOUT",
    },
    {
      routerLink: "facilities",
      label: "UNIT.UNIT_DETAILS.FACILITIES",
    },
  ];
}
