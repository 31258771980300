import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent } from "@root/shared/button/button.component";

@Component({
  selector: "est-required-fields-missing",
  standalone: true,
  imports: [DialogHeaderComponent, TranslateModule, ButtonComponent],
  templateUrl: "./required-fields-missing.component.html",
  styleUrl: "./required-fields-missing.component.scss",
})
export class RequiredFieldsMissingComponent {
  componentInputs: { missingFields: string[] } = inject(MAT_DIALOG_DATA);
  readonly matDialog = inject(MatDialog);
}
