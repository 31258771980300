<div class="flex justify-between items-end mb-5">
  <est-small-header
    heading="PROPERTY_TEXTS.NAME"
    tooltip="PROPERTY_TEXTS.NAME"
    appliedClasses="!text-xl"
    subheading="PROPERTY_TEXTS.SUBHEADER"
  ></est-small-header>
  <est-button
    label="PROPERTY_TEXTS.CREATE_NEW_TEXT"
    buttonStyleType="button--primary"
    buttonType="button"
    imageSrc="assets/images/icons/plus.svg"
    imageAlt="ADD"
    routerLink="create"
  ></est-button>
</div>
<div class="relative">
  <div class="flex flex-wrap gap-6">
    @for (text of propertyTexts; track text.id) {
      <est-text-card [actions]="actions" [textPackage]="text" [propertyId]="propertyId()"></est-text-card>
    }
  </div>
  @if (isPageLoading) {
    <est-loading-overlay appliedClasses="my-20"></est-loading-overlay>
  }
</div>
