import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const UNIT_PROPERTY_FACILITIES_HEADERS: ITableHeader[] = [
  {
    key: "isActive",
    label: "ACTIVE",
    type: TableHeaderType.Toggle,
    isSortable: true,
  },
  {
    key: "name",
    label: "PROPERTY_FACILITIES.FACILITIES.FACILITY",
    type: TableHeaderType.TextWithIcon,
    isSortable: true,
  },
  {
    key: "isAccessible",
    label: "ACCESSIBLE",
    type: TableHeaderType.Text,
    needsTranslation: true,
    isSortable: true,
  },
  {
    key: "isPaymentRequired",
    label: "PROPERTY_FACILITIES.FACILITIES.PAYMENT_REQUIRED",
    type: TableHeaderType.Text,
    needsTranslation: true,
    isSortable: true,
  },
  {
    key: "location",
    label: "LOCATION",
    type: TableHeaderType.Text,
    needsTranslation: true,
    isSortable: true,
  },
  {
    key: "category",
    label: "CATEGORY",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "createdAt",
    label: "UNIT_FACILITIES.ADDED_TO_UNIT",
    type: TableHeaderType.Date,
    isSortable: true,
  },
];
