import { Component, OnInit } from "@angular/core";
import { BasePropertyTextsComponent } from "@root/shared/abstracts/base-property-texts/base-property-texts.component";
import { PropertyTextsFormControls } from "@root/shared/abstracts/base-property-texts/property-texts.form-controls";
import { BASE_PROPERTY_TEXTS_IMPORTS } from "@root/shared/abstracts/base-property-texts/base-property-texts.imports";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TextEditorAccordionComponent } from "@root/views/main/property/property-marketing/components/texts/property-texts/text-editor-accordion/text-editor-accordion.component";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { FormControl } from "@angular/forms";
import { ITextsParams } from "@root/shared/interfaces/property-texts-params.interface";

@Component({
  selector: "est-create-property-text-fields",
  standalone: true,
  imports: [
    BASE_PROPERTY_TEXTS_IMPORTS,
    SelectSearchFieldComponent,
    SelectFieldComponent,
    TextEditorAccordionComponent,
  ],
  templateUrl: "../../../../../../../../shared/abstracts/base-property-texts/base-property-texts.component.html",
  styleUrl: "./create-property-text-fields.component.scss",
  providers: [PropertyTextsFormControls],
})
export class CreatePropertyTextFieldsComponent extends BasePropertyTextsComponent implements OnInit {
  submitForm(): void {
    this.createText();
  }

  createText() {
    this.stepper.selected!.stepControl.updateValueAndValidity();
    if (this.createPropertyTextsFormControls.secondStepGroup.invalid) {
      markAllControlsAsTouchedAndDirty(this.stepper.selected!.stepControl as FormControl);
      return;
    }
    let params: ITextsParams;

    if (this.parentRoute === "text-packages") {
      params = { isPackage: true };
    } else {
      params = { isPackage: false };
    }
    this.isSubmittingForm = true;
    this.propertyTextsService
      .createPropertyText(this.propertyId(), this.createPropertyTextsFormControls.formGroup.value, params)
      .subscribe({
        next: () => {
          this.router.navigate(["../"], { relativeTo: this.route });
          this.snackBarService.open(SnackbarType.Success);
        },
        error: () => (this.isSubmittingForm = false),
      });
  }

  override isEdit = false;
}
