<div class="flex gap-6 mb-6 justify-between">
  <div>
    <p class="font-medium mb-2">{{ "PROPERTIES.SELECT_PROPERTY" | translate }}</p>
    <p class="font-light text-sm">{{ selectPropertySubHeaderTranslationKey() | translate }}</p>
  </div>
  <est-search-input [searchSignal]="searchSignal" />
</div>
<est-small-list-table
  [checkboxControlMapper]="checkboxControlMapper()"
  [data]="paginatedData.results()"
  [headers]="headers"
  [isLoading]="isTableLoading"
  [sortingFilterSignal]="sortingFilterSignal"
/>
<est-pagination [paginationValues]="paginatedData" />
@if (control().dirty && control().touched && control().hasError(EMPTY_ARRAY_ERROR_KEY)) {
  <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
    <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
    {{ "VALIDATION.SELECT_AT_LEAST_ONE_PROPERTY" | translate }}
  </mat-error>
}
