<div class="w-[36rem] py-4 px-6 relative">
  <div class="flex items-center justify-between mb-4">
    <p class="text-sm font-medium">{{ header() | translate }}</p>
    <p class="text-xs font-light">
      {{ "LAST_CHANGE" | translate }}: <span>{{ lastChangedDate() | customDate }}</span>
    </p>
  </div>
  <div class="pb-4">
    <hr class="absolute start-0 end-0 border-black-300" />
  </div>
  <div class="grid grid-cols-[repeat(1,1fr)] gap-y-2 font-medium text-xs whitespace-nowrap mb-6">
    <p class="mb-2">{{ "INTERNAL_TITLE" | translate }}</p>
    <p class="font-light text-xs">{{ internalTitle() }}</p>
  </div>
  @if (externalTitles().length > 0) {
    <div class="grid grid-cols-[repeat(2,1fr)] gap-y-2 font-medium text-xs whitespace-nowrap">
      <p class="mb-2">{{ "EXTERNAL_TITLE" | translate }}</p>
      <p class="mb-2">{{ "LANGUAGE" | translate }}</p>
      @for (externalTitle of externalTitles(); track $index) {
        <p class="font-light">{{ externalTitle.title }}</p>
        <div class="flex items-center gap-2">
          <img [alt]="'FLAG' | translate" [src]="externalTitle.language.flagImageUrl" class="w-5" />
          <p class="font-light">
            {{ externalTitle.language.name }}
            @if ($first) {
              <span class="text-black-500">({{ "END_USER_DEFAULT" | translate }})</span>
            }
          </p>
        </div>
      }
    </div>
  }
</div>
