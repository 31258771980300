import { NgIf, NgOptimizedImage } from "@angular/common";
import { Component, DestroyRef, inject, input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { transferBytesUtility } from "../utilities/transfer-bytes.utility";

@Component({
  selector: "est-upload-logo-brand",
  standalone: true,
  imports: [NgIf, NgOptimizedImage, TranslateModule],
  templateUrl: "./upload-logo-brand.component.html",
  styleUrl: "./upload-logo-brand.component.scss",
})
export class UploadLogoBrandComponent implements OnInit {
  fileSizeConvertToBytes: number = 0;
  readonly #destroyRef$ = inject(DestroyRef);
  protected readonly transferBytes = transferBytesUtility;
  image = input.required<FormControl<File | null | string>>();
  displayImage: string = "";
  imageName = input.required<FormControl<string | null>>();
  fileSize = input.required<FormControl<string | null>>();
  imageDimensions = input.required<FormControl<string | null>>();

  selectImage(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    let image: File | null = null;
    if (element.files && element.files.length > 0) {
      image = element.files[0];
      this.imageName().setValue(image.name);
      this.fileSize().setValue(image.size.toString());
      this.fileSizeConvertToBytes = image.size;
      this.image().setValue(image);
    }
    if (image) {
      this.setImageUrlOnLoadedWithImageDimensions(image);
    }
  }

  setImageUrlOnLoadedWithImageDimensions(image: File) {
    const reader = new FileReader();
    reader.onload = (image: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = image.target?.result as string;
      this.displayImage = img.src;
      img.onload = () => {
        this.imageDimensions().setValue(`${img.width} X ${img.height}`);
      };
    };
    reader.readAsDataURL(image);
  }

  ngOnInit() {
    this.setImageUrl();
    this.setImageSize();
  }

  setImageUrl() {
    this.image()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef$))
      .subscribe((data) => {
        if (data) this.displayImage = String(data);
      });
  }

  setImageSize() {
    this.fileSize()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef$))
      .subscribe((data) => {
        this.fileSizeConvertToBytes = Number(data);
      });
  }
}
