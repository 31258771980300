import * as i0 from '@angular/core';
import { InjectionToken, Injectable, EventEmitter, Directive, Optional, Inject, Input, Output, NgModule } from '@angular/core';
import Sortable from 'sortablejs';

/** @internal */
const GLOBALS = new InjectionToken('Global config for sortablejs');

/** @internal */
function isFormArray(target) {
  // we need this to identify that the target is a FormArray
  // we don't want to have a dependency on @angular/forms just for that
  // just checking for random FormArray methods not available on a standard array
  return !!target.at && !!target.insert && !!target.reset;
}
/** @internal */
class SortablejsBinding {
  constructor(target) {
    this.target = target;
  }
  insert(index, item) {
    if (isFormArray(this.target)) {
      this.target.insert(index, item);
    } else {
      this.target.splice(index, 0, item);
    }
  }
  get(index) {
    return isFormArray(this.target) ? this.target.at(index) : this.target[index];
  }
  remove(index) {
    let item;
    if (isFormArray(this.target)) {
      item = this.target.at(index);
      this.target.removeAt(index);
    } else {
      item = this.target.splice(index, 1)[0];
    }
    return item;
  }
}

/** @internal */
class SortablejsBindings {
  constructor(bindingTargets) {
    this.bindings = bindingTargets.map(target => new SortablejsBinding(target));
  }
  injectIntoEvery(index, items) {
    this.bindings.forEach((b, i) => b.insert(index, items[i]));
  }
  getFromEvery(index) {
    return this.bindings.map(b => b.get(index));
  }
  extractFromEvery(index) {
    return this.bindings.map(b => b.remove(index));
  }
  get provided() {
    return !!this.bindings.length;
  }
}

/** @internal */
class SortablejsService {
  static {
    this.ɵfac = function SortablejsService_Factory(t) {
      return new (t || SortablejsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SortablejsService,
      factory: SortablejsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SortablejsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/** @internal */
const getIndexesFromEvent = event => {
  if (event.hasOwnProperty('newDraggableIndex') && event.hasOwnProperty('oldDraggableIndex')) {
    return {
      new: event.newDraggableIndex,
      old: event.oldDraggableIndex
    };
  } else {
    return {
      new: event.newIndex,
      old: event.oldIndex
    };
  }
};
class SortablejsDirective {
  constructor(globalConfig, service, element, zone, renderer) {
    this.globalConfig = globalConfig;
    this.service = service;
    this.element = element;
    this.zone = zone;
    this.renderer = renderer;
    /** Initialised a new Sortablejs instance */
    this.init = new EventEmitter();
  }
  /** @internal */
  ngOnInit() {
    if (Sortable?.create) {
      // Sortable does not exist in angular universal (SSR)
      this.create();
    }
  }
  /** @internal */
  ngOnChanges(changes) {
    const optionsChange = changes.config;
    if (optionsChange && !optionsChange.isFirstChange()) {
      const previousOptions = optionsChange.previousValue;
      const currentOptions = optionsChange.currentValue;
      Object.keys(currentOptions).forEach(optionName => {
        if (currentOptions[optionName] !== previousOptions[optionName]) {
          // use low-level option setter
          this.sortableInstance?.option(optionName, this.options[optionName]);
        }
      });
    }
  }
  /** @internal */
  ngOnDestroy() {
    if (this.sortableInstance) {
      this.sortableInstance.destroy();
    }
  }
  create() {
    const container = this.sortablejsContainer ? this.element.nativeElement.querySelector(this.sortablejsContainer) : this.element.nativeElement;
    setTimeout(() => {
      this.sortableInstance = Sortable.create(container, this.options);
      this.init.emit(this.sortableInstance);
    }, 0);
  }
  getBindings() {
    if (!this.data) {
      return new SortablejsBindings([]);
    } else if (this.data instanceof SortablejsBindings) {
      return this.data;
    } else {
      return new SortablejsBindings([this.data]);
    }
  }
  get options() {
    return {
      ...this.optionsWithoutEvents,
      ...this.overridenOptions
    };
  }
  get optionsWithoutEvents() {
    return {
      ...(this.globalConfig || {}),
      ...(this.config || {})
    };
  }
  proxyEvent(eventName, ...params) {
    this.zone.run(() => {
      if (this.optionsWithoutEvents?.[eventName]) {
        this.optionsWithoutEvents[eventName](...params);
      }
    });
  }
  get isCloning() {
    const groupOptions = this.sortableInstance?.options.group;
    return groupOptions && typeof groupOptions != 'string' ? groupOptions.checkPull?.(this.sortableInstance, this.sortableInstance, null, null) === 'clone' : groupOptions === 'clone';
  }
  clone(item) {
    // by default pass the item through, no cloning performed
    return (this.cloneFunction || (subitem => subitem))(item);
  }
  get overridenOptions() {
    // always intercept standard events but act only in case items are set (bindingEnabled)
    // allows to forget about tracking this.items changes
    return {
      onAdd: event => {
        this.service.transfer = items => {
          this.getBindings().injectIntoEvery(event.newIndex, items);
          this.proxyEvent('onAdd', event);
        };
        this.proxyEvent('onAddOriginal', event);
      },
      onRemove: event => {
        const bindings = this.getBindings();
        if (bindings.provided) {
          if (this.isCloning) {
            this.service.transfer?.(bindings.getFromEvery(event.oldIndex).map(item => this.clone(item)));
            // great thanks to https://github.com/tauu
            // event.item is the original item from the source list which is moved to the target list
            // event.clone is a clone of the original item and will be added to source list
            // If bindings are provided, adding the item dom element to the target list causes artifacts
            // as it interferes with the rendering performed by the angular template.
            // Therefore we remove it immediately and also move the original item back to the source list.
            // (event handler may be attached to the original item and not its clone, therefore keeping
            // the original dom node, circumvents side effects )
            this.renderer.removeChild(event.item.parentNode, event.item);
            this.renderer.insertBefore(event.clone.parentNode, event.item, event.clone);
            this.renderer.removeChild(event.clone.parentNode, event.clone);
          } else {
            this.service.transfer?.(bindings.extractFromEvery(event.oldIndex));
          }
          this.service.transfer = undefined;
        }
        this.proxyEvent('onRemove', event);
      },
      onUpdate: event => {
        const bindings = this.getBindings();
        const indexes = getIndexesFromEvent(event);
        bindings.injectIntoEvery(indexes.new, bindings.extractFromEvery(indexes.old));
        this.proxyEvent('onUpdate', event);
      }
    };
  }
  static {
    this.ɵfac = function SortablejsDirective_Factory(t) {
      return new (t || SortablejsDirective)(i0.ɵɵdirectiveInject(GLOBALS, 8), i0.ɵɵdirectiveInject(SortablejsService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SortablejsDirective,
      selectors: [["", "nxtSortablejs", ""]],
      inputs: {
        data: [i0.ɵɵInputFlags.None, "nxtSortablejs", "data"],
        sortablejsContainer: "sortablejsContainer",
        config: "config",
        cloneFunction: "cloneFunction"
      },
      outputs: {
        init: "init"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SortablejsDirective, [{
    type: Directive,
    args: [{
      selector: '[nxtSortablejs]'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [GLOBALS]
    }]
  }, {
    type: SortablejsService
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }, {
    type: i0.Renderer2
  }], {
    data: [{
      type: Input,
      args: ['nxtSortablejs']
    }],
    sortablejsContainer: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    cloneFunction: [{
      type: Input
    }],
    init: [{
      type: Output
    }]
  });
})();
class SortablejsModule {
  static forRoot(globalOptions) {
    return {
      ngModule: SortablejsModule,
      providers: [{
        provide: GLOBALS,
        useValue: globalOptions
      }]
    };
  }
  static {
    this.ɵfac = function SortablejsModule_Factory(t) {
      return new (t || SortablejsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SortablejsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SortablejsModule, [{
    type: NgModule,
    args: [{
      declarations: [SortablejsDirective],
      exports: [SortablejsDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SortablejsDirective, SortablejsModule };
