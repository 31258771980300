import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, Router } from "@angular/router";
import { isUserAuthorized } from "../../shared/utilities/auth.utilities";
import { AuthService } from "../../data/market/auth/services/auth.service";
import { UserRole } from "../../data/market/users/enums/user-role.enum";

export const RoleGuard: CanActivateChildFn = (childRoute: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const authorizedRole: UserRole = childRoute.data["authorizedRole"];
  if (!isUserAuthorized(authService.getCurrentUserRole(), authorizedRole)) {
    return router.createUrlTree(["/"]);
  } else {
    return true;
  }
};
