<div>
  <div class="flex items-baseline gap-2">
    <p class="font-medium text-xl">{{ eventTitle() | translate }}</p>
    <span
      class="text-xs text-white bg-primary font-normal rounded-full w-4 h-4 -translate-y-0.5 inline-block text-center cursor-pointer"
      >{{ totalEvents() }}</span
    >
  </div>
  <p class="font-light text-base mt-4">{{ "EVENTS.ALL_EVENTS_DESCRIPTION" | translate }}</p>
</div>
