import { inject, Injectable } from "@angular/core";
import { UnitLayoutRepository } from "@root/data/market/units/repositories/unit-layout.repository";
import { Observable } from "rxjs";
import { IUnitLayout } from "@root/data/market/units/models/unit-layout.model";
import { IRoomName } from "@root/data/market/rooms/models/room-name.model";
import { ITerraceType } from "@root/data/market/rooms/models/terrace-type.model";
import { IBalconyType } from "@root/data/market/rooms/models/balcony-type.model";

@Injectable({
  providedIn: "root",
})
export class UnitLayoutService {
  readonly #unitLayoutRepository = inject(UnitLayoutRepository);

  getRoomNames(): Observable<IRoomName[]> {
    return this.#unitLayoutRepository.getRoomNames();
  }

  getBalconyTypes(): Observable<IBalconyType[]> {
    return this.#unitLayoutRepository.getBalconyTypes();
  }

  getTerraceTypes(): Observable<ITerraceType[]> {
    return this.#unitLayoutRepository.getTerraceTypes();
  }

  getUnitLayout(id: string): Observable<IUnitLayout> {
    return this.#unitLayoutRepository.getUnitLayout(id);
  }

  patchUnitLayout(id: string, body: Partial<IUnitLayout>): Observable<void> {
    return this.#unitLayoutRepository.patchUnitLayout(id, body);
  }
}
