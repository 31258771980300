import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { catchError } from "rxjs/internal/operators/catchError";
import { throwError } from "rxjs/internal/observable/throwError";

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const snackbarService = inject(SnackbarService);
  let messageKey: string | undefined = undefined;
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        messageKey = "SNACKBAR.ERROR.UNAUTHORIZED_ERROR";
      } else {
        messageKey = undefined;
      }
      snackbarService.open(SnackbarType.Error, messageKey || undefined);
      return throwError(() => error);
    }),
  );
};
