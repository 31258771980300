import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CustomDatePipe } from "@root/core/pipes/custom-date.pipe";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { IExternalTitle } from "../interfaces/external-title.model";

@Component({
  selector: "est-external-titles-popup",
  standalone: true,
  imports: [DialogHeaderComponent, CustomDatePipe, TranslateModule],
  templateUrl: "./external-titles-popup.component.html",
  styleUrl: "./external-titles-popup.component.scss",
})
export class ExternalTitlesPopupComponent {
  header = input<string>("INTERNAL_TITLE_DETAILS");
  internalTitle = input.required<string>();
  externalTitles = input.required<IExternalTitle[]>();
  lastChangedDate = input.required<string>();
}
