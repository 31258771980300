import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "../../../../shared/under-development/under-development.component";

@Component({
  selector: "est-division-settings",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./division-settings.component.html",
  styleUrl: "./division-settings.component.scss",
})
export class DivisionSettingsComponent {}
