import { Component, inject, input, OnInit, signal, WritableSignal } from "@angular/core";
import { CompaniesService } from "@root/data/market/companies/services/companies.service";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { constructInitialAddressLookups, formatAddress } from "@root/shared/utilities/address.utilities";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { ReactiveFormsModule } from "@angular/forms";
import { CompanyDetailsFormControls } from "@root/views/main/company/company-details/company-details.form-controls";
import { AddressComponent } from "@root/shared/address/address.component";
import { InputComponent } from "@root/shared/input/input.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { PhoneInputComponent } from "@root/shared/phone-input/phone-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TranslateModule } from "@ngx-translate/core";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import {
  arePhoneControlsDirty,
  markAllControlsAsTouchedAndDirty,
  markControlAsTouchedAndDirty,
  updateNestedControlsPathAndValue,
} from "@root/shared/utilities/form.utilities";
import { DatepickerComponent } from "@root/shared/datepicker/datepicker.component";
import { DateTime } from "luxon";

@Component({
  selector: "est-company-details",
  standalone: true,
  imports: [
    AddressComponent,
    InputComponent,
    LoadingOverlayComponent,
    MatSlideToggle,
    PhoneInputComponent,
    ReactiveFormsModule,
    SelectFieldComponent,
    TranslateModule,
    SubheaderComponent,
    DatepickerComponent,
  ],
  templateUrl: "./company-details.component.html",
  styleUrl: "./company-details.component.scss",
})
export class CompanyDetailsComponent implements OnInit {
  companyId = input.required<number>();
  readonly #lookupsService = inject(LookupsService);
  readonly #companyService = inject(CompaniesService);
  protected companyDetailsFormControls = inject(CompanyDetailsFormControls);
  protected addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  protected isAddressLoadingSignal = signal(false);
  protected formattedAddressSignal: WritableSignal<string> = signal("");
  protected isPageLoading: boolean = false;

  ngOnInit(): void {
    this.#getAddressLookupsOptions();
    this.#getCompanyDetails();
  }

  #getCompanyDetails() {
    this.isPageLoading = true;
    this.#companyService.getCompanyDetails(this.companyId()).subscribe({
      next: (response) => {
        this.companyDetailsFormControls.patchValue(response);
        this.companyDetailsFormControls.phone.patchValue({
          internationalNumber: response.companyDetails.contactPersonPhoneNo,
          number: response.companyDetails.contactPersonPhoneNo,
          countryCode: response.companyDetails.contactPersonIsdCode,
        });
        this.companyDetailsFormControls.createdAt.disable();
        this.companyDetailsFormControls.companyOwner.disable();
        const luxonDateTime = DateTime.fromFormat(response.createdAt.toString(), "MM/dd/yyyy HH:mm:ss");
        this.companyDetailsFormControls.createdAt.setValue(luxonDateTime.toFormat("dd LLL, yyyy"));
        this.isPageLoading = false;
      },
    });
  }

  patchCompanyDetailChanges(changedField?: { name: string; value: any }) {
    if (
      this.companyDetailsFormControls.phone.touched &&
      arePhoneControlsDirty(this.companyDetailsFormControls.phone, this.companyDetailsFormControls.formGroup) &&
      this.companyDetailsFormControls.phone.valid
    ) {
      this.#mapPhoneControlToOrganisationDetails();
      this.#markPhoneControlsInParentAsTouchedAndDirty();
    }
    if (changedField?.name === "vat") {
      markAllControlsAsTouchedAndDirty(this.companyDetailsFormControls.addressInformation);
    }
    this.#sendPatchRequest();
  }

  #getAddressLookupsOptions(): void {
    this.#lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }

  #markPhoneControlsInParentAsTouchedAndDirty() {
    markControlAsTouchedAndDirty(this.companyDetailsFormControls.companyDetails.controls["contactPersonPhoneNo"]);
    markControlAsTouchedAndDirty(this.companyDetailsFormControls.companyDetails.controls["contactPersonIsdCode"]);
  }

  #mapPhoneControlToOrganisationDetails() {
    this.companyDetailsFormControls.companyDetails.patchValue({
      contactPersonPhoneNo: this.companyDetailsFormControls.phone.value?.number,
      contactPersonIsdCode: this.companyDetailsFormControls.phone.value?.countryCode,
    });
  }

  #sendPatchRequest() {
    const controlPaths = updateNestedControlsPathAndValue(this.companyDetailsFormControls.formGroup);
    if (Object.keys(controlPaths).length) {
      this.#companyService.patchCompanyDetails(this.companyId(), controlPaths).subscribe({
        next: () => {
          this.formattedAddressSignal.set(formatAddress(this.companyDetailsFormControls.addressInformation));
        },
      });
    }
  }
}
