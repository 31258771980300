import { Component } from "@angular/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { RouterOutlet } from "@angular/router";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";

@Component({
  selector: "est-surveys",
  standalone: true,
  imports: [NavigationTabsComponent, SubheaderComponent, RouterOutlet, SmallHeaderComponent],
  templateUrl: "./surveys.component.html",
  styleUrl: "./surveys.component.scss",
})
export class SurveysComponent {
  protected surveyTabs: INavigationTab[] = [
    {
      label: "SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.NAME",
      routerLink: "marketing-questions",
    },
    {
      label: "SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.NAME",
      routerLink: "demographic-questions",
    },
  ];
}
