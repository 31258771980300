<div class="sticky top-0 bg-white z-10">
  <div class="flex py-6 px-6 justify-between border-b border-black-200">
    <div class="flex gap-2 items-center sticky top-0">
      <p class="text-xl font-medium">
        {{ getPopupTitle | translate }}
      </p>
      <est-tooltip [tooltip]="getPopupTitle"></est-tooltip>
    </div>
  </div>
  <div class="border-b border-black-200 p-6">
    <div class="flex flex-col gap-2">
      <p class="text-sm font-medium">{{ getDetailName | translate }}</p>
      <p class="text-sm font-light">{{ getDetailDescription | translate }}</p>
    </div>
  </div>
  @if (isFormSubmitted) {
    <div class="absolute inset-0 !bg-white/60 flex items-center justify-center z-20"></div>
  }
  <div class="max-h-[calc(100dvh-18rem)] min-h-[14rem] overflow-y-auto relative p-6">
    @if (!isLoading) {
      <div class="flex flex-col gap-2">
        <p class="text-sm font-medium">
          {{ getPopupTitle | translate }} #{{ componentInputs.roomIndex }}
          @if (componentInputs.roomSubTitle) {
            <span class="text-gray-500"> ({{ componentInputs.roomSubTitle! }})</span>
          }
        </p>
        <mat-slide-toggle
          [formControl]="castControlFromAbstractToFormControl(formGroup.controls['showRoomOrientation'])"
          (change)="updateOrientationControlState($event.checked)"
          >{{ "ORIENTATIONS.VISIBLE" | translate }}
        </mat-slide-toggle>
        <est-select-field
          dropDownLabel="ORIENTATIONS.NAME"
          [selectMultipleOptions]="false"
          [dropDownOptions]="OrientationOptions"
          [control]="castControlFromAbstractToFormControl(formGroup.controls['roomOrientation'])"
        ></est-select-field>
        @for (popupControl of PopupControls; track popupControl) {
          @if (popupControl.title !== undefined) {
            <div class="flex gap-2 items-center top-0 mt-4">
              <p class="text-sm font-medium">
                {{ popupControl.title | translate }}
              </p>
              <est-tooltip [tooltip]="popupControl.title"></est-tooltip>
            </div>
          }
          <est-decision-button-list
            appliedClass="text-sm font-light"
            [label]="popupControl.label"
            [control]="castControlFromAbstractToFormControl(formGroup.controls[popupControl.value])"
          ></est-decision-button-list>
        }
      </div>
    } @else {
      <est-loading-overlay appliedClasses="!bg-white/60"></est-loading-overlay>
    }
  </div>
  <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
    <est-button
      (click)="closeDialog()"
      [appliedClasses]="'!py-3 !px-6'"
      [label]="'CANCEL'"
      buttonStyleType="button--tertiary"
      buttonType="button"
    />
    <est-button
      (click)="saveAndCloseDialog()"
      [appliedClasses]="'!py-3 !px-6'"
      [label]="'SAVE'"
      buttonStyleType="button--primary"
      buttonType="button"
      [isLoading]="isFormSubmitted"
      [disabled]="isFormSubmitted"
    />
  </div>
</div>
