import { Component } from "@angular/core";
import { UnderDevelopmentComponent } from "../../../../shared/under-development/under-development.component";

@Component({
  selector: "est-division-system-modules",
  standalone: true,
  imports: [UnderDevelopmentComponent],
  templateUrl: "./division-system-modules.component.html",
  styleUrl: "./division-system-modules.component.scss",
})
export class DivisionSystemModulesComponent {}
