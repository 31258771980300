<div class="relative">
  <est-dialog-header label="EDIT_MEDIA_DETAILS" tooltipLabel="EDIT_MEDIA_DETAILS"></est-dialog-header>

  <div class="p-6 pb-24 relative">
    <div class="flex gap-6">
      <div>
        @if (componentInputs.fileType === FileType.Image) {
          <img
            [src]="mediaUrl()"
            class="object-cover max-w-full rounded-lg h-[68.5dvh]"
            [alt]="'PROPERTY_MEDIAS.MEDIA_IMAGE' | translate"
          />
        } @else {
          <video controls [src]="mediaUrl()" class="object-cover max-w-full h-[68.5dvh] rounded-lg"></video>
        }
      </div>
      <div class="flex-1">
        <div class="text-xs font-light mb-6">
          <p>
            <span class="font-medium">{{ "UPLOADED" | translate }}:</span>
            {{ componentInputs.uploadedAt | customDate: true }}
          </p>
          <p>
            <span class="font-medium">{{ "DOCUMENTS.UPLOADED_BY" | translate }}:</span> {{ componentInputs.uploadedBy }}
          </p>
          <p>
            <span class="font-medium">{{ "FILE_NAME" | translate }}:</span> {{ componentInputs.fileName }}
          </p>
          <p>
            <span class="font-medium">{{ "FILE_TYPE" | translate }}:</span>
            {{ (componentInputs.fileType === FileType.Video ? "VIDEO" : "IMAGE") | translate }} ({{
              getFileExtension(componentInputs.fileName)
            }})
          </p>
          <p>
            <span class="font-medium">{{ "FILE_SIZE" | translate }}:</span> {{ componentInputs.fileSizeAndUnit }}
          </p>
          <p>
            <span class="font-medium">{{ "DIMENSION" | translate }}:</span>
            {{
              "DIMENSIONS_IN_PIXELS"
                | translate
                  : {
                      width: componentInputs.mediaFormGroup.controls.width.value,
                      height: componentInputs.mediaFormGroup.controls.height.value
                    }
            }}
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <est-select-field
            [control]="formGroup.controls.mediaType"
            [dropDownOptions]="mediaTypeOptions"
            [selectMultipleOptions]="false"
            dropDownLabel="PROPERTY_MEDIAS.SELECT_MEDIA_TYPE"
          ></est-select-field>
          @if (formGroup.controls.fileType.value === FileType.Image) {
            <est-text-area label="ALTERNATIVE_TEXT" [control]="formGroup.controls.alternateText"></est-text-area>
            <div class="text-xs">
              <a href="https://www.estatetool.dk" target="_blank" class="text-primary font-medium">{{
                "CREATE_PROPERTY_MEDIA_PACKAGE.LEARN_PURPOSE_OF_MEDIA" | translate
              }}</a>
              <p class="font-light">{{ "CREATE_PROPERTY_MEDIA_PACKAGE.LEAVE_BLANK" | translate }}</p>
            </div>
          }
          <est-input appliedClass="w-full" label="TITLE" [control]="formGroup.controls.title"></est-input>
          <est-text-area label="MEDIA_TEXT" [control]="formGroup.controls.mediaText"></est-text-area>
          <est-text-area label="DESCRIPTION" [control]="formGroup.controls.description"></est-text-area>
          <div class="flex items-center gap-4">
            <p class="text-xs font-medium">{{ "IMAGE_QUALITY" | translate }}:</p>
            <est-status-badge [score]="MediaQuality.Good"></est-status-badge>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky justify-between bottom-0 inset-x-0">
    <est-button (click)="dialogRef.close()" buttonStyleType="button--tertiary" buttonType="button" label="CANCEL" />
    <est-button (click)="saveChanges()" buttonStyleType="button--primary" buttonType="button" label="SAVE" />
  </div>
</div>
