import { Routes } from "@angular/router";
import { AllEventsComponent } from "@root/views/main/property/property-events/all-events/all-events.component";
import { UpcomingEventsComponent } from "@root/views/main/property/property-events/upcoming-events/upcoming-events.component";
import { PreviousEventsComponent } from "@root/views/main/property/property-events/previous-events/previous-events.component";
import { ArchivedEventsComponent } from "@root/views/main/property/property-events/archived-events/archived-events.component";
import { DraftEventsComponent } from "@root/views/main/property/property-events/draft-events/draft-events.component";

export const PropertyEventsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "all",
  },
  {
    path: "all",
    component: AllEventsComponent,
    data: {
      name: "ALL",
    },
  },
  {
    path: "upcoming-events",
    component: UpcomingEventsComponent,
    data: {
      name: "EVENTS.UPCOMING_EVENTS",
    },
  },
  {
    path: "upcoming-events",
    component: UpcomingEventsComponent,
    data: {
      name: "EVENTS.UPCOMING_EVENTS",
    },
  },
  {
    path: "Previous-events",
    component: PreviousEventsComponent,
    data: {
      name: "EVENTS.PREVIOUS_EVENTS",
    },
  },
  {
    path: "archived-events",
    component: ArchivedEventsComponent,
    data: {
      name: "EVENTS.ARCHIVED_EVENTS",
    },
  },
  {
    path: "draft-events",
    component: DraftEventsComponent,
    data: {
      name: "EVENTS.DRAFT_EVENTS",
    },
  },
];
