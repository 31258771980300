import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { IPropertyFacilityPackageDetails } from "@root/data/market/properties/models/property-facility-package-details.model";
import { APIS } from "@root/core/api/market.apis";
import { IFacilityCard } from "@root/shared/interfaces/facility-card.interface";
import { IDuplicatePackage } from "@root/shared/interfaces/duplicate-package.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPackageProperty } from "@root/data/market/properties/models/package-property.model";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";

@Injectable({
  providedIn: "root",
})
export class PropertiesFacilitiesPackagesRepository {
  readonly #http = inject(HttpClient);

  getFacilityPackageDetails(id: number): Observable<IPropertyFacilityPackageDetails> {
    return this.#http.get<IPropertyFacilityPackageDetails>(APIS.packages.getFacilityPackageDetails(id));
  }

  editFacilityPackageDetails(
    id: number,
    facilityPackageDetails: { [key: string]: any },
    selectAllFacilities: SelectionOperation,
  ): Observable<void> {
    return this.#http.patch<void>(APIS.packages.editFacilityPackage(id), facilityPackageDetails, {
      params: new HttpParams({
        fromObject: {
          selectAllFacilities: selectAllFacilities,
        },
      }),
    });
  }

  createFacilityPackage(
    packageDetails: IPropertyFacilityPackageDetails,
    selectAllFacilities: SelectionOperation,
  ): Observable<void> {
    return this.#http.post<void>(APIS.packages.createFacilityPackage, packageDetails, {
      params: new HttpParams({
        fromObject: {
          selectAllFacilities: selectAllFacilities,
        },
      }),
    });
  }

  getFacilitiesPackageList(propertyId: number): Observable<IFacilityCard[]> {
    return this.#http.get<IFacilityCard[]>(APIS.packages.getFacilitiesPackageList, {
      params: new HttpParams({
        fromObject: { propertyId },
      }),
    });
  }

  deletePackage(id: number): Observable<void> {
    return this.#http.delete<void>(APIS.packages.deletePackage(id));
  }

  duplicatePackagePackage(id: number, packageDetails: IDuplicatePackage): Observable<void> {
    return this.#http.post<void>(APIS.packages.duplicatePackage(id), packageDetails);
  }

  getPropertiesForLinking(params: IPaginationSortPayload): Observable<IPaginatedResponse<IPackageProperty>> {
    return this.#http.get<IPaginatedResponse<IPackageProperty>>(APIS.packages.getPropertiesForLinking, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }
}
