import { inject, Injectable } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { UnitUsageType } from "@root/data/market/import/units/enums/unit-usage-type.enum";
import { IUnitTermsAndConditions } from "@root/data/market/units/models/unit-terms-and-conditions.model";

@Injectable()
export class UnitTermsAndConditionsFormControls {
  readonly #fb = inject(NonNullableFormBuilder);

  usageTypeVisibleControl = this.#fb.control(false);
  usageTypeControl = this.#fb.control<UnitUsageType | null>(null, Validators.required);
  depositMonthsControl = this.#fb.control<number>(1);
  prepaidMonthsControl = this.#fb.control<number>(1);
  nonCancellationPeriodControl = this.#fb.control<number | null>(null);
  noticePeriodControl = this.#fb.control<number | null>(null);
  interiorMaintenanceControl = this.#fb.control("");
  exteriorMaintenanceControl = this.#fb.control("");

  petPolicyVisibleControl = this.#fb.control(false);
  petPolicyAllowedControl = this.#fb.control(false);
  numberOfPetsControl = this.#fb.control<number | null>(null);
  petTypesControl = this.#fb.control<[]>([]);

  termsAndConditionsFormGroup = this.#fb.group({
    rentalAgreementsTerms: this.#fb.group({
      usageTypeVisible: this.usageTypeVisibleControl,
      usageType: this.usageTypeControl,
      depositMonths: this.depositMonthsControl,
      prepaidMonths: this.prepaidMonthsControl,
      nonCancellationPeriodMonths: this.nonCancellationPeriodControl,
      noticePeriodDays: this.noticePeriodControl,
      interiorMaintenance: this.interiorMaintenanceControl,
      exteriorMaintenance: this.exteriorMaintenanceControl,
    }),
    petPolicy: this.#fb.group({
      petPolicyVisible: this.petPolicyVisibleControl,
      isPetAllowed: this.petPolicyAllowedControl,
      numberOfPets: this.numberOfPetsControl,
      petTypeIds: this.petTypesControl,
    }),
  });

  followPropertyPetRestrictionsControl = this.#fb.control(false);

  patchValue(value: Partial<IUnitTermsAndConditions>) {
    this.termsAndConditionsFormGroup.patchValue(value);
  }
}
