import { Component, inject, input, OnInit, ViewChild } from "@angular/core";
import { StepperNavigationComponent } from "@root/shared/stepper-navigation/stepper-navigation.component";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { BasePropertyFacilityPackage } from "@root/shared/abstracts/base-property-package-facility/base-property-package-facility.abstract";
import { ButtonComponent } from "@root/shared/button/button.component";
import { Router, RouterLink } from "@angular/router";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "@root/shared/input/input.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { IPropertyFacilityPackageDetails } from "@root/data/market/properties/models/property-facility-package-details.model";
import { SelectionOperation } from "@root/shared/enums/selection-operation.enum";

@Component({
  selector: "est-create-new-property-facility-package",
  standalone: true,
  imports: [
    StepperNavigationComponent,
    MatStepper,
    MatStep,
    ButtonComponent,
    RouterLink,
    SubheaderComponent,
    SmallHeaderComponent,
    InputComponent,
    MatSlideToggle,
    TranslateModule,
    ReactiveFormsModule,
    NavigationTabsComponent,
    SmallListTableComponent,
    SearchInputComponent,
    SelectFieldComponent,
    PaginationComponent,
  ],
  templateUrl: "./create-new-property-facility-package.component.html",
  styleUrl: "./create-new-property-facility-package.component.scss",
})
export class CreateNewPropertyFacilityPackageComponent extends BasePropertyFacilityPackage implements OnInit {
  @ViewChild("stepper", { static: true }) matStepper!: MatStepper;

  propertyId = input<number>();
  readonly router = inject(Router);

  ngOnInit() {
    this.id.setValue(Number(this.propertyId()) ?? 0);
    this.selectAllItemsOperation.set(SelectionOperation.SELECT_ALL);
    this.getLocationsLookUps();
    this.getCategoriesLookUps();
  }

  createNewPropertyFacilityPackage() {
    this.isLoading = true;
    const payload: IPropertyFacilityPackageDetails = {
      ...this.packageForm.value,
      removedFacilityIds: Array.from(this.removedIds),
    };
    this.propertiesFacilitiesPackagesService
      .createFacilityPackage(payload, this.selectAllItemsOperation())
      .subscribe(() => {
        this.isLoading = false;
        this.router.navigate([`/properties/${this.propertyId()}/marketing/facilities/facility-package`]);
      });
  }

  nextStep() {
    if (this.matStepper.selected?.stepControl.invalid) {
      markAllControlsAsTouchedAndDirty(this.matStepper.selected.stepControl as FormGroup);
      return;
    }
    this.matStepper.next();
  }
}
