import { Component, DestroyRef, effect, EffectRef, inject, signal, WritableSignal } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { FollowUpStatusTableHeader } from "@root/views/main/organization/organization-settings/components/system-configurations/components/follow-up-status/follow-up-status-table-header";
import { FollowUpStatusService } from "@root/data/market/system-configurations/services/follow-up-status.service";
import { MatDialog } from "@angular/material/dialog";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FollowUpStatusPopupComponent } from "@root/views/main/organization/organization-settings/components/system-configurations/components/follow-up-status/components/follow-up-status-popup/follow-up-status-popup.component";
import { IFollowUpStatusPopupInputs } from "@root/views/main/organization/organization-settings/components/system-configurations/components/follow-up-status/components/follow-up-status-popup/follow-up-status-popup-inputs.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { finalize } from "rxjs";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";

@Component({
  selector: "est-follow-up-status",
  standalone: true,
  imports: [ButtonComponent, PaginationComponent, SmallHeaderComponent, SmallListTableComponent],
  templateUrl: "./follow-up-status.component.html",
  styleUrl: "./follow-up-status.component.scss",
})
export class FollowUpStatusComponent extends BasePaginatedTableWithSearchComponent {
  readonly #followUpStatusService = inject(FollowUpStatusService);
  readonly #matDialog = inject(MatDialog);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogService = inject(DialogService);
  #isFollowUpStatusDeleting = signal(false);
  override headers: ITableHeader[] = FollowUpStatusTableHeader;
  override sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "title",
    sortDescending: false,
  });
  override loadDataEffect$: EffectRef = effect(() => this.#loadDocumentsWithParams());
  override actions: ITableAction[] = [
    {
      isVisible: (row: ISmallListTableInput) => row["isInheritanceActive"] === true,
      callbackFn: (row: any) => this.updateFollowUpStatusInheritance(row, true),
      labelFn: () => "DEACTIVATE",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["isInheritanceActive"] === false,
      callbackFn: (row: any) => this.updateFollowUpStatusInheritance(row, true),
      labelFn: () => "ACTIVATE",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["source"] === SourceLevel.Organisation,
      callbackFn: (row: ISmallListTableInput) => this.#openEditPopup(row.id.toString()),
      labelFn: () => "EDIT",
    },
    {
      isVisible: (row: ISmallListTableInput) => row["source"] === SourceLevel.Organisation,
      callbackFn: (row: ISmallListTableInput) => this.#openDeletePopup(row),
      labelFn: () => "DELETE",
    },
  ];
  override loadData(params: IPaginationSortPayload): void {
    this.isTableLoading = true;
    this.#followUpStatusService
      .getPaginatedFollowUpStatuses(params, SourceLevel.Organisation)
      .subscribe((paginatedFollowUpStatuses) => {
        copyPaginatedSignalResponse(this.paginatedData, paginatedFollowUpStatuses);
        this.isTableLoading = false;
      });
  }

  openCreatePopup() {
    this.#matDialog
      .open<FollowUpStatusPopupComponent, IFollowUpStatusPopupInputs>(FollowUpStatusPopupComponent, {
        width: "28.125rem",
        maxHeight: "95dvh",
        data: {
          sourceLevel: SourceLevel.Organisation,
          isEditPopup: false,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((isCancel) => {
        if (isCancel) return;
        this.#loadDocumentsWithParams();
      });
  }

  #openEditPopup(followUpStatusId: string) {
    this.#matDialog
      .open<FollowUpStatusPopupComponent, IFollowUpStatusPopupInputs>(FollowUpStatusPopupComponent, {
        width: "28.125rem",
        maxHeight: "95dvh",
        data: {
          sourceLevel: SourceLevel.Organisation,
          isEditPopup: true,
          followUpStatusId: followUpStatusId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((isCancel) => {
        if (isCancel) return;
        this.#loadDocumentsWithParams();
      });
  }

  #openDeletePopup(row: ISmallListTableInput) {
    this.#dialogService.open(
      {
        title: "SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_STATUS.DELETE.NAME",
        callBack: () => this.#deleteFollowUpStatus(row.id),
        submitLabel: "DELETE",
        isInputIncluded: false,
        descriptions: ["SETTINGS.SYSTEM_CONFIGURATIONS.FOLLOW_UP_STATUS.DELETE.CONFIRMATION"],
        isSubmitLoading: this.#isFollowUpStatusDeleting,
      },
      "confirmation",
    );
  }

  #deleteFollowUpStatus(id: number) {
    this.#isFollowUpStatusDeleting.set(true);
    this.#followUpStatusService
      .deleteFollowUpStatus({
        sourceLevel: SourceLevel.Organisation,
        ids: { followUpStatusId: id.toString() },
      })
      .pipe(
        finalize(() => {
          this.#isFollowUpStatusDeleting.set(false);
          this.#dialogService.close();
        }),
      )
      .subscribe(() => this.#loadDocumentsWithParams());
  }

  updateFollowUpStatusInheritance(row: any, isAction?: boolean) {
    this.#followUpStatusService
      .toggleFollowUpStatusInheritance({
        sourceLevel: SourceLevel.Organisation,
        ids: { followUpStatusId: row.id },
      })
      .subscribe(() => {
        if (isAction) {
          this.#toggleRowInheritanceButton(row);
        }
      });
  }

  #loadDocumentsWithParams(): void {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
    });
  }

  #toggleRowInheritanceButton(row: any) {
    row.isInheritanceActive = !row.isInheritanceActive;
  }
}
