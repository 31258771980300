import { Component, effect, input } from "@angular/core";
import { BaseEventAbstract } from "@root/shared/abstracts/base-event/base-event.abstract";
import { IEventFilterPayload } from "@root/shared/interfaces/event-filter-payload.interface";
import { EventTitleComponent } from "@root/shared/event-title/event-title.component";
import { EventFilterComponent } from "@root/shared/event-filter/event-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@angular/material/tooltip";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { DatePipe, NgClass, NgOptimizedImage } from "@angular/common";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { EventCardComponent } from "@root/shared/event-card/event-card.component";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { IGlobalFilter } from "@root/shared/enums/global-filter";

@Component({
  selector: "est-previous-events",
  standalone: true,
  imports: [
    TranslateModule,
    TooltipComponent,
    EventTitleComponent,
    SelectFieldComponent,
    SearchInputComponent,
    NgOptimizedImage,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    EventFilterComponent,
    EventCardComponent,
    NgClass,
    LoadingOverlayComponent,
  ],
  templateUrl: "../../../../../shared/abstracts/base-event/base-event.html",

  providers: [DatePipe],
})
export class PreviousEventsComponent extends BaseEventAbstract {
  propertyId = input.required<number>();
  override title = "EVENTS.PREVIOUS_EVENTS";
  isStartDateIncluded = true;
  isEndDateIncluded = false;

  readonly loadEventsEffect$ = effect(() => {
    this.searchSignal();
    this.loadDataWithParams();
  });

  loadEvents(params: IEventFilterPayload): void {
    this.isLoading = true;
    this.eventService.getAllEvents(params, this.propertyId()).subscribe({
      next: (value) => {
        this.allEvents = value;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  loadDataAfterStopRepetition(isStopped: boolean) {
    if (isStopped) this.loadDataWithParams();
  }

  loadDataWithParams() {
    this.loadEvents({
      eventType: this.eventTypeSignal(),
      search: this.searchSignal(),
      propertyId: this.propertyId(),
      endDate: this.transferDateWithFormat(new Date()),
      ...(this.startDateSignal() ? { fromDate: this.transferDateWithFormat(this.startDateSignal())! } : {}),
      ...(this.agentsSignal() ? { agentId: this.agentsSignal()! } : {}),
      globalFilter: IGlobalFilter.Previous,
    });
  }
}
