import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APIS } from "@root/core/api/market.apis";
import { Observable } from "rxjs/internal/Observable";
import { IPropertyMediaPackageOverview } from "../models/property-media-package-overview.interface";
import { IPropertyMediaPackage } from "@root/data/market/properties/models/property-media-package.model";

@Injectable({
  providedIn: "root",
})
export class PropertiesMediaPackagesRepository {
  readonly #http = inject(HttpClient);

  getPropertyMediaPackages(propertyId: number): Observable<IPropertyMediaPackageOverview[]> {
    return this.#http.get<IPropertyMediaPackageOverview[]>(APIS.packages.getPropertyMediaPackages(propertyId));
  }

  createPropertyMediaPackage(propertyId: number, formData: FormData): Observable<void> {
    return this.#http.post<void>(APIS.packages.createPropertyMediaPackage(propertyId), formData);
  }

  getPropertyMediaPackageDetails(propertyId: number, packageId: number): Observable<Partial<IPropertyMediaPackage>> {
    return this.#http.get<Partial<IPropertyMediaPackage>>(
      APIS.packages.getPropertyMediaPackageDetails(propertyId, packageId),
    );
  }

  editPropertyMediaPackage(propertyId: number, packageId: number, formData: FormData): Observable<void> {
    return this.#http.put<void>(APIS.packages.editPropertyMediaPackage(propertyId, packageId), formData);
  }

  deletePropertyMediaPackage(propId: number, mediaPackageId: number): Observable<void> {
    return this.#http.delete<void>(APIS.packages.deletePropertyMediaPackage(propId, mediaPackageId));
  }

  duplicatePropertyMediaPackage(
    propertyId: number,
    mediaPackageId: number,
    body: {
      [key: string]: any;
    },
  ): Observable<void> {
    return this.#http.post<void>(APIS.packages.duplicatePropertyMediaPackage(propertyId, mediaPackageId), body);
  }
}
