<div class="sticky top-0 z-10">
  <est-dialog-header
    [label]="
      componentInputs.isEditPopup
        ? 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.EDIT.NAME'
        : 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.CREATE.TITLE'
    "
    [tooltipLabel]="
      componentInputs.isEditPopup
        ? 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.EDIT.NAME'
        : 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.CREATE.TITLE'
    "
  ></est-dialog-header>
</div>
@if (isPageLoading) {
  <est-loading-overlay></est-loading-overlay>
} @else {
  <div class="p-6">
    <est-small-header
      [heading]="
        componentInputs.isEditPopup
          ? 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.EDIT.NAME'
          : 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.CREATE.NAME'
      "
      [subheading]="
        componentInputs.isEditPopup
          ? 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.EDIT.DESCRIPTION'
          : 'SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.CREATE.DESCRIPTION'
      "
    ></est-small-header>
    <div class="flex gap-2 items-center mt-6 mb-2">
      <p class="text-sm">{{ "INTERNAL_TITLE" | translate }}</p>
      <est-tooltip tooltip="INTERNAL_TITLE"></est-tooltip>
    </div>
    <est-input label="INTERNAL_TITLE" [control]="formGroup.controls.internalTitle"></est-input>
    <div class="pt-6">
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <est-small-header
      appliedClasses="mt-6"
      heading="SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.PUBLIC.NAME"
      subheading="SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.PUBLIC.DESCRIPTION"
    ></est-small-header>
    <mat-slide-toggle
      class="text-sm font-light mb-6 mt-4"
      [checked]="formGroup.controls.published.value"
      (change)="togglePublished()"
      >{{ "SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.PUBLIC.PUBLISHED" | translate }}
    </mat-slide-toggle>
    @if (formGroup.controls.published.value) {
      <div class="flex flex-col gap-4 mb-6">
        @for (externalTitleControl of formGroup.controls.externalTitles.controls; track externalTitleControl) {
          <est-external-title-input
            [language]="getExternalTitleLanguage(externalTitleControl.controls['languageId'].value)"
            [isDefault]="isDefaultLanguage(externalTitleControl.controls['languageId'].value)"
            [formGroup]="externalTitleControl"
          ></est-external-title-input>
        }
      </div>
    }
    <div class="pb-6">
      <hr class="border-black-100 absolute start-0 end-0" />
    </div>
    <est-small-header
      heading="SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.ACTIVATED.NAME"
      subheading="SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.ACTIVATED.DESCRIPTION"
    ></est-small-header>
    <mat-slide-toggle
      class="text-sm font-light mt-4"
      [checked]="formGroup.controls.isInheritanceActive.value"
      (change)="toggleActivated()"
      >{{ "SETTINGS.MARKETING.SURVEYS.MARKETING_QUESTIONS.ACTIVATED.IS_ACTIVE" | translate }}
    </mat-slide-toggle>
  </div>
}
<div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
  <est-button
    [appliedClasses]="'!py-3 !px-6'"
    (click)="closeDialog()"
    label="CANCEL"
    buttonStyleType="button--tertiary"
    buttonType="button"
  />
  <est-button
    [appliedClasses]="'!py-3 !px-6'"
    [label]="componentInputs.isEditPopup ? 'SAVE' : 'CREATE'"
    buttonStyleType="button--primary"
    buttonType="button"
    [isLoading]="isFormSubmitting"
    (click)="submitForm()"
  />
</div>
