import { Component, DestroyRef, inject, input, OnDestroy, OnInit } from "@angular/core";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PostfixTextInputComponent } from "@root/shared/postfix-text-input/postfix-text-input.component";
import { UnitLayoutFormControls } from "@root/views/main/units/unit-details/components/unit-layout/unit-layout.form-controls";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import {
  generateDropdownOptionsFromNumber,
  generateTranslatedDropdownOptionsFromEnum,
} from "@root/shared/utilities/dropdown-options.utilities";
import { Orientation } from "@root/data/market/units/enums/orientation.enum";
import { OrientationTranslationMapper } from "@root/data/market/units/mappers/orientation-translation.mapper";
import { UnitLayoutService } from "@root/data/market/units/services/unit-layout.service";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { RoomInputFieldsComponent } from "@root/views/main/units/unit-details/components/unit-layout/components/room-input-fields/room-input-fields.component";
import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { InternalFloor } from "@root/data/market/units/enums/internal-floor.enum";
import { InternalFloorTranslationMapper } from "@root/data/market/units/mappers/internal-floor-translation.mapper";
import { IRoomDropdownOption } from "@root/views/main/units/unit-details/components/unit-layout/components/room-input-fields/room-dropdown-option.interface";
import {
  mapBalconyTypesToDropdownOptions,
  mapConnectionRoom,
  mapRoomNamesToDropdownOptions,
  mapTerraceTypesToDropdownOptions,
} from "@root/views/main/units/unit-details/components/unit-layout/unit-layout-form.utilities";
import { VisibilityControlledToggleInputComponent } from "@root/views/main/units/unit-details/components/unit-layout/components/visibility-controlled-toggle-input/visibility-controlled-toggle-input.component";
import {
  castControlFromAbstractToFormArray,
  castControlFromAbstractToFormControl,
  updateNestedControlsPathAndValue,
} from "@root/shared/utilities/form.utilities";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { AccessibilityFeatureInputsComponent } from "@root/views/main/units/unit-details/components/unit-layout/components/accessibility-feature-inputs/accessibility-feature-inputs.component";
import { RoomTypeNameTranslationMapper } from "@root/data/market/units/mappers/room-type-translation.mapper";
import { forkJoin, Observable, Subscription, tap } from "rxjs";
import { IUnitLayout } from "@root/data/market/units/models/unit-layout.model";
import { IRoomName } from "@root/data/market/rooms/models/room-name.model";
import { IBalconyType } from "@root/data/market/rooms/models/balcony-type.model";
import { ITerraceType } from "@root/data/market/rooms/models/terrace-type.model";
import { RoomTypesAddons } from "@root/views/main/units/unit-details/components/unit-layout/unit-layout.constants";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "est-unit-layout",
  standalone: true,
  imports: [
    UnderDevelopmentComponent,
    SubheaderComponent,
    TooltipComponent,
    TranslateModule,
    PostfixTextInputComponent,
    SelectFieldComponent,
    LoadingOverlayComponent,
    RoomInputFieldsComponent,
    VisibilityControlledToggleInputComponent,
    ReactiveFormsModule,
    AccessibilityFeatureInputsComponent,
  ],
  templateUrl: "./unit-layout.component.html",
  styleUrl: "./unit-layout.component.scss",
})
export class UnitLayoutComponent implements OnInit, OnDestroy {
  unitId = input.required<string>();
  unitLayoutFormControls = inject(UnitLayoutFormControls);
  protected unitLayoutFormGroup!: FormGroup;
  readonly #unitLayoutService = inject(UnitLayoutService);
  protected isPageLoading: boolean = false;
  protected InternalFloors = generateTranslatedDropdownOptionsFromEnum(InternalFloor, InternalFloorTranslationMapper);
  protected limitedInternalFloors: IDropdownOption[] = [];
  protected RoomQuantities = generateDropdownOptionsFromNumber(10, false, 1);
  protected NumberOfInternalFloors = generateDropdownOptionsFromNumber(5, false, 1);
  protected RoomNames: IRoomDropdownOption[] = [];
  protected BalconyTypes: IDropdownOption[] = [];
  protected TerraceTypes: IDropdownOption[] = [];
  protected RoomTypeAddons = RoomTypesAddons;
  readonly #translateService = inject(TranslateService);
  readonly #destroyRef = inject(DestroyRef);
  protected Orientations = [
    { label: "NONE", value: null },
    ...generateTranslatedDropdownOptionsFromEnum(Orientation, OrientationTranslationMapper),
  ];
  protected ConnectionRooms: IDropdownOption[] = [
    {
      label: "UNIT_LAYOUT.NO_LOCATION",
      value: null,
    },
  ];
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;
  protected readonly castControlFromAbstractToFormArray = castControlFromAbstractToFormArray;

  #loadUnitLayoutSubscription: Subscription = new Subscription();

  ngOnInit() {
    this.isPageLoading = true;
    this.#loadUnitLayoutSubscription = forkJoin([
      this.#loadUnitLayout(),
      this.#loadRoomNames(),
      this.#loadTerraceTypes(),
      this.#loadBalconyTypes(),
    ]).subscribe(() => {
      this.#loadConnectionRooms();
      this.#updateAvailableFloorList();
      this.unitLayoutFormGroup.controls["internalFloors"].valueChanges
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(() => this.#updateAvailableFloorList());
      this.isPageLoading = false;
    });
  }

  #loadConnectionRooms(): void {
    const roomTypes = [
      { type: RoomType.Bedroom, controlName: "bedrooms" },
      { type: RoomType.LivingRoom, controlName: "livingRooms" },
      { type: RoomType.AdditionalRoom, controlName: "additionalRooms" },
    ];

    roomTypes.forEach((room) => {
      const formArray = this.castControlFromAbstractToFormArray(this.unitLayoutFormGroup.controls[room.controlName]);
      formArray.controls.forEach((control, index) => {
        this.#addConnectionRoom(room.type, control as FormGroup, index);
      });
    });
  }

  #addConnectionRoom(roomType: RoomType, formGroup: FormGroup, index: number): void {
    this.ConnectionRooms.push(
      mapConnectionRoom(
        this.#translateService.instant(RoomTypeNameTranslationMapper.get(roomType)!),
        this.#getRoomName(formGroup.controls["roomNameId"].value),
        formGroup.controls["id"].value,
        index,
      ),
    );
  }

  #getRoomName(roomNameId: number): string | undefined {
    const roomName = this.RoomNames.find((x) => x.value === roomNameId);
    if (roomName?.value === null) {
      return undefined;
    }
    return roomName?.label;
  }

  #loadUnitLayout(): Observable<IUnitLayout> {
    return this.#unitLayoutService.getUnitLayout(this.unitId()).pipe(
      tap((unitLayout) => {
        this.unitLayoutFormGroup = this.unitLayoutFormControls.constructUnitLayout(unitLayout);
      }),
    );
  }

  #loadRoomNames(): Observable<IRoomName[]> {
    return this.#unitLayoutService.getRoomNames().pipe(
      tap((roomNames) => {
        this.RoomNames = mapRoomNamesToDropdownOptions(roomNames);
      }),
    );
  }

  #loadBalconyTypes(): Observable<IBalconyType[]> {
    return this.#unitLayoutService.getBalconyTypes().pipe(
      tap((balconyTypes) => {
        this.BalconyTypes = mapBalconyTypesToDropdownOptions(balconyTypes);
      }),
    );
  }

  #loadTerraceTypes(): Observable<ITerraceType[]> {
    return this.#unitLayoutService.getTerraceTypes().pipe(
      tap((terraceTypes) => {
        this.TerraceTypes = mapTerraceTypesToDropdownOptions(terraceTypes);
      }),
    );
  }

  protected patchUnitLayoutChanges() {
    const controlPath = updateNestedControlsPathAndValue(this.unitLayoutFormGroup);
    if (Object.keys(controlPath).length) {
      this.#unitLayoutService.patchUnitLayout(this.unitId(), controlPath).subscribe();
    }
  }

  protected doesTotalRoomCountMatch(): boolean {
    return (
      this.unitLayoutFormGroup.controls["roomQuantity"].value <
      this.unitLayoutFormGroup.controls["bedroomQuantity"].value +
        this.unitLayoutFormGroup.controls["livingRoomQuantity"].value +
        this.unitLayoutFormGroup.controls["additionalRoomQuantity"].value
    );
  }

  #updateAvailableFloorList() {
    this.limitedInternalFloors = this.InternalFloors.slice(
      0,
      this.unitLayoutFormGroup.controls["internalFloors"].value,
    );
  }

  ngOnDestroy() {
    this.#loadUnitLayoutSubscription.unsubscribe();
  }
}
