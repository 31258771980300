import { Component, input, output, OutputEmitterRef } from "@angular/core";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { UnitUsageType } from "@root/data/market/import/units/enums/unit-usage-type.enum";
import { FormControl } from "@angular/forms";

@Component({
  selector: "est-usage-type-select-field",
  standalone: true,
  imports: [SelectFieldComponent],
  templateUrl: "./usage-type-select-field.component.html",
  styleUrl: "./usage-type-select-field.component.scss",
})
export class UsageTypeSelectFieldComponent {
  control = input.required<FormControl<UnitUsageType | null>>();

  change: OutputEmitterRef<any> = output();

  usageTypeOptions: IDropdownOption[] = [
    { label: "UNIT_USAGE_TYPES.STUDENT_ACCOMMODATION", value: UnitUsageType.StudentAccommodation },
    { label: "UNIT_USAGE_TYPES.SENIOR_ACCOMMODATION", value: UnitUsageType.SeniorAccommodation },
    { label: "UNIT_USAGE_TYPES.SHORT_STAY", value: UnitUsageType.Shortstay },
    { label: "UNIT_USAGE_TYPES.LONG_STAY", value: UnitUsageType.Longstay },
    { label: "UNIT_USAGE_TYPES.INDUSTRIAL", value: UnitUsageType.Industrial },
    { label: "UNIT_USAGE_TYPES.CLINIC", value: UnitUsageType.Clinic },
    { label: "UNIT_USAGE_TYPES.WAREHOUSE", value: UnitUsageType.Warehouse },
    { label: "UNIT_USAGE_TYPES.PRODUCTION_CENTRE", value: UnitUsageType.ProductionCentre },
    { label: "UNIT_USAGE_TYPES.OFFICE", value: UnitUsageType.Office },
    { label: "UNIT_USAGE_TYPES.DEDICATED_DESK", value: UnitUsageType.DedicatedDesk },
    { label: "UNIT_USAGE_TYPES.PRIVATE_OFFICE", value: UnitUsageType.PrivateOffice },
  ];
}
