import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { Observable } from "rxjs/internal/Observable";
import { IDeleteDivisionPayload } from "../../division/models/division-delete.model";
import { IOrganisationCommunicationSettings } from "../models/organisation-communication-settings.model";
import { IOrganisationDetails } from "../models/organisation.model";
import { IOrganizationStyling } from "../models/organization-styling.model";

@Injectable({ providedIn: "root" })
export class OrganisationRepository {
  readonly #http = inject(HttpClient);

  getOrganisationDetails(): Observable<IOrganisationDetails> {
    return this.#http.get<IOrganisationDetails>(APIS.organisation.getDetails);
  }

  patchOrganisationDetails(organisationDetailsForm: { [key: string]: any }): Observable<void> {
    return this.#http.patch<void>(APIS.organisation.patchDetails, organisationDetailsForm);
  }

  deleteDivision(payload: IDeleteDivisionPayload): Observable<void> {
    return this.#http.delete<void>(APIS.organisation.deleteDivision, { body: payload });
  }

  transferOrganizationOwnership(newOwnerUserId: number): Observable<void> {
    return this.#http.put<void>(APIS.organisation.transferOwnership, { newOwnerUserId: newOwnerUserId });
  }

  getOrganizationCommunicationSettings(): Observable<IOrganisationCommunicationSettings> {
    return this.#http.get<IOrganisationCommunicationSettings>(APIS.organisation.getCommunicationSettings);
  }

  patchOrganizationCommunicationSettings(organisationCommunicationSettingsForm: {
    [key: string]: any;
  }): Observable<void> {
    return this.#http.patch<void>(APIS.organisation.patchCommunicationSettings, organisationCommunicationSettingsForm);
  }

  updateOrganizationStyling(organizationStyling: FormData): Observable<void> {
    return this.#http.put<void>(APIS.organisation.updateStyling, organizationStyling);
  }

  getOrganizationStyling(id: number): Observable<IOrganizationStyling> {
    return this.#http.get<IOrganizationStyling>(APIS.organisation.organizationStyling, {
      params: new HttpParams({
        fromObject: { id },
      }),
    });
  }
}
