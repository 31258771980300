import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, shareReplay } from "rxjs";
import { ILookUpItem } from "@root/data/market/lookups/models/look-up-item.model";
import { APIS } from "@root/core/api/market.apis";

@Injectable({
  providedIn: "root",
})
export class FacilityLookupsRepository {
  readonly #http = inject(HttpClient);

  #cachedUnitFacilityLocationsLookups = this.#http
    .get<ILookUpItem[]>(APIS.lookups.getUnitFacilityLocationsLookup)
    .pipe(shareReplay(1));

  getUnitFacilityLocationsLookups(): Observable<ILookUpItem[]> {
    return this.#cachedUnitFacilityLocationsLookups;
  }

  #cachedUnitFacilityCategoriesLookups = this.#http
    .get<ILookUpItem[]>(APIS.lookups.getUnitFacilityCategoriesLookup)
    .pipe(shareReplay(1));

  getUnitFacilityCategoriesLookups(): Observable<ILookUpItem[]> {
    return this.#cachedUnitFacilityCategoriesLookups;
  }
}
