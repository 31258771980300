import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { ISortChangeConfirmationComponentInputs } from "@root/shared/abstracts/base-property-media-package/sort-change-confirmation/sort-change-confirmation-component-inputs.interface";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { ButtonComponent } from "@root/shared/button/button.component";
import { PropertyMediaPackageSortOrder } from "@root/data/market/properties/enums/property-media-package-sort-order.enum";

@Component({
  selector: "est-sort-change-confirmation",
  standalone: true,
  imports: [TranslateModule, MatRadioButton, MatRadioGroup, ButtonComponent],
  templateUrl: "./sort-change-confirmation.component.html",
  styleUrl: "./sort-change-confirmation.component.scss",
})
export class SortChangeConfirmationComponent {
  componentInputs: ISortChangeConfirmationComponentInputs = inject(MAT_DIALOG_DATA);
  readonly #matDialog = inject(MatDialog);

  cancel() {
    const control = this.componentInputs.control;
    if (control.value === PropertyMediaPackageSortOrder.InOrder)
      control.setValue(PropertyMediaPackageSortOrder.OneTimeRandomized);
    else control.setValue(PropertyMediaPackageSortOrder.InOrder);
    this.#matDialog.closeAll();
  }

  confirm() {
    this.#matDialog.closeAll();
  }
}
