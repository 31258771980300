<div class="relative p-6 z-50">
  <div class="flex gap-2 items-center sticky top-0">
    <est-small-header
      [heading]="'UPLOAD' | translate"
      [subheading]="''"
      [tooltip]="'SETTINGS.USERS.PROFILE.PROFILE_IMAGE_PLACEHOLDER' | translate"
    ></est-small-header>
  </div>
</div>
<mat-divider></mat-divider>

<div class="flex flex-col p-6">
  <p class="text-sm truncate font-medium mb-4">
    {{ "FILE_NAME" | translate }}{{ fileSizeConvertToBytes === 0 ? ":" : ": " + imageName.value }}
  </p>
  <div class="mb-6">
    <p class="text-sm font-light">
      {{ "FILE_SIZE" | translate }}
      {{ fileSizeConvertToBytes === 0 ? ":" : ": " + transferBytes(fileSizeConvertToBytes) }}
    </p>
    <p class="text-sm font-light">
      {{ "IMAGE_SIZE" | translate }}
      {{ fileSizeConvertToBytes === 0 ? ":" : ": " + imageDimensions() }}
    </p>
  </div>
  @if (!displayImage) {
    <est-upload-file-drop-zone
      appliedClasses="!w-[30rem]"
      [supportedTypes]="[FileExtension.JPEG, FileExtension.JPG, FileExtension.PNG]"
      (fileUpload)="fileChangeEvent($event)"
      [recommendedImageWidth]="300"
      [recommendedImageHeight]="200"
    ></est-upload-file-drop-zone>
  } @else {
    <img [src]="displayImage" alt="image uploaded" width="400" height="200" />
  }
</div>
<div class="flex justify-between bg-black-50 mt-6 p-6">
  <est-button label="CANCEL" buttonType="button" mat-dialog-close buttonStyleType="button--tertiary"></est-button>

  <est-button
    label="UPLOAD"
    buttonType="button"
    buttonStyleType="button--primary"
    (click)="sendImageToCreateUserComponent()"
  ></est-button>
</div>
