import { inject, Injectable } from "@angular/core";
import { TimezonesRepository } from "@root/data/market/timezones/repositories/timezones.repository";
import { map, Observable } from "rxjs";
import { ITimezoneDropdownOption } from "@root/views/main/organization/organization-settings/components/system-configurations/components/general/timezone-dropdown-option.interface";

@Injectable({
  providedIn: "root",
})
export class TimezonesService {
  readonly #timezonesRepository = inject(TimezonesRepository);

  getSystemTimeZones() {
    return this.#timezonesRepository.getSystemTimeZones();
  }

  getSystemTimeZonesDropdownOptions(): Observable<ITimezoneDropdownOption[]> {
    return this.#timezonesRepository.getSystemTimeZones().pipe(
      map((timezones) =>
        timezones.map((timezone) => ({
          label: timezone.displayName,
          value: timezone.id,
          id: timezone.id,
          displayName: timezone.displayName,
          utcOffset: timezone.utcOffset,
        })),
      ),
    );
  }
}
