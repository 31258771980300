export const CurrencyLocalMapper = new Map<string, string>([
  ["DKK", "da-DK"],
  ["USD", "en-US"],
  ["EUR", "en-EU"],
  ["GBP", "en-GB"],
  ["SEK", "sv-SE"],
  ["NOK", "nb-NO"],
  ["CHF", "fr-CH"],
  ["CAD", "en-CA"],
  ["AUD", "en-AU"],
  ["NZD", "en-NZ"],
  ["JPY", "ja-JP"],
  ["CNY", "zh-CN"],
  ["HKD", "zh-HK"],
  ["SGD", "en-SG"],
  ["INR", "en-IN"],
  ["ZAR", "en-ZA"],
  ["RUB", "ru-RU"],
  ["TRY", "tr-TR"],
  ["BRL", "pt-BR"],
  ["MXN", "es-MX"],
  ["ARS", "es-AR"],
  ["CLP", "es-CL"],
  ["COP", "es-CO"],
  ["PEN", "es-PE"],
  ["UYU", "es-UY"],
  ["PHP", "en-PH"],
  ["IDR", "id-ID"],
  ["THB", "th-TH"],
  ["MYR", "ms-MY"],
  ["VND", "vi-VN"],
  ["PLN", "pl-PL"],
  ["HUF", "hu-HU"],
  ["CZK", "cs-CZ"],
  ["ILS", "he-IL"],
  ["SAR", "ar-SA"],
  ["AED", "ar-AE"],
  ["KRW", "ko-KR"],
  ["NGN", "en-NG"],
  ["EGP", "ar-EG"],
  ["KWD", "ar-KW"],
  ["QAR", "ar-QA"],
  ["OMR", "ar-OM"],
  ["BHD", "ar-BH"],
  ["JOD", "ar-JO"],
  ["LBP", "ar-LB"],
  ["TWD", "zh-TW"],
  ["GHS", "en-GH"],
  ["CZK", "cs-CZ"],
  ["RSD", "sr-RS"],
  ["HRK", "hr-HR"],
  ["BGN", "bg-BG"],
  ["RON", "ro-RO"],
  ["DZD", "ar-DZ"],
  ["MAD", "ar-MA"],
  ["TND", "ar-TN"],
  ["PKR", "ur-PK"],
  ["BDT", "bn-BD"],
  ["LKR", "si-LK"],
  ["NPR", "ne-NP"],
  ["MVR", "dv-MV"],
  ["MUR", "mfe-MU"],
  ["NAD", "en-NA"],
  ["BWP", "en-BW"],
  ["ZMW", "en-ZM"],
  ["TZS", "en-TZ"],
  ["UGX", "en-UG"],
  ["KES", "en-KE"],
  ["GEL", "ka-GE"],
  ["AMD", "hy-AM"],
  ["AZN", "az-Latn-AZ"],
  ["BAM", "bs-Latn-BA"],
  ["BYN", "be-BY"],
  ["CDF", "sw-CD"],
  ["GMD", "en-GM"],
  ["GNF", "fr-GN"],
  ["KGS", "ky-KG"],
  ["LRD", "en-LR"],
  ["MKD", "mk-MK"],
  ["MGA", "mg-MG"],
  ["MMK", "my-MM"],
  ["UAE", "en-AE"],
  ["RWF", "rw-RW"],
  ["SWE", "en-SE"],
]);
