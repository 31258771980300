<form (ngSubmit)="submitForm()" [formGroup]="userForm">
  <div class="sticky top-0 bg-white z-10">
    <est-dialog-header
      [includeExitButton]="true"
      tooltipLabel="SETTINGS.USERS.EDIT_USER"
      label="SETTINGS.USERS.EDIT_USER"
    >
    </est-dialog-header>
    <mat-progress-bar [value]="getProgressbarValue()" class="py-1"></mat-progress-bar>
  </div>
  @if (!isPageLoading) {
    <div class="pb-8 max-h-[calc(100dvh-14rem)] overflow-y-auto relative">
      <mat-stepper #stepper animationDuration="0" (selectionChange)="updateStepData($event)">
        <!-- First step -->
        <mat-step [stepControl]="firstStepControl">
          <div class="grid grid-cols-8 gap-2">
            <div class="col-span-6 max-breakpoint-ipad-screen:col-span-8 relative">
              <div class="p-6">
                <p class="font-medium mb-3">{{ "SETTINGS.USERS.USER_INFORMATION" | translate }}</p>
                <div class="flex flex-wrap md:flex-nowrap gap-4 mb-4">
                  <est-input
                    [appliedClass]="'w-[18.75rem]'"
                    [control]="firstNameControl"
                    label="SETTINGS.USERS.FIRST_NAME"
                    [name]="'firstName'"
                  />
                  <est-input
                    [appliedClass]="'w-[18.75rem]'"
                    [control]="lastNameControl"
                    label="SETTINGS.USERS.LAST_NAME"
                    [name]="'lastName'"
                  />
                </div>
                <div class="flex flex-wrap md:flex-nowrap gap-4 mb-4">
                  <est-input [appliedClass]="'w-[18.75rem]'" [control]="emailControl" label="EMAIL" [name]="'email'" />
                  <est-input
                    [appliedClass]="'w-[18.75rem]'"
                    [control]="workPlaceControl"
                    label="SETTINGS.USERS.WORKPLACE"
                    [name]="'workPlace'"
                  />
                </div>
                <div class="flex flex-wrap md:flex-nowrap gap-4 mb-4">
                  <est-input
                    [appliedClass]="'w-[18.75rem]'"
                    [control]="titleControl"
                    label="SETTINGS.USERS.TITLE"
                    [name]="'title'"
                  />
                  <est-phone-input [control]="phoneNumberControl" />
                </div>
              </div>
              <hr class="border-black-100 absolute w-full" />
              <!--  system language -->
              <div class="flex flex-col p-6 flex-wrap">
                <p class="font-medium mb-4">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE" | translate }}</p>
                <p class="text-sm mb-6">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE_SUBTITLE" | translate }}</p>
                <est-language-dropdown
                  [appliedClasses]="'w-[300px] h-[44px]'"
                  [control]="systemLanguageControl"
                  [countries]="languages"
                  selectMultipleOptions="false"
                />
              </div>
              <hr class="border-black-100 absolute w-full" />
              <div class="flex flex-col p-6 items-start">
                <p class="font-medium mb-4">{{ "SETTINGS.USERS.USER_ROLES" | translate }}</p>
                <p class="text-sm mb-6">{{ "SETTINGS.USERS.USER_ROLES_SUBTITLE" | translate }}</p>
                @for (division of divisionsFormArray.controls; track $index) {
                  <div class="mb-8">
                    <p class="font-medium my-4">{{ "SETTINGS.USERS.USER_ROLE_NO" | translate }} {{ $index + 1 }}</p>
                    <p class="text-sm mb-6">
                      {{ "SETTINGS.USERS.USER_ROLE_SUBTITLE" | translate }}
                    </p>
                    <div class="flex gap-4 flex-wrap">
                      <div>
                        <p class="font-medium mb-4 text-sm">{{ "DIVISIONS.DIVISION" | translate }}</p>
                        <est-select-division-field
                          [disabledOptions]="getDisabledDivisionIds()"
                          [control]="getControlInFormArray(divisionsFormArray, 'divisionId', $index)"
                        />
                      </div>
                      <div class="mb-6 md:mb-0">
                        <p class="font-medium mb-4 text-sm">{{ "SETTINGS.USERS.USER_ROLE" | translate }}</p>
                        <est-select-field
                          [dropDownLabel]="'SETTINGS.USERS.SELECT_ROLE'"
                          [dropDownOptions]="roleDropdownOptions"
                          [control]="getControlInFormArray(divisionsFormArray, 'roleId', $index)"
                          [selectMultipleOptions]="false"
                          [appliedClass]="'!w-[18.75rem]'"
                        />
                      </div>
                      @if (divisionsFormArray.controls.length !== 1) {
                        <div class="mt-12 w-6 h-6 border border-black-300 rounded flex items-center justify-center">
                          <button (click)="removeAndReplaceDivisionUser($index)" type="button">
                            <img
                              property="true"
                              width="12"
                              height="12"
                              ngSrc="assets/images/icons/trash-icon.svg"
                              [alt]="'REMOVE' | translate"
                            />
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                }
                <button
                  (click)="addUserDivisionRole()"
                  class="button--secondary uppercase tracking-[0.5px] flex items-center gap-2 mb-8"
                  type="button"
                >
                  <img
                    [alt]="'ADD' | translate"
                    [ngSrc]="'/assets/images/icons/Add-icon.svg'"
                    height="12"
                    priority
                    width="12"
                  />
                  <span class="text-sm">{{ "SETTINGS.USERS.CREATE_EXTRA_USER_ROLE" | translate }}</span>
                </button>
              </div>
            </div>
            <div
              class="col-span-2 border-l pt-4 px-6 max-breakpoint-ipad-screen:col-span-8 max-breakpoint-ipad-screen:border-t max-breakpoint-ipad-screen:flex max-breakpoint-ipad-screen:justify-start"
            >
              <est-image-upload
                [title]="'SETTINGS.USERS.PROFILE.PROFILE_IMAGE_PLACEHOLDER'"
                [description]="'SETTINGS.USERS.PROFILE.PROFILE_MESSAGE'"
                [imageFormControl]="imageControl"
              ></est-image-upload>
            </div>
          </div>
        </mat-step>
        <!-- Second step -->
        <mat-step [stepControl]="teamIdsControl">
          <div class="p-6">
            <p class="font-medium mb-4">
              {{ "SETTINGS.USERS.ASSIGN_USER_TO_TEAMS" | translate }}
            </p>
            <p class="form-block__description max-w-[47rem] !mb-6">
              {{ "SETTINGS.USERS.ASSIGN_USER_SUBTITLE" | translate }}
            </p>
            <div class="text-end mb-6"></div>
            <est-list-select-teams
              [divisionIds]="divisionIds"
              [userId]="componentInputs.userId"
              [stepIndex]="stepper.selectedIndex"
              [controlMapper]="teamIdsControlMapper"
              (checkItem)="unSelectTeam($event)"
            ></est-list-select-teams>
          </div>
        </mat-step>
        <!-- Third step -->
        <mat-step>
          <div class="p-6">
            <p class="font-medium mb-4">
              {{ "SETTINGS.USERS.ASSIGN_PROPERTIES" | translate }}
            </p>
            <p class="form-block__description max-w-[47rem] !mb-6">
              {{ "SETTINGS.USERS.ASSIGN_PROPERTIES_SUBTITLE" | translate }}
            </p>
            <est-list-select-properties
              [accessLevelControl]="propertiesControl"
              [divisionIds]="divisionIds"
              [propertiesAccessLevelHashMap]="propertiesAccessLevelHashMap"
              [propertiesAccessStatusHashMap]="propertiesAccessStatusHashMap"
              [teamIds]="teamIds"
              tableAppliedClasses="mb-4"
            ></est-list-select-properties>
          </div>
        </mat-step>
      </mat-stepper>
    </div>

    <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
      @if (stepper.selectedIndex === 0) {
        <est-button
          [label]="'CANCEL'"
          [appliedClasses]="'!py-3 !px-6'"
          (click)="closeDialog()"
          buttonType="button"
          buttonStyleType="button--tertiary"
        >
        </est-button>
      } @else {
        <est-button
          [label]="'BACK'"
          [appliedClasses]="'!py-3 !px-6'"
          (click)="stepper.previous()"
          buttonStyleType="button--tertiary"
          buttonType="button"
          [disabled]="isPageLoading"
        >
        </est-button>
      }
      <div class="flex gap-3">
        @if (stepper.selectedIndex < 2) {
          <est-button
            label="SAVE_AND_CLOSE"
            [appliedClasses]="'!py-3 !px-6'"
            buttonStyleType="button--secondary"
            buttonType="submit"
            [disabled]="isPageLoading"
          >
          </est-button>

          <est-button
            label="NEXT"
            [appliedClasses]="'!py-3 !px-6'"
            [label]="'NEXT'"
            buttonStyleType="button--primary"
            buttonType="button"
            (click)="moveToNextStepIfValid()"
            [isLoading]="isPageLoading"
          >
          </est-button>
        } @else {
          <est-button
            label="SAVE"
            [appliedClasses]="'!py-3 !px-6'"
            buttonStyleType="button--primary"
            buttonType="submit"
            [disabled]="isPageLoading"
          >
          </est-button>
        }
      </div>
    </div>
  } @else {
    <div class="p-52 relative">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</form>
