import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { IPropertyFacilityOverview } from "@root/data/market/properties/models/property-facility-overview.model";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPropertyFacilityLookup } from "@root/data/market/properties/models/property-facility-lookup.model";
import { IPaginationPayload } from "@root/shared/interfaces/pagination-payload.interface";
import { APIS } from "@root/core/api/market.apis";
import { IPropertyCategory } from "@root/data/market/properties/models/property-category.model";
import { IPropertyFacilitiesPaginationPayload } from "@root/views/main/property/property-marketing/components/facilities/property-facilities/property-facilities-pagination-payload.interface";
import { ITogglePropertyFacilityPayload } from "@root/data/market/properties/models/toggle-property-facility-payload.model";
import { ITogglePropertyFacilityResponse } from "@root/data/market/properties/models/toggle-property-facility-response.model";
import { IPropertyFacility } from "@root/data/market/properties/models/property-facility.model";
import { IEditPropertyFacilityPayload } from "@root/data/market/properties/models/edit-property-facility-payload.model";

@Injectable({
  providedIn: "root",
})
export class PropertiesFacilitiesRepository {
  readonly #http = inject(HttpClient);

  getAssignedPropertyFacilitiesIds(propertyId: number): Observable<number[]> {
    return this.#http.get<number[]>(APIS.propertyFacilities.getAssignedIds(propertyId));
  }

  getAllPropertiesFacilities(params: IPaginationPayload): Observable<IPaginatedResponse<IPropertyFacilityLookup>> {
    return this.#http.get<IPaginatedResponse<IPropertyFacilityLookup>>(APIS.propertyFacilities.getAll, {
      params: new HttpParams({
        fromObject: { ...params, organizationId: 1 },
      }),
    });
  }

  getAllAssignedPropertiesFacilities(
    params: IPropertyFacilitiesPaginationPayload,
  ): Observable<IPaginatedResponse<IPropertyFacilityOverview>> {
    return this.#http.get<IPaginatedResponse<IPropertyFacilityOverview>>(APIS.propertyFacilities.getAssigned, {
      params: new HttpParams({
        fromObject: { ...params },
      }),
    });
  }

  togglePropertyFacility(payload: ITogglePropertyFacilityPayload): Observable<ITogglePropertyFacilityResponse> {
    return this.#http.post<ITogglePropertyFacilityResponse>(APIS.propertyFacilities.toggleActive, payload);
  }

  addPropertyFacility(propertyId: number, propertiesFacilitiesIds: number[]): Observable<void> {
    return this.#http.post<void>(APIS.propertyFacilities.add(propertyId), propertiesFacilitiesIds);
  }

  getPropertyFacilityDetails(propertyId: number, facilityId: number): Observable<IPropertyFacility> {
    return this.#http.get<IPropertyFacility>(APIS.propertyFacilities.getDetails, {
      params: new HttpParams({
        fromObject: { propertyId: propertyId, assignedPropertyFacilityId: facilityId },
      }),
    });
  }

  editPropertyFacility(payload: Partial<IEditPropertyFacilityPayload>): Observable<void> {
    return this.#http.put<void>(APIS.propertyFacilities.edit, payload);
  }

  getPropertiesCategories(): Observable<IPropertyCategory[]> {
    return this.#http.get<IPropertyCategory[]>(APIS.propertyFacilities.getCategories);
  }
}
