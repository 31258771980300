<div class="bg-black-200 rounded-[0.625rem] p-6">
  <est-stepper-navigation
    [buttonsLabels]="buttonsLabels"
    [selectedIndex]="stepper.selectedIndex"
  ></est-stepper-navigation>
  <div class="relative">
    <form
      (ngSubmit)="editFacilityPackage()"
      [formGroup]="packageForm"
      class="bg-white rounded-[0.625rem] p-6 r-screen-[25rem] relative pb-24"
    >
      <mat-stepper #stepper animationDuration="0">
        <mat-step [stepControl]="packageForm">
          <est-subheader
            [label]="stepFormTile(stepper.selectedIndex, 'PROPERTY.SETTINGS')"
            [tooltip]="stepFormTile(stepper.selectedIndex, 'PROPERTY.SETTINGS')"
            date="Mon Feb 19 2024 14:20:06 GMT+0200"
            name="Jesper Thorkildsen"
          ></est-subheader>
          <est-small-header
            appliedClasses="mt-12 !mb-2"
            heading="PROPERTY_FACILITIES.PACKAGE_SETTING"
            subheading="PROPERTY_FACILITIES.TO_CREATE_A_NEW_FACILITY_PACKAGE"
            tooltip="PROPERTY_FACILITIES.PACKAGE_SETTING"
          ></est-small-header>
          <est-small-header
            appliedClasses="mt-6 !mb-2"
            heading="PROPERTY_FACILITIES.INTERNAL_TITLE"
            subheading="PROPERTY_FACILITIES.AN_INTERNAL_TITLE_USED"
            tooltip="PROPERTY_FACILITIES.INTERNAL_TITLE"
          ></est-small-header>
          <est-input
            [control]="internalTitle"
            appliedClass="!w-[37.5rem] mt-4"
            label="PROPERTY_FACILITIES.INTERNAL_TITLE"
          />
          <hr class="mt-6 border-black-100 absolute start-0 end-0" />
          <est-small-header
            appliedClasses="mt-12 !mb-2"
            heading="PROPERTY_FACILITIES.ALLOW_DEACTIVATE_ON_UNIT_LEVEL"
            subheading="PROPERTY_FACILITIES.BY_ALLOWING_DEACTIVATE_ON_UNIT"
            tooltip="PROPERTY_FACILITIES.ALLOW_DEACTIVATE_ON_UNIT_LEVEL"
          ></est-small-header>
          <mat-slide-toggle [formControl]="allowDeactivatingOnUnit" class="mt-4"
            >{{ "PROPERTY_FACILITIES.ALLOW_DEACTIVATE_OF_FACILITIES" | translate }}
          </mat-slide-toggle>
          <hr class="mt-6 border-black-100 absolute start-0 end-0" />
          <est-small-header
            appliedClasses="mt-12 !mb-2"
            heading="PROPERTY_FACILITIES.ACTIVATE_PACKAGE"
            subheading="PROPERTY_FACILITIES.ENABLE_THE_SETTING"
            tooltip="PROPERTY_FACILITIES.ACTIVATE_PACKAGE"
          ></est-small-header>
          <mat-slide-toggle [formControl]="isActive" class="mt-4"
            >{{ "PROPERTY_FACILITIES.ACTIVATE_PACKAGE" | translate }}
          </mat-slide-toggle>
        </mat-step>
        <!-- ADD Facilities-->
        <mat-step>
          <est-subheader
            [label]="stepFormTile(stepper.selectedIndex, 'PROPERTY_FACILITIES.ADD_FACILITIES')"
            [tooltip]="stepFormTile(stepper.selectedIndex, 'PROPERTY_FACILITIES.ADD_FACILITIES')"
            date="Mon Feb 19 2024 14:20:06 GMT+0200"
            name="Jesper Thorkildsen"
          ></est-subheader>
          <est-small-header
            appliedClasses="mt-12 !mb-2"
            heading="PROPERTY_FACILITIES.UNIT_FACILITIES"
            subheading="PROPERTY_FACILITIES.SEARCH_AND_SELECT_FROM_THE_PREDEFINED"
            tooltip="PROPERTY_FACILITIES.UNIT_FACILITIES"
          ></est-small-header>
          <p class="text-sm font-light xl:max-w-[43.75rem] my-4">
            {{ "PROPERTY_FACILITIES.ACCESSIBLE_MEANS" | translate }}
          </p>
          <p class="text-sm font-medium">
            {{
              "PROPERTY_FACILITIES.TOTAL_FACILITIES"
                | translate
                  : {
                      total: this.paginatedData.totalCount()
                    }
            }}
          </p>
          <p class="text-sm font-medium">
            {{
              "PROPERTY_FACILITIES.SELECTED _FACILITIES"
                | translate
                  : {
                      total: selectedFacilitiesCount
                    }
            }}
          </p>

          <div class="flex gap-2 justify-end mb-6 flex-wrap">
            <est-select-field
              [dropDownOptions]="locationsLookupOptions"
              [predefinedOptions]="filtersPredefinedOptions"
              [selectMultipleOptions]="false"
              [selectSignal]="locationSignal"
              appliedClass="!w-[9.375rem]"
              dropDownLabel="LOCATION"
            ></est-select-field>
            <est-select-field
              [dropDownOptions]="categoriesLookupOptions"
              [predefinedOptions]="filtersPredefinedOptions"
              [selectMultipleOptions]="false"
              [selectSignal]="categoryIdSignal"
              appliedClass="!w-[9.375rem]"
              dropDownLabel="CATEGORY"
            ></est-select-field>
            <est-search-input [searchSignal]="searchSignal"></est-search-input>
          </div>
          <est-small-list-table
            [actions]="actions"
            [checkboxControlMapper]="facilityIdsMapper"
            [data]="paginatedData.results()"
            [headers]="headers"
            [iconMenuComponentType]="FacilityDetailsComponent"
            [isLoading]="isTableLoading"
            [sortingFilterSignal]="sortingFilterSignal"
            (checkItem)="addAndRemoveItem($event)"
            (checkAllItems)="checkCurrentPageItems($event)"
          ></est-small-list-table>
          <est-pagination [paginationValues]="paginatedData"></est-pagination>
        </mat-step>
      </mat-stepper>
      <div
        class="py-3 bg-black-50 flex justify-between border border-black-100 px-6 z-10 absolute bottom-0 start-0 end-0"
      >
        @if (stepper.selectedIndex === 0) {
          <est-button (click)="location.back()" label="CANCEL" buttonStyleType="button--tertiary" buttonType="button" />
          <div class="flex gap-3">
            <est-button
              label="SAVE_AND_CLOSE"
              appliedClasses="!py-3 !px-6"
              buttonStyleType="button--secondary"
              buttonType="submit"
            >
            </est-button>
            <est-button (click)="nextStep()" label="NEXT" buttonStyleType="button--primary" buttonType="button" />
          </div>
        } @else {
          <div class="flex gap-3">
            <est-button
              (click)="stepper.previous()"
              label="BACK"
              buttonStyleType="button--tertiary"
              buttonType="button"
            />
            <est-button
              (click)="location.back()"
              label="CANCEL"
              buttonStyleType="button--secondary"
              buttonType="button"
            />
          </div>
          <est-button label="SAVE" buttonStyleType="button--primary" buttonType="submit" [isLoading]="isLoading" />
        }
      </div>
    </form>
    @if (isPageLoading) {
      <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
    }
  </div>
</div>
