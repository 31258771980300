import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const pendingHeaders: ITableHeader[] = [
  {
    type: TableHeaderType.Text,
    key: "number",
    isSortable: true,
    label: "NUMBER",
  },
  {
    type: TableHeaderType.Text,
    key: "firstName",
    isSortable: true,
    label: "FIRST_NAME",
    maxTextLength: 70,
  },
  {
    type: TableHeaderType.Text,
    key: "lastName",
    isSortable: true,
    label: "LAST_NAME",
    maxTextLength: 70,
  },
  {
    type: TableHeaderType.TextWithIcon,
    key: "organisationRole",
    isSortable: true,
    label: "SETTINGS.USERS.ROLES",
    needsTranslation: true,
  },
  {
    type: TableHeaderType.Date,
    key: "invitedOn",
    isSortable: true,
    label: "SETTINGS.USERS.INVITATION_SENT",
    includeTimeInDate: true,
  },
  {
    type: TableHeaderType.Date,
    key: "reinvitedOn",
    isSortable: true,
    label: "SETTINGS.USERS.REMINDER_SENT",
    needsTranslation: true,
  },
];
