import { inject, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { PropertyEventBookingType } from "@root/data/market/properties/enums/property-event-booking-type.enum";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ITimeslotFormGroup } from "@root/shared/timeslots/timeslot-form-group.interface";
import { formatAddress } from "@root/shared/utilities/address.utilities";
import {
  generateDropdownOptionsFromNumberWithBreaks,
  generateTranslatedDropdownOptionsFromEnum,
} from "@root/shared/utilities/dropdown-options.utilities";
import { constructAddressFormGroup } from "@root/shared/utilities/form.utilities";
import { PropertyEventType } from "@root/views/main/property/property-events/enums/property-event-type.enum";
import { PropertyEventBookingTypeTranslationMapper } from "@root/views/main/property/property-events/mappers/property-event-booking-type-translation.mapper";
import { PropertyEventTypeTranslationMapper } from "@root/views/main/property/property-events/mappers/property-event-type-translation.mapper";
import { endDateBeforeStartDateValidator } from "@root/shared/validators/date-validators";
import { PropertyEventRepetitionEndType } from "@root/data/market/properties/enums/property-event-repetition-end-type.enum";
import { RepetitionCycle } from "@root/shared/enums/repitition-cycle";

@Injectable()
export class CreatePropertyEventFormControls {
  readonly bookingTypeOptions = generateTranslatedDropdownOptionsFromEnum(
    PropertyEventBookingType,
    PropertyEventBookingTypeTranslationMapper,
  );
  readonly timeSlotsIntervalOptions = generateDropdownOptionsFromNumberWithBreaks({
    number: 60,
    breakInterval: 5,
    suffixString: "MINUTES",
  });
  readonly breakOptions: IDropdownOption[] = [
    {
      label: "NO_BREAK",
      value: 0,
    },
    ...this.timeSlotsIntervalOptions,
  ];
  readonly eventTypeOptions: IDropdownOption[] = generateTranslatedDropdownOptionsFromEnum(
    PropertyEventType,
    PropertyEventTypeTranslationMapper,
  );
  readonly formattedAddressSignal = signal<string>("");
  readonly #fb = inject(FormBuilder);
  readonly bookingToggleControl = this.#fb.control(false, {
    nonNullable: true,
  });
  readonly #nonNullableFb = inject(NonNullableFormBuilder);
  readonly form = this.#fb.group(
    {
      type: this.#fb.control<PropertyEventType | null>(null, Validators.required),
      responsibleUserId: this.#fb.control<number | null>(null, Validators.required),
      internalTitle: this.#fb.control<string | null>(null, Validators.required),
      externalTitle: this.#fb.control<string | null>(null, Validators.required),
      address: constructAddressFormGroup(this.#nonNullableFb, false),
      date: this.#fb.control<string | null>(null, Validators.required),
      startTime: this.#fb.control<string | null>(null, Validators.required),
      endTime: this.#fb.control<string | null>(null, Validators.required),
      assignedUsersIds: this.#nonNullableFb.control<number[]>([]),
      publishOnWebsite: this.#nonNullableFb.control<boolean>(false),
      publishOnEido: this.#nonNullableFb.control<boolean>(false),
      isDraft: this.#nonNullableFb.control<boolean>(false),
      selectedUnitsIds: this.#nonNullableFb.control<string[]>([]),
      booking: this.#fb.group({
        type: this.#nonNullableFb.control<PropertyEventBookingType>(PropertyEventBookingType.NoLimit),
        maximumAttendants: this.#fb.control<number | null>(null, Validators.required),
        intervalsBreak: this.#nonNullableFb.control<number>(10),
        timeSlotInterval: this.#nonNullableFb.control<number>(5),
        timeSlots: this.#nonNullableFb.array<FormGroup<ITimeslotFormGroup>>([]),
      }),
      repetition: this.#fb.group({
        endType: this.#fb.control<PropertyEventRepetitionEndType | null>(null, Validators.required),
        repetitionCycle: this.#fb.control<RepetitionCycle | null>(null, Validators.required),
        occurrences: this.#nonNullableFb.control<number>(1, Validators.required),
        endDate: this.#fb.control<string | null>(null, Validators.required),
      }),
    },
    {
      validators: [endDateBeforeStartDateValidator("startTime", "endTime")],
    },
  );

  readonly #enableBookingIfPrivate = this.form.controls.type.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe((type) => {
      if (type === PropertyEventType.Private) {
        this.bookingToggleControl.setValue(true);
        this.bookingToggleControl.disable();
        this.toggleBookingFormGroup(true);
      } else {
        this.bookingToggleControl.enable();
      }
    });

  readonly #updateFormattedAddressSignal = this.form.controls.address.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe(() => {
      this.formattedAddressSignal.set(formatAddress(this.form.controls.address));
    });

  readonly #updateBookingFormGroupOnTypeChange$ = this.form.controls.booking.controls.type.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe(() => {
      this.updateBookingFormGroup();
    });

  readonly #updateRepetitionFormGroupOnTypeChange$ = this.form.controls.repetition.controls.endType.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe((endType) => {
      if (!endType) return;
      if (endType === PropertyEventRepetitionEndType.AfterOccurences) {
        this.form.controls.repetition.controls.occurrences.enable();
        this.form.controls.repetition.controls.endDate.disable();
      } else {
        this.form.controls.repetition.controls.occurrences.disable();
        this.form.controls.repetition.controls.endDate.enable();
      }
    });

  constructor() {
    this.toggleBookingFormGroup(false);
    this.toggleRepetitionFormGroup(false);
  }

  /*
   * Enables or disables the controls inside the booking formgroup based on the booking type
   */
  updateBookingFormGroup() {
    const bookingControls = this.form.controls.booking.controls;
    const bookingTypeOptions = this.form.controls.booking.controls.type.value;
    switch (bookingTypeOptions) {
      case PropertyEventBookingType.NoLimit:
        bookingControls.maximumAttendants.disable();
        this.#disableControlsForTimeSlotsBooking();
        break;
      case PropertyEventBookingType.Limited:
        bookingControls.maximumAttendants.enable();
        this.#disableControlsForTimeSlotsBooking();
        break;
      case PropertyEventBookingType.TimeSlot:
        this.#enableControlsForTimeSlotsBooking();
        bookingControls.maximumAttendants.disable();
    }
  }

  toggleBookingFormGroup(isEnabled: boolean) {
    if (!isEnabled) {
      this.form.controls.booking.disable({ emitEvent: false });
    } else {
      this.form.controls.booking.enable();
      this.updateBookingFormGroup();
    }
  }

  toggleRepetitionFormGroup(isEnabled: boolean) {
    if (!isEnabled) {
      this.form.controls.repetition.disable();
    } else {
      this.form.controls.repetition.enable();
    }
  }

  #disableControlsForTimeSlotsBooking() {
    this.form.controls.booking.controls.intervalsBreak.disable();
    this.form.controls.booking.controls.timeSlotInterval.disable();
    this.form.controls.booking.controls.timeSlots.disable();
  }

  #enableControlsForTimeSlotsBooking() {
    this.form.controls.booking.controls.intervalsBreak.enable();
    this.form.controls.booking.controls.timeSlotInterval.enable();
    this.form.controls.booking.controls.timeSlots.enable();
  }
}
