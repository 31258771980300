<mat-form-field
  [attr.aria-label]="dropDownLabel()"
  [ngClass]="appliedClass()"
  appearance="outline"
  class="w-[21.875rem]"
  hideRequiredMarker
  id="select"
  subscriptSizing="dynamic"
>
  <div class="absolute end-0 top-0 bottom-0 flex items-center justify-center">
    <img
      [alt]="'SELECT_MENU' | translate"
      height="8"
      ngSrc="assets/images/icons/arrow-down.svg"
      priority="true"
      width="14"
    />
  </div>
  @if (dropDownLabel()) {
    <mat-label [attr.aria-labelledby]="dropDownLabel() | translate">{{ dropDownLabel() | translate }}</mat-label>
  }
  <mat-select #selector [(value)]="value" [multiple]="selectMultipleOptions()">
    @for (item of dropDownOptions(); track item.value) {
      <mat-option [value]="item.value">
        {{ translateService.instant(item.label) }}
      </mat-option>
      <mat-divider />
    }
  </mat-select>
</mat-form-field>
