import { Routes } from "@angular/router";
import { ActiveUsersComponent } from "./active-users/active-users.component";
import { PendingUsersComponent } from "./pending-users/pending-users.component";
import { ArchivedUsersComponent } from "./archived-users/archived-users.component";
import { TeamsComponent } from "./teams/teams.component";

export const usersRoutes: Routes = [
  {
    path: "pending-users",
    component: PendingUsersComponent,
    data: {
      name: "SETTINGS.USERS.PENDING_USERS",
    },
  },
  {
    path: "active-users",
    component: ActiveUsersComponent,
    data: {
      name: "SETTINGS.USERS.ACTIVATED_USERS",
    },
  },
  {
    path: "archived-users",
    component: ArchivedUsersComponent,
    data: {
      name: "SETTINGS.USERS.ARCHIVED_USERS",
    },
  },
  {
    path: "teams",
    component: TeamsComponent,
    data: {
      name: "TEAMS.NAME",
    },
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "pending-users",
  },
];
