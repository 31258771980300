<est-dialog-header label="SAVE_AS_DRAFT" tooltipLabel="SAVE_AS_DRAFT"></est-dialog-header>
<div class="p-6">
  <p class="font-light text-sm">
    {{ "EVENTS.SAVE_AS_DRAFT_CONFIRMATION" | translate }}
  </p>
</div>
<div class="dialog__footer !static">
  <est-button
    (click)="matDialog.closeAll()"
    buttonStyleType="button--tertiary"
    buttonType="button"
    label="CANCEL"
  ></est-button>
  <est-button
    (click)="componentInputs.callbackFn()"
    [isLoading]="componentInputs.loadingSignal()"
    buttonStyleType="button--primary"
    buttonType="button"
    label="SAVE_AS_DRAFT"
  >
  </est-button>
</div>
