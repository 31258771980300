import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const UNIT_FACILITIES_HEADERS: ITableHeader[] = [
  {
    label: "ACTIVE",
    key: "isActive",
    isSortable: true,
    type: TableHeaderType.Toggle,
  },
  {
    label: "PROPERTY_FACILITIES.FACILITIES.FACILITY",
    key: "name",
    isSortable: true,
    type: TableHeaderType.TextWithIcon,
  },
  {
    key: "location",
    label: "LOCATION",
    type: TableHeaderType.Text,
    needsTranslation: true,
    isSortable: true,
  },
  {
    key: "category",
    label: "CATEGORY",
    type: TableHeaderType.Text,
    isSortable: true,
  },
  {
    key: "facilityPackage",
    label: "PACKAGE",
    type: TableHeaderType.Text,
    isSortable: true,
    needsTranslation: true,
    maxTextLength: 27,
  },
  {
    key: "createdAt",
    label: "UNIT_FACILITIES.ADDED_TO_UNIT",
    type: TableHeaderType.Date,
    isSortable: true,
  },
];
