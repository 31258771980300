import { Component, effect, inject, input, OnInit, signal, WritableSignal } from "@angular/core";
import { ITableSortingFilter } from "@root/shared/interfaces/table-sorting-filter.interface";
import { residentialUnitsHeaders } from "./residential-units.headers";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { UnderDevelopmentComponent } from "@root/shared/under-development/under-development.component";
import { PropertyUnitStatusComponent } from "../property-unit-status/property-unit-status.component";
import { SubheaderComponent } from "@root/shared/subheader/subheader.component";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { IPropertyUnitStatus } from "@root/data/market/properties/models/property-unit-status.model";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { UnitsService } from "@root/data/market/units/services/units.service";
import { UnitType } from "@root/data/market/units/enums/unit-type.enum";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { unitStatusOptions } from "@root/views/main/property/property-units/residential/unit-type-dropdown-options";
import { UnitStatus } from "@root/data/market/division/enums/unit-status.enum";
import { LargeListTableComponent } from "@root/shared/large-list-table/large-list-table.component";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { Router } from "@angular/router";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { finalize } from "rxjs/internal/operators/finalize";

@Component({
  selector: "est-residential",
  standalone: true,
  templateUrl: "./residential.component.html",
  styleUrl: "./residential.component.scss",
  imports: [
    UnderDevelopmentComponent,
    PaginationComponent,
    PropertyUnitStatusComponent,
    SubheaderComponent,
    LoadingOverlayComponent,
    SearchInputComponent,
    SelectFieldComponent,
    LargeListTableComponent,
  ],
})
export class ResidentialComponent extends BasePaginatedTableWithSearchComponent<string> implements OnInit {
  headers = residentialUnitsHeaders;
  isLoading: boolean = false;
  #propertiesService = inject(PropertiesService);
  #unitService = inject(UnitsService);
  #router = inject(Router);
  unitStatusSignal = signal<UnitStatus>(UnitStatus.All);
  protected readonly unitStatusOptions = unitStatusOptions;
  propertyUnitStatus = signal<IPropertyUnitStatus>({} as IPropertyUnitStatus);
  readonly propertyId = input.required<string>();
  override actions: ITableAction<string>[] = [
    {
      labelFn: () => "UNIT.RESIDENTIAL_UNITS_ACTIONS.CHANGE_TO_AVAILABLE",
      callbackFn: (row: ILargeListTableInput<string>) => {
        this.#makeUnitAvailable(row);
      },
      isVisible: (row) => {
        if (row.header.unitStatuses === "UNIT_STATUS.NOT_ACTIVE") {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      labelFn: () => "UNIT.RESIDENTIAL_UNITS_ACTIONS.CREATE_NEW_CONTRACT",
      callbackFn: () => {},
      isVisible: (row) => this.#hideContextItem(row.header.unitStatuses),
    },
    {
      labelFn: () => "UNIT.RESIDENTIAL_UNITS_ACTIONS.START_NEW_SCREENING",
      callbackFn: () => {},
      isVisible: (row) => this.#hideContextItem(row.header.unitStatuses),
    },
    {
      labelFn: () => "UNIT.RESIDENTIAL_UNITS_ACTIONS.GO_TO_UNIT_DETAILS",
      callbackFn: (row: ILargeListTableInput<string>) => this.#goToUnitDetails(row.id),
    },
    {
      labelFn: (row: ILargeListTableInput<string>) =>
        row.isAdvertised
          ? "UNIT.RESIDENTIAL_UNITS_ACTIONS.UN_ADVERTISE_UNIT"
          : "UNIT.RESIDENTIAL_UNITS_ACTIONS.ADVERTISE_UNIT",
      callbackFn: (row: ILargeListTableInput<string>) => this.toggleAdvertisedUnits(row),
      isVisible: (row) => this.#hideContextItem(row.header.unitStatuses),
    },
    {
      labelFn: (row: ILargeListTableInput<string>) => {
        return row.isFavourite
          ? "UNIT.RESIDENTIAL_UNITS_ACTIONS.REMOVE_AS_FAVOURITE"
          : "UNIT.RESIDENTIAL_UNITS_ACTIONS.ADD_AS_FAVOURITE";
      },
      callbackFn: (row: ILargeListTableInput<string>) => this.#toggleFavouriteUnitFromContext(row),
      isVisible: (row) => this.#hideContextItem(row.header.unitStatuses),
    },
    {
      labelFn: () => "UNIT.RESIDENTIAL_UNITS_ACTIONS.ARCHIVE_UNIT",
      callbackFn: () => {},
    },
  ];
  sortingFilterSignal: WritableSignal<ITableSortingFilter> = signal({
    sortBy: "price",
    sortDescending: false,
  });

  ngOnInit() {
    this.#getPropertyUnitStatusOverview(this.propertyId());
  }

  refreshTable = () => {
    this.loadData({
      sortBy: this.sortingFilterSignal().sortBy,
      sortDescending: this.sortingFilterSignal().sortDescending,
      pageSize: this.paginatedData.pageSize(),
      pageNumber: this.paginatedData.currentPage(),
      search: this.searchSignal(),
      propertyIds: [this.propertyId()],
      unitType: UnitType.Residential,
      ...(this.unitStatusSignal() ? { mainStatus: this.unitStatusSignal() } : {}),
    });
  };
  readonly loadDataEffect$ = effect(this.refreshTable);

  override loadData(params: IPaginationSortPayload & { propertyIds: string[] } & { unitType: UnitType }): void {
    this.isTableLoading = true;
    this.#unitService.getPaginatedUnitsInProperty(params).subscribe((paginatedProperties) => {
      copyPaginatedSignalResponse(this.paginatedData, paginatedProperties);
      this.isTableLoading = false;
    });
  }

  #getPropertyUnitStatusOverview(propertyId: string) {
    this.isLoading = true;
    this.#propertiesService
      .getPropertyUnitStatusOverview(propertyId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((response: IPropertyUnitStatus) => {
        this.propertyUnitStatus.set(response);
      });
  }

  toggleFavouriteUnit(id: string) {
    this.#unitService.toggleFavoriteUnit(id).subscribe(() => {});
  }

  toggleAdvertisedUnits(row: ILargeListTableInput<string>): void {
    this.#unitService.toggleAdvertiseUnit(row.id).subscribe(() => {
      row.isAdvertised = !row.isAdvertised;
    });
  }

  #toggleFavouriteUnitFromContext(row: ILargeListTableInput<string>) {
    this.toggleFavouriteUnit(row.id);
    row.isFavourite = !row.isFavourite;
  }

  #makeUnitAvailable(row: ILargeListTableInput<string>) {
    this.isTableLoading = true;
    this.#unitService.makeUnitAvailable(row.id).subscribe(() => {
      this.refreshTable();
    });
  }

  #goToUnitDetails(unitId: string) {
    this.#router.navigate([`unit/${unitId}`]);
  }

  #hideContextItem(unitStatus: string) {
    if (unitStatus === "UNIT_STATUS.NOT_ACTIVE") {
      return false;
    } else {
      return true;
    }
  }
}
