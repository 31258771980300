import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-event-title",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./event-title.component.html",
  styleUrl: "./event-title.component.scss",
})
export class EventTitleComponent {
  totalEvents = input.required<number>();
  eventTitle = input.required<string>();
}
