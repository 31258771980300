<est-small-header
  heading="SETTINGS.COMMUNICATION.OPTIONS.SETTINGS_ON_COMMUNICATION.NAME"
  tooltip="SETTINGS.COMMUNICATION.OPTIONS.SETTINGS_ON_COMMUNICATION.NAME"
  subheading="SETTINGS.COMMUNICATION.OPTIONS.SETTINGS_ON_COMMUNICATION.DESCRIPTION"
  appliedClasses="text-xl font-medium"
></est-small-header>

@if (!isPageLoading) {
  <form [formGroup]="organisationCommunicationSettingsFG" (change)="formPatched()">
    <div class="mt-10">
      <p class="font-medium text-base pb-4">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.ALLOW_SETTING_CHANGES.NAME" | translate }}
      </p>
      <p class="text-sm font-light xl">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.ALLOW_SETTING_CHANGES.DESCRIPTION" | translate }}
      </p>

      <mat-slide-toggle
        (change)="formPatched()"
        [formControl]="getControl('allowSettingChangesOnLevelsBelow')"
        class="mt-6"
        >{{ "SETTINGS.COMMUNICATION.OPTIONS.ALLOW_SETTING_CHANGES.TOGGLE" | translate }}
      </mat-slide-toggle>
    </div>

    <div class="mt-10">
      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.NAME"
        subheading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.DESCRIPTION"
        appliedClasses="text-l font-medium"
      ></est-small-header>

      <!-- Sender Name -->
      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.SENDER_NAME.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.SENDER_NAME.NAME"
        subheading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.SENDER_NAME.DESCRIPTION"
        appliedClasses="text-sm font-light xl !mt-4"
      ></est-small-header>

      <p class="text-sm text-black-300 mt-5">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.HINT" | translate }}
      </p>

      <est-input
        [control]="getControl('defaultEmailSenderName')"
        label="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.SENDER_NAME.NAME"
        type="string"
        appliedClass="mb-6 mt-4"
        [maxNumberOfChar]="25"
      ></est-input>

      <!-- From Email -->
      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.FROM_EMAIL.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.FROM_EMAIL.NAME"
        subheading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.FROM_EMAIL.DESCRIPTION"
        appliedClasses="'text-sm font-light xl !mt-4'"
      ></est-small-header>

      <p class="text-sm text-black-300 mt-5">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.HINT" | translate }}
      </p>

      <est-postfix-email-input
        applyNegativeValuesValidation
        appliedClass="mt-5"
        label="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.FROM_EMAIL.NAME"
        [postfixControl]="getControl('defaultFromEmailAddress')"
        appliedClass="mb-6 mt-4"
        postfixDomain="@estatetool.io"
      ></est-postfix-email-input>

      <!-- Reply to Email -->
      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.REPLY_TO_EMAIL.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.REPLY_TO_EMAIL.NAME"
        subheading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.REPLY_TO_EMAIL.DESCRIPTION"
        appliedClasses="text-sm font-light xl mt-4"
      ></est-small-header>

      <p class="text-sm text-black-300 mt-5">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.HINT" | translate }}
      </p>

      <est-input
        [control]="getControl('defaultReplyToEmail')"
        label="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.REPLY_TO_EMAIL.NAME"
        type="string"
        appliedClass="mb-6 mt-4"
        type="email"
      ></est-input>

      <!-- Branding template -->
      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.BRANDING_TEMPLATE.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.BRANDING_TEMPLATE.NAME"
        subheading="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.BRANDING_TEMPLATE.DESCRIPTION"
        appliedClasses="'text-sm font-light xl mt-4'"
      ></est-small-header>

      <p class="text-sm text-black-300 mt-5">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.HINT" | translate }}
      </p>

      <est-select-field
        [dropDownOptions]="brandingTemplates"
        [selectMultipleOptions]="false"
        appliedClass="!w-[37.5rem] mb-6 mt-4 "
        dropDownLabel="SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.BRANDING_TEMPLATE.NAME"
      />
    </div>

    <div class="mt-10">
      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.NAME"
        appliedClasses="text-l font-medium"
      ></est-small-header>

      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.NOTIFICATIONS.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.NOTIFICATIONS.NAME"
        subheading="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.NOTIFICATIONS.DESCRIPTION"
        appliedClasses="text-sm font-light xl mt-4"
      ></est-small-header>

      <p class="text-sm text-black-300 mt-5">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.HINT" | translate }}
      </p>

      <mat-slide-toggle
        [formControl]="getControl('activateTextMessagesNotifications')"
        (change)="formPatched()"
        class="mt-6"
        >{{ "SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.NOTIFICATIONS.TOGGLE" | translate }}
      </mat-slide-toggle>

      <p class="text-sm text-black-300 mt-5">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.NOTIFICATIONS.NOTE" | translate }}
      </p>

      <est-small-header
        heading="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.SENDER_NAME.NAME"
        tooltip="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.SENDER_NAME.NAME"
        subheading="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.SENDER_NAME.DESCRIPTION"
        appliedClasses="text-sm font-light xl mt-4"
      ></est-small-header>

      <p class="text-sm text-black-300 mt-5">
        {{ "SETTINGS.COMMUNICATION.OPTIONS.EMAIL_DEFAULTS.HINT" | translate }}
      </p>

      <est-input
        [control]="getControl('defaultMessageSenderName')"
        appliedClass="w-full"
        label="SETTINGS.COMMUNICATION.OPTIONS.TEXT_MESSAGES.SENDER_NAME.NAME"
        type="string"
        [appliedClass]="'mt-4'"
      ></est-input>
    </div>
  </form>
} @else {
  <div class="py-32 relative">
    <est-loading-overlay></est-loading-overlay>
  </div>
}
