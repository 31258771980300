<div class="flex justify-between gap-x-2 gap-y-4 mb-6 max-2xl:flex-col">
  <form [formGroup]="generalSettingsFormControls.generalSettingsFormGroup" (change)="updateGeneralSettings()">
    <est-small-header
      heading="TIME_ZONE"
      subheading="TIME_ZONE_DESCRIPTION"
      tooltip="TIME_ZONE"
      appliedClasses="!mb-4"
    ></est-small-header>
    <est-select-field
      [control]="generalSettingsFormControls.timezoneControl"
      [dropDownOptions]="timeZoneDropdownOptions"
      [selectMultipleOptions]="false"
    ></est-select-field>

    <div class="mt-2 text-sm font-light xl:max-w-[43.75rem]">
      {{ "UNIVERSAL_TIME" | translate }} <b>{{ currentTime.toISO() | date: "yyyy-MM-dd HH:mm:ss" }}</b>
    </div>
  </form>

  @if (isLoading) {
    <est-loading-overlay appliedClasses="!bg-white/60 "></est-loading-overlay>
  }
</div>
