import { Routes } from "@angular/router";
import { UnitDetailsComponent } from "@root/views/main/units/unit-details/unit-details.component";
import { UnitOverviewComponent } from "@root/views/main/units/unit-overview/unit-overview.component";
import { UnitContractsComponent } from "@root/views/main/units/unit-contracts/unit-contracts.component";
import { UnitTextsComponent } from "@root/views/main/units/unit-texts/unit-texts.component";
import { UnitMediaComponent } from "@root/views/main/units/unit-media/unit-media.component";
import { UnitMarketingComponent } from "@root/views/main/units/unit-marketing/unit-marketing.component";
import { UnitSettingsComponent } from "@root/views/main/units/unit-settings/unit-settings.component";
import { unitDetailsRoutes } from "@root/views/main/units/unit-details/unit-details.routes";

export const unitsRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "unit-details",
  },
  {
    path: "overview",
    component: UnitOverviewComponent,
    data: {
      name: "OVERVIEW",
    },
  },
  {
    path: "contracts",
    component: UnitContractsComponent,
    data: {
      name: "UNIT.CONTRACTS",
    },
  },
  {
    path: "unit-details",
    component: UnitDetailsComponent,
    children: unitDetailsRoutes,
    data: {
      name: "UNIT.UNIT_DETAILS.NAME",
    },
  },
  {
    path: "texts",
    component: UnitTextsComponent,
    data: {
      name: "UNIT.TEXTS",
    },
  },
  {
    path: "media",
    component: UnitMediaComponent,
    data: {
      name: "UNIT.MEDIA",
    },
  },
  {
    path: "marketing",
    component: UnitMarketingComponent,
    data: {
      name: "UNIT.MARKETING",
    },
  },
  {
    path: "setting",
    component: UnitSettingsComponent,
    data: {
      name: "UNIT.SETTINGS",
    },
  },
];
