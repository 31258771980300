import { Component, inject } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { InputComponent } from "@root/shared/input/input.component";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { TranslateModule } from "@ngx-translate/core";
import { UploadFileDropZoneComponent } from "@root/shared/upload-file-drop-zone/upload-file-drop-zone.component";
import { BaseStepperComponent } from "@root/shared/abstracts/stepper/base-stepper.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IUploadMultiFilesInputs } from "@root/shared/interfaces/upload-multi-files-component-inputs.interface";
import { DomSanitizer } from "@angular/platform-browser";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { FileUploadSuccessToastComponent } from "@root/shared/file-upload-success-toast/file-upload-success-toast.component";
import { extractValidFiles } from "@root/data/market/documents/utilities/documents.utilities";
import { FilesUploadCounterComponent } from "@root/shared/Files-upload-counter/files-upload-counter.component";
import { DocumentUploadDialogComponent } from "@root/shared/document-upload-dialog/document-upload-dialog.component";

import { getControlInFormArray, markAllControlsAsTouchedAndDirty } from "@root/shared/utilities/form.utilities";
import { IUploadPropertyMedia } from "@root/views/main/property/property-marketing/components/medias/property-medias/upload-property-media.interface";
import { PropertiesService } from "@root/data/market/properties/services/properties.service";
import { getFileSize } from "@root/shared/utilities/files.utilities";
import { FileExtension } from "@root/data/market/documents/enums/file-extension.enum";
import { PropertyMediaType } from "@root/data/market/properties/enums/property-media-type.enum";

@Component({
  selector: "est-upload-media",
  standalone: true,
  imports: [
    ButtonComponent,
    DialogHeaderComponent,
    InputComponent,
    MatProgressBar,
    MatSlideToggle,
    MatStep,
    MatStepper,
    NgOptimizedImage,
    SelectFieldComponent,
    TranslateModule,
    UploadFileDropZoneComponent,
    NgClass,
  ],
  templateUrl: "./upload-media.component.html",
  styleUrl: "./upload-media.component.scss",
})
export class UploadMediaComponent extends BaseStepperComponent {
  readonly #propertyService = inject(PropertiesService);
  protected dialogRef: MatDialogRef<DocumentUploadDialogComponent> = inject(MatDialogRef);
  protected componentInputs: IUploadMultiFilesInputs & { propertyId: number } = inject(MAT_DIALOG_DATA);
  protected readonly getFileSizeInKb = getFileSize;
  sanitizer = inject(DomSanitizer);
  snackBarService = inject(SnackbarService);
  fb = inject(NonNullableFormBuilder);
  mediaTypes: IDropdownOption[] = [
    { label: PropertyMediaType.HeroMedia, value: PropertyMediaType.HeroMedia },
    { label: PropertyMediaType.ExteriorMedia, value: PropertyMediaType.ExteriorMedia },
    { label: PropertyMediaType.NeighborhoodMedia, value: PropertyMediaType.NeighborhoodMedia },
    { label: PropertyMediaType.InteriorMedia, value: PropertyMediaType.InteriorMedia },
    { label: PropertyMediaType.FloorPlan, value: PropertyMediaType.FloorPlan },
  ];
  totalTabsNo = 2;
  control = this.fb.control<IUploadPropertyMedia[]>([]);
  getControlInFormArray = getControlInFormArray;
  mediaArrayControl = this.fb.array([this.fileFormGroup()]);
  fileType = FileExtension;
  maxFileSize = 100;
  isUploading = false;
  fileFormGroup(): FormGroup {
    return this.fb.group({
      title: [""],
      mediaType: ["", Validators.required],
      seoScore: [""],
      imageQuality: [""],
      file: null,
    });
  }
  constructExitFileGroup(file: IUploadPropertyMedia): FormGroup {
    return this.fb.group({
      title: [file.title],
      mediaType: [file.mediaType, Validators.required],
      seoScore: [file.seoScore],
      imageQuality: [file.imageQuality],
      file: [file.file],
    });
  }

  closeDialog(): void {
    this.dialogRef.close(this.isUploading);
  }

  deleteFile(index: number) {
    this.control.value.splice(index, 1);
  }

  #uploadFileMedia() {
    this.#propertyService
      .uploadPropertyMedia(this.mediaArrayControl.value as IUploadPropertyMedia[], this.componentInputs.propertyId)
      .subscribe({ complete: () => this.#finishUploading(), error: () => (this.isUploading = false) });
  }

  #finishUploading(): void {
    this.isUploading = false;
    this.snackBarService.closeCurrentSnackbar();
    this.closeDialog();
    this.snackBarService.open(
      SnackbarType.Success,
      "SNACKBAR.SUCCESS.DESCRIPTION",
      FileUploadSuccessToastComponent,
      {
        totalFiles: this.control.value.length,
      },
      5000,
    );
  }

  #createDocumentFrom(file: File): IUploadPropertyMedia {
    return {
      title: file.name,
      mediaType: "",
      file: file,
      imageQuality: "",
      seoScore: "",
    };
  }

  #updateControlValueWithFiles(files: FileList): void {
    const newFile = Array.from(files).map((file) => this.#createDocumentFrom(file));
    const updatedValue = [...this.control.value, ...newFile];
    this.control.setValue(updatedValue);
  }

  #updateFileArrayControl(): void {
    this.control.value.forEach((document) => {
      this.mediaArrayControl.push(this.constructExitFileGroup(document));
    });
  }

  updateControl(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const files = element.files;
    if (files && files.length > 0) {
      if (extractValidFiles(files, this.maxFileSize).length === files.length) {
        this.#clearFileArrayControl();
        this.#updateControlValueWithFiles(files);
        this.#updateFileArrayControl();
      } else {
        this.snackBarService.open(SnackbarType.Error, "SNACKBAR.ERROR.MAX_FILE_REACHED");
      }
    }
    element.value = "";
  }

  #clearFileArrayControl(): void {
    this.mediaArrayControl.clear();
  }
  getFileUrl(file: File) {
    return this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
  }

  getPosterUrl(file: File) {
    const posterUrl = file.name.replace(/\.[^/.]+$/, "") + ".jpg";
    const newFile = new File([], posterUrl);
    return this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(newFile));
  }
  submitForm(): void {
    if (!this.mediaArrayControl.valid) {
      markAllControlsAsTouchedAndDirty(this.mediaArrayControl);
      return;
    }
    this.isUploading = true;
    this.#uploadFileMedia();
    this.snackBarService.open(
      SnackbarType.Loading,
      "SNACKBAR.LOADING.DESCRIPTION",
      FilesUploadCounterComponent,
      {
        totalFiles: this.control.value.length,
      },
      Infinity,
    );
  }
}
