<form [formGroup]="duplicateFromGroup" (ngSubmit)="duplicatePackage()">
  <est-dialog-header
    appliedClasses="!sticky top-0 z-10"
    label="PACKAGES.DUPLICATE_PACKAGE.NAME"
    tooltipLabel="PACKAGES.DUPLICATE_PACKAGE.NAME"
  ></est-dialog-header>

  <div class="max-h-[calc(100dvh-14rem)]">
    <div class="p-6">
      <p class="text-base font-medium pb-2">{{ "PACKAGES.DUPLICATE_PACKAGE.NEW_PACKAGE" | translate }}</p>
      <p class="text-sm font-light">
        {{ "PACKAGES.FACILITY_PACKAGES.DUPLICATE_PACKAGE.TO_DUPLICATE_A_FACILITY" | translate }}
      </p>

      <est-small-header
        heading="PACKAGES.DUPLICATE_PACKAGE.ORIGINAL_PACKAGE_NAME"
        tooltip="PACKAGES.DUPLICATE_PACKAGE.ORIGINAL_PACKAGE_NAME"
        appliedClasses="text-sm pt-6"
      ></est-small-header>
      <est-input
        appliedClass="w-[37.5rem]"
        label="PACKAGES.DUPLICATE_PACKAGE.ORIGINAL_PACKAGE_NAME"
        [control]="originalName"
      ></est-input>

      <est-small-header
        heading="PROPERTY_FACILITIES.INTERNAL_TITLE"
        tooltip="PROPERTY_FACILITIES.INTERNAL_TITLE"
        appliedClasses="text-sm pt-6"
      ></est-small-header>
      <est-input
        appliedClass="w-[37.5rem]"
        label="PROPERTY_FACILITIES.INTERNAL_TITLE"
        [control]="internalTitleControl"
      ></est-input>

      <est-small-header
        heading="PACKAGES.DUPLICATE_PACKAGE.DUPLICATION_OPTION"
        tooltip="PACKAGES.DUPLICATE_PACKAGE.DUPLICATION_OPTION"
        appliedClasses="text-sm pt-6"
      ></est-small-header>
      <est-select-field
        [dropDownOptions]="duplicationOptions"
        dropDownLabel="PACKAGES.DUPLICATE_PACKAGE.DUPLICATION_OPTION"
        [selectMultipleOptions]="false"
        appliedClass="w-[37.5rem]"
        [control]="duplicationOptionControl"
      ></est-select-field>
      <hr class="absolute start-0 end-0 border-black-100 my-6" />
      <div class="mt-12 mb-6 flex justify-between">
        <div>
          <p class="text-base font-medium">{{ "PACKAGES.DUPLICATE_PACKAGE.SELECT_PROPERTY" | translate }}</p>
          <p class="text-sm font-light w-[51.75rem]">
            {{ "PACKAGES.DUPLICATE_PACKAGE.IT_IS_ONLY_POSSIBLE" | translate }}
          </p>
        </div>
        <est-search-input [searchSignal]="searchSignal"></est-search-input>
      </div>
      <est-small-list-table
        [data]="paginatedData.results()"
        [headers]="headers"
        [isLoading]="isTableLoading"
        [sortingFilterSignal]="sortingFilterSignal"
        [checkboxControlMapper]="facilityIdsMapper"
      />
      <est-pagination [paginationValues]="paginatedData"></est-pagination>
      @if (isSelectedPropertyError) {
        <mat-error class="!text-error-200 text-md font-normal !flex items-center pt-1">
          <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
          {{ "LINKED_UNITS.SELECT_PROPERTY" | translate }}
        </mat-error>
      }
    </div>
    <div class="py-3 bg-black-50 flex border border-black-100 px-6 sticky bottom-0 z-10 justify-between">
      <est-button
        label="CANCEL"
        appliedClasses="!py-3 !px-6"
        (click)="closeDialog()"
        buttonType="button"
        buttonStyleType="button--tertiary"
      >
      </est-button>

      <est-button
        label="DUPLICATE"
        appliedClasses="!py-3 !px-6"
        buttonStyleType="button--primary"
        buttonType="submit"
        [isLoading]="isLoading"
      >
      </est-button>
    </div>
  </div>
  @if (isPackageDetailsLoading) {
    <est-loading-overlay></est-loading-overlay>
  }
</form>
