import { Component, DestroyRef, inject, input, OnInit, signal } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PropertiesFacilitiesPackagesService } from "@root/data/market/properties/services/properties-facilities-packages.service";
import { ButtonComponent } from "@root/shared/button/button.component";
import { FacilityPackagesCardComponent } from "@root/shared/facility-packages-card/facility-packages-card.component";
import { ICardAction } from "@root/shared/interfaces/card-Action.interface";
import { IFacilityCard } from "@root/shared/interfaces/facility-card.interface";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { CreateLinkDialogComponent } from "@root/shared/create-link-dialog/create-link-dialog.component";
import { EditLinkDialogComponent } from "@root/shared/edit-link-dialog/edit-link-dialog.component";
import { LinkingSource } from "@root/shared/enums/linking-source.enum";
import { SourceLevel } from "@root/shared/enums/source-level.enum";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { DuplicateDialogComponent } from "@root/views/main/property/property-marketing/components/facilities/facility-package/duplicate-dialog/duplicate-dialog.component";
import { UpdateFacilityPackageListCommunicationService } from "@root/views/main/property/property-marketing/components/facilities/facility-package/update-facility-package-list-communication.service";

@Component({
  selector: "est-facility-package",
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink,
    FacilityPackagesCardComponent,
    TooltipComponent,
    TranslateModule,
    SmallHeaderComponent,
    LoadingOverlayComponent,
  ],
  templateUrl: "./facility-package.component.html",
  styleUrl: "./facility-package.component.scss",
})
export class FacilityPackageComponent implements OnInit {
  readonly #router = inject(Router);
  readonly #dialogService = inject(DialogService);
  readonly #matDialog = inject(MatDialog);
  readonly #translate = inject(TranslateService);
  propertiesFacilitiesPackagesService = inject(PropertiesFacilitiesPackagesService);
  #isDeletingPackage = signal(false);
  propertyId = input.required<number>();

  updateFacilityPackageListCommunicationService = inject(UpdateFacilityPackageListCommunicationService);
  readonly #destroyRef = inject(DestroyRef);
  facilityPackages!: IFacilityCard[];
  linkingSource: LinkingSource = LinkingSource.FACILITY_PACKAGE;
  isPageLoading: boolean = false;
  actions: ICardAction[] = [
    {
      label: "PACKAGES.EDIT_PACKAGE",
      callbackFn: (id: number) => this.routeToEditPackage(id),
    },
    {
      label: "PACKAGES.CHANGE_LINK",
      callbackFn: (id: number) => this.openChangeLinkDialog(id),
      isVisible: (id: number) => this.isLinkedCreated(id) && this.hasFacilities(id),
    },
    {
      label: "PACKAGES.CREATE_LINK",
      callbackFn: (id: number) => this.openCreateLinkDialog(id),
      isVisible: (id: number) => !this.isLinkedCreated(id) && this.hasFacilities(id),
    },
    {
      label: "PACKAGES.DUPLICATE_PACKAGE.NAME",
      callbackFn: (id: number) => this.openDuplicateDialog(id),
    },
    {
      label: "PACKAGES.DELETE_PACKAGE",
      callbackFn: (id: number, packageName: string, numberOfLinkedUnits: number) =>
        this.openDeleteDocumentDialog(id, packageName, numberOfLinkedUnits),
    },
  ];

  ngOnInit() {
    this.getFacilitiesPackageList();
    this.subscribeToUpdateList$();
  }

  getFacilitiesPackageList() {
    this.isPageLoading = true;
    this.propertiesFacilitiesPackagesService.getFacilitiesPackageList(this.propertyId()).subscribe({
      next: (data) => {
        this.isPageLoading = false;
        this.facilityPackages = data;
      },
      error: () => {
        this.isPageLoading = false;
      },
    });
  }

  isLinkedCreated(id: number) {
    const facility = this.facilityPackages.find((facility) => facility.id === id);
    return facility ? facility.isLinked : false;
  }

  hasFacilities(id: number) {
    const facilityPackage = this.facilityPackages.find((facility) => facility.id === id);
    return facilityPackage ? facilityPackage.iconUrls.length > 0 : false;
  }

  routeToEditPackage(id: number) {
    this.#router.navigate(["/edit-package-facility", id]);
  }

  routeToCreatePackage() {
    this.#router.navigate(["/create-new-package-facility", this.propertyId()]);
  }

  openDeleteDocumentDialog(id: number, packageName?: string, numberOfLinkedUnits?: number): void {
    this.#dialogService.open(
      {
        title: "PACKAGES.DELETE_PACKAGE",
        callBack: () => this.#deletePackage(id),
        submitLabel: "IM_SURE",
        isInputIncluded: false,
        descriptions: [
          "PACKAGES.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_PACKAGE",
          this.getPackageName(packageName),
          this.getLinkedUnits(numberOfLinkedUnits),
          "PACKAGES.THEREFORE_ONLY_DELETE",
        ],
        isSubmitLoading: this.#isDeletingPackage,
        additionalData: { id: id, packageName: packageName },
      },
      "confirmation",
    );
  }

  #deletePackage(id: number): void {
    this.#isDeletingPackage.set(true);
    this.propertiesFacilitiesPackagesService.deletePackage(id).subscribe({
      next: () => {
        this.#isDeletingPackage.set(false);
        this.#matDialog.closeAll();
        this.getFacilitiesPackageList();
      },
      error: () => {
        this.#isDeletingPackage.set(false);
        this.#matDialog.closeAll();
      },
    });
  }

  getPackageName(packageName?: string) {
    return this.#translate.instant("PACKAGES.PACKAGE_NAME", {
      packageName: packageName,
    });
  }

  getLinkedUnits(numberOfLinkedUnits?: number) {
    return this.#translate.instant("PACKAGES.FACILITY_PACKAGES.IS_LINKED", {
      numberOfLinkedUnits: numberOfLinkedUnits,
    });
  }

  openCreateLinkDialog(id: number) {
    this.#matDialog.open(CreateLinkDialogComponent, {
      width: "77dvw",
      data: {
        propertyId: this.propertyId(),
        packageId: id,
        stepName: SourceLevel.Unit,
        linkingSource: this.linkingSource,
      },
    });
  }

  openChangeLinkDialog(id: number) {
    this.#matDialog.open(EditLinkDialogComponent, {
      width: "77dvw",
      data: {
        propertyId: this.propertyId(),
        packageId: id,
        stepName: SourceLevel.Unit,
        linkingSource: this.linkingSource,
      },
    });
  }

  openDuplicateDialog(packageId: number) {
    this.#matDialog.open(DuplicateDialogComponent, {
      data: { packageId: packageId },
      width: "75dvw",
    });
  }

  subscribeToUpdateList$() {
    this.updateFacilityPackageListCommunicationService.reloadFacilityPackageListPage$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.getFacilitiesPackageList();
      });
  }
}
