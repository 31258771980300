export enum RoomType {
  Bedroom = "Bedroom",
  LivingRoom = "LivingRoom",
  AdditionalRoom = "AdditionalRoom",
  Restroom = "Restroom",
  Toilet = "Toilet",
  Bathroom = "Bathroom",
  InternalStorageRoom = "InternalStorageRoom",
  Garden = "Garden",
  Terrace = "Terrace",
  Balcony = "Balcony",
}
