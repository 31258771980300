import { Routes } from "@angular/router";
import { companiesRoutes } from "@root/views/main/companies/companies.routes";
import { CompanyComponent } from "@root/views/main/company/company.component";
import { companyRoutes } from "@root/views/main/company/company.routes";
import { propertiesRoutes } from "@root/views/main/properties/properties.routes";
import { CreatePropertyEventComponent } from "@root/views/main/property/property-events/create-property-event/create-property-event.component";
import { EditPropertyEventComponent } from "@root/views/main/property/property-events/edit-property-event/edit-property-event.component";
import { CreateNewPropertyFacilityPackageComponent } from "@root/views/main/property/property-marketing/components/facilities/facility-package/create-new-property-facility-package/create-new-property-facility-package.component";
import { EditPropertyFacilityPackageComponent } from "@root/views/main/property/property-marketing/components/facilities/facility-package/edit-property-facility-package/edit-property-facility-package.component";
import { CreatePropertyMediaPackageComponent } from "@root/views/main/property/property-marketing/components/medias/property-media-packages/create-property-media-package/create-property-media-package.component";
import { EditPropertyMediaPackageComponent } from "@root/views/main/property/property-marketing/components/medias/property-media-packages/edit-property-media-package/edit-property-media-package.component";
import { CreatePropertyTextFieldsComponent } from "@root/views/main/property/property-marketing/components/texts/property-texts/create-property-text-fields/create-property-text-fields.component";
import { EditPropertyTextFieldsComponent } from "@root/views/main/property/property-marketing/components/texts/property-texts/edit-property-text-fields/edit-property-text-fields.component";
import { PropertyComponent } from "@root/views/main/property/property.component";
import { propertyRoutes } from "@root/views/main/property/property.routes";
import { ToolboxComponent } from "@root/views/main/toolbox/toolbox.component";
import { toolboxRoutes } from "@root/views/main/toolbox/toolbox.routes";
import { UnitsComponent } from "@root/views/main/units/units.component";
import { unitsRoutes } from "@root/views/main/units/units.routes";
import { UserRole } from "../../data/market/users/enums/user-role.enum";
import { CompaniesComponent } from "./companies/companies.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DivisionComponent } from "./division/division.component";
import { divisionRoutes } from "./division/division.routes";
import { OrganizationComponent } from "./organization/organization.component";
import { organizationRoutes } from "./organization/organization.routes";
import { PropertiesComponent } from "./properties/properties.component";
import { EventDetailsComponent } from "./property/property-events/event-details/event-details.component";

export const mainRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "organization",
    component: OrganizationComponent,
    children: organizationRoutes,
    data: {
      name: "ORGANIZATION",
      authorizedRole: UserRole.SystemAdmin,
    },
  },
  {
    path: "division",
    component: DivisionComponent,
    children: divisionRoutes,
    data: {
      name: "DIVISIONS.DIVISION",
      authorizedRole: UserRole.DivisionAdmin,
    },
  },
  {
    path: "companies",
    component: CompaniesComponent,
    children: companiesRoutes,
    data: {
      name: "COMPANIES.NAME",
      authorizedRole: UserRole.GenericUser,
    },
  },
  {
    path: "properties",
    component: PropertiesComponent,
    children: propertiesRoutes,
    data: {
      name: "PROPERTIES.NAME",
      authorizedRole: UserRole.GenericUser,
    },
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    data: {
      name: "DASHBOARD",
    },
  },
  {
    path: "toolbox",
    component: ToolboxComponent,
    children: toolboxRoutes,
    data: {
      name: "TOOLBOX.NAME",
      authorizedRole: UserRole.DivisionAdmin,
    },
  },
  {
    path: "unit/:unitId",
    component: UnitsComponent,
    children: unitsRoutes,
    data: {
      name: "UNIT.NAME",
    },
  },
  {
    path: "properties/:propertyId",
    component: PropertyComponent,
    children: propertyRoutes,
    data: {
      name: "PROPERTY.NAME",
    },
  },
  {
    path: "companies/:companyId",
    component: CompanyComponent,
    children: companyRoutes,
    data: {
      name: "COMPANIES.COMPANY",
    },
  },
  {
    path: "create-new-package-facility/:propertyId",
    component: CreateNewPropertyFacilityPackageComponent,
    data: {
      name: "PROPERTY_FACILITIES.CREATE_NEW_PACKAGE",
    },
  },
  {
    path: "edit-package-facility/:packageId",
    component: EditPropertyFacilityPackageComponent,
    data: {
      name: "PROPERTY_FACILITIES.EDIT_PACKAGE",
    },
  },
  {
    path: "properties/:propertyId/marketing/medias/media-package/create",
    component: CreatePropertyMediaPackageComponent,
    data: {
      name: "PROPERTY_FACILITIES.CREATE_NEW_PACKAGE",
    },
  },
  {
    path: "properties/:propertyId/marketing/medias/media-package/edit/:packageId",
    component: EditPropertyMediaPackageComponent,
    data: {
      name: "PROPERTY_FACILITIES.EDIT_PACKAGE",
    },
  },
  {
    path: "properties/:propertyId/marketing/texts/property-texts/create",
    component: CreatePropertyTextFieldsComponent,
    data: {
      name: "PROPERTY_TEXTS.CREATE_NEW_TEXT_FIELD",
    },
  },
  {
    path: "properties/:propertyId/marketing/texts/text-packages/create",
    component: CreatePropertyTextFieldsComponent,
    data: {
      name: "PROPERTY_TEXTS.CREATE_NEW_PACKAGE",
    },
  },
  {
    path: "properties/:propertyId/marketing/texts/property-texts/edit/:textFieldId",
    component: EditPropertyTextFieldsComponent,
    data: {
      name: "PROPERTY_TEXTS.EDIT_NEW_TEXT_FIELD",
    },
  },
  {
    path: "properties/:propertyId/marketing/texts/text-packages/edit/:textFieldId",
    component: EditPropertyTextFieldsComponent,
    data: {
      name: "PROPERTY_TEXTS.EDIT_NEW_PACKAGE",
    },
  },
  {
    path: "properties/:propertyId/events/create",
    component: CreatePropertyEventComponent,
    data: {
      name: "EVENTS.CREATE_NEW_EVENT_ON_PROPERTY",
    },
  },
  {
    path: "properties/:propertyId/events/edit/:eventId",
    component: EditPropertyEventComponent,
    data: {
      name: "EVENTS.EDIT_EVENT_ON_PROPERTY",
    },
  },
  {
    path: "properties/:propertyId/events/:eventId/event-details",
    component: EventDetailsComponent,
    data: {
      name: "EVENTS.DETAILS.PAGE_TITLE",
    },
  },
];
