import { Component, inject, OnInit, signal, WritableSignal } from "@angular/core";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { NgOptimizedImage } from "@angular/common";
import { UserLoginStatus } from "@root/data/market/users/enums/user-login-status.enum";
import { UserLoginStatusNameMapper } from "@root/data/market/users/mappers/user-login-status-name.mapper";
import { activeUsersHeaders } from "./active-users.headers";
import { TranslateModule } from "@ngx-translate/core";
import { MatMenuModule } from "@angular/material/menu";
import { BaseUsersComponent } from "@root/views/main/organization/organization-settings/abstracts/base-users/base-users.component";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { UserRole } from "@root/data/market/users/enums/user-role.enum";
import { EditSystemAdminComponent } from "@root/views/main/organization/organization-settings/components/users/edit-system-admin/edit-system-admin.component";
import { EditUserComponent } from "@root/views/main/organization/organization-settings/components/users/edit-user/edit-user.component";
import { PropertyAccessComponent } from "@root/views/main/organization/organization-settings/components/users/active-users/property-access/property-access.component";
import { CreateUserComponent } from "@root/views/main/organization/organization-settings/components/users/create-user/create-user.component";
import { CreateSystemAdminComponent } from "@root/views/main/organization/organization-settings/components/users/create-system-admin/create-system-admin.component";
import { UserStatus } from "@root/data/market/users/enums/user-status.enum";
import { HandlingUserArchivingService } from "../services/handling-user-archiving.service";

@Component({
  selector: "est-active-users",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    SmallListTableComponent,
    SearchInputComponent,
    PaginationComponent,
    SelectSearchFieldComponent,
    SelectFieldComponent,
    NgOptimizedImage,
    TranslateModule,
    MatMenuModule,
  ],
  templateUrl: "./active-users.component.html",
  styleUrl: "./active-users.component.scss",
})
export class ActiveUsersComponent extends BaseUsersComponent implements OnInit {
  readonly headers = activeUsersHeaders;
  confirmationDialog = inject(DialogService);
  #handlingArchiveUser = inject(HandlingUserArchivingService);
  isBlockUserLoading: WritableSignal<boolean> = signal(false);
  isReactivateUserLoading: WritableSignal<boolean> = signal(false);
  loginStatusPredefinedOptions: IDropdownOption[] = [
    {
      label: "ALL",
      value: "",
    },
  ];
  loginStatusOptions: IDropdownOption[] = [
    {
      label: UserLoginStatusNameMapper.get(UserLoginStatus.Active)!,
      value: UserLoginStatus.Active,
    },
    {
      label: UserLoginStatusNameMapper.get(UserLoginStatus.Blocked)!,
      value: UserLoginStatus.Blocked,
    },
  ];

  override actions: ITableAction[] = [
    {
      callbackFn: (row: ISmallListTableInput) => this.openSystemAdminEditDialog(row),
      labelFn: () => "SETTINGS.USERS.USER_DETAILS",
      isVisible: (row: ISmallListTableInput) => row["role"] === UserRole.SystemAdmin,
    },
    {
      callbackFn: (row: ISmallListTableInput) => this.openEditUserDialog(row),
      labelFn: () => "SETTINGS.USERS.USER_DETAILS",
      isVisible: (row: ISmallListTableInput) => row["role"] !== UserRole.SystemAdmin,
    },
    {
      callbackFn: (item: ISmallListTableInput) => this.openPropertyAccessModal(item.id),
      labelFn: () => "PROPERTIES.PROPERTY_ACCESS.NAME",
      isVisible: (row: ISmallListTableInput) => row["role"] !== UserRole.SystemAdmin,
    },
    {
      labelFn: () => "SETTINGS.USERS.BLOCK_ACCESS",
      callbackFn: (row: ISmallListTableInput) => this.openConfirmationBlockUser(row),
      isVisible: (row: ISmallListTableInput) =>
        row["loginStatus"] === UserLoginStatusNameMapper.get(UserLoginStatus.Active),
    },
    {
      labelFn: () => "SETTINGS.USERS.REACTIVATE_ACCESS",
      callbackFn: (row: ISmallListTableInput) => this.openConfirmationUnblockUser(row),
      isVisible: (row: ISmallListTableInput) =>
        row["loginStatus"] === UserLoginStatusNameMapper.get(UserLoginStatus.Blocked),
    },
    {
      callbackFn: (item: ISmallListTableInput) => this.#handlingArchiveUser.archiveUser(item as any),
      labelFn: () => "SETTINGS.USERS.ARCHIVE_USER",
    },
  ];

  openCreateUserDialog() {
    this.dialog.open(CreateUserComponent, {
      minWidth: "75dvw",
    });
  }

  openCreateSystemAdminDialog() {
    this.dialog.open(CreateSystemAdminComponent, {
      minWidth: "75dvw",
    });
  }

  getPaginatedSmallTableInputUsers(params: IPaginationSortPayload) {
    return this.usersService.getPaginatedSmallTableInputUsers(UserStatus.Active, params);
  }

  openConfirmationBlockUser(row: ISmallListTableInput) {
    this.confirmationDialog.open(
      {
        tooltipLabel: "SETTINGS.USERS.BLOCK_ACCESS",
        title: "SETTINGS.USERS.BLOCK_ACCESS",
        callBack: () => this.blockUser(row.id),
        submitLabel: "CONFIRM",
        isInputIncluded: false,
        descriptions: [
          "SETTINGS.USERS.BLOCK_CONFIRMATION.BLOCK_ACCESS",
          this.#handlingArchiveUser.userName(row),
          "SETTINGS.USERS.BLOCK_CONFIRMATION.ACCESS_CAN_BE_REACTIVATE_LATER",
        ],
        isSubmitLoading: this.isBlockUserLoading,
      },
      "confirmation",
    );
  }

  openConfirmationUnblockUser(row: ISmallListTableInput) {
    this.confirmationDialog.open(
      {
        tooltipLabel: "SETTINGS.USERS.REACTIVATE_CONFIRMATION.REACTIVATE_ACCESS",
        title: "SETTINGS.USERS.BLOCK_ACCESS",
        callBack: () => this.unblockUser(row.id),
        submitLabel: "CONFIRM",
        isInputIncluded: false,
        descriptions: [
          "SETTINGS.USERS.REACTIVATE_CONFIRMATION.DO_YOU_WANT_REACTIVATE",
          this.#handlingArchiveUser.userName(row),
          "SETTINGS.USERS.REACTIVATE_CONFIRMATION.BY_CONFIRM",
        ],
        isSubmitLoading: this.isReactivateUserLoading,
      },
      "confirmation",
    );
  }

  blockUser(id: number) {
    this.isBlockUserLoading.set(true);
    this.usersService.blockUser(id).subscribe(() => {
      this.isBlockUserLoading.set(false);
      this.updateUserListService.reloadUserListPage$.next();
      this.dialog.closeAll();
    });
  }

  unblockUser(id: number) {
    this.isReactivateUserLoading.set(true);
    this.usersService.unblockUser(id).subscribe(() => {
      this.isReactivateUserLoading.set(false);
      this.updateUserListService.reloadUserListPage$.next();
      this.dialog.closeAll();
    });
  }

  openPropertyAccessModal(id: number) {
    this.dialog.open(PropertyAccessComponent, {
      data: {
        userId: id,
      },
      maxWidth: "80dvw",
      maxHeight: "95dvh",
    });
  }

  ngOnInit() {
    this.subscribeToUpdateList$();
  }
  openEditUserDialog(user: ISmallListTableInput) {
    this.dialog.open(EditUserComponent, {
      data: {
        userId: user.id,
      },
    });
  }
  openSystemAdminEditDialog(user: ISmallListTableInput) {
    this.dialog.open(EditSystemAdminComponent, {
      data: {
        userId: user.id,
      },
    });
  }
}
