import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const activeUsersHeaders: ITableHeader[] = [
  {
    type: TableHeaderType.Text,
    key: "number",
    isSortable: true,
    label: "NUMBER",
  },
  {
    type: TableHeaderType.Text,
    key: "firstName",
    isSortable: true,
    label: "FIRST_NAME",
    maxTextLength: 70,
  },
  {
    type: TableHeaderType.Text,
    key: "lastName",
    isSortable: true,
    label: "LAST_NAME",
    maxTextLength: 70,
  },
  {
    type: TableHeaderType.TextWithIcon,
    key: "organisationRole",
    isSortable: true,
    label: "SETTINGS.USERS.ROLES",
    needsTranslation: true,
  },
  {
    type: TableHeaderType.Text,
    key: "loginStatus",
    isSortable: true,
    label: "SETTINGS.USERS.LOGIN_STATUS",
    needsTranslation: true,
  },
  {
    type: TableHeaderType.Date,
    key: "joinedOn",
    isSortable: true,
    label: "SETTINGS.USERS.JOINED",
    needsTranslation: true,
  },
  {
    type: TableHeaderType.Date,
    key: "lastActive",
    isSortable: true,
    label: "SETTINGS.USERS.LAST_ACTIVE",
    needsTranslation: true,
  },
];
