import { Component, inject } from "@angular/core";
import { RouterLink, RouterOutlet } from "@angular/router";
import { LayoutComponent } from "@root/core/layout/layout.component";
import { NavigationTabsComponent } from "@root/shared/navigation-tabs/navigation-tabs.component";
import { INavigationTab } from "@root/shared/navigation-tabs/navigation-tab.interface";
import { AuthService } from "@root/data/market/auth/services/auth.service";

@Component({
  selector: "est-organization",
  standalone: true,
  imports: [RouterOutlet, LayoutComponent, RouterLink, NavigationTabsComponent],
  templateUrl: "./organization.component.html",
  styleUrl: "./organization.component.scss",
})
export class OrganizationComponent {
  authService = inject(AuthService);
  protected tabs: INavigationTab[] = [
    {
      label: "DETAILS.NAME",
      routerLink: "details",
    },
    {
      label: "CUSTOMISATION.NAME",
      routerLink: "customisation",
    },
    {
      label: "MAINTENANCE.NAME",
      routerLink: "maintenance",
    },
    {
      label: "DIVISIONS.NAME",
      routerLink: "divisions",
    },
    {
      label: "DOCUMENTS.NAME",
      routerLink: "documents",
    },
    {
      label: "SYSTEM_MODULES.NAME",
      routerLink: "system-modules",
    },
    {
      label: "SETTINGS.NAME",
      routerLink: "settings",
    },
  ];
}
