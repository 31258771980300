import { Component, EventEmitter, input, Output } from "@angular/core";
import { DecisionButtonListComponent } from "@root/shared/decision-button-list/decision-button-list.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { EndUserVisibilityInputComponent } from "@root/shared/end-user-visibility-input/end-user-visibility-input.component";
import { NullableBoolean } from "@root/shared/type/nullable-boolean.type";

@Component({
  selector: "est-accessibility-feature-inputs",
  standalone: true,
  imports: [
    DecisionButtonListComponent,
    MatSlideToggle,
    TooltipComponent,
    ReactiveFormsModule,
    TranslateModule,
    EndUserVisibilityInputComponent,
  ],
  templateUrl: "./accessibility-feature-inputs.component.html",
  styleUrl: "./accessibility-feature-inputs.component.scss",
})
export class AccessibilityFeatureInputsComponent {
  handicapAccessibleControl = input.required<FormControl<boolean>>();
  handicapAccessibleVisibleControl = input.required<FormControl<boolean>>();
  stepFreeEntranceControl = input.required<FormControl<NullableBoolean>>();
  stepFreeEntrancePathControl = input.required<FormControl<NullableBoolean>>();
  wideEntranceControl = input.required<FormControl<NullableBoolean>>();
  parkingAccessibleControl = input.required<FormControl<NullableBoolean>>();
  stepFreeBedroomEntranceControl = input.required<FormControl<NullableBoolean>>();
  wideBedroomEntranceControl = input.required<FormControl<NullableBoolean>>();
  restroomGrabBarControl = input.required<FormControl<NullableBoolean>>();
  showerChairControl = input.required<FormControl<NullableBoolean>>();
  stepFreeShowerControl = input.required<FormControl<NullableBoolean>>();
  stepFreeBathroomEntranceControl = input.required<FormControl<NullableBoolean>>();
  wideBathroomEntranceControl = input.required<FormControl<NullableBoolean>>();
  bathroomGrabBarControl = input.required<FormControl<NullableBoolean>>();
  @Output() valuesChanged = new EventEmitter<any>();
}
