import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const propertiesHeaders: ITableHeader[] = [
  {
    key: "name",
    label: "PROPERTIES.PROPERTY",
    isSortable: true,
    type: TableHeaderType.ImageWithHeaderAndSubHeader,
    maxTextLength: 70,
  },
  {
    key: "propertyType",
    label: "TYPE",
    isSortable: true,
    type: TableHeaderType.Text,
    needsTranslation: true,
  },
  {
    key: "units",
    label: "UNITS",
    isSortable: true,
    type: TableHeaderType.Text,
  },
  {
    key: "availability",
    label: "AVAILABILITY",
    isSortable: true,
    type: TableHeaderType.Text,
    partialTranslationKey: "UNITS",
  },
  {
    key: "idleness",
    label: "IDLENESS",
    isSortable: true,
    type: TableHeaderType.Text,
    partialTranslationKey: "UNITS",
  },
  {
    key: "unitsOverview",
    label: "UNITS_OVERVIEW",
    isSortable: false,
    type: TableHeaderType.OverViewStatus,
  },
];
