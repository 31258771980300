import { Component, OnInit, inject, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltip } from "@angular/material/tooltip";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { ITableAction } from "@root/shared/interfaces/table-action.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { BaseUsersComponent } from "../../../abstracts/base-users/base-users.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { pendingHeaders } from "@root/views/main/organization/organization-settings/components/users/pending-users/pending-user.header";
import { EditUserComponent } from "@root/views/main/organization/organization-settings/components/users/edit-user/edit-user.component";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { UserRole } from "@root/data/market/users/enums/user-role.enum";
import { EditSystemAdminComponent } from "@root/views/main/organization/organization-settings/components/users/edit-system-admin/edit-system-admin.component";
import { UserStatus } from "@root/data/market/users/enums/user-status.enum";
import { DialogService } from "@root/shared/ui-services/small-dialog.service";
import { UsersService } from "@root/data/market/users/services/users.service";
import { HandlingUserArchivingService } from "../services/handling-user-archiving.service";

@Component({
  selector: "est-pending-users",
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltip,
    SearchInputComponent,
    SmallHeaderComponent,
    SmallListTableComponent,
    PaginationComponent,
    SelectSearchFieldComponent,
  ],
  templateUrl: "./pending-users.component.html",
  styleUrl: "./pending-users.component.scss",
})
export class PendingUsersComponent extends BaseUsersComponent implements OnInit {
  headers: ITableHeader[] = pendingHeaders;
  #dialogService = inject(DialogService);
  #userService = inject(UsersService);
  #handlingArchiveUser = inject(HandlingUserArchivingService);
  #isResendInviteLoading = signal(false);
  override actions: ITableAction[] = [
    {
      callbackFn: (user: ISmallListTableInput) => this.openResendInvitationDialog(user),
      labelFn: () => "SETTINGS.USERS.RESEND_INVITATION.TITLE",
    },
    {
      callbackFn: (user: ISmallListTableInput) => this.openSystemAdminEditDialog(user),
      labelFn: () => "SETTINGS.USERS.EDIT_USER",
      isVisible: (row: ISmallListTableInput) => row["role"] === UserRole.SystemAdmin,
    },
    {
      callbackFn: (user: ISmallListTableInput) => this.openEditUserDialog(user),
      labelFn: () => "SETTINGS.USERS.EDIT_USER",
      isVisible: (row: ISmallListTableInput) => row["role"] !== UserRole.SystemAdmin,
    },
    {
      callbackFn: (item: any) => this.#handlingArchiveUser.archiveUser(item),
      labelFn: () => "SETTINGS.USERS.ARCHIVE_USER",
    },
  ];

  ngOnInit() {
    this.subscribeToUpdateList$();
  }
  openEditUserDialog(user: ISmallListTableInput) {
    this.dialog.open(EditUserComponent, {
      data: {
        userId: user.id,
      },
    });
  }
  openSystemAdminEditDialog(user: ISmallListTableInput) {
    this.dialog.open(EditSystemAdminComponent, {
      data: {
        userId: user.id,
      },
    });
  }
  openResendInvitationDialog(user: ISmallListTableInput) {
    this.#dialogService.open(
      {
        tooltipLabel: "SETTINGS.USERS.RESEND_INVITATION.TITLE",
        title: "SETTINGS.USERS.RESEND_INVITATION.TITLE",
        cancelLabel: "CANCEL",
        callBack: () => this.#resendInvitation(user.id),
        submitLabel: "CONFIRM",
        isInputIncluded: false,
        descriptions: [
          "SETTINGS.USERS.RESEND_INVITATION.RESEND_CONFIRMATION",
          "SETTINGS.USERS.RESEND_INVITATION.SEND_TO",
          `<p class='text-black-500 -mt-5'>${user["email"]}</p>`,
        ],
        isSubmitLoading: this.#isResendInviteLoading,
      },
      "confirmation",
    );
  }

  #resendInvitation(id: number): void {
    this.#isResendInviteLoading.set(true);
    this.#userService.resendInvitation(id).subscribe({
      next: () => {
        this.#isResendInviteLoading.set(false);
        this.#dialogService.close();
        this.loadUserDate();
      },
      error: () => {
        this.#isResendInviteLoading.set(false);
        this.#dialogService.close();
      },
    });
  }

  getPaginatedSmallTableInputUsers(params: IPaginationSortPayload) {
    return this.usersService.getPaginatedSmallTableInputUsers(UserStatus.Pending, params);
  }
}
