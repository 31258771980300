import { map, OperatorFunction } from "rxjs";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ICompanyOverview } from "../models/company-overview.model";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { mapUnitsOverviewToUnitStatuses } from "../../../utilities/units-overview.utilities";
import { mapUnitsPercentageString } from "../../../utilities/units-availability-percentage.utilities";

export const mapCompaniesToSignalLargeListInputOperator = (): OperatorFunction<
  IPaginatedResponse<ICompanyOverview>,
  ISignalPaginatedResponse<ILargeListTableInput>
> => {
  return map((paginatedCompanies) => ({
    ...mapToSignalPaginatedResponse(paginatedCompanies),
    results: signal(mapCompaniesToLargeListInput(paginatedCompanies.results)),
  }));
};

const mapCompaniesToLargeListInput = (companies: ICompanyOverview[]): ILargeListTableInput[] => {
  return companies.map((company) => ({
    ...company,
    header: {
      name: company.name,
      subheader: company.address,
    },
    availability: mapUnitsPercentageString(company.availableUnits, company.availability),
    idleness: mapUnitsPercentageString(company.idleUnits, company.idleness),
    unitStatuses: mapUnitsOverviewToUnitStatuses(company.unitsOverview),
  }));
};
