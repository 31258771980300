import {
  INotificationClientListeners,
  MethodListener,
} from "@root/core/services/signalR/interfaces/notificationHub.interface";
import { MediaPackageNotificationMessage } from "@root/core/services/signalR/models/mediaPackageNotificationMessage";
import { RECEIVE_MEDIA_PACKAGE_NOTIFICATION } from "@root/core/services/signalR/constants/notificationClientMethods.constants";
import { Injectable } from "@angular/core";
import { BaseSignalRHub } from "@root/core/services/signalR/base/baseSignalRHub";

@Injectable({
  providedIn: "root",
})
export class NotificationHub extends BaseSignalRHub implements INotificationClientListeners {
  notificationMessage = new MethodListener<MediaPackageNotificationMessage>(RECEIVE_MEDIA_PACKAGE_NOTIFICATION);
  constructor() {
    super("notification");
    this.startConnection().subscribe();
  }
}
