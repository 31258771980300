import { NgClass, NgOptimizedImage } from "@angular/common";
import { Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from "@angular/material/expansion";
import { MatIcon } from "@angular/material/icon";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonStyleType } from "@root/shared/button/button-style.type";
import { ButtonComponent } from "@root/shared/button/button.component";
import { EventInstanceFilter } from "@root/shared/enums/property-event-instances-filtering.enum";
import { EventInstanceSortBy } from "@root/shared/enums/property-event-instances-sorting.enum";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { PaginationComponent } from "../../../../../../shared/pagination/pagination.component";
import { EventInstanceDetailsComponent } from "../event-instance-details/event-instance-details.component";
import { EventDetailsSignalStateService } from "../services/event-details-signal-state.service";
import { SingleEventInstanceWrapperComponent } from "../single-event-instance-wrapper/single-event-instance-wrapper.component";

@Component({
  selector: "est-series-events-instances-wrapper",
  standalone: true,
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatIcon,
    SingleEventInstanceWrapperComponent,
    EventInstanceDetailsComponent,
    NgClass,
    SmallHeaderComponent,
    ButtonComponent,
    TranslateModule,
    SpinnerComponent,
    PaginationComponent,
    NgOptimizedImage,
  ],
  templateUrl: "./series-events-instances-wrapper.component.html",
  styleUrl: "./series-events-instances-wrapper.component.scss",
})
export class SeriesEventsInstancesWrapperComponent {
  readonly #eventDetailsSignalStateService = inject(EventDetailsSignalStateService);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #destroyRef = inject(DestroyRef);
  #upcomingInstanceId!: number;

  constructor() {
    this.#activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((qParam) => (this.#upcomingInstanceId = qParam["instanceId"]));
  }

  paginatedEventInstances = this.#eventDetailsSignalStateService.paginatedEventInstances;
  eventInstances = this.paginatedEventInstances.results;
  filterBy = this.#eventDetailsSignalStateService.filterBy;
  sortBy = this.#eventDetailsSignalStateService.sortBy;
  sortDescending = this.#eventDetailsSignalStateService.sortDescending;
  isInstanceLoading = this.#eventDetailsSignalStateService.isInstanceLoading;
  filterButtons = [
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.ALL",
      getButtonStyleType: () => this.getButtonStyleType(EventInstanceFilter.All),
      callBackFn: () => this.selectButton(EventInstanceFilter.All),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.UPCOMING",
      getButtonStyleType: () => this.getButtonStyleType(EventInstanceFilter.Upcoming),
      callBackFn: () => this.selectButton(EventInstanceFilter.Upcoming),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.COMPLETED",
      getButtonStyleType: () => this.getButtonStyleType(EventInstanceFilter.Completed),
      callBackFn: () => this.selectButton(EventInstanceFilter.Completed),
    },
  ];

  sortingKeys = [
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.DATE_OF_EVENT",
      callbackFn: () => this.setSorting(EventInstanceSortBy.EventDate),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.BOOKING",
      callbackFn: () => this.setSorting(EventInstanceSortBy.Booking),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.ATTENDED",
      callbackFn: () => this.setSorting(EventInstanceSortBy.AttendedCustomers),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.CONVERSION",
      callbackFn: () => this.setSorting(EventInstanceSortBy.Conversion),
    },
    {
      label: "EVENTS.DETAILS.SERIES_EVENT.SORTING.RESPONSIBLE_AGENT",
      callbackFn: () => this.setSorting(EventInstanceSortBy.ResponsibleAgent),
    },
  ];

  isInstanceExpanded(instanceId: number): boolean {
    return instanceId === +this.#upcomingInstanceId;
  }
  getButtonStyleType(filterName: EventInstanceFilter): ButtonStyleType {
    return this.filterBy() === filterName ? "button--quaternary" : "button--tertiary";
  }
  selectButton(filterName: EventInstanceFilter): void {
    this.filterBy.set(filterName);
  }
  setSorting(sortBy: EventInstanceSortBy): void {
    if (this.sortBy() === sortBy) {
      this.sortDescending.set(!this.sortDescending());
    } else {
      this.sortBy.set(sortBy);
    }
  }
}
