import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { formatDateToShortString } from "../utilities/date.utilities";
import { IUserDivisionRole } from "../../data/market/users/models/user-division-role.model";
import { RoleNameMapper } from "../../data/market/users/mappers/role-name.mapper";

@Component({
  selector: "est-user-division-roles-overview",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./user-division-roles-overview.component.html",
  styleUrl: "./user-division-roles-overview.component.scss",
})
export class UserDivisionRolesOverviewComponent {
  name = input.required<string>();
  userDivisionRoles = input.required<IUserDivisionRole[]>();
  protected readonly RoleNameMapper = RoleNameMapper;

  getFormattedJoinedDate(isoDate: string): string {
    return formatDateToShortString(new Date(isoDate));
  }
}
