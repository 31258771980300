import { Routes } from "@angular/router";
import { ImportDataComponent } from "@root/views/main/toolbox/import-data/import-data.component";
import { ExportDataComponent } from "@root/views/main/toolbox/export-data/export-data.component";

export const toolboxRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "import",
  },
  {
    path: "import",
    component: ImportDataComponent,
    data: {
      name: "TOOLBOX.IMPORT_DATA.NAME",
    },
  },
  {
    path: "export",
    component: ExportDataComponent,
    data: {
      name: "TOOLBOX.EXPORT_DATA.NAME",
    },
  },
];
