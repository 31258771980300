import { Component, inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipComponent } from "@root/shared/tooltip/tooltip.component";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { LoadingOverlayComponent } from "@root/shared/loading-overlay/loading-overlay.component";
import { ButtonComponent } from "@root/shared/button/button.component";
import { UnitFacilitiesService } from "@root/data/market/units/services/unit-facilities.service";
import { AsyncPipe } from "@angular/common";
import { IUnitAssignedFacility } from "@root/data/market/units/models/unit-assigned-facility.model";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { FacilityLookupsService } from "@root/data/market/lookups/services/facility-lookups.service";
import { finalize } from "rxjs";
import { SnackbarService } from "@root/shared/ui-services/snackbar.service";
import { SnackbarType } from "@root/shared/enums/snackbar-type.enum";
import { UnitFacilitiesCommunicationService } from "@root/views/main/units/unit-details/components/facilities/unit-facilities/unit-facilities-communication.service";

@Component({
  selector: "est-edit-assigned-unit-facility",
  standalone: true,
  imports: [
    DialogHeaderComponent,
    FormsModule,
    TranslateModule,
    TooltipComponent,
    MatSlideToggle,
    ReactiveFormsModule,
    LoadingOverlayComponent,
    ButtonComponent,
    AsyncPipe,
    SelectFieldComponent,
  ],
  templateUrl: "./edit-assigned-unit-facility.component.html",
  styleUrl: "./edit-assigned-unit-facility.component.scss",
})
export class EditAssignedUnitFacilityComponent implements OnInit {
  readonly matDialog = inject(MatDialog);
  componentInputs: { unitId: string; facilityId: number } = inject(MAT_DIALOG_DATA);
  isSubmittingForm = false;
  facilityDetails: IUnitAssignedFacility | null = null;
  readonly #unitFacilitiesCommunicationService = inject(UnitFacilitiesCommunicationService);
  readonly #unitFacilitiesService = inject(UnitFacilitiesService);
  readonly #facilityLookupsService = inject(FacilityLookupsService);
  unitLocationOptions$ = this.#facilityLookupsService.getUnitFacilityLocationsLookups();
  readonly #snackbarService = inject(SnackbarService);
  readonly #fb = inject(FormBuilder);
  readonly formGroup = this.#fb.group({
    locationId: this.#fb.control<number | null>(null),
    isActive: this.#fb.control<boolean>(false),
  });

  get form() {
    return this.formGroup.controls;
  }

  ngOnInit() {
    this.#loadUnitDetails();
  }

  submitForm(): void {
    this.isSubmittingForm = true;
    this.#unitFacilitiesService
      .updateUnitAssignedFacility(this.formGroup.value, this.componentInputs.unitId, this.componentInputs.facilityId)
      .pipe(
        finalize(() => {
          this.isSubmittingForm = false;
        }),
      )
      .subscribe({
        next: () => {
          this.matDialog.closeAll();
          this.#snackbarService.open(SnackbarType.Success);
          this.#unitFacilitiesCommunicationService.unitFacilitiesModified$.next();
        },
      });
  }

  #loadUnitDetails(): void {
    this.#unitFacilitiesService
      .getUnitAssignedFacility(this.componentInputs.unitId, this.componentInputs.facilityId)
      .subscribe({
        next: (res) => {
          this.facilityDetails = res;
          this.formGroup.patchValue(res);
        },
        error: () => this.matDialog.closeAll(),
      });
  }
}
