import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { map } from "rxjs";
import { AuthService } from "../../data/market/auth/services/auth.service";

export const NoDivisionGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.getUserDivisionRoles()) {
    return authService.getUserDivisionRoles()!.length > 0 ? router.createUrlTree(["/"]) : true;
  } else {
    return authService.registerUser().pipe(
      map((user) => {
        return user.userDivisionRoles.length > 0 ? router.createUrlTree(["/"]) : true;
      }),
    );
  }
};
