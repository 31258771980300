import { ITableHeader } from "@root/shared/interfaces/table-header.interface";
import { TableHeaderType } from "@root/shared/enums/table-header-type.enum";

export const ADD_PROPERTIES_FACILITIES_TABLE_HEADERS: ITableHeader[] = [
  {
    key: "facilityId",
    label: "",
    type: TableHeaderType.Checkbox,
    isSortable: true,
  },
  {
    key: "facility",
    label: "PROPERTY_FACILITIES.FACILITIES.FACILITY",
    type: TableHeaderType.TextWithIcon,
    isSortable: true,
  },
  {
    key: "accessible",
    label: "ACCESSIBLE",
    type: TableHeaderType.Text,
    needsTranslation: true,
    isSortable: true,
  },
  {
    key: "location",
    label: "LOCATION",
    type: TableHeaderType.Text,
    needsTranslation: true,
    isSortable: true,
  },
  {
    key: "category",
    label: "CATEGORY",
    type: TableHeaderType.Text,
    isSortable: true,
  },
];
