import { Component, effect, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LinkingPackagesService } from "@root/data/market/linked/services/linking-packages.service";
import { BasePaginatedTableWithSearchComponent } from "@root/shared/abstracts/base-paginated-table-with-search/base-paginated-table-with-search.abstract";
import { DialogHeaderComponent } from "@root/shared/dialog-header/dialog-header.component";
import { LINKED_UNITS_TABLE_HEADERS } from "@root/shared/headers/linked-units.header";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { OutdoorSpaceForUnitComponent } from "@root/shared/outdoor-space-for-unit/outdoor-space-for-unit.component";
import { PaginationComponent } from "@root/shared/pagination/pagination.component";
import { SearchInputComponent } from "@root/shared/search-input/search-input.component";
import { SelectFieldComponent } from "@root/shared/select-field/select-field.component";
import { SelectSearchFieldComponent } from "@root/shared/select-search-field/select-search-field.component";
import { SmallHeaderComponent } from "@root/shared/small-header/small-header.component";
import { SmallListTableComponent } from "@root/shared/small-list-table/small-list-table.component";
import { copyPaginatedSignalResponse } from "@root/shared/utilities/signals.utilities";
import { finalize } from "rxjs/internal/operators/finalize";
import { LinkingSource } from "../enums/linking-source.enum";

@Component({
  selector: "est-linked-units-table-popup",
  standalone: true,
  imports: [
    SmallHeaderComponent,
    DialogHeaderComponent,
    TranslateModule,
    PaginationComponent,
    SelectSearchFieldComponent,
    SmallListTableComponent,
    SelectFieldComponent,
    SearchInputComponent,
  ],
  templateUrl: "./linked-units-table-popup.component.html",
  styleUrl: "./linked-units-table-popup.component.scss",
})
export class LinkedUnitsTablePopupComponent extends BasePaginatedTableWithSearchComponent {
  packageId = input<number>();
  isClicked = input<boolean>();
  propertyId = input.required<number>();
  linkingSource = input.required<LinkingSource>();

  sortingFilterSignal = signal({
    sortBy: "unitNo",
    sortDescending: false,
  });

  #linkingPackagesService = inject(LinkingPackagesService);

  readonly headers = LINKED_UNITS_TABLE_HEADERS.filter((header) => header.key !== "ids");
  readonly loadDataEffect$ = effect(() => {
    if (this.isClicked()) {
      this.loadData({
        sortBy: this.sortingFilterSignal().sortBy,
        pageNumber: this.paginatedData.currentPage(),
        search: this.searchSignal(),
        sortDescending: this.sortingFilterSignal().sortDescending,
        pageSize: this.paginatedData.pageSize(),
        filterLinked: true,
      });
    }
  });

  loadData(params: IPaginationSortPayload & { filterLinked: boolean }): void {
    this.isTableLoading = true;
    this.#linkingPackagesService
      .getLinkedUnitsList(params, this.propertyId(), this.packageId()!, this.linkingSource())
      .pipe(finalize(() => (this.isTableLoading = false)))
      .subscribe({
        next: (paginatedLinkedUnit) => copyPaginatedSignalResponse(this.paginatedData, paginatedLinkedUnit),
      });
  }

  protected readonly outdoorSpaceForUnitComponent = OutdoorSpaceForUnitComponent;
}
