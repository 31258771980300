import { RoomType } from "@root/data/market/units/enums/room-type.enum";
import { RestroomType } from "@root/data/market/units/enums/restroom-type.enum";

export const RoomTypeNameTranslationMapper = new Map<RoomType, string>([
  [RoomType.Bedroom, "UNIT_LAYOUT.ROOM_TYPES.BEDROOM.NAME"],
  [RoomType.LivingRoom, "UNIT_LAYOUT.ROOM_TYPES.LIVING_ROOM.NAME"],
  [RoomType.AdditionalRoom, "UNIT_LAYOUT.ROOM_TYPES.ADDITIONAL_ROOM.NAME"],
  [RoomType.Restroom, "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.NAME"],
  [RoomType.InternalStorageRoom, "UNIT_LAYOUT.ROOM_TYPES.INTERNAL_STORAGE_ROOM.NAME"],
  [RoomType.Garden, "UNIT_LAYOUT.ROOM_TYPES.GARDEN.NAME"],
  [RoomType.Terrace, "UNIT_LAYOUT.ROOM_TYPES.TERRACE.NAME"],
  [RoomType.Balcony, "UNIT_LAYOUT.ROOM_TYPES.BALCONY.NAME"],
]);

export const RoomTypeQuantityTranslationMapper = new Map<RoomType, string>([
  [RoomType.Bedroom, "UNIT_LAYOUT.ROOM_TYPES.BEDROOM.QUANTITY"],
  [RoomType.LivingRoom, "UNIT_LAYOUT.ROOM_TYPES.LIVING_ROOM.QUANTITY"],
  [RoomType.AdditionalRoom, "UNIT_LAYOUT.ROOM_TYPES.ADDITIONAL_ROOM.QUANTITY"],
  [RoomType.Restroom, "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.QUANTITY"],
  [RoomType.InternalStorageRoom, "UNIT_LAYOUT.ROOM_TYPES.INTERNAL_STORAGE_ROOM.QUANTITY"],
  [RoomType.Garden, "UNIT_LAYOUT.ROOM_TYPES.GARDEN.QUANTITY"],
  [RoomType.Terrace, "UNIT_LAYOUT.ROOM_TYPES.TERRACE.QUANTITY"],
  [RoomType.Balcony, "UNIT_LAYOUT.ROOM_TYPES.BALCONY.QUANTITY"],
]);

export const RoomTypeDetailNameTranslationMapper = new Map<RoomType, string>([
  [RoomType.Bedroom, "UNIT_LAYOUT.ROOM_TYPES.BEDROOM.DETAILS.NAME"],
  [RoomType.LivingRoom, "UNIT_LAYOUT.ROOM_TYPES.LIVING_ROOM.DETAILS.NAME"],
  [RoomType.AdditionalRoom, "UNIT_LAYOUT.ROOM_TYPES.ADDITIONAL_ROOM.DETAILS.NAME"],
  [RoomType.Garden, "UNIT_LAYOUT.ROOM_TYPES.GARDEN.DETAILS.NAME"],
  [RoomType.Terrace, "UNIT_LAYOUT.ROOM_TYPES.TERRACE.DETAILS.NAME"],
  [RoomType.Balcony, "UNIT_LAYOUT.ROOM_TYPES.BALCONY.DETAILS.NAME"],
]);

export const RoomTypeDetailDescriptionTranslationMapper = new Map<RoomType, string>([
  [RoomType.Bedroom, "UNIT_LAYOUT.ROOM_TYPES.BEDROOM.DETAILS.DESCRIPTION"],
  [RoomType.LivingRoom, "UNIT_LAYOUT.ROOM_TYPES.LIVING_ROOM.DETAILS.DESCRIPTION"],
  [RoomType.AdditionalRoom, "UNIT_LAYOUT.ROOM_TYPES.ADDITIONAL_ROOM.DETAILS.DESCRIPTION"],
  [RoomType.Garden, "UNIT_LAYOUT.ROOM_TYPES.GARDEN.DETAILS.DESCRIPTION"],
  [RoomType.Terrace, "UNIT_LAYOUT.ROOM_TYPES.TERRACE.DETAILS.DESCRIPTION"],
  [RoomType.Balcony, "UNIT_LAYOUT.ROOM_TYPES.BALCONY.DETAILS.DESCRIPTION"],
]);

export const RestroomTypeDetailNameTranslationMapper = new Map<RestroomType, string>([
  [RestroomType.Restroom, "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.RESTROOM.NAME"],
  [RestroomType.Bathroom, "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.NAME"],
]);

export const RestroomTypeDescriptionNameTranslationMapper = new Map<RestroomType, string>([
  [RestroomType.Restroom, "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.RESTROOM.DESCRIPTION"],
  [RestroomType.Bathroom, "UNIT_LAYOUT.ROOM_TYPES.RESTROOM.DETAILS.BATHROOM.DESCRIPTION"],
]);
