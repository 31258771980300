<ul [config]="options" [nxtSortablejs]="formArray()" class="flex gap-6 flex-wrap px-6">
  <!-- This will not work properly if trackBy is added, therefore avoid using @if -->
  <li *ngFor="let group of formArray().controls; let $index = index" class="w-[22%] overflow-hidden">
    <est-media-card
      (open)="openMediaDetails($index)"
      (delete)="deleteMedia($index)"
      [mediaFormGroup]="group"
      [uploadedBy]="authService.getFullName()!"
      [fileName]="getFileName($index)"
      (onDropDownChange)="changeControl.emit($event)"
    ></est-media-card>
  </li>
</ul>
