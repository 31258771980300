import { Component, input } from "@angular/core";
import { ILanguage } from "@root/shared/interfaces/language.interface";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "est-language-display",
  standalone: true,
  imports: [TranslateModule, NgClass],
  templateUrl: "./language-display.component.html",
  styleUrl: "./language-display.component.scss",
})
export class LanguageDisplayComponent {
  appliedClass = input<string>("");
  language = input.required<ILanguage>();
  small = input<boolean>(false);
  isDefault = input<boolean>(false);
  disabled = input<boolean>(false);
}
