<div class="my-3" id="text-Accordion">
  <mat-accordion>
    <mat-expansion-panel hideToggle="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <div class="flex justify-between items-center">
          <div class="flex gap-2 items-center">
            <img class="w-6 h-4 rounded-sm" [src]="allowedLanguage().flagImageUrl" [alt]="'FLAG' | translate" />
            <span class="font-medium text-sm">{{ allowedLanguage().name }}</span>
          </div>

          <div class="flex gap-1 items-center">
            @if (allowedLanguage().isDefault) {
              <p class="text-sm font-light">
                {{ "PROPERTY_TEXTS.DEFAULT_LANGUAGE_END_USER" | translate }}
              </p>
            } @else {
              <mat-slide-toggle
                (click)="$event.stopPropagation()"
                [formControl]="getControlInFormGroup(textFieldFormGroup(), 'isEnabled')"
                class="me-5"
                >{{ "ENABLE" | translate }}
              </mat-slide-toggle>
            }
            <mat-icon class="text-3xl pb-8">
              @if (!panelOpenState) {
                expand_less
              } @else {
                expand_more
              }
            </mat-icon>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div class="py-4">
        <div class="mb-6 flex flex-col">
          <est-small-header
            appliedClasses="mb-2"
            heading="PROPERTY_TEXTS.HEADLINE"
            subheading="PROPERTY_TEXTS.HEADLINE_DESCRIPTION"
            subheadingAppliedClasses="!max-w-[85%]"
            tooltip="PROPERTY_TEXTS.HEADLINE"
          ></est-small-header>
          <mat-slide-toggle
            (change)="toggleHeadlineRequired($event.checked)"
            [formControl]="getControlInFormGroup(textFieldFormGroup(), 'isHeadlineEnabled')"
            class="mt-5"
            >{{ "PROPERTY_TEXTS.ENABLE_HEADLINE" | translate }}
          </mat-slide-toggle>
          <est-input
            [control]="getControlInFormGroup(textFieldFormGroup(), 'headline')"
            appliedClass="mt-5 w-full xl:w-[37.5rem]"
            label="PROPERTY_TEXTS.HEADLINE"
          ></est-input>
        </div>
        <div>
          <est-small-header
            appliedClasses="mb-2"
            heading="PROPERTY_TEXTS.TEXT"
            subheading="PROPERTY_TEXTS.TEXT_DESCRIPTION"
            subheadingAppliedClasses="!max-w-[85%]"
            tooltip="PROPERTY_TEXTS.TEXT"
          ></est-small-header>
          <est-ck-text-editor [textControl]="textFieldFormGroup().controls.text"></est-ck-text-editor>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
