import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { IPhoneNumber } from "../../interfaces/phone-number.interface";
import { inject, signal, WritableSignal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { REGEX_PATTERNS } from "../../constants/regex-patterns.constants";
import { IDropdownOption } from "../../interfaces/dropdown-option.interface";
import { DivisionService } from "@root/data/market/division/services/division.service";
import { LookupsService } from "@root/data/market/lookups/services/lookups.service";
import { BaseStepperComponent } from "@root/shared/abstracts/stepper/base-stepper.component";
import { constructInitialPhoneNumberValue } from "@root/shared/utilities/phone-number.utilities";
import { IAddressLookUp } from "@root/shared/interfaces/address-look-up.interface";
import { constructInitialAddressLookups } from "@root/shared/utilities/address.utilities";
import { AuthService } from "@root/data/market/auth/services/auth.service";
import { constructAddressFormGroup } from "@root/shared/utilities/form.utilities";
import { OrganisationDivisionsCommunicationService } from "@root/views/main/organization/organization-divisions/services/organisation-details-communication.service";

export abstract class DivisionFormClass extends BaseStepperComponent {
  protected readonly authService = inject(AuthService);
  protected readonly fb = inject(NonNullableFormBuilder);
  readonly organisationDivisionsCommunicationService = inject(OrganisationDivisionsCommunicationService);
  divisionService = inject(DivisionService);
  lookupsService = inject(LookupsService);

  dialog = inject(MatDialog);
  dateOfCreation = new Date().toISOString();
  nameControl = this.fb.control<string>("", Validators.required);
  ownerIdControl = this.fb.control<number | null>(null, Validators.required);
  websiteControl = this.fb.control<string>("");
  contactPersonNameControl = this.fb.control<string>("", Validators.required);
  contactPersonEmailControl = this.fb.control<string>("", [
    Validators.required,
    Validators.pattern(REGEX_PATTERNS.email),
  ]);
  contactPersonIsdCodeControl = this.fb.control<string | null>("");
  phoneNumberControl = this.fb.control<IPhoneNumber>(constructInitialPhoneNumberValue(), Validators.required);
  contactPersonPhoneNo = this.fb.control<string | null>("");
  addressInformationControl = constructAddressFormGroup(this.fb, true, false);
  imageControl = this.fb.control<File | null | string>(null);
  logoThumbFilesizeControl = this.fb.control<string>("");
  logoThumbFileDimensionControl = this.fb.control<string>("");
  logoThumbFilenameControl = this.fb.control<string>("");
  organisationIdControl = this.fb.control<number>(1);

  divisionDetailsControl = this.fb.group({
    addressInformation: this.addressInformationControl,
    contactPersonName: this.contactPersonNameControl,
    contactPersonEmail: this.contactPersonEmailControl,
    websiteUrl: this.websiteControl,
    contactPersonPhoneNo: this.contactPersonPhoneNo,
    contactPersonIsdCode: this.contactPersonIsdCodeControl,
  });

  divisionForm = this.fb.group({
    name: this.nameControl,
    ownerId: this.ownerIdControl,
    organisationId: this.organisationIdControl,
    divisionDetails: this.divisionDetailsControl,
    image: this.imageControl,
    logoThumbFilesize: this.logoThumbFilesizeControl,
    logoThumbFileDimension: this.logoThumbFileDimensionControl,
    logoThumbFilename: this.logoThumbFilenameControl,
  });

  isAddressLoadingSignal: WritableSignal<boolean> = signal(false);
  addressLookupsOptions: IAddressLookUp<IDropdownOption> = constructInitialAddressLookups();
  isLoading = false;

  closeDialog() {
    this.dialog.closeAll();
  }

  getAddressLookupsOptions(): void {
    this.lookupsService.getAddressLookupOptions().subscribe((addressLookupsOptions) => {
      this.addressLookupsOptions = addressLookupsOptions;
    });
  }
  closeDialogAndUpdateTable() {
    this.organisationDivisionsCommunicationService.reloadOrganisationDivisionsPage$.next();
    this.closeDialog();
  }
}
