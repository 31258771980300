import { INamedEntity } from "@root/shared/interfaces/named-entity";
import { map, OperatorFunction } from "rxjs";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";

export const mapNamedEntitiesToDropdownOptions = (): OperatorFunction<INamedEntity[], IDropdownOption[]> => {
  return map((entities) => {
    return entities.map((entity) => ({
      label: entity.name,
      value: entity.id,
    }));
  });
};
