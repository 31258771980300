<form [formGroup]="systemAdminForm" (ngSubmit)="editSystemAdmin()">
  <div [ngClass]="{ 'absolute inset-0 bg-black/20 z-[100]': isLoading }" *ngIf="isLoading"></div>
  <est-dialog-header
    label="SETTINGS.USERS.EDIT_SYSTEM_ADMIN"
    tooltipLabel="SETTINGS.USERS.EDIT_SYSTEM_ADMIN"
    [includeExitButton]="true"
  >
  </est-dialog-header>
  @if (!isPageLoading) {
    <div class="max-h-[calc(100dvh-14rem)] overflow-y-auto">
      <div class="grid grid-cols-8 gap-2">
        <div class="col-span-6 max-breakpoint-ipad-screen:col-span-8 pb-8 overflow-y-auto relative">
          <p class="w-[52%] ps-6 pt-6 text-sm">{{ "SETTINGS.USERS.CREATE_SYSTEM_ADMIN_SUBTITLE" | translate }}</p>
          <!--  user information -->
          <div class="flex flex-col p-6 border-b border-black-100 flex-wrap">
            <p class="font-medium mb-4">{{ "SETTINGS.USERS.USER_INFORMATION" | translate }}</p>
            <div class="flex gap-4 mb-4">
              <est-input
                [appliedClass]="'w-[18.75rem]'"
                [label]="'SETTINGS.USERS.FIRST_NAME' | translate"
                [name]="'firstName'"
                [control]="firstName"
              />
              <est-input
                [appliedClass]="'w-[18.75rem]'"
                [label]="'SETTINGS.USERS.LAST_NAME' | translate"
                [name]="'lastName'"
                [control]="lastName"
              />
            </div>
            <div class="flex gap-4 mb-4">
              <est-input
                [appliedClass]="'w-[18.75rem]'"
                [label]="'EMAIL' | translate"
                [name]="'email'"
                [control]="email"
              />
              <est-input
                [appliedClass]="'w-[18.75rem]'"
                [label]="'SETTINGS.USERS.WORKPLACE' | translate"
                [name]="'workPlace'"
                [control]="workPlace"
              />
            </div>
            <div class="flex gap-4 mb-4">
              <est-input
                [appliedClass]="'w-[18.75rem]'"
                [label]="'SETTINGS.USERS.TITLE' | translate"
                [name]="'title'"
                [control]="title"
              />
              <est-phone-input [control]="phoneNumber" />
            </div>
          </div>
          <!--  system language -->
          <div class="flex flex-col p-6">
            <p class="font-medium mb-4">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE" | translate }}</p>
            <p class="text-sm mb-6">{{ "SETTINGS.USERS.SYSTEM_LANGUAGE_SUBTITLE" | translate }}</p>
            <est-language-dropdown
              [appliedClasses]="'w-[300px] h-[44px]'"
              selectMultipleOptions="false"
              [countries]="languages"
              [control]="systemLanguage"
            />
          </div>
        </div>
        <div
          class="col-span-2 border-l pt-4 px-6 max-breakpoint-ipad-screen:col-span-8 max-breakpoint-ipad-screen:border-t max-breakpoint-ipad-screen:flex max-breakpoint-ipad-screen:justify-start"
        >
          <est-image-upload
            [title]="'SETTINGS.USERS.PROFILE.PROFILE_IMAGE_PLACEHOLDER'"
            [description]="'SETTINGS.USERS.PROFILE.PROFILE_MESSAGE'"
            [imageFormControl]="imageControl"
          ></est-image-upload>
        </div>
      </div>
    </div>

    <div class="flex justify-between bg-black-50 px-6 py-3 sticky bottom-0 z-10">
      <est-button label="SAVE" buttonStyleType="button--tertiary" buttonType="submit" appliedClasses="!px-6 !py-3">
      </est-button>
      <est-button
        [isLoading]="isLoading"
        label="SAVE"
        buttonStyleType="button--primary"
        buttonType="submit"
        appliedClasses="!px-6 !py-3"
      >
      </est-button>
    </div>
  } @else {
    <div class="p-52 relative">
      <est-loading-overlay></est-loading-overlay>
    </div>
  }
</form>
